{
  "pagination": {
    "ListAccessGrants": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListAccessGrantsInstances": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListAccessGrantsLocations": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListAccessPoints": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListAccessPointsForObjectLambda": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "ObjectLambdaAccessPointList"
    },
    "ListJobs": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListMultiRegionAccessPoints": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListRegionalBuckets": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListStorageLensConfigurations": {
      "input_token": "NextToken",
      "output_token": "NextToken"
    },
    "ListStorageLensGroups": {
      "input_token": "NextToken",
      "output_token": "NextToken"
    }
  }
}
