import "../../style.css";

import React, { ReactElement } from "react";
import { FormControl, FormHelperText, Input, OutlinedInput, Paper } from "@mui/material";

interface Props {
    onChange: (right: string) => void;
    name_parameter: string;
    default_value: string;
    disabled: boolean;
    error: boolean;
}

const LargeTextInput = (props: Props): ReactElement => {

    return (
        // <div style={{height: 390}}>
        <div style={{height: 1530}}>
        {/* <div> */}
            <FormHelperText sx={{marginLeft: '14px', marginTop: '3px'}}>
                {props.name_parameter}
            </FormHelperText>
            <FormControl error={props.error} className="input-box" sx={{overflow: 'auto', height: '100%'}}>
                {/* <FormHelperText>
                    {props.name_parameter}
                </FormHelperText> */}
                <OutlinedInput
                    defaultValue={props.default_value}
                    multiline={true}
                    type="text"
                    onChange={(ev) => props.onChange(ev.target.value)}
                    fullWidth={true}
                    disabled={props.disabled}
                    sx={{height: '100%'}}
                />
            </FormControl>
        </div>

        // <>
        // <FormHelperText sx={{marginLeft: '14px', marginTop: '3px'}}>
        //     {props.name_parameter}
        // </FormHelperText>
        // <Paper style={{height: 390, border: '1px solid grey', padding: 1}} elevation={0}>
        //     <FormControl error={props.error} className="input-box" sx={{overflow: 'auto', height: '100%'}}>
        //         <Input
        //             // required
        //             defaultValue={props.default_value}
        //             multiline={true}
        //             type="text"
        //             onChange={(ev) => props.onChange(ev.target.value)}
        //             fullWidth={true}

        //         />
        //     </FormControl>
        // </Paper>
        // </>
    )
}

export default LargeTextInput