import "../style.css";

import React, { ReactElement, useContext } from "react";
import MenuAppBar from "./components/MenuAppBar";
import { Box, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { jwtDecode } from "jwt-decode";

interface Props {
    content: ReactElement;
    moduleName: string;
}

export const Layout = (props: Props): React.ReactElement => {
    const theme = useTheme();

    const formattedDate = new Date().toISOString().split("T")[0];

    return (
        <div className="module-page">
            <MenuAppBar moduleName={props.moduleName} fullWidthPage={false} />

            <Box marginTop={"84px"}>{props.content}</Box>

            {/* {props.content} */}

            <Grid container spacing={0} mt={3} justifyContent="space-between">
                <Grid item xs={12}>
                    <hr />
                </Grid>
                <Grid item xs={6} textAlign={"left"}>
                    <Typography>
                        For support please contact <a href="mailto:john@mail.com">John Doe</a>
                    </Typography>
                </Grid>
                <Grid item xs={6} textAlign={"right"}>
                    <Typography>Version 0.0.1 ({formattedDate})</Typography>
                </Grid>
            </Grid>
        </div>
    );
};
