{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-08-08",
    "endpointPrefix": "connect",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "Amazon Connect",
    "serviceFullName": "Amazon Connect Service",
    "serviceId": "Connect",
    "signatureVersion": "v4",
    "signingName": "connect",
    "uid": "connect-2017-08-08"
  },
  "operations": {
    "ActivateEvaluationForm": {
      "http": {
        "requestUri": "/evaluation-forms/{InstanceId}/{EvaluationFormId}/activate"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "EvaluationFormId",
          "EvaluationFormVersion"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "EvaluationFormId": {
            "location": "uri",
            "locationName": "EvaluationFormId"
          },
          "EvaluationFormVersion": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "EvaluationFormId",
          "EvaluationFormArn",
          "EvaluationFormVersion"
        ],
        "members": {
          "EvaluationFormId": {},
          "EvaluationFormArn": {},
          "EvaluationFormVersion": {
            "type": "integer"
          }
        }
      }
    },
    "AssociateAnalyticsDataSet": {
      "http": {
        "method": "PUT",
        "requestUri": "/analytics-data/instance/{InstanceId}/association"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "DataSetId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "DataSetId": {},
          "TargetAccountId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataSetId": {},
          "TargetAccountId": {},
          "ResourceShareId": {},
          "ResourceShareArn": {}
        }
      }
    },
    "AssociateApprovedOrigin": {
      "http": {
        "method": "PUT",
        "requestUri": "/instance/{InstanceId}/approved-origin"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "Origin"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "Origin": {}
        }
      }
    },
    "AssociateBot": {
      "http": {
        "method": "PUT",
        "requestUri": "/instance/{InstanceId}/bot"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "LexBot": {
            "shape": "Sf"
          },
          "LexV2Bot": {
            "shape": "Si"
          }
        }
      }
    },
    "AssociateDefaultVocabulary": {
      "http": {
        "method": "PUT",
        "requestUri": "/default-vocabulary/{InstanceId}/{LanguageCode}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "LanguageCode"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "LanguageCode": {
            "location": "uri",
            "locationName": "LanguageCode"
          },
          "VocabularyId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AssociateFlow": {
      "http": {
        "method": "PUT",
        "requestUri": "/flow-associations/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ResourceId",
          "FlowId",
          "ResourceType"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ResourceId": {},
          "FlowId": {},
          "ResourceType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AssociateInstanceStorageConfig": {
      "http": {
        "method": "PUT",
        "requestUri": "/instance/{InstanceId}/storage-config"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ResourceType",
          "StorageConfig"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ResourceType": {},
          "StorageConfig": {
            "shape": "St"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssociationId": {}
        }
      }
    },
    "AssociateLambdaFunction": {
      "http": {
        "method": "PUT",
        "requestUri": "/instance/{InstanceId}/lambda-function"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "FunctionArn"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "FunctionArn": {}
        }
      }
    },
    "AssociateLexBot": {
      "http": {
        "method": "PUT",
        "requestUri": "/instance/{InstanceId}/lex-bot"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "LexBot"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "LexBot": {
            "shape": "Sf"
          }
        }
      }
    },
    "AssociatePhoneNumberContactFlow": {
      "http": {
        "method": "PUT",
        "requestUri": "/phone-number/{PhoneNumberId}/contact-flow"
      },
      "input": {
        "type": "structure",
        "required": [
          "PhoneNumberId",
          "InstanceId",
          "ContactFlowId"
        ],
        "members": {
          "PhoneNumberId": {
            "location": "uri",
            "locationName": "PhoneNumberId"
          },
          "InstanceId": {},
          "ContactFlowId": {}
        }
      }
    },
    "AssociateQueueQuickConnects": {
      "http": {
        "requestUri": "/queues/{InstanceId}/{QueueId}/associate-quick-connects"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "QueueId",
          "QuickConnectIds"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "QueueId": {
            "location": "uri",
            "locationName": "QueueId"
          },
          "QuickConnectIds": {
            "shape": "S1f"
          }
        }
      }
    },
    "AssociateRoutingProfileQueues": {
      "http": {
        "requestUri": "/routing-profiles/{InstanceId}/{RoutingProfileId}/associate-queues"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "RoutingProfileId",
          "QueueConfigs"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "RoutingProfileId": {
            "location": "uri",
            "locationName": "RoutingProfileId"
          },
          "QueueConfigs": {
            "shape": "S1j"
          }
        }
      }
    },
    "AssociateSecurityKey": {
      "http": {
        "method": "PUT",
        "requestUri": "/instance/{InstanceId}/security-key"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "Key"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "Key": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssociationId": {}
        }
      }
    },
    "AssociateTrafficDistributionGroupUser": {
      "http": {
        "method": "PUT",
        "requestUri": "/traffic-distribution-group/{TrafficDistributionGroupId}/user"
      },
      "input": {
        "type": "structure",
        "required": [
          "TrafficDistributionGroupId",
          "UserId",
          "InstanceId"
        ],
        "members": {
          "TrafficDistributionGroupId": {
            "location": "uri",
            "locationName": "TrafficDistributionGroupId"
          },
          "UserId": {},
          "InstanceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "AssociateUserProficiencies": {
      "http": {
        "requestUri": "/users/{InstanceId}/{UserId}/associate-proficiencies"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "UserId",
          "UserProficiencies"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "UserId": {
            "location": "uri",
            "locationName": "UserId"
          },
          "UserProficiencies": {
            "shape": "S1x"
          }
        }
      }
    },
    "BatchAssociateAnalyticsDataSet": {
      "http": {
        "method": "PUT",
        "requestUri": "/analytics-data/instance/{InstanceId}/associations"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "DataSetIds"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "DataSetIds": {
            "shape": "S23"
          },
          "TargetAccountId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Created": {
            "shape": "S25"
          },
          "Errors": {
            "shape": "S27"
          }
        }
      }
    },
    "BatchDisassociateAnalyticsDataSet": {
      "http": {
        "requestUri": "/analytics-data/instance/{InstanceId}/associations"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "DataSetIds"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "DataSetIds": {
            "shape": "S23"
          },
          "TargetAccountId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Deleted": {
            "shape": "S23"
          },
          "Errors": {
            "shape": "S27"
          }
        }
      }
    },
    "BatchGetFlowAssociation": {
      "http": {
        "requestUri": "/flow-associations-batch/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ResourceIds"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ResourceIds": {
            "type": "list",
            "member": {}
          },
          "ResourceType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FlowAssociationSummaryList": {
            "shape": "S2f"
          }
        }
      }
    },
    "BatchPutContact": {
      "http": {
        "method": "PUT",
        "requestUri": "/contact/batch/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ContactDataRequestList"
        ],
        "members": {
          "ClientToken": {
            "idempotencyToken": true
          },
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ContactDataRequestList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "SystemEndpoint": {
                  "shape": "S2l"
                },
                "CustomerEndpoint": {
                  "shape": "S2l"
                },
                "RequestIdentifier": {},
                "QueueId": {},
                "Attributes": {
                  "shape": "S2p"
                },
                "Campaign": {
                  "type": "structure",
                  "members": {
                    "CampaignId": {}
                  }
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SuccessfulRequestList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "RequestIdentifier": {},
                "ContactId": {}
              }
            }
          },
          "FailedRequestList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "RequestIdentifier": {},
                "FailureReasonCode": {},
                "FailureReasonMessage": {}
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "ClaimPhoneNumber": {
      "http": {
        "requestUri": "/phone-number/claim"
      },
      "input": {
        "type": "structure",
        "required": [
          "PhoneNumber"
        ],
        "members": {
          "TargetArn": {},
          "InstanceId": {},
          "PhoneNumber": {},
          "PhoneNumberDescription": {},
          "Tags": {
            "shape": "S34"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PhoneNumberId": {},
          "PhoneNumberArn": {}
        }
      }
    },
    "CreateAgentStatus": {
      "http": {
        "method": "PUT",
        "requestUri": "/agent-status/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "Name",
          "State"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "Name": {},
          "Description": {},
          "State": {},
          "DisplayOrder": {
            "type": "integer"
          },
          "Tags": {
            "shape": "S34"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AgentStatusARN": {},
          "AgentStatusId": {}
        }
      }
    },
    "CreateContactFlow": {
      "http": {
        "method": "PUT",
        "requestUri": "/contact-flows/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "Name",
          "Type",
          "Content"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "Name": {},
          "Type": {},
          "Description": {},
          "Content": {},
          "Tags": {
            "shape": "S34"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ContactFlowId": {},
          "ContactFlowArn": {}
        }
      }
    },
    "CreateContactFlowModule": {
      "http": {
        "method": "PUT",
        "requestUri": "/contact-flow-modules/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "Name",
          "Content"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "Name": {},
          "Description": {},
          "Content": {},
          "Tags": {
            "shape": "S34"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Id": {},
          "Arn": {}
        }
      }
    },
    "CreateEvaluationForm": {
      "http": {
        "method": "PUT",
        "requestUri": "/evaluation-forms/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "Title",
          "Items"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "Title": {},
          "Description": {},
          "Items": {
            "shape": "S3u"
          },
          "ScoringStrategy": {
            "shape": "S4p"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "EvaluationFormId",
          "EvaluationFormArn"
        ],
        "members": {
          "EvaluationFormId": {},
          "EvaluationFormArn": {}
        }
      },
      "idempotent": true
    },
    "CreateHoursOfOperation": {
      "http": {
        "method": "PUT",
        "requestUri": "/hours-of-operations/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "Name",
          "TimeZone",
          "Config"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "Name": {},
          "Description": {},
          "TimeZone": {},
          "Config": {
            "shape": "S4x"
          },
          "Tags": {
            "shape": "S34"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HoursOfOperationId": {},
          "HoursOfOperationArn": {}
        }
      }
    },
    "CreateInstance": {
      "http": {
        "method": "PUT",
        "requestUri": "/instance"
      },
      "input": {
        "type": "structure",
        "required": [
          "IdentityManagementType",
          "InboundCallsEnabled",
          "OutboundCallsEnabled"
        ],
        "members": {
          "ClientToken": {},
          "IdentityManagementType": {},
          "InstanceAlias": {
            "shape": "S57"
          },
          "DirectoryId": {},
          "InboundCallsEnabled": {
            "type": "boolean"
          },
          "OutboundCallsEnabled": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "S34"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Id": {},
          "Arn": {}
        }
      }
    },
    "CreateIntegrationAssociation": {
      "http": {
        "method": "PUT",
        "requestUri": "/instance/{InstanceId}/integration-associations"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "IntegrationType",
          "IntegrationArn"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "IntegrationType": {},
          "IntegrationArn": {},
          "SourceApplicationUrl": {},
          "SourceApplicationName": {},
          "SourceType": {},
          "Tags": {
            "shape": "S34"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IntegrationAssociationId": {},
          "IntegrationAssociationArn": {}
        }
      }
    },
    "CreateParticipant": {
      "http": {
        "requestUri": "/contact/create-participant"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ContactId",
          "ParticipantDetails"
        ],
        "members": {
          "InstanceId": {},
          "ContactId": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "ParticipantDetails": {
            "type": "structure",
            "members": {
              "ParticipantRole": {},
              "DisplayName": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ParticipantCredentials": {
            "type": "structure",
            "members": {
              "ParticipantToken": {},
              "Expiry": {}
            }
          },
          "ParticipantId": {}
        }
      }
    },
    "CreatePersistentContactAssociation": {
      "http": {
        "requestUri": "/contact/persistent-contact-association/{InstanceId}/{InitialContactId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "InitialContactId",
          "RehydrationType",
          "SourceContactId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "InitialContactId": {
            "location": "uri",
            "locationName": "InitialContactId"
          },
          "RehydrationType": {},
          "SourceContactId": {},
          "ClientToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ContinuedFromContactId": {}
        }
      }
    },
    "CreatePredefinedAttribute": {
      "http": {
        "method": "PUT",
        "requestUri": "/predefined-attributes/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "Name",
          "Values"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "Name": {},
          "Values": {
            "shape": "S5w"
          }
        }
      }
    },
    "CreatePrompt": {
      "http": {
        "method": "PUT",
        "requestUri": "/prompts/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "Name",
          "S3Uri"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "Name": {},
          "Description": {},
          "S3Uri": {},
          "Tags": {
            "shape": "S34"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PromptARN": {},
          "PromptId": {}
        }
      }
    },
    "CreateQueue": {
      "http": {
        "method": "PUT",
        "requestUri": "/queues/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "Name",
          "HoursOfOperationId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "Name": {},
          "Description": {},
          "OutboundCallerConfig": {
            "shape": "S65"
          },
          "HoursOfOperationId": {},
          "MaxContacts": {
            "type": "integer"
          },
          "QuickConnectIds": {
            "shape": "S1f"
          },
          "Tags": {
            "shape": "S34"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "QueueArn": {},
          "QueueId": {}
        }
      }
    },
    "CreateQuickConnect": {
      "http": {
        "method": "PUT",
        "requestUri": "/quick-connects/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "Name",
          "QuickConnectConfig"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "Name": {},
          "Description": {},
          "QuickConnectConfig": {
            "shape": "S6c"
          },
          "Tags": {
            "shape": "S34"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "QuickConnectARN": {},
          "QuickConnectId": {}
        }
      }
    },
    "CreateRoutingProfile": {
      "http": {
        "method": "PUT",
        "requestUri": "/routing-profiles/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "Name",
          "Description",
          "DefaultOutboundQueueId",
          "MediaConcurrencies"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "Name": {},
          "Description": {},
          "DefaultOutboundQueueId": {},
          "QueueConfigs": {
            "shape": "S1j"
          },
          "MediaConcurrencies": {
            "shape": "S6l"
          },
          "Tags": {
            "shape": "S34"
          },
          "AgentAvailabilityTimer": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RoutingProfileArn": {},
          "RoutingProfileId": {}
        }
      }
    },
    "CreateRule": {
      "http": {
        "requestUri": "/rules/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "Name",
          "TriggerEventSource",
          "Function",
          "Actions",
          "PublishStatus"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "Name": {},
          "TriggerEventSource": {
            "shape": "S6u"
          },
          "Function": {},
          "Actions": {
            "shape": "S6x"
          },
          "PublishStatus": {},
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "RuleArn",
          "RuleId"
        ],
        "members": {
          "RuleArn": {},
          "RuleId": {}
        }
      }
    },
    "CreateSecurityProfile": {
      "http": {
        "method": "PUT",
        "requestUri": "/security-profiles/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "SecurityProfileName",
          "InstanceId"
        ],
        "members": {
          "SecurityProfileName": {},
          "Description": {},
          "Permissions": {
            "shape": "S80"
          },
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "Tags": {
            "shape": "S34"
          },
          "AllowedAccessControlTags": {
            "shape": "S82"
          },
          "TagRestrictedResources": {
            "shape": "S85"
          },
          "Applications": {
            "shape": "S87"
          },
          "HierarchyRestrictedResources": {
            "shape": "S8c"
          },
          "AllowedAccessControlHierarchyGroupId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SecurityProfileId": {},
          "SecurityProfileArn": {}
        }
      }
    },
    "CreateTaskTemplate": {
      "http": {
        "method": "PUT",
        "requestUri": "/instance/{InstanceId}/task/template"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "Name",
          "Fields"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "Name": {},
          "Description": {},
          "ContactFlowId": {},
          "Constraints": {
            "shape": "S8k"
          },
          "Defaults": {
            "shape": "S8t"
          },
          "Status": {},
          "Fields": {
            "shape": "S8y"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Id",
          "Arn"
        ],
        "members": {
          "Id": {},
          "Arn": {}
        }
      }
    },
    "CreateTrafficDistributionGroup": {
      "http": {
        "method": "PUT",
        "requestUri": "/traffic-distribution-group"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "InstanceId"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "InstanceId": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "S34"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Id": {},
          "Arn": {}
        }
      }
    },
    "CreateUseCase": {
      "http": {
        "method": "PUT",
        "requestUri": "/instance/{InstanceId}/integration-associations/{IntegrationAssociationId}/use-cases"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "IntegrationAssociationId",
          "UseCaseType"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "IntegrationAssociationId": {
            "location": "uri",
            "locationName": "IntegrationAssociationId"
          },
          "UseCaseType": {},
          "Tags": {
            "shape": "S34"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UseCaseId": {},
          "UseCaseArn": {}
        }
      }
    },
    "CreateUser": {
      "http": {
        "method": "PUT",
        "requestUri": "/users/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Username",
          "PhoneConfig",
          "SecurityProfileIds",
          "RoutingProfileId",
          "InstanceId"
        ],
        "members": {
          "Username": {},
          "Password": {
            "type": "string",
            "sensitive": true
          },
          "IdentityInfo": {
            "shape": "S9l"
          },
          "PhoneConfig": {
            "shape": "S9p"
          },
          "DirectoryUserId": {},
          "SecurityProfileIds": {
            "shape": "S9u"
          },
          "RoutingProfileId": {},
          "HierarchyGroupId": {},
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "Tags": {
            "shape": "S34"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserId": {},
          "UserArn": {}
        }
      }
    },
    "CreateUserHierarchyGroup": {
      "http": {
        "method": "PUT",
        "requestUri": "/user-hierarchy-groups/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "InstanceId"
        ],
        "members": {
          "Name": {},
          "ParentGroupId": {},
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "Tags": {
            "shape": "S34"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HierarchyGroupId": {},
          "HierarchyGroupArn": {}
        }
      }
    },
    "CreateView": {
      "http": {
        "method": "PUT",
        "requestUri": "/views/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "Status",
          "Content",
          "Name"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ClientToken": {},
          "Status": {},
          "Content": {
            "shape": "Sa3"
          },
          "Description": {},
          "Name": {
            "shape": "Sa8"
          },
          "Tags": {
            "shape": "S34"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "View": {
            "shape": "Saa"
          }
        }
      },
      "idempotent": true
    },
    "CreateViewVersion": {
      "http": {
        "method": "PUT",
        "requestUri": "/views/{InstanceId}/{ViewId}/versions"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ViewId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ViewId": {
            "location": "uri",
            "locationName": "ViewId"
          },
          "VersionDescription": {},
          "ViewContentSha256": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "View": {
            "shape": "Saa"
          }
        }
      },
      "idempotent": true
    },
    "CreateVocabulary": {
      "http": {
        "requestUri": "/vocabulary/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "VocabularyName",
          "LanguageCode",
          "Content"
        ],
        "members": {
          "ClientToken": {
            "idempotencyToken": true
          },
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "VocabularyName": {},
          "LanguageCode": {},
          "Content": {},
          "Tags": {
            "shape": "S34"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "VocabularyArn",
          "VocabularyId",
          "State"
        ],
        "members": {
          "VocabularyArn": {},
          "VocabularyId": {},
          "State": {}
        }
      }
    },
    "DeactivateEvaluationForm": {
      "http": {
        "requestUri": "/evaluation-forms/{InstanceId}/{EvaluationFormId}/deactivate"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "EvaluationFormId",
          "EvaluationFormVersion"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "EvaluationFormId": {
            "location": "uri",
            "locationName": "EvaluationFormId"
          },
          "EvaluationFormVersion": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "EvaluationFormId",
          "EvaluationFormArn",
          "EvaluationFormVersion"
        ],
        "members": {
          "EvaluationFormId": {},
          "EvaluationFormArn": {},
          "EvaluationFormVersion": {
            "type": "integer"
          }
        }
      }
    },
    "DeleteContactEvaluation": {
      "http": {
        "method": "DELETE",
        "requestUri": "/contact-evaluations/{InstanceId}/{EvaluationId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "EvaluationId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "EvaluationId": {
            "location": "uri",
            "locationName": "EvaluationId"
          }
        }
      },
      "idempotent": true
    },
    "DeleteContactFlow": {
      "http": {
        "method": "DELETE",
        "requestUri": "/contact-flows/{InstanceId}/{ContactFlowId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ContactFlowId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ContactFlowId": {
            "location": "uri",
            "locationName": "ContactFlowId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteContactFlowModule": {
      "http": {
        "method": "DELETE",
        "requestUri": "/contact-flow-modules/{InstanceId}/{ContactFlowModuleId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ContactFlowModuleId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ContactFlowModuleId": {
            "location": "uri",
            "locationName": "ContactFlowModuleId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteEvaluationForm": {
      "http": {
        "method": "DELETE",
        "requestUri": "/evaluation-forms/{InstanceId}/{EvaluationFormId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "EvaluationFormId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "EvaluationFormId": {
            "location": "uri",
            "locationName": "EvaluationFormId"
          },
          "EvaluationFormVersion": {
            "location": "querystring",
            "locationName": "version",
            "type": "integer"
          }
        }
      },
      "idempotent": true
    },
    "DeleteHoursOfOperation": {
      "http": {
        "method": "DELETE",
        "requestUri": "/hours-of-operations/{InstanceId}/{HoursOfOperationId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "HoursOfOperationId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "HoursOfOperationId": {
            "location": "uri",
            "locationName": "HoursOfOperationId"
          }
        }
      }
    },
    "DeleteInstance": {
      "http": {
        "method": "DELETE",
        "requestUri": "/instance/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          }
        }
      }
    },
    "DeleteIntegrationAssociation": {
      "http": {
        "method": "DELETE",
        "requestUri": "/instance/{InstanceId}/integration-associations/{IntegrationAssociationId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "IntegrationAssociationId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "IntegrationAssociationId": {
            "location": "uri",
            "locationName": "IntegrationAssociationId"
          }
        }
      }
    },
    "DeletePredefinedAttribute": {
      "http": {
        "method": "DELETE",
        "requestUri": "/predefined-attributes/{InstanceId}/{Name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "Name"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "Name": {
            "location": "uri",
            "locationName": "Name"
          }
        }
      },
      "idempotent": true
    },
    "DeletePrompt": {
      "http": {
        "method": "DELETE",
        "requestUri": "/prompts/{InstanceId}/{PromptId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "PromptId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "PromptId": {
            "location": "uri",
            "locationName": "PromptId"
          }
        }
      }
    },
    "DeleteQueue": {
      "http": {
        "method": "DELETE",
        "requestUri": "/queues/{InstanceId}/{QueueId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "QueueId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "QueueId": {
            "location": "uri",
            "locationName": "QueueId"
          }
        }
      }
    },
    "DeleteQuickConnect": {
      "http": {
        "method": "DELETE",
        "requestUri": "/quick-connects/{InstanceId}/{QuickConnectId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "QuickConnectId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "QuickConnectId": {
            "location": "uri",
            "locationName": "QuickConnectId"
          }
        }
      }
    },
    "DeleteRoutingProfile": {
      "http": {
        "method": "DELETE",
        "requestUri": "/routing-profiles/{InstanceId}/{RoutingProfileId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "RoutingProfileId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "RoutingProfileId": {
            "location": "uri",
            "locationName": "RoutingProfileId"
          }
        }
      }
    },
    "DeleteRule": {
      "http": {
        "method": "DELETE",
        "requestUri": "/rules/{InstanceId}/{RuleId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "RuleId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "RuleId": {
            "location": "uri",
            "locationName": "RuleId"
          }
        }
      }
    },
    "DeleteSecurityProfile": {
      "http": {
        "method": "DELETE",
        "requestUri": "/security-profiles/{InstanceId}/{SecurityProfileId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "SecurityProfileId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "SecurityProfileId": {
            "location": "uri",
            "locationName": "SecurityProfileId"
          }
        }
      }
    },
    "DeleteTaskTemplate": {
      "http": {
        "method": "DELETE",
        "requestUri": "/instance/{InstanceId}/task/template/{TaskTemplateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "TaskTemplateId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "TaskTemplateId": {
            "location": "uri",
            "locationName": "TaskTemplateId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteTrafficDistributionGroup": {
      "http": {
        "method": "DELETE",
        "requestUri": "/traffic-distribution-group/{TrafficDistributionGroupId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "TrafficDistributionGroupId"
        ],
        "members": {
          "TrafficDistributionGroupId": {
            "location": "uri",
            "locationName": "TrafficDistributionGroupId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteUseCase": {
      "http": {
        "method": "DELETE",
        "requestUri": "/instance/{InstanceId}/integration-associations/{IntegrationAssociationId}/use-cases/{UseCaseId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "IntegrationAssociationId",
          "UseCaseId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "IntegrationAssociationId": {
            "location": "uri",
            "locationName": "IntegrationAssociationId"
          },
          "UseCaseId": {
            "location": "uri",
            "locationName": "UseCaseId"
          }
        }
      }
    },
    "DeleteUser": {
      "http": {
        "method": "DELETE",
        "requestUri": "/users/{InstanceId}/{UserId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "UserId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "UserId": {
            "location": "uri",
            "locationName": "UserId"
          }
        }
      }
    },
    "DeleteUserHierarchyGroup": {
      "http": {
        "method": "DELETE",
        "requestUri": "/user-hierarchy-groups/{InstanceId}/{HierarchyGroupId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "HierarchyGroupId",
          "InstanceId"
        ],
        "members": {
          "HierarchyGroupId": {
            "location": "uri",
            "locationName": "HierarchyGroupId"
          },
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          }
        }
      }
    },
    "DeleteView": {
      "http": {
        "method": "DELETE",
        "requestUri": "/views/{InstanceId}/{ViewId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ViewId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ViewId": {
            "location": "uri",
            "locationName": "ViewId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteViewVersion": {
      "http": {
        "method": "DELETE",
        "requestUri": "/views/{InstanceId}/{ViewId}/versions/{ViewVersion}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ViewId",
          "ViewVersion"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ViewId": {
            "location": "uri",
            "locationName": "ViewId"
          },
          "ViewVersion": {
            "location": "uri",
            "locationName": "ViewVersion",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteVocabulary": {
      "http": {
        "requestUri": "/vocabulary-remove/{InstanceId}/{VocabularyId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "VocabularyId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "VocabularyId": {
            "location": "uri",
            "locationName": "VocabularyId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "VocabularyArn",
          "VocabularyId",
          "State"
        ],
        "members": {
          "VocabularyArn": {},
          "VocabularyId": {},
          "State": {}
        }
      }
    },
    "DescribeAgentStatus": {
      "http": {
        "method": "GET",
        "requestUri": "/agent-status/{InstanceId}/{AgentStatusId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "AgentStatusId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "AgentStatusId": {
            "location": "uri",
            "locationName": "AgentStatusId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AgentStatus": {
            "type": "structure",
            "members": {
              "AgentStatusARN": {},
              "AgentStatusId": {},
              "Name": {},
              "Description": {},
              "Type": {},
              "DisplayOrder": {
                "type": "integer"
              },
              "State": {},
              "Tags": {
                "shape": "S34"
              },
              "LastModifiedTime": {
                "type": "timestamp"
              },
              "LastModifiedRegion": {}
            }
          }
        }
      }
    },
    "DescribeContact": {
      "http": {
        "method": "GET",
        "requestUri": "/contacts/{InstanceId}/{ContactId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ContactId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ContactId": {
            "location": "uri",
            "locationName": "ContactId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Contact": {
            "type": "structure",
            "members": {
              "Arn": {},
              "Id": {},
              "InitialContactId": {},
              "PreviousContactId": {},
              "InitiationMethod": {},
              "Name": {
                "shape": "Sbt"
              },
              "Description": {
                "shape": "Sbu"
              },
              "Channel": {},
              "QueueInfo": {
                "type": "structure",
                "members": {
                  "Id": {},
                  "EnqueueTimestamp": {
                    "type": "timestamp"
                  }
                }
              },
              "AgentInfo": {
                "type": "structure",
                "members": {
                  "Id": {},
                  "ConnectedToAgentTimestamp": {
                    "type": "timestamp"
                  },
                  "AgentPauseDurationInSeconds": {
                    "type": "integer"
                  }
                }
              },
              "InitiationTimestamp": {
                "type": "timestamp"
              },
              "DisconnectTimestamp": {
                "type": "timestamp"
              },
              "LastUpdateTimestamp": {
                "type": "timestamp"
              },
              "LastPausedTimestamp": {
                "type": "timestamp"
              },
              "LastResumedTimestamp": {
                "type": "timestamp"
              },
              "TotalPauseCount": {
                "type": "integer"
              },
              "TotalPauseDurationInSeconds": {
                "type": "integer"
              },
              "ScheduledTimestamp": {
                "type": "timestamp"
              },
              "RelatedContactId": {},
              "WisdomInfo": {
                "type": "structure",
                "members": {
                  "SessionArn": {}
                }
              },
              "QueueTimeAdjustmentSeconds": {
                "type": "integer"
              },
              "QueuePriority": {
                "type": "long"
              },
              "Tags": {
                "shape": "Sc5"
              }
            }
          }
        }
      }
    },
    "DescribeContactEvaluation": {
      "http": {
        "method": "GET",
        "requestUri": "/contact-evaluations/{InstanceId}/{EvaluationId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "EvaluationId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "EvaluationId": {
            "location": "uri",
            "locationName": "EvaluationId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Evaluation",
          "EvaluationForm"
        ],
        "members": {
          "Evaluation": {
            "type": "structure",
            "required": [
              "EvaluationId",
              "EvaluationArn",
              "Metadata",
              "Answers",
              "Notes",
              "Status",
              "CreatedTime",
              "LastModifiedTime"
            ],
            "members": {
              "EvaluationId": {},
              "EvaluationArn": {},
              "Metadata": {
                "type": "structure",
                "required": [
                  "ContactId",
                  "EvaluatorArn"
                ],
                "members": {
                  "ContactId": {},
                  "EvaluatorArn": {},
                  "ContactAgentId": {},
                  "Score": {
                    "shape": "Scc"
                  }
                }
              },
              "Answers": {
                "type": "map",
                "key": {},
                "value": {
                  "type": "structure",
                  "members": {
                    "Value": {
                      "shape": "Scg"
                    },
                    "SystemSuggestedValue": {
                      "shape": "Scg"
                    }
                  }
                }
              },
              "Notes": {
                "shape": "Scj"
              },
              "Status": {},
              "Scores": {
                "type": "map",
                "key": {},
                "value": {
                  "shape": "Scc"
                }
              },
              "CreatedTime": {
                "type": "timestamp"
              },
              "LastModifiedTime": {
                "type": "timestamp"
              },
              "Tags": {
                "shape": "S34"
              }
            }
          },
          "EvaluationForm": {
            "type": "structure",
            "required": [
              "EvaluationFormVersion",
              "EvaluationFormId",
              "EvaluationFormArn",
              "Title",
              "Items"
            ],
            "members": {
              "EvaluationFormVersion": {
                "type": "integer"
              },
              "EvaluationFormId": {},
              "EvaluationFormArn": {},
              "Title": {},
              "Description": {},
              "Items": {
                "shape": "S3u"
              },
              "ScoringStrategy": {
                "shape": "S4p"
              }
            }
          }
        }
      }
    },
    "DescribeContactFlow": {
      "http": {
        "method": "GET",
        "requestUri": "/contact-flows/{InstanceId}/{ContactFlowId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ContactFlowId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ContactFlowId": {
            "location": "uri",
            "locationName": "ContactFlowId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ContactFlow": {
            "type": "structure",
            "members": {
              "Arn": {},
              "Id": {},
              "Name": {},
              "Type": {},
              "State": {},
              "Description": {},
              "Content": {},
              "Tags": {
                "shape": "S34"
              }
            }
          }
        }
      }
    },
    "DescribeContactFlowModule": {
      "http": {
        "method": "GET",
        "requestUri": "/contact-flow-modules/{InstanceId}/{ContactFlowModuleId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ContactFlowModuleId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ContactFlowModuleId": {
            "location": "uri",
            "locationName": "ContactFlowModuleId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ContactFlowModule": {
            "type": "structure",
            "members": {
              "Arn": {},
              "Id": {},
              "Name": {},
              "Content": {},
              "Description": {},
              "State": {},
              "Status": {},
              "Tags": {
                "shape": "S34"
              }
            }
          }
        }
      }
    },
    "DescribeEvaluationForm": {
      "http": {
        "method": "GET",
        "requestUri": "/evaluation-forms/{InstanceId}/{EvaluationFormId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "EvaluationFormId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "EvaluationFormId": {
            "location": "uri",
            "locationName": "EvaluationFormId"
          },
          "EvaluationFormVersion": {
            "location": "querystring",
            "locationName": "version",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "EvaluationForm"
        ],
        "members": {
          "EvaluationForm": {
            "type": "structure",
            "required": [
              "EvaluationFormId",
              "EvaluationFormVersion",
              "Locked",
              "EvaluationFormArn",
              "Title",
              "Status",
              "Items",
              "CreatedTime",
              "CreatedBy",
              "LastModifiedTime",
              "LastModifiedBy"
            ],
            "members": {
              "EvaluationFormId": {},
              "EvaluationFormVersion": {
                "type": "integer"
              },
              "Locked": {
                "type": "boolean"
              },
              "EvaluationFormArn": {},
              "Title": {},
              "Description": {},
              "Status": {},
              "Items": {
                "shape": "S3u"
              },
              "ScoringStrategy": {
                "shape": "S4p"
              },
              "CreatedTime": {
                "type": "timestamp"
              },
              "CreatedBy": {},
              "LastModifiedTime": {
                "type": "timestamp"
              },
              "LastModifiedBy": {},
              "Tags": {
                "shape": "S34"
              }
            }
          }
        }
      }
    },
    "DescribeHoursOfOperation": {
      "http": {
        "method": "GET",
        "requestUri": "/hours-of-operations/{InstanceId}/{HoursOfOperationId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "HoursOfOperationId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "HoursOfOperationId": {
            "location": "uri",
            "locationName": "HoursOfOperationId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HoursOfOperation": {
            "shape": "Sd5"
          }
        }
      }
    },
    "DescribeInstance": {
      "http": {
        "method": "GET",
        "requestUri": "/instance/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Instance": {
            "type": "structure",
            "members": {
              "Id": {},
              "Arn": {},
              "IdentityManagementType": {},
              "InstanceAlias": {
                "shape": "S57"
              },
              "CreatedTime": {
                "type": "timestamp"
              },
              "ServiceRole": {},
              "InstanceStatus": {},
              "StatusReason": {
                "type": "structure",
                "members": {
                  "Message": {}
                }
              },
              "InboundCallsEnabled": {
                "type": "boolean"
              },
              "OutboundCallsEnabled": {
                "type": "boolean"
              },
              "InstanceAccessUrl": {},
              "Tags": {
                "shape": "S34"
              }
            }
          }
        }
      }
    },
    "DescribeInstanceAttribute": {
      "http": {
        "method": "GET",
        "requestUri": "/instance/{InstanceId}/attribute/{AttributeType}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "AttributeType"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "AttributeType": {
            "location": "uri",
            "locationName": "AttributeType"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Attribute": {
            "shape": "Sdf"
          }
        }
      }
    },
    "DescribeInstanceStorageConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/instance/{InstanceId}/storage-config/{AssociationId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "AssociationId",
          "ResourceType"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "AssociationId": {
            "location": "uri",
            "locationName": "AssociationId"
          },
          "ResourceType": {
            "location": "querystring",
            "locationName": "resourceType"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StorageConfig": {
            "shape": "St"
          }
        }
      }
    },
    "DescribePhoneNumber": {
      "http": {
        "method": "GET",
        "requestUri": "/phone-number/{PhoneNumberId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "PhoneNumberId"
        ],
        "members": {
          "PhoneNumberId": {
            "location": "uri",
            "locationName": "PhoneNumberId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClaimedPhoneNumberSummary": {
            "type": "structure",
            "members": {
              "PhoneNumberId": {},
              "PhoneNumberArn": {},
              "PhoneNumber": {},
              "PhoneNumberCountryCode": {},
              "PhoneNumberType": {},
              "PhoneNumberDescription": {},
              "TargetArn": {},
              "InstanceId": {},
              "Tags": {
                "shape": "S34"
              },
              "PhoneNumberStatus": {
                "type": "structure",
                "members": {
                  "Status": {},
                  "Message": {}
                }
              },
              "SourcePhoneNumberArn": {}
            }
          }
        }
      }
    },
    "DescribePredefinedAttribute": {
      "http": {
        "method": "GET",
        "requestUri": "/predefined-attributes/{InstanceId}/{Name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "Name"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "Name": {
            "location": "uri",
            "locationName": "Name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PredefinedAttribute": {
            "shape": "Sdt"
          }
        }
      }
    },
    "DescribePrompt": {
      "http": {
        "method": "GET",
        "requestUri": "/prompts/{InstanceId}/{PromptId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "PromptId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "PromptId": {
            "location": "uri",
            "locationName": "PromptId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Prompt": {
            "shape": "Sdw"
          }
        }
      }
    },
    "DescribeQueue": {
      "http": {
        "method": "GET",
        "requestUri": "/queues/{InstanceId}/{QueueId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "QueueId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "QueueId": {
            "location": "uri",
            "locationName": "QueueId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Queue": {
            "shape": "Sdz"
          }
        }
      }
    },
    "DescribeQuickConnect": {
      "http": {
        "method": "GET",
        "requestUri": "/quick-connects/{InstanceId}/{QuickConnectId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "QuickConnectId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "QuickConnectId": {
            "location": "uri",
            "locationName": "QuickConnectId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "QuickConnect": {
            "shape": "Se3"
          }
        }
      }
    },
    "DescribeRoutingProfile": {
      "http": {
        "method": "GET",
        "requestUri": "/routing-profiles/{InstanceId}/{RoutingProfileId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "RoutingProfileId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "RoutingProfileId": {
            "location": "uri",
            "locationName": "RoutingProfileId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RoutingProfile": {
            "shape": "Se6"
          }
        }
      }
    },
    "DescribeRule": {
      "http": {
        "method": "GET",
        "requestUri": "/rules/{InstanceId}/{RuleId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "RuleId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "RuleId": {
            "location": "uri",
            "locationName": "RuleId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Rule"
        ],
        "members": {
          "Rule": {
            "type": "structure",
            "required": [
              "Name",
              "RuleId",
              "RuleArn",
              "TriggerEventSource",
              "Function",
              "Actions",
              "PublishStatus",
              "CreatedTime",
              "LastUpdatedTime",
              "LastUpdatedBy"
            ],
            "members": {
              "Name": {},
              "RuleId": {},
              "RuleArn": {},
              "TriggerEventSource": {
                "shape": "S6u"
              },
              "Function": {},
              "Actions": {
                "shape": "S6x"
              },
              "PublishStatus": {},
              "CreatedTime": {
                "type": "timestamp"
              },
              "LastUpdatedTime": {
                "type": "timestamp"
              },
              "LastUpdatedBy": {},
              "Tags": {
                "shape": "S34"
              }
            }
          }
        }
      }
    },
    "DescribeSecurityProfile": {
      "http": {
        "method": "GET",
        "requestUri": "/security-profiles/{InstanceId}/{SecurityProfileId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "SecurityProfileId",
          "InstanceId"
        ],
        "members": {
          "SecurityProfileId": {
            "location": "uri",
            "locationName": "SecurityProfileId"
          },
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SecurityProfile": {
            "type": "structure",
            "members": {
              "Id": {},
              "OrganizationResourceId": {},
              "Arn": {},
              "SecurityProfileName": {},
              "Description": {},
              "Tags": {
                "shape": "S34"
              },
              "AllowedAccessControlTags": {
                "shape": "S82"
              },
              "TagRestrictedResources": {
                "shape": "S85"
              },
              "LastModifiedTime": {
                "type": "timestamp"
              },
              "LastModifiedRegion": {},
              "HierarchyRestrictedResources": {
                "shape": "S8c"
              },
              "AllowedAccessControlHierarchyGroupId": {}
            }
          }
        }
      }
    },
    "DescribeTrafficDistributionGroup": {
      "http": {
        "method": "GET",
        "requestUri": "/traffic-distribution-group/{TrafficDistributionGroupId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "TrafficDistributionGroupId"
        ],
        "members": {
          "TrafficDistributionGroupId": {
            "location": "uri",
            "locationName": "TrafficDistributionGroupId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficDistributionGroup": {
            "type": "structure",
            "members": {
              "Id": {},
              "Arn": {},
              "Name": {},
              "Description": {},
              "InstanceArn": {},
              "Status": {},
              "Tags": {
                "shape": "S34"
              },
              "IsDefault": {
                "type": "boolean"
              }
            }
          }
        }
      }
    },
    "DescribeUser": {
      "http": {
        "method": "GET",
        "requestUri": "/users/{InstanceId}/{UserId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "UserId",
          "InstanceId"
        ],
        "members": {
          "UserId": {
            "location": "uri",
            "locationName": "UserId"
          },
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "User": {
            "type": "structure",
            "members": {
              "Id": {},
              "Arn": {},
              "Username": {},
              "IdentityInfo": {
                "shape": "S9l"
              },
              "PhoneConfig": {
                "shape": "S9p"
              },
              "DirectoryUserId": {},
              "SecurityProfileIds": {
                "shape": "S9u"
              },
              "RoutingProfileId": {},
              "HierarchyGroupId": {},
              "Tags": {
                "shape": "S34"
              },
              "LastModifiedTime": {
                "type": "timestamp"
              },
              "LastModifiedRegion": {}
            }
          }
        }
      }
    },
    "DescribeUserHierarchyGroup": {
      "http": {
        "method": "GET",
        "requestUri": "/user-hierarchy-groups/{InstanceId}/{HierarchyGroupId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "HierarchyGroupId",
          "InstanceId"
        ],
        "members": {
          "HierarchyGroupId": {
            "location": "uri",
            "locationName": "HierarchyGroupId"
          },
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HierarchyGroup": {
            "type": "structure",
            "members": {
              "Id": {},
              "Arn": {},
              "Name": {},
              "LevelId": {},
              "HierarchyPath": {
                "type": "structure",
                "members": {
                  "LevelOne": {
                    "shape": "Ses"
                  },
                  "LevelTwo": {
                    "shape": "Ses"
                  },
                  "LevelThree": {
                    "shape": "Ses"
                  },
                  "LevelFour": {
                    "shape": "Ses"
                  },
                  "LevelFive": {
                    "shape": "Ses"
                  }
                }
              },
              "Tags": {
                "shape": "S34"
              },
              "LastModifiedTime": {
                "type": "timestamp"
              },
              "LastModifiedRegion": {}
            }
          }
        }
      }
    },
    "DescribeUserHierarchyStructure": {
      "http": {
        "method": "GET",
        "requestUri": "/user-hierarchy-structure/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HierarchyStructure": {
            "type": "structure",
            "members": {
              "LevelOne": {
                "shape": "Sew"
              },
              "LevelTwo": {
                "shape": "Sew"
              },
              "LevelThree": {
                "shape": "Sew"
              },
              "LevelFour": {
                "shape": "Sew"
              },
              "LevelFive": {
                "shape": "Sew"
              }
            }
          }
        }
      }
    },
    "DescribeView": {
      "http": {
        "method": "GET",
        "requestUri": "/views/{InstanceId}/{ViewId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ViewId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ViewId": {
            "location": "uri",
            "locationName": "ViewId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "View": {
            "shape": "Saa"
          }
        }
      }
    },
    "DescribeVocabulary": {
      "http": {
        "method": "GET",
        "requestUri": "/vocabulary/{InstanceId}/{VocabularyId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "VocabularyId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "VocabularyId": {
            "location": "uri",
            "locationName": "VocabularyId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Vocabulary"
        ],
        "members": {
          "Vocabulary": {
            "type": "structure",
            "required": [
              "Name",
              "Id",
              "Arn",
              "LanguageCode",
              "State",
              "LastModifiedTime"
            ],
            "members": {
              "Name": {},
              "Id": {},
              "Arn": {},
              "LanguageCode": {},
              "State": {},
              "LastModifiedTime": {
                "type": "timestamp"
              },
              "FailureReason": {},
              "Content": {},
              "Tags": {
                "shape": "S34"
              }
            }
          }
        }
      }
    },
    "DisassociateAnalyticsDataSet": {
      "http": {
        "requestUri": "/analytics-data/instance/{InstanceId}/association"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "DataSetId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "DataSetId": {},
          "TargetAccountId": {}
        }
      }
    },
    "DisassociateApprovedOrigin": {
      "http": {
        "method": "DELETE",
        "requestUri": "/instance/{InstanceId}/approved-origin"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "Origin"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "Origin": {
            "location": "querystring",
            "locationName": "origin"
          }
        }
      }
    },
    "DisassociateBot": {
      "http": {
        "requestUri": "/instance/{InstanceId}/bot"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "LexBot": {
            "shape": "Sf"
          },
          "LexV2Bot": {
            "shape": "Si"
          }
        }
      }
    },
    "DisassociateFlow": {
      "http": {
        "method": "DELETE",
        "requestUri": "/flow-associations/{InstanceId}/{ResourceId}/{ResourceType}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ResourceId",
          "ResourceType"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ResourceId": {
            "location": "uri",
            "locationName": "ResourceId"
          },
          "ResourceType": {
            "location": "uri",
            "locationName": "ResourceType"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisassociateInstanceStorageConfig": {
      "http": {
        "method": "DELETE",
        "requestUri": "/instance/{InstanceId}/storage-config/{AssociationId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "AssociationId",
          "ResourceType"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "AssociationId": {
            "location": "uri",
            "locationName": "AssociationId"
          },
          "ResourceType": {
            "location": "querystring",
            "locationName": "resourceType"
          }
        }
      }
    },
    "DisassociateLambdaFunction": {
      "http": {
        "method": "DELETE",
        "requestUri": "/instance/{InstanceId}/lambda-function"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "FunctionArn"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "FunctionArn": {
            "location": "querystring",
            "locationName": "functionArn"
          }
        }
      }
    },
    "DisassociateLexBot": {
      "http": {
        "method": "DELETE",
        "requestUri": "/instance/{InstanceId}/lex-bot"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "BotName",
          "LexRegion"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "BotName": {
            "location": "querystring",
            "locationName": "botName"
          },
          "LexRegion": {
            "location": "querystring",
            "locationName": "lexRegion"
          }
        }
      }
    },
    "DisassociatePhoneNumberContactFlow": {
      "http": {
        "method": "DELETE",
        "requestUri": "/phone-number/{PhoneNumberId}/contact-flow"
      },
      "input": {
        "type": "structure",
        "required": [
          "PhoneNumberId",
          "InstanceId"
        ],
        "members": {
          "PhoneNumberId": {
            "location": "uri",
            "locationName": "PhoneNumberId"
          },
          "InstanceId": {
            "location": "querystring",
            "locationName": "instanceId"
          }
        }
      }
    },
    "DisassociateQueueQuickConnects": {
      "http": {
        "requestUri": "/queues/{InstanceId}/{QueueId}/disassociate-quick-connects"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "QueueId",
          "QuickConnectIds"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "QueueId": {
            "location": "uri",
            "locationName": "QueueId"
          },
          "QuickConnectIds": {
            "shape": "S1f"
          }
        }
      }
    },
    "DisassociateRoutingProfileQueues": {
      "http": {
        "requestUri": "/routing-profiles/{InstanceId}/{RoutingProfileId}/disassociate-queues"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "RoutingProfileId",
          "QueueReferences"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "RoutingProfileId": {
            "location": "uri",
            "locationName": "RoutingProfileId"
          },
          "QueueReferences": {
            "type": "list",
            "member": {
              "shape": "S1l"
            }
          }
        }
      }
    },
    "DisassociateSecurityKey": {
      "http": {
        "method": "DELETE",
        "requestUri": "/instance/{InstanceId}/security-key/{AssociationId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "AssociationId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "AssociationId": {
            "location": "uri",
            "locationName": "AssociationId"
          }
        }
      }
    },
    "DisassociateTrafficDistributionGroupUser": {
      "http": {
        "method": "DELETE",
        "requestUri": "/traffic-distribution-group/{TrafficDistributionGroupId}/user"
      },
      "input": {
        "type": "structure",
        "required": [
          "TrafficDistributionGroupId",
          "UserId",
          "InstanceId"
        ],
        "members": {
          "TrafficDistributionGroupId": {
            "location": "uri",
            "locationName": "TrafficDistributionGroupId"
          },
          "UserId": {
            "location": "querystring",
            "locationName": "UserId"
          },
          "InstanceId": {
            "location": "querystring",
            "locationName": "InstanceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DisassociateUserProficiencies": {
      "http": {
        "requestUri": "/users/{InstanceId}/{UserId}/disassociate-proficiencies"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "UserId",
          "UserProficiencies"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "UserId": {
            "location": "uri",
            "locationName": "UserId"
          },
          "UserProficiencies": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "AttributeName",
                "AttributeValue"
              ],
              "members": {
                "AttributeName": {},
                "AttributeValue": {}
              }
            }
          }
        }
      }
    },
    "DismissUserContact": {
      "http": {
        "requestUri": "/users/{InstanceId}/{UserId}/contact"
      },
      "input": {
        "type": "structure",
        "required": [
          "UserId",
          "InstanceId",
          "ContactId"
        ],
        "members": {
          "UserId": {
            "location": "uri",
            "locationName": "UserId"
          },
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ContactId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "GetContactAttributes": {
      "http": {
        "method": "GET",
        "requestUri": "/contact/attributes/{InstanceId}/{InitialContactId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "InitialContactId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "InitialContactId": {
            "location": "uri",
            "locationName": "InitialContactId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Attributes": {
            "shape": "S2p"
          }
        }
      }
    },
    "GetCurrentMetricData": {
      "http": {
        "requestUri": "/metrics/current/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "Filters",
          "CurrentMetrics"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "Filters": {
            "shape": "Sfs"
          },
          "Groupings": {
            "shape": "Sfy"
          },
          "CurrentMetrics": {
            "type": "list",
            "member": {
              "shape": "Sg1"
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "SortCriteria": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "SortByMetric": {},
                "SortOrder": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MetricResults": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Dimensions": {
                  "shape": "Sgc"
                },
                "Collections": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Metric": {
                        "shape": "Sg1"
                      },
                      "Value": {
                        "type": "double"
                      }
                    }
                  }
                }
              }
            }
          },
          "DataSnapshotTime": {
            "type": "timestamp"
          },
          "ApproximateTotalCount": {
            "type": "long"
          }
        }
      }
    },
    "GetCurrentUserData": {
      "http": {
        "requestUri": "/metrics/userdata/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "Filters"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "Filters": {
            "type": "structure",
            "members": {
              "Queues": {
                "shape": "Sft"
              },
              "ContactFilter": {
                "type": "structure",
                "members": {
                  "ContactStates": {
                    "type": "list",
                    "member": {}
                  }
                }
              },
              "RoutingProfiles": {
                "shape": "Sfv"
              },
              "Agents": {
                "type": "list",
                "member": {}
              },
              "UserHierarchyGroups": {
                "type": "list",
                "member": {}
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "UserDataList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "User": {
                  "type": "structure",
                  "members": {
                    "Id": {},
                    "Arn": {}
                  }
                },
                "RoutingProfile": {
                  "shape": "Sge"
                },
                "HierarchyPath": {
                  "type": "structure",
                  "members": {
                    "LevelOne": {
                      "shape": "Sgv"
                    },
                    "LevelTwo": {
                      "shape": "Sgv"
                    },
                    "LevelThree": {
                      "shape": "Sgv"
                    },
                    "LevelFour": {
                      "shape": "Sgv"
                    },
                    "LevelFive": {
                      "shape": "Sgv"
                    }
                  }
                },
                "Status": {
                  "type": "structure",
                  "members": {
                    "StatusStartTimestamp": {
                      "type": "timestamp"
                    },
                    "StatusArn": {},
                    "StatusName": {}
                  }
                },
                "AvailableSlotsByChannel": {
                  "shape": "Sgx"
                },
                "MaxSlotsByChannel": {
                  "shape": "Sgx"
                },
                "ActiveSlotsByChannel": {
                  "shape": "Sgx"
                },
                "Contacts": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "ContactId": {},
                      "Channel": {},
                      "InitiationMethod": {},
                      "AgentContactState": {},
                      "StateStartTimestamp": {
                        "type": "timestamp"
                      },
                      "ConnectedToAgentTimestamp": {
                        "type": "timestamp"
                      },
                      "Queue": {
                        "shape": "Sgd"
                      }
                    }
                  }
                },
                "NextStatus": {}
              }
            }
          },
          "ApproximateTotalCount": {
            "type": "long"
          }
        }
      }
    },
    "GetFederationToken": {
      "http": {
        "method": "GET",
        "requestUri": "/user/federate/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Credentials": {
            "type": "structure",
            "members": {
              "AccessToken": {
                "shape": "Sh4"
              },
              "AccessTokenExpiration": {
                "type": "timestamp"
              },
              "RefreshToken": {
                "shape": "Sh4"
              },
              "RefreshTokenExpiration": {
                "type": "timestamp"
              }
            },
            "sensitive": true
          },
          "SignInUrl": {},
          "UserArn": {},
          "UserId": {}
        }
      }
    },
    "GetFlowAssociation": {
      "http": {
        "method": "GET",
        "requestUri": "/flow-associations/{InstanceId}/{ResourceId}/{ResourceType}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ResourceId",
          "ResourceType"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ResourceId": {
            "location": "uri",
            "locationName": "ResourceId"
          },
          "ResourceType": {
            "location": "uri",
            "locationName": "ResourceType"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceId": {},
          "FlowId": {},
          "ResourceType": {}
        }
      }
    },
    "GetMetricData": {
      "http": {
        "requestUri": "/metrics/historical/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "StartTime",
          "EndTime",
          "Filters",
          "HistoricalMetrics"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Filters": {
            "shape": "Sfs"
          },
          "Groupings": {
            "shape": "Sfy"
          },
          "HistoricalMetrics": {
            "type": "list",
            "member": {
              "shape": "Sh9"
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MetricResults": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Dimensions": {
                  "shape": "Sgc"
                },
                "Collections": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Metric": {
                        "shape": "Sh9"
                      },
                      "Value": {
                        "type": "double"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "GetMetricDataV2": {
      "http": {
        "requestUri": "/metrics/data"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "StartTime",
          "EndTime",
          "Filters",
          "Metrics"
        ],
        "members": {
          "ResourceArn": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Interval": {
            "type": "structure",
            "members": {
              "TimeZone": {},
              "IntervalPeriod": {}
            }
          },
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "FilterKey": {},
                "FilterValues": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "Groupings": {
            "type": "list",
            "member": {}
          },
          "Metrics": {
            "type": "list",
            "member": {
              "shape": "Shu"
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MetricResults": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Dimensions": {
                  "type": "map",
                  "key": {},
                  "value": {}
                },
                "MetricInterval": {
                  "type": "structure",
                  "members": {
                    "Interval": {},
                    "StartTime": {
                      "type": "timestamp"
                    },
                    "EndTime": {
                      "type": "timestamp"
                    }
                  }
                },
                "Collections": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Metric": {
                        "shape": "Shu"
                      },
                      "Value": {
                        "type": "double"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "GetPromptFile": {
      "http": {
        "method": "GET",
        "requestUri": "/prompts/{InstanceId}/{PromptId}/file"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "PromptId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "PromptId": {
            "location": "uri",
            "locationName": "PromptId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PromptPresignedUrl": {},
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "LastModifiedRegion": {}
        }
      }
    },
    "GetTaskTemplate": {
      "http": {
        "method": "GET",
        "requestUri": "/instance/{InstanceId}/task/template/{TaskTemplateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "TaskTemplateId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "TaskTemplateId": {
            "location": "uri",
            "locationName": "TaskTemplateId"
          },
          "SnapshotVersion": {
            "location": "querystring",
            "locationName": "snapshotVersion"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Id",
          "Arn",
          "Name"
        ],
        "members": {
          "InstanceId": {},
          "Id": {},
          "Arn": {},
          "Name": {},
          "Description": {},
          "ContactFlowId": {},
          "Constraints": {
            "shape": "S8k"
          },
          "Defaults": {
            "shape": "S8t"
          },
          "Fields": {
            "shape": "S8y"
          },
          "Status": {},
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "CreatedTime": {
            "type": "timestamp"
          },
          "Tags": {
            "shape": "S34"
          }
        }
      }
    },
    "GetTrafficDistribution": {
      "http": {
        "method": "GET",
        "requestUri": "/traffic-distribution/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TelephonyConfig": {
            "shape": "Sij"
          },
          "Id": {},
          "Arn": {},
          "SignInConfig": {
            "shape": "Sio"
          },
          "AgentConfig": {
            "shape": "Sir"
          }
        }
      }
    },
    "ImportPhoneNumber": {
      "http": {
        "requestUri": "/phone-number/import"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "SourcePhoneNumberArn"
        ],
        "members": {
          "InstanceId": {},
          "SourcePhoneNumberArn": {},
          "PhoneNumberDescription": {},
          "Tags": {
            "shape": "S34"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PhoneNumberId": {},
          "PhoneNumberArn": {}
        }
      }
    },
    "ListAgentStatuses": {
      "http": {
        "method": "GET",
        "requestUri": "/agent-status/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "AgentStatusTypes": {
            "location": "querystring",
            "locationName": "AgentStatusTypes",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "AgentStatusSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Arn": {},
                "Name": {},
                "Type": {},
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "LastModifiedRegion": {}
              }
            }
          }
        }
      }
    },
    "ListAnalyticsDataAssociations": {
      "http": {
        "method": "GET",
        "requestUri": "/analytics-data/instance/{InstanceId}/association"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "DataSetId": {
            "location": "querystring",
            "locationName": "DataSetId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Results": {
            "shape": "S25"
          },
          "NextToken": {}
        }
      }
    },
    "ListApprovedOrigins": {
      "http": {
        "method": "GET",
        "requestUri": "/instance/{InstanceId}/approved-origins"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Origins": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      }
    },
    "ListBots": {
      "http": {
        "method": "GET",
        "requestUri": "/instance/{InstanceId}/bots"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "LexVersion"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "LexVersion": {
            "location": "querystring",
            "locationName": "lexVersion"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LexBots": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "LexBot": {
                  "shape": "Sf"
                },
                "LexV2Bot": {
                  "shape": "Si"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListContactEvaluations": {
      "http": {
        "method": "GET",
        "requestUri": "/contact-evaluations/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ContactId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ContactId": {
            "location": "querystring",
            "locationName": "contactId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "EvaluationSummaryList"
        ],
        "members": {
          "EvaluationSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "EvaluationId",
                "EvaluationArn",
                "EvaluationFormTitle",
                "EvaluationFormId",
                "Status",
                "EvaluatorArn",
                "CreatedTime",
                "LastModifiedTime"
              ],
              "members": {
                "EvaluationId": {},
                "EvaluationArn": {},
                "EvaluationFormTitle": {},
                "EvaluationFormId": {},
                "Status": {},
                "EvaluatorArn": {},
                "Score": {
                  "shape": "Scc"
                },
                "CreatedTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListContactFlowModules": {
      "http": {
        "method": "GET",
        "requestUri": "/contact-flow-modules-summary/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "ContactFlowModuleState": {
            "location": "querystring",
            "locationName": "state"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ContactFlowModulesSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Arn": {},
                "Name": {},
                "State": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListContactFlows": {
      "http": {
        "method": "GET",
        "requestUri": "/contact-flows-summary/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ContactFlowTypes": {
            "location": "querystring",
            "locationName": "contactFlowTypes",
            "type": "list",
            "member": {}
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ContactFlowSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Arn": {},
                "Name": {},
                "ContactFlowType": {},
                "ContactFlowState": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListContactReferences": {
      "http": {
        "method": "GET",
        "requestUri": "/contact/references/{InstanceId}/{ContactId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ContactId",
          "ReferenceTypes"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ContactId": {
            "location": "uri",
            "locationName": "ContactId"
          },
          "ReferenceTypes": {
            "location": "querystring",
            "locationName": "referenceTypes",
            "type": "list",
            "member": {}
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReferenceSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Url": {
                  "type": "structure",
                  "members": {
                    "Name": {},
                    "Value": {}
                  }
                },
                "Attachment": {
                  "type": "structure",
                  "members": {
                    "Name": {},
                    "Value": {},
                    "Status": {}
                  }
                },
                "String": {
                  "type": "structure",
                  "members": {
                    "Name": {},
                    "Value": {}
                  }
                },
                "Number": {
                  "type": "structure",
                  "members": {
                    "Name": {},
                    "Value": {}
                  }
                },
                "Date": {
                  "type": "structure",
                  "members": {
                    "Name": {},
                    "Value": {}
                  }
                },
                "Email": {
                  "type": "structure",
                  "members": {
                    "Name": {},
                    "Value": {}
                  }
                }
              },
              "union": true
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListDefaultVocabularies": {
      "http": {
        "requestUri": "/default-vocabulary-summary/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "LanguageCode": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DefaultVocabularyList"
        ],
        "members": {
          "DefaultVocabularyList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "InstanceId",
                "LanguageCode",
                "VocabularyId",
                "VocabularyName"
              ],
              "members": {
                "InstanceId": {},
                "LanguageCode": {},
                "VocabularyId": {},
                "VocabularyName": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListEvaluationFormVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/evaluation-forms/{InstanceId}/{EvaluationFormId}/versions"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "EvaluationFormId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "EvaluationFormId": {
            "location": "uri",
            "locationName": "EvaluationFormId"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "EvaluationFormVersionSummaryList"
        ],
        "members": {
          "EvaluationFormVersionSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "EvaluationFormArn",
                "EvaluationFormId",
                "EvaluationFormVersion",
                "Locked",
                "Status",
                "CreatedTime",
                "CreatedBy",
                "LastModifiedTime",
                "LastModifiedBy"
              ],
              "members": {
                "EvaluationFormArn": {},
                "EvaluationFormId": {},
                "EvaluationFormVersion": {
                  "type": "integer"
                },
                "Locked": {
                  "type": "boolean"
                },
                "Status": {},
                "CreatedTime": {
                  "type": "timestamp"
                },
                "CreatedBy": {},
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "LastModifiedBy": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListEvaluationForms": {
      "http": {
        "method": "GET",
        "requestUri": "/evaluation-forms/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "EvaluationFormSummaryList"
        ],
        "members": {
          "EvaluationFormSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "EvaluationFormId",
                "EvaluationFormArn",
                "Title",
                "CreatedTime",
                "CreatedBy",
                "LastModifiedTime",
                "LastModifiedBy",
                "LatestVersion"
              ],
              "members": {
                "EvaluationFormId": {},
                "EvaluationFormArn": {},
                "Title": {},
                "CreatedTime": {
                  "type": "timestamp"
                },
                "CreatedBy": {},
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "LastModifiedBy": {},
                "LastActivatedTime": {
                  "type": "timestamp"
                },
                "LastActivatedBy": {},
                "LatestVersion": {
                  "type": "integer"
                },
                "ActiveVersion": {
                  "type": "integer"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListFlowAssociations": {
      "http": {
        "method": "GET",
        "requestUri": "/flow-associations-summary/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ResourceType": {
            "location": "querystring",
            "locationName": "ResourceType"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FlowAssociationSummaryList": {
            "shape": "S2f"
          },
          "NextToken": {}
        }
      }
    },
    "ListHoursOfOperations": {
      "http": {
        "method": "GET",
        "requestUri": "/hours-of-operations-summary/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HoursOfOperationSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Arn": {},
                "Name": {},
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "LastModifiedRegion": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListInstanceAttributes": {
      "http": {
        "method": "GET",
        "requestUri": "/instance/{InstanceId}/attributes"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Attributes": {
            "type": "list",
            "member": {
              "shape": "Sdf"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListInstanceStorageConfigs": {
      "http": {
        "method": "GET",
        "requestUri": "/instance/{InstanceId}/storage-configs"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ResourceType"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ResourceType": {
            "location": "querystring",
            "locationName": "resourceType"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StorageConfigs": {
            "type": "list",
            "member": {
              "shape": "St"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListInstances": {
      "http": {
        "method": "GET",
        "requestUri": "/instance"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Arn": {},
                "IdentityManagementType": {},
                "InstanceAlias": {
                  "shape": "S57"
                },
                "CreatedTime": {
                  "type": "timestamp"
                },
                "ServiceRole": {},
                "InstanceStatus": {},
                "InboundCallsEnabled": {
                  "type": "boolean"
                },
                "OutboundCallsEnabled": {
                  "type": "boolean"
                },
                "InstanceAccessUrl": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListIntegrationAssociations": {
      "http": {
        "method": "GET",
        "requestUri": "/instance/{InstanceId}/integration-associations"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "IntegrationType": {
            "location": "querystring",
            "locationName": "integrationType"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "IntegrationArn": {
            "location": "querystring",
            "locationName": "integrationArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IntegrationAssociationSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "IntegrationAssociationId": {},
                "IntegrationAssociationArn": {},
                "InstanceId": {},
                "IntegrationType": {},
                "IntegrationArn": {},
                "SourceApplicationUrl": {},
                "SourceApplicationName": {},
                "SourceType": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListLambdaFunctions": {
      "http": {
        "method": "GET",
        "requestUri": "/instance/{InstanceId}/lambda-functions"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LambdaFunctions": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      }
    },
    "ListLexBots": {
      "http": {
        "method": "GET",
        "requestUri": "/instance/{InstanceId}/lex-bots"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LexBots": {
            "type": "list",
            "member": {
              "shape": "Sf"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListPhoneNumbers": {
      "http": {
        "method": "GET",
        "requestUri": "/phone-numbers-summary/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "PhoneNumberTypes": {
            "shape": "Sl7",
            "location": "querystring",
            "locationName": "phoneNumberTypes"
          },
          "PhoneNumberCountryCodes": {
            "shape": "Sl8",
            "location": "querystring",
            "locationName": "phoneNumberCountryCodes"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PhoneNumberSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Arn": {},
                "PhoneNumber": {},
                "PhoneNumberType": {},
                "PhoneNumberCountryCode": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListPhoneNumbersV2": {
      "http": {
        "requestUri": "/phone-number/list"
      },
      "input": {
        "type": "structure",
        "members": {
          "TargetArn": {},
          "InstanceId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "PhoneNumberCountryCodes": {
            "shape": "Sl8"
          },
          "PhoneNumberTypes": {
            "shape": "Sl7"
          },
          "PhoneNumberPrefix": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "ListPhoneNumbersSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PhoneNumberId": {},
                "PhoneNumberArn": {},
                "PhoneNumber": {},
                "PhoneNumberCountryCode": {},
                "PhoneNumberType": {},
                "TargetArn": {},
                "InstanceId": {},
                "PhoneNumberDescription": {},
                "SourcePhoneNumberArn": {}
              }
            }
          }
        }
      }
    },
    "ListPredefinedAttributes": {
      "http": {
        "method": "GET",
        "requestUri": "/predefined-attributes/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "PredefinedAttributeSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "LastModifiedRegion": {}
              }
            }
          }
        }
      }
    },
    "ListPrompts": {
      "http": {
        "method": "GET",
        "requestUri": "/prompts-summary/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PromptSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Arn": {},
                "Name": {},
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "LastModifiedRegion": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListQueueQuickConnects": {
      "http": {
        "method": "GET",
        "requestUri": "/queues/{InstanceId}/{QueueId}/quick-connects"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "QueueId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "QueueId": {
            "location": "uri",
            "locationName": "QueueId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "QuickConnectSummaryList": {
            "shape": "Slt"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "LastModifiedRegion": {}
        }
      }
    },
    "ListQueues": {
      "http": {
        "method": "GET",
        "requestUri": "/queues-summary/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "QueueTypes": {
            "location": "querystring",
            "locationName": "queueTypes",
            "type": "list",
            "member": {}
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "QueueSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Arn": {},
                "Name": {},
                "QueueType": {},
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "LastModifiedRegion": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListQuickConnects": {
      "http": {
        "method": "GET",
        "requestUri": "/quick-connects/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "QuickConnectTypes": {
            "location": "querystring",
            "locationName": "QuickConnectTypes",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "QuickConnectSummaryList": {
            "shape": "Slt"
          },
          "NextToken": {}
        }
      }
    },
    "ListRealtimeContactAnalysisSegmentsV2": {
      "http": {
        "requestUri": "/contact/list-real-time-analysis-segments-v2/{InstanceId}/{ContactId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ContactId",
          "OutputType",
          "SegmentTypes"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ContactId": {
            "location": "uri",
            "locationName": "ContactId"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "OutputType": {},
          "SegmentTypes": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Channel",
          "Status",
          "Segments"
        ],
        "members": {
          "Channel": {},
          "Status": {},
          "Segments": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Transcript": {
                  "type": "structure",
                  "required": [
                    "Id",
                    "ParticipantId",
                    "ParticipantRole",
                    "Content",
                    "Time"
                  ],
                  "members": {
                    "Id": {},
                    "ParticipantId": {},
                    "ParticipantRole": {},
                    "DisplayName": {},
                    "Content": {},
                    "ContentType": {},
                    "Time": {
                      "shape": "Smi"
                    },
                    "Redaction": {
                      "type": "structure",
                      "members": {
                        "CharacterOffsets": {
                          "type": "list",
                          "member": {
                            "shape": "Smm"
                          }
                        }
                      }
                    },
                    "Sentiment": {}
                  }
                },
                "Categories": {
                  "type": "structure",
                  "required": [
                    "MatchedDetails"
                  ],
                  "members": {
                    "MatchedDetails": {
                      "type": "map",
                      "key": {},
                      "value": {
                        "type": "structure",
                        "required": [
                          "PointsOfInterest"
                        ],
                        "members": {
                          "PointsOfInterest": {
                            "type": "list",
                            "member": {
                              "type": "structure",
                              "members": {
                                "TranscriptItems": {
                                  "type": "list",
                                  "member": {
                                    "type": "structure",
                                    "required": [
                                      "Id"
                                    ],
                                    "members": {
                                      "Id": {},
                                      "CharacterOffsets": {
                                        "shape": "Smm"
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                },
                "Issues": {
                  "type": "structure",
                  "required": [
                    "IssuesDetected"
                  ],
                  "members": {
                    "IssuesDetected": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "required": [
                          "TranscriptItems"
                        ],
                        "members": {
                          "TranscriptItems": {
                            "type": "list",
                            "member": {
                              "type": "structure",
                              "required": [
                                "Id"
                              ],
                              "members": {
                                "Content": {},
                                "Id": {},
                                "CharacterOffsets": {
                                  "shape": "Smm"
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                },
                "Event": {
                  "type": "structure",
                  "required": [
                    "Id",
                    "EventType",
                    "Time"
                  ],
                  "members": {
                    "Id": {},
                    "ParticipantId": {},
                    "ParticipantRole": {},
                    "DisplayName": {},
                    "EventType": {},
                    "Time": {
                      "shape": "Smi"
                    }
                  }
                },
                "Attachments": {
                  "type": "structure",
                  "required": [
                    "Id",
                    "ParticipantId",
                    "ParticipantRole",
                    "Attachments",
                    "Time"
                  ],
                  "members": {
                    "Id": {},
                    "ParticipantId": {},
                    "ParticipantRole": {},
                    "DisplayName": {},
                    "Attachments": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "required": [
                          "AttachmentName",
                          "AttachmentId"
                        ],
                        "members": {
                          "AttachmentName": {},
                          "ContentType": {},
                          "AttachmentId": {},
                          "Status": {}
                        }
                      }
                    },
                    "Time": {
                      "shape": "Smi"
                    }
                  }
                }
              },
              "union": true
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListRoutingProfileQueues": {
      "http": {
        "method": "GET",
        "requestUri": "/routing-profiles/{InstanceId}/{RoutingProfileId}/queues"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "RoutingProfileId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "RoutingProfileId": {
            "location": "uri",
            "locationName": "RoutingProfileId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "RoutingProfileQueueConfigSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "QueueId",
                "QueueArn",
                "QueueName",
                "Priority",
                "Delay",
                "Channel"
              ],
              "members": {
                "QueueId": {},
                "QueueArn": {},
                "QueueName": {},
                "Priority": {
                  "type": "integer"
                },
                "Delay": {
                  "type": "integer"
                },
                "Channel": {}
              }
            }
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "LastModifiedRegion": {}
        }
      }
    },
    "ListRoutingProfiles": {
      "http": {
        "method": "GET",
        "requestUri": "/routing-profiles-summary/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RoutingProfileSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Arn": {},
                "Name": {},
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "LastModifiedRegion": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListRules": {
      "http": {
        "method": "GET",
        "requestUri": "/rules/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "PublishStatus": {
            "location": "querystring",
            "locationName": "publishStatus"
          },
          "EventSourceName": {
            "location": "querystring",
            "locationName": "eventSourceName"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "RuleSummaryList"
        ],
        "members": {
          "RuleSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Name",
                "RuleId",
                "RuleArn",
                "EventSourceName",
                "PublishStatus",
                "ActionSummaries",
                "CreatedTime",
                "LastUpdatedTime"
              ],
              "members": {
                "Name": {},
                "RuleId": {},
                "RuleArn": {},
                "EventSourceName": {},
                "PublishStatus": {},
                "ActionSummaries": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "ActionType"
                    ],
                    "members": {
                      "ActionType": {}
                    }
                  }
                },
                "CreatedTime": {
                  "type": "timestamp"
                },
                "LastUpdatedTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListSecurityKeys": {
      "http": {
        "method": "GET",
        "requestUri": "/instance/{InstanceId}/security-keys"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SecurityKeys": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AssociationId": {},
                "Key": {},
                "CreationTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListSecurityProfileApplications": {
      "http": {
        "method": "GET",
        "requestUri": "/security-profiles-applications/{InstanceId}/{SecurityProfileId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "SecurityProfileId",
          "InstanceId"
        ],
        "members": {
          "SecurityProfileId": {
            "location": "uri",
            "locationName": "SecurityProfileId"
          },
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Applications": {
            "shape": "S87"
          },
          "NextToken": {},
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "LastModifiedRegion": {}
        }
      }
    },
    "ListSecurityProfilePermissions": {
      "http": {
        "method": "GET",
        "requestUri": "/security-profiles-permissions/{InstanceId}/{SecurityProfileId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "SecurityProfileId",
          "InstanceId"
        ],
        "members": {
          "SecurityProfileId": {
            "location": "uri",
            "locationName": "SecurityProfileId"
          },
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Permissions": {
            "shape": "S80"
          },
          "NextToken": {},
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "LastModifiedRegion": {}
        }
      }
    },
    "ListSecurityProfiles": {
      "http": {
        "method": "GET",
        "requestUri": "/security-profiles-summary/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SecurityProfileSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Arn": {},
                "Name": {},
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "LastModifiedRegion": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "S34"
          }
        }
      }
    },
    "ListTaskTemplates": {
      "http": {
        "method": "GET",
        "requestUri": "/instance/{InstanceId}/task/template"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "Status": {
            "location": "querystring",
            "locationName": "status"
          },
          "Name": {
            "location": "querystring",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TaskTemplates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Arn": {},
                "Name": {},
                "Description": {},
                "Status": {},
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "CreatedTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTrafficDistributionGroupUsers": {
      "http": {
        "method": "GET",
        "requestUri": "/traffic-distribution-group/{TrafficDistributionGroupId}/user"
      },
      "input": {
        "type": "structure",
        "required": [
          "TrafficDistributionGroupId"
        ],
        "members": {
          "TrafficDistributionGroupId": {
            "location": "uri",
            "locationName": "TrafficDistributionGroupId"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "TrafficDistributionGroupUserSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "UserId": {}
              }
            }
          }
        }
      }
    },
    "ListTrafficDistributionGroups": {
      "http": {
        "method": "GET",
        "requestUri": "/traffic-distribution-groups"
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "InstanceId": {
            "location": "querystring",
            "locationName": "instanceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "TrafficDistributionGroupSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Arn": {},
                "Name": {},
                "InstanceArn": {},
                "Status": {},
                "IsDefault": {
                  "type": "boolean"
                }
              }
            }
          }
        }
      }
    },
    "ListUseCases": {
      "http": {
        "method": "GET",
        "requestUri": "/instance/{InstanceId}/integration-associations/{IntegrationAssociationId}/use-cases"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "IntegrationAssociationId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "IntegrationAssociationId": {
            "location": "uri",
            "locationName": "IntegrationAssociationId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UseCaseSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "UseCaseId": {},
                "UseCaseArn": {},
                "UseCaseType": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListUserHierarchyGroups": {
      "http": {
        "method": "GET",
        "requestUri": "/user-hierarchy-groups-summary/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserHierarchyGroupSummaryList": {
            "type": "list",
            "member": {
              "shape": "Ses"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListUserProficiencies": {
      "http": {
        "method": "GET",
        "requestUri": "/users/{InstanceId}/{UserId}/proficiencies"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "UserId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "UserId": {
            "location": "uri",
            "locationName": "UserId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "UserProficiencyList": {
            "shape": "S1x"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "LastModifiedRegion": {}
        }
      }
    },
    "ListUsers": {
      "http": {
        "method": "GET",
        "requestUri": "/users-summary/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Arn": {},
                "Username": {},
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "LastModifiedRegion": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListViewVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/views/{InstanceId}/{ViewId}/versions"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ViewId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ViewId": {
            "location": "uri",
            "locationName": "ViewId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ViewVersionSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Arn": {},
                "Description": {},
                "Name": {
                  "shape": "Sa8"
                },
                "Type": {},
                "Version": {
                  "type": "integer"
                },
                "VersionDescription": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListViews": {
      "http": {
        "method": "GET",
        "requestUri": "/views/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "Type": {
            "location": "querystring",
            "locationName": "type"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ViewsSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Arn": {},
                "Name": {
                  "shape": "Sa8"
                },
                "Type": {},
                "Status": {},
                "Description": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "MonitorContact": {
      "http": {
        "requestUri": "/contact/monitor"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ContactId",
          "UserId"
        ],
        "members": {
          "InstanceId": {},
          "ContactId": {},
          "UserId": {},
          "AllowedMonitorCapabilities": {
            "type": "list",
            "member": {}
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ContactId": {},
          "ContactArn": {}
        }
      }
    },
    "PauseContact": {
      "http": {
        "requestUri": "/contact/pause"
      },
      "input": {
        "type": "structure",
        "required": [
          "ContactId",
          "InstanceId"
        ],
        "members": {
          "ContactId": {},
          "InstanceId": {},
          "ContactFlowId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "PutUserStatus": {
      "http": {
        "method": "PUT",
        "requestUri": "/users/{InstanceId}/{UserId}/status"
      },
      "input": {
        "type": "structure",
        "required": [
          "UserId",
          "InstanceId",
          "AgentStatusId"
        ],
        "members": {
          "UserId": {
            "location": "uri",
            "locationName": "UserId"
          },
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "AgentStatusId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ReleasePhoneNumber": {
      "http": {
        "method": "DELETE",
        "requestUri": "/phone-number/{PhoneNumberId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "PhoneNumberId"
        ],
        "members": {
          "PhoneNumberId": {
            "location": "uri",
            "locationName": "PhoneNumberId"
          },
          "ClientToken": {
            "idempotencyToken": true,
            "location": "querystring",
            "locationName": "clientToken"
          }
        }
      }
    },
    "ReplicateInstance": {
      "http": {
        "requestUri": "/instance/{InstanceId}/replicate"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ReplicaRegion",
          "ReplicaAlias"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ReplicaRegion": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "ReplicaAlias": {
            "shape": "S57"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Id": {},
          "Arn": {}
        }
      }
    },
    "ResumeContact": {
      "http": {
        "requestUri": "/contact/resume"
      },
      "input": {
        "type": "structure",
        "required": [
          "ContactId",
          "InstanceId"
        ],
        "members": {
          "ContactId": {},
          "InstanceId": {},
          "ContactFlowId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ResumeContactRecording": {
      "http": {
        "requestUri": "/contact/resume-recording"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ContactId",
          "InitialContactId"
        ],
        "members": {
          "InstanceId": {},
          "ContactId": {},
          "InitialContactId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "SearchAvailablePhoneNumbers": {
      "http": {
        "requestUri": "/phone-number/search-available"
      },
      "input": {
        "type": "structure",
        "required": [
          "PhoneNumberCountryCode",
          "PhoneNumberType"
        ],
        "members": {
          "TargetArn": {},
          "InstanceId": {},
          "PhoneNumberCountryCode": {},
          "PhoneNumberType": {},
          "PhoneNumberPrefix": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "AvailableNumbersList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PhoneNumber": {},
                "PhoneNumberCountryCode": {},
                "PhoneNumberType": {}
              }
            }
          }
        }
      }
    },
    "SearchContacts": {
      "http": {
        "requestUri": "/search-contacts"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "TimeRange"
        ],
        "members": {
          "InstanceId": {},
          "TimeRange": {
            "type": "structure",
            "required": [
              "Type",
              "StartTime",
              "EndTime"
            ],
            "members": {
              "Type": {},
              "StartTime": {
                "type": "timestamp"
              },
              "EndTime": {
                "type": "timestamp"
              }
            }
          },
          "SearchCriteria": {
            "type": "structure",
            "members": {
              "AgentIds": {
                "type": "list",
                "member": {}
              },
              "AgentHierarchyGroups": {
                "type": "structure",
                "members": {
                  "L1Ids": {
                    "shape": "Spt"
                  },
                  "L2Ids": {
                    "shape": "Spt"
                  },
                  "L3Ids": {
                    "shape": "Spt"
                  },
                  "L4Ids": {
                    "shape": "Spt"
                  },
                  "L5Ids": {
                    "shape": "Spt"
                  }
                }
              },
              "Channels": {
                "type": "list",
                "member": {}
              },
              "ContactAnalysis": {
                "type": "structure",
                "members": {
                  "Transcript": {
                    "type": "structure",
                    "required": [
                      "Criteria"
                    ],
                    "members": {
                      "Criteria": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "required": [
                            "ParticipantRole",
                            "SearchText",
                            "MatchType"
                          ],
                          "members": {
                            "ParticipantRole": {},
                            "SearchText": {
                              "type": "list",
                              "member": {
                                "type": "string",
                                "sensitive": true
                              }
                            },
                            "MatchType": {}
                          }
                        }
                      },
                      "MatchType": {}
                    }
                  }
                }
              },
              "InitiationMethods": {
                "type": "list",
                "member": {}
              },
              "QueueIds": {
                "type": "list",
                "member": {}
              },
              "SearchableContactAttributes": {
                "type": "structure",
                "required": [
                  "Criteria"
                ],
                "members": {
                  "Criteria": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "required": [
                        "Key",
                        "Values"
                      ],
                      "members": {
                        "Key": {
                          "type": "string",
                          "sensitive": true
                        },
                        "Values": {
                          "type": "list",
                          "member": {
                            "type": "string",
                            "sensitive": true
                          }
                        }
                      }
                    }
                  },
                  "MatchType": {}
                }
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Sort": {
            "type": "structure",
            "required": [
              "FieldName",
              "Order"
            ],
            "members": {
              "FieldName": {},
              "Order": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Contacts"
        ],
        "members": {
          "Contacts": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "Id": {},
                "InitialContactId": {},
                "PreviousContactId": {},
                "InitiationMethod": {},
                "Channel": {},
                "QueueInfo": {
                  "type": "structure",
                  "members": {
                    "Id": {},
                    "EnqueueTimestamp": {
                      "type": "timestamp"
                    }
                  }
                },
                "AgentInfo": {
                  "type": "structure",
                  "members": {
                    "Id": {},
                    "ConnectedToAgentTimestamp": {
                      "type": "timestamp"
                    }
                  }
                },
                "InitiationTimestamp": {
                  "type": "timestamp"
                },
                "DisconnectTimestamp": {
                  "type": "timestamp"
                },
                "ScheduledTimestamp": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {},
          "TotalCount": {
            "type": "long"
          }
        }
      }
    },
    "SearchHoursOfOperations": {
      "http": {
        "requestUri": "/search-hours-of-operations"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "SearchFilter": {
            "type": "structure",
            "members": {
              "TagFilter": {
                "shape": "Sqk"
              }
            }
          },
          "SearchCriteria": {
            "shape": "Sqo"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HoursOfOperations": {
            "type": "list",
            "member": {
              "shape": "Sd5"
            }
          },
          "NextToken": {},
          "ApproximateTotalCount": {
            "type": "long"
          }
        }
      }
    },
    "SearchPredefinedAttributes": {
      "http": {
        "requestUri": "/search-predefined-attributes"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "SearchCriteria": {
            "shape": "Sqv"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PredefinedAttributes": {
            "type": "list",
            "member": {
              "shape": "Sdt"
            }
          },
          "NextToken": {},
          "ApproximateTotalCount": {
            "type": "long"
          }
        }
      }
    },
    "SearchPrompts": {
      "http": {
        "requestUri": "/search-prompts"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "SearchFilter": {
            "type": "structure",
            "members": {
              "TagFilter": {
                "shape": "Sqk"
              }
            }
          },
          "SearchCriteria": {
            "shape": "Sr1"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Prompts": {
            "type": "list",
            "member": {
              "shape": "Sdw"
            }
          },
          "NextToken": {},
          "ApproximateTotalCount": {
            "type": "long"
          }
        }
      }
    },
    "SearchQueues": {
      "http": {
        "requestUri": "/search-queues"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "SearchFilter": {
            "type": "structure",
            "members": {
              "TagFilter": {
                "shape": "Sqk"
              }
            }
          },
          "SearchCriteria": {
            "shape": "Sr8"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Queues": {
            "type": "list",
            "member": {
              "shape": "Sdz"
            }
          },
          "NextToken": {},
          "ApproximateTotalCount": {
            "type": "long"
          }
        }
      }
    },
    "SearchQuickConnects": {
      "http": {
        "requestUri": "/search-quick-connects"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "SearchFilter": {
            "type": "structure",
            "members": {
              "TagFilter": {
                "shape": "Sqk"
              }
            }
          },
          "SearchCriteria": {
            "shape": "Srf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "QuickConnects": {
            "type": "list",
            "member": {
              "shape": "Se3"
            }
          },
          "NextToken": {},
          "ApproximateTotalCount": {
            "type": "long"
          }
        }
      }
    },
    "SearchResourceTags": {
      "http": {
        "requestUri": "/search-resource-tags"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {},
          "ResourceTypes": {
            "type": "list",
            "member": {}
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "SearchCriteria": {
            "type": "structure",
            "members": {
              "TagSearchCondition": {
                "type": "structure",
                "members": {
                  "tagKey": {},
                  "tagValue": {},
                  "tagKeyComparisonType": {},
                  "tagValueComparisonType": {}
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "key": {},
                "value": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "SearchRoutingProfiles": {
      "http": {
        "requestUri": "/search-routing-profiles"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "SearchFilter": {
            "type": "structure",
            "members": {
              "TagFilter": {
                "shape": "Sqk"
              }
            }
          },
          "SearchCriteria": {
            "shape": "Sru"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RoutingProfiles": {
            "type": "list",
            "member": {
              "shape": "Se6"
            }
          },
          "NextToken": {},
          "ApproximateTotalCount": {
            "type": "long"
          }
        }
      }
    },
    "SearchSecurityProfiles": {
      "http": {
        "requestUri": "/search-security-profiles"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "SearchCriteria": {
            "shape": "Srz"
          },
          "SearchFilter": {
            "type": "structure",
            "members": {
              "TagFilter": {
                "shape": "Sqk"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SecurityProfiles": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "OrganizationResourceId": {},
                "Arn": {},
                "SecurityProfileName": {},
                "Description": {},
                "Tags": {
                  "shape": "S34"
                }
              }
            }
          },
          "NextToken": {},
          "ApproximateTotalCount": {
            "type": "long"
          }
        }
      }
    },
    "SearchUsers": {
      "http": {
        "requestUri": "/search-users"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "SearchFilter": {
            "type": "structure",
            "members": {
              "TagFilter": {
                "shape": "Sqk"
              },
              "UserAttributeFilter": {
                "type": "structure",
                "members": {
                  "OrConditions": {
                    "type": "list",
                    "member": {
                      "shape": "Ss9"
                    }
                  },
                  "AndCondition": {
                    "shape": "Ss9"
                  },
                  "TagCondition": {
                    "shape": "Sqn"
                  },
                  "HierarchyGroupCondition": {
                    "shape": "Ssa"
                  }
                }
              }
            }
          },
          "SearchCriteria": {
            "shape": "Ssc"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Users": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "DirectoryUserId": {},
                "HierarchyGroupId": {},
                "Id": {},
                "IdentityInfo": {
                  "type": "structure",
                  "members": {
                    "FirstName": {
                      "shape": "S9m"
                    },
                    "LastName": {
                      "shape": "S9n"
                    }
                  }
                },
                "PhoneConfig": {
                  "shape": "S9p"
                },
                "RoutingProfileId": {},
                "SecurityProfileIds": {
                  "shape": "S9u"
                },
                "Tags": {
                  "shape": "S34"
                },
                "Username": {}
              }
            }
          },
          "NextToken": {},
          "ApproximateTotalCount": {
            "type": "long"
          }
        }
      }
    },
    "SearchVocabularies": {
      "http": {
        "requestUri": "/vocabulary-summary/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "State": {},
          "NameStartsWith": {},
          "LanguageCode": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VocabularySummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Name",
                "Id",
                "Arn",
                "LanguageCode",
                "State",
                "LastModifiedTime"
              ],
              "members": {
                "Name": {},
                "Id": {},
                "Arn": {},
                "LanguageCode": {},
                "State": {},
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "FailureReason": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "SendChatIntegrationEvent": {
      "http": {
        "requestUri": "/chat-integration-event"
      },
      "input": {
        "type": "structure",
        "required": [
          "SourceId",
          "DestinationId",
          "Event"
        ],
        "members": {
          "SourceId": {},
          "DestinationId": {},
          "Subtype": {},
          "Event": {
            "type": "structure",
            "required": [
              "Type"
            ],
            "members": {
              "Type": {},
              "ContentType": {},
              "Content": {}
            }
          },
          "NewSessionDetails": {
            "type": "structure",
            "members": {
              "SupportedMessagingContentTypes": {
                "shape": "Ssv"
              },
              "ParticipantDetails": {
                "shape": "Ssx"
              },
              "Attributes": {
                "shape": "S2p"
              },
              "StreamingConfiguration": {
                "shape": "Ssy"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InitialContactId": {},
          "NewChatCreated": {
            "type": "boolean"
          }
        }
      }
    },
    "StartChatContact": {
      "http": {
        "method": "PUT",
        "requestUri": "/contact/chat"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ContactFlowId",
          "ParticipantDetails"
        ],
        "members": {
          "InstanceId": {},
          "ContactFlowId": {},
          "Attributes": {
            "shape": "S2p"
          },
          "ParticipantDetails": {
            "shape": "Ssx"
          },
          "InitialMessage": {
            "type": "structure",
            "required": [
              "ContentType",
              "Content"
            ],
            "members": {
              "ContentType": {},
              "Content": {}
            }
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "ChatDurationInMinutes": {
            "type": "integer"
          },
          "SupportedMessagingContentTypes": {
            "shape": "Ssv"
          },
          "PersistentChat": {
            "type": "structure",
            "members": {
              "RehydrationType": {},
              "SourceContactId": {}
            }
          },
          "RelatedContactId": {},
          "SegmentAttributes": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "members": {
                "ValueString": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ContactId": {},
          "ParticipantId": {},
          "ParticipantToken": {},
          "ContinuedFromContactId": {}
        }
      }
    },
    "StartContactEvaluation": {
      "http": {
        "method": "PUT",
        "requestUri": "/contact-evaluations/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ContactId",
          "EvaluationFormId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ContactId": {},
          "EvaluationFormId": {},
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "EvaluationId",
          "EvaluationArn"
        ],
        "members": {
          "EvaluationId": {},
          "EvaluationArn": {}
        }
      },
      "idempotent": true
    },
    "StartContactRecording": {
      "http": {
        "requestUri": "/contact/start-recording"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ContactId",
          "InitialContactId",
          "VoiceRecordingConfiguration"
        ],
        "members": {
          "InstanceId": {},
          "ContactId": {},
          "InitialContactId": {},
          "VoiceRecordingConfiguration": {
            "type": "structure",
            "members": {
              "VoiceRecordingTrack": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StartContactStreaming": {
      "http": {
        "requestUri": "/contact/start-streaming"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ContactId",
          "ChatStreamingConfiguration",
          "ClientToken"
        ],
        "members": {
          "InstanceId": {},
          "ContactId": {},
          "ChatStreamingConfiguration": {
            "shape": "Ssy"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "StreamingId"
        ],
        "members": {
          "StreamingId": {}
        }
      }
    },
    "StartOutboundVoiceContact": {
      "http": {
        "method": "PUT",
        "requestUri": "/contact/outbound-voice"
      },
      "input": {
        "type": "structure",
        "required": [
          "DestinationPhoneNumber",
          "ContactFlowId",
          "InstanceId"
        ],
        "members": {
          "Name": {
            "shape": "Sbt"
          },
          "Description": {
            "shape": "Sbu"
          },
          "References": {
            "shape": "S73"
          },
          "RelatedContactId": {},
          "DestinationPhoneNumber": {},
          "ContactFlowId": {},
          "InstanceId": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "SourcePhoneNumber": {},
          "QueueId": {},
          "Attributes": {
            "shape": "S2p"
          },
          "AnswerMachineDetectionConfig": {
            "type": "structure",
            "members": {
              "EnableAnswerMachineDetection": {
                "type": "boolean"
              },
              "AwaitAnswerMachinePrompt": {
                "type": "boolean"
              }
            }
          },
          "CampaignId": {},
          "TrafficType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ContactId": {}
        }
      }
    },
    "StartTaskContact": {
      "http": {
        "method": "PUT",
        "requestUri": "/contact/task"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "Name"
        ],
        "members": {
          "InstanceId": {},
          "PreviousContactId": {},
          "ContactFlowId": {},
          "Attributes": {
            "shape": "S2p"
          },
          "Name": {
            "shape": "Sbt"
          },
          "References": {
            "shape": "S73"
          },
          "Description": {
            "shape": "Sbu"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "ScheduledTime": {
            "type": "timestamp"
          },
          "TaskTemplateId": {},
          "QuickConnectId": {},
          "RelatedContactId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ContactId": {}
        }
      }
    },
    "StartWebRTCContact": {
      "http": {
        "method": "PUT",
        "requestUri": "/contact/webrtc"
      },
      "input": {
        "type": "structure",
        "required": [
          "ContactFlowId",
          "InstanceId",
          "ParticipantDetails"
        ],
        "members": {
          "Attributes": {
            "shape": "S2p"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "ContactFlowId": {},
          "InstanceId": {},
          "AllowedCapabilities": {
            "type": "structure",
            "members": {
              "Customer": {
                "shape": "Sts"
              },
              "Agent": {
                "shape": "Sts"
              }
            }
          },
          "ParticipantDetails": {
            "shape": "Ssx"
          },
          "RelatedContactId": {},
          "References": {
            "shape": "S73"
          },
          "Description": {
            "shape": "Sbu"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConnectionData": {
            "type": "structure",
            "members": {
              "Attendee": {
                "type": "structure",
                "members": {
                  "AttendeeId": {},
                  "JoinToken": {
                    "type": "string",
                    "sensitive": true
                  }
                }
              },
              "Meeting": {
                "type": "structure",
                "members": {
                  "MediaRegion": {},
                  "MediaPlacement": {
                    "type": "structure",
                    "members": {
                      "AudioHostUrl": {},
                      "AudioFallbackUrl": {},
                      "SignalingUrl": {},
                      "TurnControlUrl": {},
                      "EventIngestionUrl": {}
                    }
                  },
                  "MeetingFeatures": {
                    "type": "structure",
                    "members": {
                      "Audio": {
                        "type": "structure",
                        "members": {
                          "EchoReduction": {}
                        }
                      }
                    }
                  },
                  "MeetingId": {}
                }
              }
            }
          },
          "ContactId": {},
          "ParticipantId": {},
          "ParticipantToken": {}
        }
      }
    },
    "StopContact": {
      "http": {
        "requestUri": "/contact/stop"
      },
      "input": {
        "type": "structure",
        "required": [
          "ContactId",
          "InstanceId"
        ],
        "members": {
          "ContactId": {},
          "InstanceId": {},
          "DisconnectReason": {
            "type": "structure",
            "members": {
              "Code": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StopContactRecording": {
      "http": {
        "requestUri": "/contact/stop-recording"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ContactId",
          "InitialContactId"
        ],
        "members": {
          "InstanceId": {},
          "ContactId": {},
          "InitialContactId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StopContactStreaming": {
      "http": {
        "requestUri": "/contact/stop-streaming"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ContactId",
          "StreamingId"
        ],
        "members": {
          "InstanceId": {},
          "ContactId": {},
          "StreamingId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "SubmitContactEvaluation": {
      "http": {
        "requestUri": "/contact-evaluations/{InstanceId}/{EvaluationId}/submit"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "EvaluationId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "EvaluationId": {
            "location": "uri",
            "locationName": "EvaluationId"
          },
          "Answers": {
            "shape": "Suf"
          },
          "Notes": {
            "shape": "Scj"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "EvaluationId",
          "EvaluationArn"
        ],
        "members": {
          "EvaluationId": {},
          "EvaluationArn": {}
        }
      }
    },
    "SuspendContactRecording": {
      "http": {
        "requestUri": "/contact/suspend-recording"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ContactId",
          "InitialContactId"
        ],
        "members": {
          "InstanceId": {},
          "ContactId": {},
          "InitialContactId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "TagContact": {
      "http": {
        "requestUri": "/contact/tags"
      },
      "input": {
        "type": "structure",
        "required": [
          "ContactId",
          "InstanceId",
          "Tags"
        ],
        "members": {
          "ContactId": {},
          "InstanceId": {},
          "Tags": {
            "shape": "Sc5"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "S34"
          }
        }
      }
    },
    "TransferContact": {
      "http": {
        "requestUri": "/contact/transfer"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ContactId",
          "ContactFlowId"
        ],
        "members": {
          "InstanceId": {},
          "ContactId": {},
          "QueueId": {},
          "UserId": {},
          "ContactFlowId": {},
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ContactId": {},
          "ContactArn": {}
        }
      }
    },
    "UntagContact": {
      "http": {
        "method": "DELETE",
        "requestUri": "/contact/tags/{InstanceId}/{ContactId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ContactId",
          "InstanceId",
          "TagKeys"
        ],
        "members": {
          "ContactId": {
            "location": "uri",
            "locationName": "ContactId"
          },
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "TagKeys": {
            "location": "querystring",
            "locationName": "TagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "UpdateAgentStatus": {
      "http": {
        "requestUri": "/agent-status/{InstanceId}/{AgentStatusId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "AgentStatusId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "AgentStatusId": {
            "location": "uri",
            "locationName": "AgentStatusId"
          },
          "Name": {},
          "Description": {},
          "State": {},
          "DisplayOrder": {
            "type": "integer"
          },
          "ResetOrderNumber": {
            "type": "boolean"
          }
        }
      }
    },
    "UpdateContact": {
      "http": {
        "requestUri": "/contacts/{InstanceId}/{ContactId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ContactId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ContactId": {
            "location": "uri",
            "locationName": "ContactId"
          },
          "Name": {
            "shape": "Sbt"
          },
          "Description": {
            "shape": "Sbu"
          },
          "References": {
            "shape": "S73"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateContactAttributes": {
      "http": {
        "requestUri": "/contact/attributes"
      },
      "input": {
        "type": "structure",
        "required": [
          "InitialContactId",
          "InstanceId",
          "Attributes"
        ],
        "members": {
          "InitialContactId": {},
          "InstanceId": {},
          "Attributes": {
            "shape": "S2p"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateContactEvaluation": {
      "http": {
        "requestUri": "/contact-evaluations/{InstanceId}/{EvaluationId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "EvaluationId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "EvaluationId": {
            "location": "uri",
            "locationName": "EvaluationId"
          },
          "Answers": {
            "shape": "Suf"
          },
          "Notes": {
            "shape": "Scj"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "EvaluationId",
          "EvaluationArn"
        ],
        "members": {
          "EvaluationId": {},
          "EvaluationArn": {}
        }
      }
    },
    "UpdateContactFlowContent": {
      "http": {
        "requestUri": "/contact-flows/{InstanceId}/{ContactFlowId}/content"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ContactFlowId",
          "Content"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ContactFlowId": {
            "location": "uri",
            "locationName": "ContactFlowId"
          },
          "Content": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateContactFlowMetadata": {
      "http": {
        "requestUri": "/contact-flows/{InstanceId}/{ContactFlowId}/metadata"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ContactFlowId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ContactFlowId": {
            "location": "uri",
            "locationName": "ContactFlowId"
          },
          "Name": {},
          "Description": {},
          "ContactFlowState": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateContactFlowModuleContent": {
      "http": {
        "requestUri": "/contact-flow-modules/{InstanceId}/{ContactFlowModuleId}/content"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ContactFlowModuleId",
          "Content"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ContactFlowModuleId": {
            "location": "uri",
            "locationName": "ContactFlowModuleId"
          },
          "Content": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateContactFlowModuleMetadata": {
      "http": {
        "requestUri": "/contact-flow-modules/{InstanceId}/{ContactFlowModuleId}/metadata"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ContactFlowModuleId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ContactFlowModuleId": {
            "location": "uri",
            "locationName": "ContactFlowModuleId"
          },
          "Name": {},
          "Description": {},
          "State": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateContactFlowName": {
      "http": {
        "requestUri": "/contact-flows/{InstanceId}/{ContactFlowId}/name"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ContactFlowId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ContactFlowId": {
            "location": "uri",
            "locationName": "ContactFlowId"
          },
          "Name": {},
          "Description": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateContactRoutingData": {
      "http": {
        "requestUri": "/contacts/{InstanceId}/{ContactId}/routing-data"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ContactId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ContactId": {
            "location": "uri",
            "locationName": "ContactId"
          },
          "QueueTimeAdjustmentSeconds": {
            "type": "integer"
          },
          "QueuePriority": {
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateContactSchedule": {
      "http": {
        "requestUri": "/contact/schedule"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ContactId",
          "ScheduledTime"
        ],
        "members": {
          "InstanceId": {},
          "ContactId": {},
          "ScheduledTime": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateEvaluationForm": {
      "http": {
        "method": "PUT",
        "requestUri": "/evaluation-forms/{InstanceId}/{EvaluationFormId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "EvaluationFormId",
          "EvaluationFormVersion",
          "Title",
          "Items"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "EvaluationFormId": {
            "location": "uri",
            "locationName": "EvaluationFormId"
          },
          "EvaluationFormVersion": {
            "type": "integer"
          },
          "CreateNewVersion": {
            "type": "boolean"
          },
          "Title": {},
          "Description": {},
          "Items": {
            "shape": "S3u"
          },
          "ScoringStrategy": {
            "shape": "S4p"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "EvaluationFormId",
          "EvaluationFormArn",
          "EvaluationFormVersion"
        ],
        "members": {
          "EvaluationFormId": {},
          "EvaluationFormArn": {},
          "EvaluationFormVersion": {
            "type": "integer"
          }
        }
      },
      "idempotent": true
    },
    "UpdateHoursOfOperation": {
      "http": {
        "requestUri": "/hours-of-operations/{InstanceId}/{HoursOfOperationId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "HoursOfOperationId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "HoursOfOperationId": {
            "location": "uri",
            "locationName": "HoursOfOperationId"
          },
          "Name": {},
          "Description": {},
          "TimeZone": {},
          "Config": {
            "shape": "S4x"
          }
        }
      }
    },
    "UpdateInstanceAttribute": {
      "http": {
        "requestUri": "/instance/{InstanceId}/attribute/{AttributeType}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "AttributeType",
          "Value"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "AttributeType": {
            "location": "uri",
            "locationName": "AttributeType"
          },
          "Value": {}
        }
      }
    },
    "UpdateInstanceStorageConfig": {
      "http": {
        "requestUri": "/instance/{InstanceId}/storage-config/{AssociationId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "AssociationId",
          "ResourceType",
          "StorageConfig"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "AssociationId": {
            "location": "uri",
            "locationName": "AssociationId"
          },
          "ResourceType": {
            "location": "querystring",
            "locationName": "resourceType"
          },
          "StorageConfig": {
            "shape": "St"
          }
        }
      }
    },
    "UpdateParticipantRoleConfig": {
      "http": {
        "method": "PUT",
        "requestUri": "/contact/participant-role-config/{InstanceId}/{ContactId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ContactId",
          "ChannelConfiguration"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ContactId": {
            "location": "uri",
            "locationName": "ContactId"
          },
          "ChannelConfiguration": {
            "type": "structure",
            "members": {
              "Chat": {
                "type": "structure",
                "required": [
                  "ParticipantTimerConfigList"
                ],
                "members": {
                  "ParticipantTimerConfigList": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "required": [
                        "ParticipantRole",
                        "TimerType",
                        "TimerValue"
                      ],
                      "members": {
                        "ParticipantRole": {},
                        "TimerType": {},
                        "TimerValue": {
                          "type": "structure",
                          "members": {
                            "ParticipantTimerAction": {},
                            "ParticipantTimerDurationInMinutes": {
                              "type": "integer"
                            }
                          },
                          "union": true
                        }
                      }
                    }
                  }
                }
              }
            },
            "union": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdatePhoneNumber": {
      "http": {
        "method": "PUT",
        "requestUri": "/phone-number/{PhoneNumberId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "PhoneNumberId"
        ],
        "members": {
          "PhoneNumberId": {
            "location": "uri",
            "locationName": "PhoneNumberId"
          },
          "TargetArn": {},
          "InstanceId": {},
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PhoneNumberId": {},
          "PhoneNumberArn": {}
        }
      }
    },
    "UpdatePhoneNumberMetadata": {
      "http": {
        "method": "PUT",
        "requestUri": "/phone-number/{PhoneNumberId}/metadata"
      },
      "input": {
        "type": "structure",
        "required": [
          "PhoneNumberId"
        ],
        "members": {
          "PhoneNumberId": {
            "location": "uri",
            "locationName": "PhoneNumberId"
          },
          "PhoneNumberDescription": {},
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      }
    },
    "UpdatePredefinedAttribute": {
      "http": {
        "requestUri": "/predefined-attributes/{InstanceId}/{Name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "Name"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "Name": {
            "location": "uri",
            "locationName": "Name"
          },
          "Values": {
            "shape": "S5w"
          }
        }
      }
    },
    "UpdatePrompt": {
      "http": {
        "requestUri": "/prompts/{InstanceId}/{PromptId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "PromptId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "PromptId": {
            "location": "uri",
            "locationName": "PromptId"
          },
          "Name": {},
          "Description": {},
          "S3Uri": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PromptARN": {},
          "PromptId": {}
        }
      }
    },
    "UpdateQueueHoursOfOperation": {
      "http": {
        "requestUri": "/queues/{InstanceId}/{QueueId}/hours-of-operation"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "QueueId",
          "HoursOfOperationId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "QueueId": {
            "location": "uri",
            "locationName": "QueueId"
          },
          "HoursOfOperationId": {}
        }
      }
    },
    "UpdateQueueMaxContacts": {
      "http": {
        "requestUri": "/queues/{InstanceId}/{QueueId}/max-contacts"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "QueueId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "QueueId": {
            "location": "uri",
            "locationName": "QueueId"
          },
          "MaxContacts": {
            "type": "integer"
          }
        }
      }
    },
    "UpdateQueueName": {
      "http": {
        "requestUri": "/queues/{InstanceId}/{QueueId}/name"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "QueueId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "QueueId": {
            "location": "uri",
            "locationName": "QueueId"
          },
          "Name": {},
          "Description": {}
        }
      }
    },
    "UpdateQueueOutboundCallerConfig": {
      "http": {
        "requestUri": "/queues/{InstanceId}/{QueueId}/outbound-caller-config"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "QueueId",
          "OutboundCallerConfig"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "QueueId": {
            "location": "uri",
            "locationName": "QueueId"
          },
          "OutboundCallerConfig": {
            "shape": "S65"
          }
        }
      }
    },
    "UpdateQueueStatus": {
      "http": {
        "requestUri": "/queues/{InstanceId}/{QueueId}/status"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "QueueId",
          "Status"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "QueueId": {
            "location": "uri",
            "locationName": "QueueId"
          },
          "Status": {}
        }
      }
    },
    "UpdateQuickConnectConfig": {
      "http": {
        "requestUri": "/quick-connects/{InstanceId}/{QuickConnectId}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "QuickConnectId",
          "QuickConnectConfig"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "QuickConnectId": {
            "location": "uri",
            "locationName": "QuickConnectId"
          },
          "QuickConnectConfig": {
            "shape": "S6c"
          }
        }
      }
    },
    "UpdateQuickConnectName": {
      "http": {
        "requestUri": "/quick-connects/{InstanceId}/{QuickConnectId}/name"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "QuickConnectId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "QuickConnectId": {
            "location": "uri",
            "locationName": "QuickConnectId"
          },
          "Name": {},
          "Description": {}
        }
      }
    },
    "UpdateRoutingProfileAgentAvailabilityTimer": {
      "http": {
        "requestUri": "/routing-profiles/{InstanceId}/{RoutingProfileId}/agent-availability-timer"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "RoutingProfileId",
          "AgentAvailabilityTimer"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "RoutingProfileId": {
            "location": "uri",
            "locationName": "RoutingProfileId"
          },
          "AgentAvailabilityTimer": {}
        }
      }
    },
    "UpdateRoutingProfileConcurrency": {
      "http": {
        "requestUri": "/routing-profiles/{InstanceId}/{RoutingProfileId}/concurrency"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "RoutingProfileId",
          "MediaConcurrencies"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "RoutingProfileId": {
            "location": "uri",
            "locationName": "RoutingProfileId"
          },
          "MediaConcurrencies": {
            "shape": "S6l"
          }
        }
      }
    },
    "UpdateRoutingProfileDefaultOutboundQueue": {
      "http": {
        "requestUri": "/routing-profiles/{InstanceId}/{RoutingProfileId}/default-outbound-queue"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "RoutingProfileId",
          "DefaultOutboundQueueId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "RoutingProfileId": {
            "location": "uri",
            "locationName": "RoutingProfileId"
          },
          "DefaultOutboundQueueId": {}
        }
      }
    },
    "UpdateRoutingProfileName": {
      "http": {
        "requestUri": "/routing-profiles/{InstanceId}/{RoutingProfileId}/name"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "RoutingProfileId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "RoutingProfileId": {
            "location": "uri",
            "locationName": "RoutingProfileId"
          },
          "Name": {},
          "Description": {}
        }
      }
    },
    "UpdateRoutingProfileQueues": {
      "http": {
        "requestUri": "/routing-profiles/{InstanceId}/{RoutingProfileId}/queues"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "RoutingProfileId",
          "QueueConfigs"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "RoutingProfileId": {
            "location": "uri",
            "locationName": "RoutingProfileId"
          },
          "QueueConfigs": {
            "shape": "S1j"
          }
        }
      }
    },
    "UpdateRule": {
      "http": {
        "method": "PUT",
        "requestUri": "/rules/{InstanceId}/{RuleId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "RuleId",
          "InstanceId",
          "Name",
          "Function",
          "Actions",
          "PublishStatus"
        ],
        "members": {
          "RuleId": {
            "location": "uri",
            "locationName": "RuleId"
          },
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "Name": {},
          "Function": {},
          "Actions": {
            "shape": "S6x"
          },
          "PublishStatus": {}
        }
      }
    },
    "UpdateSecurityProfile": {
      "http": {
        "requestUri": "/security-profiles/{InstanceId}/{SecurityProfileId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "SecurityProfileId",
          "InstanceId"
        ],
        "members": {
          "Description": {},
          "Permissions": {
            "shape": "S80"
          },
          "SecurityProfileId": {
            "location": "uri",
            "locationName": "SecurityProfileId"
          },
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "AllowedAccessControlTags": {
            "shape": "S82"
          },
          "TagRestrictedResources": {
            "shape": "S85"
          },
          "Applications": {
            "shape": "S87"
          },
          "HierarchyRestrictedResources": {
            "shape": "S8c"
          },
          "AllowedAccessControlHierarchyGroupId": {}
        }
      }
    },
    "UpdateTaskTemplate": {
      "http": {
        "requestUri": "/instance/{InstanceId}/task/template/{TaskTemplateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "TaskTemplateId",
          "InstanceId"
        ],
        "members": {
          "TaskTemplateId": {
            "location": "uri",
            "locationName": "TaskTemplateId"
          },
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "Name": {},
          "Description": {},
          "ContactFlowId": {},
          "Constraints": {
            "shape": "S8k"
          },
          "Defaults": {
            "shape": "S8t"
          },
          "Status": {},
          "Fields": {
            "shape": "S8y"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceId": {},
          "Id": {},
          "Arn": {},
          "Name": {},
          "Description": {},
          "ContactFlowId": {},
          "Constraints": {
            "shape": "S8k"
          },
          "Defaults": {
            "shape": "S8t"
          },
          "Fields": {
            "shape": "S8y"
          },
          "Status": {},
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "CreatedTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "UpdateTrafficDistribution": {
      "http": {
        "method": "PUT",
        "requestUri": "/traffic-distribution/{Id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "TelephonyConfig": {
            "shape": "Sij"
          },
          "SignInConfig": {
            "shape": "Sio"
          },
          "AgentConfig": {
            "shape": "Sir"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateUserHierarchy": {
      "http": {
        "requestUri": "/users/{InstanceId}/{UserId}/hierarchy"
      },
      "input": {
        "type": "structure",
        "required": [
          "UserId",
          "InstanceId"
        ],
        "members": {
          "HierarchyGroupId": {},
          "UserId": {
            "location": "uri",
            "locationName": "UserId"
          },
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          }
        }
      }
    },
    "UpdateUserHierarchyGroupName": {
      "http": {
        "requestUri": "/user-hierarchy-groups/{InstanceId}/{HierarchyGroupId}/name"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "HierarchyGroupId",
          "InstanceId"
        ],
        "members": {
          "Name": {},
          "HierarchyGroupId": {
            "location": "uri",
            "locationName": "HierarchyGroupId"
          },
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          }
        }
      }
    },
    "UpdateUserHierarchyStructure": {
      "http": {
        "requestUri": "/user-hierarchy-structure/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "HierarchyStructure",
          "InstanceId"
        ],
        "members": {
          "HierarchyStructure": {
            "type": "structure",
            "members": {
              "LevelOne": {
                "shape": "Swr"
              },
              "LevelTwo": {
                "shape": "Swr"
              },
              "LevelThree": {
                "shape": "Swr"
              },
              "LevelFour": {
                "shape": "Swr"
              },
              "LevelFive": {
                "shape": "Swr"
              }
            }
          },
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          }
        }
      }
    },
    "UpdateUserIdentityInfo": {
      "http": {
        "requestUri": "/users/{InstanceId}/{UserId}/identity-info"
      },
      "input": {
        "type": "structure",
        "required": [
          "IdentityInfo",
          "UserId",
          "InstanceId"
        ],
        "members": {
          "IdentityInfo": {
            "shape": "S9l"
          },
          "UserId": {
            "location": "uri",
            "locationName": "UserId"
          },
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          }
        }
      }
    },
    "UpdateUserPhoneConfig": {
      "http": {
        "requestUri": "/users/{InstanceId}/{UserId}/phone-config"
      },
      "input": {
        "type": "structure",
        "required": [
          "PhoneConfig",
          "UserId",
          "InstanceId"
        ],
        "members": {
          "PhoneConfig": {
            "shape": "S9p"
          },
          "UserId": {
            "location": "uri",
            "locationName": "UserId"
          },
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          }
        }
      }
    },
    "UpdateUserProficiencies": {
      "http": {
        "requestUri": "/users/{InstanceId}/{UserId}/proficiencies"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "UserId",
          "UserProficiencies"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "UserId": {
            "location": "uri",
            "locationName": "UserId"
          },
          "UserProficiencies": {
            "shape": "S1x"
          }
        }
      }
    },
    "UpdateUserRoutingProfile": {
      "http": {
        "requestUri": "/users/{InstanceId}/{UserId}/routing-profile"
      },
      "input": {
        "type": "structure",
        "required": [
          "RoutingProfileId",
          "UserId",
          "InstanceId"
        ],
        "members": {
          "RoutingProfileId": {},
          "UserId": {
            "location": "uri",
            "locationName": "UserId"
          },
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          }
        }
      }
    },
    "UpdateUserSecurityProfiles": {
      "http": {
        "requestUri": "/users/{InstanceId}/{UserId}/security-profiles"
      },
      "input": {
        "type": "structure",
        "required": [
          "SecurityProfileIds",
          "UserId",
          "InstanceId"
        ],
        "members": {
          "SecurityProfileIds": {
            "shape": "S9u"
          },
          "UserId": {
            "location": "uri",
            "locationName": "UserId"
          },
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          }
        }
      }
    },
    "UpdateViewContent": {
      "http": {
        "requestUri": "/views/{InstanceId}/{ViewId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ViewId",
          "Status",
          "Content"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ViewId": {
            "location": "uri",
            "locationName": "ViewId"
          },
          "Status": {},
          "Content": {
            "shape": "Sa3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "View": {
            "shape": "Saa"
          }
        }
      }
    },
    "UpdateViewMetadata": {
      "http": {
        "requestUri": "/views/{InstanceId}/{ViewId}/metadata"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ViewId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "ViewId": {
            "location": "uri",
            "locationName": "ViewId"
          },
          "Name": {
            "shape": "Sa8"
          },
          "Description": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "Sf": {
      "type": "structure",
      "required": [
        "Name",
        "LexRegion"
      ],
      "members": {
        "Name": {},
        "LexRegion": {}
      }
    },
    "Si": {
      "type": "structure",
      "members": {
        "AliasArn": {}
      }
    },
    "St": {
      "type": "structure",
      "required": [
        "StorageType"
      ],
      "members": {
        "AssociationId": {},
        "StorageType": {},
        "S3Config": {
          "type": "structure",
          "required": [
            "BucketName",
            "BucketPrefix"
          ],
          "members": {
            "BucketName": {},
            "BucketPrefix": {},
            "EncryptionConfig": {
              "shape": "Sz"
            }
          }
        },
        "KinesisVideoStreamConfig": {
          "type": "structure",
          "required": [
            "Prefix",
            "RetentionPeriodHours",
            "EncryptionConfig"
          ],
          "members": {
            "Prefix": {},
            "RetentionPeriodHours": {
              "type": "integer"
            },
            "EncryptionConfig": {
              "shape": "Sz"
            }
          }
        },
        "KinesisStreamConfig": {
          "type": "structure",
          "required": [
            "StreamArn"
          ],
          "members": {
            "StreamArn": {}
          }
        },
        "KinesisFirehoseConfig": {
          "type": "structure",
          "required": [
            "FirehoseArn"
          ],
          "members": {
            "FirehoseArn": {}
          }
        }
      }
    },
    "Sz": {
      "type": "structure",
      "required": [
        "EncryptionType",
        "KeyId"
      ],
      "members": {
        "EncryptionType": {},
        "KeyId": {}
      }
    },
    "S1f": {
      "type": "list",
      "member": {}
    },
    "S1j": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "QueueReference",
          "Priority",
          "Delay"
        ],
        "members": {
          "QueueReference": {
            "shape": "S1l"
          },
          "Priority": {
            "type": "integer"
          },
          "Delay": {
            "type": "integer"
          }
        }
      }
    },
    "S1l": {
      "type": "structure",
      "required": [
        "QueueId",
        "Channel"
      ],
      "members": {
        "QueueId": {},
        "Channel": {}
      }
    },
    "S1x": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "AttributeName",
          "AttributeValue",
          "Level"
        ],
        "members": {
          "AttributeName": {},
          "AttributeValue": {},
          "Level": {
            "type": "float"
          }
        }
      }
    },
    "S23": {
      "type": "list",
      "member": {}
    },
    "S25": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "DataSetId": {},
          "TargetAccountId": {},
          "ResourceShareId": {},
          "ResourceShareArn": {}
        }
      }
    },
    "S27": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ErrorCode": {},
          "ErrorMessage": {}
        }
      }
    },
    "S2f": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ResourceId": {},
          "FlowId": {},
          "ResourceType": {}
        }
      }
    },
    "S2l": {
      "type": "structure",
      "members": {
        "Type": {},
        "Address": {}
      }
    },
    "S2p": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S34": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S3u": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Section": {
            "type": "structure",
            "required": [
              "Title",
              "RefId",
              "Items"
            ],
            "members": {
              "Title": {},
              "RefId": {},
              "Instructions": {},
              "Items": {
                "shape": "S3u"
              },
              "Weight": {
                "type": "double"
              }
            }
          },
          "Question": {
            "type": "structure",
            "required": [
              "Title",
              "RefId",
              "QuestionType"
            ],
            "members": {
              "Title": {},
              "Instructions": {},
              "RefId": {},
              "NotApplicableEnabled": {
                "type": "boolean"
              },
              "QuestionType": {},
              "QuestionTypeProperties": {
                "type": "structure",
                "members": {
                  "Numeric": {
                    "type": "structure",
                    "required": [
                      "MinValue",
                      "MaxValue"
                    ],
                    "members": {
                      "MinValue": {
                        "type": "integer"
                      },
                      "MaxValue": {
                        "type": "integer"
                      },
                      "Options": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "required": [
                            "MinValue",
                            "MaxValue"
                          ],
                          "members": {
                            "MinValue": {
                              "type": "integer"
                            },
                            "MaxValue": {
                              "type": "integer"
                            },
                            "Score": {
                              "type": "integer"
                            },
                            "AutomaticFail": {
                              "type": "boolean"
                            }
                          }
                        }
                      },
                      "Automation": {
                        "type": "structure",
                        "members": {
                          "PropertyValue": {
                            "type": "structure",
                            "required": [
                              "Label"
                            ],
                            "members": {
                              "Label": {}
                            }
                          }
                        },
                        "union": true
                      }
                    }
                  },
                  "SingleSelect": {
                    "type": "structure",
                    "required": [
                      "Options"
                    ],
                    "members": {
                      "Options": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "required": [
                            "RefId",
                            "Text"
                          ],
                          "members": {
                            "RefId": {},
                            "Text": {},
                            "Score": {
                              "type": "integer"
                            },
                            "AutomaticFail": {
                              "type": "boolean"
                            }
                          }
                        }
                      },
                      "DisplayAs": {},
                      "Automation": {
                        "type": "structure",
                        "required": [
                          "Options"
                        ],
                        "members": {
                          "Options": {
                            "type": "list",
                            "member": {
                              "type": "structure",
                              "members": {
                                "RuleCategory": {
                                  "type": "structure",
                                  "required": [
                                    "Category",
                                    "Condition",
                                    "OptionRefId"
                                  ],
                                  "members": {
                                    "Category": {},
                                    "Condition": {},
                                    "OptionRefId": {}
                                  }
                                }
                              },
                              "union": true
                            }
                          },
                          "DefaultOptionRefId": {}
                        }
                      }
                    }
                  }
                },
                "union": true
              },
              "Weight": {
                "type": "double"
              }
            }
          }
        },
        "union": true
      }
    },
    "S4p": {
      "type": "structure",
      "required": [
        "Mode",
        "Status"
      ],
      "members": {
        "Mode": {},
        "Status": {}
      }
    },
    "S4x": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Day",
          "StartTime",
          "EndTime"
        ],
        "members": {
          "Day": {},
          "StartTime": {
            "shape": "S50"
          },
          "EndTime": {
            "shape": "S50"
          }
        }
      }
    },
    "S50": {
      "type": "structure",
      "required": [
        "Hours",
        "Minutes"
      ],
      "members": {
        "Hours": {
          "type": "integer"
        },
        "Minutes": {
          "type": "integer"
        }
      }
    },
    "S57": {
      "type": "string",
      "sensitive": true
    },
    "S5w": {
      "type": "structure",
      "members": {
        "StringList": {
          "type": "list",
          "member": {}
        }
      },
      "union": true
    },
    "S65": {
      "type": "structure",
      "members": {
        "OutboundCallerIdName": {},
        "OutboundCallerIdNumberId": {},
        "OutboundFlowId": {}
      }
    },
    "S6c": {
      "type": "structure",
      "required": [
        "QuickConnectType"
      ],
      "members": {
        "QuickConnectType": {},
        "UserConfig": {
          "type": "structure",
          "required": [
            "UserId",
            "ContactFlowId"
          ],
          "members": {
            "UserId": {},
            "ContactFlowId": {}
          }
        },
        "QueueConfig": {
          "type": "structure",
          "required": [
            "QueueId",
            "ContactFlowId"
          ],
          "members": {
            "QueueId": {},
            "ContactFlowId": {}
          }
        },
        "PhoneConfig": {
          "type": "structure",
          "required": [
            "PhoneNumber"
          ],
          "members": {
            "PhoneNumber": {}
          }
        }
      }
    },
    "S6l": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Channel",
          "Concurrency"
        ],
        "members": {
          "Channel": {},
          "Concurrency": {
            "type": "integer"
          },
          "CrossChannelBehavior": {
            "type": "structure",
            "required": [
              "BehaviorType"
            ],
            "members": {
              "BehaviorType": {}
            }
          }
        }
      }
    },
    "S6u": {
      "type": "structure",
      "required": [
        "EventSourceName"
      ],
      "members": {
        "EventSourceName": {},
        "IntegrationAssociationId": {}
      }
    },
    "S6x": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "ActionType"
        ],
        "members": {
          "ActionType": {},
          "TaskAction": {
            "type": "structure",
            "required": [
              "Name",
              "ContactFlowId"
            ],
            "members": {
              "Name": {},
              "Description": {},
              "ContactFlowId": {},
              "References": {
                "shape": "S73"
              }
            }
          },
          "EventBridgeAction": {
            "type": "structure",
            "required": [
              "Name"
            ],
            "members": {
              "Name": {}
            }
          },
          "AssignContactCategoryAction": {
            "type": "structure",
            "members": {}
          },
          "SendNotificationAction": {
            "type": "structure",
            "required": [
              "DeliveryMethod",
              "Content",
              "ContentType",
              "Recipient"
            ],
            "members": {
              "DeliveryMethod": {},
              "Subject": {},
              "Content": {},
              "ContentType": {},
              "Recipient": {
                "type": "structure",
                "members": {
                  "UserTags": {
                    "type": "map",
                    "key": {},
                    "value": {}
                  },
                  "UserIds": {
                    "type": "list",
                    "member": {}
                  }
                }
              }
            }
          },
          "CreateCaseAction": {
            "type": "structure",
            "required": [
              "Fields",
              "TemplateId"
            ],
            "members": {
              "Fields": {
                "shape": "S7k"
              },
              "TemplateId": {}
            }
          },
          "UpdateCaseAction": {
            "type": "structure",
            "required": [
              "Fields"
            ],
            "members": {
              "Fields": {
                "shape": "S7k"
              }
            }
          },
          "EndAssociatedTasksAction": {
            "type": "structure",
            "members": {}
          }
        }
      }
    },
    "S73": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "required": [
          "Value",
          "Type"
        ],
        "members": {
          "Value": {},
          "Type": {}
        }
      }
    },
    "S7k": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Id",
          "Value"
        ],
        "members": {
          "Id": {},
          "Value": {
            "type": "structure",
            "members": {
              "BooleanValue": {
                "type": "boolean"
              },
              "DoubleValue": {
                "type": "double"
              },
              "EmptyValue": {
                "type": "structure",
                "members": {}
              },
              "StringValue": {}
            }
          }
        }
      }
    },
    "S80": {
      "type": "list",
      "member": {}
    },
    "S82": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S85": {
      "type": "list",
      "member": {}
    },
    "S87": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Namespace": {},
          "ApplicationPermissions": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "S8c": {
      "type": "list",
      "member": {}
    },
    "S8k": {
      "type": "structure",
      "members": {
        "RequiredFields": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Id": {
                "shape": "S8n"
              }
            }
          }
        },
        "ReadOnlyFields": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Id": {
                "shape": "S8n"
              }
            }
          }
        },
        "InvisibleFields": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Id": {
                "shape": "S8n"
              }
            }
          }
        }
      }
    },
    "S8n": {
      "type": "structure",
      "members": {
        "Name": {}
      }
    },
    "S8t": {
      "type": "structure",
      "members": {
        "DefaultFieldValues": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Id": {
                "shape": "S8n"
              },
              "DefaultValue": {}
            }
          }
        }
      }
    },
    "S8y": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "shape": "S8n"
          },
          "Description": {},
          "Type": {},
          "SingleSelectOptions": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "S9l": {
      "type": "structure",
      "members": {
        "FirstName": {
          "shape": "S9m"
        },
        "LastName": {
          "shape": "S9n"
        },
        "Email": {
          "shape": "S9o"
        },
        "SecondaryEmail": {
          "shape": "S9o"
        },
        "Mobile": {}
      }
    },
    "S9m": {
      "type": "string",
      "sensitive": true
    },
    "S9n": {
      "type": "string",
      "sensitive": true
    },
    "S9o": {
      "type": "string",
      "sensitive": true
    },
    "S9p": {
      "type": "structure",
      "required": [
        "PhoneType"
      ],
      "members": {
        "PhoneType": {},
        "AutoAccept": {
          "type": "boolean"
        },
        "AfterContactWorkTimeLimit": {
          "type": "integer"
        },
        "DeskPhoneNumber": {}
      }
    },
    "S9u": {
      "type": "list",
      "member": {}
    },
    "Sa3": {
      "type": "structure",
      "members": {
        "Template": {},
        "Actions": {
          "shape": "Sa5"
        }
      }
    },
    "Sa5": {
      "type": "list",
      "member": {
        "type": "string",
        "sensitive": true
      }
    },
    "Sa8": {
      "type": "string",
      "sensitive": true
    },
    "Saa": {
      "type": "structure",
      "members": {
        "Id": {},
        "Arn": {},
        "Name": {
          "shape": "Sa8"
        },
        "Status": {},
        "Type": {},
        "Description": {},
        "Version": {
          "type": "integer"
        },
        "VersionDescription": {},
        "Content": {
          "type": "structure",
          "members": {
            "InputSchema": {
              "type": "string",
              "sensitive": true
            },
            "Template": {},
            "Actions": {
              "shape": "Sa5"
            }
          }
        },
        "Tags": {
          "shape": "S34"
        },
        "CreatedTime": {
          "type": "timestamp"
        },
        "LastModifiedTime": {
          "type": "timestamp"
        },
        "ViewContentSha256": {}
      }
    },
    "Sbt": {
      "type": "string",
      "sensitive": true
    },
    "Sbu": {
      "type": "string",
      "sensitive": true
    },
    "Sc5": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Scc": {
      "type": "structure",
      "members": {
        "Percentage": {
          "type": "double"
        },
        "NotApplicable": {
          "type": "boolean"
        },
        "AutomaticFail": {
          "type": "boolean"
        }
      }
    },
    "Scg": {
      "type": "structure",
      "members": {
        "StringValue": {},
        "NumericValue": {
          "type": "double"
        },
        "NotApplicable": {
          "type": "boolean"
        }
      },
      "union": true
    },
    "Scj": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "Value": {}
        }
      }
    },
    "Sd5": {
      "type": "structure",
      "members": {
        "HoursOfOperationId": {},
        "HoursOfOperationArn": {},
        "Name": {},
        "Description": {},
        "TimeZone": {},
        "Config": {
          "shape": "S4x"
        },
        "Tags": {
          "shape": "S34"
        },
        "LastModifiedTime": {
          "type": "timestamp"
        },
        "LastModifiedRegion": {}
      }
    },
    "Sdf": {
      "type": "structure",
      "members": {
        "AttributeType": {},
        "Value": {}
      }
    },
    "Sdt": {
      "type": "structure",
      "members": {
        "Name": {},
        "Values": {
          "shape": "S5w"
        },
        "LastModifiedTime": {
          "type": "timestamp"
        },
        "LastModifiedRegion": {}
      }
    },
    "Sdw": {
      "type": "structure",
      "members": {
        "PromptARN": {},
        "PromptId": {},
        "Name": {},
        "Description": {},
        "Tags": {
          "shape": "S34"
        },
        "LastModifiedTime": {
          "type": "timestamp"
        },
        "LastModifiedRegion": {}
      }
    },
    "Sdz": {
      "type": "structure",
      "members": {
        "Name": {},
        "QueueArn": {},
        "QueueId": {},
        "Description": {},
        "OutboundCallerConfig": {
          "shape": "S65"
        },
        "HoursOfOperationId": {},
        "MaxContacts": {
          "type": "integer"
        },
        "Status": {},
        "Tags": {
          "shape": "S34"
        },
        "LastModifiedTime": {
          "type": "timestamp"
        },
        "LastModifiedRegion": {}
      }
    },
    "Se3": {
      "type": "structure",
      "members": {
        "QuickConnectARN": {},
        "QuickConnectId": {},
        "Name": {},
        "Description": {},
        "QuickConnectConfig": {
          "shape": "S6c"
        },
        "Tags": {
          "shape": "S34"
        },
        "LastModifiedTime": {
          "type": "timestamp"
        },
        "LastModifiedRegion": {}
      }
    },
    "Se6": {
      "type": "structure",
      "members": {
        "InstanceId": {},
        "Name": {},
        "RoutingProfileArn": {},
        "RoutingProfileId": {},
        "Description": {},
        "MediaConcurrencies": {
          "shape": "S6l"
        },
        "DefaultOutboundQueueId": {},
        "Tags": {
          "shape": "S34"
        },
        "NumberOfAssociatedQueues": {
          "type": "long"
        },
        "NumberOfAssociatedUsers": {
          "type": "long"
        },
        "AgentAvailabilityTimer": {},
        "LastModifiedTime": {
          "type": "timestamp"
        },
        "LastModifiedRegion": {},
        "IsDefault": {
          "type": "boolean"
        }
      }
    },
    "Ses": {
      "type": "structure",
      "members": {
        "Id": {},
        "Arn": {},
        "Name": {},
        "LastModifiedTime": {
          "type": "timestamp"
        },
        "LastModifiedRegion": {}
      }
    },
    "Sew": {
      "type": "structure",
      "members": {
        "Id": {},
        "Arn": {},
        "Name": {},
        "LastModifiedTime": {
          "type": "timestamp"
        },
        "LastModifiedRegion": {}
      }
    },
    "Sfs": {
      "type": "structure",
      "members": {
        "Queues": {
          "shape": "Sft"
        },
        "Channels": {
          "type": "list",
          "member": {}
        },
        "RoutingProfiles": {
          "shape": "Sfv"
        },
        "RoutingStepExpressions": {
          "type": "list",
          "member": {}
        }
      }
    },
    "Sft": {
      "type": "list",
      "member": {}
    },
    "Sfv": {
      "type": "list",
      "member": {}
    },
    "Sfy": {
      "type": "list",
      "member": {}
    },
    "Sg1": {
      "type": "structure",
      "members": {
        "Name": {},
        "Unit": {}
      }
    },
    "Sgc": {
      "type": "structure",
      "members": {
        "Queue": {
          "shape": "Sgd"
        },
        "Channel": {},
        "RoutingProfile": {
          "shape": "Sge"
        },
        "RoutingStepExpression": {}
      }
    },
    "Sgd": {
      "type": "structure",
      "members": {
        "Id": {},
        "Arn": {}
      }
    },
    "Sge": {
      "type": "structure",
      "members": {
        "Id": {},
        "Arn": {}
      }
    },
    "Sgv": {
      "type": "structure",
      "members": {
        "Id": {},
        "Arn": {}
      }
    },
    "Sgx": {
      "type": "map",
      "key": {},
      "value": {
        "type": "integer"
      }
    },
    "Sh4": {
      "type": "string",
      "sensitive": true
    },
    "Sh9": {
      "type": "structure",
      "members": {
        "Name": {},
        "Threshold": {
          "type": "structure",
          "members": {
            "Comparison": {},
            "ThresholdValue": {
              "type": "double"
            }
          }
        },
        "Statistic": {},
        "Unit": {}
      }
    },
    "Shu": {
      "type": "structure",
      "members": {
        "Name": {},
        "Threshold": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Comparison": {},
              "ThresholdValue": {
                "type": "double"
              }
            }
          }
        },
        "MetricFilters": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "MetricFilterKey": {},
              "MetricFilterValues": {
                "type": "list",
                "member": {}
              },
              "Negate": {
                "type": "boolean"
              }
            }
          }
        }
      }
    },
    "Sij": {
      "type": "structure",
      "required": [
        "Distributions"
      ],
      "members": {
        "Distributions": {
          "shape": "Sik"
        }
      }
    },
    "Sik": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Region",
          "Percentage"
        ],
        "members": {
          "Region": {},
          "Percentage": {
            "type": "integer"
          }
        }
      }
    },
    "Sio": {
      "type": "structure",
      "required": [
        "Distributions"
      ],
      "members": {
        "Distributions": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Region",
              "Enabled"
            ],
            "members": {
              "Region": {},
              "Enabled": {
                "type": "boolean"
              }
            }
          }
        }
      }
    },
    "Sir": {
      "type": "structure",
      "required": [
        "Distributions"
      ],
      "members": {
        "Distributions": {
          "shape": "Sik"
        }
      }
    },
    "Sl7": {
      "type": "list",
      "member": {}
    },
    "Sl8": {
      "type": "list",
      "member": {}
    },
    "Slt": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Id": {},
          "Arn": {},
          "Name": {},
          "QuickConnectType": {},
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "LastModifiedRegion": {}
        }
      }
    },
    "Smi": {
      "type": "structure",
      "members": {
        "AbsoluteTime": {
          "type": "timestamp",
          "timestampFormat": "iso8601"
        }
      },
      "union": true
    },
    "Smm": {
      "type": "structure",
      "required": [
        "BeginOffsetChar",
        "EndOffsetChar"
      ],
      "members": {
        "BeginOffsetChar": {
          "type": "integer"
        },
        "EndOffsetChar": {
          "type": "integer"
        }
      }
    },
    "Spt": {
      "type": "list",
      "member": {}
    },
    "Sqk": {
      "type": "structure",
      "members": {
        "OrConditions": {
          "type": "list",
          "member": {
            "shape": "Sqm"
          }
        },
        "AndConditions": {
          "shape": "Sqm"
        },
        "TagCondition": {
          "shape": "Sqn"
        }
      }
    },
    "Sqm": {
      "type": "list",
      "member": {
        "shape": "Sqn"
      }
    },
    "Sqn": {
      "type": "structure",
      "members": {
        "TagKey": {},
        "TagValue": {}
      }
    },
    "Sqo": {
      "type": "structure",
      "members": {
        "OrConditions": {
          "shape": "Sqp"
        },
        "AndConditions": {
          "shape": "Sqp"
        },
        "StringCondition": {
          "shape": "Sqq"
        }
      }
    },
    "Sqp": {
      "type": "list",
      "member": {
        "shape": "Sqo"
      }
    },
    "Sqq": {
      "type": "structure",
      "members": {
        "FieldName": {},
        "Value": {},
        "ComparisonType": {}
      }
    },
    "Sqv": {
      "type": "structure",
      "members": {
        "OrConditions": {
          "shape": "Sqw"
        },
        "AndConditions": {
          "shape": "Sqw"
        },
        "StringCondition": {
          "shape": "Sqq"
        }
      }
    },
    "Sqw": {
      "type": "list",
      "member": {
        "shape": "Sqv"
      }
    },
    "Sr1": {
      "type": "structure",
      "members": {
        "OrConditions": {
          "shape": "Sr2"
        },
        "AndConditions": {
          "shape": "Sr2"
        },
        "StringCondition": {
          "shape": "Sqq"
        }
      }
    },
    "Sr2": {
      "type": "list",
      "member": {
        "shape": "Sr1"
      }
    },
    "Sr8": {
      "type": "structure",
      "members": {
        "OrConditions": {
          "shape": "Sr9"
        },
        "AndConditions": {
          "shape": "Sr9"
        },
        "StringCondition": {
          "shape": "Sqq"
        },
        "QueueTypeCondition": {}
      }
    },
    "Sr9": {
      "type": "list",
      "member": {
        "shape": "Sr8"
      }
    },
    "Srf": {
      "type": "structure",
      "members": {
        "OrConditions": {
          "shape": "Srg"
        },
        "AndConditions": {
          "shape": "Srg"
        },
        "StringCondition": {
          "shape": "Sqq"
        }
      }
    },
    "Srg": {
      "type": "list",
      "member": {
        "shape": "Srf"
      }
    },
    "Sru": {
      "type": "structure",
      "members": {
        "OrConditions": {
          "shape": "Srv"
        },
        "AndConditions": {
          "shape": "Srv"
        },
        "StringCondition": {
          "shape": "Sqq"
        }
      }
    },
    "Srv": {
      "type": "list",
      "member": {
        "shape": "Sru"
      }
    },
    "Srz": {
      "type": "structure",
      "members": {
        "OrConditions": {
          "shape": "Ss0"
        },
        "AndConditions": {
          "shape": "Ss0"
        },
        "StringCondition": {
          "shape": "Sqq"
        }
      }
    },
    "Ss0": {
      "type": "list",
      "member": {
        "shape": "Srz"
      }
    },
    "Ss9": {
      "type": "structure",
      "members": {
        "TagConditions": {
          "shape": "Sqm"
        },
        "HierarchyGroupCondition": {
          "shape": "Ssa"
        }
      }
    },
    "Ssa": {
      "type": "structure",
      "members": {
        "Value": {},
        "HierarchyGroupMatchType": {}
      }
    },
    "Ssc": {
      "type": "structure",
      "members": {
        "OrConditions": {
          "shape": "Ssd"
        },
        "AndConditions": {
          "shape": "Ssd"
        },
        "StringCondition": {
          "shape": "Sqq"
        },
        "HierarchyGroupCondition": {
          "shape": "Ssa"
        }
      }
    },
    "Ssd": {
      "type": "list",
      "member": {
        "shape": "Ssc"
      }
    },
    "Ssv": {
      "type": "list",
      "member": {}
    },
    "Ssx": {
      "type": "structure",
      "required": [
        "DisplayName"
      ],
      "members": {
        "DisplayName": {}
      }
    },
    "Ssy": {
      "type": "structure",
      "required": [
        "StreamingEndpointArn"
      ],
      "members": {
        "StreamingEndpointArn": {}
      }
    },
    "Sts": {
      "type": "structure",
      "members": {
        "Video": {}
      }
    },
    "Suf": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "Value": {
            "shape": "Scg"
          }
        }
      }
    },
    "Swr": {
      "type": "structure",
      "required": [
        "Name"
      ],
      "members": {
        "Name": {}
      }
    }
  }
}