{
  "pagination": {
    "ListAdapterVersions": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "AdapterVersions"
    },
    "ListAdapters": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "Adapters"
    }
  }
}
