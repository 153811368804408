import React from "react";
import {
    Box,
    Paper,
} from "@mui/material";



interface Props {
    colorMap: (string|number)[];
}

export default function ColorLegend(props: Props) {

    return (
        <div className="map-config">
            <Box 
                component={Paper} 
                className="bathymetry-legend"
                margin={0}
            >
                <Box 
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'row', 
                        justifyContent: 'space-between',
                        position: 'relative' // Establishes a positioning context
                    }}
                >
                    {/* First set of boxes */}
                    {props.colorMap.map((value, index) => (
                        typeof value === 'string'? 
                            <Box
                                key={index}
                                sx={{
                                    width: `${100 / (props.colorMap.length / 2)}%`,
                                    height: '18px',
                                    borderLeft: '1px solid black',
                                    // paddingTop: 0.5,
                                    // paddingBottom: 0.5,
                                    backgroundColor: value,
                                    color:'black',
                                }}
                            >
                                {}
                            </Box>
                        : null
                    ))}

                    {/* Second set of boxes to overlap */}
                    <Box
                        sx={{
                            position: 'absolute',
                            display: 'flex', 
                            flexDirection: 'row', 
                            justifyContent: 'space-between',
                            top: 0,
                            left: 0,
                            zIndex: 1,
                            width: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                width: `${100 / (props.colorMap.length / 2) / 2}%`,
                                // borderLeft: '1px solid black',
                                paddingTop: 0.5,
                                paddingBottom: 0.5,
                            }}
                        >
                            {}
                        </Box>
                        {props.colorMap.map((value, index) => (
                                
                            (typeof value === 'number') && (index < props.colorMap.length - 2) ? 
                                <Box
                                key={index}
                                sx={{
                                    width: `${100 / (props.colorMap.length / 2)}%`,
                                    backgroundColor: value,
                                    fontWeight: 500,
                                    color: 'white'
                                }}
                                >
                                    {value}
                                </Box>
                            : null
                        ))}
                        <Box
                            sx={{
                                width: `${100 / (props.colorMap.length / 2) / 2}%`,
                                paddingTop: 0.5,
                                paddingBottom: 0.5,
                            }}
                        >
                            {}
                        </Box>
                    </Box>
                </Box>

                <Box sx={{ position: 'relative', height: '100%' }}>
                    <Box color={'black'} fontWeight={700}>
                        Bathymetric Tints [m]
                    </Box>
                </Box>
            </Box>
        </div>
    );
}
