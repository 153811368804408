{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2019-12-03",
    "endpointPrefix": "outposts",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "Outposts",
    "serviceFullName": "AWS Outposts",
    "serviceId": "Outposts",
    "signatureVersion": "v4",
    "signingName": "outposts",
    "uid": "outposts-2019-12-03"
  },
  "operations": {
    "CancelOrder": {
      "http": {
        "requestUri": "/orders/{OrderId}/cancel"
      },
      "input": {
        "type": "structure",
        "required": [
          "OrderId"
        ],
        "members": {
          "OrderId": {
            "location": "uri",
            "locationName": "OrderId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateOrder": {
      "http": {
        "requestUri": "/orders"
      },
      "input": {
        "type": "structure",
        "required": [
          "OutpostIdentifier",
          "LineItems",
          "PaymentOption"
        ],
        "members": {
          "OutpostIdentifier": {},
          "LineItems": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "CatalogItemId": {},
                "Quantity": {
                  "type": "integer"
                }
              }
            }
          },
          "PaymentOption": {},
          "PaymentTerm": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Order": {
            "shape": "Sd"
          }
        }
      }
    },
    "CreateOutpost": {
      "http": {
        "requestUri": "/outposts"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "SiteId"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "SiteId": {},
          "AvailabilityZone": {},
          "AvailabilityZoneId": {},
          "Tags": {
            "shape": "S10"
          },
          "SupportedHardwareType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Outpost": {
            "shape": "S15"
          }
        }
      }
    },
    "CreateSite": {
      "http": {
        "requestUri": "/sites"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "Notes": {},
          "Tags": {
            "shape": "S10"
          },
          "OperatingAddress": {
            "shape": "S1f"
          },
          "ShippingAddress": {
            "shape": "S1f"
          },
          "RackPhysicalProperties": {
            "shape": "S1r"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Site": {
            "shape": "S22"
          }
        }
      }
    },
    "DeleteOutpost": {
      "http": {
        "method": "DELETE",
        "requestUri": "/outposts/{OutpostId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "OutpostId"
        ],
        "members": {
          "OutpostId": {
            "location": "uri",
            "locationName": "OutpostId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteSite": {
      "http": {
        "method": "DELETE",
        "requestUri": "/sites/{SiteId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "SiteId"
        ],
        "members": {
          "SiteId": {
            "location": "uri",
            "locationName": "SiteId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "GetCatalogItem": {
      "http": {
        "method": "GET",
        "requestUri": "/catalog/item/{CatalogItemId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "CatalogItemId"
        ],
        "members": {
          "CatalogItemId": {
            "location": "uri",
            "locationName": "CatalogItemId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CatalogItem": {
            "shape": "S2a"
          }
        }
      }
    },
    "GetConnection": {
      "http": {
        "method": "GET",
        "requestUri": "/connections/{ConnectionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ConnectionId"
        ],
        "members": {
          "ConnectionId": {
            "location": "uri",
            "locationName": "ConnectionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConnectionId": {},
          "ConnectionDetails": {
            "type": "structure",
            "members": {
              "ClientPublicKey": {},
              "ServerPublicKey": {},
              "ServerEndpoint": {},
              "ClientTunnelAddress": {},
              "ServerTunnelAddress": {},
              "AllowedIps": {
                "type": "list",
                "member": {}
              }
            }
          }
        }
      }
    },
    "GetOrder": {
      "http": {
        "method": "GET",
        "requestUri": "/orders/{OrderId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "OrderId"
        ],
        "members": {
          "OrderId": {
            "location": "uri",
            "locationName": "OrderId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Order": {
            "shape": "Sd"
          }
        }
      }
    },
    "GetOutpost": {
      "http": {
        "method": "GET",
        "requestUri": "/outposts/{OutpostId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "OutpostId"
        ],
        "members": {
          "OutpostId": {
            "location": "uri",
            "locationName": "OutpostId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Outpost": {
            "shape": "S15"
          }
        }
      }
    },
    "GetOutpostInstanceTypes": {
      "http": {
        "method": "GET",
        "requestUri": "/outposts/{OutpostId}/instanceTypes"
      },
      "input": {
        "type": "structure",
        "required": [
          "OutpostId"
        ],
        "members": {
          "OutpostId": {
            "location": "uri",
            "locationName": "OutpostId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceTypes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "InstanceType": {}
              }
            }
          },
          "NextToken": {},
          "OutpostId": {},
          "OutpostArn": {}
        }
      }
    },
    "GetSite": {
      "http": {
        "method": "GET",
        "requestUri": "/sites/{SiteId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "SiteId"
        ],
        "members": {
          "SiteId": {
            "location": "uri",
            "locationName": "SiteId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Site": {
            "shape": "S22"
          }
        }
      }
    },
    "GetSiteAddress": {
      "http": {
        "method": "GET",
        "requestUri": "/sites/{SiteId}/address"
      },
      "input": {
        "type": "structure",
        "required": [
          "SiteId",
          "AddressType"
        ],
        "members": {
          "SiteId": {
            "location": "uri",
            "locationName": "SiteId"
          },
          "AddressType": {
            "location": "querystring",
            "locationName": "AddressType"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SiteId": {},
          "AddressType": {},
          "Address": {
            "shape": "S1f"
          }
        }
      }
    },
    "ListAssets": {
      "http": {
        "method": "GET",
        "requestUri": "/outposts/{OutpostId}/assets"
      },
      "input": {
        "type": "structure",
        "required": [
          "OutpostIdentifier"
        ],
        "members": {
          "OutpostIdentifier": {
            "location": "uri",
            "locationName": "OutpostId"
          },
          "HostIdFilter": {
            "location": "querystring",
            "locationName": "HostIdFilter",
            "type": "list",
            "member": {}
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          },
          "StatusFilter": {
            "location": "querystring",
            "locationName": "StatusFilter",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Assets": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AssetId": {},
                "RackId": {},
                "AssetType": {},
                "ComputeAttributes": {
                  "type": "structure",
                  "members": {
                    "HostId": {},
                    "State": {},
                    "InstanceFamilies": {
                      "type": "list",
                      "member": {}
                    }
                  }
                },
                "AssetLocation": {
                  "type": "structure",
                  "members": {
                    "RackElevation": {
                      "type": "float"
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListCatalogItems": {
      "http": {
        "method": "GET",
        "requestUri": "/catalog/items"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "ItemClassFilter": {
            "location": "querystring",
            "locationName": "ItemClassFilter",
            "type": "list",
            "member": {}
          },
          "SupportedStorageFilter": {
            "shape": "S2l",
            "location": "querystring",
            "locationName": "SupportedStorageFilter"
          },
          "EC2FamilyFilter": {
            "location": "querystring",
            "locationName": "EC2FamilyFilter",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CatalogItems": {
            "type": "list",
            "member": {
              "shape": "S2a"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListOrders": {
      "http": {
        "method": "GET",
        "requestUri": "/list-orders"
      },
      "input": {
        "type": "structure",
        "members": {
          "OutpostIdentifierFilter": {
            "location": "querystring",
            "locationName": "OutpostIdentifierFilter"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Orders": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "OutpostId": {},
                "OrderId": {},
                "OrderType": {},
                "Status": {},
                "LineItemCountsByStatus": {
                  "type": "map",
                  "key": {},
                  "value": {
                    "type": "integer"
                  }
                },
                "OrderSubmissionDate": {
                  "type": "timestamp"
                },
                "OrderFulfilledDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListOutposts": {
      "http": {
        "method": "GET",
        "requestUri": "/outposts"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "LifeCycleStatusFilter": {
            "location": "querystring",
            "locationName": "LifeCycleStatusFilter",
            "type": "list",
            "member": {}
          },
          "AvailabilityZoneFilter": {
            "location": "querystring",
            "locationName": "AvailabilityZoneFilter",
            "type": "list",
            "member": {}
          },
          "AvailabilityZoneIdFilter": {
            "location": "querystring",
            "locationName": "AvailabilityZoneIdFilter",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Outposts": {
            "type": "list",
            "member": {
              "shape": "S15"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListSites": {
      "http": {
        "method": "GET",
        "requestUri": "/sites"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "OperatingAddressCountryCodeFilter": {
            "location": "querystring",
            "locationName": "OperatingAddressCountryCodeFilter",
            "type": "list",
            "member": {}
          },
          "OperatingAddressStateOrRegionFilter": {
            "location": "querystring",
            "locationName": "OperatingAddressStateOrRegionFilter",
            "type": "list",
            "member": {}
          },
          "OperatingAddressCityFilter": {
            "location": "querystring",
            "locationName": "OperatingAddressCityFilter",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Sites": {
            "type": "list",
            "member": {
              "shape": "S22"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{ResourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S10"
          }
        }
      }
    },
    "StartConnection": {
      "http": {
        "requestUri": "/connections"
      },
      "input": {
        "type": "structure",
        "required": [
          "AssetId",
          "ClientPublicKey",
          "NetworkInterfaceDeviceIndex"
        ],
        "members": {
          "DeviceSerialNumber": {},
          "AssetId": {},
          "ClientPublicKey": {},
          "NetworkInterfaceDeviceIndex": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConnectionId": {},
          "UnderlayIpAddress": {}
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{ResourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          },
          "Tags": {
            "shape": "S10"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{ResourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          },
          "TagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateOutpost": {
      "http": {
        "method": "PATCH",
        "requestUri": "/outposts/{OutpostId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "OutpostId"
        ],
        "members": {
          "OutpostId": {
            "location": "uri",
            "locationName": "OutpostId"
          },
          "Name": {},
          "Description": {},
          "SupportedHardwareType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Outpost": {
            "shape": "S15"
          }
        }
      }
    },
    "UpdateSite": {
      "http": {
        "method": "PATCH",
        "requestUri": "/sites/{SiteId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "SiteId"
        ],
        "members": {
          "SiteId": {
            "location": "uri",
            "locationName": "SiteId"
          },
          "Name": {},
          "Description": {},
          "Notes": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Site": {
            "shape": "S22"
          }
        }
      }
    },
    "UpdateSiteAddress": {
      "http": {
        "method": "PUT",
        "requestUri": "/sites/{SiteId}/address"
      },
      "input": {
        "type": "structure",
        "required": [
          "SiteId",
          "AddressType",
          "Address"
        ],
        "members": {
          "SiteId": {
            "location": "uri",
            "locationName": "SiteId"
          },
          "AddressType": {},
          "Address": {
            "shape": "S1f"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AddressType": {},
          "Address": {
            "shape": "S1f"
          }
        }
      }
    },
    "UpdateSiteRackPhysicalProperties": {
      "http": {
        "method": "PATCH",
        "requestUri": "/sites/{SiteId}/rackPhysicalProperties"
      },
      "input": {
        "type": "structure",
        "required": [
          "SiteId"
        ],
        "members": {
          "SiteId": {
            "location": "uri",
            "locationName": "SiteId"
          },
          "PowerDrawKva": {},
          "PowerPhase": {},
          "PowerConnector": {},
          "PowerFeedDrop": {},
          "UplinkGbps": {},
          "UplinkCount": {},
          "FiberOpticCableType": {},
          "OpticalStandard": {},
          "MaximumSupportedWeightLbs": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Site": {
            "shape": "S22"
          }
        }
      }
    }
  },
  "shapes": {
    "Sd": {
      "type": "structure",
      "members": {
        "OutpostId": {},
        "OrderId": {},
        "Status": {},
        "LineItems": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "CatalogItemId": {},
              "LineItemId": {},
              "Quantity": {
                "type": "integer"
              },
              "Status": {},
              "ShipmentInformation": {
                "type": "structure",
                "members": {
                  "ShipmentTrackingNumber": {},
                  "ShipmentCarrier": {}
                }
              },
              "AssetInformationList": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "AssetId": {},
                    "MacAddressList": {
                      "type": "list",
                      "member": {}
                    }
                  }
                }
              },
              "PreviousLineItemId": {},
              "PreviousOrderId": {}
            }
          }
        },
        "PaymentOption": {},
        "OrderSubmissionDate": {
          "type": "timestamp"
        },
        "OrderFulfilledDate": {
          "type": "timestamp"
        },
        "PaymentTerm": {},
        "OrderType": {}
      }
    },
    "S10": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S15": {
      "type": "structure",
      "members": {
        "OutpostId": {},
        "OwnerId": {},
        "OutpostArn": {},
        "SiteId": {},
        "Name": {},
        "Description": {},
        "LifeCycleStatus": {},
        "AvailabilityZone": {},
        "AvailabilityZoneId": {},
        "Tags": {
          "shape": "S10"
        },
        "SiteArn": {},
        "SupportedHardwareType": {}
      }
    },
    "S1f": {
      "type": "structure",
      "required": [
        "AddressLine1",
        "City",
        "StateOrRegion",
        "PostalCode",
        "CountryCode"
      ],
      "members": {
        "ContactName": {},
        "ContactPhoneNumber": {},
        "AddressLine1": {},
        "AddressLine2": {},
        "AddressLine3": {},
        "City": {},
        "StateOrRegion": {},
        "DistrictOrCounty": {},
        "PostalCode": {},
        "CountryCode": {},
        "Municipality": {}
      }
    },
    "S1r": {
      "type": "structure",
      "members": {
        "PowerDrawKva": {},
        "PowerPhase": {},
        "PowerConnector": {},
        "PowerFeedDrop": {},
        "UplinkGbps": {},
        "UplinkCount": {},
        "FiberOpticCableType": {},
        "OpticalStandard": {},
        "MaximumSupportedWeightLbs": {}
      }
    },
    "S22": {
      "type": "structure",
      "members": {
        "SiteId": {},
        "AccountId": {},
        "Name": {},
        "Description": {},
        "Tags": {
          "shape": "S10"
        },
        "SiteArn": {},
        "Notes": {},
        "OperatingAddressCountryCode": {},
        "OperatingAddressStateOrRegion": {},
        "OperatingAddressCity": {},
        "RackPhysicalProperties": {
          "shape": "S1r"
        }
      }
    },
    "S2a": {
      "type": "structure",
      "members": {
        "CatalogItemId": {},
        "ItemStatus": {},
        "EC2Capacities": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Family": {},
              "MaxSize": {},
              "Quantity": {}
            }
          }
        },
        "PowerKva": {
          "type": "float"
        },
        "WeightLbs": {
          "type": "integer"
        },
        "SupportedUplinkGbps": {
          "type": "list",
          "member": {
            "type": "integer"
          }
        },
        "SupportedStorage": {
          "shape": "S2l"
        }
      }
    },
    "S2l": {
      "type": "list",
      "member": {}
    }
  }
}