{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2023-04-20",
    "endpointPrefix": "bedrock",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "Amazon Bedrock",
    "serviceId": "Bedrock",
    "signatureVersion": "v4",
    "signingName": "bedrock",
    "uid": "bedrock-2023-04-20"
  },
  "operations": {
    "CreateModelCustomizationJob": {
      "http": {
        "requestUri": "/model-customization-jobs",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "jobName",
          "customModelName",
          "roleArn",
          "baseModelIdentifier",
          "trainingDataConfig",
          "outputDataConfig",
          "hyperParameters"
        ],
        "members": {
          "jobName": {},
          "customModelName": {},
          "roleArn": {},
          "clientRequestToken": {
            "idempotencyToken": true
          },
          "baseModelIdentifier": {},
          "customizationType": {},
          "customModelKmsKeyId": {},
          "jobTags": {
            "shape": "S9"
          },
          "customModelTags": {
            "shape": "S9"
          },
          "trainingDataConfig": {
            "shape": "Sd"
          },
          "validationDataConfig": {
            "shape": "Sf"
          },
          "outputDataConfig": {
            "shape": "Si"
          },
          "hyperParameters": {
            "shape": "Sj"
          },
          "vpcConfig": {
            "shape": "Sl"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "jobArn"
        ],
        "members": {
          "jobArn": {}
        }
      },
      "idempotent": true
    },
    "CreateProvisionedModelThroughput": {
      "http": {
        "requestUri": "/provisioned-model-throughput",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "modelUnits",
          "provisionedModelName",
          "modelId"
        ],
        "members": {
          "clientRequestToken": {
            "idempotencyToken": true
          },
          "modelUnits": {
            "type": "integer"
          },
          "provisionedModelName": {},
          "modelId": {},
          "commitmentDuration": {},
          "tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "provisionedModelArn"
        ],
        "members": {
          "provisionedModelArn": {}
        }
      },
      "idempotent": true
    },
    "DeleteCustomModel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/custom-models/{modelIdentifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "modelIdentifier"
        ],
        "members": {
          "modelIdentifier": {
            "location": "uri",
            "locationName": "modelIdentifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteModelInvocationLoggingConfiguration": {
      "http": {
        "method": "DELETE",
        "requestUri": "/logging/modelinvocations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteProvisionedModelThroughput": {
      "http": {
        "method": "DELETE",
        "requestUri": "/provisioned-model-throughput/{provisionedModelId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "provisionedModelId"
        ],
        "members": {
          "provisionedModelId": {
            "location": "uri",
            "locationName": "provisionedModelId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "GetCustomModel": {
      "http": {
        "method": "GET",
        "requestUri": "/custom-models/{modelIdentifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "modelIdentifier"
        ],
        "members": {
          "modelIdentifier": {
            "location": "uri",
            "locationName": "modelIdentifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "modelArn",
          "modelName",
          "jobArn",
          "baseModelArn",
          "trainingDataConfig",
          "outputDataConfig",
          "creationTime"
        ],
        "members": {
          "modelArn": {},
          "modelName": {},
          "jobName": {},
          "jobArn": {},
          "baseModelArn": {},
          "customizationType": {},
          "modelKmsKeyArn": {},
          "hyperParameters": {
            "shape": "Sj"
          },
          "trainingDataConfig": {
            "shape": "Sd"
          },
          "validationDataConfig": {
            "shape": "Sf"
          },
          "outputDataConfig": {
            "shape": "Si"
          },
          "trainingMetrics": {
            "shape": "S1a"
          },
          "validationMetrics": {
            "shape": "S1c"
          },
          "creationTime": {
            "shape": "S1e"
          }
        }
      }
    },
    "GetFoundationModel": {
      "http": {
        "method": "GET",
        "requestUri": "/foundation-models/{modelIdentifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "modelIdentifier"
        ],
        "members": {
          "modelIdentifier": {
            "location": "uri",
            "locationName": "modelIdentifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "modelDetails": {
            "type": "structure",
            "required": [
              "modelArn",
              "modelId"
            ],
            "members": {
              "modelArn": {},
              "modelId": {},
              "modelName": {},
              "providerName": {},
              "inputModalities": {
                "shape": "S1l"
              },
              "outputModalities": {
                "shape": "S1l"
              },
              "responseStreamingSupported": {
                "type": "boolean"
              },
              "customizationsSupported": {
                "shape": "S1o"
              },
              "inferenceTypesSupported": {
                "shape": "S1q"
              },
              "modelLifecycle": {
                "shape": "S1s"
              }
            }
          }
        }
      }
    },
    "GetModelCustomizationJob": {
      "http": {
        "method": "GET",
        "requestUri": "/model-customization-jobs/{jobIdentifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "jobIdentifier"
        ],
        "members": {
          "jobIdentifier": {
            "location": "uri",
            "locationName": "jobIdentifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "jobArn",
          "jobName",
          "outputModelName",
          "roleArn",
          "creationTime",
          "baseModelArn",
          "hyperParameters",
          "trainingDataConfig",
          "validationDataConfig",
          "outputDataConfig"
        ],
        "members": {
          "jobArn": {},
          "jobName": {},
          "outputModelName": {},
          "outputModelArn": {},
          "clientRequestToken": {},
          "roleArn": {},
          "status": {},
          "failureMessage": {},
          "creationTime": {
            "shape": "S1e"
          },
          "lastModifiedTime": {
            "shape": "S1e"
          },
          "endTime": {
            "shape": "S1e"
          },
          "baseModelArn": {},
          "hyperParameters": {
            "shape": "Sj"
          },
          "trainingDataConfig": {
            "shape": "Sd"
          },
          "validationDataConfig": {
            "shape": "Sf"
          },
          "outputDataConfig": {
            "shape": "Si"
          },
          "customizationType": {},
          "outputModelKmsKeyArn": {},
          "trainingMetrics": {
            "shape": "S1a"
          },
          "validationMetrics": {
            "shape": "S1c"
          },
          "vpcConfig": {
            "shape": "Sl"
          }
        }
      }
    },
    "GetModelInvocationLoggingConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/logging/modelinvocations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "loggingConfig": {
            "shape": "S22"
          }
        }
      }
    },
    "GetProvisionedModelThroughput": {
      "http": {
        "method": "GET",
        "requestUri": "/provisioned-model-throughput/{provisionedModelId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "provisionedModelId"
        ],
        "members": {
          "provisionedModelId": {
            "location": "uri",
            "locationName": "provisionedModelId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "modelUnits",
          "desiredModelUnits",
          "provisionedModelName",
          "provisionedModelArn",
          "modelArn",
          "desiredModelArn",
          "foundationModelArn",
          "status",
          "creationTime",
          "lastModifiedTime"
        ],
        "members": {
          "modelUnits": {
            "type": "integer"
          },
          "desiredModelUnits": {
            "type": "integer"
          },
          "provisionedModelName": {},
          "provisionedModelArn": {},
          "modelArn": {},
          "desiredModelArn": {},
          "foundationModelArn": {},
          "status": {},
          "creationTime": {
            "shape": "S1e"
          },
          "lastModifiedTime": {
            "shape": "S1e"
          },
          "failureMessage": {},
          "commitmentDuration": {},
          "commitmentExpirationTime": {
            "shape": "S1e"
          }
        }
      }
    },
    "ListCustomModels": {
      "http": {
        "method": "GET",
        "requestUri": "/custom-models",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "creationTimeBefore": {
            "shape": "S1e",
            "location": "querystring",
            "locationName": "creationTimeBefore"
          },
          "creationTimeAfter": {
            "shape": "S1e",
            "location": "querystring",
            "locationName": "creationTimeAfter"
          },
          "nameContains": {
            "location": "querystring",
            "locationName": "nameContains"
          },
          "baseModelArnEquals": {
            "location": "querystring",
            "locationName": "baseModelArnEquals"
          },
          "foundationModelArnEquals": {
            "location": "querystring",
            "locationName": "foundationModelArnEquals"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "sortBy": {
            "location": "querystring",
            "locationName": "sortBy"
          },
          "sortOrder": {
            "location": "querystring",
            "locationName": "sortOrder"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "modelSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "modelArn",
                "modelName",
                "creationTime",
                "baseModelArn",
                "baseModelName"
              ],
              "members": {
                "modelArn": {},
                "modelName": {},
                "creationTime": {
                  "shape": "S1e"
                },
                "baseModelArn": {},
                "baseModelName": {},
                "customizationType": {}
              }
            }
          }
        }
      }
    },
    "ListFoundationModels": {
      "http": {
        "method": "GET",
        "requestUri": "/foundation-models",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "byProvider": {
            "location": "querystring",
            "locationName": "byProvider"
          },
          "byCustomizationType": {
            "location": "querystring",
            "locationName": "byCustomizationType"
          },
          "byOutputModality": {
            "location": "querystring",
            "locationName": "byOutputModality"
          },
          "byInferenceType": {
            "location": "querystring",
            "locationName": "byInferenceType"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "modelSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "modelArn",
                "modelId"
              ],
              "members": {
                "modelArn": {},
                "modelId": {},
                "modelName": {},
                "providerName": {},
                "inputModalities": {
                  "shape": "S1l"
                },
                "outputModalities": {
                  "shape": "S1l"
                },
                "responseStreamingSupported": {
                  "type": "boolean"
                },
                "customizationsSupported": {
                  "shape": "S1o"
                },
                "inferenceTypesSupported": {
                  "shape": "S1q"
                },
                "modelLifecycle": {
                  "shape": "S1s"
                }
              }
            }
          }
        }
      }
    },
    "ListModelCustomizationJobs": {
      "http": {
        "method": "GET",
        "requestUri": "/model-customization-jobs",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "creationTimeAfter": {
            "shape": "S1e",
            "location": "querystring",
            "locationName": "creationTimeAfter"
          },
          "creationTimeBefore": {
            "shape": "S1e",
            "location": "querystring",
            "locationName": "creationTimeBefore"
          },
          "statusEquals": {
            "location": "querystring",
            "locationName": "statusEquals"
          },
          "nameContains": {
            "location": "querystring",
            "locationName": "nameContains"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "sortBy": {
            "location": "querystring",
            "locationName": "sortBy"
          },
          "sortOrder": {
            "location": "querystring",
            "locationName": "sortOrder"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "modelCustomizationJobSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "jobArn",
                "baseModelArn",
                "jobName",
                "status",
                "creationTime"
              ],
              "members": {
                "jobArn": {},
                "baseModelArn": {},
                "jobName": {},
                "status": {},
                "lastModifiedTime": {
                  "shape": "S1e"
                },
                "creationTime": {
                  "shape": "S1e"
                },
                "endTime": {
                  "shape": "S1e"
                },
                "customModelArn": {},
                "customModelName": {},
                "customizationType": {}
              }
            }
          }
        }
      }
    },
    "ListProvisionedModelThroughputs": {
      "http": {
        "method": "GET",
        "requestUri": "/provisioned-model-throughputs",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "creationTimeAfter": {
            "shape": "S1e",
            "location": "querystring",
            "locationName": "creationTimeAfter"
          },
          "creationTimeBefore": {
            "shape": "S1e",
            "location": "querystring",
            "locationName": "creationTimeBefore"
          },
          "statusEquals": {
            "location": "querystring",
            "locationName": "statusEquals"
          },
          "modelArnEquals": {
            "location": "querystring",
            "locationName": "modelArnEquals"
          },
          "nameContains": {
            "location": "querystring",
            "locationName": "nameContains"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "sortBy": {
            "location": "querystring",
            "locationName": "sortBy"
          },
          "sortOrder": {
            "location": "querystring",
            "locationName": "sortOrder"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "provisionedModelSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "provisionedModelName",
                "provisionedModelArn",
                "modelArn",
                "desiredModelArn",
                "foundationModelArn",
                "modelUnits",
                "desiredModelUnits",
                "status",
                "creationTime",
                "lastModifiedTime"
              ],
              "members": {
                "provisionedModelName": {},
                "provisionedModelArn": {},
                "modelArn": {},
                "desiredModelArn": {},
                "foundationModelArn": {},
                "modelUnits": {
                  "type": "integer"
                },
                "desiredModelUnits": {
                  "type": "integer"
                },
                "status": {},
                "commitmentDuration": {},
                "commitmentExpirationTime": {
                  "shape": "S1e"
                },
                "creationTime": {
                  "shape": "S1e"
                },
                "lastModifiedTime": {
                  "shape": "S1e"
                }
              }
            }
          }
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "requestUri": "/listTagsForResource",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceARN"
        ],
        "members": {
          "resourceARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "S9"
          }
        }
      }
    },
    "PutModelInvocationLoggingConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/logging/modelinvocations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "loggingConfig"
        ],
        "members": {
          "loggingConfig": {
            "shape": "S22"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "StopModelCustomizationJob": {
      "http": {
        "requestUri": "/model-customization-jobs/{jobIdentifier}/stop",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "jobIdentifier"
        ],
        "members": {
          "jobIdentifier": {
            "location": "uri",
            "locationName": "jobIdentifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "TagResource": {
      "http": {
        "requestUri": "/tagResource",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceARN",
          "tags"
        ],
        "members": {
          "resourceARN": {},
          "tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "requestUri": "/untagResource",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceARN",
          "tagKeys"
        ],
        "members": {
          "resourceARN": {},
          "tagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateProvisionedModelThroughput": {
      "http": {
        "method": "PATCH",
        "requestUri": "/provisioned-model-throughput/{provisionedModelId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "provisionedModelId"
        ],
        "members": {
          "provisionedModelId": {
            "location": "uri",
            "locationName": "provisionedModelId"
          },
          "desiredProvisionedModelName": {},
          "desiredModelId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    }
  },
  "shapes": {
    "S9": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "key",
          "value"
        ],
        "members": {
          "key": {},
          "value": {}
        }
      }
    },
    "Sd": {
      "type": "structure",
      "required": [
        "s3Uri"
      ],
      "members": {
        "s3Uri": {}
      }
    },
    "Sf": {
      "type": "structure",
      "required": [
        "validators"
      ],
      "members": {
        "validators": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "s3Uri"
            ],
            "members": {
              "s3Uri": {}
            }
          }
        }
      }
    },
    "Si": {
      "type": "structure",
      "required": [
        "s3Uri"
      ],
      "members": {
        "s3Uri": {}
      }
    },
    "Sj": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sl": {
      "type": "structure",
      "required": [
        "subnetIds",
        "securityGroupIds"
      ],
      "members": {
        "subnetIds": {
          "type": "list",
          "member": {}
        },
        "securityGroupIds": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S1a": {
      "type": "structure",
      "members": {
        "trainingLoss": {
          "type": "float"
        }
      }
    },
    "S1c": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "validationLoss": {
            "type": "float"
          }
        }
      }
    },
    "S1e": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "S1l": {
      "type": "list",
      "member": {}
    },
    "S1o": {
      "type": "list",
      "member": {}
    },
    "S1q": {
      "type": "list",
      "member": {}
    },
    "S1s": {
      "type": "structure",
      "required": [
        "status"
      ],
      "members": {
        "status": {}
      }
    },
    "S22": {
      "type": "structure",
      "members": {
        "cloudWatchConfig": {
          "type": "structure",
          "required": [
            "logGroupName",
            "roleArn"
          ],
          "members": {
            "logGroupName": {},
            "roleArn": {},
            "largeDataDeliveryS3Config": {
              "shape": "S25"
            }
          }
        },
        "s3Config": {
          "shape": "S25"
        },
        "textDataDeliveryEnabled": {
          "type": "boolean"
        },
        "imageDataDeliveryEnabled": {
          "type": "boolean"
        },
        "embeddingDataDeliveryEnabled": {
          "type": "boolean"
        }
      }
    },
    "S25": {
      "type": "structure",
      "required": [
        "bucketName"
      ],
      "members": {
        "bucketName": {},
        "keyPrefix": {}
      }
    }
  }
}