{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-05-10",
    "endpointPrefix": "entityresolution",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "AWSEntityResolution",
    "serviceFullName": "AWS EntityResolution",
    "serviceId": "EntityResolution",
    "signatureVersion": "v4",
    "signingName": "entityresolution",
    "uid": "entityresolution-2018-05-10"
  },
  "operations": {
    "CreateIdMappingWorkflow": {
      "http": {
        "requestUri": "/idmappingworkflows",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "idMappingTechniques",
          "inputSourceConfig",
          "outputSourceConfig",
          "roleArn",
          "workflowName"
        ],
        "members": {
          "description": {},
          "idMappingTechniques": {
            "shape": "S3"
          },
          "inputSourceConfig": {
            "shape": "Sa"
          },
          "outputSourceConfig": {
            "shape": "Se"
          },
          "roleArn": {},
          "tags": {
            "shape": "Si"
          },
          "workflowName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "idMappingTechniques",
          "inputSourceConfig",
          "outputSourceConfig",
          "roleArn",
          "workflowArn",
          "workflowName"
        ],
        "members": {
          "description": {},
          "idMappingTechniques": {
            "shape": "S3"
          },
          "inputSourceConfig": {
            "shape": "Sa"
          },
          "outputSourceConfig": {
            "shape": "Se"
          },
          "roleArn": {},
          "workflowArn": {},
          "workflowName": {}
        }
      }
    },
    "CreateMatchingWorkflow": {
      "http": {
        "requestUri": "/matchingworkflows",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "inputSourceConfig",
          "outputSourceConfig",
          "resolutionTechniques",
          "roleArn",
          "workflowName"
        ],
        "members": {
          "description": {},
          "incrementalRunConfig": {
            "shape": "So"
          },
          "inputSourceConfig": {
            "shape": "Sq"
          },
          "outputSourceConfig": {
            "shape": "Su"
          },
          "resolutionTechniques": {
            "shape": "Sz"
          },
          "roleArn": {},
          "tags": {
            "shape": "Si"
          },
          "workflowName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "inputSourceConfig",
          "outputSourceConfig",
          "resolutionTechniques",
          "roleArn",
          "workflowArn",
          "workflowName"
        ],
        "members": {
          "description": {},
          "incrementalRunConfig": {
            "shape": "So"
          },
          "inputSourceConfig": {
            "shape": "Sq"
          },
          "outputSourceConfig": {
            "shape": "Su"
          },
          "resolutionTechniques": {
            "shape": "Sz"
          },
          "roleArn": {},
          "workflowArn": {},
          "workflowName": {}
        }
      }
    },
    "CreateSchemaMapping": {
      "http": {
        "requestUri": "/schemas",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "mappedInputFields",
          "schemaName"
        ],
        "members": {
          "description": {},
          "mappedInputFields": {
            "shape": "S1b"
          },
          "schemaName": {},
          "tags": {
            "shape": "Si"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "description",
          "mappedInputFields",
          "schemaArn",
          "schemaName"
        ],
        "members": {
          "description": {},
          "mappedInputFields": {
            "shape": "S1b"
          },
          "schemaArn": {},
          "schemaName": {}
        }
      }
    },
    "DeleteIdMappingWorkflow": {
      "http": {
        "method": "DELETE",
        "requestUri": "/idmappingworkflows/{workflowName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workflowName"
        ],
        "members": {
          "workflowName": {
            "location": "uri",
            "locationName": "workflowName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "message"
        ],
        "members": {
          "message": {}
        }
      },
      "idempotent": true
    },
    "DeleteMatchingWorkflow": {
      "http": {
        "method": "DELETE",
        "requestUri": "/matchingworkflows/{workflowName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workflowName"
        ],
        "members": {
          "workflowName": {
            "location": "uri",
            "locationName": "workflowName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "message"
        ],
        "members": {
          "message": {}
        }
      },
      "idempotent": true
    },
    "DeleteSchemaMapping": {
      "http": {
        "method": "DELETE",
        "requestUri": "/schemas/{schemaName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "schemaName"
        ],
        "members": {
          "schemaName": {
            "location": "uri",
            "locationName": "schemaName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "message"
        ],
        "members": {
          "message": {}
        }
      },
      "idempotent": true
    },
    "GetIdMappingJob": {
      "http": {
        "method": "GET",
        "requestUri": "/idmappingworkflows/{workflowName}/jobs/{jobId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId",
          "workflowName"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          },
          "workflowName": {
            "location": "uri",
            "locationName": "workflowName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "jobId",
          "startTime",
          "status"
        ],
        "members": {
          "endTime": {
            "type": "timestamp"
          },
          "errorDetails": {
            "shape": "S1q"
          },
          "jobId": {},
          "metrics": {
            "type": "structure",
            "members": {
              "inputRecords": {
                "type": "integer"
              },
              "recordsNotProcessed": {
                "type": "integer"
              },
              "totalRecordsProcessed": {
                "type": "integer"
              }
            }
          },
          "startTime": {
            "type": "timestamp"
          },
          "status": {}
        }
      }
    },
    "GetIdMappingWorkflow": {
      "http": {
        "method": "GET",
        "requestUri": "/idmappingworkflows/{workflowName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workflowName"
        ],
        "members": {
          "workflowName": {
            "location": "uri",
            "locationName": "workflowName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createdAt",
          "idMappingTechniques",
          "inputSourceConfig",
          "outputSourceConfig",
          "roleArn",
          "updatedAt",
          "workflowArn",
          "workflowName"
        ],
        "members": {
          "createdAt": {
            "type": "timestamp"
          },
          "description": {},
          "idMappingTechniques": {
            "shape": "S3"
          },
          "inputSourceConfig": {
            "shape": "Sa"
          },
          "outputSourceConfig": {
            "shape": "Se"
          },
          "roleArn": {},
          "tags": {
            "shape": "Si"
          },
          "updatedAt": {
            "type": "timestamp"
          },
          "workflowArn": {},
          "workflowName": {}
        }
      }
    },
    "GetMatchId": {
      "http": {
        "requestUri": "/matchingworkflows/{workflowName}/matches",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "record",
          "workflowName"
        ],
        "members": {
          "record": {
            "type": "map",
            "key": {},
            "value": {},
            "sensitive": true
          },
          "workflowName": {
            "location": "uri",
            "locationName": "workflowName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "matchId": {}
        }
      }
    },
    "GetMatchingJob": {
      "http": {
        "method": "GET",
        "requestUri": "/matchingworkflows/{workflowName}/jobs/{jobId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId",
          "workflowName"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          },
          "workflowName": {
            "location": "uri",
            "locationName": "workflowName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "jobId",
          "startTime",
          "status"
        ],
        "members": {
          "endTime": {
            "type": "timestamp"
          },
          "errorDetails": {
            "shape": "S1q"
          },
          "jobId": {},
          "metrics": {
            "type": "structure",
            "members": {
              "inputRecords": {
                "type": "integer"
              },
              "matchIDs": {
                "type": "integer"
              },
              "recordsNotProcessed": {
                "type": "integer"
              },
              "totalRecordsProcessed": {
                "type": "integer"
              }
            }
          },
          "startTime": {
            "type": "timestamp"
          },
          "status": {}
        }
      }
    },
    "GetMatchingWorkflow": {
      "http": {
        "method": "GET",
        "requestUri": "/matchingworkflows/{workflowName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workflowName"
        ],
        "members": {
          "workflowName": {
            "location": "uri",
            "locationName": "workflowName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createdAt",
          "inputSourceConfig",
          "outputSourceConfig",
          "resolutionTechniques",
          "roleArn",
          "updatedAt",
          "workflowArn",
          "workflowName"
        ],
        "members": {
          "createdAt": {
            "type": "timestamp"
          },
          "description": {},
          "incrementalRunConfig": {
            "shape": "So"
          },
          "inputSourceConfig": {
            "shape": "Sq"
          },
          "outputSourceConfig": {
            "shape": "Su"
          },
          "resolutionTechniques": {
            "shape": "Sz"
          },
          "roleArn": {},
          "tags": {
            "shape": "Si"
          },
          "updatedAt": {
            "type": "timestamp"
          },
          "workflowArn": {},
          "workflowName": {}
        }
      }
    },
    "GetProviderService": {
      "http": {
        "method": "GET",
        "requestUri": "/providerservices/{providerName}/{providerServiceName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "providerName",
          "providerServiceName"
        ],
        "members": {
          "providerName": {
            "location": "uri",
            "locationName": "providerName"
          },
          "providerServiceName": {
            "location": "uri",
            "locationName": "providerServiceName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "anonymizedOutput",
          "providerEndpointConfiguration",
          "providerEntityOutputDefinition",
          "providerName",
          "providerServiceArn",
          "providerServiceDisplayName",
          "providerServiceName",
          "providerServiceType"
        ],
        "members": {
          "anonymizedOutput": {
            "type": "boolean"
          },
          "providerConfigurationDefinition": {
            "shape": "S8"
          },
          "providerEndpointConfiguration": {
            "type": "structure",
            "members": {
              "marketplaceConfiguration": {
                "type": "structure",
                "required": [
                  "assetId",
                  "dataSetId",
                  "listingId",
                  "revisionId"
                ],
                "members": {
                  "assetId": {},
                  "dataSetId": {},
                  "listingId": {},
                  "revisionId": {}
                }
              }
            },
            "union": true
          },
          "providerEntityOutputDefinition": {
            "shape": "S8"
          },
          "providerIntermediateDataAccessConfiguration": {
            "type": "structure",
            "members": {
              "awsAccountIds": {
                "type": "list",
                "member": {}
              },
              "requiredBucketActions": {
                "type": "list",
                "member": {}
              }
            }
          },
          "providerName": {},
          "providerServiceArn": {},
          "providerServiceDisplayName": {},
          "providerServiceName": {},
          "providerServiceType": {}
        }
      }
    },
    "GetSchemaMapping": {
      "http": {
        "method": "GET",
        "requestUri": "/schemas/{schemaName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "schemaName"
        ],
        "members": {
          "schemaName": {
            "location": "uri",
            "locationName": "schemaName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createdAt",
          "hasWorkflows",
          "mappedInputFields",
          "schemaArn",
          "schemaName",
          "updatedAt"
        ],
        "members": {
          "createdAt": {
            "type": "timestamp"
          },
          "description": {},
          "hasWorkflows": {
            "type": "boolean"
          },
          "mappedInputFields": {
            "shape": "S1b"
          },
          "schemaArn": {},
          "schemaName": {},
          "tags": {
            "shape": "Si"
          },
          "updatedAt": {
            "type": "timestamp"
          }
        }
      }
    },
    "ListIdMappingJobs": {
      "http": {
        "method": "GET",
        "requestUri": "/idmappingworkflows/{workflowName}/jobs",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workflowName"
        ],
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "workflowName": {
            "location": "uri",
            "locationName": "workflowName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobs": {
            "shape": "S2n"
          },
          "nextToken": {}
        }
      }
    },
    "ListIdMappingWorkflows": {
      "http": {
        "method": "GET",
        "requestUri": "/idmappingworkflows",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "workflowSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "createdAt",
                "updatedAt",
                "workflowArn",
                "workflowName"
              ],
              "members": {
                "createdAt": {
                  "type": "timestamp"
                },
                "updatedAt": {
                  "type": "timestamp"
                },
                "workflowArn": {},
                "workflowName": {}
              }
            }
          }
        }
      }
    },
    "ListMatchingJobs": {
      "http": {
        "method": "GET",
        "requestUri": "/matchingworkflows/{workflowName}/jobs",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workflowName"
        ],
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "workflowName": {
            "location": "uri",
            "locationName": "workflowName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobs": {
            "shape": "S2n"
          },
          "nextToken": {}
        }
      }
    },
    "ListMatchingWorkflows": {
      "http": {
        "method": "GET",
        "requestUri": "/matchingworkflows",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "workflowSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "createdAt",
                "resolutionType",
                "updatedAt",
                "workflowArn",
                "workflowName"
              ],
              "members": {
                "createdAt": {
                  "type": "timestamp"
                },
                "resolutionType": {},
                "updatedAt": {
                  "type": "timestamp"
                },
                "workflowArn": {},
                "workflowName": {}
              }
            }
          }
        }
      }
    },
    "ListProviderServices": {
      "http": {
        "method": "GET",
        "requestUri": "/providerservices",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "providerName": {
            "location": "querystring",
            "locationName": "providerName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "providerServiceSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "providerName",
                "providerServiceArn",
                "providerServiceDisplayName",
                "providerServiceName",
                "providerServiceType"
              ],
              "members": {
                "providerName": {},
                "providerServiceArn": {},
                "providerServiceDisplayName": {},
                "providerServiceName": {},
                "providerServiceType": {}
              }
            }
          }
        }
      }
    },
    "ListSchemaMappings": {
      "http": {
        "method": "GET",
        "requestUri": "/schemas",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "schemaList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "createdAt",
                "hasWorkflows",
                "schemaArn",
                "schemaName",
                "updatedAt"
              ],
              "members": {
                "createdAt": {
                  "type": "timestamp"
                },
                "hasWorkflows": {
                  "type": "boolean"
                },
                "schemaArn": {},
                "schemaName": {},
                "updatedAt": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "tags"
        ],
        "members": {
          "tags": {
            "shape": "Si"
          }
        }
      }
    },
    "StartIdMappingJob": {
      "http": {
        "requestUri": "/idmappingworkflows/{workflowName}/jobs",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workflowName"
        ],
        "members": {
          "workflowName": {
            "location": "uri",
            "locationName": "workflowName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "jobId"
        ],
        "members": {
          "jobId": {}
        }
      }
    },
    "StartMatchingJob": {
      "http": {
        "requestUri": "/matchingworkflows/{workflowName}/jobs",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workflowName"
        ],
        "members": {
          "workflowName": {
            "location": "uri",
            "locationName": "workflowName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "jobId"
        ],
        "members": {
          "jobId": {}
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "Si"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateIdMappingWorkflow": {
      "http": {
        "method": "PUT",
        "requestUri": "/idmappingworkflows/{workflowName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "idMappingTechniques",
          "inputSourceConfig",
          "outputSourceConfig",
          "roleArn",
          "workflowName"
        ],
        "members": {
          "description": {},
          "idMappingTechniques": {
            "shape": "S3"
          },
          "inputSourceConfig": {
            "shape": "Sa"
          },
          "outputSourceConfig": {
            "shape": "Se"
          },
          "roleArn": {},
          "workflowName": {
            "location": "uri",
            "locationName": "workflowName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "idMappingTechniques",
          "inputSourceConfig",
          "outputSourceConfig",
          "roleArn",
          "workflowArn",
          "workflowName"
        ],
        "members": {
          "description": {},
          "idMappingTechniques": {
            "shape": "S3"
          },
          "inputSourceConfig": {
            "shape": "Sa"
          },
          "outputSourceConfig": {
            "shape": "Se"
          },
          "roleArn": {},
          "workflowArn": {},
          "workflowName": {}
        }
      },
      "idempotent": true
    },
    "UpdateMatchingWorkflow": {
      "http": {
        "method": "PUT",
        "requestUri": "/matchingworkflows/{workflowName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "inputSourceConfig",
          "outputSourceConfig",
          "resolutionTechniques",
          "roleArn",
          "workflowName"
        ],
        "members": {
          "description": {},
          "incrementalRunConfig": {
            "shape": "So"
          },
          "inputSourceConfig": {
            "shape": "Sq"
          },
          "outputSourceConfig": {
            "shape": "Su"
          },
          "resolutionTechniques": {
            "shape": "Sz"
          },
          "roleArn": {},
          "workflowName": {
            "location": "uri",
            "locationName": "workflowName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "inputSourceConfig",
          "outputSourceConfig",
          "resolutionTechniques",
          "roleArn",
          "workflowName"
        ],
        "members": {
          "description": {},
          "incrementalRunConfig": {
            "shape": "So"
          },
          "inputSourceConfig": {
            "shape": "Sq"
          },
          "outputSourceConfig": {
            "shape": "Su"
          },
          "resolutionTechniques": {
            "shape": "Sz"
          },
          "roleArn": {},
          "workflowName": {}
        }
      },
      "idempotent": true
    },
    "UpdateSchemaMapping": {
      "http": {
        "method": "PUT",
        "requestUri": "/schemas/{schemaName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "mappedInputFields",
          "schemaName"
        ],
        "members": {
          "description": {},
          "mappedInputFields": {
            "shape": "S1b"
          },
          "schemaName": {
            "location": "uri",
            "locationName": "schemaName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "mappedInputFields",
          "schemaArn",
          "schemaName"
        ],
        "members": {
          "description": {},
          "mappedInputFields": {
            "shape": "S1b"
          },
          "schemaArn": {},
          "schemaName": {}
        }
      },
      "idempotent": true
    }
  },
  "shapes": {
    "S3": {
      "type": "structure",
      "required": [
        "idMappingType",
        "providerProperties"
      ],
      "members": {
        "idMappingType": {},
        "providerProperties": {
          "shape": "S5"
        }
      }
    },
    "S5": {
      "type": "structure",
      "required": [
        "providerServiceArn"
      ],
      "members": {
        "intermediateSourceConfiguration": {
          "type": "structure",
          "required": [
            "intermediateS3Path"
          ],
          "members": {
            "intermediateS3Path": {}
          }
        },
        "providerConfiguration": {
          "shape": "S8"
        },
        "providerServiceArn": {}
      }
    },
    "S8": {
      "type": "structure",
      "members": {},
      "document": true
    },
    "Sa": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "inputSourceARN",
          "schemaName"
        ],
        "members": {
          "inputSourceARN": {},
          "schemaName": {}
        }
      }
    },
    "Se": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "outputS3Path"
        ],
        "members": {
          "KMSArn": {},
          "outputS3Path": {}
        }
      }
    },
    "Si": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "So": {
      "type": "structure",
      "members": {
        "incrementalRunType": {}
      }
    },
    "Sq": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "inputSourceARN",
          "schemaName"
        ],
        "members": {
          "applyNormalization": {
            "type": "boolean"
          },
          "inputSourceARN": {},
          "schemaName": {}
        }
      }
    },
    "Su": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "output",
          "outputS3Path"
        ],
        "members": {
          "KMSArn": {},
          "applyNormalization": {
            "type": "boolean"
          },
          "output": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "name"
              ],
              "members": {
                "hashed": {
                  "type": "boolean"
                },
                "name": {}
              }
            }
          },
          "outputS3Path": {}
        }
      }
    },
    "Sz": {
      "type": "structure",
      "required": [
        "resolutionType"
      ],
      "members": {
        "providerProperties": {
          "shape": "S5"
        },
        "resolutionType": {},
        "ruleBasedProperties": {
          "type": "structure",
          "required": [
            "attributeMatchingModel",
            "rules"
          ],
          "members": {
            "attributeMatchingModel": {},
            "rules": {
              "type": "list",
              "member": {
                "type": "structure",
                "required": [
                  "matchingKeys",
                  "ruleName"
                ],
                "members": {
                  "matchingKeys": {
                    "type": "list",
                    "member": {}
                  },
                  "ruleName": {}
                }
              }
            }
          }
        }
      }
    },
    "S1b": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "fieldName",
          "type"
        ],
        "members": {
          "fieldName": {},
          "groupName": {},
          "matchKey": {},
          "subType": {},
          "type": {}
        }
      }
    },
    "S1q": {
      "type": "structure",
      "members": {
        "errorMessage": {}
      }
    },
    "S2n": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "jobId",
          "startTime",
          "status"
        ],
        "members": {
          "endTime": {
            "type": "timestamp"
          },
          "jobId": {},
          "startTime": {
            "type": "timestamp"
          },
          "status": {}
        }
      }
    }
  }
}