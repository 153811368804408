{
  "pagination": {
    "ListAccessTokens": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "items"
    },
    "ListDevEnvironmentSessions": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "items"
    },
    "ListDevEnvironments": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "items"
    },
    "ListEventLogs": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "items"
    },
    "ListProjects": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "items"
    },
    "ListSourceRepositories": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "items"
    },
    "ListSourceRepositoryBranches": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "items"
    },
    "ListSpaces": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "items"
    },
    "ListWorkflowRuns": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "items"
    },
    "ListWorkflows": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "items"
    }
  }
}
