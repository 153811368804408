{
  "pagination": {
    "GetCisScanResultDetails": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "scanResultDetails"
    },
    "ListAccountPermissions": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "permissions"
    },
    "ListCisScanConfigurations": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "scanConfigurations"
    },
    "ListCisScanResultsAggregatedByChecks": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "checkAggregations"
    },
    "ListCisScanResultsAggregatedByTargetResource": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "targetResourceAggregations"
    },
    "ListCisScans": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "scans"
    },
    "ListCoverage": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "coveredResources"
    },
    "ListCoverageStatistics": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "countsByGroup"
    },
    "ListDelegatedAdminAccounts": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "delegatedAdminAccounts"
    },
    "ListFilters": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "filters"
    },
    "ListFindingAggregations": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "responses"
    },
    "ListFindings": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "findings"
    },
    "ListMembers": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "members"
    },
    "ListUsageTotals": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "totals"
    },
    "SearchVulnerabilities": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "vulnerabilities"
    }
  }
}
