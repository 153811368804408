import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "@mui/material";
import { Link as DomLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";

import { AuthContext } from "../contexts/authContext";

interface Props {
    moduleName: string;
    fullWidthPage: boolean;
}

const ResponsiveAppBar = (props: Props): React.ReactElement => {
    // const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    // const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    //   setAnchorElNav(event.currentTarget);
    // };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    // const handleCloseNavMenu = () => {
    //   setAnchorElNav(null);
    // };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const navigate = useNavigate();

    const auth = useContext(AuthContext);

    function signOutClicked() {
        auth.signOut();
        navigate("/");
    }

    const settings = {
        // {label}: {action to take when that label is clicked},
        "Log out": signOutClicked,
    };

    const logo_shell = new URL(
        "../../logos/logo_digital_weather-nobg-cropped.png",
        import.meta.url
    );

    return (
        <AppBar
            position="fixed"
            style={{
                marginBottom: 20,
            }}
        >
            <Container maxWidth={false}>
                <Toolbar
                    disableGutters
                    sx={{
                        maxWidth: props.fullWidthPage ? "100%" : "1200px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <DomLink to="/">
                        <img className="app-bar-logo" alt="home" />
                    </DomLink>
                    <Typography variant="h6" component="div">
                        {props.moduleName}
                    </Typography>
                    <Box sx={{ flexGrow: 0 }}>
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <Avatar alt="John Doe" />
                        </IconButton>
                        <Menu
                            sx={{ mt: "45px" }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {Object.entries(settings).map(([label, action]) => (
                                <MenuItem key={label} onClick={action}>
                                    <Link
                                        component="button"
                                        underline="none"
                                        onClick={action}
                                        color={"black"}
                                    >
                                        {label}
                                    </Link>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default ResponsiveAppBar;
