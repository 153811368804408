{
  "pagination": {
    "ListEnrollmentStatuses": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "items"
    },
    "ListRecommendationSummaries": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "items"
    },
    "ListRecommendations": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "items"
    }
  }
}
