{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2023-07-26",
    "endpointPrefix": "bedrock-agent-runtime",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "Agents for Amazon Bedrock Runtime",
    "serviceId": "Bedrock Agent Runtime",
    "signatureVersion": "v4",
    "signingName": "bedrock",
    "uid": "bedrock-agent-runtime-2023-07-26"
  },
  "operations": {
    "InvokeAgent": {
      "http": {
        "requestUri": "/agents/{agentId}/agentAliases/{agentAliasId}/sessions/{sessionId}/text",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "agentAliasId",
          "agentId",
          "inputText",
          "sessionId"
        ],
        "members": {
          "agentAliasId": {
            "location": "uri",
            "locationName": "agentAliasId"
          },
          "agentId": {
            "location": "uri",
            "locationName": "agentId"
          },
          "enableTrace": {
            "type": "boolean"
          },
          "endSession": {
            "type": "boolean"
          },
          "inputText": {
            "type": "string",
            "sensitive": true
          },
          "sessionId": {
            "location": "uri",
            "locationName": "sessionId"
          },
          "sessionState": {
            "type": "structure",
            "members": {
              "promptSessionAttributes": {
                "type": "map",
                "key": {},
                "value": {}
              },
              "sessionAttributes": {
                "type": "map",
                "key": {},
                "value": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "completion",
          "contentType",
          "sessionId"
        ],
        "members": {
          "completion": {
            "type": "structure",
            "members": {
              "accessDeniedException": {
                "type": "structure",
                "members": {
                  "message": {}
                },
                "error": {
                  "httpStatusCode": 403,
                  "senderFault": true
                },
                "exception": true
              },
              "badGatewayException": {
                "type": "structure",
                "members": {
                  "message": {},
                  "resourceName": {}
                },
                "error": {
                  "httpStatusCode": 502
                },
                "exception": true,
                "fault": true
              },
              "chunk": {
                "type": "structure",
                "members": {
                  "attribution": {
                    "type": "structure",
                    "members": {
                      "citations": {
                        "shape": "Si"
                      }
                    }
                  },
                  "bytes": {
                    "type": "blob",
                    "sensitive": true
                  }
                },
                "event": true,
                "sensitive": true
              },
              "conflictException": {
                "type": "structure",
                "members": {
                  "message": {}
                },
                "error": {
                  "httpStatusCode": 409,
                  "senderFault": true
                },
                "exception": true
              },
              "dependencyFailedException": {
                "type": "structure",
                "members": {
                  "message": {},
                  "resourceName": {}
                },
                "error": {
                  "httpStatusCode": 424,
                  "senderFault": true
                },
                "exception": true
              },
              "internalServerException": {
                "type": "structure",
                "members": {
                  "message": {}
                },
                "error": {
                  "httpStatusCode": 500
                },
                "exception": true,
                "fault": true
              },
              "resourceNotFoundException": {
                "type": "structure",
                "members": {
                  "message": {}
                },
                "error": {
                  "httpStatusCode": 404,
                  "senderFault": true
                },
                "exception": true
              },
              "serviceQuotaExceededException": {
                "type": "structure",
                "members": {
                  "message": {}
                },
                "error": {
                  "httpStatusCode": 400,
                  "senderFault": true
                },
                "exception": true
              },
              "throttlingException": {
                "type": "structure",
                "members": {
                  "message": {}
                },
                "error": {
                  "httpStatusCode": 429,
                  "senderFault": true
                },
                "exception": true
              },
              "trace": {
                "type": "structure",
                "members": {
                  "agentAliasId": {},
                  "agentId": {},
                  "sessionId": {},
                  "trace": {
                    "type": "structure",
                    "members": {
                      "failureTrace": {
                        "type": "structure",
                        "members": {
                          "failureReason": {
                            "type": "string",
                            "sensitive": true
                          },
                          "traceId": {}
                        },
                        "sensitive": true
                      },
                      "orchestrationTrace": {
                        "type": "structure",
                        "members": {
                          "invocationInput": {
                            "type": "structure",
                            "members": {
                              "actionGroupInvocationInput": {
                                "type": "structure",
                                "members": {
                                  "actionGroupName": {
                                    "type": "string",
                                    "sensitive": true
                                  },
                                  "apiPath": {
                                    "type": "string",
                                    "sensitive": true
                                  },
                                  "parameters": {
                                    "shape": "S1f"
                                  },
                                  "requestBody": {
                                    "type": "structure",
                                    "members": {
                                      "content": {
                                        "type": "map",
                                        "key": {},
                                        "value": {
                                          "shape": "S1f"
                                        }
                                      }
                                    }
                                  },
                                  "verb": {
                                    "type": "string",
                                    "sensitive": true
                                  }
                                }
                              },
                              "invocationType": {},
                              "knowledgeBaseLookupInput": {
                                "type": "structure",
                                "members": {
                                  "knowledgeBaseId": {
                                    "type": "string",
                                    "sensitive": true
                                  },
                                  "text": {
                                    "type": "string",
                                    "sensitive": true
                                  }
                                }
                              },
                              "traceId": {}
                            },
                            "sensitive": true
                          },
                          "modelInvocationInput": {
                            "shape": "S1o"
                          },
                          "observation": {
                            "type": "structure",
                            "members": {
                              "actionGroupInvocationOutput": {
                                "type": "structure",
                                "members": {
                                  "text": {
                                    "type": "string",
                                    "sensitive": true
                                  }
                                }
                              },
                              "finalResponse": {
                                "type": "structure",
                                "members": {
                                  "text": {
                                    "type": "string",
                                    "sensitive": true
                                  }
                                }
                              },
                              "knowledgeBaseLookupOutput": {
                                "type": "structure",
                                "members": {
                                  "retrievedReferences": {
                                    "shape": "Sp"
                                  }
                                }
                              },
                              "repromptResponse": {
                                "type": "structure",
                                "members": {
                                  "source": {
                                    "type": "string",
                                    "sensitive": true
                                  },
                                  "text": {}
                                },
                                "sensitive": true
                              },
                              "traceId": {},
                              "type": {}
                            },
                            "sensitive": true
                          },
                          "rationale": {
                            "type": "structure",
                            "members": {
                              "text": {
                                "shape": "S29"
                              },
                              "traceId": {}
                            },
                            "sensitive": true
                          }
                        },
                        "sensitive": true,
                        "union": true
                      },
                      "postProcessingTrace": {
                        "type": "structure",
                        "members": {
                          "modelInvocationInput": {
                            "shape": "S1o"
                          },
                          "modelInvocationOutput": {
                            "type": "structure",
                            "members": {
                              "parsedResponse": {
                                "type": "structure",
                                "members": {
                                  "text": {
                                    "type": "string",
                                    "sensitive": true
                                  }
                                },
                                "sensitive": true
                              },
                              "traceId": {}
                            },
                            "sensitive": true
                          }
                        },
                        "sensitive": true,
                        "union": true
                      },
                      "preProcessingTrace": {
                        "type": "structure",
                        "members": {
                          "modelInvocationInput": {
                            "shape": "S1o"
                          },
                          "modelInvocationOutput": {
                            "type": "structure",
                            "members": {
                              "parsedResponse": {
                                "type": "structure",
                                "members": {
                                  "isValid": {
                                    "type": "boolean"
                                  },
                                  "rationale": {
                                    "shape": "S29"
                                  }
                                },
                                "sensitive": true
                              },
                              "traceId": {}
                            },
                            "sensitive": true
                          }
                        },
                        "sensitive": true,
                        "union": true
                      }
                    },
                    "sensitive": true,
                    "union": true
                  }
                },
                "event": true,
                "sensitive": true
              },
              "validationException": {
                "type": "structure",
                "members": {
                  "message": {}
                },
                "error": {
                  "httpStatusCode": 400,
                  "senderFault": true
                },
                "exception": true
              }
            },
            "eventstream": true
          },
          "contentType": {
            "location": "header",
            "locationName": "x-amzn-bedrock-agent-content-type"
          },
          "sessionId": {
            "location": "header",
            "locationName": "x-amz-bedrock-agent-session-id"
          }
        },
        "payload": "completion"
      }
    },
    "Retrieve": {
      "http": {
        "requestUri": "/knowledgebases/{knowledgeBaseId}/retrieve",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "knowledgeBaseId",
          "retrievalQuery"
        ],
        "members": {
          "knowledgeBaseId": {
            "location": "uri",
            "locationName": "knowledgeBaseId"
          },
          "nextToken": {},
          "retrievalConfiguration": {
            "shape": "S2m"
          },
          "retrievalQuery": {
            "type": "structure",
            "required": [
              "text"
            ],
            "members": {
              "text": {}
            },
            "sensitive": true
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "retrievalResults"
        ],
        "members": {
          "nextToken": {},
          "retrievalResults": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "content"
              ],
              "members": {
                "content": {
                  "shape": "Sr"
                },
                "location": {
                  "shape": "Ss"
                },
                "metadata": {
                  "shape": "Sv"
                },
                "score": {
                  "type": "double"
                }
              }
            },
            "sensitive": true
          }
        }
      }
    },
    "RetrieveAndGenerate": {
      "http": {
        "requestUri": "/retrieveAndGenerate",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "input"
        ],
        "members": {
          "input": {
            "type": "structure",
            "required": [
              "text"
            ],
            "members": {
              "text": {}
            },
            "sensitive": true
          },
          "retrieveAndGenerateConfiguration": {
            "type": "structure",
            "required": [
              "type"
            ],
            "members": {
              "knowledgeBaseConfiguration": {
                "type": "structure",
                "required": [
                  "knowledgeBaseId",
                  "modelArn"
                ],
                "members": {
                  "generationConfiguration": {
                    "type": "structure",
                    "members": {
                      "promptTemplate": {
                        "type": "structure",
                        "members": {
                          "textPromptTemplate": {
                            "type": "string",
                            "sensitive": true
                          }
                        }
                      }
                    }
                  },
                  "knowledgeBaseId": {},
                  "modelArn": {},
                  "retrievalConfiguration": {
                    "shape": "S2m"
                  }
                }
              },
              "type": {}
            }
          },
          "sessionConfiguration": {
            "type": "structure",
            "required": [
              "kmsKeyArn"
            ],
            "members": {
              "kmsKeyArn": {}
            }
          },
          "sessionId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "output",
          "sessionId"
        ],
        "members": {
          "citations": {
            "shape": "Si"
          },
          "output": {
            "type": "structure",
            "required": [
              "text"
            ],
            "members": {
              "text": {}
            },
            "sensitive": true
          },
          "sessionId": {}
        }
      }
    }
  },
  "shapes": {
    "Si": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "generatedResponsePart": {
            "type": "structure",
            "members": {
              "textResponsePart": {
                "type": "structure",
                "members": {
                  "span": {
                    "type": "structure",
                    "members": {
                      "end": {
                        "type": "integer"
                      },
                      "start": {
                        "type": "integer"
                      }
                    }
                  },
                  "text": {}
                },
                "sensitive": true
              }
            }
          },
          "retrievedReferences": {
            "shape": "Sp"
          }
        }
      }
    },
    "Sp": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "content": {
            "shape": "Sr"
          },
          "location": {
            "shape": "Ss"
          },
          "metadata": {
            "shape": "Sv"
          }
        }
      }
    },
    "Sr": {
      "type": "structure",
      "required": [
        "text"
      ],
      "members": {
        "text": {}
      },
      "sensitive": true
    },
    "Ss": {
      "type": "structure",
      "required": [
        "type"
      ],
      "members": {
        "s3Location": {
          "type": "structure",
          "members": {
            "uri": {}
          }
        },
        "type": {}
      },
      "sensitive": true
    },
    "Sv": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {},
        "document": true
      },
      "sensitive": true
    },
    "S1f": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "name": {},
          "type": {},
          "value": {}
        }
      }
    },
    "S1o": {
      "type": "structure",
      "members": {
        "inferenceConfiguration": {
          "type": "structure",
          "members": {
            "maximumLength": {
              "type": "integer"
            },
            "stopSequences": {
              "type": "list",
              "member": {}
            },
            "temperature": {
              "type": "float"
            },
            "topK": {
              "type": "integer"
            },
            "topP": {
              "type": "float"
            }
          }
        },
        "overrideLambda": {},
        "parserMode": {},
        "promptCreationMode": {},
        "text": {
          "type": "string",
          "sensitive": true
        },
        "traceId": {},
        "type": {}
      },
      "sensitive": true
    },
    "S29": {
      "type": "string",
      "sensitive": true
    },
    "S2m": {
      "type": "structure",
      "required": [
        "vectorSearchConfiguration"
      ],
      "members": {
        "vectorSearchConfiguration": {
          "type": "structure",
          "members": {
            "filter": {
              "shape": "S2o"
            },
            "numberOfResults": {
              "type": "integer"
            },
            "overrideSearchType": {}
          }
        }
      }
    },
    "S2o": {
      "type": "structure",
      "members": {
        "andAll": {
          "shape": "S2p"
        },
        "equals": {
          "shape": "S2q"
        },
        "greaterThan": {
          "shape": "S2q"
        },
        "greaterThanOrEquals": {
          "shape": "S2q"
        },
        "in": {
          "shape": "S2q"
        },
        "lessThan": {
          "shape": "S2q"
        },
        "lessThanOrEquals": {
          "shape": "S2q"
        },
        "notEquals": {
          "shape": "S2q"
        },
        "notIn": {
          "shape": "S2q"
        },
        "orAll": {
          "shape": "S2p"
        },
        "startsWith": {
          "shape": "S2q"
        }
      },
      "sensitive": true,
      "union": true
    },
    "S2p": {
      "type": "list",
      "member": {
        "shape": "S2o"
      }
    },
    "S2q": {
      "type": "structure",
      "required": [
        "key",
        "value"
      ],
      "members": {
        "key": {},
        "value": {
          "type": "structure",
          "members": {},
          "document": true
        }
      }
    }
  }
}