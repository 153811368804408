import { SENSITIVE_STRING } from "@smithy/smithy-client";
import { ECSServiceException as __BaseException } from "./ECSServiceException";
export class AccessDeniedException extends __BaseException {
    constructor(opts) {
        super({
            name: "AccessDeniedException",
            $fault: "client",
            ...opts,
        });
        this.name = "AccessDeniedException";
        this.$fault = "client";
        Object.setPrototypeOf(this, AccessDeniedException.prototype);
    }
}
export const AgentUpdateStatus = {
    FAILED: "FAILED",
    PENDING: "PENDING",
    STAGED: "STAGED",
    STAGING: "STAGING",
    UPDATED: "UPDATED",
    UPDATING: "UPDATING",
};
export class ClientException extends __BaseException {
    constructor(opts) {
        super({
            name: "ClientException",
            $fault: "client",
            ...opts,
        });
        this.name = "ClientException";
        this.$fault = "client";
        Object.setPrototypeOf(this, ClientException.prototype);
    }
}
export const ManagedScalingStatus = {
    DISABLED: "DISABLED",
    ENABLED: "ENABLED",
};
export const ManagedTerminationProtection = {
    DISABLED: "DISABLED",
    ENABLED: "ENABLED",
};
export const CapacityProviderStatus = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
};
export const CapacityProviderUpdateStatus = {
    DELETE_COMPLETE: "DELETE_COMPLETE",
    DELETE_FAILED: "DELETE_FAILED",
    DELETE_IN_PROGRESS: "DELETE_IN_PROGRESS",
    UPDATE_COMPLETE: "UPDATE_COMPLETE",
    UPDATE_FAILED: "UPDATE_FAILED",
    UPDATE_IN_PROGRESS: "UPDATE_IN_PROGRESS",
};
export class InvalidParameterException extends __BaseException {
    constructor(opts) {
        super({
            name: "InvalidParameterException",
            $fault: "client",
            ...opts,
        });
        this.name = "InvalidParameterException";
        this.$fault = "client";
        Object.setPrototypeOf(this, InvalidParameterException.prototype);
    }
}
export class LimitExceededException extends __BaseException {
    constructor(opts) {
        super({
            name: "LimitExceededException",
            $fault: "client",
            ...opts,
        });
        this.name = "LimitExceededException";
        this.$fault = "client";
        Object.setPrototypeOf(this, LimitExceededException.prototype);
    }
}
export class ServerException extends __BaseException {
    constructor(opts) {
        super({
            name: "ServerException",
            $fault: "server",
            ...opts,
        });
        this.name = "ServerException";
        this.$fault = "server";
        Object.setPrototypeOf(this, ServerException.prototype);
    }
}
export class UpdateInProgressException extends __BaseException {
    constructor(opts) {
        super({
            name: "UpdateInProgressException",
            $fault: "client",
            ...opts,
        });
        this.name = "UpdateInProgressException";
        this.$fault = "client";
        Object.setPrototypeOf(this, UpdateInProgressException.prototype);
    }
}
export const ExecuteCommandLogging = {
    DEFAULT: "DEFAULT",
    NONE: "NONE",
    OVERRIDE: "OVERRIDE",
};
export const ClusterSettingName = {
    CONTAINER_INSIGHTS: "containerInsights",
};
export class NamespaceNotFoundException extends __BaseException {
    constructor(opts) {
        super({
            name: "NamespaceNotFoundException",
            $fault: "client",
            ...opts,
        });
        this.name = "NamespaceNotFoundException";
        this.$fault = "client";
        Object.setPrototypeOf(this, NamespaceNotFoundException.prototype);
    }
}
export class ClusterNotFoundException extends __BaseException {
    constructor(opts) {
        super({
            name: "ClusterNotFoundException",
            $fault: "client",
            ...opts,
        });
        this.name = "ClusterNotFoundException";
        this.$fault = "client";
        Object.setPrototypeOf(this, ClusterNotFoundException.prototype);
    }
}
export const DeploymentControllerType = {
    CODE_DEPLOY: "CODE_DEPLOY",
    ECS: "ECS",
    EXTERNAL: "EXTERNAL",
};
export const LaunchType = {
    EC2: "EC2",
    EXTERNAL: "EXTERNAL",
    FARGATE: "FARGATE",
};
export const AssignPublicIp = {
    DISABLED: "DISABLED",
    ENABLED: "ENABLED",
};
export const PlacementConstraintType = {
    DISTINCT_INSTANCE: "distinctInstance",
    MEMBER_OF: "memberOf",
};
export const PlacementStrategyType = {
    BINPACK: "binpack",
    RANDOM: "random",
    SPREAD: "spread",
};
export const PropagateTags = {
    NONE: "NONE",
    SERVICE: "SERVICE",
    TASK_DEFINITION: "TASK_DEFINITION",
};
export const SchedulingStrategy = {
    DAEMON: "DAEMON",
    REPLICA: "REPLICA",
};
export const LogDriver = {
    AWSFIRELENS: "awsfirelens",
    AWSLOGS: "awslogs",
    FLUENTD: "fluentd",
    GELF: "gelf",
    JOURNALD: "journald",
    JSON_FILE: "json-file",
    SPLUNK: "splunk",
    SYSLOG: "syslog",
};
export const DeploymentRolloutState = {
    COMPLETED: "COMPLETED",
    FAILED: "FAILED",
    IN_PROGRESS: "IN_PROGRESS",
};
export const ScaleUnit = {
    PERCENT: "PERCENT",
};
export const StabilityStatus = {
    STABILIZING: "STABILIZING",
    STEADY_STATE: "STEADY_STATE",
};
export class PlatformTaskDefinitionIncompatibilityException extends __BaseException {
    constructor(opts) {
        super({
            name: "PlatformTaskDefinitionIncompatibilityException",
            $fault: "client",
            ...opts,
        });
        this.name = "PlatformTaskDefinitionIncompatibilityException";
        this.$fault = "client";
        Object.setPrototypeOf(this, PlatformTaskDefinitionIncompatibilityException.prototype);
    }
}
export class PlatformUnknownException extends __BaseException {
    constructor(opts) {
        super({
            name: "PlatformUnknownException",
            $fault: "client",
            ...opts,
        });
        this.name = "PlatformUnknownException";
        this.$fault = "client";
        Object.setPrototypeOf(this, PlatformUnknownException.prototype);
    }
}
export class UnsupportedFeatureException extends __BaseException {
    constructor(opts) {
        super({
            name: "UnsupportedFeatureException",
            $fault: "client",
            ...opts,
        });
        this.name = "UnsupportedFeatureException";
        this.$fault = "client";
        Object.setPrototypeOf(this, UnsupportedFeatureException.prototype);
    }
}
export class ServiceNotActiveException extends __BaseException {
    constructor(opts) {
        super({
            name: "ServiceNotActiveException",
            $fault: "client",
            ...opts,
        });
        this.name = "ServiceNotActiveException";
        this.$fault = "client";
        Object.setPrototypeOf(this, ServiceNotActiveException.prototype);
    }
}
export class ServiceNotFoundException extends __BaseException {
    constructor(opts) {
        super({
            name: "ServiceNotFoundException",
            $fault: "client",
            ...opts,
        });
        this.name = "ServiceNotFoundException";
        this.$fault = "client";
        Object.setPrototypeOf(this, ServiceNotFoundException.prototype);
    }
}
export const SettingName = {
    AWSVPC_TRUNKING: "awsvpcTrunking",
    CONTAINER_INSIGHTS: "containerInsights",
    CONTAINER_INSTANCE_LONG_ARN_FORMAT: "containerInstanceLongArnFormat",
    FARGATE_FIPS_MODE: "fargateFIPSMode",
    FARGATE_TASK_RETIREMENT_WAIT_PERIOD: "fargateTaskRetirementWaitPeriod",
    GUARD_DUTY_ACTIVATE: "guardDutyActivate",
    SERVICE_LONG_ARN_FORMAT: "serviceLongArnFormat",
    TAG_RESOURCE_AUTHORIZATION: "tagResourceAuthorization",
    TASK_LONG_ARN_FORMAT: "taskLongArnFormat",
};
export const SettingType = {
    AWS_MANAGED: "aws_managed",
    USER: "user",
};
export const TargetType = {
    CONTAINER_INSTANCE: "container-instance",
};
export class TargetNotFoundException extends __BaseException {
    constructor(opts) {
        super({
            name: "TargetNotFoundException",
            $fault: "client",
            ...opts,
        });
        this.name = "TargetNotFoundException";
        this.$fault = "client";
        Object.setPrototypeOf(this, TargetNotFoundException.prototype);
    }
}
export class ClusterContainsContainerInstancesException extends __BaseException {
    constructor(opts) {
        super({
            name: "ClusterContainsContainerInstancesException",
            $fault: "client",
            ...opts,
        });
        this.name = "ClusterContainsContainerInstancesException";
        this.$fault = "client";
        Object.setPrototypeOf(this, ClusterContainsContainerInstancesException.prototype);
    }
}
export class ClusterContainsServicesException extends __BaseException {
    constructor(opts) {
        super({
            name: "ClusterContainsServicesException",
            $fault: "client",
            ...opts,
        });
        this.name = "ClusterContainsServicesException";
        this.$fault = "client";
        Object.setPrototypeOf(this, ClusterContainsServicesException.prototype);
    }
}
export class ClusterContainsTasksException extends __BaseException {
    constructor(opts) {
        super({
            name: "ClusterContainsTasksException",
            $fault: "client",
            ...opts,
        });
        this.name = "ClusterContainsTasksException";
        this.$fault = "client";
        Object.setPrototypeOf(this, ClusterContainsTasksException.prototype);
    }
}
export const Compatibility = {
    EC2: "EC2",
    EXTERNAL: "EXTERNAL",
    FARGATE: "FARGATE",
};
export const ContainerCondition = {
    COMPLETE: "COMPLETE",
    HEALTHY: "HEALTHY",
    START: "START",
    SUCCESS: "SUCCESS",
};
export const EnvironmentFileType = {
    S3: "s3",
};
export const FirelensConfigurationType = {
    FLUENTBIT: "fluentbit",
    FLUENTD: "fluentd",
};
export const DeviceCgroupPermission = {
    MKNOD: "mknod",
    READ: "read",
    WRITE: "write",
};
export const ApplicationProtocol = {
    GRPC: "grpc",
    HTTP: "http",
    HTTP2: "http2",
};
export const TransportProtocol = {
    TCP: "tcp",
    UDP: "udp",
};
export const ResourceType = {
    GPU: "GPU",
    INFERENCE_ACCELERATOR: "InferenceAccelerator",
};
export const UlimitName = {
    CORE: "core",
    CPU: "cpu",
    DATA: "data",
    FSIZE: "fsize",
    LOCKS: "locks",
    MEMLOCK: "memlock",
    MSGQUEUE: "msgqueue",
    NICE: "nice",
    NOFILE: "nofile",
    NPROC: "nproc",
    RSS: "rss",
    RTPRIO: "rtprio",
    RTTIME: "rttime",
    SIGPENDING: "sigpending",
    STACK: "stack",
};
export const IpcMode = {
    HOST: "host",
    NONE: "none",
    TASK: "task",
};
export const NetworkMode = {
    AWSVPC: "awsvpc",
    BRIDGE: "bridge",
    HOST: "host",
    NONE: "none",
};
export const PidMode = {
    HOST: "host",
    TASK: "task",
};
export const TaskDefinitionPlacementConstraintType = {
    MEMBER_OF: "memberOf",
};
export const ProxyConfigurationType = {
    APPMESH: "APPMESH",
};
export const CPUArchitecture = {
    ARM64: "ARM64",
    X86_64: "X86_64",
};
export const OSFamily = {
    LINUX: "LINUX",
    WINDOWS_SERVER_2004_CORE: "WINDOWS_SERVER_2004_CORE",
    WINDOWS_SERVER_2016_FULL: "WINDOWS_SERVER_2016_FULL",
    WINDOWS_SERVER_2019_CORE: "WINDOWS_SERVER_2019_CORE",
    WINDOWS_SERVER_2019_FULL: "WINDOWS_SERVER_2019_FULL",
    WINDOWS_SERVER_2022_CORE: "WINDOWS_SERVER_2022_CORE",
    WINDOWS_SERVER_2022_FULL: "WINDOWS_SERVER_2022_FULL",
    WINDOWS_SERVER_20H2_CORE: "WINDOWS_SERVER_20H2_CORE",
};
export const TaskDefinitionStatus = {
    ACTIVE: "ACTIVE",
    DELETE_IN_PROGRESS: "DELETE_IN_PROGRESS",
    INACTIVE: "INACTIVE",
};
export const Scope = {
    SHARED: "shared",
    TASK: "task",
};
export const EFSAuthorizationConfigIAM = {
    DISABLED: "DISABLED",
    ENABLED: "ENABLED",
};
export const EFSTransitEncryption = {
    DISABLED: "DISABLED",
    ENABLED: "ENABLED",
};
export class TaskSetNotFoundException extends __BaseException {
    constructor(opts) {
        super({
            name: "TaskSetNotFoundException",
            $fault: "client",
            ...opts,
        });
        this.name = "TaskSetNotFoundException";
        this.$fault = "client";
        Object.setPrototypeOf(this, TaskSetNotFoundException.prototype);
    }
}
export const InstanceHealthCheckState = {
    IMPAIRED: "IMPAIRED",
    INITIALIZING: "INITIALIZING",
    INSUFFICIENT_DATA: "INSUFFICIENT_DATA",
    OK: "OK",
};
export const InstanceHealthCheckType = {
    CONTAINER_RUNTIME: "CONTAINER_RUNTIME",
};
export const CapacityProviderField = {
    TAGS: "TAGS",
};
export const ClusterField = {
    ATTACHMENTS: "ATTACHMENTS",
    CONFIGURATIONS: "CONFIGURATIONS",
    SETTINGS: "SETTINGS",
    STATISTICS: "STATISTICS",
    TAGS: "TAGS",
};
export const ContainerInstanceField = {
    CONTAINER_INSTANCE_HEALTH: "CONTAINER_INSTANCE_HEALTH",
    TAGS: "TAGS",
};
export const ServiceField = {
    TAGS: "TAGS",
};
export const TaskDefinitionField = {
    TAGS: "TAGS",
};
export const TaskField = {
    TAGS: "TAGS",
};
export const Connectivity = {
    CONNECTED: "CONNECTED",
    DISCONNECTED: "DISCONNECTED",
};
export const HealthStatus = {
    HEALTHY: "HEALTHY",
    UNHEALTHY: "UNHEALTHY",
    UNKNOWN: "UNKNOWN",
};
export const ManagedAgentName = {
    ExecuteCommandAgent: "ExecuteCommandAgent",
};
export const TaskStopCode = {
    ESSENTIAL_CONTAINER_EXITED: "EssentialContainerExited",
    SERVICE_SCHEDULER_INITIATED: "ServiceSchedulerInitiated",
    SPOT_INTERRUPTION: "SpotInterruption",
    TASK_FAILED_TO_START: "TaskFailedToStart",
    TERMINATION_NOTICE: "TerminationNotice",
    USER_INITIATED: "UserInitiated",
};
export const TaskSetField = {
    TAGS: "TAGS",
};
export class TargetNotConnectedException extends __BaseException {
    constructor(opts) {
        super({
            name: "TargetNotConnectedException",
            $fault: "client",
            ...opts,
        });
        this.name = "TargetNotConnectedException";
        this.$fault = "client";
        Object.setPrototypeOf(this, TargetNotConnectedException.prototype);
    }
}
export class ResourceNotFoundException extends __BaseException {
    constructor(opts) {
        super({
            name: "ResourceNotFoundException",
            $fault: "client",
            ...opts,
        });
        this.name = "ResourceNotFoundException";
        this.$fault = "client";
        Object.setPrototypeOf(this, ResourceNotFoundException.prototype);
    }
}
export const ContainerInstanceStatus = {
    ACTIVE: "ACTIVE",
    DEREGISTERING: "DEREGISTERING",
    DRAINING: "DRAINING",
    REGISTERING: "REGISTERING",
    REGISTRATION_FAILED: "REGISTRATION_FAILED",
};
export const TaskDefinitionFamilyStatus = {
    ACTIVE: "ACTIVE",
    ALL: "ALL",
    INACTIVE: "INACTIVE",
};
export const SortOrder = {
    ASC: "ASC",
    DESC: "DESC",
};
export const DesiredStatus = {
    PENDING: "PENDING",
    RUNNING: "RUNNING",
    STOPPED: "STOPPED",
};
export class AttributeLimitExceededException extends __BaseException {
    constructor(opts) {
        super({
            name: "AttributeLimitExceededException",
            $fault: "client",
            ...opts,
        });
        this.name = "AttributeLimitExceededException";
        this.$fault = "client";
        Object.setPrototypeOf(this, AttributeLimitExceededException.prototype);
    }
}
export class ResourceInUseException extends __BaseException {
    constructor(opts) {
        super({
            name: "ResourceInUseException",
            $fault: "client",
            ...opts,
        });
        this.name = "ResourceInUseException";
        this.$fault = "client";
        Object.setPrototypeOf(this, ResourceInUseException.prototype);
    }
}
export const PlatformDeviceType = {
    GPU: "GPU",
};
export class BlockedException extends __BaseException {
    constructor(opts) {
        super({
            name: "BlockedException",
            $fault: "client",
            ...opts,
        });
        this.name = "BlockedException";
        this.$fault = "client";
        Object.setPrototypeOf(this, BlockedException.prototype);
    }
}
export class ConflictException extends __BaseException {
    constructor(opts) {
        super({
            name: "ConflictException",
            $fault: "client",
            ...opts,
        });
        this.name = "ConflictException";
        this.$fault = "client";
        Object.setPrototypeOf(this, ConflictException.prototype);
        this.resourceIds = opts.resourceIds;
    }
}
export class MissingVersionException extends __BaseException {
    constructor(opts) {
        super({
            name: "MissingVersionException",
            $fault: "client",
            ...opts,
        });
        this.name = "MissingVersionException";
        this.$fault = "client";
        Object.setPrototypeOf(this, MissingVersionException.prototype);
    }
}
export class NoUpdateAvailableException extends __BaseException {
    constructor(opts) {
        super({
            name: "NoUpdateAvailableException",
            $fault: "client",
            ...opts,
        });
        this.name = "NoUpdateAvailableException";
        this.$fault = "client";
        Object.setPrototypeOf(this, NoUpdateAvailableException.prototype);
    }
}
export const SessionFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.tokenValue && { tokenValue: SENSITIVE_STRING }),
});
export const ExecuteCommandResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.session && { session: SessionFilterSensitiveLog(obj.session) }),
});
