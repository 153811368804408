import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import { Checkbox, FormControl, FormHelperText, ListItemText} from '@mui/material/';
import Select, { SelectChangeEvent } from '@mui/material/Select';

function getStyles(name: string, personName: string, theme: Theme) {
    return {
        fontWeight:
        personName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            // : theme.typography.fontWeightMedium,
            : theme.typography.fontWeightRegular,
    };
}

interface Props {
    onChange: (right: string[]) => void;
    name_parameter: string;
    namesOptions: string[];
    error: boolean;
    multiple: boolean;
    disabled: boolean;
}

export default function SelectItems(props: Props) {

    const theme = useTheme();
    const [optionName, setOptionName] = React.useState<string>('');

    const handleChange = (event: SelectChangeEvent<typeof optionName>) => {

        const {
            target: { value },
        } = event;

        setOptionName(value);
        props.onChange(value);
    };

    return (
        <>
        <FormControl variant="outlined" error={props.error} className="input-box">
            <FormHelperText id="outlined-weight-helper-text">
                {props.name_parameter}
            </FormHelperText>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                multiple={props.multiple}
                value={optionName}
                onChange={handleChange}
                disabled={props.disabled}
                renderValue={(selected) => selected}
            >
                {props.namesOptions.map((name) => (
                    <MenuItem
                        key={name}
                        value={name}
                        style={getStyles(name, optionName, theme)}
                    >
                        <Checkbox checked={optionName.indexOf(name) > -1} />
                        {name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
        </>
    );
}