import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Checkbox, FormControlLabel, Paper, Tooltip } from '@mui/material';
import legalClausesForDocuments from '../utils/LegalClausesForDocuments';
import { AuthContext } from '../contexts/authContext';

interface Props {
    isDocumentDialogOpen: boolean, 
    setIsDocumentDialogOpen: (filter: boolean) => void,
    isRestricted: boolean,
    contactEmail: string,
    contactName: string,
    documentName: string,
    documentLink: string,
    documentYear: string,
    userName: string,
}

export default function DocumentDialog(props: Props) {

    const { sessionInfo } = React.useContext(AuthContext);

    const [isTermsAndConditionsScrolledToBottom, setIsTermsAndConditionsScrolledToBottom] = React.useState(false);
    const [isTermsAndConditionsAccepted, setIsTermsAndConditionsAccepted] = React.useState(false);
    const [requestReason, setRequestReason] = React.useState("");
    const [projectOrAssetName, setProjectOrAssetName] = React.useState("");
    const [employmentCountry, setEmploymentCountry] = React.useState("");
    const [connect, setConnect] = React.useState(false);
    const [shellUsername, setShellUsername] = React.useState("");

    // VALIDATION
    const isValid =
        isTermsAndConditionsScrolledToBottom && isTermsAndConditionsAccepted && requestReason.length > 0 && projectOrAssetName.length > 0 && employmentCountry.length > 0;

    const handleClickOpen = () => {
        props.setIsDocumentDialogOpen(true);
    };

    const handleClose = () => {
        props.setIsDocumentDialogOpen(false);
        // reset all inputs
        setIsTermsAndConditionsScrolledToBottom(false);
        setIsTermsAndConditionsAccepted(false);
        setRequestReason("");
        setProjectOrAssetName("");
        setEmploymentCountry("");
        setConnect(false);
    };

    // getSession().then((e) => {
    //     setShellUsername(e['idToken']['payload']['email'].split('@')[0]);
    // })

    // Get recipients first and last name from email
    // let recipientFirstName = props.contactEmail.split('@')[0].split('.')[0]
    // recipientFirstName = recipientFirstName.charAt(0).toUpperCase() + recipientFirstName.slice(1)
    // let recipientLastName = props.contactEmail.split('@')[0].split('.')[1]
    // recipientLastName = recipientLastName.charAt(0).toUpperCase() + recipientLastName.slice(1)

    function getMailtoString(isRestricted: boolean) {

        // In case SharePoint-link should be included again:
        // %20%20%20%20- SharePoint link to the document: ${encodeURIComponent(props.documentLink)}%0D%0A%0D%0A 

        return (
            isRestricted 
            ? 
            `mailto:${props.contactEmail}
            ?subject=Document request from DigitalWeather
            &body=To ${props.contactEmail},%0D%0A%0D%0A
            I would like to contact you for further consultation after downloading the following document:%0D%0A
            %20%20%20%20- Document title: ${encodeURIComponent(props.documentName)}%0D%0A
            %20%20%20%20- Year: ${props.documentYear}%0D%0A
            %20%20%20%20- Confidentiality: Confidential%0D%0A
            %20%20%20%20- Requestor: ${sessionInfo.email}%0D%0A
            %20%20%20%20- Asset/project name for which document will be used: ${encodeURIComponent(projectOrAssetName)}%0D%0A
            %20%20%20%20- Reason for the document request: ${encodeURIComponent(requestReason)}%0D%0A
            %20%20%20%20- Country of employment: ${encodeURIComponent(employmentCountry)}%0D%0A%0D%0A
            Kind regards,%0D%0A
            ${sessionInfo.email}`
            :
            `mailto:${props.contactEmail}
            ?subject=Document request from DigitalWeather
            &body=To ${props.contactEmail},%0D%0A%0D%0A
            I would like to request the following confidential document:%0D%0A
            %20%20%20%20- Document title: ${encodeURIComponent(props.documentName)}%0D%0A
            %20%20%20%20- Year: ${props.documentYear}%0D%0A
            %20%20%20%20- Confidentiality: Confidential%0D%0A
            %20%20%20%20- Requestor: ${sessionInfo.email}%0D%0A
            %20%20%20%20- Asset/project name for which document will be used: ${encodeURIComponent(projectOrAssetName)}%0D%0A
            %20%20%20%20- Reason for the document request: ${encodeURIComponent(requestReason)}%0D%0A
            %20%20%20%20- Country of employment: ${encodeURIComponent(employmentCountry)}%0D%0A
            %20%20%20%20- I would like to connect to the metocean focal point for this document: ${connect ? 'Yes' : 'No'}%0D%0A%0D%0A
            Kind regards,%0D%0A
            ${sessionInfo.email}`
        )
    } 

    function handleScroll(event) {

        if (event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight) {

            setIsTermsAndConditionsScrolledToBottom(true);
            console.log('scrolling...')
        }
    }

    return (
        <React.Fragment>
            {/* <Button variant="outlined" onClick={handleClickOpen}>
                Open form dialog
            </Button> */}
            <Dialog
                open={props.isDocumentDialogOpen}
                onClose={handleClose}
            >
                {/* <DialogTitle>Subscribe</DialogTitle> */}
                <DialogContent sx={{width: '550px'}}>
                    <DialogContentText>
                        *Reason for obtaining the document:
                    </DialogContentText>
                    <TextField
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(e) => {
                            setRequestReason(e.target.value);
                        }}
                    />
                    <br/>
                    <br/>
                    <br/>
                    <DialogContentText>
                        *Name of project/asset you are working on:
                    </DialogContentText>
                    <TextField
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(e) => setProjectOrAssetName(e.target.value)}
                    />
                    <br/>
                    <br/>
                    <br/>
                    <DialogContentText>
                        *Country of employment:
                    </DialogContentText>
                    <TextField
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(e) => setEmploymentCountry(e.target.value)}
                    />
                    <br/>
                    <br/>
                    <br/>
                    {/* <DialogContentText>
                        Terms and conditions:
                    </DialogContentText> */}
                    <Paper 
                        variant="outlined" 
                        onScroll={handleScroll}
                        sx={{maxHeight: '350px', overflowY: 'scroll', padding: '10px'}}
                    >
                        {legalClausesForDocuments}
                    </Paper>
                    <br/>
                    <br/>
                    <br/>
                    <FormControlLabel  
                        control={
                            <Tooltip 
                                title={"You must read the TERMS and CONDITIONS before checking this box."}
                                placement='top'
                            >
                                <span>
                                    <Checkbox 
                                        onClick={() => setIsTermsAndConditionsAccepted(!isTermsAndConditionsAccepted)} 
                                        disabled={!isTermsAndConditionsScrolledToBottom}
                                    />
                                </span>
                            </Tooltip>
                        } 
                        // label="*By checking the box, I declare that have fully read and understood the Articles in this document." 
                        disabled={!isTermsAndConditionsScrolledToBottom}
                        label="*By checking the box, I declare that I have fully read and understood the TERMS and CONDITIONS." 
                    />
                    <FormControlLabel 
                        control={
                            <Checkbox onClick={() => setConnect(!connect)}/>
                        }
                        label="I would like to connect to the metocean focal point associated with this document." 
                        
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button 
                        disabled={!isValid}
                        onClick={() => {
                            if (!props.isRestricted) {
                                // if restricted: prepare email
                                console.log('[LOG] Preparing email');
                                window.location.href = getMailtoString(props.isRestricted);
                            }                             
                            else if (props.isRestricted && connect) {
                                // if not restricted, but contact is requested: download document and prepare email
                                console.log('[LOG] Downloading document and preparing email');
                                window.open(props.documentLink);
                                window.location.href = getMailtoString(props.isRestricted);
                            } else {
                                // else: just download document
                                console.log('[LOG] Downloading document');
                                window.open(props.documentLink);
                            };
                            handleClose();
                        }}
                    >
                        {props.isRestricted ? "Download" : "Request"}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
