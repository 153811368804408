{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-05-10",
    "endpointPrefix": "launchwizard",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "AWS Launch Wizard",
    "serviceId": "Launch Wizard",
    "signatureVersion": "v4",
    "signingName": "launchwizard",
    "uid": "launch-wizard-2018-05-10"
  },
  "operations": {
    "CreateDeployment": {
      "http": {
        "requestUri": "/createDeployment",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "deploymentPatternName",
          "name",
          "specifications",
          "workloadName"
        ],
        "members": {
          "deploymentPatternName": {},
          "dryRun": {
            "type": "boolean"
          },
          "name": {},
          "specifications": {
            "shape": "S5"
          },
          "workloadName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "deploymentId": {}
        }
      }
    },
    "DeleteDeployment": {
      "http": {
        "requestUri": "/deleteDeployment",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "deploymentId"
        ],
        "members": {
          "deploymentId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "status": {},
          "statusReason": {}
        }
      },
      "idempotent": true
    },
    "GetDeployment": {
      "http": {
        "requestUri": "/getDeployment",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "deploymentId"
        ],
        "members": {
          "deploymentId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "deployment": {
            "type": "structure",
            "members": {
              "createdAt": {
                "type": "timestamp"
              },
              "deletedAt": {
                "type": "timestamp"
              },
              "id": {},
              "name": {},
              "patternName": {},
              "resourceGroup": {},
              "specifications": {
                "shape": "S5"
              },
              "status": {},
              "workloadName": {}
            }
          }
        }
      }
    },
    "GetWorkload": {
      "http": {
        "requestUri": "/getWorkload",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workloadName"
        ],
        "members": {
          "workloadName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "workload": {
            "type": "structure",
            "members": {
              "description": {},
              "displayName": {},
              "documentationUrl": {},
              "iconUrl": {},
              "status": {},
              "statusMessage": {},
              "workloadName": {}
            }
          }
        }
      }
    },
    "ListDeploymentEvents": {
      "http": {
        "requestUri": "/listDeploymentEvents",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "deploymentId"
        ],
        "members": {
          "deploymentId": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "deploymentEvents": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "description": {},
                "name": {},
                "status": {},
                "statusReason": {},
                "timestamp": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListDeployments": {
      "http": {
        "requestUri": "/listDeployments",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "deployments": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "createdAt": {
                  "type": "timestamp"
                },
                "id": {},
                "name": {},
                "patternName": {},
                "status": {},
                "workloadName": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListWorkloadDeploymentPatterns": {
      "http": {
        "requestUri": "/listWorkloadDeploymentPatterns",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "workloadName"
        ],
        "members": {
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "workloadName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "workloadDeploymentPatterns": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "deploymentPatternName": {},
                "description": {},
                "displayName": {},
                "status": {},
                "statusMessage": {},
                "workloadName": {},
                "workloadVersionName": {}
              }
            }
          }
        }
      }
    },
    "ListWorkloads": {
      "http": {
        "requestUri": "/listWorkloads",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "workloads": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "displayName": {},
                "workloadName": {}
              }
            }
          }
        }
      }
    }
  },
  "shapes": {
    "S5": {
      "type": "map",
      "key": {},
      "value": {},
      "sensitive": true
    }
  }
}