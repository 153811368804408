{
  "pagination": {
    "ListAccelerators": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "Accelerators"
    },
    "ListByoipCidrs": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "ByoipCidrs"
    },
    "ListCrossAccountAttachments": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "CrossAccountAttachments"
    },
    "ListCrossAccountResources": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "CrossAccountResources"
    },
    "ListCustomRoutingAccelerators": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "Accelerators"
    },
    "ListCustomRoutingEndpointGroups": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "EndpointGroups"
    },
    "ListCustomRoutingListeners": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "Listeners"
    },
    "ListCustomRoutingPortMappings": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "PortMappings"
    },
    "ListCustomRoutingPortMappingsByDestination": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "DestinationPortMappings"
    },
    "ListEndpointGroups": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "EndpointGroups"
    },
    "ListListeners": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "Listeners"
    }
  }
}
