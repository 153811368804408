import { HttpRequest as __HttpRequest } from "@smithy/protocol-http";
import { _json, collectBody, decorateServiceException as __decorateServiceException, expectBoolean as __expectBoolean, expectInt32 as __expectInt32, expectLong as __expectLong, expectNonNull as __expectNonNull, expectNumber as __expectNumber, expectString as __expectString, limitedParseDouble as __limitedParseDouble, parseEpochTimestamp as __parseEpochTimestamp, serializeFloat as __serializeFloat, take, withBaseException, } from "@smithy/smithy-client";
import { v4 as generateIdempotencyToken } from "uuid";
import { ECSServiceException as __BaseException } from "../models/ECSServiceException";
import { AccessDeniedException, AttributeLimitExceededException, BlockedException, ClientException, ClusterContainsContainerInstancesException, ClusterContainsServicesException, ClusterContainsTasksException, ClusterNotFoundException, ConflictException, InvalidParameterException, LimitExceededException, MissingVersionException, NamespaceNotFoundException, NoUpdateAvailableException, PlatformTaskDefinitionIncompatibilityException, PlatformUnknownException, ResourceInUseException, ResourceNotFoundException, ServerException, ServiceNotActiveException, ServiceNotFoundException, TargetNotConnectedException, TargetNotFoundException, TaskSetNotFoundException, UnsupportedFeatureException, UpdateInProgressException, } from "../models/models_0";
export const se_CreateCapacityProviderCommand = async (input, context) => {
    const headers = sharedHeaders("CreateCapacityProvider");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_CreateClusterCommand = async (input, context) => {
    const headers = sharedHeaders("CreateCluster");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_CreateServiceCommand = async (input, context) => {
    const headers = sharedHeaders("CreateService");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_CreateTaskSetCommand = async (input, context) => {
    const headers = sharedHeaders("CreateTaskSet");
    let body;
    body = JSON.stringify(se_CreateTaskSetRequest(input, context));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_DeleteAccountSettingCommand = async (input, context) => {
    const headers = sharedHeaders("DeleteAccountSetting");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_DeleteAttributesCommand = async (input, context) => {
    const headers = sharedHeaders("DeleteAttributes");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_DeleteCapacityProviderCommand = async (input, context) => {
    const headers = sharedHeaders("DeleteCapacityProvider");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_DeleteClusterCommand = async (input, context) => {
    const headers = sharedHeaders("DeleteCluster");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_DeleteServiceCommand = async (input, context) => {
    const headers = sharedHeaders("DeleteService");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_DeleteTaskDefinitionsCommand = async (input, context) => {
    const headers = sharedHeaders("DeleteTaskDefinitions");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_DeleteTaskSetCommand = async (input, context) => {
    const headers = sharedHeaders("DeleteTaskSet");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_DeregisterContainerInstanceCommand = async (input, context) => {
    const headers = sharedHeaders("DeregisterContainerInstance");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_DeregisterTaskDefinitionCommand = async (input, context) => {
    const headers = sharedHeaders("DeregisterTaskDefinition");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_DescribeCapacityProvidersCommand = async (input, context) => {
    const headers = sharedHeaders("DescribeCapacityProviders");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_DescribeClustersCommand = async (input, context) => {
    const headers = sharedHeaders("DescribeClusters");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_DescribeContainerInstancesCommand = async (input, context) => {
    const headers = sharedHeaders("DescribeContainerInstances");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_DescribeServicesCommand = async (input, context) => {
    const headers = sharedHeaders("DescribeServices");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_DescribeTaskDefinitionCommand = async (input, context) => {
    const headers = sharedHeaders("DescribeTaskDefinition");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_DescribeTasksCommand = async (input, context) => {
    const headers = sharedHeaders("DescribeTasks");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_DescribeTaskSetsCommand = async (input, context) => {
    const headers = sharedHeaders("DescribeTaskSets");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_DiscoverPollEndpointCommand = async (input, context) => {
    const headers = sharedHeaders("DiscoverPollEndpoint");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_ExecuteCommandCommand = async (input, context) => {
    const headers = sharedHeaders("ExecuteCommand");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_GetTaskProtectionCommand = async (input, context) => {
    const headers = sharedHeaders("GetTaskProtection");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_ListAccountSettingsCommand = async (input, context) => {
    const headers = sharedHeaders("ListAccountSettings");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_ListAttributesCommand = async (input, context) => {
    const headers = sharedHeaders("ListAttributes");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_ListClustersCommand = async (input, context) => {
    const headers = sharedHeaders("ListClusters");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_ListContainerInstancesCommand = async (input, context) => {
    const headers = sharedHeaders("ListContainerInstances");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_ListServicesCommand = async (input, context) => {
    const headers = sharedHeaders("ListServices");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_ListServicesByNamespaceCommand = async (input, context) => {
    const headers = sharedHeaders("ListServicesByNamespace");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_ListTagsForResourceCommand = async (input, context) => {
    const headers = sharedHeaders("ListTagsForResource");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_ListTaskDefinitionFamiliesCommand = async (input, context) => {
    const headers = sharedHeaders("ListTaskDefinitionFamilies");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_ListTaskDefinitionsCommand = async (input, context) => {
    const headers = sharedHeaders("ListTaskDefinitions");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_ListTasksCommand = async (input, context) => {
    const headers = sharedHeaders("ListTasks");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_PutAccountSettingCommand = async (input, context) => {
    const headers = sharedHeaders("PutAccountSetting");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_PutAccountSettingDefaultCommand = async (input, context) => {
    const headers = sharedHeaders("PutAccountSettingDefault");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_PutAttributesCommand = async (input, context) => {
    const headers = sharedHeaders("PutAttributes");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_PutClusterCapacityProvidersCommand = async (input, context) => {
    const headers = sharedHeaders("PutClusterCapacityProviders");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_RegisterContainerInstanceCommand = async (input, context) => {
    const headers = sharedHeaders("RegisterContainerInstance");
    let body;
    body = JSON.stringify(se_RegisterContainerInstanceRequest(input, context));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_RegisterTaskDefinitionCommand = async (input, context) => {
    const headers = sharedHeaders("RegisterTaskDefinition");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_RunTaskCommand = async (input, context) => {
    const headers = sharedHeaders("RunTask");
    let body;
    body = JSON.stringify(se_RunTaskRequest(input, context));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_StartTaskCommand = async (input, context) => {
    const headers = sharedHeaders("StartTask");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_StopTaskCommand = async (input, context) => {
    const headers = sharedHeaders("StopTask");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_SubmitAttachmentStateChangesCommand = async (input, context) => {
    const headers = sharedHeaders("SubmitAttachmentStateChanges");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_SubmitContainerStateChangeCommand = async (input, context) => {
    const headers = sharedHeaders("SubmitContainerStateChange");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_SubmitTaskStateChangeCommand = async (input, context) => {
    const headers = sharedHeaders("SubmitTaskStateChange");
    let body;
    body = JSON.stringify(se_SubmitTaskStateChangeRequest(input, context));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_TagResourceCommand = async (input, context) => {
    const headers = sharedHeaders("TagResource");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_UntagResourceCommand = async (input, context) => {
    const headers = sharedHeaders("UntagResource");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_UpdateCapacityProviderCommand = async (input, context) => {
    const headers = sharedHeaders("UpdateCapacityProvider");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_UpdateClusterCommand = async (input, context) => {
    const headers = sharedHeaders("UpdateCluster");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_UpdateClusterSettingsCommand = async (input, context) => {
    const headers = sharedHeaders("UpdateClusterSettings");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_UpdateContainerAgentCommand = async (input, context) => {
    const headers = sharedHeaders("UpdateContainerAgent");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_UpdateContainerInstancesStateCommand = async (input, context) => {
    const headers = sharedHeaders("UpdateContainerInstancesState");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_UpdateServiceCommand = async (input, context) => {
    const headers = sharedHeaders("UpdateService");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_UpdateServicePrimaryTaskSetCommand = async (input, context) => {
    const headers = sharedHeaders("UpdateServicePrimaryTaskSet");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_UpdateTaskProtectionCommand = async (input, context) => {
    const headers = sharedHeaders("UpdateTaskProtection");
    let body;
    body = JSON.stringify(_json(input));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_UpdateTaskSetCommand = async (input, context) => {
    const headers = sharedHeaders("UpdateTaskSet");
    let body;
    body = JSON.stringify(se_UpdateTaskSetRequest(input, context));
    return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const de_CreateCapacityProviderCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_CreateCapacityProviderCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_CreateCapacityProviderCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "LimitExceededException":
        case "com.amazonaws.ecs#LimitExceededException":
            throw await de_LimitExceededExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        case "UpdateInProgressException":
        case "com.amazonaws.ecs#UpdateInProgressException":
            throw await de_UpdateInProgressExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_CreateClusterCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_CreateClusterCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_CreateClusterCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "NamespaceNotFoundException":
        case "com.amazonaws.ecs#NamespaceNotFoundException":
            throw await de_NamespaceNotFoundExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_CreateServiceCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_CreateServiceCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = de_CreateServiceResponse(data, context);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_CreateServiceCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "AccessDeniedException":
        case "com.amazonaws.ecs#AccessDeniedException":
            throw await de_AccessDeniedExceptionRes(parsedOutput, context);
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "ClusterNotFoundException":
        case "com.amazonaws.ecs#ClusterNotFoundException":
            throw await de_ClusterNotFoundExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "NamespaceNotFoundException":
        case "com.amazonaws.ecs#NamespaceNotFoundException":
            throw await de_NamespaceNotFoundExceptionRes(parsedOutput, context);
        case "PlatformTaskDefinitionIncompatibilityException":
        case "com.amazonaws.ecs#PlatformTaskDefinitionIncompatibilityException":
            throw await de_PlatformTaskDefinitionIncompatibilityExceptionRes(parsedOutput, context);
        case "PlatformUnknownException":
        case "com.amazonaws.ecs#PlatformUnknownException":
            throw await de_PlatformUnknownExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        case "UnsupportedFeatureException":
        case "com.amazonaws.ecs#UnsupportedFeatureException":
            throw await de_UnsupportedFeatureExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_CreateTaskSetCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_CreateTaskSetCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = de_CreateTaskSetResponse(data, context);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_CreateTaskSetCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "AccessDeniedException":
        case "com.amazonaws.ecs#AccessDeniedException":
            throw await de_AccessDeniedExceptionRes(parsedOutput, context);
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "ClusterNotFoundException":
        case "com.amazonaws.ecs#ClusterNotFoundException":
            throw await de_ClusterNotFoundExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "NamespaceNotFoundException":
        case "com.amazonaws.ecs#NamespaceNotFoundException":
            throw await de_NamespaceNotFoundExceptionRes(parsedOutput, context);
        case "PlatformTaskDefinitionIncompatibilityException":
        case "com.amazonaws.ecs#PlatformTaskDefinitionIncompatibilityException":
            throw await de_PlatformTaskDefinitionIncompatibilityExceptionRes(parsedOutput, context);
        case "PlatformUnknownException":
        case "com.amazonaws.ecs#PlatformUnknownException":
            throw await de_PlatformUnknownExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        case "ServiceNotActiveException":
        case "com.amazonaws.ecs#ServiceNotActiveException":
            throw await de_ServiceNotActiveExceptionRes(parsedOutput, context);
        case "ServiceNotFoundException":
        case "com.amazonaws.ecs#ServiceNotFoundException":
            throw await de_ServiceNotFoundExceptionRes(parsedOutput, context);
        case "UnsupportedFeatureException":
        case "com.amazonaws.ecs#UnsupportedFeatureException":
            throw await de_UnsupportedFeatureExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_DeleteAccountSettingCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_DeleteAccountSettingCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_DeleteAccountSettingCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_DeleteAttributesCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_DeleteAttributesCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_DeleteAttributesCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClusterNotFoundException":
        case "com.amazonaws.ecs#ClusterNotFoundException":
            throw await de_ClusterNotFoundExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "TargetNotFoundException":
        case "com.amazonaws.ecs#TargetNotFoundException":
            throw await de_TargetNotFoundExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_DeleteCapacityProviderCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_DeleteCapacityProviderCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_DeleteCapacityProviderCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_DeleteClusterCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_DeleteClusterCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_DeleteClusterCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "ClusterContainsContainerInstancesException":
        case "com.amazonaws.ecs#ClusterContainsContainerInstancesException":
            throw await de_ClusterContainsContainerInstancesExceptionRes(parsedOutput, context);
        case "ClusterContainsServicesException":
        case "com.amazonaws.ecs#ClusterContainsServicesException":
            throw await de_ClusterContainsServicesExceptionRes(parsedOutput, context);
        case "ClusterContainsTasksException":
        case "com.amazonaws.ecs#ClusterContainsTasksException":
            throw await de_ClusterContainsTasksExceptionRes(parsedOutput, context);
        case "ClusterNotFoundException":
        case "com.amazonaws.ecs#ClusterNotFoundException":
            throw await de_ClusterNotFoundExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        case "UpdateInProgressException":
        case "com.amazonaws.ecs#UpdateInProgressException":
            throw await de_UpdateInProgressExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_DeleteServiceCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_DeleteServiceCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = de_DeleteServiceResponse(data, context);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_DeleteServiceCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "ClusterNotFoundException":
        case "com.amazonaws.ecs#ClusterNotFoundException":
            throw await de_ClusterNotFoundExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        case "ServiceNotFoundException":
        case "com.amazonaws.ecs#ServiceNotFoundException":
            throw await de_ServiceNotFoundExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_DeleteTaskDefinitionsCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_DeleteTaskDefinitionsCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = de_DeleteTaskDefinitionsResponse(data, context);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_DeleteTaskDefinitionsCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "AccessDeniedException":
        case "com.amazonaws.ecs#AccessDeniedException":
            throw await de_AccessDeniedExceptionRes(parsedOutput, context);
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_DeleteTaskSetCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_DeleteTaskSetCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = de_DeleteTaskSetResponse(data, context);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_DeleteTaskSetCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "AccessDeniedException":
        case "com.amazonaws.ecs#AccessDeniedException":
            throw await de_AccessDeniedExceptionRes(parsedOutput, context);
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "ClusterNotFoundException":
        case "com.amazonaws.ecs#ClusterNotFoundException":
            throw await de_ClusterNotFoundExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        case "ServiceNotActiveException":
        case "com.amazonaws.ecs#ServiceNotActiveException":
            throw await de_ServiceNotActiveExceptionRes(parsedOutput, context);
        case "ServiceNotFoundException":
        case "com.amazonaws.ecs#ServiceNotFoundException":
            throw await de_ServiceNotFoundExceptionRes(parsedOutput, context);
        case "TaskSetNotFoundException":
        case "com.amazonaws.ecs#TaskSetNotFoundException":
            throw await de_TaskSetNotFoundExceptionRes(parsedOutput, context);
        case "UnsupportedFeatureException":
        case "com.amazonaws.ecs#UnsupportedFeatureException":
            throw await de_UnsupportedFeatureExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_DeregisterContainerInstanceCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_DeregisterContainerInstanceCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = de_DeregisterContainerInstanceResponse(data, context);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_DeregisterContainerInstanceCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "ClusterNotFoundException":
        case "com.amazonaws.ecs#ClusterNotFoundException":
            throw await de_ClusterNotFoundExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_DeregisterTaskDefinitionCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_DeregisterTaskDefinitionCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = de_DeregisterTaskDefinitionResponse(data, context);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_DeregisterTaskDefinitionCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_DescribeCapacityProvidersCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_DescribeCapacityProvidersCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_DescribeCapacityProvidersCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_DescribeClustersCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_DescribeClustersCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_DescribeClustersCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_DescribeContainerInstancesCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_DescribeContainerInstancesCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = de_DescribeContainerInstancesResponse(data, context);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_DescribeContainerInstancesCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "ClusterNotFoundException":
        case "com.amazonaws.ecs#ClusterNotFoundException":
            throw await de_ClusterNotFoundExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_DescribeServicesCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_DescribeServicesCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = de_DescribeServicesResponse(data, context);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_DescribeServicesCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "ClusterNotFoundException":
        case "com.amazonaws.ecs#ClusterNotFoundException":
            throw await de_ClusterNotFoundExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_DescribeTaskDefinitionCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_DescribeTaskDefinitionCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = de_DescribeTaskDefinitionResponse(data, context);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_DescribeTaskDefinitionCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_DescribeTasksCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_DescribeTasksCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = de_DescribeTasksResponse(data, context);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_DescribeTasksCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "ClusterNotFoundException":
        case "com.amazonaws.ecs#ClusterNotFoundException":
            throw await de_ClusterNotFoundExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_DescribeTaskSetsCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_DescribeTaskSetsCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = de_DescribeTaskSetsResponse(data, context);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_DescribeTaskSetsCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "AccessDeniedException":
        case "com.amazonaws.ecs#AccessDeniedException":
            throw await de_AccessDeniedExceptionRes(parsedOutput, context);
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "ClusterNotFoundException":
        case "com.amazonaws.ecs#ClusterNotFoundException":
            throw await de_ClusterNotFoundExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        case "ServiceNotActiveException":
        case "com.amazonaws.ecs#ServiceNotActiveException":
            throw await de_ServiceNotActiveExceptionRes(parsedOutput, context);
        case "ServiceNotFoundException":
        case "com.amazonaws.ecs#ServiceNotFoundException":
            throw await de_ServiceNotFoundExceptionRes(parsedOutput, context);
        case "UnsupportedFeatureException":
        case "com.amazonaws.ecs#UnsupportedFeatureException":
            throw await de_UnsupportedFeatureExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_DiscoverPollEndpointCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_DiscoverPollEndpointCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_DiscoverPollEndpointCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_ExecuteCommandCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_ExecuteCommandCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_ExecuteCommandCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "AccessDeniedException":
        case "com.amazonaws.ecs#AccessDeniedException":
            throw await de_AccessDeniedExceptionRes(parsedOutput, context);
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "ClusterNotFoundException":
        case "com.amazonaws.ecs#ClusterNotFoundException":
            throw await de_ClusterNotFoundExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        case "TargetNotConnectedException":
        case "com.amazonaws.ecs#TargetNotConnectedException":
            throw await de_TargetNotConnectedExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_GetTaskProtectionCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_GetTaskProtectionCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = de_GetTaskProtectionResponse(data, context);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_GetTaskProtectionCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "AccessDeniedException":
        case "com.amazonaws.ecs#AccessDeniedException":
            throw await de_AccessDeniedExceptionRes(parsedOutput, context);
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "ClusterNotFoundException":
        case "com.amazonaws.ecs#ClusterNotFoundException":
            throw await de_ClusterNotFoundExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ResourceNotFoundException":
        case "com.amazonaws.ecs#ResourceNotFoundException":
            throw await de_ResourceNotFoundExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        case "UnsupportedFeatureException":
        case "com.amazonaws.ecs#UnsupportedFeatureException":
            throw await de_UnsupportedFeatureExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_ListAccountSettingsCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_ListAccountSettingsCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_ListAccountSettingsCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_ListAttributesCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_ListAttributesCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_ListAttributesCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClusterNotFoundException":
        case "com.amazonaws.ecs#ClusterNotFoundException":
            throw await de_ClusterNotFoundExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_ListClustersCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_ListClustersCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_ListClustersCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_ListContainerInstancesCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_ListContainerInstancesCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_ListContainerInstancesCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "ClusterNotFoundException":
        case "com.amazonaws.ecs#ClusterNotFoundException":
            throw await de_ClusterNotFoundExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_ListServicesCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_ListServicesCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_ListServicesCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "ClusterNotFoundException":
        case "com.amazonaws.ecs#ClusterNotFoundException":
            throw await de_ClusterNotFoundExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_ListServicesByNamespaceCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_ListServicesByNamespaceCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_ListServicesByNamespaceCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "NamespaceNotFoundException":
        case "com.amazonaws.ecs#NamespaceNotFoundException":
            throw await de_NamespaceNotFoundExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_ListTagsForResourceCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_ListTagsForResourceCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_ListTagsForResourceCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "ClusterNotFoundException":
        case "com.amazonaws.ecs#ClusterNotFoundException":
            throw await de_ClusterNotFoundExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_ListTaskDefinitionFamiliesCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_ListTaskDefinitionFamiliesCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_ListTaskDefinitionFamiliesCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_ListTaskDefinitionsCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_ListTaskDefinitionsCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_ListTaskDefinitionsCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_ListTasksCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_ListTasksCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_ListTasksCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "ClusterNotFoundException":
        case "com.amazonaws.ecs#ClusterNotFoundException":
            throw await de_ClusterNotFoundExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        case "ServiceNotFoundException":
        case "com.amazonaws.ecs#ServiceNotFoundException":
            throw await de_ServiceNotFoundExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_PutAccountSettingCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_PutAccountSettingCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_PutAccountSettingCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_PutAccountSettingDefaultCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_PutAccountSettingDefaultCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_PutAccountSettingDefaultCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_PutAttributesCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_PutAttributesCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_PutAttributesCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "AttributeLimitExceededException":
        case "com.amazonaws.ecs#AttributeLimitExceededException":
            throw await de_AttributeLimitExceededExceptionRes(parsedOutput, context);
        case "ClusterNotFoundException":
        case "com.amazonaws.ecs#ClusterNotFoundException":
            throw await de_ClusterNotFoundExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "TargetNotFoundException":
        case "com.amazonaws.ecs#TargetNotFoundException":
            throw await de_TargetNotFoundExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_PutClusterCapacityProvidersCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_PutClusterCapacityProvidersCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_PutClusterCapacityProvidersCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "ClusterNotFoundException":
        case "com.amazonaws.ecs#ClusterNotFoundException":
            throw await de_ClusterNotFoundExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ResourceInUseException":
        case "com.amazonaws.ecs#ResourceInUseException":
            throw await de_ResourceInUseExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        case "UpdateInProgressException":
        case "com.amazonaws.ecs#UpdateInProgressException":
            throw await de_UpdateInProgressExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_RegisterContainerInstanceCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_RegisterContainerInstanceCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = de_RegisterContainerInstanceResponse(data, context);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_RegisterContainerInstanceCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_RegisterTaskDefinitionCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_RegisterTaskDefinitionCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = de_RegisterTaskDefinitionResponse(data, context);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_RegisterTaskDefinitionCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_RunTaskCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_RunTaskCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = de_RunTaskResponse(data, context);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_RunTaskCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "AccessDeniedException":
        case "com.amazonaws.ecs#AccessDeniedException":
            throw await de_AccessDeniedExceptionRes(parsedOutput, context);
        case "BlockedException":
        case "com.amazonaws.ecs#BlockedException":
            throw await de_BlockedExceptionRes(parsedOutput, context);
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "ClusterNotFoundException":
        case "com.amazonaws.ecs#ClusterNotFoundException":
            throw await de_ClusterNotFoundExceptionRes(parsedOutput, context);
        case "ConflictException":
        case "com.amazonaws.ecs#ConflictException":
            throw await de_ConflictExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "PlatformTaskDefinitionIncompatibilityException":
        case "com.amazonaws.ecs#PlatformTaskDefinitionIncompatibilityException":
            throw await de_PlatformTaskDefinitionIncompatibilityExceptionRes(parsedOutput, context);
        case "PlatformUnknownException":
        case "com.amazonaws.ecs#PlatformUnknownException":
            throw await de_PlatformUnknownExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        case "UnsupportedFeatureException":
        case "com.amazonaws.ecs#UnsupportedFeatureException":
            throw await de_UnsupportedFeatureExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_StartTaskCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_StartTaskCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = de_StartTaskResponse(data, context);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_StartTaskCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "ClusterNotFoundException":
        case "com.amazonaws.ecs#ClusterNotFoundException":
            throw await de_ClusterNotFoundExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_StopTaskCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_StopTaskCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = de_StopTaskResponse(data, context);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_StopTaskCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "ClusterNotFoundException":
        case "com.amazonaws.ecs#ClusterNotFoundException":
            throw await de_ClusterNotFoundExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_SubmitAttachmentStateChangesCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_SubmitAttachmentStateChangesCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_SubmitAttachmentStateChangesCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "AccessDeniedException":
        case "com.amazonaws.ecs#AccessDeniedException":
            throw await de_AccessDeniedExceptionRes(parsedOutput, context);
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_SubmitContainerStateChangeCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_SubmitContainerStateChangeCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_SubmitContainerStateChangeCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "AccessDeniedException":
        case "com.amazonaws.ecs#AccessDeniedException":
            throw await de_AccessDeniedExceptionRes(parsedOutput, context);
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_SubmitTaskStateChangeCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_SubmitTaskStateChangeCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_SubmitTaskStateChangeCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "AccessDeniedException":
        case "com.amazonaws.ecs#AccessDeniedException":
            throw await de_AccessDeniedExceptionRes(parsedOutput, context);
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_TagResourceCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_TagResourceCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_TagResourceCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "ClusterNotFoundException":
        case "com.amazonaws.ecs#ClusterNotFoundException":
            throw await de_ClusterNotFoundExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ResourceNotFoundException":
        case "com.amazonaws.ecs#ResourceNotFoundException":
            throw await de_ResourceNotFoundExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_UntagResourceCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_UntagResourceCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_UntagResourceCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "ClusterNotFoundException":
        case "com.amazonaws.ecs#ClusterNotFoundException":
            throw await de_ClusterNotFoundExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ResourceNotFoundException":
        case "com.amazonaws.ecs#ResourceNotFoundException":
            throw await de_ResourceNotFoundExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_UpdateCapacityProviderCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_UpdateCapacityProviderCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_UpdateCapacityProviderCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_UpdateClusterCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_UpdateClusterCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_UpdateClusterCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "ClusterNotFoundException":
        case "com.amazonaws.ecs#ClusterNotFoundException":
            throw await de_ClusterNotFoundExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "NamespaceNotFoundException":
        case "com.amazonaws.ecs#NamespaceNotFoundException":
            throw await de_NamespaceNotFoundExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_UpdateClusterSettingsCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_UpdateClusterSettingsCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = _json(data);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_UpdateClusterSettingsCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "ClusterNotFoundException":
        case "com.amazonaws.ecs#ClusterNotFoundException":
            throw await de_ClusterNotFoundExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_UpdateContainerAgentCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_UpdateContainerAgentCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = de_UpdateContainerAgentResponse(data, context);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_UpdateContainerAgentCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "ClusterNotFoundException":
        case "com.amazonaws.ecs#ClusterNotFoundException":
            throw await de_ClusterNotFoundExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "MissingVersionException":
        case "com.amazonaws.ecs#MissingVersionException":
            throw await de_MissingVersionExceptionRes(parsedOutput, context);
        case "NoUpdateAvailableException":
        case "com.amazonaws.ecs#NoUpdateAvailableException":
            throw await de_NoUpdateAvailableExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        case "UpdateInProgressException":
        case "com.amazonaws.ecs#UpdateInProgressException":
            throw await de_UpdateInProgressExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_UpdateContainerInstancesStateCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_UpdateContainerInstancesStateCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = de_UpdateContainerInstancesStateResponse(data, context);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_UpdateContainerInstancesStateCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "ClusterNotFoundException":
        case "com.amazonaws.ecs#ClusterNotFoundException":
            throw await de_ClusterNotFoundExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_UpdateServiceCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_UpdateServiceCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = de_UpdateServiceResponse(data, context);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_UpdateServiceCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "AccessDeniedException":
        case "com.amazonaws.ecs#AccessDeniedException":
            throw await de_AccessDeniedExceptionRes(parsedOutput, context);
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "ClusterNotFoundException":
        case "com.amazonaws.ecs#ClusterNotFoundException":
            throw await de_ClusterNotFoundExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "NamespaceNotFoundException":
        case "com.amazonaws.ecs#NamespaceNotFoundException":
            throw await de_NamespaceNotFoundExceptionRes(parsedOutput, context);
        case "PlatformTaskDefinitionIncompatibilityException":
        case "com.amazonaws.ecs#PlatformTaskDefinitionIncompatibilityException":
            throw await de_PlatformTaskDefinitionIncompatibilityExceptionRes(parsedOutput, context);
        case "PlatformUnknownException":
        case "com.amazonaws.ecs#PlatformUnknownException":
            throw await de_PlatformUnknownExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        case "ServiceNotActiveException":
        case "com.amazonaws.ecs#ServiceNotActiveException":
            throw await de_ServiceNotActiveExceptionRes(parsedOutput, context);
        case "ServiceNotFoundException":
        case "com.amazonaws.ecs#ServiceNotFoundException":
            throw await de_ServiceNotFoundExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_UpdateServicePrimaryTaskSetCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_UpdateServicePrimaryTaskSetCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = de_UpdateServicePrimaryTaskSetResponse(data, context);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_UpdateServicePrimaryTaskSetCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "AccessDeniedException":
        case "com.amazonaws.ecs#AccessDeniedException":
            throw await de_AccessDeniedExceptionRes(parsedOutput, context);
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "ClusterNotFoundException":
        case "com.amazonaws.ecs#ClusterNotFoundException":
            throw await de_ClusterNotFoundExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        case "ServiceNotActiveException":
        case "com.amazonaws.ecs#ServiceNotActiveException":
            throw await de_ServiceNotActiveExceptionRes(parsedOutput, context);
        case "ServiceNotFoundException":
        case "com.amazonaws.ecs#ServiceNotFoundException":
            throw await de_ServiceNotFoundExceptionRes(parsedOutput, context);
        case "TaskSetNotFoundException":
        case "com.amazonaws.ecs#TaskSetNotFoundException":
            throw await de_TaskSetNotFoundExceptionRes(parsedOutput, context);
        case "UnsupportedFeatureException":
        case "com.amazonaws.ecs#UnsupportedFeatureException":
            throw await de_UnsupportedFeatureExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_UpdateTaskProtectionCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_UpdateTaskProtectionCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = de_UpdateTaskProtectionResponse(data, context);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_UpdateTaskProtectionCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "AccessDeniedException":
        case "com.amazonaws.ecs#AccessDeniedException":
            throw await de_AccessDeniedExceptionRes(parsedOutput, context);
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "ClusterNotFoundException":
        case "com.amazonaws.ecs#ClusterNotFoundException":
            throw await de_ClusterNotFoundExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ResourceNotFoundException":
        case "com.amazonaws.ecs#ResourceNotFoundException":
            throw await de_ResourceNotFoundExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        case "UnsupportedFeatureException":
        case "com.amazonaws.ecs#UnsupportedFeatureException":
            throw await de_UnsupportedFeatureExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_UpdateTaskSetCommand = async (output, context) => {
    if (output.statusCode >= 300) {
        return de_UpdateTaskSetCommandError(output, context);
    }
    const data = await parseBody(output.body, context);
    let contents = {};
    contents = de_UpdateTaskSetResponse(data, context);
    const response = {
        $metadata: deserializeMetadata(output),
        ...contents,
    };
    return response;
};
const de_UpdateTaskSetCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "AccessDeniedException":
        case "com.amazonaws.ecs#AccessDeniedException":
            throw await de_AccessDeniedExceptionRes(parsedOutput, context);
        case "ClientException":
        case "com.amazonaws.ecs#ClientException":
            throw await de_ClientExceptionRes(parsedOutput, context);
        case "ClusterNotFoundException":
        case "com.amazonaws.ecs#ClusterNotFoundException":
            throw await de_ClusterNotFoundExceptionRes(parsedOutput, context);
        case "InvalidParameterException":
        case "com.amazonaws.ecs#InvalidParameterException":
            throw await de_InvalidParameterExceptionRes(parsedOutput, context);
        case "ServerException":
        case "com.amazonaws.ecs#ServerException":
            throw await de_ServerExceptionRes(parsedOutput, context);
        case "ServiceNotActiveException":
        case "com.amazonaws.ecs#ServiceNotActiveException":
            throw await de_ServiceNotActiveExceptionRes(parsedOutput, context);
        case "ServiceNotFoundException":
        case "com.amazonaws.ecs#ServiceNotFoundException":
            throw await de_ServiceNotFoundExceptionRes(parsedOutput, context);
        case "TaskSetNotFoundException":
        case "com.amazonaws.ecs#TaskSetNotFoundException":
            throw await de_TaskSetNotFoundExceptionRes(parsedOutput, context);
        case "UnsupportedFeatureException":
        case "com.amazonaws.ecs#UnsupportedFeatureException":
            throw await de_UnsupportedFeatureExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
const de_AccessDeniedExceptionRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new AccessDeniedException({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_AttributeLimitExceededExceptionRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new AttributeLimitExceededException({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_BlockedExceptionRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new BlockedException({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_ClientExceptionRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new ClientException({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_ClusterContainsContainerInstancesExceptionRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new ClusterContainsContainerInstancesException({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_ClusterContainsServicesExceptionRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new ClusterContainsServicesException({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_ClusterContainsTasksExceptionRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new ClusterContainsTasksException({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_ClusterNotFoundExceptionRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new ClusterNotFoundException({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_ConflictExceptionRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new ConflictException({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_InvalidParameterExceptionRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new InvalidParameterException({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_LimitExceededExceptionRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new LimitExceededException({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_MissingVersionExceptionRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new MissingVersionException({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_NamespaceNotFoundExceptionRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new NamespaceNotFoundException({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_NoUpdateAvailableExceptionRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new NoUpdateAvailableException({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_PlatformTaskDefinitionIncompatibilityExceptionRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new PlatformTaskDefinitionIncompatibilityException({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_PlatformUnknownExceptionRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new PlatformUnknownException({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_ResourceInUseExceptionRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new ResourceInUseException({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_ResourceNotFoundExceptionRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new ResourceNotFoundException({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_ServerExceptionRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new ServerException({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_ServiceNotActiveExceptionRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new ServiceNotActiveException({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_ServiceNotFoundExceptionRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new ServiceNotFoundException({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_TargetNotConnectedExceptionRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new TargetNotConnectedException({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_TargetNotFoundExceptionRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new TargetNotFoundException({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_TaskSetNotFoundExceptionRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new TaskSetNotFoundException({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_UnsupportedFeatureExceptionRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new UnsupportedFeatureException({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const de_UpdateInProgressExceptionRes = async (parsedOutput, context) => {
    const body = parsedOutput.body;
    const deserialized = _json(body);
    const exception = new UpdateInProgressException({
        $metadata: deserializeMetadata(parsedOutput),
        ...deserialized,
    });
    return __decorateServiceException(exception, body);
};
const se_CreateTaskSetRequest = (input, context) => {
    return take(input, {
        capacityProviderStrategy: _json,
        clientToken: [],
        cluster: [],
        externalId: [],
        launchType: [],
        loadBalancers: _json,
        networkConfiguration: _json,
        platformVersion: [],
        scale: (_) => se_Scale(_, context),
        service: [],
        serviceRegistries: _json,
        tags: _json,
        taskDefinition: [],
    });
};
const se_RegisterContainerInstanceRequest = (input, context) => {
    return take(input, {
        attributes: _json,
        cluster: [],
        containerInstanceArn: [],
        instanceIdentityDocument: [],
        instanceIdentityDocumentSignature: [],
        platformDevices: _json,
        tags: _json,
        totalResources: (_) => se_Resources(_, context),
        versionInfo: _json,
    });
};
const se_Resource = (input, context) => {
    return take(input, {
        doubleValue: __serializeFloat,
        integerValue: [],
        longValue: [],
        name: [],
        stringSetValue: _json,
        type: [],
    });
};
const se_Resources = (input, context) => {
    return input
        .filter((e) => e != null)
        .map((entry) => {
        return se_Resource(entry, context);
    });
};
const se_RunTaskRequest = (input, context) => {
    return take(input, {
        capacityProviderStrategy: _json,
        clientToken: [true, (_) => _ ?? generateIdempotencyToken()],
        cluster: [],
        count: [],
        enableECSManagedTags: [],
        enableExecuteCommand: [],
        group: [],
        launchType: [],
        networkConfiguration: _json,
        overrides: _json,
        placementConstraints: _json,
        placementStrategy: _json,
        platformVersion: [],
        propagateTags: [],
        referenceId: [],
        startedBy: [],
        tags: _json,
        taskDefinition: [],
    });
};
const se_Scale = (input, context) => {
    return take(input, {
        unit: [],
        value: __serializeFloat,
    });
};
const se_SubmitTaskStateChangeRequest = (input, context) => {
    return take(input, {
        attachments: _json,
        cluster: [],
        containers: _json,
        executionStoppedAt: (_) => Math.round(_.getTime() / 1000),
        managedAgents: _json,
        pullStartedAt: (_) => Math.round(_.getTime() / 1000),
        pullStoppedAt: (_) => Math.round(_.getTime() / 1000),
        reason: [],
        status: [],
        task: [],
    });
};
const se_UpdateTaskSetRequest = (input, context) => {
    return take(input, {
        cluster: [],
        scale: (_) => se_Scale(_, context),
        service: [],
        taskSet: [],
    });
};
const de_Container = (output, context) => {
    return take(output, {
        containerArn: __expectString,
        cpu: __expectString,
        exitCode: __expectInt32,
        gpuIds: _json,
        healthStatus: __expectString,
        image: __expectString,
        imageDigest: __expectString,
        lastStatus: __expectString,
        managedAgents: (_) => de_ManagedAgents(_, context),
        memory: __expectString,
        memoryReservation: __expectString,
        name: __expectString,
        networkBindings: _json,
        networkInterfaces: _json,
        reason: __expectString,
        runtimeId: __expectString,
        taskArn: __expectString,
    });
};
const de_ContainerInstance = (output, context) => {
    return take(output, {
        agentConnected: __expectBoolean,
        agentUpdateStatus: __expectString,
        attachments: _json,
        attributes: _json,
        capacityProviderName: __expectString,
        containerInstanceArn: __expectString,
        ec2InstanceId: __expectString,
        healthStatus: (_) => de_ContainerInstanceHealthStatus(_, context),
        pendingTasksCount: __expectInt32,
        registeredAt: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        registeredResources: (_) => de_Resources(_, context),
        remainingResources: (_) => de_Resources(_, context),
        runningTasksCount: __expectInt32,
        status: __expectString,
        statusReason: __expectString,
        tags: _json,
        version: __expectLong,
        versionInfo: _json,
    });
};
const de_ContainerInstanceHealthStatus = (output, context) => {
    return take(output, {
        details: (_) => de_InstanceHealthCheckResultList(_, context),
        overallStatus: __expectString,
    });
};
const de_ContainerInstances = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_ContainerInstance(entry, context);
    });
    return retVal;
};
const de_Containers = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_Container(entry, context);
    });
    return retVal;
};
const de_CreateServiceResponse = (output, context) => {
    return take(output, {
        service: (_) => de_Service(_, context),
    });
};
const de_CreateTaskSetResponse = (output, context) => {
    return take(output, {
        taskSet: (_) => de_TaskSet(_, context),
    });
};
const de_DeleteServiceResponse = (output, context) => {
    return take(output, {
        service: (_) => de_Service(_, context),
    });
};
const de_DeleteTaskDefinitionsResponse = (output, context) => {
    return take(output, {
        failures: _json,
        taskDefinitions: (_) => de_TaskDefinitionList(_, context),
    });
};
const de_DeleteTaskSetResponse = (output, context) => {
    return take(output, {
        taskSet: (_) => de_TaskSet(_, context),
    });
};
const de_Deployment = (output, context) => {
    return take(output, {
        capacityProviderStrategy: _json,
        createdAt: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        desiredCount: __expectInt32,
        failedTasks: __expectInt32,
        id: __expectString,
        launchType: __expectString,
        networkConfiguration: _json,
        pendingCount: __expectInt32,
        platformFamily: __expectString,
        platformVersion: __expectString,
        rolloutState: __expectString,
        rolloutStateReason: __expectString,
        runningCount: __expectInt32,
        serviceConnectConfiguration: _json,
        serviceConnectResources: _json,
        status: __expectString,
        taskDefinition: __expectString,
        updatedAt: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    });
};
const de_Deployments = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_Deployment(entry, context);
    });
    return retVal;
};
const de_DeregisterContainerInstanceResponse = (output, context) => {
    return take(output, {
        containerInstance: (_) => de_ContainerInstance(_, context),
    });
};
const de_DeregisterTaskDefinitionResponse = (output, context) => {
    return take(output, {
        taskDefinition: (_) => de_TaskDefinition(_, context),
    });
};
const de_DescribeContainerInstancesResponse = (output, context) => {
    return take(output, {
        containerInstances: (_) => de_ContainerInstances(_, context),
        failures: _json,
    });
};
const de_DescribeServicesResponse = (output, context) => {
    return take(output, {
        failures: _json,
        services: (_) => de_Services(_, context),
    });
};
const de_DescribeTaskDefinitionResponse = (output, context) => {
    return take(output, {
        tags: _json,
        taskDefinition: (_) => de_TaskDefinition(_, context),
    });
};
const de_DescribeTaskSetsResponse = (output, context) => {
    return take(output, {
        failures: _json,
        taskSets: (_) => de_TaskSets(_, context),
    });
};
const de_DescribeTasksResponse = (output, context) => {
    return take(output, {
        failures: _json,
        tasks: (_) => de_Tasks(_, context),
    });
};
const de_GetTaskProtectionResponse = (output, context) => {
    return take(output, {
        failures: _json,
        protectedTasks: (_) => de_ProtectedTasks(_, context),
    });
};
const de_InstanceHealthCheckResult = (output, context) => {
    return take(output, {
        lastStatusChange: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        lastUpdated: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        status: __expectString,
        type: __expectString,
    });
};
const de_InstanceHealthCheckResultList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_InstanceHealthCheckResult(entry, context);
    });
    return retVal;
};
const de_ManagedAgent = (output, context) => {
    return take(output, {
        lastStartedAt: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        lastStatus: __expectString,
        name: __expectString,
        reason: __expectString,
    });
};
const de_ManagedAgents = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_ManagedAgent(entry, context);
    });
    return retVal;
};
const de_ProtectedTask = (output, context) => {
    return take(output, {
        expirationDate: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        protectionEnabled: __expectBoolean,
        taskArn: __expectString,
    });
};
const de_ProtectedTasks = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_ProtectedTask(entry, context);
    });
    return retVal;
};
const de_RegisterContainerInstanceResponse = (output, context) => {
    return take(output, {
        containerInstance: (_) => de_ContainerInstance(_, context),
    });
};
const de_RegisterTaskDefinitionResponse = (output, context) => {
    return take(output, {
        tags: _json,
        taskDefinition: (_) => de_TaskDefinition(_, context),
    });
};
const de_Resource = (output, context) => {
    return take(output, {
        doubleValue: __limitedParseDouble,
        integerValue: __expectInt32,
        longValue: __expectLong,
        name: __expectString,
        stringSetValue: _json,
        type: __expectString,
    });
};
const de_Resources = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_Resource(entry, context);
    });
    return retVal;
};
const de_RunTaskResponse = (output, context) => {
    return take(output, {
        failures: _json,
        tasks: (_) => de_Tasks(_, context),
    });
};
const de_Scale = (output, context) => {
    return take(output, {
        unit: __expectString,
        value: __limitedParseDouble,
    });
};
const de_Service = (output, context) => {
    return take(output, {
        capacityProviderStrategy: _json,
        clusterArn: __expectString,
        createdAt: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        createdBy: __expectString,
        deploymentConfiguration: _json,
        deploymentController: _json,
        deployments: (_) => de_Deployments(_, context),
        desiredCount: __expectInt32,
        enableECSManagedTags: __expectBoolean,
        enableExecuteCommand: __expectBoolean,
        events: (_) => de_ServiceEvents(_, context),
        healthCheckGracePeriodSeconds: __expectInt32,
        launchType: __expectString,
        loadBalancers: _json,
        networkConfiguration: _json,
        pendingCount: __expectInt32,
        placementConstraints: _json,
        placementStrategy: _json,
        platformFamily: __expectString,
        platformVersion: __expectString,
        propagateTags: __expectString,
        roleArn: __expectString,
        runningCount: __expectInt32,
        schedulingStrategy: __expectString,
        serviceArn: __expectString,
        serviceName: __expectString,
        serviceRegistries: _json,
        status: __expectString,
        tags: _json,
        taskDefinition: __expectString,
        taskSets: (_) => de_TaskSets(_, context),
    });
};
const de_ServiceEvent = (output, context) => {
    return take(output, {
        createdAt: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        id: __expectString,
        message: __expectString,
    });
};
const de_ServiceEvents = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_ServiceEvent(entry, context);
    });
    return retVal;
};
const de_Services = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_Service(entry, context);
    });
    return retVal;
};
const de_StartTaskResponse = (output, context) => {
    return take(output, {
        failures: _json,
        tasks: (_) => de_Tasks(_, context),
    });
};
const de_StopTaskResponse = (output, context) => {
    return take(output, {
        task: (_) => de_Task(_, context),
    });
};
const de_Task = (output, context) => {
    return take(output, {
        attachments: _json,
        attributes: _json,
        availabilityZone: __expectString,
        capacityProviderName: __expectString,
        clusterArn: __expectString,
        connectivity: __expectString,
        connectivityAt: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        containerInstanceArn: __expectString,
        containers: (_) => de_Containers(_, context),
        cpu: __expectString,
        createdAt: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        desiredStatus: __expectString,
        enableExecuteCommand: __expectBoolean,
        ephemeralStorage: _json,
        executionStoppedAt: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        group: __expectString,
        healthStatus: __expectString,
        inferenceAccelerators: _json,
        lastStatus: __expectString,
        launchType: __expectString,
        memory: __expectString,
        overrides: _json,
        platformFamily: __expectString,
        platformVersion: __expectString,
        pullStartedAt: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        pullStoppedAt: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        startedAt: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        startedBy: __expectString,
        stopCode: __expectString,
        stoppedAt: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        stoppedReason: __expectString,
        stoppingAt: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        tags: _json,
        taskArn: __expectString,
        taskDefinitionArn: __expectString,
        version: __expectLong,
    });
};
const de_TaskDefinition = (output, context) => {
    return take(output, {
        compatibilities: _json,
        containerDefinitions: _json,
        cpu: __expectString,
        deregisteredAt: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        ephemeralStorage: _json,
        executionRoleArn: __expectString,
        family: __expectString,
        inferenceAccelerators: _json,
        ipcMode: __expectString,
        memory: __expectString,
        networkMode: __expectString,
        pidMode: __expectString,
        placementConstraints: _json,
        proxyConfiguration: _json,
        registeredAt: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        registeredBy: __expectString,
        requiresAttributes: _json,
        requiresCompatibilities: _json,
        revision: __expectInt32,
        runtimePlatform: _json,
        status: __expectString,
        taskDefinitionArn: __expectString,
        taskRoleArn: __expectString,
        volumes: _json,
    });
};
const de_TaskDefinitionList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_TaskDefinition(entry, context);
    });
    return retVal;
};
const de_Tasks = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_Task(entry, context);
    });
    return retVal;
};
const de_TaskSet = (output, context) => {
    return take(output, {
        capacityProviderStrategy: _json,
        clusterArn: __expectString,
        computedDesiredCount: __expectInt32,
        createdAt: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        externalId: __expectString,
        id: __expectString,
        launchType: __expectString,
        loadBalancers: _json,
        networkConfiguration: _json,
        pendingCount: __expectInt32,
        platformFamily: __expectString,
        platformVersion: __expectString,
        runningCount: __expectInt32,
        scale: (_) => de_Scale(_, context),
        serviceArn: __expectString,
        serviceRegistries: _json,
        stabilityStatus: __expectString,
        stabilityStatusAt: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        startedBy: __expectString,
        status: __expectString,
        tags: _json,
        taskDefinition: __expectString,
        taskSetArn: __expectString,
        updatedAt: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    });
};
const de_TaskSets = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_TaskSet(entry, context);
    });
    return retVal;
};
const de_UpdateContainerAgentResponse = (output, context) => {
    return take(output, {
        containerInstance: (_) => de_ContainerInstance(_, context),
    });
};
const de_UpdateContainerInstancesStateResponse = (output, context) => {
    return take(output, {
        containerInstances: (_) => de_ContainerInstances(_, context),
        failures: _json,
    });
};
const de_UpdateServicePrimaryTaskSetResponse = (output, context) => {
    return take(output, {
        taskSet: (_) => de_TaskSet(_, context),
    });
};
const de_UpdateServiceResponse = (output, context) => {
    return take(output, {
        service: (_) => de_Service(_, context),
    });
};
const de_UpdateTaskProtectionResponse = (output, context) => {
    return take(output, {
        failures: _json,
        protectedTasks: (_) => de_ProtectedTasks(_, context),
    });
};
const de_UpdateTaskSetResponse = (output, context) => {
    return take(output, {
        taskSet: (_) => de_TaskSet(_, context),
    });
};
const deserializeMetadata = (output) => ({
    httpStatusCode: output.statusCode,
    requestId: output.headers["x-amzn-requestid"] ?? output.headers["x-amzn-request-id"] ?? output.headers["x-amz-request-id"],
    extendedRequestId: output.headers["x-amz-id-2"],
    cfId: output.headers["x-amz-cf-id"],
});
const collectBodyString = (streamBody, context) => collectBody(streamBody, context).then((body) => context.utf8Encoder(body));
const throwDefaultError = withBaseException(__BaseException);
const buildHttpRpcRequest = async (context, headers, path, resolvedHostname, body) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const contents = {
        protocol,
        hostname,
        port,
        method: "POST",
        path: basePath.endsWith("/") ? basePath.slice(0, -1) + path : basePath + path,
        headers,
    };
    if (resolvedHostname !== undefined) {
        contents.hostname = resolvedHostname;
    }
    if (body !== undefined) {
        contents.body = body;
    }
    return new __HttpRequest(contents);
};
function sharedHeaders(operation) {
    return {
        "content-type": "application/x-amz-json-1.1",
        "x-amz-target": `AmazonEC2ContainerServiceV20141113.${operation}`,
    };
}
const parseBody = (streamBody, context) => collectBodyString(streamBody, context).then((encoded) => {
    if (encoded.length) {
        return JSON.parse(encoded);
    }
    return {};
});
const parseErrorBody = async (errorBody, context) => {
    const value = await parseBody(errorBody, context);
    value.message = value.message ?? value.Message;
    return value;
};
const loadRestJsonErrorCode = (output, data) => {
    const findKey = (object, key) => Object.keys(object).find((k) => k.toLowerCase() === key.toLowerCase());
    const sanitizeErrorCode = (rawValue) => {
        let cleanValue = rawValue;
        if (typeof cleanValue === "number") {
            cleanValue = cleanValue.toString();
        }
        if (cleanValue.indexOf(",") >= 0) {
            cleanValue = cleanValue.split(",")[0];
        }
        if (cleanValue.indexOf(":") >= 0) {
            cleanValue = cleanValue.split(":")[0];
        }
        if (cleanValue.indexOf("#") >= 0) {
            cleanValue = cleanValue.split("#")[1];
        }
        return cleanValue;
    };
    const headerKey = findKey(output.headers, "x-amzn-errortype");
    if (headerKey !== undefined) {
        return sanitizeErrorCode(output.headers[headerKey]);
    }
    if (data.code !== undefined) {
        return sanitizeErrorCode(data.code);
    }
    if (data["__type"] !== undefined) {
        return sanitizeErrorCode(data["__type"]);
    }
};
