{
  "pagination": {
    "ListAssistantAssociations": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "assistantAssociationSummaries"
    },
    "ListAssistants": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "assistantSummaries"
    },
    "ListContents": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "contentSummaries"
    },
    "ListImportJobs": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "importJobSummaries"
    },
    "ListKnowledgeBases": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "knowledgeBaseSummaries"
    },
    "ListQuickResponses": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "quickResponseSummaries"
    },
    "QueryAssistant": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "results"
    },
    "SearchContent": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "contentSummaries"
    },
    "SearchQuickResponses": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "results"
    },
    "SearchSessions": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "sessionSummaries"
    }
  }
}
