{
  "pagination": {
    "DescribeDomainAutoTunes": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "DescribeInboundConnections": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "DescribeOutboundConnections": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "DescribePackages": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "DescribeReservedInstanceOfferings": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "DescribeReservedInstances": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "GetPackageVersionHistory": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "GetUpgradeHistory": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListDomainMaintenances": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListDomainsForPackage": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListInstanceTypeDetails": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListPackagesForDomain": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListScheduledActions": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    },
    "ListVersions": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults"
    }
  }
}
