import "../../style.css";

import {
    Box,
    Divider,
    FormControlLabel,
    IconButton,
    List,
    ListItemButton,
    ListItemText,
    Paper,
    Radio,
    RadioGroup,
    Stack,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import ClearIcon from "@mui/icons-material/Clear";
import { iconPaths } from "../utils/Map";



const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
        width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
        transform: 'translateX(12px)',
        // button color
        color: '#fff',
        '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: theme.palette.primary.main,
        },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
        duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        boxSizing: 'border-box',
    },
}));



interface Props {
    features: object[];
    documentTextFilter: string;
    setDocumentTextFilter: (filter: string) => void;
    setRenderTableOrDescription: (filter: "Description" | "Table") => void;
    documentTableScope: "fieldOfView" | "global";
    setDocumentTableScope:  (filter: "fieldOfView" | "global") => void;
    setHoveredFeatureIds: (featureId: number[]) => void;
    setClickedFeatureIds: (featureId: number[]) => void;
    setCenterCoordinates: (filter: [number, number]) => void;
}

export default function DocumentTable(props: Props) {

    //// Filter such that only show one of each document in the DocumentTable
    const uniqueFeatures = []; 
    
    for (let feature of props.features) {

        // Check if feature with this spec already exists in uniqueFeatures
        let nameExists: boolean = uniqueFeatures.some(uniqueFeature => uniqueFeature['Name'] === feature['Name']);
        let projectExists: boolean = uniqueFeatures.some(uniqueFeature => uniqueFeature['Project/Asset'] === feature['Project/Asset']);
        let typeExists: boolean = uniqueFeatures.some(uniqueFeature => uniqueFeature['Type'] === feature['Type']);
        let filelinkExists: boolean = uniqueFeatures.some(uniqueFeature => uniqueFeature['File link'] === feature['File link']);
        let yearExists: boolean = uniqueFeatures.some(uniqueFeature => uniqueFeature['Year'] === feature['Year']);

        // If all of the above are true, categorise feature as existing
        const featureExists: boolean = [nameExists, projectExists, typeExists, filelinkExists, yearExists, ].every(Boolean)

        // If feature does not already exist in uniqueFeatures, push feature to array
        if (!featureExists) {
            uniqueFeatures.push(feature)
        }
    }

    return (
        !props.features 
        ? 
        <></> 
        :
        <Paper
            id="document-table"
            className="map-container"
            elevation={0}
            sx={{
                width: "auto",
                opacity: "100%",
                overflow: "auto",
                borderRadius: 0,
            }}
        >
            {/* Text filter */}
            <Typography
                sx={{
                    padding: "10px",
                    color: "#fff",
                    borderBottom: "2px solid rgba(0, 0, 0, 0.25)",
                }}
                component={"div"}
            >
                <TextField
                    InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                            <IconButton
                                edge="start"
                                onClick={() => props.setDocumentTextFilter("")} // Clear the text field
                            >
                                <ClearIcon />
                            </IconButton>
                        ),
                    }}
                    id="document-text-filter"
                    variant="standard"
                    autoComplete="off"
                    label="Filter results by meta data"
                    fullWidth
                    value={props.documentTextFilter}
                    onChange={(e) => {
                        props.setDocumentTextFilter(e.target.value);
                    }}
                    // onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    //     if (e.key === 'Enter') {
                    //         props.setDocumentTextFilter(e.target.value);
                    //     }
                    // }}
                />
            </Typography>

            {/* Toggles */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center', // Center items horizontally
                    alignItems: 'center', // Center items vertically (which centers them horizontally in a row)
                    padding: 1,
                    m: 0,
                    borderBottom: 2,
                    borderBottomColor: 'rgba(0, 0, 0, 0.25)',
                }}
            >
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography>Field of view</Typography>
                    <AntSwitch 
                        checked={props.documentTableScope === 'global' ? true : false}
                        inputProps={{ 'aria-label': 'ant design' }}
                        onChange={
                            () => {
                                props.setDocumentTableScope(
                                    props.documentTableScope === 'global' ? 'fieldOfView' : 'global'
                                );
                            }
                        } 
                    />
                    <Typography>Global</Typography>
                </Stack>
            </Box>

            {/* List of features */}
            <div>
                <List dense component={"div"} sx={{ width: "100%", bgcolor: "background.paper" }}>
                    {uniqueFeatures.map((catalogEntry, idx) =>
                        <div key={idx}>
                            <ListItemButton
                                key={idx}
                                onClick={() => {
                                    props.setCenterCoordinates([catalogEntry['Longitude'], catalogEntry['Latitude']]);
                                    props.setClickedFeatureIds([catalogEntry['id']]);
                                    props.setRenderTableOrDescription("Description");
                                }}
                                onMouseEnter={() => props.setHoveredFeatureIds([catalogEntry['id']])}
                                onMouseLeave={() => props.setHoveredFeatureIds([])}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: "28px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <img
                                            src={iconPaths[catalogEntry["Type"]]}
                                            alt=""
                                            style={{ width: "100%", height: "auto" }}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            width: "calc(100% - 28px)",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            textAlign: "left",
                                            marginLeft: "15px",
                                        }}
                                    >
                                        <ListItemText
                                            primary={catalogEntry["Name"]}
                                            secondary={
                                                `${catalogEntry["Year"]}, 
                                                ${catalogEntry["Type"]}`
                                            }
                                        />
                                    </Box>
                                </Box>
                            </ListItemButton>
                            <Divider variant="middle" component="li" />
                        </div>
                    )}
                </List>
            </div>
        </Paper>
    );
}
