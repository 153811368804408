import React, { useState } from "react";
import { ArrowBack } from "@mui/icons-material";
import {
    Box,
    Button,
    Divider,
    IconButton,
    List,
    Paper,
    Tooltip,
} from "@mui/material";
import DocumentDialog from "./DocumentDialog";
import ICatalogEntry from "../types/ICatalogEntry";
import { convertDecimalDegreesToDegreesAndMinutes } from "../utils/Map";



function listItem(key, value) {
    return (
        <div key={key}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    paddingTop: 1,
                    paddingBottom: 1,
                    paddingLeft: "15px",
                    paddingRight: "15px",
                }}
            >
                <Box
                    sx={{
                        width: "32%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "left",
                        fontWeight: "500",
                    }}
                >
                    {key}
                </Box>
                <Box
                    sx={{
                        width: "68%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "left",
                    }}
                >
                    {value}
                </Box>
            </Box>
            <Divider variant="middle" component="li" />
        </div>
    );
}



interface Props {
    catalogEntries: ICatalogEntry[];
    setRenderTableOrDescription: (filter: "Table" | "Description") => void;
}

export default function DocumentDescription(props: Props) {

    const [isDocumentDialogOpen, setIsDocumentDialogOpen] = useState<boolean>(false);

    return (
        <Paper
            id="document-table"
            className="map-container"
            elevation={0}
            sx={{ overflow: "auto", borderRadius: 0 }}
        >
            <Tooltip title="Back" placement="top">
                <IconButton
                    onClick={() => {
                        props.setRenderTableOrDescription("Table");
                    }}
                >
                    <ArrowBack fontSize="large" />
                </IconButton>
            </Tooltip>
            {props.catalogEntries.map( (catalogEntry, idx) => {return (
                <div key={idx}>
                    <List 
                        dense 
                        sx={{ width: "100%", bgcolor: "background.paper" }}
                    >
                        {/* Various specs */}
                        {listItem('Name', catalogEntry['Name'])}
                        {listItem('Type', catalogEntry['Type'])}
                        {listItem('Project/Asset', catalogEntry['Project/Asset'])}
                        {listItem('Country', catalogEntry['Country'])}
                        {listItem(
                            `(Latitude, Longitude)`,
                            `(${convertDecimalDegreesToDegreesAndMinutes(
                                catalogEntry['Latitude'],
                                false
                            )}, ${convertDecimalDegreesToDegreesAndMinutes(
                                catalogEntry['Longitude'],
                                true
                            )})`
                        )}
                        {listItem('Year', catalogEntry['Year'])}
                        {listItem('Expiry', catalogEntry['Expiry'])}
                        {listItem('Confidentiality', catalogEntry['Confidentiality'])}
                        {listItem('Contact', catalogEntry['Contact'])}
                        {/* Button for download or request of document */}
                        <Box
                            marginTop={2}
                            marginBottom={2}
                            sx={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Button
                                variant="contained"
                                onClick={() => {
                                    console.log("[LOG] catalogEntry['Confidentiality'].includes('restricted'): ", catalogEntry['Confidentiality'].includes("restricted"));
                                    setIsDocumentDialogOpen(true);
                                }}
                            >
                                {catalogEntry['Confidentiality'].includes("restricted") ? "Download" : "Request"}
                            </Button>
                            <DocumentDialog 
                                isDocumentDialogOpen={isDocumentDialogOpen} 
                                setIsDocumentDialogOpen={setIsDocumentDialogOpen} 
                                isRestricted={catalogEntry['Confidentiality'].includes("restricted")}
                                contactEmail={catalogEntry['Contact']}
                                contactName={catalogEntry['Contact']}
                                documentName={catalogEntry['Name']}
                                documentYear={catalogEntry['Year']}
                                documentLink={catalogEntry['File link']}
                                userName=""
                            />
                        </Box>
                        {idx < props.catalogEntries.length - 1 ? 
                            // add divider after all but the last description
                            <Divider variant="middle" component="li" sx={{ borderBottomWidth: 4 }}/>
                            :
                            null
                        }
                    </List>
                </div>
            )}
            )}
        </Paper>
    );
}
