{
  "pagination": {
    "ListCustomModels": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "modelSummaries"
    },
    "ListModelCustomizationJobs": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "modelCustomizationJobSummaries"
    },
    "ListProvisionedModelThroughputs": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "provisionedModelSummaries"
    }
  }
}
