{
  "pagination": {
    "ListChecks": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "checkSummaries"
    },
    "ListOrganizationRecommendationAccounts": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "accountRecommendationLifecycleSummaries"
    },
    "ListOrganizationRecommendationResources": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "organizationRecommendationResourceSummaries"
    },
    "ListOrganizationRecommendations": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "organizationRecommendationSummaries"
    },
    "ListRecommendationResources": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "recommendationResourceSummaries"
    },
    "ListRecommendations": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "recommendationSummaries"
    }
  }
}
