import "../../style.css";

import React, { ReactElement } from "react";
import Box from '@mui/material/Box';
import { FormControl, FormControlLabel, FormHelperText, FormLabel, InputAdornment, OutlinedInput, Radio, RadioGroup, TextField } from "@mui/material";

interface Props {
    onChange: (right: string) => void;
    name_parameter: string;
    error: boolean;
    options: string[];
    default: string;
}

const HorisontalRadioButtons = (props: Props): ReactElement => {

    return (
        <FormControl variant="outlined" error={props.error} className="input-box">
            <FormHelperText id="demo-radio-buttons-group-label">{props.name_parameter}</FormHelperText>
            <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={props.default}
                name="radio-buttons-group"
                sx={{display: 'flex', margin: 'auto'}}
                onChange={(ev) => props.onChange(ev.target.value)}
            >
                {props.options.map((label, index) => (
                    <FormControlLabel
                        key={index}
                        value={label}
                        control={<Radio />}
                        label={label}
                    />
                ))}
                {/* <FormControlLabel value="female" control={<Radio />} label="Female" />
                <FormControlLabel value="male" control={<Radio />} label="Male" /> */}
            </RadioGroup>
        </FormControl>
    )
}

export default HorisontalRadioButtons