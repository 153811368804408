import "../../style.css";

import React, { ReactElement } from "react"
import Box from '@mui/material/Box';
import { FormControl, FormHelperText, InputAdornment, OutlinedInput, InputBase } from "@mui/material";

interface Props {
    onChange: (right: string) => void;
    name_parameter: string;
    unit: string
    defaultValue: string
    step: string
    error: boolean
    disabled?: boolean
}

const NumberInput = (props: Props):
ReactElement => {

    const { name_parameter, unit, defaultValue, step, error } = props;

    return (
        <FormControl variant="outlined" error={error} className="input-box">
            <FormHelperText id="outlined-weight-helper-text">
                {name_parameter}
            </FormHelperText>
            <OutlinedInput
                required
                endAdornment={<InputAdornment position="end">{unit}</InputAdornment>}
                type="number"
                inputProps={{step: step}}
                defaultValue={defaultValue}
                onChange={(ev) => props.onChange(ev.target.value)}
                fullWidth={true}
                disabled={props.disabled ? props.disabled : false}
            />
        </FormControl>
    )
}

export default NumberInput