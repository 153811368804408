{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-04-01",
    "endpointPrefix": "quicksight",
    "jsonVersion": "1.0",
    "protocol": "rest-json",
    "serviceFullName": "Amazon QuickSight",
    "serviceId": "QuickSight",
    "signatureVersion": "v4",
    "uid": "quicksight-2018-04-01"
  },
  "operations": {
    "CancelIngestion": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/data-sets/{DataSetId}/ingestions/{IngestionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DataSetId",
          "IngestionId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DataSetId": {
            "location": "uri",
            "locationName": "DataSetId"
          },
          "IngestionId": {
            "location": "uri",
            "locationName": "IngestionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "IngestionId": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "CreateAccountCustomization": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/customizations"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "AccountCustomization"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "querystring",
            "locationName": "namespace"
          },
          "AccountCustomization": {
            "shape": "Sa"
          },
          "Tags": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "AwsAccountId": {},
          "Namespace": {},
          "AccountCustomization": {
            "shape": "Sa"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "CreateAccountSubscription": {
      "http": {
        "requestUri": "/account/{AwsAccountId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Edition",
          "AuthenticationMethod",
          "AwsAccountId",
          "AccountName",
          "NotificationEmail"
        ],
        "members": {
          "Edition": {},
          "AuthenticationMethod": {},
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "AccountName": {},
          "NotificationEmail": {},
          "ActiveDirectoryName": {},
          "Realm": {},
          "DirectoryId": {},
          "AdminGroup": {
            "shape": "Sk"
          },
          "AuthorGroup": {
            "shape": "Sk"
          },
          "ReaderGroup": {
            "shape": "Sk"
          },
          "FirstName": {},
          "LastName": {},
          "EmailAddress": {},
          "ContactNumber": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SignupResponse": {
            "type": "structure",
            "members": {
              "IAMUser": {
                "type": "boolean"
              },
              "userLoginName": {},
              "accountName": {},
              "directoryType": {}
            }
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "CreateAnalysis": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/analyses/{AnalysisId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "AnalysisId",
          "Name"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "AnalysisId": {
            "location": "uri",
            "locationName": "AnalysisId"
          },
          "Name": {},
          "Parameters": {
            "shape": "Sr"
          },
          "Permissions": {
            "shape": "S19"
          },
          "SourceEntity": {
            "shape": "S1d"
          },
          "ThemeArn": {},
          "Tags": {
            "shape": "Sb"
          },
          "Definition": {
            "shape": "S1h"
          },
          "ValidationStrategy": {
            "shape": "Smc"
          },
          "FolderArns": {
            "shape": "Sme"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "AnalysisId": {},
          "CreationStatus": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "CreateDashboard": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/dashboards/{DashboardId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DashboardId",
          "Name"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DashboardId": {
            "location": "uri",
            "locationName": "DashboardId"
          },
          "Name": {},
          "Parameters": {
            "shape": "Sr"
          },
          "Permissions": {
            "shape": "S19"
          },
          "SourceEntity": {
            "shape": "Smj"
          },
          "Tags": {
            "shape": "Sb"
          },
          "VersionDescription": {},
          "DashboardPublishOptions": {
            "shape": "Smm"
          },
          "ThemeArn": {},
          "Definition": {
            "shape": "Smz"
          },
          "ValidationStrategy": {
            "shape": "Smc"
          },
          "FolderArns": {
            "shape": "Sme"
          },
          "LinkSharingConfiguration": {
            "shape": "Sn0"
          },
          "LinkEntities": {
            "shape": "Sn1"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "VersionArn": {},
          "DashboardId": {},
          "CreationStatus": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "CreateDataSet": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/data-sets"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DataSetId",
          "Name",
          "PhysicalTableMap",
          "ImportMode"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DataSetId": {},
          "Name": {},
          "PhysicalTableMap": {
            "shape": "Sn7"
          },
          "LogicalTableMap": {
            "shape": "Snr"
          },
          "ImportMode": {},
          "ColumnGroups": {
            "shape": "Soy"
          },
          "FieldFolders": {
            "shape": "Sp4"
          },
          "Permissions": {
            "shape": "S19"
          },
          "RowLevelPermissionDataSet": {
            "shape": "Sp9"
          },
          "RowLevelPermissionTagConfiguration": {
            "shape": "Spd"
          },
          "ColumnLevelPermissionRules": {
            "shape": "Spl"
          },
          "Tags": {
            "shape": "Sb"
          },
          "DataSetUsageConfiguration": {
            "shape": "Spp"
          },
          "DatasetParameters": {
            "shape": "Spq"
          },
          "FolderArns": {
            "shape": "Sme"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "DataSetId": {},
          "IngestionArn": {},
          "IngestionId": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "CreateDataSource": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/data-sources"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DataSourceId",
          "Name",
          "Type"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DataSourceId": {},
          "Name": {},
          "Type": {},
          "DataSourceParameters": {
            "shape": "Sq5"
          },
          "Credentials": {
            "shape": "Srm"
          },
          "Permissions": {
            "shape": "S19"
          },
          "VpcConnectionProperties": {
            "shape": "Srt"
          },
          "SslProperties": {
            "shape": "Sru"
          },
          "Tags": {
            "shape": "Sb"
          },
          "FolderArns": {
            "shape": "Sme"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "DataSourceId": {},
          "CreationStatus": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "CreateFolder": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/folders/{FolderId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "FolderId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "FolderId": {
            "location": "uri",
            "locationName": "FolderId"
          },
          "Name": {},
          "FolderType": {},
          "ParentFolderArn": {},
          "Permissions": {
            "shape": "S19"
          },
          "Tags": {
            "shape": "Sb"
          },
          "SharingModel": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "Arn": {},
          "FolderId": {},
          "RequestId": {}
        }
      }
    },
    "CreateFolderMembership": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/folders/{FolderId}/members/{MemberType}/{MemberId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "FolderId",
          "MemberId",
          "MemberType"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "FolderId": {
            "location": "uri",
            "locationName": "FolderId"
          },
          "MemberId": {
            "location": "uri",
            "locationName": "MemberId"
          },
          "MemberType": {
            "location": "uri",
            "locationName": "MemberType"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "type": "integer"
          },
          "FolderMember": {
            "type": "structure",
            "members": {
              "MemberId": {},
              "MemberType": {}
            }
          },
          "RequestId": {}
        }
      }
    },
    "CreateGroup": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/groups"
      },
      "input": {
        "type": "structure",
        "required": [
          "GroupName",
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "GroupName": {},
          "Description": {},
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Group": {
            "shape": "Ssa"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "CreateGroupMembership": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/groups/{GroupName}/members/{MemberName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "MemberName",
          "GroupName",
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "MemberName": {
            "location": "uri",
            "locationName": "MemberName"
          },
          "GroupName": {
            "location": "uri",
            "locationName": "GroupName"
          },
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GroupMember": {
            "shape": "Sse"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "CreateIAMPolicyAssignment": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/iam-policy-assignments/"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "AssignmentName",
          "AssignmentStatus",
          "Namespace"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "AssignmentName": {},
          "AssignmentStatus": {},
          "PolicyArn": {},
          "Identities": {
            "shape": "Ssi"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssignmentName": {},
          "AssignmentId": {},
          "AssignmentStatus": {},
          "PolicyArn": {},
          "Identities": {
            "shape": "Ssi"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "CreateIngestion": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/data-sets/{DataSetId}/ingestions/{IngestionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "DataSetId",
          "IngestionId",
          "AwsAccountId"
        ],
        "members": {
          "DataSetId": {
            "location": "uri",
            "locationName": "DataSetId"
          },
          "IngestionId": {
            "location": "uri",
            "locationName": "IngestionId"
          },
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "IngestionType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "IngestionId": {},
          "IngestionStatus": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "CreateNamespace": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "Namespace",
          "IdentityStore"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {},
          "IdentityStore": {},
          "Tags": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "Name": {},
          "CapacityRegion": {},
          "CreationStatus": {},
          "IdentityStore": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "CreateRefreshSchedule": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/data-sets/{DataSetId}/refresh-schedules"
      },
      "input": {
        "type": "structure",
        "required": [
          "DataSetId",
          "AwsAccountId",
          "Schedule"
        ],
        "members": {
          "DataSetId": {
            "location": "uri",
            "locationName": "DataSetId"
          },
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Schedule": {
            "shape": "Ssv"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {},
          "ScheduleId": {},
          "Arn": {}
        }
      }
    },
    "CreateRoleMembership": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/roles/{Role}/members/{MemberName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "MemberName",
          "AwsAccountId",
          "Namespace",
          "Role"
        ],
        "members": {
          "MemberName": {
            "location": "uri",
            "locationName": "MemberName"
          },
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          },
          "Role": {
            "location": "uri",
            "locationName": "Role"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "CreateTemplate": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/templates/{TemplateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TemplateId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TemplateId": {
            "location": "uri",
            "locationName": "TemplateId"
          },
          "Name": {},
          "Permissions": {
            "shape": "S19"
          },
          "SourceEntity": {
            "shape": "St7"
          },
          "Tags": {
            "shape": "Sb"
          },
          "VersionDescription": {},
          "Definition": {
            "shape": "Sta"
          },
          "ValidationStrategy": {
            "shape": "Smc"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "VersionArn": {},
          "TemplateId": {},
          "CreationStatus": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "CreateTemplateAlias": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/templates/{TemplateId}/aliases/{AliasName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TemplateId",
          "AliasName",
          "TemplateVersionNumber"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TemplateId": {
            "location": "uri",
            "locationName": "TemplateId"
          },
          "AliasName": {
            "location": "uri",
            "locationName": "AliasName"
          },
          "TemplateVersionNumber": {
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TemplateAlias": {
            "shape": "Stp"
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "CreateTheme": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/themes/{ThemeId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "ThemeId",
          "Name",
          "BaseThemeId",
          "Configuration"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "ThemeId": {
            "location": "uri",
            "locationName": "ThemeId"
          },
          "Name": {},
          "BaseThemeId": {},
          "VersionDescription": {},
          "Configuration": {
            "shape": "Sts"
          },
          "Permissions": {
            "shape": "S19"
          },
          "Tags": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "VersionArn": {},
          "ThemeId": {},
          "CreationStatus": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "CreateThemeAlias": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/themes/{ThemeId}/aliases/{AliasName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "ThemeId",
          "AliasName",
          "ThemeVersionNumber"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "ThemeId": {
            "location": "uri",
            "locationName": "ThemeId"
          },
          "AliasName": {
            "location": "uri",
            "locationName": "AliasName"
          },
          "ThemeVersionNumber": {
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ThemeAlias": {
            "shape": "Su8"
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "CreateTopic": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/topics"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TopicId",
          "Topic"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TopicId": {},
          "Topic": {
            "shape": "Sub"
          },
          "Tags": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "TopicId": {},
          "RefreshArn": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "CreateTopicRefreshSchedule": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/topics/{TopicId}/schedules"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TopicId",
          "DatasetArn",
          "RefreshSchedule"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TopicId": {
            "location": "uri",
            "locationName": "TopicId"
          },
          "DatasetArn": {},
          "DatasetName": {},
          "RefreshSchedule": {
            "shape": "Sw0"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TopicId": {},
          "TopicArn": {},
          "DatasetArn": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "CreateVPCConnection": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/vpc-connections"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "VPCConnectionId",
          "Name",
          "SubnetIds",
          "SecurityGroupIds",
          "RoleArn"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "VPCConnectionId": {},
          "Name": {},
          "SubnetIds": {
            "shape": "Sw5"
          },
          "SecurityGroupIds": {
            "shape": "Sw7"
          },
          "DnsResolvers": {
            "shape": "Sw9"
          },
          "RoleArn": {},
          "Tags": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "VPCConnectionId": {},
          "CreationStatus": {},
          "AvailabilityStatus": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DeleteAccountCustomization": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/customizations"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "querystring",
            "locationName": "namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DeleteAccountSubscription": {
      "http": {
        "method": "DELETE",
        "requestUri": "/account/{AwsAccountId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DeleteAnalysis": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/analyses/{AnalysisId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "AnalysisId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "AnalysisId": {
            "location": "uri",
            "locationName": "AnalysisId"
          },
          "RecoveryWindowInDays": {
            "location": "querystring",
            "locationName": "recovery-window-in-days",
            "type": "long"
          },
          "ForceDeleteWithoutRecovery": {
            "location": "querystring",
            "locationName": "force-delete-without-recovery",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "Arn": {},
          "AnalysisId": {},
          "DeletionTime": {
            "type": "timestamp"
          },
          "RequestId": {}
        }
      }
    },
    "DeleteDashboard": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/dashboards/{DashboardId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DashboardId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DashboardId": {
            "location": "uri",
            "locationName": "DashboardId"
          },
          "VersionNumber": {
            "location": "querystring",
            "locationName": "version-number",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "Arn": {},
          "DashboardId": {},
          "RequestId": {}
        }
      }
    },
    "DeleteDataSet": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/data-sets/{DataSetId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DataSetId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DataSetId": {
            "location": "uri",
            "locationName": "DataSetId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "DataSetId": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DeleteDataSetRefreshProperties": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/data-sets/{DataSetId}/refresh-properties"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DataSetId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DataSetId": {
            "location": "uri",
            "locationName": "DataSetId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DeleteDataSource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/data-sources/{DataSourceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DataSourceId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DataSourceId": {
            "location": "uri",
            "locationName": "DataSourceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "DataSourceId": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DeleteFolder": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/folders/{FolderId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "FolderId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "FolderId": {
            "location": "uri",
            "locationName": "FolderId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "Arn": {},
          "FolderId": {},
          "RequestId": {}
        }
      }
    },
    "DeleteFolderMembership": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/folders/{FolderId}/members/{MemberType}/{MemberId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "FolderId",
          "MemberId",
          "MemberType"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "FolderId": {
            "location": "uri",
            "locationName": "FolderId"
          },
          "MemberId": {
            "location": "uri",
            "locationName": "MemberId"
          },
          "MemberType": {
            "location": "uri",
            "locationName": "MemberType"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "DeleteGroup": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/groups/{GroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "GroupName",
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "GroupName": {
            "location": "uri",
            "locationName": "GroupName"
          },
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DeleteGroupMembership": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/groups/{GroupName}/members/{MemberName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "MemberName",
          "GroupName",
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "MemberName": {
            "location": "uri",
            "locationName": "MemberName"
          },
          "GroupName": {
            "location": "uri",
            "locationName": "GroupName"
          },
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DeleteIAMPolicyAssignment": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/namespace/{Namespace}/iam-policy-assignments/{AssignmentName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "AssignmentName",
          "Namespace"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "AssignmentName": {
            "location": "uri",
            "locationName": "AssignmentName"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssignmentName": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DeleteIdentityPropagationConfig": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/identity-propagation-config/{Service}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "Service"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Service": {
            "location": "uri",
            "locationName": "Service"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DeleteNamespace": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DeleteRefreshSchedule": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/data-sets/{DataSetId}/refresh-schedules/{ScheduleId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "DataSetId",
          "AwsAccountId",
          "ScheduleId"
        ],
        "members": {
          "DataSetId": {
            "location": "uri",
            "locationName": "DataSetId"
          },
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "ScheduleId": {
            "location": "uri",
            "locationName": "ScheduleId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {},
          "ScheduleId": {},
          "Arn": {}
        }
      }
    },
    "DeleteRoleCustomPermission": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/roles/{Role}/custom-permission"
      },
      "input": {
        "type": "structure",
        "required": [
          "Role",
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "Role": {
            "location": "uri",
            "locationName": "Role"
          },
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestId": {},
          "Status": {
            "type": "integer"
          }
        }
      }
    },
    "DeleteRoleMembership": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/roles/{Role}/members/{MemberName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "MemberName",
          "Role",
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "MemberName": {
            "location": "uri",
            "locationName": "MemberName"
          },
          "Role": {
            "location": "uri",
            "locationName": "Role"
          },
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DeleteTemplate": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/templates/{TemplateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TemplateId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TemplateId": {
            "location": "uri",
            "locationName": "TemplateId"
          },
          "VersionNumber": {
            "location": "querystring",
            "locationName": "version-number",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestId": {},
          "Arn": {},
          "TemplateId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DeleteTemplateAlias": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/templates/{TemplateId}/aliases/{AliasName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TemplateId",
          "AliasName"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TemplateId": {
            "location": "uri",
            "locationName": "TemplateId"
          },
          "AliasName": {
            "location": "uri",
            "locationName": "AliasName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "TemplateId": {},
          "AliasName": {},
          "Arn": {},
          "RequestId": {}
        }
      }
    },
    "DeleteTheme": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/themes/{ThemeId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "ThemeId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "ThemeId": {
            "location": "uri",
            "locationName": "ThemeId"
          },
          "VersionNumber": {
            "location": "querystring",
            "locationName": "version-number",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "ThemeId": {}
        }
      }
    },
    "DeleteThemeAlias": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/themes/{ThemeId}/aliases/{AliasName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "ThemeId",
          "AliasName"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "ThemeId": {
            "location": "uri",
            "locationName": "ThemeId"
          },
          "AliasName": {
            "location": "uri",
            "locationName": "AliasName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AliasName": {},
          "Arn": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "ThemeId": {}
        }
      }
    },
    "DeleteTopic": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/topics/{TopicId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TopicId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TopicId": {
            "location": "uri",
            "locationName": "TopicId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "TopicId": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DeleteTopicRefreshSchedule": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/topics/{TopicId}/schedules/{DatasetId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TopicId",
          "DatasetId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TopicId": {
            "location": "uri",
            "locationName": "TopicId"
          },
          "DatasetId": {
            "location": "uri",
            "locationName": "DatasetId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TopicId": {},
          "TopicArn": {},
          "DatasetArn": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "DeleteUser": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/users/{UserName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "UserName",
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "UserName": {
            "location": "uri",
            "locationName": "UserName"
          },
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DeleteUserByPrincipalId": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/user-principals/{PrincipalId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "PrincipalId",
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "PrincipalId": {
            "location": "uri",
            "locationName": "PrincipalId"
          },
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DeleteVPCConnection": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{AwsAccountId}/vpc-connections/{VPCConnectionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "VPCConnectionId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "VPCConnectionId": {
            "location": "uri",
            "locationName": "VPCConnectionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "VPCConnectionId": {},
          "DeletionStatus": {},
          "AvailabilityStatus": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DescribeAccountCustomization": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/customizations"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "querystring",
            "locationName": "namespace"
          },
          "Resolved": {
            "location": "querystring",
            "locationName": "resolved",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "AwsAccountId": {},
          "Namespace": {},
          "AccountCustomization": {
            "shape": "Sa"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DescribeAccountSettings": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/settings"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AccountSettings": {
            "type": "structure",
            "members": {
              "AccountName": {},
              "Edition": {},
              "DefaultNamespace": {},
              "NotificationEmail": {},
              "PublicSharingEnabled": {
                "type": "boolean"
              },
              "TerminationProtectionEnabled": {
                "type": "boolean"
              }
            }
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DescribeAccountSubscription": {
      "http": {
        "method": "GET",
        "requestUri": "/account/{AwsAccountId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AccountInfo": {
            "type": "structure",
            "members": {
              "AccountName": {},
              "Edition": {},
              "NotificationEmail": {},
              "AuthenticationType": {},
              "AccountSubscriptionStatus": {},
              "IAMIdentityCenterInstanceArn": {}
            }
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "DescribeAnalysis": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/analyses/{AnalysisId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "AnalysisId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "AnalysisId": {
            "location": "uri",
            "locationName": "AnalysisId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Analysis": {
            "type": "structure",
            "members": {
              "AnalysisId": {},
              "Arn": {},
              "Name": {},
              "Status": {},
              "Errors": {
                "shape": "Sya"
              },
              "DataSetArns": {
                "shape": "Syf"
              },
              "ThemeArn": {},
              "CreatedTime": {
                "type": "timestamp"
              },
              "LastUpdatedTime": {
                "type": "timestamp"
              },
              "Sheets": {
                "shape": "Syg"
              }
            }
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "DescribeAnalysisDefinition": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/analyses/{AnalysisId}/definition"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "AnalysisId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "AnalysisId": {
            "location": "uri",
            "locationName": "AnalysisId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AnalysisId": {},
          "Name": {},
          "Errors": {
            "shape": "Sya"
          },
          "ResourceStatus": {},
          "ThemeArn": {},
          "Definition": {
            "shape": "S1h"
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "DescribeAnalysisPermissions": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/analyses/{AnalysisId}/permissions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "AnalysisId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "AnalysisId": {
            "location": "uri",
            "locationName": "AnalysisId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AnalysisId": {},
          "AnalysisArn": {},
          "Permissions": {
            "shape": "S19"
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "DescribeAssetBundleExportJob": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/asset-bundle-export-jobs/{AssetBundleExportJobId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "AssetBundleExportJobId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "AssetBundleExportJobId": {
            "location": "uri",
            "locationName": "AssetBundleExportJobId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobStatus": {},
          "DownloadUrl": {
            "shape": "Syp"
          },
          "Errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "Type": {},
                "Message": {}
              }
            }
          },
          "Arn": {},
          "CreatedTime": {
            "type": "timestamp"
          },
          "AssetBundleExportJobId": {},
          "AwsAccountId": {},
          "ResourceArns": {
            "shape": "Sys"
          },
          "IncludeAllDependencies": {
            "type": "boolean"
          },
          "ExportFormat": {},
          "CloudFormationOverridePropertyConfiguration": {
            "shape": "Syu"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "IncludePermissions": {
            "type": "boolean"
          },
          "IncludeTags": {
            "type": "boolean"
          },
          "ValidationStrategy": {
            "shape": "Szo"
          },
          "Warnings": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "Message": {}
              }
            }
          }
        }
      }
    },
    "DescribeAssetBundleImportJob": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/asset-bundle-import-jobs/{AssetBundleImportJobId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "AssetBundleImportJobId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "AssetBundleImportJobId": {
            "location": "uri",
            "locationName": "AssetBundleImportJobId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobStatus": {},
          "Errors": {
            "shape": "Szu"
          },
          "RollbackErrors": {
            "shape": "Szu"
          },
          "Arn": {},
          "CreatedTime": {
            "type": "timestamp"
          },
          "AssetBundleImportJobId": {},
          "AwsAccountId": {},
          "AssetBundleImportSource": {
            "type": "structure",
            "members": {
              "Body": {
                "shape": "Syp"
              },
              "S3Uri": {}
            }
          },
          "OverrideParameters": {
            "shape": "Szy"
          },
          "FailureAction": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "OverridePermissions": {
            "shape": "S10h"
          },
          "OverrideTags": {
            "shape": "S10x"
          },
          "OverrideValidationStrategy": {
            "shape": "S11a"
          }
        }
      }
    },
    "DescribeDashboard": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/dashboards/{DashboardId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DashboardId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DashboardId": {
            "location": "uri",
            "locationName": "DashboardId"
          },
          "VersionNumber": {
            "location": "querystring",
            "locationName": "version-number",
            "type": "long"
          },
          "AliasName": {
            "location": "querystring",
            "locationName": "alias-name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Dashboard": {
            "type": "structure",
            "members": {
              "DashboardId": {},
              "Arn": {},
              "Name": {},
              "Version": {
                "type": "structure",
                "members": {
                  "CreatedTime": {
                    "type": "timestamp"
                  },
                  "Errors": {
                    "shape": "S11f"
                  },
                  "VersionNumber": {
                    "type": "long"
                  },
                  "Status": {},
                  "Arn": {},
                  "SourceEntityArn": {},
                  "DataSetArns": {
                    "shape": "Syf"
                  },
                  "Description": {},
                  "ThemeArn": {},
                  "Sheets": {
                    "shape": "Syg"
                  }
                }
              },
              "CreatedTime": {
                "type": "timestamp"
              },
              "LastPublishedTime": {
                "type": "timestamp"
              },
              "LastUpdatedTime": {
                "type": "timestamp"
              },
              "LinkEntities": {
                "shape": "Sn1"
              }
            }
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "DescribeDashboardDefinition": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/dashboards/{DashboardId}/definition"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DashboardId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DashboardId": {
            "location": "uri",
            "locationName": "DashboardId"
          },
          "VersionNumber": {
            "location": "querystring",
            "locationName": "version-number",
            "type": "long"
          },
          "AliasName": {
            "location": "querystring",
            "locationName": "alias-name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DashboardId": {},
          "Errors": {
            "shape": "S11f"
          },
          "Name": {},
          "ResourceStatus": {},
          "ThemeArn": {},
          "Definition": {
            "shape": "Smz"
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {},
          "DashboardPublishOptions": {
            "shape": "Smm"
          }
        }
      }
    },
    "DescribeDashboardPermissions": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/dashboards/{DashboardId}/permissions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DashboardId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DashboardId": {
            "location": "uri",
            "locationName": "DashboardId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DashboardId": {},
          "DashboardArn": {},
          "Permissions": {
            "shape": "S19"
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {},
          "LinkSharingConfiguration": {
            "shape": "Sn0"
          }
        }
      }
    },
    "DescribeDashboardSnapshotJob": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/dashboards/{DashboardId}/snapshot-jobs/{SnapshotJobId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DashboardId",
          "SnapshotJobId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DashboardId": {
            "location": "uri",
            "locationName": "DashboardId"
          },
          "SnapshotJobId": {
            "location": "uri",
            "locationName": "SnapshotJobId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AwsAccountId": {},
          "DashboardId": {},
          "SnapshotJobId": {},
          "UserConfiguration": {
            "type": "structure",
            "members": {
              "AnonymousUsers": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "RowLevelPermissionTagKeys": {
                      "type": "list",
                      "member": {}
                    }
                  }
                }
              }
            }
          },
          "SnapshotConfiguration": {
            "shape": "S11s"
          },
          "Arn": {},
          "JobStatus": {},
          "CreatedTime": {
            "type": "timestamp"
          },
          "LastUpdatedTime": {
            "type": "timestamp"
          },
          "RequestId": {},
          "Status": {
            "type": "integer"
          }
        }
      }
    },
    "DescribeDashboardSnapshotJobResult": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/dashboards/{DashboardId}/snapshot-jobs/{SnapshotJobId}/result"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DashboardId",
          "SnapshotJobId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DashboardId": {
            "location": "uri",
            "locationName": "DashboardId"
          },
          "SnapshotJobId": {
            "location": "uri",
            "locationName": "SnapshotJobId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "JobStatus": {},
          "CreatedTime": {
            "type": "timestamp"
          },
          "LastUpdatedTime": {
            "type": "timestamp"
          },
          "Result": {
            "type": "structure",
            "members": {
              "AnonymousUsers": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "FileGroups": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "members": {
                          "Files": {
                            "shape": "S11v"
                          },
                          "S3Results": {
                            "type": "list",
                            "member": {
                              "type": "structure",
                              "members": {
                                "S3DestinationConfiguration": {
                                  "shape": "S124"
                                },
                                "S3Uri": {
                                  "shape": "Syp"
                                },
                                "ErrorInfo": {
                                  "type": "list",
                                  "member": {
                                    "type": "structure",
                                    "members": {
                                      "ErrorMessage": {},
                                      "ErrorType": {}
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          "ErrorInfo": {
            "type": "structure",
            "members": {
              "ErrorMessage": {},
              "ErrorType": {}
            }
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DescribeDataSet": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/data-sets/{DataSetId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DataSetId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DataSetId": {
            "location": "uri",
            "locationName": "DataSetId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataSet": {
            "type": "structure",
            "members": {
              "Arn": {},
              "DataSetId": {},
              "Name": {},
              "CreatedTime": {
                "type": "timestamp"
              },
              "LastUpdatedTime": {
                "type": "timestamp"
              },
              "PhysicalTableMap": {
                "shape": "Sn7"
              },
              "LogicalTableMap": {
                "shape": "Snr"
              },
              "OutputColumns": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "Name": {},
                    "Description": {},
                    "Type": {},
                    "SubType": {}
                  }
                }
              },
              "ImportMode": {},
              "ConsumedSpiceCapacityInBytes": {
                "type": "long"
              },
              "ColumnGroups": {
                "shape": "Soy"
              },
              "FieldFolders": {
                "shape": "Sp4"
              },
              "RowLevelPermissionDataSet": {
                "shape": "Sp9"
              },
              "RowLevelPermissionTagConfiguration": {
                "shape": "Spd"
              },
              "ColumnLevelPermissionRules": {
                "shape": "Spl"
              },
              "DataSetUsageConfiguration": {
                "shape": "Spp"
              },
              "DatasetParameters": {
                "shape": "Spq"
              }
            }
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DescribeDataSetPermissions": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/data-sets/{DataSetId}/permissions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DataSetId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DataSetId": {
            "location": "uri",
            "locationName": "DataSetId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataSetArn": {},
          "DataSetId": {},
          "Permissions": {
            "shape": "S19"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DescribeDataSetRefreshProperties": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/data-sets/{DataSetId}/refresh-properties"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DataSetId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DataSetId": {
            "location": "uri",
            "locationName": "DataSetId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "DataSetRefreshProperties": {
            "shape": "S12s"
          }
        }
      }
    },
    "DescribeDataSource": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/data-sources/{DataSourceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DataSourceId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DataSourceId": {
            "location": "uri",
            "locationName": "DataSourceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataSource": {
            "shape": "S130"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DescribeDataSourcePermissions": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/data-sources/{DataSourceId}/permissions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DataSourceId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DataSourceId": {
            "location": "uri",
            "locationName": "DataSourceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataSourceArn": {},
          "DataSourceId": {},
          "Permissions": {
            "shape": "S19"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DescribeFolder": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/folders/{FolderId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "FolderId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "FolderId": {
            "location": "uri",
            "locationName": "FolderId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "Folder": {
            "type": "structure",
            "members": {
              "FolderId": {},
              "Arn": {},
              "Name": {},
              "FolderType": {},
              "FolderPath": {
                "type": "list",
                "member": {}
              },
              "CreatedTime": {
                "type": "timestamp"
              },
              "LastUpdatedTime": {
                "type": "timestamp"
              },
              "SharingModel": {}
            }
          },
          "RequestId": {}
        }
      }
    },
    "DescribeFolderPermissions": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/folders/{FolderId}/permissions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "FolderId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "FolderId": {
            "location": "uri",
            "locationName": "FolderId"
          },
          "Namespace": {
            "location": "querystring",
            "locationName": "namespace"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "FolderId": {},
          "Arn": {},
          "Permissions": {
            "shape": "S19"
          },
          "RequestId": {},
          "NextToken": {}
        }
      }
    },
    "DescribeFolderResolvedPermissions": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/folders/{FolderId}/resolved-permissions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "FolderId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "FolderId": {
            "location": "uri",
            "locationName": "FolderId"
          },
          "Namespace": {
            "location": "querystring",
            "locationName": "namespace"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "FolderId": {},
          "Arn": {},
          "Permissions": {
            "shape": "S19"
          },
          "RequestId": {},
          "NextToken": {}
        }
      }
    },
    "DescribeGroup": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/groups/{GroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "GroupName",
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "GroupName": {
            "location": "uri",
            "locationName": "GroupName"
          },
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Group": {
            "shape": "Ssa"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DescribeGroupMembership": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/groups/{GroupName}/members/{MemberName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "MemberName",
          "GroupName",
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "MemberName": {
            "location": "uri",
            "locationName": "MemberName"
          },
          "GroupName": {
            "location": "uri",
            "locationName": "GroupName"
          },
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GroupMember": {
            "shape": "Sse"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DescribeIAMPolicyAssignment": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/iam-policy-assignments/{AssignmentName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "AssignmentName",
          "Namespace"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "AssignmentName": {
            "location": "uri",
            "locationName": "AssignmentName"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IAMPolicyAssignment": {
            "type": "structure",
            "members": {
              "AwsAccountId": {},
              "AssignmentId": {},
              "AssignmentName": {},
              "PolicyArn": {},
              "Identities": {
                "shape": "Ssi"
              },
              "AssignmentStatus": {}
            }
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DescribeIngestion": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/data-sets/{DataSetId}/ingestions/{IngestionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DataSetId",
          "IngestionId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DataSetId": {
            "location": "uri",
            "locationName": "DataSetId"
          },
          "IngestionId": {
            "location": "uri",
            "locationName": "IngestionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Ingestion": {
            "shape": "S13n"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DescribeIpRestriction": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/ip-restriction"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AwsAccountId": {},
          "IpRestrictionRuleMap": {
            "shape": "S13w"
          },
          "VpcIdRestrictionRuleMap": {
            "shape": "S13z"
          },
          "VpcEndpointIdRestrictionRuleMap": {
            "shape": "S142"
          },
          "Enabled": {
            "type": "boolean"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DescribeNamespace": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Namespace": {
            "shape": "S147"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DescribeRefreshSchedule": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/data-sets/{DataSetId}/refresh-schedules/{ScheduleId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DataSetId",
          "ScheduleId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DataSetId": {
            "location": "uri",
            "locationName": "DataSetId"
          },
          "ScheduleId": {
            "location": "uri",
            "locationName": "ScheduleId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RefreshSchedule": {
            "shape": "Ssv"
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {},
          "Arn": {}
        }
      }
    },
    "DescribeRoleCustomPermission": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/roles/{Role}/custom-permission"
      },
      "input": {
        "type": "structure",
        "required": [
          "Role",
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "Role": {
            "location": "uri",
            "locationName": "Role"
          },
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CustomPermissionsName": {},
          "RequestId": {},
          "Status": {
            "type": "integer"
          }
        }
      }
    },
    "DescribeTemplate": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/templates/{TemplateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TemplateId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TemplateId": {
            "location": "uri",
            "locationName": "TemplateId"
          },
          "VersionNumber": {
            "location": "querystring",
            "locationName": "version-number",
            "type": "long"
          },
          "AliasName": {
            "location": "querystring",
            "locationName": "alias-name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Template": {
            "type": "structure",
            "members": {
              "Arn": {},
              "Name": {},
              "Version": {
                "type": "structure",
                "members": {
                  "CreatedTime": {
                    "type": "timestamp"
                  },
                  "Errors": {
                    "shape": "S14j"
                  },
                  "VersionNumber": {
                    "type": "long"
                  },
                  "Status": {},
                  "DataSetConfigurations": {
                    "shape": "Stb"
                  },
                  "Description": {},
                  "SourceEntityArn": {},
                  "ThemeArn": {},
                  "Sheets": {
                    "shape": "Syg"
                  }
                }
              },
              "TemplateId": {},
              "LastUpdatedTime": {
                "type": "timestamp"
              },
              "CreatedTime": {
                "type": "timestamp"
              }
            }
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "DescribeTemplateAlias": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/templates/{TemplateId}/aliases/{AliasName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TemplateId",
          "AliasName"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TemplateId": {
            "location": "uri",
            "locationName": "TemplateId"
          },
          "AliasName": {
            "location": "uri",
            "locationName": "AliasName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TemplateAlias": {
            "shape": "Stp"
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "DescribeTemplateDefinition": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/templates/{TemplateId}/definition"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TemplateId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TemplateId": {
            "location": "uri",
            "locationName": "TemplateId"
          },
          "VersionNumber": {
            "location": "querystring",
            "locationName": "version-number",
            "type": "long"
          },
          "AliasName": {
            "location": "querystring",
            "locationName": "alias-name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {},
          "TemplateId": {},
          "Errors": {
            "shape": "S14j"
          },
          "ResourceStatus": {},
          "ThemeArn": {},
          "Definition": {
            "shape": "Sta"
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "DescribeTemplatePermissions": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/templates/{TemplateId}/permissions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TemplateId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TemplateId": {
            "location": "uri",
            "locationName": "TemplateId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TemplateId": {},
          "TemplateArn": {},
          "Permissions": {
            "shape": "S19"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DescribeTheme": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/themes/{ThemeId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "ThemeId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "ThemeId": {
            "location": "uri",
            "locationName": "ThemeId"
          },
          "VersionNumber": {
            "location": "querystring",
            "locationName": "version-number",
            "type": "long"
          },
          "AliasName": {
            "location": "querystring",
            "locationName": "alias-name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Theme": {
            "type": "structure",
            "members": {
              "Arn": {},
              "Name": {},
              "ThemeId": {},
              "Version": {
                "type": "structure",
                "members": {
                  "VersionNumber": {
                    "type": "long"
                  },
                  "Arn": {},
                  "Description": {},
                  "BaseThemeId": {},
                  "CreatedTime": {
                    "type": "timestamp"
                  },
                  "Configuration": {
                    "shape": "Sts"
                  },
                  "Errors": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "members": {
                        "Type": {},
                        "Message": {}
                      }
                    }
                  },
                  "Status": {}
                }
              },
              "CreatedTime": {
                "type": "timestamp"
              },
              "LastUpdatedTime": {
                "type": "timestamp"
              },
              "Type": {}
            }
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "DescribeThemeAlias": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/themes/{ThemeId}/aliases/{AliasName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "ThemeId",
          "AliasName"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "ThemeId": {
            "location": "uri",
            "locationName": "ThemeId"
          },
          "AliasName": {
            "location": "uri",
            "locationName": "AliasName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ThemeAlias": {
            "shape": "Su8"
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "DescribeThemePermissions": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/themes/{ThemeId}/permissions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "ThemeId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "ThemeId": {
            "location": "uri",
            "locationName": "ThemeId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ThemeId": {},
          "ThemeArn": {},
          "Permissions": {
            "shape": "S19"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DescribeTopic": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/topics/{TopicId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TopicId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TopicId": {
            "location": "uri",
            "locationName": "TopicId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "TopicId": {},
          "Topic": {
            "shape": "Sub"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DescribeTopicPermissions": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/topics/{TopicId}/permissions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TopicId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TopicId": {
            "location": "uri",
            "locationName": "TopicId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TopicId": {},
          "TopicArn": {},
          "Permissions": {
            "shape": "S19"
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "DescribeTopicRefresh": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/topics/{TopicId}/refresh/{RefreshId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TopicId",
          "RefreshId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TopicId": {
            "location": "uri",
            "locationName": "TopicId"
          },
          "RefreshId": {
            "location": "uri",
            "locationName": "RefreshId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RefreshDetails": {
            "type": "structure",
            "members": {
              "RefreshArn": {},
              "RefreshId": {},
              "RefreshStatus": {}
            }
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DescribeTopicRefreshSchedule": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/topics/{TopicId}/schedules/{DatasetId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TopicId",
          "DatasetId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TopicId": {
            "location": "uri",
            "locationName": "TopicId"
          },
          "DatasetId": {
            "location": "uri",
            "locationName": "DatasetId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TopicId": {},
          "TopicArn": {},
          "DatasetArn": {},
          "RefreshSchedule": {
            "shape": "Sw0"
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "DescribeUser": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/users/{UserName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "UserName",
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "UserName": {
            "location": "uri",
            "locationName": "UserName"
          },
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "User": {
            "shape": "S15h"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "DescribeVPCConnection": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/vpc-connections/{VPCConnectionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "VPCConnectionId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "VPCConnectionId": {
            "location": "uri",
            "locationName": "VPCConnectionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VPCConnection": {
            "type": "structure",
            "members": {
              "VPCConnectionId": {},
              "Arn": {},
              "Name": {},
              "VPCId": {},
              "SecurityGroupIds": {
                "shape": "Sw7"
              },
              "DnsResolvers": {
                "shape": "Sut"
              },
              "Status": {},
              "AvailabilityStatus": {},
              "NetworkInterfaces": {
                "shape": "S15n"
              },
              "RoleArn": {},
              "CreatedTime": {
                "type": "timestamp"
              },
              "LastUpdatedTime": {
                "type": "timestamp"
              }
            }
          },
          "RequestId": {},
          "Status": {
            "type": "integer"
          }
        }
      }
    },
    "GenerateEmbedUrlForAnonymousUser": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/embed-url/anonymous-user"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "Namespace",
          "AuthorizedResourceArns",
          "ExperienceConfiguration"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "SessionLifetimeInMinutes": {
            "type": "long"
          },
          "Namespace": {},
          "SessionTags": {
            "shape": "S15t"
          },
          "AuthorizedResourceArns": {
            "type": "list",
            "member": {}
          },
          "ExperienceConfiguration": {
            "type": "structure",
            "members": {
              "Dashboard": {
                "type": "structure",
                "required": [
                  "InitialDashboardId"
                ],
                "members": {
                  "InitialDashboardId": {}
                }
              },
              "DashboardVisual": {
                "type": "structure",
                "required": [
                  "InitialDashboardVisualId"
                ],
                "members": {
                  "InitialDashboardVisualId": {
                    "shape": "S15z"
                  }
                }
              },
              "QSearchBar": {
                "type": "structure",
                "required": [
                  "InitialTopicId"
                ],
                "members": {
                  "InitialTopicId": {}
                }
              }
            }
          },
          "AllowedDomains": {
            "shape": "Sut"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "EmbedUrl",
          "Status",
          "RequestId",
          "AnonymousUserArn"
        ],
        "members": {
          "EmbedUrl": {
            "shape": "S162"
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {},
          "AnonymousUserArn": {}
        }
      }
    },
    "GenerateEmbedUrlForRegisteredUser": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/embed-url/registered-user"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "UserArn",
          "ExperienceConfiguration"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "SessionLifetimeInMinutes": {
            "type": "long"
          },
          "UserArn": {},
          "ExperienceConfiguration": {
            "type": "structure",
            "members": {
              "Dashboard": {
                "type": "structure",
                "required": [
                  "InitialDashboardId"
                ],
                "members": {
                  "InitialDashboardId": {},
                  "FeatureConfigurations": {
                    "type": "structure",
                    "members": {
                      "StatePersistence": {
                        "shape": "S167"
                      },
                      "Bookmarks": {
                        "type": "structure",
                        "required": [
                          "Enabled"
                        ],
                        "members": {
                          "Enabled": {
                            "type": "boolean"
                          }
                        }
                      }
                    }
                  }
                }
              },
              "QuickSightConsole": {
                "type": "structure",
                "members": {
                  "InitialPath": {},
                  "FeatureConfigurations": {
                    "type": "structure",
                    "members": {
                      "StatePersistence": {
                        "shape": "S167"
                      }
                    }
                  }
                }
              },
              "QSearchBar": {
                "type": "structure",
                "members": {
                  "InitialTopicId": {}
                }
              },
              "DashboardVisual": {
                "type": "structure",
                "required": [
                  "InitialDashboardVisualId"
                ],
                "members": {
                  "InitialDashboardVisualId": {
                    "shape": "S15z"
                  }
                }
              }
            }
          },
          "AllowedDomains": {
            "shape": "Sut"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "EmbedUrl",
          "Status",
          "RequestId"
        ],
        "members": {
          "EmbedUrl": {
            "shape": "S162"
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "GetDashboardEmbedUrl": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/dashboards/{DashboardId}/embed-url"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DashboardId",
          "IdentityType"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DashboardId": {
            "location": "uri",
            "locationName": "DashboardId"
          },
          "IdentityType": {
            "location": "querystring",
            "locationName": "creds-type"
          },
          "SessionLifetimeInMinutes": {
            "location": "querystring",
            "locationName": "session-lifetime",
            "type": "long"
          },
          "UndoRedoDisabled": {
            "location": "querystring",
            "locationName": "undo-redo-disabled",
            "type": "boolean"
          },
          "ResetDisabled": {
            "location": "querystring",
            "locationName": "reset-disabled",
            "type": "boolean"
          },
          "StatePersistenceEnabled": {
            "location": "querystring",
            "locationName": "state-persistence-enabled",
            "type": "boolean"
          },
          "UserArn": {
            "location": "querystring",
            "locationName": "user-arn"
          },
          "Namespace": {
            "location": "querystring",
            "locationName": "namespace"
          },
          "AdditionalDashboardIds": {
            "location": "querystring",
            "locationName": "additional-dashboard-ids",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EmbedUrl": {
            "shape": "S162"
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "GetSessionEmbedUrl": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/session-embed-url"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "EntryPoint": {
            "location": "querystring",
            "locationName": "entry-point"
          },
          "SessionLifetimeInMinutes": {
            "location": "querystring",
            "locationName": "session-lifetime",
            "type": "long"
          },
          "UserArn": {
            "location": "querystring",
            "locationName": "user-arn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EmbedUrl": {
            "shape": "S162"
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "ListAnalyses": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/analyses"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AnalysisSummaryList": {
            "shape": "S16o"
          },
          "NextToken": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "ListAssetBundleExportJobs": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/asset-bundle-export-jobs"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssetBundleExportJobSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "JobStatus": {},
                "Arn": {},
                "CreatedTime": {
                  "type": "timestamp"
                },
                "AssetBundleExportJobId": {},
                "IncludeAllDependencies": {
                  "type": "boolean"
                },
                "ExportFormat": {},
                "IncludePermissions": {
                  "type": "boolean"
                },
                "IncludeTags": {
                  "type": "boolean"
                }
              }
            }
          },
          "NextToken": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "ListAssetBundleImportJobs": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/asset-bundle-import-jobs"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssetBundleImportJobSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "JobStatus": {},
                "Arn": {},
                "CreatedTime": {
                  "type": "timestamp"
                },
                "AssetBundleImportJobId": {},
                "FailureAction": {}
              }
            }
          },
          "NextToken": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "ListDashboardVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/dashboards/{DashboardId}/versions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DashboardId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DashboardId": {
            "location": "uri",
            "locationName": "DashboardId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DashboardVersionSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "CreatedTime": {
                  "type": "timestamp"
                },
                "VersionNumber": {
                  "type": "long"
                },
                "Status": {},
                "SourceEntityArn": {},
                "Description": {}
              }
            }
          },
          "NextToken": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "ListDashboards": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/dashboards"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DashboardSummaryList": {
            "shape": "S174"
          },
          "NextToken": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "ListDataSets": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/data-sets"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataSetSummaries": {
            "shape": "S178"
          },
          "NextToken": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "ListDataSources": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/data-sources"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataSources": {
            "type": "list",
            "member": {
              "shape": "S130"
            }
          },
          "NextToken": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "ListFolderMembers": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/folders/{FolderId}/members"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "FolderId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "FolderId": {
            "location": "uri",
            "locationName": "FolderId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "FolderMemberList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "MemberId": {},
                "MemberArn": {}
              }
            }
          },
          "NextToken": {},
          "RequestId": {}
        }
      }
    },
    "ListFolders": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/folders"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "FolderSummaryList": {
            "shape": "S17j"
          },
          "NextToken": {},
          "RequestId": {}
        }
      }
    },
    "ListGroupMemberships": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/groups/{GroupName}/members"
      },
      "input": {
        "type": "structure",
        "required": [
          "GroupName",
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "GroupName": {
            "location": "uri",
            "locationName": "GroupName"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GroupMemberList": {
            "type": "list",
            "member": {
              "shape": "Sse"
            }
          },
          "NextToken": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "ListGroups": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/groups"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GroupList": {
            "shape": "S17q"
          },
          "NextToken": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "ListIAMPolicyAssignments": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/v2/iam-policy-assignments"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "AssignmentStatus": {
            "location": "querystring",
            "locationName": "assignment-status"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IAMPolicyAssignments": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AssignmentName": {},
                "AssignmentStatus": {}
              }
            }
          },
          "NextToken": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "ListIAMPolicyAssignmentsForUser": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/users/{UserName}/iam-policy-assignments"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "UserName",
          "Namespace"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "UserName": {
            "location": "uri",
            "locationName": "UserName"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ActiveAssignments": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AssignmentName": {},
                "PolicyArn": {}
              }
            }
          },
          "RequestId": {},
          "NextToken": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "ListIdentityPropagationConfigs": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/identity-propagation-config"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Services": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Service": {},
                "AuthorizedTargets": {
                  "shape": "S184"
                }
              }
            }
          },
          "NextToken": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "ListIngestions": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/data-sets/{DataSetId}/ingestions"
      },
      "input": {
        "type": "structure",
        "required": [
          "DataSetId",
          "AwsAccountId"
        ],
        "members": {
          "DataSetId": {
            "location": "uri",
            "locationName": "DataSetId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Ingestions": {
            "type": "list",
            "member": {
              "shape": "S13n"
            }
          },
          "NextToken": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "ListNamespaces": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/namespaces"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Namespaces": {
            "type": "list",
            "member": {
              "shape": "S147"
            }
          },
          "NextToken": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "ListRefreshSchedules": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/data-sets/{DataSetId}/refresh-schedules"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DataSetId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DataSetId": {
            "location": "uri",
            "locationName": "DataSetId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RefreshSchedules": {
            "type": "list",
            "member": {
              "shape": "Ssv"
            }
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "ListRoleMemberships": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/roles/{Role}/members"
      },
      "input": {
        "type": "structure",
        "required": [
          "Role",
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "Role": {
            "location": "uri",
            "locationName": "Role"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MembersList": {
            "shape": "Sk"
          },
          "NextToken": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/resources/{ResourceArn}/tags"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "Sb"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "ListTemplateAliases": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/templates/{TemplateId}/aliases"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TemplateId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TemplateId": {
            "location": "uri",
            "locationName": "TemplateId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-result",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TemplateAliasList": {
            "type": "list",
            "member": {
              "shape": "Stp"
            }
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {},
          "NextToken": {}
        }
      }
    },
    "ListTemplateVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/templates/{TemplateId}/versions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TemplateId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TemplateId": {
            "location": "uri",
            "locationName": "TemplateId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TemplateVersionSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "VersionNumber": {
                  "type": "long"
                },
                "CreatedTime": {
                  "type": "timestamp"
                },
                "Status": {},
                "Description": {}
              }
            }
          },
          "NextToken": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "ListTemplates": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/templates"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-result",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TemplateSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "TemplateId": {},
                "Name": {},
                "LatestVersionNumber": {
                  "type": "long"
                },
                "CreatedTime": {
                  "type": "timestamp"
                },
                "LastUpdatedTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "ListThemeAliases": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/themes/{ThemeId}/aliases"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "ThemeId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "ThemeId": {
            "location": "uri",
            "locationName": "ThemeId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-result",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ThemeAliasList": {
            "type": "list",
            "member": {
              "shape": "Su8"
            }
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {},
          "NextToken": {}
        }
      }
    },
    "ListThemeVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/themes/{ThemeId}/versions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "ThemeId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "ThemeId": {
            "location": "uri",
            "locationName": "ThemeId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ThemeVersionSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "VersionNumber": {
                  "type": "long"
                },
                "Arn": {},
                "Description": {},
                "CreatedTime": {
                  "type": "timestamp"
                },
                "Status": {}
              }
            }
          },
          "NextToken": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "ListThemes": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/themes"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "Type": {
            "location": "querystring",
            "locationName": "type"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ThemeSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "Name": {},
                "ThemeId": {},
                "LatestVersionNumber": {
                  "type": "long"
                },
                "CreatedTime": {
                  "type": "timestamp"
                },
                "LastUpdatedTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "ListTopicRefreshSchedules": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/topics/{TopicId}/schedules"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TopicId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TopicId": {
            "location": "uri",
            "locationName": "TopicId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TopicId": {},
          "TopicArn": {},
          "RefreshSchedules": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DatasetId": {},
                "DatasetArn": {},
                "DatasetName": {},
                "RefreshSchedule": {
                  "shape": "Sw0"
                }
              }
            }
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "ListTopics": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/topics"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TopicsSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "TopicId": {},
                "Name": {}
              }
            }
          },
          "NextToken": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "ListUserGroups": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/users/{UserName}/groups"
      },
      "input": {
        "type": "structure",
        "required": [
          "UserName",
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "UserName": {
            "location": "uri",
            "locationName": "UserName"
          },
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GroupList": {
            "shape": "S17q"
          },
          "NextToken": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "ListUsers": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/users"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserList": {
            "type": "list",
            "member": {
              "shape": "S15h"
            }
          },
          "NextToken": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "ListVPCConnections": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{AwsAccountId}/vpc-connections"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VPCConnectionSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "VPCConnectionId": {},
                "Arn": {},
                "Name": {},
                "VPCId": {},
                "SecurityGroupIds": {
                  "shape": "Sw7"
                },
                "DnsResolvers": {
                  "shape": "Sut"
                },
                "Status": {},
                "AvailabilityStatus": {},
                "NetworkInterfaces": {
                  "shape": "S15n"
                },
                "RoleArn": {},
                "CreatedTime": {
                  "type": "timestamp"
                },
                "LastUpdatedTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "PutDataSetRefreshProperties": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/data-sets/{DataSetId}/refresh-properties"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DataSetId",
          "DataSetRefreshProperties"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DataSetId": {
            "location": "uri",
            "locationName": "DataSetId"
          },
          "DataSetRefreshProperties": {
            "shape": "S12s"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "RegisterUser": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/users"
      },
      "input": {
        "type": "structure",
        "required": [
          "IdentityType",
          "Email",
          "UserRole",
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "IdentityType": {},
          "Email": {},
          "UserRole": {},
          "IamArn": {},
          "SessionName": {},
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          },
          "UserName": {},
          "CustomPermissionsName": {},
          "ExternalLoginFederationProviderType": {},
          "CustomFederationProviderUrl": {},
          "ExternalLoginId": {},
          "Tags": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "User": {
            "shape": "S15h"
          },
          "UserInvitationUrl": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "RestoreAnalysis": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/restore/analyses/{AnalysisId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "AnalysisId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "AnalysisId": {
            "location": "uri",
            "locationName": "AnalysisId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "Arn": {},
          "AnalysisId": {},
          "RequestId": {}
        }
      }
    },
    "SearchAnalyses": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/search/analyses"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "Filters"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Operator": {},
                "Name": {},
                "Value": {}
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AnalysisSummaryList": {
            "shape": "S16o"
          },
          "NextToken": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "SearchDashboards": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/search/dashboards"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "Filters"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Operator"
              ],
              "members": {
                "Operator": {},
                "Name": {},
                "Value": {}
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DashboardSummaryList": {
            "shape": "S174"
          },
          "NextToken": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "SearchDataSets": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/search/data-sets"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "Filters"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Operator",
                "Name",
                "Value"
              ],
              "members": {
                "Operator": {},
                "Name": {},
                "Value": {}
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataSetSummaries": {
            "shape": "S178"
          },
          "NextToken": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "SearchDataSources": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/search/data-sources"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "Filters"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Operator",
                "Name",
                "Value"
              ],
              "members": {
                "Operator": {},
                "Name": {},
                "Value": {}
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataSourceSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "DataSourceId": {},
                "Name": {},
                "Type": {},
                "CreatedTime": {
                  "type": "timestamp"
                },
                "LastUpdatedTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "SearchFolders": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/search/folders"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "Filters"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Operator": {},
                "Name": {},
                "Value": {}
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "FolderSummaryList": {
            "shape": "S17j"
          },
          "NextToken": {},
          "RequestId": {}
        }
      }
    },
    "SearchGroups": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/groups-search"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "Namespace",
          "Filters"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          },
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Operator",
                "Name",
                "Value"
              ],
              "members": {
                "Operator": {},
                "Name": {},
                "Value": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GroupList": {
            "shape": "S17q"
          },
          "NextToken": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "StartAssetBundleExportJob": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/asset-bundle-export-jobs/export"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "AssetBundleExportJobId",
          "ResourceArns",
          "ExportFormat"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "AssetBundleExportJobId": {},
          "ResourceArns": {
            "shape": "Sys"
          },
          "IncludeAllDependencies": {
            "type": "boolean"
          },
          "ExportFormat": {},
          "CloudFormationOverridePropertyConfiguration": {
            "shape": "Syu"
          },
          "IncludePermissions": {
            "type": "boolean"
          },
          "IncludeTags": {
            "type": "boolean"
          },
          "ValidationStrategy": {
            "shape": "Szo"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "AssetBundleExportJobId": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "StartAssetBundleImportJob": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/asset-bundle-import-jobs/import"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "AssetBundleImportJobId",
          "AssetBundleImportSource"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "AssetBundleImportJobId": {},
          "AssetBundleImportSource": {
            "type": "structure",
            "members": {
              "Body": {
                "type": "blob",
                "sensitive": true
              },
              "S3Uri": {}
            }
          },
          "OverrideParameters": {
            "shape": "Szy"
          },
          "FailureAction": {},
          "OverridePermissions": {
            "shape": "S10h"
          },
          "OverrideTags": {
            "shape": "S10x"
          },
          "OverrideValidationStrategy": {
            "shape": "S11a"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "AssetBundleImportJobId": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "StartDashboardSnapshotJob": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/dashboards/{DashboardId}/snapshot-jobs"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DashboardId",
          "SnapshotJobId",
          "UserConfiguration",
          "SnapshotConfiguration"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DashboardId": {
            "location": "uri",
            "locationName": "DashboardId"
          },
          "SnapshotJobId": {},
          "UserConfiguration": {
            "type": "structure",
            "members": {
              "AnonymousUsers": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "RowLevelPermissionTags": {
                      "shape": "S15t"
                    }
                  }
                }
              }
            }
          },
          "SnapshotConfiguration": {
            "shape": "S11s"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "SnapshotJobId": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/resources/{ResourceArn}/tags"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          },
          "Tags": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/resources/{ResourceArn}/tags"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          },
          "TagKeys": {
            "location": "querystring",
            "locationName": "keys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "UpdateAccountCustomization": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/customizations"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "AccountCustomization"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "querystring",
            "locationName": "namespace"
          },
          "AccountCustomization": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "AwsAccountId": {},
          "Namespace": {},
          "AccountCustomization": {
            "shape": "Sa"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "UpdateAccountSettings": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/settings"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DefaultNamespace"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DefaultNamespace": {},
          "NotificationEmail": {},
          "TerminationProtectionEnabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "UpdateAnalysis": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/analyses/{AnalysisId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "AnalysisId",
          "Name"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "AnalysisId": {
            "location": "uri",
            "locationName": "AnalysisId"
          },
          "Name": {},
          "Parameters": {
            "shape": "Sr"
          },
          "SourceEntity": {
            "shape": "S1d"
          },
          "ThemeArn": {},
          "Definition": {
            "shape": "S1h"
          },
          "ValidationStrategy": {
            "shape": "Smc"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "AnalysisId": {},
          "UpdateStatus": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "UpdateAnalysisPermissions": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/analyses/{AnalysisId}/permissions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "AnalysisId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "AnalysisId": {
            "location": "uri",
            "locationName": "AnalysisId"
          },
          "GrantPermissions": {
            "shape": "S1be"
          },
          "RevokePermissions": {
            "shape": "S1be"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AnalysisArn": {},
          "AnalysisId": {},
          "Permissions": {
            "shape": "S19"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "UpdateDashboard": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/dashboards/{DashboardId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DashboardId",
          "Name"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DashboardId": {
            "location": "uri",
            "locationName": "DashboardId"
          },
          "Name": {},
          "SourceEntity": {
            "shape": "Smj"
          },
          "Parameters": {
            "shape": "Sr"
          },
          "VersionDescription": {},
          "DashboardPublishOptions": {
            "shape": "Smm"
          },
          "ThemeArn": {},
          "Definition": {
            "shape": "Smz"
          },
          "ValidationStrategy": {
            "shape": "Smc"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "VersionArn": {},
          "DashboardId": {},
          "CreationStatus": {},
          "Status": {
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "UpdateDashboardLinks": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/dashboards/{DashboardId}/linked-entities"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DashboardId",
          "LinkEntities"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DashboardId": {
            "location": "uri",
            "locationName": "DashboardId"
          },
          "LinkEntities": {
            "shape": "Sn1"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "DashboardArn": {},
          "LinkEntities": {
            "shape": "Sn1"
          }
        }
      }
    },
    "UpdateDashboardPermissions": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/dashboards/{DashboardId}/permissions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DashboardId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DashboardId": {
            "location": "uri",
            "locationName": "DashboardId"
          },
          "GrantPermissions": {
            "shape": "S1be"
          },
          "RevokePermissions": {
            "shape": "S1be"
          },
          "GrantLinkPermissions": {
            "shape": "S1bl"
          },
          "RevokeLinkPermissions": {
            "shape": "S1bl"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DashboardArn": {},
          "DashboardId": {},
          "Permissions": {
            "shape": "S19"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "LinkSharingConfiguration": {
            "shape": "Sn0"
          }
        }
      }
    },
    "UpdateDashboardPublishedVersion": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/dashboards/{DashboardId}/versions/{VersionNumber}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DashboardId",
          "VersionNumber"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DashboardId": {
            "location": "uri",
            "locationName": "DashboardId"
          },
          "VersionNumber": {
            "location": "uri",
            "locationName": "VersionNumber",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DashboardId": {},
          "DashboardArn": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "UpdateDataSet": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/data-sets/{DataSetId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DataSetId",
          "Name",
          "PhysicalTableMap",
          "ImportMode"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DataSetId": {
            "location": "uri",
            "locationName": "DataSetId"
          },
          "Name": {},
          "PhysicalTableMap": {
            "shape": "Sn7"
          },
          "LogicalTableMap": {
            "shape": "Snr"
          },
          "ImportMode": {},
          "ColumnGroups": {
            "shape": "Soy"
          },
          "FieldFolders": {
            "shape": "Sp4"
          },
          "RowLevelPermissionDataSet": {
            "shape": "Sp9"
          },
          "RowLevelPermissionTagConfiguration": {
            "shape": "Spd"
          },
          "ColumnLevelPermissionRules": {
            "shape": "Spl"
          },
          "DataSetUsageConfiguration": {
            "shape": "Spp"
          },
          "DatasetParameters": {
            "shape": "Spq"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "DataSetId": {},
          "IngestionArn": {},
          "IngestionId": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "UpdateDataSetPermissions": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/data-sets/{DataSetId}/permissions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DataSetId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DataSetId": {
            "location": "uri",
            "locationName": "DataSetId"
          },
          "GrantPermissions": {
            "shape": "S19"
          },
          "RevokePermissions": {
            "shape": "S19"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataSetArn": {},
          "DataSetId": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "UpdateDataSource": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/data-sources/{DataSourceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DataSourceId",
          "Name"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DataSourceId": {
            "location": "uri",
            "locationName": "DataSourceId"
          },
          "Name": {},
          "DataSourceParameters": {
            "shape": "Sq5"
          },
          "Credentials": {
            "shape": "Srm"
          },
          "VpcConnectionProperties": {
            "shape": "Srt"
          },
          "SslProperties": {
            "shape": "Sru"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "DataSourceId": {},
          "UpdateStatus": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "UpdateDataSourcePermissions": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/data-sources/{DataSourceId}/permissions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "DataSourceId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "DataSourceId": {
            "location": "uri",
            "locationName": "DataSourceId"
          },
          "GrantPermissions": {
            "shape": "S19"
          },
          "RevokePermissions": {
            "shape": "S19"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataSourceArn": {},
          "DataSourceId": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "UpdateFolder": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/folders/{FolderId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "FolderId",
          "Name"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "FolderId": {
            "location": "uri",
            "locationName": "FolderId"
          },
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "Arn": {},
          "FolderId": {},
          "RequestId": {}
        }
      }
    },
    "UpdateFolderPermissions": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/folders/{FolderId}/permissions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "FolderId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "FolderId": {
            "location": "uri",
            "locationName": "FolderId"
          },
          "GrantPermissions": {
            "shape": "S19"
          },
          "RevokePermissions": {
            "shape": "S19"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "type": "integer"
          },
          "Arn": {},
          "FolderId": {},
          "Permissions": {
            "shape": "S19"
          },
          "RequestId": {}
        }
      }
    },
    "UpdateGroup": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/groups/{GroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "GroupName",
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "GroupName": {
            "location": "uri",
            "locationName": "GroupName"
          },
          "Description": {},
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Group": {
            "shape": "Ssa"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "UpdateIAMPolicyAssignment": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/iam-policy-assignments/{AssignmentName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "AssignmentName",
          "Namespace"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "AssignmentName": {
            "location": "uri",
            "locationName": "AssignmentName"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          },
          "AssignmentStatus": {},
          "PolicyArn": {},
          "Identities": {
            "shape": "Ssi"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssignmentName": {},
          "AssignmentId": {},
          "PolicyArn": {},
          "Identities": {
            "shape": "Ssi"
          },
          "AssignmentStatus": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "UpdateIdentityPropagationConfig": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/identity-propagation-config/{Service}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "Service"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Service": {
            "location": "uri",
            "locationName": "Service"
          },
          "AuthorizedTargets": {
            "shape": "S184"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "UpdateIpRestriction": {
      "http": {
        "requestUri": "/accounts/{AwsAccountId}/ip-restriction"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "IpRestrictionRuleMap": {
            "shape": "S13w"
          },
          "VpcIdRestrictionRuleMap": {
            "shape": "S13z"
          },
          "VpcEndpointIdRestrictionRuleMap": {
            "shape": "S142"
          },
          "Enabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AwsAccountId": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "UpdatePublicSharingSettings": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/public-sharing-settings"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "PublicSharingEnabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "UpdateRefreshSchedule": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/data-sets/{DataSetId}/refresh-schedules"
      },
      "input": {
        "type": "structure",
        "required": [
          "DataSetId",
          "AwsAccountId",
          "Schedule"
        ],
        "members": {
          "DataSetId": {
            "location": "uri",
            "locationName": "DataSetId"
          },
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Schedule": {
            "shape": "Ssv"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {},
          "ScheduleId": {},
          "Arn": {}
        }
      }
    },
    "UpdateRoleCustomPermission": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/roles/{Role}/custom-permission"
      },
      "input": {
        "type": "structure",
        "required": [
          "CustomPermissionsName",
          "Role",
          "AwsAccountId",
          "Namespace"
        ],
        "members": {
          "CustomPermissionsName": {},
          "Role": {
            "location": "uri",
            "locationName": "Role"
          },
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestId": {},
          "Status": {
            "type": "integer"
          }
        }
      }
    },
    "UpdateTemplate": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/templates/{TemplateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TemplateId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TemplateId": {
            "location": "uri",
            "locationName": "TemplateId"
          },
          "SourceEntity": {
            "shape": "St7"
          },
          "VersionDescription": {},
          "Name": {},
          "Definition": {
            "shape": "Sta"
          },
          "ValidationStrategy": {
            "shape": "Smc"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TemplateId": {},
          "Arn": {},
          "VersionArn": {},
          "CreationStatus": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "UpdateTemplateAlias": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/templates/{TemplateId}/aliases/{AliasName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TemplateId",
          "AliasName",
          "TemplateVersionNumber"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TemplateId": {
            "location": "uri",
            "locationName": "TemplateId"
          },
          "AliasName": {
            "location": "uri",
            "locationName": "AliasName"
          },
          "TemplateVersionNumber": {
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TemplateAlias": {
            "shape": "Stp"
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "UpdateTemplatePermissions": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/templates/{TemplateId}/permissions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TemplateId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TemplateId": {
            "location": "uri",
            "locationName": "TemplateId"
          },
          "GrantPermissions": {
            "shape": "S1be"
          },
          "RevokePermissions": {
            "shape": "S1be"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TemplateId": {},
          "TemplateArn": {},
          "Permissions": {
            "shape": "S19"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "UpdateTheme": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/themes/{ThemeId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "ThemeId",
          "BaseThemeId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "ThemeId": {
            "location": "uri",
            "locationName": "ThemeId"
          },
          "Name": {},
          "BaseThemeId": {},
          "VersionDescription": {},
          "Configuration": {
            "shape": "Sts"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ThemeId": {},
          "Arn": {},
          "VersionArn": {},
          "CreationStatus": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "UpdateThemeAlias": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/themes/{ThemeId}/aliases/{AliasName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "ThemeId",
          "AliasName",
          "ThemeVersionNumber"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "ThemeId": {
            "location": "uri",
            "locationName": "ThemeId"
          },
          "AliasName": {
            "location": "uri",
            "locationName": "AliasName"
          },
          "ThemeVersionNumber": {
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ThemeAlias": {
            "shape": "Su8"
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "UpdateThemePermissions": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/themes/{ThemeId}/permissions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "ThemeId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "ThemeId": {
            "location": "uri",
            "locationName": "ThemeId"
          },
          "GrantPermissions": {
            "shape": "S1be"
          },
          "RevokePermissions": {
            "shape": "S1be"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ThemeId": {},
          "ThemeArn": {},
          "Permissions": {
            "shape": "S19"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "UpdateTopic": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/topics/{TopicId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TopicId",
          "Topic"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TopicId": {
            "location": "uri",
            "locationName": "TopicId"
          },
          "Topic": {
            "shape": "Sub"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TopicId": {},
          "Arn": {},
          "RefreshArn": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "UpdateTopicPermissions": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/topics/{TopicId}/permissions"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TopicId"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TopicId": {
            "location": "uri",
            "locationName": "TopicId"
          },
          "GrantPermissions": {
            "shape": "S1be"
          },
          "RevokePermissions": {
            "shape": "S1be"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TopicId": {},
          "TopicArn": {},
          "Permissions": {
            "shape": "S19"
          },
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "UpdateTopicRefreshSchedule": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/topics/{TopicId}/schedules/{DatasetId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "TopicId",
          "DatasetId",
          "RefreshSchedule"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "TopicId": {
            "location": "uri",
            "locationName": "TopicId"
          },
          "DatasetId": {
            "location": "uri",
            "locationName": "DatasetId"
          },
          "RefreshSchedule": {
            "shape": "Sw0"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TopicId": {},
          "TopicArn": {},
          "DatasetArn": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          },
          "RequestId": {}
        }
      }
    },
    "UpdateUser": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/namespaces/{Namespace}/users/{UserName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "UserName",
          "AwsAccountId",
          "Namespace",
          "Email",
          "Role"
        ],
        "members": {
          "UserName": {
            "location": "uri",
            "locationName": "UserName"
          },
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "Namespace": {
            "location": "uri",
            "locationName": "Namespace"
          },
          "Email": {},
          "Role": {},
          "CustomPermissionsName": {},
          "UnapplyCustomPermissions": {
            "type": "boolean"
          },
          "ExternalLoginFederationProviderType": {},
          "CustomFederationProviderUrl": {},
          "ExternalLoginId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "User": {
            "shape": "S15h"
          },
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    },
    "UpdateVPCConnection": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{AwsAccountId}/vpc-connections/{VPCConnectionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AwsAccountId",
          "VPCConnectionId",
          "Name",
          "SubnetIds",
          "SecurityGroupIds",
          "RoleArn"
        ],
        "members": {
          "AwsAccountId": {
            "location": "uri",
            "locationName": "AwsAccountId"
          },
          "VPCConnectionId": {
            "location": "uri",
            "locationName": "VPCConnectionId"
          },
          "Name": {},
          "SubnetIds": {
            "shape": "Sw5"
          },
          "SecurityGroupIds": {
            "shape": "Sw7"
          },
          "DnsResolvers": {
            "shape": "Sw9"
          },
          "RoleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "VPCConnectionId": {},
          "UpdateStatus": {},
          "AvailabilityStatus": {},
          "RequestId": {},
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        }
      }
    }
  },
  "shapes": {
    "Sa": {
      "type": "structure",
      "members": {
        "DefaultTheme": {},
        "DefaultEmailCustomizationTemplate": {}
      }
    },
    "Sb": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sk": {
      "type": "list",
      "member": {}
    },
    "Sr": {
      "type": "structure",
      "members": {
        "StringParameters": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Name",
              "Values"
            ],
            "members": {
              "Name": {},
              "Values": {
                "shape": "Sv"
              }
            }
          }
        },
        "IntegerParameters": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Name",
              "Values"
            ],
            "members": {
              "Name": {},
              "Values": {
                "type": "list",
                "member": {
                  "shape": "S10"
                }
              }
            }
          }
        },
        "DecimalParameters": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Name",
              "Values"
            ],
            "members": {
              "Name": {},
              "Values": {
                "type": "list",
                "member": {
                  "shape": "S14"
                }
              }
            }
          }
        },
        "DateTimeParameters": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Name",
              "Values"
            ],
            "members": {
              "Name": {},
              "Values": {
                "type": "list",
                "member": {
                  "shape": "S18"
                }
              }
            }
          }
        }
      }
    },
    "Sv": {
      "type": "list",
      "member": {
        "shape": "Sw"
      }
    },
    "Sw": {
      "type": "string",
      "sensitive": true
    },
    "S10": {
      "type": "long",
      "sensitive": true
    },
    "S14": {
      "type": "double",
      "sensitive": true
    },
    "S18": {
      "type": "timestamp",
      "sensitive": true
    },
    "S19": {
      "type": "list",
      "member": {
        "shape": "S1a"
      }
    },
    "S1a": {
      "type": "structure",
      "required": [
        "Principal",
        "Actions"
      ],
      "members": {
        "Principal": {},
        "Actions": {
          "shape": "S1c"
        }
      }
    },
    "S1c": {
      "type": "list",
      "member": {}
    },
    "S1d": {
      "type": "structure",
      "members": {
        "SourceTemplate": {
          "type": "structure",
          "required": [
            "DataSetReferences",
            "Arn"
          ],
          "members": {
            "DataSetReferences": {
              "shape": "S1f"
            },
            "Arn": {}
          }
        }
      }
    },
    "S1f": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "DataSetPlaceholder",
          "DataSetArn"
        ],
        "members": {
          "DataSetPlaceholder": {},
          "DataSetArn": {}
        }
      }
    },
    "S1h": {
      "type": "structure",
      "required": [
        "DataSetIdentifierDeclarations"
      ],
      "members": {
        "DataSetIdentifierDeclarations": {
          "shape": "S1i"
        },
        "Sheets": {
          "shape": "S1l"
        },
        "CalculatedFields": {
          "shape": "Sk9"
        },
        "ParameterDeclarations": {
          "shape": "Skc"
        },
        "FilterGroups": {
          "shape": "Skw"
        },
        "ColumnConfigurations": {
          "shape": "Slw"
        },
        "AnalysisDefaults": {
          "shape": "Sm3"
        },
        "Options": {
          "shape": "Sma"
        }
      }
    },
    "S1i": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Identifier",
          "DataSetArn"
        ],
        "members": {
          "Identifier": {},
          "DataSetArn": {}
        }
      }
    },
    "S1l": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "SheetId"
        ],
        "members": {
          "SheetId": {},
          "Title": {},
          "Description": {},
          "Name": {},
          "ParameterControls": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DateTimePicker": {
                  "type": "structure",
                  "required": [
                    "ParameterControlId",
                    "Title",
                    "SourceParameterName"
                  ],
                  "members": {
                    "ParameterControlId": {},
                    "Title": {},
                    "SourceParameterName": {},
                    "DisplayOptions": {
                      "shape": "S1v"
                    }
                  }
                },
                "List": {
                  "type": "structure",
                  "required": [
                    "ParameterControlId",
                    "Title",
                    "SourceParameterName"
                  ],
                  "members": {
                    "ParameterControlId": {},
                    "Title": {},
                    "SourceParameterName": {},
                    "DisplayOptions": {
                      "shape": "S2a"
                    },
                    "Type": {},
                    "SelectableValues": {
                      "shape": "S2e"
                    },
                    "CascadingControlConfiguration": {
                      "shape": "S2i"
                    }
                  }
                },
                "Dropdown": {
                  "type": "structure",
                  "required": [
                    "ParameterControlId",
                    "Title",
                    "SourceParameterName"
                  ],
                  "members": {
                    "ParameterControlId": {},
                    "Title": {},
                    "SourceParameterName": {},
                    "DisplayOptions": {
                      "shape": "S2m"
                    },
                    "Type": {},
                    "SelectableValues": {
                      "shape": "S2e"
                    },
                    "CascadingControlConfiguration": {
                      "shape": "S2i"
                    }
                  }
                },
                "TextField": {
                  "type": "structure",
                  "required": [
                    "ParameterControlId",
                    "Title",
                    "SourceParameterName"
                  ],
                  "members": {
                    "ParameterControlId": {},
                    "Title": {},
                    "SourceParameterName": {},
                    "DisplayOptions": {
                      "shape": "S2o"
                    }
                  }
                },
                "TextArea": {
                  "type": "structure",
                  "required": [
                    "ParameterControlId",
                    "Title",
                    "SourceParameterName"
                  ],
                  "members": {
                    "ParameterControlId": {},
                    "Title": {},
                    "SourceParameterName": {},
                    "Delimiter": {},
                    "DisplayOptions": {
                      "shape": "S2s"
                    }
                  }
                },
                "Slider": {
                  "type": "structure",
                  "required": [
                    "ParameterControlId",
                    "Title",
                    "SourceParameterName",
                    "MaximumValue",
                    "MinimumValue",
                    "StepSize"
                  ],
                  "members": {
                    "ParameterControlId": {},
                    "Title": {},
                    "SourceParameterName": {},
                    "DisplayOptions": {
                      "shape": "S2u"
                    },
                    "MaximumValue": {
                      "type": "double"
                    },
                    "MinimumValue": {
                      "type": "double"
                    },
                    "StepSize": {
                      "type": "double"
                    }
                  }
                }
              }
            }
          },
          "FilterControls": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DateTimePicker": {
                  "type": "structure",
                  "required": [
                    "FilterControlId",
                    "Title",
                    "SourceFilterId"
                  ],
                  "members": {
                    "FilterControlId": {},
                    "Title": {},
                    "SourceFilterId": {},
                    "DisplayOptions": {
                      "shape": "S1v"
                    },
                    "Type": {}
                  }
                },
                "List": {
                  "type": "structure",
                  "required": [
                    "FilterControlId",
                    "Title",
                    "SourceFilterId"
                  ],
                  "members": {
                    "FilterControlId": {},
                    "Title": {},
                    "SourceFilterId": {},
                    "DisplayOptions": {
                      "shape": "S2a"
                    },
                    "Type": {},
                    "SelectableValues": {
                      "shape": "S31"
                    },
                    "CascadingControlConfiguration": {
                      "shape": "S2i"
                    }
                  }
                },
                "Dropdown": {
                  "type": "structure",
                  "required": [
                    "FilterControlId",
                    "Title",
                    "SourceFilterId"
                  ],
                  "members": {
                    "FilterControlId": {},
                    "Title": {},
                    "SourceFilterId": {},
                    "DisplayOptions": {
                      "shape": "S2m"
                    },
                    "Type": {},
                    "SelectableValues": {
                      "shape": "S31"
                    },
                    "CascadingControlConfiguration": {
                      "shape": "S2i"
                    }
                  }
                },
                "TextField": {
                  "type": "structure",
                  "required": [
                    "FilterControlId",
                    "Title",
                    "SourceFilterId"
                  ],
                  "members": {
                    "FilterControlId": {},
                    "Title": {},
                    "SourceFilterId": {},
                    "DisplayOptions": {
                      "shape": "S2o"
                    }
                  }
                },
                "TextArea": {
                  "type": "structure",
                  "required": [
                    "FilterControlId",
                    "Title",
                    "SourceFilterId"
                  ],
                  "members": {
                    "FilterControlId": {},
                    "Title": {},
                    "SourceFilterId": {},
                    "Delimiter": {},
                    "DisplayOptions": {
                      "shape": "S2s"
                    }
                  }
                },
                "Slider": {
                  "type": "structure",
                  "required": [
                    "FilterControlId",
                    "Title",
                    "SourceFilterId",
                    "MaximumValue",
                    "MinimumValue",
                    "StepSize"
                  ],
                  "members": {
                    "FilterControlId": {},
                    "Title": {},
                    "SourceFilterId": {},
                    "DisplayOptions": {
                      "shape": "S2u"
                    },
                    "Type": {},
                    "MaximumValue": {
                      "type": "double"
                    },
                    "MinimumValue": {
                      "type": "double"
                    },
                    "StepSize": {
                      "type": "double"
                    }
                  }
                },
                "RelativeDateTime": {
                  "type": "structure",
                  "required": [
                    "FilterControlId",
                    "Title",
                    "SourceFilterId"
                  ],
                  "members": {
                    "FilterControlId": {},
                    "Title": {},
                    "SourceFilterId": {},
                    "DisplayOptions": {
                      "type": "structure",
                      "members": {
                        "TitleOptions": {
                          "shape": "S1w"
                        },
                        "DateTimeFormat": {},
                        "InfoIconLabelOptions": {
                          "shape": "S27"
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          "Visuals": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TableVisual": {
                  "type": "structure",
                  "required": [
                    "VisualId"
                  ],
                  "members": {
                    "VisualId": {},
                    "Title": {
                      "shape": "S3c"
                    },
                    "Subtitle": {
                      "shape": "S3g"
                    },
                    "ChartConfiguration": {
                      "type": "structure",
                      "members": {
                        "FieldWells": {
                          "type": "structure",
                          "members": {
                            "TableAggregatedFieldWells": {
                              "type": "structure",
                              "members": {
                                "GroupBy": {
                                  "shape": "S3n"
                                },
                                "Values": {
                                  "shape": "S4f"
                                }
                              }
                            },
                            "TableUnaggregatedFieldWells": {
                              "type": "structure",
                              "members": {
                                "Values": {
                                  "type": "list",
                                  "member": {
                                    "type": "structure",
                                    "required": [
                                      "FieldId",
                                      "Column"
                                    ],
                                    "members": {
                                      "FieldId": {},
                                      "Column": {
                                        "shape": "S2g"
                                      },
                                      "FormatConfiguration": {
                                        "shape": "S4v"
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        },
                        "SortConfiguration": {
                          "type": "structure",
                          "members": {
                            "RowSort": {
                              "type": "list",
                              "member": {
                                "shape": "S4y"
                              }
                            },
                            "PaginationConfiguration": {
                              "shape": "S55"
                            }
                          }
                        },
                        "TableOptions": {
                          "type": "structure",
                          "members": {
                            "Orientation": {},
                            "HeaderStyle": {
                              "shape": "S5a"
                            },
                            "CellStyle": {
                              "shape": "S5a"
                            },
                            "RowAlternateColorOptions": {
                              "shape": "S5k"
                            }
                          }
                        },
                        "TotalOptions": {
                          "type": "structure",
                          "members": {
                            "TotalsVisibility": {},
                            "Placement": {},
                            "ScrollStatus": {},
                            "CustomLabel": {},
                            "TotalCellStyle": {
                              "shape": "S5a"
                            },
                            "TotalAggregationOptions": {
                              "shape": "S5q"
                            }
                          }
                        },
                        "FieldOptions": {
                          "type": "structure",
                          "members": {
                            "SelectedFieldOptions": {
                              "type": "list",
                              "member": {
                                "type": "structure",
                                "required": [
                                  "FieldId"
                                ],
                                "members": {
                                  "FieldId": {},
                                  "Width": {},
                                  "CustomLabel": {},
                                  "Visibility": {},
                                  "URLStyling": {
                                    "type": "structure",
                                    "members": {
                                      "LinkConfiguration": {
                                        "type": "structure",
                                        "required": [
                                          "Target",
                                          "Content"
                                        ],
                                        "members": {
                                          "Target": {},
                                          "Content": {
                                            "type": "structure",
                                            "members": {
                                              "CustomTextContent": {
                                                "type": "structure",
                                                "required": [
                                                  "FontConfiguration"
                                                ],
                                                "members": {
                                                  "Value": {},
                                                  "FontConfiguration": {
                                                    "shape": "S1y"
                                                  }
                                                }
                                              },
                                              "CustomIconContent": {
                                                "type": "structure",
                                                "members": {
                                                  "Icon": {}
                                                }
                                              }
                                            }
                                          }
                                        }
                                      },
                                      "ImageConfiguration": {
                                        "type": "structure",
                                        "members": {
                                          "SizingOptions": {
                                            "type": "structure",
                                            "members": {
                                              "TableCellImageScalingConfiguration": {}
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            },
                            "Order": {
                              "type": "list",
                              "member": {}
                            },
                            "PinnedFieldOptions": {
                              "type": "structure",
                              "members": {
                                "PinnedLeftFields": {
                                  "type": "list",
                                  "member": {}
                                }
                              }
                            }
                          }
                        },
                        "PaginatedReportOptions": {
                          "type": "structure",
                          "members": {
                            "VerticalOverflowVisibility": {},
                            "OverflowColumnHeaderVisibility": {}
                          }
                        },
                        "TableInlineVisualizations": {
                          "type": "list",
                          "member": {
                            "type": "structure",
                            "members": {
                              "DataBars": {
                                "type": "structure",
                                "required": [
                                  "FieldId"
                                ],
                                "members": {
                                  "FieldId": {},
                                  "PositiveColor": {},
                                  "NegativeColor": {}
                                }
                              }
                            }
                          }
                        },
                        "Interactions": {
                          "shape": "S6g"
                        }
                      }
                    },
                    "ConditionalFormatting": {
                      "type": "structure",
                      "members": {
                        "ConditionalFormattingOptions": {
                          "type": "list",
                          "member": {
                            "type": "structure",
                            "members": {
                              "Cell": {
                                "type": "structure",
                                "required": [
                                  "FieldId"
                                ],
                                "members": {
                                  "FieldId": {},
                                  "TextFormat": {
                                    "shape": "S6o"
                                  }
                                }
                              },
                              "Row": {
                                "type": "structure",
                                "members": {
                                  "BackgroundColor": {
                                    "shape": "S6p"
                                  },
                                  "TextColor": {
                                    "shape": "S6p"
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    },
                    "Actions": {
                      "shape": "S75"
                    }
                  }
                },
                "PivotTableVisual": {
                  "type": "structure",
                  "required": [
                    "VisualId"
                  ],
                  "members": {
                    "VisualId": {},
                    "Title": {
                      "shape": "S3c"
                    },
                    "Subtitle": {
                      "shape": "S3g"
                    },
                    "ChartConfiguration": {
                      "type": "structure",
                      "members": {
                        "FieldWells": {
                          "type": "structure",
                          "members": {
                            "PivotTableAggregatedFieldWells": {
                              "type": "structure",
                              "members": {
                                "Rows": {
                                  "shape": "S87"
                                },
                                "Columns": {
                                  "shape": "S87"
                                },
                                "Values": {
                                  "type": "list",
                                  "member": {
                                    "shape": "S4g"
                                  }
                                }
                              }
                            }
                          }
                        },
                        "SortConfiguration": {
                          "type": "structure",
                          "members": {
                            "FieldSortOptions": {
                              "type": "list",
                              "member": {
                                "type": "structure",
                                "required": [
                                  "FieldId",
                                  "SortBy"
                                ],
                                "members": {
                                  "FieldId": {},
                                  "SortBy": {
                                    "type": "structure",
                                    "members": {
                                      "Field": {
                                        "shape": "S4z"
                                      },
                                      "Column": {
                                        "shape": "S51"
                                      },
                                      "DataPath": {
                                        "type": "structure",
                                        "required": [
                                          "Direction",
                                          "SortPaths"
                                        ],
                                        "members": {
                                          "Direction": {},
                                          "SortPaths": {
                                            "shape": "S8e"
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        },
                        "TableOptions": {
                          "type": "structure",
                          "members": {
                            "MetricPlacement": {},
                            "SingleMetricVisibility": {},
                            "ColumnNamesVisibility": {},
                            "ToggleButtonsVisibility": {},
                            "ColumnHeaderStyle": {
                              "shape": "S5a"
                            },
                            "RowHeaderStyle": {
                              "shape": "S5a"
                            },
                            "CellStyle": {
                              "shape": "S5a"
                            },
                            "RowFieldNamesStyle": {
                              "shape": "S5a"
                            },
                            "RowAlternateColorOptions": {
                              "shape": "S5k"
                            },
                            "CollapsedRowDimensionsVisibility": {},
                            "RowsLayout": {},
                            "RowsLabelOptions": {
                              "type": "structure",
                              "members": {
                                "Visibility": {},
                                "CustomLabel": {}
                              }
                            },
                            "DefaultCellWidth": {}
                          }
                        },
                        "TotalOptions": {
                          "type": "structure",
                          "members": {
                            "RowSubtotalOptions": {
                              "shape": "S8p"
                            },
                            "ColumnSubtotalOptions": {
                              "shape": "S8p"
                            },
                            "RowTotalOptions": {
                              "shape": "S8w"
                            },
                            "ColumnTotalOptions": {
                              "shape": "S8w"
                            }
                          }
                        },
                        "FieldOptions": {
                          "type": "structure",
                          "members": {
                            "SelectedFieldOptions": {
                              "type": "list",
                              "member": {
                                "type": "structure",
                                "required": [
                                  "FieldId"
                                ],
                                "members": {
                                  "FieldId": {},
                                  "CustomLabel": {},
                                  "Visibility": {}
                                }
                              }
                            },
                            "DataPathOptions": {
                              "type": "list",
                              "member": {
                                "type": "structure",
                                "required": [
                                  "DataPathList"
                                ],
                                "members": {
                                  "DataPathList": {
                                    "shape": "S8e"
                                  },
                                  "Width": {}
                                }
                              }
                            },
                            "CollapseStateOptions": {
                              "type": "list",
                              "member": {
                                "type": "structure",
                                "required": [
                                  "Target"
                                ],
                                "members": {
                                  "Target": {
                                    "type": "structure",
                                    "members": {
                                      "FieldId": {},
                                      "FieldDataPathValues": {
                                        "shape": "S8e"
                                      }
                                    }
                                  },
                                  "State": {}
                                }
                              }
                            }
                          }
                        },
                        "PaginatedReportOptions": {
                          "type": "structure",
                          "members": {
                            "VerticalOverflowVisibility": {},
                            "OverflowColumnHeaderVisibility": {}
                          }
                        },
                        "Interactions": {
                          "shape": "S6g"
                        }
                      }
                    },
                    "ConditionalFormatting": {
                      "type": "structure",
                      "members": {
                        "ConditionalFormattingOptions": {
                          "type": "list",
                          "member": {
                            "type": "structure",
                            "members": {
                              "Cell": {
                                "type": "structure",
                                "required": [
                                  "FieldId"
                                ],
                                "members": {
                                  "FieldId": {},
                                  "TextFormat": {
                                    "shape": "S6o"
                                  },
                                  "Scope": {
                                    "shape": "S9b"
                                  },
                                  "Scopes": {
                                    "type": "list",
                                    "member": {
                                      "shape": "S9b"
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    },
                    "Actions": {
                      "shape": "S75"
                    }
                  }
                },
                "BarChartVisual": {
                  "type": "structure",
                  "required": [
                    "VisualId"
                  ],
                  "members": {
                    "VisualId": {},
                    "Title": {
                      "shape": "S3c"
                    },
                    "Subtitle": {
                      "shape": "S3g"
                    },
                    "ChartConfiguration": {
                      "type": "structure",
                      "members": {
                        "FieldWells": {
                          "type": "structure",
                          "members": {
                            "BarChartAggregatedFieldWells": {
                              "type": "structure",
                              "members": {
                                "Category": {
                                  "shape": "S3n"
                                },
                                "Values": {
                                  "shape": "S4f"
                                },
                                "Colors": {
                                  "shape": "S3n"
                                },
                                "SmallMultiples": {
                                  "shape": "S9i"
                                }
                              }
                            }
                          }
                        },
                        "SortConfiguration": {
                          "type": "structure",
                          "members": {
                            "CategorySort": {
                              "shape": "S9k"
                            },
                            "CategoryItemsLimit": {
                              "shape": "S9l"
                            },
                            "ColorSort": {
                              "shape": "S9k"
                            },
                            "ColorItemsLimit": {
                              "shape": "S9l"
                            },
                            "SmallMultiplesSort": {
                              "shape": "S9k"
                            },
                            "SmallMultiplesLimitConfiguration": {
                              "shape": "S9l"
                            }
                          }
                        },
                        "Orientation": {},
                        "BarsArrangement": {},
                        "VisualPalette": {
                          "shape": "S9p"
                        },
                        "SmallMultiplesOptions": {
                          "shape": "S9s"
                        },
                        "CategoryAxis": {
                          "shape": "Sa2"
                        },
                        "CategoryLabelOptions": {
                          "shape": "Sai"
                        },
                        "ValueAxis": {
                          "shape": "Sa2"
                        },
                        "ValueLabelOptions": {
                          "shape": "Sai"
                        },
                        "ColorLabelOptions": {
                          "shape": "Sai"
                        },
                        "Legend": {
                          "shape": "Sam"
                        },
                        "DataLabels": {
                          "shape": "Sao"
                        },
                        "Tooltip": {
                          "shape": "Saz"
                        },
                        "ReferenceLines": {
                          "shape": "Sb8"
                        },
                        "ContributionAnalysisDefaults": {
                          "shape": "Sbn"
                        },
                        "Interactions": {
                          "shape": "S6g"
                        }
                      }
                    },
                    "Actions": {
                      "shape": "S75"
                    },
                    "ColumnHierarchies": {
                      "shape": "Sbq"
                    }
                  }
                },
                "KPIVisual": {
                  "type": "structure",
                  "required": [
                    "VisualId"
                  ],
                  "members": {
                    "VisualId": {},
                    "Title": {
                      "shape": "S3c"
                    },
                    "Subtitle": {
                      "shape": "S3g"
                    },
                    "ChartConfiguration": {
                      "type": "structure",
                      "members": {
                        "FieldWells": {
                          "type": "structure",
                          "members": {
                            "Values": {
                              "shape": "S4f"
                            },
                            "TargetValues": {
                              "shape": "S4f"
                            },
                            "TrendGroups": {
                              "shape": "S3n"
                            }
                          }
                        },
                        "SortConfiguration": {
                          "type": "structure",
                          "members": {
                            "TrendGroupSort": {
                              "shape": "S9k"
                            }
                          }
                        },
                        "KPIOptions": {
                          "type": "structure",
                          "members": {
                            "ProgressBar": {
                              "type": "structure",
                              "members": {
                                "Visibility": {}
                              }
                            },
                            "TrendArrows": {
                              "type": "structure",
                              "members": {
                                "Visibility": {}
                              }
                            },
                            "SecondaryValue": {
                              "type": "structure",
                              "members": {
                                "Visibility": {}
                              }
                            },
                            "Comparison": {
                              "shape": "Scd"
                            },
                            "PrimaryValueDisplayType": {},
                            "PrimaryValueFontConfiguration": {
                              "shape": "S1y"
                            },
                            "SecondaryValueFontConfiguration": {
                              "shape": "S1y"
                            },
                            "Sparkline": {
                              "type": "structure",
                              "required": [
                                "Type"
                              ],
                              "members": {
                                "Visibility": {},
                                "Type": {},
                                "Color": {},
                                "TooltipVisibility": {}
                              }
                            },
                            "VisualLayoutOptions": {
                              "type": "structure",
                              "members": {
                                "StandardLayout": {
                                  "type": "structure",
                                  "required": [
                                    "Type"
                                  ],
                                  "members": {
                                    "Type": {}
                                  }
                                }
                              }
                            }
                          }
                        },
                        "Interactions": {
                          "shape": "S6g"
                        }
                      }
                    },
                    "ConditionalFormatting": {
                      "type": "structure",
                      "members": {
                        "ConditionalFormattingOptions": {
                          "type": "list",
                          "member": {
                            "type": "structure",
                            "members": {
                              "PrimaryValue": {
                                "type": "structure",
                                "members": {
                                  "TextColor": {
                                    "shape": "S6p"
                                  },
                                  "Icon": {
                                    "shape": "S6v"
                                  }
                                }
                              },
                              "ProgressBar": {
                                "type": "structure",
                                "members": {
                                  "ForegroundColor": {
                                    "shape": "S6p"
                                  }
                                }
                              },
                              "ActualValue": {
                                "type": "structure",
                                "members": {
                                  "TextColor": {
                                    "shape": "S6p"
                                  },
                                  "Icon": {
                                    "shape": "S6v"
                                  }
                                }
                              },
                              "ComparisonValue": {
                                "type": "structure",
                                "members": {
                                  "TextColor": {
                                    "shape": "S6p"
                                  },
                                  "Icon": {
                                    "shape": "S6v"
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    },
                    "Actions": {
                      "shape": "S75"
                    },
                    "ColumnHierarchies": {
                      "shape": "Sbq"
                    }
                  }
                },
                "PieChartVisual": {
                  "type": "structure",
                  "required": [
                    "VisualId"
                  ],
                  "members": {
                    "VisualId": {},
                    "Title": {
                      "shape": "S3c"
                    },
                    "Subtitle": {
                      "shape": "S3g"
                    },
                    "ChartConfiguration": {
                      "type": "structure",
                      "members": {
                        "FieldWells": {
                          "type": "structure",
                          "members": {
                            "PieChartAggregatedFieldWells": {
                              "type": "structure",
                              "members": {
                                "Category": {
                                  "shape": "S3n"
                                },
                                "Values": {
                                  "shape": "S4f"
                                },
                                "SmallMultiples": {
                                  "shape": "S9i"
                                }
                              }
                            }
                          }
                        },
                        "SortConfiguration": {
                          "type": "structure",
                          "members": {
                            "CategorySort": {
                              "shape": "S9k"
                            },
                            "CategoryItemsLimit": {
                              "shape": "S9l"
                            },
                            "SmallMultiplesSort": {
                              "shape": "S9k"
                            },
                            "SmallMultiplesLimitConfiguration": {
                              "shape": "S9l"
                            }
                          }
                        },
                        "DonutOptions": {
                          "type": "structure",
                          "members": {
                            "ArcOptions": {
                              "type": "structure",
                              "members": {
                                "ArcThickness": {}
                              }
                            },
                            "DonutCenterOptions": {
                              "type": "structure",
                              "members": {
                                "LabelVisibility": {}
                              }
                            }
                          }
                        },
                        "SmallMultiplesOptions": {
                          "shape": "S9s"
                        },
                        "CategoryLabelOptions": {
                          "shape": "Sai"
                        },
                        "ValueLabelOptions": {
                          "shape": "Sai"
                        },
                        "Legend": {
                          "shape": "Sam"
                        },
                        "DataLabels": {
                          "shape": "Sao"
                        },
                        "Tooltip": {
                          "shape": "Saz"
                        },
                        "VisualPalette": {
                          "shape": "S9p"
                        },
                        "ContributionAnalysisDefaults": {
                          "shape": "Sbn"
                        },
                        "Interactions": {
                          "shape": "S6g"
                        }
                      }
                    },
                    "Actions": {
                      "shape": "S75"
                    },
                    "ColumnHierarchies": {
                      "shape": "Sbq"
                    }
                  }
                },
                "GaugeChartVisual": {
                  "type": "structure",
                  "required": [
                    "VisualId"
                  ],
                  "members": {
                    "VisualId": {},
                    "Title": {
                      "shape": "S3c"
                    },
                    "Subtitle": {
                      "shape": "S3g"
                    },
                    "ChartConfiguration": {
                      "type": "structure",
                      "members": {
                        "FieldWells": {
                          "type": "structure",
                          "members": {
                            "Values": {
                              "shape": "S4f"
                            },
                            "TargetValues": {
                              "shape": "S4f"
                            }
                          }
                        },
                        "GaugeChartOptions": {
                          "type": "structure",
                          "members": {
                            "PrimaryValueDisplayType": {},
                            "Comparison": {
                              "shape": "Scd"
                            },
                            "ArcAxis": {
                              "type": "structure",
                              "members": {
                                "Range": {
                                  "type": "structure",
                                  "members": {
                                    "Min": {
                                      "type": "double"
                                    },
                                    "Max": {
                                      "type": "double"
                                    }
                                  }
                                },
                                "ReserveRange": {
                                  "type": "integer"
                                }
                              }
                            },
                            "Arc": {
                              "type": "structure",
                              "members": {
                                "ArcAngle": {
                                  "type": "double"
                                },
                                "ArcThickness": {}
                              }
                            },
                            "PrimaryValueFontConfiguration": {
                              "shape": "S1y"
                            }
                          }
                        },
                        "DataLabels": {
                          "shape": "Sao"
                        },
                        "TooltipOptions": {
                          "shape": "Saz"
                        },
                        "VisualPalette": {
                          "shape": "S9p"
                        },
                        "Interactions": {
                          "shape": "S6g"
                        }
                      }
                    },
                    "ConditionalFormatting": {
                      "type": "structure",
                      "members": {
                        "ConditionalFormattingOptions": {
                          "type": "list",
                          "member": {
                            "type": "structure",
                            "members": {
                              "PrimaryValue": {
                                "type": "structure",
                                "members": {
                                  "TextColor": {
                                    "shape": "S6p"
                                  },
                                  "Icon": {
                                    "shape": "S6v"
                                  }
                                }
                              },
                              "Arc": {
                                "type": "structure",
                                "members": {
                                  "ForegroundColor": {
                                    "shape": "S6p"
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    },
                    "Actions": {
                      "shape": "S75"
                    }
                  }
                },
                "LineChartVisual": {
                  "type": "structure",
                  "required": [
                    "VisualId"
                  ],
                  "members": {
                    "VisualId": {},
                    "Title": {
                      "shape": "S3c"
                    },
                    "Subtitle": {
                      "shape": "S3g"
                    },
                    "ChartConfiguration": {
                      "type": "structure",
                      "members": {
                        "FieldWells": {
                          "type": "structure",
                          "members": {
                            "LineChartAggregatedFieldWells": {
                              "type": "structure",
                              "members": {
                                "Category": {
                                  "shape": "S3n"
                                },
                                "Values": {
                                  "shape": "S4f"
                                },
                                "Colors": {
                                  "shape": "S3n"
                                },
                                "SmallMultiples": {
                                  "shape": "S9i"
                                }
                              }
                            }
                          }
                        },
                        "SortConfiguration": {
                          "type": "structure",
                          "members": {
                            "CategorySort": {
                              "shape": "S9k"
                            },
                            "CategoryItemsLimitConfiguration": {
                              "shape": "S9l"
                            },
                            "ColorItemsLimitConfiguration": {
                              "shape": "S9l"
                            },
                            "SmallMultiplesSort": {
                              "shape": "S9k"
                            },
                            "SmallMultiplesLimitConfiguration": {
                              "shape": "S9l"
                            }
                          }
                        },
                        "ForecastConfigurations": {
                          "type": "list",
                          "member": {
                            "type": "structure",
                            "members": {
                              "ForecastProperties": {
                                "type": "structure",
                                "members": {
                                  "PeriodsForward": {
                                    "type": "integer"
                                  },
                                  "PeriodsBackward": {
                                    "type": "integer"
                                  },
                                  "UpperBoundary": {
                                    "type": "double"
                                  },
                                  "LowerBoundary": {
                                    "type": "double"
                                  },
                                  "PredictionInterval": {
                                    "type": "integer"
                                  },
                                  "Seasonality": {
                                    "type": "integer"
                                  }
                                }
                              },
                              "Scenario": {
                                "type": "structure",
                                "members": {
                                  "WhatIfPointScenario": {
                                    "type": "structure",
                                    "required": [
                                      "Date",
                                      "Value"
                                    ],
                                    "members": {
                                      "Date": {
                                        "type": "timestamp"
                                      },
                                      "Value": {
                                        "type": "double"
                                      }
                                    }
                                  },
                                  "WhatIfRangeScenario": {
                                    "type": "structure",
                                    "required": [
                                      "StartDate",
                                      "EndDate",
                                      "Value"
                                    ],
                                    "members": {
                                      "StartDate": {
                                        "type": "timestamp"
                                      },
                                      "EndDate": {
                                        "type": "timestamp"
                                      },
                                      "Value": {
                                        "type": "double"
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        },
                        "Type": {},
                        "SmallMultiplesOptions": {
                          "shape": "S9s"
                        },
                        "XAxisDisplayOptions": {
                          "shape": "Sa2"
                        },
                        "XAxisLabelOptions": {
                          "shape": "Sai"
                        },
                        "PrimaryYAxisDisplayOptions": {
                          "shape": "Sdv"
                        },
                        "PrimaryYAxisLabelOptions": {
                          "shape": "Sai"
                        },
                        "SecondaryYAxisDisplayOptions": {
                          "shape": "Sdv"
                        },
                        "SecondaryYAxisLabelOptions": {
                          "shape": "Sai"
                        },
                        "SingleAxisOptions": {
                          "shape": "Sdz"
                        },
                        "DefaultSeriesSettings": {
                          "type": "structure",
                          "members": {
                            "AxisBinding": {},
                            "LineStyleSettings": {
                              "shape": "Se3"
                            },
                            "MarkerStyleSettings": {
                              "shape": "Se6"
                            }
                          }
                        },
                        "Series": {
                          "type": "list",
                          "member": {
                            "type": "structure",
                            "members": {
                              "FieldSeriesItem": {
                                "type": "structure",
                                "required": [
                                  "FieldId",
                                  "AxisBinding"
                                ],
                                "members": {
                                  "FieldId": {},
                                  "AxisBinding": {},
                                  "Settings": {
                                    "shape": "Seb"
                                  }
                                }
                              },
                              "DataFieldSeriesItem": {
                                "type": "structure",
                                "required": [
                                  "FieldId",
                                  "AxisBinding"
                                ],
                                "members": {
                                  "FieldId": {},
                                  "FieldValue": {
                                    "shape": "Sw"
                                  },
                                  "AxisBinding": {},
                                  "Settings": {
                                    "shape": "Seb"
                                  }
                                }
                              }
                            }
                          }
                        },
                        "Legend": {
                          "shape": "Sam"
                        },
                        "DataLabels": {
                          "shape": "Sao"
                        },
                        "ReferenceLines": {
                          "shape": "Sb8"
                        },
                        "Tooltip": {
                          "shape": "Saz"
                        },
                        "ContributionAnalysisDefaults": {
                          "shape": "Sbn"
                        },
                        "VisualPalette": {
                          "shape": "S9p"
                        },
                        "Interactions": {
                          "shape": "S6g"
                        }
                      }
                    },
                    "Actions": {
                      "shape": "S75"
                    },
                    "ColumnHierarchies": {
                      "shape": "Sbq"
                    }
                  }
                },
                "HeatMapVisual": {
                  "type": "structure",
                  "required": [
                    "VisualId"
                  ],
                  "members": {
                    "VisualId": {},
                    "Title": {
                      "shape": "S3c"
                    },
                    "Subtitle": {
                      "shape": "S3g"
                    },
                    "ChartConfiguration": {
                      "type": "structure",
                      "members": {
                        "FieldWells": {
                          "type": "structure",
                          "members": {
                            "HeatMapAggregatedFieldWells": {
                              "type": "structure",
                              "members": {
                                "Rows": {
                                  "shape": "Seh"
                                },
                                "Columns": {
                                  "shape": "Seh"
                                },
                                "Values": {
                                  "type": "list",
                                  "member": {
                                    "shape": "S4g"
                                  }
                                }
                              }
                            }
                          }
                        },
                        "SortConfiguration": {
                          "type": "structure",
                          "members": {
                            "HeatMapRowSort": {
                              "shape": "S9k"
                            },
                            "HeatMapColumnSort": {
                              "shape": "S9k"
                            },
                            "HeatMapRowItemsLimitConfiguration": {
                              "shape": "S9l"
                            },
                            "HeatMapColumnItemsLimitConfiguration": {
                              "shape": "S9l"
                            }
                          }
                        },
                        "RowLabelOptions": {
                          "shape": "Sai"
                        },
                        "ColumnLabelOptions": {
                          "shape": "Sai"
                        },
                        "ColorScale": {
                          "shape": "Sek"
                        },
                        "Legend": {
                          "shape": "Sam"
                        },
                        "DataLabels": {
                          "shape": "Sao"
                        },
                        "Tooltip": {
                          "shape": "Saz"
                        },
                        "Interactions": {
                          "shape": "S6g"
                        }
                      }
                    },
                    "ColumnHierarchies": {
                      "shape": "Sbq"
                    },
                    "Actions": {
                      "shape": "S75"
                    }
                  }
                },
                "TreeMapVisual": {
                  "type": "structure",
                  "required": [
                    "VisualId"
                  ],
                  "members": {
                    "VisualId": {},
                    "Title": {
                      "shape": "S3c"
                    },
                    "Subtitle": {
                      "shape": "S3g"
                    },
                    "ChartConfiguration": {
                      "type": "structure",
                      "members": {
                        "FieldWells": {
                          "type": "structure",
                          "members": {
                            "TreeMapAggregatedFieldWells": {
                              "type": "structure",
                              "members": {
                                "Groups": {
                                  "type": "list",
                                  "member": {
                                    "shape": "S3o"
                                  }
                                },
                                "Sizes": {
                                  "shape": "Set"
                                },
                                "Colors": {
                                  "shape": "Set"
                                }
                              }
                            }
                          }
                        },
                        "SortConfiguration": {
                          "type": "structure",
                          "members": {
                            "TreeMapSort": {
                              "shape": "S9k"
                            },
                            "TreeMapGroupItemsLimitConfiguration": {
                              "shape": "S9l"
                            }
                          }
                        },
                        "GroupLabelOptions": {
                          "shape": "Sai"
                        },
                        "SizeLabelOptions": {
                          "shape": "Sai"
                        },
                        "ColorLabelOptions": {
                          "shape": "Sai"
                        },
                        "ColorScale": {
                          "shape": "Sek"
                        },
                        "Legend": {
                          "shape": "Sam"
                        },
                        "DataLabels": {
                          "shape": "Sao"
                        },
                        "Tooltip": {
                          "shape": "Saz"
                        },
                        "Interactions": {
                          "shape": "S6g"
                        }
                      }
                    },
                    "Actions": {
                      "shape": "S75"
                    },
                    "ColumnHierarchies": {
                      "shape": "Sbq"
                    }
                  }
                },
                "GeospatialMapVisual": {
                  "type": "structure",
                  "required": [
                    "VisualId"
                  ],
                  "members": {
                    "VisualId": {},
                    "Title": {
                      "shape": "S3c"
                    },
                    "Subtitle": {
                      "shape": "S3g"
                    },
                    "ChartConfiguration": {
                      "type": "structure",
                      "members": {
                        "FieldWells": {
                          "type": "structure",
                          "members": {
                            "GeospatialMapAggregatedFieldWells": {
                              "type": "structure",
                              "members": {
                                "Geospatial": {
                                  "shape": "S3n"
                                },
                                "Values": {
                                  "shape": "S4f"
                                },
                                "Colors": {
                                  "shape": "S3n"
                                }
                              }
                            }
                          }
                        },
                        "Legend": {
                          "shape": "Sam"
                        },
                        "Tooltip": {
                          "shape": "Saz"
                        },
                        "WindowOptions": {
                          "shape": "Sez"
                        },
                        "MapStyleOptions": {
                          "shape": "Sf4"
                        },
                        "PointStyleOptions": {
                          "type": "structure",
                          "members": {
                            "SelectedPointStyle": {},
                            "ClusterMarkerConfiguration": {
                              "type": "structure",
                              "members": {
                                "ClusterMarker": {
                                  "type": "structure",
                                  "members": {
                                    "SimpleClusterMarker": {
                                      "type": "structure",
                                      "members": {
                                        "Color": {}
                                      }
                                    }
                                  }
                                }
                              }
                            },
                            "HeatmapConfiguration": {
                              "type": "structure",
                              "members": {
                                "HeatmapColor": {
                                  "type": "structure",
                                  "members": {
                                    "Colors": {
                                      "type": "list",
                                      "member": {
                                        "type": "structure",
                                        "required": [
                                          "Color"
                                        ],
                                        "members": {
                                          "Color": {}
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        },
                        "VisualPalette": {
                          "shape": "S9p"
                        },
                        "Interactions": {
                          "shape": "S6g"
                        }
                      }
                    },
                    "ColumnHierarchies": {
                      "shape": "Sbq"
                    },
                    "Actions": {
                      "shape": "S75"
                    }
                  }
                },
                "FilledMapVisual": {
                  "type": "structure",
                  "required": [
                    "VisualId"
                  ],
                  "members": {
                    "VisualId": {},
                    "Title": {
                      "shape": "S3c"
                    },
                    "Subtitle": {
                      "shape": "S3g"
                    },
                    "ChartConfiguration": {
                      "type": "structure",
                      "members": {
                        "FieldWells": {
                          "type": "structure",
                          "members": {
                            "FilledMapAggregatedFieldWells": {
                              "type": "structure",
                              "members": {
                                "Geospatial": {
                                  "type": "list",
                                  "member": {
                                    "shape": "S3o"
                                  }
                                },
                                "Values": {
                                  "type": "list",
                                  "member": {
                                    "shape": "S4g"
                                  }
                                }
                              }
                            }
                          }
                        },
                        "SortConfiguration": {
                          "type": "structure",
                          "members": {
                            "CategorySort": {
                              "shape": "S9k"
                            }
                          }
                        },
                        "Legend": {
                          "shape": "Sam"
                        },
                        "Tooltip": {
                          "shape": "Saz"
                        },
                        "WindowOptions": {
                          "shape": "Sez"
                        },
                        "MapStyleOptions": {
                          "shape": "Sf4"
                        },
                        "Interactions": {
                          "shape": "S6g"
                        }
                      }
                    },
                    "ConditionalFormatting": {
                      "type": "structure",
                      "required": [
                        "ConditionalFormattingOptions"
                      ],
                      "members": {
                        "ConditionalFormattingOptions": {
                          "type": "list",
                          "member": {
                            "type": "structure",
                            "required": [
                              "Shape"
                            ],
                            "members": {
                              "Shape": {
                                "type": "structure",
                                "required": [
                                  "FieldId"
                                ],
                                "members": {
                                  "FieldId": {},
                                  "Format": {
                                    "type": "structure",
                                    "required": [
                                      "BackgroundColor"
                                    ],
                                    "members": {
                                      "BackgroundColor": {
                                        "shape": "S6p"
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    },
                    "ColumnHierarchies": {
                      "shape": "Sbq"
                    },
                    "Actions": {
                      "shape": "S75"
                    }
                  }
                },
                "FunnelChartVisual": {
                  "type": "structure",
                  "required": [
                    "VisualId"
                  ],
                  "members": {
                    "VisualId": {},
                    "Title": {
                      "shape": "S3c"
                    },
                    "Subtitle": {
                      "shape": "S3g"
                    },
                    "ChartConfiguration": {
                      "type": "structure",
                      "members": {
                        "FieldWells": {
                          "type": "structure",
                          "members": {
                            "FunnelChartAggregatedFieldWells": {
                              "type": "structure",
                              "members": {
                                "Category": {
                                  "type": "list",
                                  "member": {
                                    "shape": "S3o"
                                  }
                                },
                                "Values": {
                                  "type": "list",
                                  "member": {
                                    "shape": "S4g"
                                  }
                                }
                              }
                            }
                          }
                        },
                        "SortConfiguration": {
                          "type": "structure",
                          "members": {
                            "CategorySort": {
                              "shape": "S9k"
                            },
                            "CategoryItemsLimit": {
                              "shape": "S9l"
                            }
                          }
                        },
                        "CategoryLabelOptions": {
                          "shape": "Sai"
                        },
                        "ValueLabelOptions": {
                          "shape": "Sai"
                        },
                        "Tooltip": {
                          "shape": "Saz"
                        },
                        "DataLabelOptions": {
                          "type": "structure",
                          "members": {
                            "Visibility": {},
                            "CategoryLabelVisibility": {},
                            "MeasureLabelVisibility": {},
                            "Position": {},
                            "LabelFontConfiguration": {
                              "shape": "S1y"
                            },
                            "LabelColor": {},
                            "MeasureDataLabelStyle": {}
                          }
                        },
                        "VisualPalette": {
                          "shape": "S9p"
                        },
                        "Interactions": {
                          "shape": "S6g"
                        }
                      }
                    },
                    "Actions": {
                      "shape": "S75"
                    },
                    "ColumnHierarchies": {
                      "shape": "Sbq"
                    }
                  }
                },
                "ScatterPlotVisual": {
                  "type": "structure",
                  "required": [
                    "VisualId"
                  ],
                  "members": {
                    "VisualId": {},
                    "Title": {
                      "shape": "S3c"
                    },
                    "Subtitle": {
                      "shape": "S3g"
                    },
                    "ChartConfiguration": {
                      "type": "structure",
                      "members": {
                        "FieldWells": {
                          "type": "structure",
                          "members": {
                            "ScatterPlotCategoricallyAggregatedFieldWells": {
                              "type": "structure",
                              "members": {
                                "XAxis": {
                                  "shape": "S4f"
                                },
                                "YAxis": {
                                  "shape": "S4f"
                                },
                                "Category": {
                                  "shape": "S3n"
                                },
                                "Size": {
                                  "shape": "S4f"
                                },
                                "Label": {
                                  "shape": "S3n"
                                }
                              }
                            },
                            "ScatterPlotUnaggregatedFieldWells": {
                              "type": "structure",
                              "members": {
                                "XAxis": {
                                  "shape": "S3n"
                                },
                                "YAxis": {
                                  "shape": "S3n"
                                },
                                "Size": {
                                  "shape": "S4f"
                                },
                                "Category": {
                                  "shape": "S3n"
                                },
                                "Label": {
                                  "shape": "S3n"
                                }
                              }
                            }
                          }
                        },
                        "SortConfiguration": {
                          "type": "structure",
                          "members": {
                            "ScatterPlotLimitConfiguration": {
                              "shape": "S9l"
                            }
                          }
                        },
                        "XAxisLabelOptions": {
                          "shape": "Sai"
                        },
                        "XAxisDisplayOptions": {
                          "shape": "Sa2"
                        },
                        "YAxisLabelOptions": {
                          "shape": "Sai"
                        },
                        "YAxisDisplayOptions": {
                          "shape": "Sa2"
                        },
                        "Legend": {
                          "shape": "Sam"
                        },
                        "DataLabels": {
                          "shape": "Sao"
                        },
                        "Tooltip": {
                          "shape": "Saz"
                        },
                        "VisualPalette": {
                          "shape": "S9p"
                        },
                        "Interactions": {
                          "shape": "S6g"
                        }
                      }
                    },
                    "Actions": {
                      "shape": "S75"
                    },
                    "ColumnHierarchies": {
                      "shape": "Sbq"
                    }
                  }
                },
                "ComboChartVisual": {
                  "type": "structure",
                  "required": [
                    "VisualId"
                  ],
                  "members": {
                    "VisualId": {},
                    "Title": {
                      "shape": "S3c"
                    },
                    "Subtitle": {
                      "shape": "S3g"
                    },
                    "ChartConfiguration": {
                      "type": "structure",
                      "members": {
                        "FieldWells": {
                          "type": "structure",
                          "members": {
                            "ComboChartAggregatedFieldWells": {
                              "type": "structure",
                              "members": {
                                "Category": {
                                  "shape": "S3n"
                                },
                                "BarValues": {
                                  "shape": "S4f"
                                },
                                "Colors": {
                                  "shape": "S3n"
                                },
                                "LineValues": {
                                  "shape": "S4f"
                                }
                              }
                            }
                          }
                        },
                        "SortConfiguration": {
                          "type": "structure",
                          "members": {
                            "CategorySort": {
                              "shape": "S9k"
                            },
                            "CategoryItemsLimit": {
                              "shape": "S9l"
                            },
                            "ColorSort": {
                              "shape": "S9k"
                            },
                            "ColorItemsLimit": {
                              "shape": "S9l"
                            }
                          }
                        },
                        "BarsArrangement": {},
                        "CategoryAxis": {
                          "shape": "Sa2"
                        },
                        "CategoryLabelOptions": {
                          "shape": "Sai"
                        },
                        "PrimaryYAxisDisplayOptions": {
                          "shape": "Sa2"
                        },
                        "PrimaryYAxisLabelOptions": {
                          "shape": "Sai"
                        },
                        "SecondaryYAxisDisplayOptions": {
                          "shape": "Sa2"
                        },
                        "SecondaryYAxisLabelOptions": {
                          "shape": "Sai"
                        },
                        "SingleAxisOptions": {
                          "shape": "Sdz"
                        },
                        "ColorLabelOptions": {
                          "shape": "Sai"
                        },
                        "Legend": {
                          "shape": "Sam"
                        },
                        "BarDataLabels": {
                          "shape": "Sao"
                        },
                        "LineDataLabels": {
                          "shape": "Sao"
                        },
                        "Tooltip": {
                          "shape": "Saz"
                        },
                        "ReferenceLines": {
                          "shape": "Sb8"
                        },
                        "VisualPalette": {
                          "shape": "S9p"
                        },
                        "Interactions": {
                          "shape": "S6g"
                        }
                      }
                    },
                    "Actions": {
                      "shape": "S75"
                    },
                    "ColumnHierarchies": {
                      "shape": "Sbq"
                    }
                  }
                },
                "BoxPlotVisual": {
                  "type": "structure",
                  "required": [
                    "VisualId"
                  ],
                  "members": {
                    "VisualId": {},
                    "Title": {
                      "shape": "S3c"
                    },
                    "Subtitle": {
                      "shape": "S3g"
                    },
                    "ChartConfiguration": {
                      "type": "structure",
                      "members": {
                        "FieldWells": {
                          "type": "structure",
                          "members": {
                            "BoxPlotAggregatedFieldWells": {
                              "type": "structure",
                              "members": {
                                "GroupBy": {
                                  "type": "list",
                                  "member": {
                                    "shape": "S3o"
                                  }
                                },
                                "Values": {
                                  "type": "list",
                                  "member": {
                                    "shape": "S4g"
                                  }
                                }
                              }
                            }
                          }
                        },
                        "SortConfiguration": {
                          "type": "structure",
                          "members": {
                            "CategorySort": {
                              "shape": "S9k"
                            },
                            "PaginationConfiguration": {
                              "shape": "S55"
                            }
                          }
                        },
                        "BoxPlotOptions": {
                          "type": "structure",
                          "members": {
                            "StyleOptions": {
                              "type": "structure",
                              "members": {
                                "FillStyle": {}
                              }
                            },
                            "OutlierVisibility": {},
                            "AllDataPointsVisibility": {}
                          }
                        },
                        "CategoryAxis": {
                          "shape": "Sa2"
                        },
                        "CategoryLabelOptions": {
                          "shape": "Sai"
                        },
                        "PrimaryYAxisDisplayOptions": {
                          "shape": "Sa2"
                        },
                        "PrimaryYAxisLabelOptions": {
                          "shape": "Sai"
                        },
                        "Legend": {
                          "shape": "Sam"
                        },
                        "Tooltip": {
                          "shape": "Saz"
                        },
                        "ReferenceLines": {
                          "shape": "Sb8"
                        },
                        "VisualPalette": {
                          "shape": "S9p"
                        },
                        "Interactions": {
                          "shape": "S6g"
                        }
                      }
                    },
                    "Actions": {
                      "shape": "S75"
                    },
                    "ColumnHierarchies": {
                      "shape": "Sbq"
                    }
                  }
                },
                "WaterfallVisual": {
                  "type": "structure",
                  "required": [
                    "VisualId"
                  ],
                  "members": {
                    "VisualId": {},
                    "Title": {
                      "shape": "S3c"
                    },
                    "Subtitle": {
                      "shape": "S3g"
                    },
                    "ChartConfiguration": {
                      "type": "structure",
                      "members": {
                        "FieldWells": {
                          "type": "structure",
                          "members": {
                            "WaterfallChartAggregatedFieldWells": {
                              "type": "structure",
                              "members": {
                                "Categories": {
                                  "shape": "S3n"
                                },
                                "Values": {
                                  "shape": "S4f"
                                },
                                "Breakdowns": {
                                  "shape": "S3n"
                                }
                              }
                            }
                          }
                        },
                        "SortConfiguration": {
                          "type": "structure",
                          "members": {
                            "CategorySort": {
                              "shape": "S9k"
                            },
                            "BreakdownItemsLimit": {
                              "shape": "S9l"
                            }
                          }
                        },
                        "WaterfallChartOptions": {
                          "type": "structure",
                          "members": {
                            "TotalBarLabel": {}
                          }
                        },
                        "CategoryAxisLabelOptions": {
                          "shape": "Sai"
                        },
                        "CategoryAxisDisplayOptions": {
                          "shape": "Sa2"
                        },
                        "PrimaryYAxisLabelOptions": {
                          "shape": "Sai"
                        },
                        "PrimaryYAxisDisplayOptions": {
                          "shape": "Sa2"
                        },
                        "Legend": {
                          "shape": "Sam"
                        },
                        "DataLabels": {
                          "shape": "Sao"
                        },
                        "VisualPalette": {
                          "shape": "S9p"
                        },
                        "ColorConfiguration": {
                          "type": "structure",
                          "members": {
                            "GroupColorConfiguration": {
                              "type": "structure",
                              "members": {
                                "PositiveBarColor": {},
                                "NegativeBarColor": {},
                                "TotalBarColor": {}
                              }
                            }
                          }
                        },
                        "Interactions": {
                          "shape": "S6g"
                        }
                      }
                    },
                    "Actions": {
                      "shape": "S75"
                    },
                    "ColumnHierarchies": {
                      "shape": "Sbq"
                    }
                  }
                },
                "HistogramVisual": {
                  "type": "structure",
                  "required": [
                    "VisualId"
                  ],
                  "members": {
                    "VisualId": {},
                    "Title": {
                      "shape": "S3c"
                    },
                    "Subtitle": {
                      "shape": "S3g"
                    },
                    "ChartConfiguration": {
                      "type": "structure",
                      "members": {
                        "FieldWells": {
                          "type": "structure",
                          "members": {
                            "HistogramAggregatedFieldWells": {
                              "type": "structure",
                              "members": {
                                "Values": {
                                  "type": "list",
                                  "member": {
                                    "shape": "S4g"
                                  }
                                }
                              }
                            }
                          }
                        },
                        "XAxisDisplayOptions": {
                          "shape": "Sa2"
                        },
                        "XAxisLabelOptions": {
                          "shape": "Sai"
                        },
                        "YAxisDisplayOptions": {
                          "shape": "Sa2"
                        },
                        "BinOptions": {
                          "type": "structure",
                          "members": {
                            "SelectedBinType": {},
                            "BinCount": {
                              "type": "structure",
                              "members": {
                                "Value": {
                                  "type": "integer"
                                }
                              }
                            },
                            "BinWidth": {
                              "type": "structure",
                              "members": {
                                "Value": {
                                  "type": "double"
                                },
                                "BinCountLimit": {
                                  "type": "long"
                                }
                              }
                            },
                            "StartValue": {
                              "type": "double"
                            }
                          }
                        },
                        "DataLabels": {
                          "shape": "Sao"
                        },
                        "Tooltip": {
                          "shape": "Saz"
                        },
                        "VisualPalette": {
                          "shape": "S9p"
                        },
                        "Interactions": {
                          "shape": "S6g"
                        }
                      }
                    },
                    "Actions": {
                      "shape": "S75"
                    }
                  }
                },
                "WordCloudVisual": {
                  "type": "structure",
                  "required": [
                    "VisualId"
                  ],
                  "members": {
                    "VisualId": {},
                    "Title": {
                      "shape": "S3c"
                    },
                    "Subtitle": {
                      "shape": "S3g"
                    },
                    "ChartConfiguration": {
                      "type": "structure",
                      "members": {
                        "FieldWells": {
                          "type": "structure",
                          "members": {
                            "WordCloudAggregatedFieldWells": {
                              "type": "structure",
                              "members": {
                                "GroupBy": {
                                  "type": "list",
                                  "member": {
                                    "shape": "S3o"
                                  }
                                },
                                "Size": {
                                  "type": "list",
                                  "member": {
                                    "shape": "S4g"
                                  }
                                }
                              }
                            }
                          }
                        },
                        "SortConfiguration": {
                          "type": "structure",
                          "members": {
                            "CategoryItemsLimit": {
                              "shape": "S9l"
                            },
                            "CategorySort": {
                              "shape": "S9k"
                            }
                          }
                        },
                        "CategoryLabelOptions": {
                          "shape": "Sai"
                        },
                        "WordCloudOptions": {
                          "type": "structure",
                          "members": {
                            "WordOrientation": {},
                            "WordScaling": {},
                            "CloudLayout": {},
                            "WordCasing": {},
                            "WordPadding": {},
                            "MaximumStringLength": {
                              "type": "integer"
                            }
                          }
                        },
                        "Interactions": {
                          "shape": "S6g"
                        }
                      }
                    },
                    "Actions": {
                      "shape": "S75"
                    },
                    "ColumnHierarchies": {
                      "shape": "Sbq"
                    }
                  }
                },
                "InsightVisual": {
                  "type": "structure",
                  "required": [
                    "VisualId",
                    "DataSetIdentifier"
                  ],
                  "members": {
                    "VisualId": {},
                    "Title": {
                      "shape": "S3c"
                    },
                    "Subtitle": {
                      "shape": "S3g"
                    },
                    "InsightConfiguration": {
                      "type": "structure",
                      "members": {
                        "Computations": {
                          "type": "list",
                          "member": {
                            "type": "structure",
                            "members": {
                              "TopBottomRanked": {
                                "type": "structure",
                                "required": [
                                  "ComputationId",
                                  "Type"
                                ],
                                "members": {
                                  "ComputationId": {},
                                  "Name": {},
                                  "Category": {
                                    "shape": "S3o"
                                  },
                                  "Value": {
                                    "shape": "S4g"
                                  },
                                  "ResultSize": {
                                    "type": "integer"
                                  },
                                  "Type": {}
                                }
                              },
                              "TopBottomMovers": {
                                "type": "structure",
                                "required": [
                                  "ComputationId",
                                  "Type"
                                ],
                                "members": {
                                  "ComputationId": {},
                                  "Name": {},
                                  "Time": {
                                    "shape": "S3o"
                                  },
                                  "Category": {
                                    "shape": "S3o"
                                  },
                                  "Value": {
                                    "shape": "S4g"
                                  },
                                  "MoverSize": {
                                    "type": "integer"
                                  },
                                  "SortOrder": {},
                                  "Type": {}
                                }
                              },
                              "TotalAggregation": {
                                "type": "structure",
                                "required": [
                                  "ComputationId"
                                ],
                                "members": {
                                  "ComputationId": {},
                                  "Name": {},
                                  "Value": {
                                    "shape": "S4g"
                                  }
                                }
                              },
                              "MaximumMinimum": {
                                "type": "structure",
                                "required": [
                                  "ComputationId",
                                  "Type"
                                ],
                                "members": {
                                  "ComputationId": {},
                                  "Name": {},
                                  "Time": {
                                    "shape": "S3o"
                                  },
                                  "Value": {
                                    "shape": "S4g"
                                  },
                                  "Type": {}
                                }
                              },
                              "MetricComparison": {
                                "type": "structure",
                                "required": [
                                  "ComputationId"
                                ],
                                "members": {
                                  "ComputationId": {},
                                  "Name": {},
                                  "Time": {
                                    "shape": "S3o"
                                  },
                                  "FromValue": {
                                    "shape": "S4g"
                                  },
                                  "TargetValue": {
                                    "shape": "S4g"
                                  }
                                }
                              },
                              "PeriodOverPeriod": {
                                "type": "structure",
                                "required": [
                                  "ComputationId"
                                ],
                                "members": {
                                  "ComputationId": {},
                                  "Name": {},
                                  "Time": {
                                    "shape": "S3o"
                                  },
                                  "Value": {
                                    "shape": "S4g"
                                  }
                                }
                              },
                              "PeriodToDate": {
                                "type": "structure",
                                "required": [
                                  "ComputationId"
                                ],
                                "members": {
                                  "ComputationId": {},
                                  "Name": {},
                                  "Time": {
                                    "shape": "S3o"
                                  },
                                  "Value": {
                                    "shape": "S4g"
                                  },
                                  "PeriodTimeGranularity": {}
                                }
                              },
                              "GrowthRate": {
                                "type": "structure",
                                "required": [
                                  "ComputationId"
                                ],
                                "members": {
                                  "ComputationId": {},
                                  "Name": {},
                                  "Time": {
                                    "shape": "S3o"
                                  },
                                  "Value": {
                                    "shape": "S4g"
                                  },
                                  "PeriodSize": {
                                    "type": "integer"
                                  }
                                }
                              },
                              "UniqueValues": {
                                "type": "structure",
                                "required": [
                                  "ComputationId"
                                ],
                                "members": {
                                  "ComputationId": {},
                                  "Name": {},
                                  "Category": {
                                    "shape": "S3o"
                                  }
                                }
                              },
                              "Forecast": {
                                "type": "structure",
                                "required": [
                                  "ComputationId"
                                ],
                                "members": {
                                  "ComputationId": {},
                                  "Name": {},
                                  "Time": {
                                    "shape": "S3o"
                                  },
                                  "Value": {
                                    "shape": "S4g"
                                  },
                                  "PeriodsForward": {
                                    "type": "integer"
                                  },
                                  "PeriodsBackward": {
                                    "type": "integer"
                                  },
                                  "UpperBoundary": {
                                    "type": "double"
                                  },
                                  "LowerBoundary": {
                                    "type": "double"
                                  },
                                  "PredictionInterval": {
                                    "type": "integer"
                                  },
                                  "Seasonality": {},
                                  "CustomSeasonalityValue": {
                                    "type": "integer"
                                  }
                                }
                              }
                            }
                          }
                        },
                        "CustomNarrative": {
                          "type": "structure",
                          "required": [
                            "Narrative"
                          ],
                          "members": {
                            "Narrative": {}
                          }
                        },
                        "Interactions": {
                          "shape": "S6g"
                        }
                      }
                    },
                    "Actions": {
                      "shape": "S75"
                    },
                    "DataSetIdentifier": {}
                  }
                },
                "SankeyDiagramVisual": {
                  "type": "structure",
                  "required": [
                    "VisualId"
                  ],
                  "members": {
                    "VisualId": {},
                    "Title": {
                      "shape": "S3c"
                    },
                    "Subtitle": {
                      "shape": "S3g"
                    },
                    "ChartConfiguration": {
                      "type": "structure",
                      "members": {
                        "FieldWells": {
                          "type": "structure",
                          "members": {
                            "SankeyDiagramAggregatedFieldWells": {
                              "type": "structure",
                              "members": {
                                "Source": {
                                  "shape": "S3n"
                                },
                                "Destination": {
                                  "shape": "S3n"
                                },
                                "Weight": {
                                  "shape": "S4f"
                                }
                              }
                            }
                          }
                        },
                        "SortConfiguration": {
                          "type": "structure",
                          "members": {
                            "WeightSort": {
                              "shape": "S9k"
                            },
                            "SourceItemsLimit": {
                              "shape": "S9l"
                            },
                            "DestinationItemsLimit": {
                              "shape": "S9l"
                            }
                          }
                        },
                        "DataLabels": {
                          "shape": "Sao"
                        },
                        "Interactions": {
                          "shape": "S6g"
                        }
                      }
                    },
                    "Actions": {
                      "shape": "S75"
                    }
                  }
                },
                "CustomContentVisual": {
                  "type": "structure",
                  "required": [
                    "VisualId",
                    "DataSetIdentifier"
                  ],
                  "members": {
                    "VisualId": {},
                    "Title": {
                      "shape": "S3c"
                    },
                    "Subtitle": {
                      "shape": "S3g"
                    },
                    "ChartConfiguration": {
                      "type": "structure",
                      "members": {
                        "ContentUrl": {},
                        "ContentType": {},
                        "ImageScaling": {},
                        "Interactions": {
                          "shape": "S6g"
                        }
                      }
                    },
                    "Actions": {
                      "shape": "S75"
                    },
                    "DataSetIdentifier": {}
                  }
                },
                "EmptyVisual": {
                  "type": "structure",
                  "required": [
                    "VisualId",
                    "DataSetIdentifier"
                  ],
                  "members": {
                    "VisualId": {},
                    "DataSetIdentifier": {},
                    "Actions": {
                      "shape": "S75"
                    }
                  }
                },
                "RadarChartVisual": {
                  "type": "structure",
                  "required": [
                    "VisualId"
                  ],
                  "members": {
                    "VisualId": {},
                    "Title": {
                      "shape": "S3c"
                    },
                    "Subtitle": {
                      "shape": "S3g"
                    },
                    "ChartConfiguration": {
                      "type": "structure",
                      "members": {
                        "FieldWells": {
                          "type": "structure",
                          "members": {
                            "RadarChartAggregatedFieldWells": {
                              "type": "structure",
                              "members": {
                                "Category": {
                                  "type": "list",
                                  "member": {
                                    "shape": "S3o"
                                  }
                                },
                                "Color": {
                                  "type": "list",
                                  "member": {
                                    "shape": "S3o"
                                  }
                                },
                                "Values": {
                                  "type": "list",
                                  "member": {
                                    "shape": "S4g"
                                  }
                                }
                              }
                            }
                          }
                        },
                        "SortConfiguration": {
                          "type": "structure",
                          "members": {
                            "CategorySort": {
                              "shape": "S9k"
                            },
                            "CategoryItemsLimit": {
                              "shape": "S9l"
                            },
                            "ColorSort": {
                              "shape": "S9k"
                            },
                            "ColorItemsLimit": {
                              "shape": "S9l"
                            }
                          }
                        },
                        "Shape": {},
                        "BaseSeriesSettings": {
                          "type": "structure",
                          "members": {
                            "AreaStyleSettings": {
                              "type": "structure",
                              "members": {
                                "Visibility": {}
                              }
                            }
                          }
                        },
                        "StartAngle": {
                          "type": "double"
                        },
                        "VisualPalette": {
                          "shape": "S9p"
                        },
                        "AlternateBandColorsVisibility": {},
                        "AlternateBandEvenColor": {},
                        "AlternateBandOddColor": {},
                        "CategoryAxis": {
                          "shape": "Sa2"
                        },
                        "CategoryLabelOptions": {
                          "shape": "Sai"
                        },
                        "ColorAxis": {
                          "shape": "Sa2"
                        },
                        "ColorLabelOptions": {
                          "shape": "Sai"
                        },
                        "Legend": {
                          "shape": "Sam"
                        },
                        "AxesRangeScale": {},
                        "Interactions": {
                          "shape": "S6g"
                        }
                      }
                    },
                    "Actions": {
                      "shape": "S75"
                    },
                    "ColumnHierarchies": {
                      "shape": "Sbq"
                    }
                  }
                }
              }
            }
          },
          "TextBoxes": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "SheetTextBoxId"
              ],
              "members": {
                "SheetTextBoxId": {},
                "Content": {}
              }
            }
          },
          "Layouts": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Configuration"
              ],
              "members": {
                "Configuration": {
                  "type": "structure",
                  "members": {
                    "GridLayout": {
                      "shape": "Sj0"
                    },
                    "FreeFormLayout": {
                      "type": "structure",
                      "required": [
                        "Elements"
                      ],
                      "members": {
                        "Elements": {
                          "shape": "Sjc"
                        },
                        "CanvasSizeOptions": {
                          "shape": "Sjl"
                        }
                      }
                    },
                    "SectionBasedLayout": {
                      "type": "structure",
                      "required": [
                        "HeaderSections",
                        "BodySections",
                        "FooterSections",
                        "CanvasSizeOptions"
                      ],
                      "members": {
                        "HeaderSections": {
                          "shape": "Sjo"
                        },
                        "BodySections": {
                          "type": "list",
                          "member": {
                            "type": "structure",
                            "required": [
                              "SectionId",
                              "Content"
                            ],
                            "members": {
                              "SectionId": {},
                              "Content": {
                                "type": "structure",
                                "members": {
                                  "Layout": {
                                    "shape": "Sjq"
                                  }
                                }
                              },
                              "Style": {
                                "shape": "Sjs"
                              },
                              "PageBreakConfiguration": {
                                "type": "structure",
                                "members": {
                                  "After": {
                                    "type": "structure",
                                    "members": {
                                      "Status": {}
                                    }
                                  }
                                }
                              }
                            }
                          }
                        },
                        "FooterSections": {
                          "shape": "Sjo"
                        },
                        "CanvasSizeOptions": {
                          "shape": "Sk1"
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          "SheetControlLayouts": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Configuration"
              ],
              "members": {
                "Configuration": {
                  "type": "structure",
                  "members": {
                    "GridLayout": {
                      "shape": "Sj0"
                    }
                  }
                }
              }
            }
          },
          "ContentType": {}
        }
      }
    },
    "S1v": {
      "type": "structure",
      "members": {
        "TitleOptions": {
          "shape": "S1w"
        },
        "DateTimeFormat": {},
        "InfoIconLabelOptions": {
          "shape": "S27"
        }
      }
    },
    "S1w": {
      "type": "structure",
      "members": {
        "Visibility": {},
        "FontConfiguration": {
          "shape": "S1y"
        },
        "CustomLabel": {}
      }
    },
    "S1y": {
      "type": "structure",
      "members": {
        "FontSize": {
          "type": "structure",
          "members": {
            "Relative": {}
          }
        },
        "FontDecoration": {},
        "FontColor": {},
        "FontWeight": {
          "type": "structure",
          "members": {
            "Name": {}
          }
        },
        "FontStyle": {}
      }
    },
    "S27": {
      "type": "structure",
      "members": {
        "Visibility": {},
        "InfoIconText": {}
      }
    },
    "S2a": {
      "type": "structure",
      "members": {
        "SearchOptions": {
          "type": "structure",
          "members": {
            "Visibility": {}
          }
        },
        "SelectAllOptions": {
          "shape": "S2c"
        },
        "TitleOptions": {
          "shape": "S1w"
        },
        "InfoIconLabelOptions": {
          "shape": "S27"
        }
      }
    },
    "S2c": {
      "type": "structure",
      "members": {
        "Visibility": {}
      }
    },
    "S2e": {
      "type": "structure",
      "members": {
        "Values": {
          "shape": "S2f"
        },
        "LinkToDataSetColumn": {
          "shape": "S2g"
        }
      }
    },
    "S2f": {
      "type": "list",
      "member": {}
    },
    "S2g": {
      "type": "structure",
      "required": [
        "DataSetIdentifier",
        "ColumnName"
      ],
      "members": {
        "DataSetIdentifier": {},
        "ColumnName": {}
      }
    },
    "S2i": {
      "type": "structure",
      "members": {
        "SourceControls": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "SourceSheetControlId": {},
              "ColumnToMatch": {
                "shape": "S2g"
              }
            }
          }
        }
      }
    },
    "S2m": {
      "type": "structure",
      "members": {
        "SelectAllOptions": {
          "shape": "S2c"
        },
        "TitleOptions": {
          "shape": "S1w"
        },
        "InfoIconLabelOptions": {
          "shape": "S27"
        }
      }
    },
    "S2o": {
      "type": "structure",
      "members": {
        "TitleOptions": {
          "shape": "S1w"
        },
        "PlaceholderOptions": {
          "shape": "S2p"
        },
        "InfoIconLabelOptions": {
          "shape": "S27"
        }
      }
    },
    "S2p": {
      "type": "structure",
      "members": {
        "Visibility": {}
      }
    },
    "S2s": {
      "type": "structure",
      "members": {
        "TitleOptions": {
          "shape": "S1w"
        },
        "PlaceholderOptions": {
          "shape": "S2p"
        },
        "InfoIconLabelOptions": {
          "shape": "S27"
        }
      }
    },
    "S2u": {
      "type": "structure",
      "members": {
        "TitleOptions": {
          "shape": "S1w"
        },
        "InfoIconLabelOptions": {
          "shape": "S27"
        }
      }
    },
    "S31": {
      "type": "structure",
      "members": {
        "Values": {
          "shape": "S2f"
        }
      }
    },
    "S3c": {
      "type": "structure",
      "members": {
        "Visibility": {},
        "FormatText": {
          "type": "structure",
          "members": {
            "PlainText": {},
            "RichText": {}
          }
        }
      }
    },
    "S3g": {
      "type": "structure",
      "members": {
        "Visibility": {},
        "FormatText": {
          "type": "structure",
          "members": {
            "PlainText": {},
            "RichText": {}
          }
        }
      }
    },
    "S3n": {
      "type": "list",
      "member": {
        "shape": "S3o"
      }
    },
    "S3o": {
      "type": "structure",
      "members": {
        "NumericalDimensionField": {
          "type": "structure",
          "required": [
            "FieldId",
            "Column"
          ],
          "members": {
            "FieldId": {},
            "Column": {
              "shape": "S2g"
            },
            "HierarchyId": {},
            "FormatConfiguration": {
              "shape": "S3s"
            }
          }
        },
        "CategoricalDimensionField": {
          "type": "structure",
          "required": [
            "FieldId",
            "Column"
          ],
          "members": {
            "FieldId": {},
            "Column": {
              "shape": "S2g"
            },
            "HierarchyId": {},
            "FormatConfiguration": {
              "shape": "S4b"
            }
          }
        },
        "DateDimensionField": {
          "type": "structure",
          "required": [
            "FieldId",
            "Column"
          ],
          "members": {
            "FieldId": {},
            "Column": {
              "shape": "S2g"
            },
            "DateGranularity": {},
            "HierarchyId": {},
            "FormatConfiguration": {
              "shape": "S4e"
            }
          }
        }
      }
    },
    "S3s": {
      "type": "structure",
      "members": {
        "FormatConfiguration": {
          "shape": "S3t"
        }
      }
    },
    "S3t": {
      "type": "structure",
      "members": {
        "NumberDisplayFormatConfiguration": {
          "shape": "S3u"
        },
        "CurrencyDisplayFormatConfiguration": {
          "type": "structure",
          "members": {
            "Prefix": {
              "shape": "S3v"
            },
            "Suffix": {
              "shape": "S3w"
            },
            "SeparatorConfiguration": {
              "shape": "S3x"
            },
            "Symbol": {},
            "DecimalPlacesConfiguration": {
              "shape": "S40"
            },
            "NumberScale": {},
            "NegativeValueConfiguration": {
              "shape": "S43"
            },
            "NullValueFormatConfiguration": {
              "shape": "S45"
            }
          }
        },
        "PercentageDisplayFormatConfiguration": {
          "shape": "S49"
        }
      }
    },
    "S3u": {
      "type": "structure",
      "members": {
        "Prefix": {
          "shape": "S3v"
        },
        "Suffix": {
          "shape": "S3w"
        },
        "SeparatorConfiguration": {
          "shape": "S3x"
        },
        "DecimalPlacesConfiguration": {
          "shape": "S40"
        },
        "NumberScale": {},
        "NegativeValueConfiguration": {
          "shape": "S43"
        },
        "NullValueFormatConfiguration": {
          "shape": "S45"
        }
      }
    },
    "S3v": {
      "type": "string",
      "sensitive": true
    },
    "S3w": {
      "type": "string",
      "sensitive": true
    },
    "S3x": {
      "type": "structure",
      "members": {
        "DecimalSeparator": {},
        "ThousandsSeparator": {
          "type": "structure",
          "members": {
            "Symbol": {},
            "Visibility": {}
          }
        }
      }
    },
    "S40": {
      "type": "structure",
      "required": [
        "DecimalPlaces"
      ],
      "members": {
        "DecimalPlaces": {
          "type": "long"
        }
      }
    },
    "S43": {
      "type": "structure",
      "required": [
        "DisplayMode"
      ],
      "members": {
        "DisplayMode": {}
      }
    },
    "S45": {
      "type": "structure",
      "required": [
        "NullString"
      ],
      "members": {
        "NullString": {
          "type": "string",
          "sensitive": true
        }
      }
    },
    "S49": {
      "type": "structure",
      "members": {
        "Prefix": {
          "shape": "S3v"
        },
        "Suffix": {
          "shape": "S3w"
        },
        "SeparatorConfiguration": {
          "shape": "S3x"
        },
        "DecimalPlacesConfiguration": {
          "shape": "S40"
        },
        "NegativeValueConfiguration": {
          "shape": "S43"
        },
        "NullValueFormatConfiguration": {
          "shape": "S45"
        }
      }
    },
    "S4b": {
      "type": "structure",
      "members": {
        "NullValueFormatConfiguration": {
          "shape": "S45"
        },
        "NumericFormatConfiguration": {
          "shape": "S3t"
        }
      }
    },
    "S4e": {
      "type": "structure",
      "members": {
        "DateTimeFormat": {},
        "NullValueFormatConfiguration": {
          "shape": "S45"
        },
        "NumericFormatConfiguration": {
          "shape": "S3t"
        }
      }
    },
    "S4f": {
      "type": "list",
      "member": {
        "shape": "S4g"
      }
    },
    "S4g": {
      "type": "structure",
      "members": {
        "NumericalMeasureField": {
          "type": "structure",
          "required": [
            "FieldId",
            "Column"
          ],
          "members": {
            "FieldId": {},
            "Column": {
              "shape": "S2g"
            },
            "AggregationFunction": {
              "shape": "S4i"
            },
            "FormatConfiguration": {
              "shape": "S3s"
            }
          }
        },
        "CategoricalMeasureField": {
          "type": "structure",
          "required": [
            "FieldId",
            "Column"
          ],
          "members": {
            "FieldId": {},
            "Column": {
              "shape": "S2g"
            },
            "AggregationFunction": {},
            "FormatConfiguration": {
              "shape": "S4b"
            }
          }
        },
        "DateMeasureField": {
          "type": "structure",
          "required": [
            "FieldId",
            "Column"
          ],
          "members": {
            "FieldId": {},
            "Column": {
              "shape": "S2g"
            },
            "AggregationFunction": {},
            "FormatConfiguration": {
              "shape": "S4e"
            }
          }
        },
        "CalculatedMeasureField": {
          "type": "structure",
          "required": [
            "FieldId",
            "Expression"
          ],
          "members": {
            "FieldId": {},
            "Expression": {
              "shape": "S4r"
            }
          }
        }
      }
    },
    "S4i": {
      "type": "structure",
      "members": {
        "SimpleNumericalAggregation": {},
        "PercentileAggregation": {
          "type": "structure",
          "members": {
            "PercentileValue": {
              "type": "double"
            }
          }
        }
      }
    },
    "S4r": {
      "type": "string",
      "sensitive": true
    },
    "S4v": {
      "type": "structure",
      "members": {
        "StringFormatConfiguration": {
          "shape": "S4b"
        },
        "NumberFormatConfiguration": {
          "shape": "S3s"
        },
        "DateTimeFormatConfiguration": {
          "shape": "S4e"
        }
      }
    },
    "S4y": {
      "type": "structure",
      "members": {
        "FieldSort": {
          "shape": "S4z"
        },
        "ColumnSort": {
          "shape": "S51"
        }
      }
    },
    "S4z": {
      "type": "structure",
      "required": [
        "FieldId",
        "Direction"
      ],
      "members": {
        "FieldId": {},
        "Direction": {}
      }
    },
    "S51": {
      "type": "structure",
      "required": [
        "SortBy",
        "Direction"
      ],
      "members": {
        "SortBy": {
          "shape": "S2g"
        },
        "Direction": {},
        "AggregationFunction": {
          "shape": "S52"
        }
      }
    },
    "S52": {
      "type": "structure",
      "members": {
        "NumericalAggregationFunction": {
          "shape": "S4i"
        },
        "CategoricalAggregationFunction": {},
        "DateAggregationFunction": {},
        "AttributeAggregationFunction": {
          "type": "structure",
          "members": {
            "SimpleAttributeAggregation": {},
            "ValueForMultipleValues": {}
          }
        }
      }
    },
    "S55": {
      "type": "structure",
      "required": [
        "PageSize",
        "PageNumber"
      ],
      "members": {
        "PageSize": {
          "type": "long"
        },
        "PageNumber": {
          "type": "long"
        }
      }
    },
    "S5a": {
      "type": "structure",
      "members": {
        "Visibility": {},
        "FontConfiguration": {
          "shape": "S1y"
        },
        "TextWrap": {},
        "HorizontalTextAlignment": {},
        "VerticalTextAlignment": {},
        "BackgroundColor": {},
        "Height": {
          "type": "integer"
        },
        "Border": {
          "type": "structure",
          "members": {
            "UniformBorder": {
              "shape": "S5g"
            },
            "SideSpecificBorder": {
              "type": "structure",
              "members": {
                "InnerVertical": {
                  "shape": "S5g"
                },
                "InnerHorizontal": {
                  "shape": "S5g"
                },
                "Left": {
                  "shape": "S5g"
                },
                "Right": {
                  "shape": "S5g"
                },
                "Top": {
                  "shape": "S5g"
                },
                "Bottom": {
                  "shape": "S5g"
                }
              }
            }
          }
        }
      }
    },
    "S5g": {
      "type": "structure",
      "members": {
        "Color": {},
        "Thickness": {
          "type": "integer"
        },
        "Style": {}
      }
    },
    "S5k": {
      "type": "structure",
      "members": {
        "Status": {},
        "RowAlternateColors": {
          "type": "list",
          "member": {}
        },
        "UsePrimaryBackgroundColor": {}
      }
    },
    "S5q": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "FieldId",
          "TotalAggregationFunction"
        ],
        "members": {
          "FieldId": {},
          "TotalAggregationFunction": {
            "type": "structure",
            "members": {
              "SimpleTotalAggregationFunction": {}
            }
          }
        }
      }
    },
    "S6g": {
      "type": "structure",
      "members": {
        "VisualMenuOption": {
          "shape": "S6h"
        },
        "ContextMenuOption": {
          "type": "structure",
          "members": {
            "AvailabilityStatus": {}
          }
        }
      }
    },
    "S6h": {
      "type": "structure",
      "members": {
        "AvailabilityStatus": {}
      }
    },
    "S6o": {
      "type": "structure",
      "members": {
        "BackgroundColor": {
          "shape": "S6p"
        },
        "TextColor": {
          "shape": "S6p"
        },
        "Icon": {
          "shape": "S6v"
        }
      }
    },
    "S6p": {
      "type": "structure",
      "members": {
        "Solid": {
          "type": "structure",
          "required": [
            "Expression"
          ],
          "members": {
            "Expression": {
              "shape": "S4r"
            },
            "Color": {}
          }
        },
        "Gradient": {
          "type": "structure",
          "required": [
            "Expression",
            "Color"
          ],
          "members": {
            "Expression": {
              "shape": "S4r"
            },
            "Color": {
              "type": "structure",
              "members": {
                "Stops": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "GradientOffset"
                    ],
                    "members": {
                      "GradientOffset": {
                        "type": "double"
                      },
                      "DataValue": {
                        "type": "double"
                      },
                      "Color": {}
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "S6v": {
      "type": "structure",
      "members": {
        "IconSet": {
          "type": "structure",
          "required": [
            "Expression"
          ],
          "members": {
            "Expression": {
              "shape": "S4r"
            },
            "IconSetType": {}
          }
        },
        "CustomCondition": {
          "type": "structure",
          "required": [
            "Expression",
            "IconOptions"
          ],
          "members": {
            "Expression": {
              "shape": "S4r"
            },
            "IconOptions": {
              "type": "structure",
              "members": {
                "Icon": {},
                "UnicodeIcon": {}
              }
            },
            "Color": {},
            "DisplayConfiguration": {
              "type": "structure",
              "members": {
                "IconDisplayOption": {}
              }
            }
          }
        }
      }
    },
    "S75": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "CustomActionId",
          "Name",
          "Trigger",
          "ActionOperations"
        ],
        "members": {
          "CustomActionId": {},
          "Name": {},
          "Status": {},
          "Trigger": {},
          "ActionOperations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "FilterOperation": {
                  "type": "structure",
                  "required": [
                    "SelectedFieldsConfiguration",
                    "TargetVisualsConfiguration"
                  ],
                  "members": {
                    "SelectedFieldsConfiguration": {
                      "type": "structure",
                      "members": {
                        "SelectedFields": {
                          "type": "list",
                          "member": {}
                        },
                        "SelectedFieldOptions": {},
                        "SelectedColumns": {
                          "type": "list",
                          "member": {
                            "shape": "S2g"
                          }
                        }
                      }
                    },
                    "TargetVisualsConfiguration": {
                      "type": "structure",
                      "members": {
                        "SameSheetTargetVisualConfiguration": {
                          "type": "structure",
                          "members": {
                            "TargetVisuals": {
                              "type": "list",
                              "member": {}
                            },
                            "TargetVisualOptions": {}
                          }
                        }
                      }
                    }
                  }
                },
                "NavigationOperation": {
                  "type": "structure",
                  "members": {
                    "LocalNavigationConfiguration": {
                      "type": "structure",
                      "required": [
                        "TargetSheetId"
                      ],
                      "members": {
                        "TargetSheetId": {}
                      }
                    }
                  }
                },
                "URLOperation": {
                  "type": "structure",
                  "required": [
                    "URLTemplate",
                    "URLTarget"
                  ],
                  "members": {
                    "URLTemplate": {},
                    "URLTarget": {}
                  }
                },
                "SetParametersOperation": {
                  "type": "structure",
                  "required": [
                    "ParameterValueConfigurations"
                  ],
                  "members": {
                    "ParameterValueConfigurations": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "required": [
                          "DestinationParameterName",
                          "Value"
                        ],
                        "members": {
                          "DestinationParameterName": {},
                          "Value": {
                            "type": "structure",
                            "members": {
                              "CustomValuesConfiguration": {
                                "type": "structure",
                                "required": [
                                  "CustomValues"
                                ],
                                "members": {
                                  "IncludeNullValue": {
                                    "type": "boolean"
                                  },
                                  "CustomValues": {
                                    "type": "structure",
                                    "members": {
                                      "StringValues": {
                                        "shape": "S7v"
                                      },
                                      "IntegerValues": {
                                        "shape": "S7x"
                                      },
                                      "DecimalValues": {
                                        "shape": "S7z"
                                      },
                                      "DateTimeValues": {
                                        "shape": "S81"
                                      }
                                    }
                                  }
                                }
                              },
                              "SelectAllValueOptions": {},
                              "SourceParameterName": {},
                              "SourceField": {},
                              "SourceColumn": {
                                "shape": "S2g"
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "S7v": {
      "type": "list",
      "member": {
        "type": "string",
        "sensitive": true
      }
    },
    "S7x": {
      "type": "list",
      "member": {
        "type": "long",
        "sensitive": true
      }
    },
    "S7z": {
      "type": "list",
      "member": {
        "type": "double",
        "sensitive": true
      }
    },
    "S81": {
      "type": "list",
      "member": {
        "shape": "S18"
      }
    },
    "S87": {
      "type": "list",
      "member": {
        "shape": "S3o"
      }
    },
    "S8e": {
      "type": "list",
      "member": {
        "shape": "S8f"
      }
    },
    "S8f": {
      "type": "structure",
      "members": {
        "FieldId": {},
        "FieldValue": {
          "shape": "S8g"
        },
        "DataPathType": {
          "type": "structure",
          "members": {
            "PivotTableDataPathType": {}
          }
        }
      }
    },
    "S8g": {
      "type": "string",
      "sensitive": true
    },
    "S8p": {
      "type": "structure",
      "members": {
        "TotalsVisibility": {},
        "CustomLabel": {},
        "FieldLevel": {},
        "FieldLevelOptions": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "FieldId": {}
            }
          }
        },
        "TotalCellStyle": {
          "shape": "S5a"
        },
        "ValueCellStyle": {
          "shape": "S5a"
        },
        "MetricHeaderCellStyle": {
          "shape": "S5a"
        },
        "StyleTargets": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "CellType"
            ],
            "members": {
              "CellType": {}
            }
          }
        }
      }
    },
    "S8w": {
      "type": "structure",
      "members": {
        "TotalsVisibility": {},
        "Placement": {},
        "ScrollStatus": {},
        "CustomLabel": {},
        "TotalCellStyle": {
          "shape": "S5a"
        },
        "ValueCellStyle": {
          "shape": "S5a"
        },
        "MetricHeaderCellStyle": {
          "shape": "S5a"
        },
        "TotalAggregationOptions": {
          "shape": "S5q"
        }
      }
    },
    "S9b": {
      "type": "structure",
      "members": {
        "Role": {}
      }
    },
    "S9i": {
      "type": "list",
      "member": {
        "shape": "S3o"
      }
    },
    "S9k": {
      "type": "list",
      "member": {
        "shape": "S4y"
      }
    },
    "S9l": {
      "type": "structure",
      "members": {
        "ItemsLimit": {
          "type": "long"
        },
        "OtherCategories": {}
      }
    },
    "S9p": {
      "type": "structure",
      "members": {
        "ChartColor": {},
        "ColorMap": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Element",
              "Color"
            ],
            "members": {
              "Element": {
                "shape": "S8f"
              },
              "Color": {},
              "TimeGranularity": {}
            }
          }
        }
      }
    },
    "S9s": {
      "type": "structure",
      "members": {
        "MaxVisibleRows": {
          "type": "long"
        },
        "MaxVisibleColumns": {
          "type": "long"
        },
        "PanelConfiguration": {
          "type": "structure",
          "members": {
            "Title": {
              "type": "structure",
              "members": {
                "Visibility": {},
                "FontConfiguration": {
                  "shape": "S1y"
                },
                "HorizontalTextAlignment": {}
              }
            },
            "BorderVisibility": {},
            "BorderThickness": {},
            "BorderStyle": {},
            "BorderColor": {},
            "GutterVisibility": {},
            "GutterSpacing": {},
            "BackgroundVisibility": {},
            "BackgroundColor": {}
          }
        },
        "XAxis": {
          "shape": "S9z"
        },
        "YAxis": {
          "shape": "S9z"
        }
      }
    },
    "S9z": {
      "type": "structure",
      "members": {
        "Scale": {},
        "Placement": {}
      }
    },
    "Sa2": {
      "type": "structure",
      "members": {
        "TickLabelOptions": {
          "type": "structure",
          "members": {
            "LabelOptions": {
              "shape": "S1w"
            },
            "RotationAngle": {
              "type": "double"
            }
          }
        },
        "AxisLineVisibility": {},
        "GridLineVisibility": {},
        "DataOptions": {
          "type": "structure",
          "members": {
            "NumericAxisOptions": {
              "type": "structure",
              "members": {
                "Scale": {
                  "type": "structure",
                  "members": {
                    "Linear": {
                      "type": "structure",
                      "members": {
                        "StepCount": {
                          "type": "integer"
                        },
                        "StepSize": {
                          "type": "double"
                        }
                      }
                    },
                    "Logarithmic": {
                      "type": "structure",
                      "members": {
                        "Base": {
                          "type": "double"
                        }
                      }
                    }
                  }
                },
                "Range": {
                  "type": "structure",
                  "members": {
                    "MinMax": {
                      "type": "structure",
                      "members": {
                        "Minimum": {
                          "type": "double"
                        },
                        "Maximum": {
                          "type": "double"
                        }
                      }
                    },
                    "DataDriven": {
                      "type": "structure",
                      "members": {}
                    }
                  }
                }
              }
            },
            "DateAxisOptions": {
              "type": "structure",
              "members": {
                "MissingDateVisibility": {}
              }
            }
          }
        },
        "ScrollbarOptions": {
          "type": "structure",
          "members": {
            "Visibility": {},
            "VisibleRange": {
              "type": "structure",
              "members": {
                "PercentRange": {
                  "type": "structure",
                  "members": {
                    "From": {
                      "type": "double"
                    },
                    "To": {
                      "type": "double"
                    }
                  }
                }
              }
            }
          }
        },
        "AxisOffset": {}
      }
    },
    "Sai": {
      "type": "structure",
      "members": {
        "Visibility": {},
        "SortIconVisibility": {},
        "AxisLabelOptions": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "FontConfiguration": {
                "shape": "S1y"
              },
              "CustomLabel": {},
              "ApplyTo": {
                "type": "structure",
                "required": [
                  "FieldId",
                  "Column"
                ],
                "members": {
                  "FieldId": {},
                  "Column": {
                    "shape": "S2g"
                  }
                }
              }
            }
          }
        }
      }
    },
    "Sam": {
      "type": "structure",
      "members": {
        "Visibility": {},
        "Title": {
          "shape": "S1w"
        },
        "Position": {},
        "Width": {},
        "Height": {}
      }
    },
    "Sao": {
      "type": "structure",
      "members": {
        "Visibility": {},
        "CategoryLabelVisibility": {},
        "MeasureLabelVisibility": {},
        "DataLabelTypes": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "FieldLabelType": {
                "type": "structure",
                "members": {
                  "FieldId": {},
                  "Visibility": {}
                }
              },
              "DataPathLabelType": {
                "type": "structure",
                "members": {
                  "FieldId": {},
                  "FieldValue": {
                    "shape": "S8g"
                  },
                  "Visibility": {}
                }
              },
              "RangeEndsLabelType": {
                "type": "structure",
                "members": {
                  "Visibility": {}
                }
              },
              "MinimumLabelType": {
                "type": "structure",
                "members": {
                  "Visibility": {}
                }
              },
              "MaximumLabelType": {
                "type": "structure",
                "members": {
                  "Visibility": {}
                }
              }
            }
          }
        },
        "Position": {},
        "LabelContent": {},
        "LabelFontConfiguration": {
          "shape": "S1y"
        },
        "LabelColor": {},
        "Overlap": {},
        "TotalsVisibility": {}
      }
    },
    "Saz": {
      "type": "structure",
      "members": {
        "TooltipVisibility": {},
        "SelectedTooltipType": {},
        "FieldBasedTooltip": {
          "type": "structure",
          "members": {
            "AggregationVisibility": {},
            "TooltipTitleType": {},
            "TooltipFields": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "FieldTooltipItem": {
                    "type": "structure",
                    "required": [
                      "FieldId"
                    ],
                    "members": {
                      "FieldId": {},
                      "Label": {},
                      "Visibility": {},
                      "TooltipTarget": {}
                    }
                  },
                  "ColumnTooltipItem": {
                    "type": "structure",
                    "required": [
                      "Column"
                    ],
                    "members": {
                      "Column": {
                        "shape": "S2g"
                      },
                      "Label": {},
                      "Visibility": {},
                      "Aggregation": {
                        "shape": "S52"
                      },
                      "TooltipTarget": {}
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "Sb8": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "DataConfiguration"
        ],
        "members": {
          "Status": {},
          "DataConfiguration": {
            "type": "structure",
            "members": {
              "StaticConfiguration": {
                "type": "structure",
                "required": [
                  "Value"
                ],
                "members": {
                  "Value": {
                    "shape": "S14"
                  }
                }
              },
              "DynamicConfiguration": {
                "type": "structure",
                "required": [
                  "Column",
                  "Calculation"
                ],
                "members": {
                  "Column": {
                    "shape": "S2g"
                  },
                  "MeasureAggregationFunction": {
                    "shape": "S52"
                  },
                  "Calculation": {
                    "shape": "S4i"
                  }
                }
              },
              "AxisBinding": {},
              "SeriesType": {}
            }
          },
          "StyleConfiguration": {
            "type": "structure",
            "members": {
              "Pattern": {},
              "Color": {}
            }
          },
          "LabelConfiguration": {
            "type": "structure",
            "members": {
              "ValueLabelConfiguration": {
                "type": "structure",
                "members": {
                  "RelativePosition": {},
                  "FormatConfiguration": {
                    "shape": "S3t"
                  }
                }
              },
              "CustomLabelConfiguration": {
                "type": "structure",
                "required": [
                  "CustomLabel"
                ],
                "members": {
                  "CustomLabel": {}
                }
              },
              "FontConfiguration": {
                "shape": "S1y"
              },
              "FontColor": {},
              "HorizontalPosition": {},
              "VerticalPosition": {}
            }
          }
        }
      }
    },
    "Sbn": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "MeasureFieldId",
          "ContributorDimensions"
        ],
        "members": {
          "MeasureFieldId": {},
          "ContributorDimensions": {
            "type": "list",
            "member": {
              "shape": "S2g"
            }
          }
        }
      }
    },
    "Sbq": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ExplicitHierarchy": {
            "type": "structure",
            "required": [
              "HierarchyId",
              "Columns"
            ],
            "members": {
              "HierarchyId": {},
              "Columns": {
                "type": "list",
                "member": {
                  "shape": "S2g"
                }
              },
              "DrillDownFilters": {
                "shape": "Sbu"
              }
            }
          },
          "DateTimeHierarchy": {
            "type": "structure",
            "required": [
              "HierarchyId"
            ],
            "members": {
              "HierarchyId": {},
              "DrillDownFilters": {
                "shape": "Sbu"
              }
            }
          },
          "PredefinedHierarchy": {
            "type": "structure",
            "required": [
              "HierarchyId",
              "Columns"
            ],
            "members": {
              "HierarchyId": {},
              "Columns": {
                "type": "list",
                "member": {
                  "shape": "S2g"
                }
              },
              "DrillDownFilters": {
                "shape": "Sbu"
              }
            }
          }
        }
      }
    },
    "Sbu": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "NumericEqualityFilter": {
            "type": "structure",
            "required": [
              "Column",
              "Value"
            ],
            "members": {
              "Column": {
                "shape": "S2g"
              },
              "Value": {
                "type": "double"
              }
            }
          },
          "CategoryFilter": {
            "type": "structure",
            "required": [
              "Column",
              "CategoryValues"
            ],
            "members": {
              "Column": {
                "shape": "S2g"
              },
              "CategoryValues": {
                "shape": "Sby"
              }
            }
          },
          "TimeRangeFilter": {
            "type": "structure",
            "required": [
              "Column",
              "RangeMinimum",
              "RangeMaximum",
              "TimeGranularity"
            ],
            "members": {
              "Column": {
                "shape": "S2g"
              },
              "RangeMinimum": {
                "type": "timestamp"
              },
              "RangeMaximum": {
                "type": "timestamp"
              },
              "TimeGranularity": {}
            }
          }
        }
      }
    },
    "Sby": {
      "type": "list",
      "member": {}
    },
    "Scd": {
      "type": "structure",
      "members": {
        "ComparisonMethod": {},
        "ComparisonFormat": {
          "type": "structure",
          "members": {
            "NumberDisplayFormatConfiguration": {
              "shape": "S3u"
            },
            "PercentageDisplayFormatConfiguration": {
              "shape": "S49"
            }
          }
        }
      }
    },
    "Sdv": {
      "type": "structure",
      "members": {
        "AxisOptions": {
          "shape": "Sa2"
        },
        "MissingDataConfigurations": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "TreatmentOption": {}
            }
          }
        }
      }
    },
    "Sdz": {
      "type": "structure",
      "members": {
        "YAxisOptions": {
          "type": "structure",
          "required": [
            "YAxis"
          ],
          "members": {
            "YAxis": {}
          }
        }
      }
    },
    "Se3": {
      "type": "structure",
      "members": {
        "LineVisibility": {},
        "LineInterpolation": {},
        "LineStyle": {},
        "LineWidth": {}
      }
    },
    "Se6": {
      "type": "structure",
      "members": {
        "MarkerVisibility": {},
        "MarkerShape": {},
        "MarkerSize": {},
        "MarkerColor": {}
      }
    },
    "Seb": {
      "type": "structure",
      "members": {
        "LineStyleSettings": {
          "shape": "Se3"
        },
        "MarkerStyleSettings": {
          "shape": "Se6"
        }
      }
    },
    "Seh": {
      "type": "list",
      "member": {
        "shape": "S3o"
      }
    },
    "Sek": {
      "type": "structure",
      "required": [
        "Colors",
        "ColorFillType"
      ],
      "members": {
        "Colors": {
          "type": "list",
          "member": {
            "shape": "Sem"
          }
        },
        "ColorFillType": {},
        "NullValueColor": {
          "shape": "Sem"
        }
      }
    },
    "Sem": {
      "type": "structure",
      "members": {
        "Color": {},
        "DataValue": {
          "type": "double"
        }
      }
    },
    "Set": {
      "type": "list",
      "member": {
        "shape": "S4g"
      }
    },
    "Sez": {
      "type": "structure",
      "members": {
        "Bounds": {
          "type": "structure",
          "required": [
            "North",
            "South",
            "West",
            "East"
          ],
          "members": {
            "North": {
              "type": "double"
            },
            "South": {
              "type": "double"
            },
            "West": {
              "type": "double"
            },
            "East": {
              "type": "double"
            }
          }
        },
        "MapZoomMode": {}
      }
    },
    "Sf4": {
      "type": "structure",
      "members": {
        "BaseMapStyle": {}
      }
    },
    "Sj0": {
      "type": "structure",
      "required": [
        "Elements"
      ],
      "members": {
        "Elements": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "ElementId",
              "ElementType",
              "ColumnSpan",
              "RowSpan"
            ],
            "members": {
              "ElementId": {},
              "ElementType": {},
              "ColumnIndex": {
                "type": "integer"
              },
              "ColumnSpan": {
                "type": "integer"
              },
              "RowIndex": {
                "type": "integer"
              },
              "RowSpan": {
                "type": "integer"
              }
            }
          }
        },
        "CanvasSizeOptions": {
          "shape": "Sj8"
        }
      }
    },
    "Sj8": {
      "type": "structure",
      "members": {
        "ScreenCanvasSizeOptions": {
          "type": "structure",
          "required": [
            "ResizeOption"
          ],
          "members": {
            "ResizeOption": {},
            "OptimizedViewPortWidth": {}
          }
        }
      }
    },
    "Sjc": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "ElementId",
          "ElementType",
          "XAxisLocation",
          "YAxisLocation",
          "Width",
          "Height"
        ],
        "members": {
          "ElementId": {},
          "ElementType": {},
          "XAxisLocation": {},
          "YAxisLocation": {},
          "Width": {},
          "Height": {},
          "Visibility": {},
          "RenderingRules": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Expression",
                "ConfigurationOverrides"
              ],
              "members": {
                "Expression": {
                  "shape": "S4r"
                },
                "ConfigurationOverrides": {
                  "type": "structure",
                  "members": {
                    "Visibility": {}
                  }
                }
              }
            }
          },
          "BorderStyle": {
            "shape": "Sji"
          },
          "SelectedBorderStyle": {
            "shape": "Sji"
          },
          "BackgroundStyle": {
            "type": "structure",
            "members": {
              "Visibility": {},
              "Color": {}
            }
          },
          "LoadingAnimation": {
            "type": "structure",
            "members": {
              "Visibility": {}
            }
          }
        }
      }
    },
    "Sji": {
      "type": "structure",
      "members": {
        "Visibility": {},
        "Color": {}
      }
    },
    "Sjl": {
      "type": "structure",
      "members": {
        "ScreenCanvasSizeOptions": {
          "type": "structure",
          "required": [
            "OptimizedViewPortWidth"
          ],
          "members": {
            "OptimizedViewPortWidth": {}
          }
        }
      }
    },
    "Sjo": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "SectionId",
          "Layout"
        ],
        "members": {
          "SectionId": {},
          "Layout": {
            "shape": "Sjq"
          },
          "Style": {
            "shape": "Sjs"
          }
        }
      }
    },
    "Sjq": {
      "type": "structure",
      "required": [
        "FreeFormLayout"
      ],
      "members": {
        "FreeFormLayout": {
          "type": "structure",
          "required": [
            "Elements"
          ],
          "members": {
            "Elements": {
              "shape": "Sjc"
            }
          }
        }
      }
    },
    "Sjs": {
      "type": "structure",
      "members": {
        "Height": {},
        "Padding": {
          "shape": "Sjt"
        }
      }
    },
    "Sjt": {
      "type": "structure",
      "members": {
        "Top": {},
        "Bottom": {},
        "Left": {},
        "Right": {}
      }
    },
    "Sk1": {
      "type": "structure",
      "members": {
        "PaperCanvasSizeOptions": {
          "type": "structure",
          "members": {
            "PaperSize": {},
            "PaperOrientation": {},
            "PaperMargin": {
              "shape": "Sjt"
            }
          }
        }
      }
    },
    "Sk9": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "DataSetIdentifier",
          "Name",
          "Expression"
        ],
        "members": {
          "DataSetIdentifier": {},
          "Name": {},
          "Expression": {
            "type": "string",
            "sensitive": true
          }
        }
      }
    },
    "Skc": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "StringParameterDeclaration": {
            "type": "structure",
            "required": [
              "ParameterValueType",
              "Name"
            ],
            "members": {
              "ParameterValueType": {},
              "Name": {},
              "DefaultValues": {
                "type": "structure",
                "members": {
                  "DynamicValue": {
                    "shape": "Skh"
                  },
                  "StaticValues": {
                    "shape": "S7v"
                  }
                }
              },
              "ValueWhenUnset": {
                "type": "structure",
                "members": {
                  "ValueWhenUnsetOption": {},
                  "CustomValue": {
                    "shape": "Sw"
                  }
                }
              },
              "MappedDataSetParameters": {
                "shape": "Skk"
              }
            }
          },
          "DecimalParameterDeclaration": {
            "type": "structure",
            "required": [
              "ParameterValueType",
              "Name"
            ],
            "members": {
              "ParameterValueType": {},
              "Name": {},
              "DefaultValues": {
                "type": "structure",
                "members": {
                  "DynamicValue": {
                    "shape": "Skh"
                  },
                  "StaticValues": {
                    "shape": "S7z"
                  }
                }
              },
              "ValueWhenUnset": {
                "type": "structure",
                "members": {
                  "ValueWhenUnsetOption": {},
                  "CustomValue": {
                    "shape": "S14"
                  }
                }
              },
              "MappedDataSetParameters": {
                "shape": "Skk"
              }
            }
          },
          "IntegerParameterDeclaration": {
            "type": "structure",
            "required": [
              "ParameterValueType",
              "Name"
            ],
            "members": {
              "ParameterValueType": {},
              "Name": {},
              "DefaultValues": {
                "type": "structure",
                "members": {
                  "DynamicValue": {
                    "shape": "Skh"
                  },
                  "StaticValues": {
                    "shape": "S7x"
                  }
                }
              },
              "ValueWhenUnset": {
                "type": "structure",
                "members": {
                  "ValueWhenUnsetOption": {},
                  "CustomValue": {
                    "shape": "S10"
                  }
                }
              },
              "MappedDataSetParameters": {
                "shape": "Skk"
              }
            }
          },
          "DateTimeParameterDeclaration": {
            "type": "structure",
            "required": [
              "Name"
            ],
            "members": {
              "Name": {},
              "DefaultValues": {
                "type": "structure",
                "members": {
                  "DynamicValue": {
                    "shape": "Skh"
                  },
                  "StaticValues": {
                    "shape": "S81"
                  },
                  "RollingDate": {
                    "shape": "Sku"
                  }
                }
              },
              "TimeGranularity": {},
              "ValueWhenUnset": {
                "type": "structure",
                "members": {
                  "ValueWhenUnsetOption": {},
                  "CustomValue": {
                    "shape": "S18"
                  }
                }
              },
              "MappedDataSetParameters": {
                "shape": "Skk"
              }
            }
          }
        }
      }
    },
    "Skh": {
      "type": "structure",
      "required": [
        "DefaultValueColumn"
      ],
      "members": {
        "UserNameColumn": {
          "shape": "S2g"
        },
        "GroupNameColumn": {
          "shape": "S2g"
        },
        "DefaultValueColumn": {
          "shape": "S2g"
        }
      }
    },
    "Skk": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "DataSetIdentifier",
          "DataSetParameterName"
        ],
        "members": {
          "DataSetIdentifier": {},
          "DataSetParameterName": {}
        }
      }
    },
    "Sku": {
      "type": "structure",
      "required": [
        "Expression"
      ],
      "members": {
        "DataSetIdentifier": {},
        "Expression": {
          "shape": "S4r"
        }
      }
    },
    "Skw": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "FilterGroupId",
          "Filters",
          "ScopeConfiguration",
          "CrossDataset"
        ],
        "members": {
          "FilterGroupId": {},
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "CategoryFilter": {
                  "type": "structure",
                  "required": [
                    "FilterId",
                    "Column",
                    "Configuration"
                  ],
                  "members": {
                    "FilterId": {},
                    "Column": {
                      "shape": "S2g"
                    },
                    "Configuration": {
                      "type": "structure",
                      "members": {
                        "FilterListConfiguration": {
                          "type": "structure",
                          "required": [
                            "MatchOperator"
                          ],
                          "members": {
                            "MatchOperator": {},
                            "CategoryValues": {
                              "shape": "Sby"
                            },
                            "SelectAllOptions": {},
                            "NullOption": {}
                          }
                        },
                        "CustomFilterListConfiguration": {
                          "type": "structure",
                          "required": [
                            "MatchOperator",
                            "NullOption"
                          ],
                          "members": {
                            "MatchOperator": {},
                            "CategoryValues": {
                              "shape": "Sby"
                            },
                            "SelectAllOptions": {},
                            "NullOption": {}
                          }
                        },
                        "CustomFilterConfiguration": {
                          "type": "structure",
                          "required": [
                            "MatchOperator",
                            "NullOption"
                          ],
                          "members": {
                            "MatchOperator": {},
                            "CategoryValue": {},
                            "SelectAllOptions": {},
                            "ParameterName": {},
                            "NullOption": {}
                          }
                        }
                      }
                    }
                  }
                },
                "NumericRangeFilter": {
                  "type": "structure",
                  "required": [
                    "FilterId",
                    "Column",
                    "NullOption"
                  ],
                  "members": {
                    "FilterId": {},
                    "Column": {
                      "shape": "S2g"
                    },
                    "IncludeMinimum": {
                      "type": "boolean"
                    },
                    "IncludeMaximum": {
                      "type": "boolean"
                    },
                    "RangeMinimum": {
                      "shape": "Sl9"
                    },
                    "RangeMaximum": {
                      "shape": "Sl9"
                    },
                    "SelectAllOptions": {},
                    "AggregationFunction": {
                      "shape": "S52"
                    },
                    "NullOption": {}
                  }
                },
                "NumericEqualityFilter": {
                  "type": "structure",
                  "required": [
                    "FilterId",
                    "Column",
                    "MatchOperator",
                    "NullOption"
                  ],
                  "members": {
                    "FilterId": {},
                    "Column": {
                      "shape": "S2g"
                    },
                    "Value": {
                      "type": "double"
                    },
                    "SelectAllOptions": {},
                    "MatchOperator": {},
                    "AggregationFunction": {
                      "shape": "S52"
                    },
                    "ParameterName": {},
                    "NullOption": {}
                  }
                },
                "TimeEqualityFilter": {
                  "type": "structure",
                  "required": [
                    "FilterId",
                    "Column"
                  ],
                  "members": {
                    "FilterId": {},
                    "Column": {
                      "shape": "S2g"
                    },
                    "Value": {
                      "type": "timestamp"
                    },
                    "ParameterName": {},
                    "TimeGranularity": {},
                    "RollingDate": {
                      "shape": "Sku"
                    }
                  }
                },
                "TimeRangeFilter": {
                  "type": "structure",
                  "required": [
                    "FilterId",
                    "Column",
                    "NullOption"
                  ],
                  "members": {
                    "FilterId": {},
                    "Column": {
                      "shape": "S2g"
                    },
                    "IncludeMinimum": {
                      "type": "boolean"
                    },
                    "IncludeMaximum": {
                      "type": "boolean"
                    },
                    "RangeMinimumValue": {
                      "shape": "Slf"
                    },
                    "RangeMaximumValue": {
                      "shape": "Slf"
                    },
                    "NullOption": {},
                    "ExcludePeriodConfiguration": {
                      "shape": "Slg"
                    },
                    "TimeGranularity": {}
                  }
                },
                "RelativeDatesFilter": {
                  "type": "structure",
                  "required": [
                    "FilterId",
                    "Column",
                    "AnchorDateConfiguration",
                    "TimeGranularity",
                    "RelativeDateType",
                    "NullOption"
                  ],
                  "members": {
                    "FilterId": {},
                    "Column": {
                      "shape": "S2g"
                    },
                    "AnchorDateConfiguration": {
                      "type": "structure",
                      "members": {
                        "AnchorOption": {},
                        "ParameterName": {}
                      }
                    },
                    "MinimumGranularity": {},
                    "TimeGranularity": {},
                    "RelativeDateType": {},
                    "RelativeDateValue": {
                      "type": "integer"
                    },
                    "ParameterName": {},
                    "NullOption": {},
                    "ExcludePeriodConfiguration": {
                      "shape": "Slg"
                    }
                  }
                },
                "TopBottomFilter": {
                  "type": "structure",
                  "required": [
                    "FilterId",
                    "Column",
                    "AggregationSortConfigurations"
                  ],
                  "members": {
                    "FilterId": {},
                    "Column": {
                      "shape": "S2g"
                    },
                    "Limit": {
                      "type": "integer"
                    },
                    "AggregationSortConfigurations": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "required": [
                          "Column",
                          "SortDirection"
                        ],
                        "members": {
                          "Column": {
                            "shape": "S2g"
                          },
                          "SortDirection": {},
                          "AggregationFunction": {
                            "shape": "S52"
                          }
                        }
                      }
                    },
                    "TimeGranularity": {},
                    "ParameterName": {}
                  }
                }
              }
            }
          },
          "ScopeConfiguration": {
            "type": "structure",
            "members": {
              "SelectedSheets": {
                "type": "structure",
                "members": {
                  "SheetVisualScopingConfigurations": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "required": [
                        "SheetId",
                        "Scope"
                      ],
                      "members": {
                        "SheetId": {},
                        "Scope": {},
                        "VisualIds": {
                          "type": "list",
                          "member": {}
                        }
                      }
                    }
                  }
                }
              },
              "AllSheets": {
                "type": "structure",
                "members": {}
              }
            }
          },
          "Status": {},
          "CrossDataset": {}
        }
      }
    },
    "Sl9": {
      "type": "structure",
      "members": {
        "StaticValue": {
          "type": "double"
        },
        "Parameter": {}
      }
    },
    "Slf": {
      "type": "structure",
      "members": {
        "StaticValue": {
          "type": "timestamp"
        },
        "RollingDate": {
          "shape": "Sku"
        },
        "Parameter": {}
      }
    },
    "Slg": {
      "type": "structure",
      "required": [
        "Amount",
        "Granularity"
      ],
      "members": {
        "Amount": {
          "type": "integer"
        },
        "Granularity": {},
        "Status": {}
      }
    },
    "Slw": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Column"
        ],
        "members": {
          "Column": {
            "shape": "S2g"
          },
          "FormatConfiguration": {
            "shape": "S4v"
          },
          "Role": {},
          "ColorsConfiguration": {
            "type": "structure",
            "members": {
              "CustomColors": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "Color"
                  ],
                  "members": {
                    "FieldValue": {
                      "shape": "S8g"
                    },
                    "Color": {},
                    "SpecialValue": {}
                  }
                }
              }
            }
          }
        }
      }
    },
    "Sm3": {
      "type": "structure",
      "required": [
        "DefaultNewSheetConfiguration"
      ],
      "members": {
        "DefaultNewSheetConfiguration": {
          "type": "structure",
          "members": {
            "InteractiveLayoutConfiguration": {
              "type": "structure",
              "members": {
                "Grid": {
                  "type": "structure",
                  "required": [
                    "CanvasSizeOptions"
                  ],
                  "members": {
                    "CanvasSizeOptions": {
                      "shape": "Sj8"
                    }
                  }
                },
                "FreeForm": {
                  "type": "structure",
                  "required": [
                    "CanvasSizeOptions"
                  ],
                  "members": {
                    "CanvasSizeOptions": {
                      "shape": "Sjl"
                    }
                  }
                }
              }
            },
            "PaginatedLayoutConfiguration": {
              "type": "structure",
              "members": {
                "SectionBased": {
                  "type": "structure",
                  "required": [
                    "CanvasSizeOptions"
                  ],
                  "members": {
                    "CanvasSizeOptions": {
                      "shape": "Sk1"
                    }
                  }
                }
              }
            },
            "SheetContentType": {}
          }
        }
      }
    },
    "Sma": {
      "type": "structure",
      "members": {
        "Timezone": {},
        "WeekStart": {}
      }
    },
    "Smc": {
      "type": "structure",
      "required": [
        "Mode"
      ],
      "members": {
        "Mode": {}
      }
    },
    "Sme": {
      "type": "list",
      "member": {}
    },
    "Smj": {
      "type": "structure",
      "members": {
        "SourceTemplate": {
          "type": "structure",
          "required": [
            "DataSetReferences",
            "Arn"
          ],
          "members": {
            "DataSetReferences": {
              "shape": "S1f"
            },
            "Arn": {}
          }
        }
      }
    },
    "Smm": {
      "type": "structure",
      "members": {
        "AdHocFilteringOption": {
          "type": "structure",
          "members": {
            "AvailabilityStatus": {}
          }
        },
        "ExportToCSVOption": {
          "type": "structure",
          "members": {
            "AvailabilityStatus": {}
          }
        },
        "SheetControlsOption": {
          "type": "structure",
          "members": {
            "VisibilityState": {}
          }
        },
        "VisualPublishOptions": {
          "deprecated": true,
          "deprecatedMessage": "VisualPublishOptions property will reach its end of standard support in a future release. To perform this action, use ExportWithHiddenFields.",
          "type": "structure",
          "members": {
            "ExportHiddenFieldsOption": {
              "type": "structure",
              "members": {
                "AvailabilityStatus": {}
              }
            }
          }
        },
        "SheetLayoutElementMaximizationOption": {
          "type": "structure",
          "members": {
            "AvailabilityStatus": {}
          }
        },
        "VisualMenuOption": {
          "shape": "S6h"
        },
        "VisualAxisSortOption": {
          "type": "structure",
          "members": {
            "AvailabilityStatus": {}
          }
        },
        "ExportWithHiddenFieldsOption": {
          "type": "structure",
          "members": {
            "AvailabilityStatus": {}
          }
        },
        "DataPointDrillUpDownOption": {
          "type": "structure",
          "members": {
            "AvailabilityStatus": {}
          }
        },
        "DataPointMenuLabelOption": {
          "type": "structure",
          "members": {
            "AvailabilityStatus": {}
          }
        },
        "DataPointTooltipOption": {
          "type": "structure",
          "members": {
            "AvailabilityStatus": {}
          }
        }
      }
    },
    "Smz": {
      "type": "structure",
      "required": [
        "DataSetIdentifierDeclarations"
      ],
      "members": {
        "DataSetIdentifierDeclarations": {
          "shape": "S1i"
        },
        "Sheets": {
          "shape": "S1l"
        },
        "CalculatedFields": {
          "shape": "Sk9"
        },
        "ParameterDeclarations": {
          "shape": "Skc"
        },
        "FilterGroups": {
          "shape": "Skw"
        },
        "ColumnConfigurations": {
          "shape": "Slw"
        },
        "AnalysisDefaults": {
          "shape": "Sm3"
        },
        "Options": {
          "shape": "Sma"
        }
      }
    },
    "Sn0": {
      "type": "structure",
      "members": {
        "Permissions": {
          "shape": "S19"
        }
      }
    },
    "Sn1": {
      "type": "list",
      "member": {}
    },
    "Sn7": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "RelationalTable": {
            "type": "structure",
            "required": [
              "DataSourceArn",
              "Name",
              "InputColumns"
            ],
            "members": {
              "DataSourceArn": {},
              "Catalog": {},
              "Schema": {},
              "Name": {},
              "InputColumns": {
                "shape": "Sne"
              }
            }
          },
          "CustomSql": {
            "type": "structure",
            "required": [
              "DataSourceArn",
              "Name",
              "SqlQuery"
            ],
            "members": {
              "DataSourceArn": {},
              "Name": {},
              "SqlQuery": {},
              "Columns": {
                "shape": "Sne"
              }
            }
          },
          "S3Source": {
            "type": "structure",
            "required": [
              "DataSourceArn",
              "InputColumns"
            ],
            "members": {
              "DataSourceArn": {},
              "UploadSettings": {
                "type": "structure",
                "members": {
                  "Format": {},
                  "StartFromRow": {
                    "type": "integer"
                  },
                  "ContainsHeader": {
                    "type": "boolean"
                  },
                  "TextQualifier": {},
                  "Delimiter": {}
                }
              },
              "InputColumns": {
                "shape": "Sne"
              }
            }
          }
        }
      }
    },
    "Sne": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name",
          "Type"
        ],
        "members": {
          "Name": {},
          "Type": {},
          "SubType": {}
        }
      }
    },
    "Snr": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "required": [
          "Alias",
          "Source"
        ],
        "members": {
          "Alias": {},
          "DataTransforms": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ProjectOperation": {
                  "type": "structure",
                  "required": [
                    "ProjectedColumns"
                  ],
                  "members": {
                    "ProjectedColumns": {
                      "type": "list",
                      "member": {}
                    }
                  }
                },
                "FilterOperation": {
                  "type": "structure",
                  "required": [
                    "ConditionExpression"
                  ],
                  "members": {
                    "ConditionExpression": {
                      "shape": "S4r"
                    }
                  }
                },
                "CreateColumnsOperation": {
                  "type": "structure",
                  "required": [
                    "Columns"
                  ],
                  "members": {
                    "Columns": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "required": [
                          "ColumnName",
                          "ColumnId",
                          "Expression"
                        ],
                        "members": {
                          "ColumnName": {},
                          "ColumnId": {},
                          "Expression": {
                            "shape": "S4r"
                          }
                        }
                      }
                    }
                  }
                },
                "RenameColumnOperation": {
                  "type": "structure",
                  "required": [
                    "ColumnName",
                    "NewColumnName"
                  ],
                  "members": {
                    "ColumnName": {},
                    "NewColumnName": {}
                  }
                },
                "CastColumnTypeOperation": {
                  "type": "structure",
                  "required": [
                    "ColumnName",
                    "NewColumnType"
                  ],
                  "members": {
                    "ColumnName": {},
                    "NewColumnType": {},
                    "SubType": {},
                    "Format": {}
                  }
                },
                "TagColumnOperation": {
                  "type": "structure",
                  "required": [
                    "ColumnName",
                    "Tags"
                  ],
                  "members": {
                    "ColumnName": {},
                    "Tags": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "members": {
                          "ColumnGeographicRole": {},
                          "ColumnDescription": {
                            "type": "structure",
                            "members": {
                              "Text": {}
                            }
                          }
                        }
                      }
                    }
                  }
                },
                "UntagColumnOperation": {
                  "type": "structure",
                  "required": [
                    "ColumnName",
                    "TagNames"
                  ],
                  "members": {
                    "ColumnName": {},
                    "TagNames": {
                      "type": "list",
                      "member": {}
                    }
                  }
                },
                "OverrideDatasetParameterOperation": {
                  "type": "structure",
                  "required": [
                    "ParameterName"
                  ],
                  "members": {
                    "ParameterName": {},
                    "NewParameterName": {},
                    "NewDefaultValues": {
                      "type": "structure",
                      "members": {
                        "StringStaticValues": {
                          "shape": "Sok"
                        },
                        "DecimalStaticValues": {
                          "shape": "Som"
                        },
                        "DateTimeStaticValues": {
                          "shape": "Soo"
                        },
                        "IntegerStaticValues": {
                          "shape": "Soq"
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          "Source": {
            "type": "structure",
            "members": {
              "JoinInstruction": {
                "type": "structure",
                "required": [
                  "LeftOperand",
                  "RightOperand",
                  "Type",
                  "OnClause"
                ],
                "members": {
                  "LeftOperand": {},
                  "RightOperand": {},
                  "LeftJoinKeyProperties": {
                    "shape": "Sou"
                  },
                  "RightJoinKeyProperties": {
                    "shape": "Sou"
                  },
                  "Type": {},
                  "OnClause": {}
                }
              },
              "PhysicalTableId": {},
              "DataSetArn": {}
            }
          }
        }
      }
    },
    "Sok": {
      "type": "list",
      "member": {}
    },
    "Som": {
      "type": "list",
      "member": {
        "type": "double"
      }
    },
    "Soo": {
      "type": "list",
      "member": {
        "type": "timestamp"
      }
    },
    "Soq": {
      "type": "list",
      "member": {
        "type": "long"
      }
    },
    "Sou": {
      "type": "structure",
      "members": {
        "UniqueKey": {
          "type": "boolean"
        }
      }
    },
    "Soy": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "GeoSpatialColumnGroup": {
            "type": "structure",
            "required": [
              "Name",
              "Columns"
            ],
            "members": {
              "Name": {},
              "CountryCode": {},
              "Columns": {
                "type": "list",
                "member": {}
              }
            }
          }
        }
      }
    },
    "Sp4": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "description": {},
          "columns": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "Sp9": {
      "type": "structure",
      "required": [
        "Arn",
        "PermissionPolicy"
      ],
      "members": {
        "Namespace": {},
        "Arn": {},
        "PermissionPolicy": {},
        "FormatVersion": {},
        "Status": {}
      }
    },
    "Spd": {
      "type": "structure",
      "required": [
        "TagRules"
      ],
      "members": {
        "Status": {},
        "TagRules": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "TagKey",
              "ColumnName"
            ],
            "members": {
              "TagKey": {},
              "ColumnName": {},
              "TagMultiValueDelimiter": {},
              "MatchAllValue": {
                "shape": "Spi"
              }
            }
          }
        },
        "TagRuleConfigurations": {
          "type": "list",
          "member": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "Spi": {
      "type": "string",
      "sensitive": true
    },
    "Spl": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Principals": {
            "type": "list",
            "member": {}
          },
          "ColumnNames": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "Spp": {
      "type": "structure",
      "members": {
        "DisableUseAsDirectQuerySource": {
          "type": "boolean"
        },
        "DisableUseAsImportedSource": {
          "type": "boolean"
        }
      }
    },
    "Spq": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "StringDatasetParameter": {
            "type": "structure",
            "required": [
              "Id",
              "Name",
              "ValueType"
            ],
            "members": {
              "Id": {},
              "Name": {},
              "ValueType": {},
              "DefaultValues": {
                "type": "structure",
                "members": {
                  "StaticValues": {
                    "shape": "Sok"
                  }
                }
              }
            }
          },
          "DecimalDatasetParameter": {
            "type": "structure",
            "required": [
              "Id",
              "Name",
              "ValueType"
            ],
            "members": {
              "Id": {},
              "Name": {},
              "ValueType": {},
              "DefaultValues": {
                "type": "structure",
                "members": {
                  "StaticValues": {
                    "shape": "Som"
                  }
                }
              }
            }
          },
          "IntegerDatasetParameter": {
            "type": "structure",
            "required": [
              "Id",
              "Name",
              "ValueType"
            ],
            "members": {
              "Id": {},
              "Name": {},
              "ValueType": {},
              "DefaultValues": {
                "type": "structure",
                "members": {
                  "StaticValues": {
                    "shape": "Soq"
                  }
                }
              }
            }
          },
          "DateTimeDatasetParameter": {
            "type": "structure",
            "required": [
              "Id",
              "Name",
              "ValueType"
            ],
            "members": {
              "Id": {},
              "Name": {},
              "ValueType": {},
              "TimeGranularity": {},
              "DefaultValues": {
                "type": "structure",
                "members": {
                  "StaticValues": {
                    "shape": "Soo"
                  }
                }
              }
            }
          }
        }
      }
    },
    "Sq5": {
      "type": "structure",
      "members": {
        "AmazonElasticsearchParameters": {
          "type": "structure",
          "required": [
            "Domain"
          ],
          "members": {
            "Domain": {}
          }
        },
        "AthenaParameters": {
          "type": "structure",
          "members": {
            "WorkGroup": {},
            "RoleArn": {}
          }
        },
        "AuroraParameters": {
          "type": "structure",
          "required": [
            "Host",
            "Port",
            "Database"
          ],
          "members": {
            "Host": {},
            "Port": {
              "type": "integer"
            },
            "Database": {}
          }
        },
        "AuroraPostgreSqlParameters": {
          "type": "structure",
          "required": [
            "Host",
            "Port",
            "Database"
          ],
          "members": {
            "Host": {},
            "Port": {
              "type": "integer"
            },
            "Database": {}
          }
        },
        "AwsIotAnalyticsParameters": {
          "type": "structure",
          "required": [
            "DataSetName"
          ],
          "members": {
            "DataSetName": {}
          }
        },
        "JiraParameters": {
          "type": "structure",
          "required": [
            "SiteBaseUrl"
          ],
          "members": {
            "SiteBaseUrl": {}
          }
        },
        "MariaDbParameters": {
          "type": "structure",
          "required": [
            "Host",
            "Port",
            "Database"
          ],
          "members": {
            "Host": {},
            "Port": {
              "type": "integer"
            },
            "Database": {}
          }
        },
        "MySqlParameters": {
          "type": "structure",
          "required": [
            "Host",
            "Port",
            "Database"
          ],
          "members": {
            "Host": {},
            "Port": {
              "type": "integer"
            },
            "Database": {}
          }
        },
        "OracleParameters": {
          "type": "structure",
          "required": [
            "Host",
            "Port",
            "Database"
          ],
          "members": {
            "Host": {},
            "Port": {
              "type": "integer"
            },
            "Database": {}
          }
        },
        "PostgreSqlParameters": {
          "type": "structure",
          "required": [
            "Host",
            "Port",
            "Database"
          ],
          "members": {
            "Host": {},
            "Port": {
              "type": "integer"
            },
            "Database": {}
          }
        },
        "PrestoParameters": {
          "type": "structure",
          "required": [
            "Host",
            "Port",
            "Catalog"
          ],
          "members": {
            "Host": {},
            "Port": {
              "type": "integer"
            },
            "Catalog": {}
          }
        },
        "RdsParameters": {
          "type": "structure",
          "required": [
            "InstanceId",
            "Database"
          ],
          "members": {
            "InstanceId": {},
            "Database": {}
          }
        },
        "RedshiftParameters": {
          "type": "structure",
          "required": [
            "Database"
          ],
          "members": {
            "Host": {},
            "Port": {
              "type": "integer"
            },
            "Database": {},
            "ClusterId": {},
            "IAMParameters": {
              "type": "structure",
              "required": [
                "RoleArn",
                "DatabaseUser"
              ],
              "members": {
                "RoleArn": {},
                "DatabaseUser": {},
                "DatabaseGroups": {
                  "type": "list",
                  "member": {}
                },
                "AutoCreateDatabaseUser": {
                  "type": "boolean"
                }
              }
            },
            "IdentityCenterConfiguration": {
              "type": "structure",
              "members": {
                "EnableIdentityPropagation": {
                  "type": "boolean"
                }
              }
            }
          }
        },
        "S3Parameters": {
          "type": "structure",
          "required": [
            "ManifestFileLocation"
          ],
          "members": {
            "ManifestFileLocation": {
              "type": "structure",
              "required": [
                "Bucket",
                "Key"
              ],
              "members": {
                "Bucket": {},
                "Key": {}
              }
            },
            "RoleArn": {}
          }
        },
        "ServiceNowParameters": {
          "type": "structure",
          "required": [
            "SiteBaseUrl"
          ],
          "members": {
            "SiteBaseUrl": {}
          }
        },
        "SnowflakeParameters": {
          "type": "structure",
          "required": [
            "Host",
            "Database",
            "Warehouse"
          ],
          "members": {
            "Host": {},
            "Database": {},
            "Warehouse": {}
          }
        },
        "SparkParameters": {
          "type": "structure",
          "required": [
            "Host",
            "Port"
          ],
          "members": {
            "Host": {},
            "Port": {
              "type": "integer"
            }
          }
        },
        "SqlServerParameters": {
          "type": "structure",
          "required": [
            "Host",
            "Port",
            "Database"
          ],
          "members": {
            "Host": {},
            "Port": {
              "type": "integer"
            },
            "Database": {}
          }
        },
        "TeradataParameters": {
          "type": "structure",
          "required": [
            "Host",
            "Port",
            "Database"
          ],
          "members": {
            "Host": {},
            "Port": {
              "type": "integer"
            },
            "Database": {}
          }
        },
        "TwitterParameters": {
          "type": "structure",
          "required": [
            "Query",
            "MaxRows"
          ],
          "members": {
            "Query": {},
            "MaxRows": {
              "type": "integer"
            }
          }
        },
        "AmazonOpenSearchParameters": {
          "type": "structure",
          "required": [
            "Domain"
          ],
          "members": {
            "Domain": {}
          }
        },
        "ExasolParameters": {
          "type": "structure",
          "required": [
            "Host",
            "Port"
          ],
          "members": {
            "Host": {},
            "Port": {
              "type": "integer"
            }
          }
        },
        "DatabricksParameters": {
          "type": "structure",
          "required": [
            "Host",
            "Port",
            "SqlEndpointPath"
          ],
          "members": {
            "Host": {},
            "Port": {
              "type": "integer"
            },
            "SqlEndpointPath": {}
          }
        },
        "StarburstParameters": {
          "type": "structure",
          "required": [
            "Host",
            "Port",
            "Catalog"
          ],
          "members": {
            "Host": {},
            "Port": {
              "type": "integer"
            },
            "Catalog": {},
            "ProductType": {}
          }
        },
        "TrinoParameters": {
          "type": "structure",
          "required": [
            "Host",
            "Port",
            "Catalog"
          ],
          "members": {
            "Host": {},
            "Port": {
              "type": "integer"
            },
            "Catalog": {}
          }
        },
        "BigQueryParameters": {
          "type": "structure",
          "required": [
            "ProjectId"
          ],
          "members": {
            "ProjectId": {},
            "DataSetRegion": {}
          }
        }
      }
    },
    "Srm": {
      "type": "structure",
      "members": {
        "CredentialPair": {
          "type": "structure",
          "required": [
            "Username",
            "Password"
          ],
          "members": {
            "Username": {},
            "Password": {},
            "AlternateDataSourceParameters": {
              "shape": "Srq"
            }
          }
        },
        "CopySourceArn": {},
        "SecretArn": {}
      },
      "sensitive": true
    },
    "Srq": {
      "type": "list",
      "member": {
        "shape": "Sq5"
      }
    },
    "Srt": {
      "type": "structure",
      "required": [
        "VpcConnectionArn"
      ],
      "members": {
        "VpcConnectionArn": {}
      }
    },
    "Sru": {
      "type": "structure",
      "members": {
        "DisableSsl": {
          "type": "boolean"
        }
      }
    },
    "Ssa": {
      "type": "structure",
      "members": {
        "Arn": {},
        "GroupName": {},
        "Description": {},
        "PrincipalId": {}
      }
    },
    "Sse": {
      "type": "structure",
      "members": {
        "Arn": {},
        "MemberName": {}
      }
    },
    "Ssi": {
      "type": "map",
      "key": {},
      "value": {
        "type": "list",
        "member": {}
      }
    },
    "Ssv": {
      "type": "structure",
      "required": [
        "ScheduleId",
        "ScheduleFrequency",
        "RefreshType"
      ],
      "members": {
        "ScheduleId": {},
        "ScheduleFrequency": {
          "type": "structure",
          "required": [
            "Interval"
          ],
          "members": {
            "Interval": {},
            "RefreshOnDay": {
              "type": "structure",
              "members": {
                "DayOfWeek": {},
                "DayOfMonth": {}
              }
            },
            "Timezone": {},
            "TimeOfTheDay": {}
          }
        },
        "StartAfterDateTime": {
          "type": "timestamp"
        },
        "RefreshType": {},
        "Arn": {}
      }
    },
    "St7": {
      "type": "structure",
      "members": {
        "SourceAnalysis": {
          "type": "structure",
          "required": [
            "Arn",
            "DataSetReferences"
          ],
          "members": {
            "Arn": {},
            "DataSetReferences": {
              "shape": "S1f"
            }
          }
        },
        "SourceTemplate": {
          "type": "structure",
          "required": [
            "Arn"
          ],
          "members": {
            "Arn": {}
          }
        }
      }
    },
    "Sta": {
      "type": "structure",
      "required": [
        "DataSetConfigurations"
      ],
      "members": {
        "DataSetConfigurations": {
          "shape": "Stb"
        },
        "Sheets": {
          "shape": "S1l"
        },
        "CalculatedFields": {
          "shape": "Sk9"
        },
        "ParameterDeclarations": {
          "shape": "Skc"
        },
        "FilterGroups": {
          "shape": "Skw"
        },
        "ColumnConfigurations": {
          "shape": "Slw"
        },
        "AnalysisDefaults": {
          "shape": "Sm3"
        },
        "Options": {
          "shape": "Sma"
        }
      }
    },
    "Stb": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Placeholder": {},
          "DataSetSchema": {
            "type": "structure",
            "members": {
              "ColumnSchemaList": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "Name": {},
                    "DataType": {},
                    "GeographicRole": {}
                  }
                }
              }
            }
          },
          "ColumnGroupSchemaList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "ColumnGroupColumnSchemaList": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Name": {}
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "Stp": {
      "type": "structure",
      "members": {
        "AliasName": {},
        "Arn": {},
        "TemplateVersionNumber": {
          "type": "long"
        }
      }
    },
    "Sts": {
      "type": "structure",
      "members": {
        "DataColorPalette": {
          "type": "structure",
          "members": {
            "Colors": {
              "shape": "Stu"
            },
            "MinMaxGradient": {
              "shape": "Stu"
            },
            "EmptyFillColor": {}
          }
        },
        "UIColorPalette": {
          "type": "structure",
          "members": {
            "PrimaryForeground": {},
            "PrimaryBackground": {},
            "SecondaryForeground": {},
            "SecondaryBackground": {},
            "Accent": {},
            "AccentForeground": {},
            "Danger": {},
            "DangerForeground": {},
            "Warning": {},
            "WarningForeground": {},
            "Success": {},
            "SuccessForeground": {},
            "Dimension": {},
            "DimensionForeground": {},
            "Measure": {},
            "MeasureForeground": {}
          }
        },
        "Sheet": {
          "type": "structure",
          "members": {
            "Tile": {
              "type": "structure",
              "members": {
                "Border": {
                  "type": "structure",
                  "members": {
                    "Show": {
                      "type": "boolean"
                    }
                  }
                }
              }
            },
            "TileLayout": {
              "type": "structure",
              "members": {
                "Gutter": {
                  "type": "structure",
                  "members": {
                    "Show": {
                      "type": "boolean"
                    }
                  }
                },
                "Margin": {
                  "type": "structure",
                  "members": {
                    "Show": {
                      "type": "boolean"
                    }
                  }
                }
              }
            }
          }
        },
        "Typography": {
          "type": "structure",
          "members": {
            "FontFamilies": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "FontFamily": {}
                }
              }
            }
          }
        }
      }
    },
    "Stu": {
      "type": "list",
      "member": {}
    },
    "Su8": {
      "type": "structure",
      "members": {
        "Arn": {},
        "AliasName": {},
        "ThemeVersionNumber": {
          "type": "long"
        }
      }
    },
    "Sub": {
      "type": "structure",
      "members": {
        "Name": {},
        "Description": {},
        "UserExperienceVersion": {},
        "DataSets": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "DatasetArn"
            ],
            "members": {
              "DatasetArn": {},
              "DatasetName": {},
              "DatasetDescription": {},
              "DataAggregation": {
                "type": "structure",
                "members": {
                  "DatasetRowDateGranularity": {},
                  "DefaultDateColumnName": {}
                }
              },
              "Filters": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "FilterName",
                    "OperandFieldName"
                  ],
                  "members": {
                    "FilterDescription": {},
                    "FilterClass": {},
                    "FilterName": {},
                    "FilterSynonyms": {
                      "shape": "Sul"
                    },
                    "OperandFieldName": {},
                    "FilterType": {},
                    "CategoryFilter": {
                      "type": "structure",
                      "members": {
                        "CategoryFilterFunction": {},
                        "CategoryFilterType": {},
                        "Constant": {
                          "type": "structure",
                          "members": {
                            "ConstantType": {},
                            "SingularConstant": {},
                            "CollectiveConstant": {
                              "type": "structure",
                              "members": {
                                "ValueList": {
                                  "shape": "Sut"
                                }
                              }
                            }
                          },
                          "sensitive": true
                        },
                        "Inverse": {
                          "type": "boolean"
                        }
                      }
                    },
                    "NumericEqualityFilter": {
                      "type": "structure",
                      "members": {
                        "Constant": {
                          "shape": "Suv"
                        },
                        "Aggregation": {}
                      }
                    },
                    "NumericRangeFilter": {
                      "type": "structure",
                      "members": {
                        "Inclusive": {
                          "type": "boolean"
                        },
                        "Constant": {
                          "shape": "Suy"
                        },
                        "Aggregation": {}
                      }
                    },
                    "DateRangeFilter": {
                      "type": "structure",
                      "members": {
                        "Inclusive": {
                          "type": "boolean"
                        },
                        "Constant": {
                          "shape": "Suy"
                        }
                      }
                    },
                    "RelativeDateFilter": {
                      "type": "structure",
                      "members": {
                        "TimeGranularity": {},
                        "RelativeDateFilterFunction": {},
                        "Constant": {
                          "shape": "Suv"
                        }
                      }
                    }
                  }
                }
              },
              "Columns": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "ColumnName"
                  ],
                  "members": {
                    "ColumnName": {},
                    "ColumnFriendlyName": {},
                    "ColumnDescription": {},
                    "ColumnSynonyms": {
                      "shape": "Sul"
                    },
                    "ColumnDataRole": {},
                    "Aggregation": {},
                    "IsIncludedInTopic": {
                      "type": "boolean"
                    },
                    "DisableIndexing": {
                      "type": "boolean"
                    },
                    "ComparativeOrder": {
                      "shape": "Sv8"
                    },
                    "SemanticType": {
                      "shape": "Svb"
                    },
                    "TimeGranularity": {},
                    "AllowedAggregations": {
                      "shape": "Svd"
                    },
                    "NotAllowedAggregations": {
                      "shape": "Svd"
                    },
                    "DefaultFormatting": {
                      "shape": "Svf"
                    },
                    "NeverAggregateInFilter": {
                      "type": "boolean"
                    },
                    "CellValueSynonyms": {
                      "shape": "Svk"
                    },
                    "NonAdditive": {
                      "type": "boolean"
                    }
                  }
                }
              },
              "CalculatedFields": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "CalculatedFieldName",
                    "Expression"
                  ],
                  "members": {
                    "CalculatedFieldName": {},
                    "CalculatedFieldDescription": {},
                    "Expression": {
                      "shape": "S4r"
                    },
                    "CalculatedFieldSynonyms": {
                      "shape": "Sul"
                    },
                    "IsIncludedInTopic": {
                      "type": "boolean"
                    },
                    "DisableIndexing": {
                      "type": "boolean"
                    },
                    "ColumnDataRole": {},
                    "TimeGranularity": {},
                    "DefaultFormatting": {
                      "shape": "Svf"
                    },
                    "Aggregation": {},
                    "ComparativeOrder": {
                      "shape": "Sv8"
                    },
                    "SemanticType": {
                      "shape": "Svb"
                    },
                    "AllowedAggregations": {
                      "shape": "Svd"
                    },
                    "NotAllowedAggregations": {
                      "shape": "Svd"
                    },
                    "NeverAggregateInFilter": {
                      "type": "boolean"
                    },
                    "CellValueSynonyms": {
                      "shape": "Svk"
                    },
                    "NonAdditive": {
                      "type": "boolean"
                    }
                  }
                }
              },
              "NamedEntities": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "EntityName"
                  ],
                  "members": {
                    "EntityName": {},
                    "EntityDescription": {},
                    "EntitySynonyms": {
                      "shape": "Sul"
                    },
                    "SemanticEntityType": {
                      "type": "structure",
                      "members": {
                        "TypeName": {},
                        "SubTypeName": {},
                        "TypeParameters": {
                          "shape": "Svc"
                        }
                      }
                    },
                    "Definition": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "members": {
                          "FieldName": {},
                          "PropertyName": {},
                          "PropertyRole": {},
                          "PropertyUsage": {},
                          "Metric": {
                            "type": "structure",
                            "members": {
                              "Aggregation": {},
                              "AggregationFunctionParameters": {
                                "type": "map",
                                "key": {},
                                "value": {}
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "Sul": {
      "type": "list",
      "member": {}
    },
    "Sut": {
      "type": "list",
      "member": {}
    },
    "Suv": {
      "type": "structure",
      "members": {
        "ConstantType": {},
        "SingularConstant": {}
      },
      "sensitive": true
    },
    "Suy": {
      "type": "structure",
      "members": {
        "ConstantType": {},
        "RangeConstant": {
          "type": "structure",
          "members": {
            "Minimum": {},
            "Maximum": {}
          }
        }
      },
      "sensitive": true
    },
    "Sv8": {
      "type": "structure",
      "members": {
        "UseOrdering": {},
        "SpecifedOrder": {
          "shape": "Sut"
        },
        "TreatUndefinedSpecifiedValues": {}
      }
    },
    "Svb": {
      "type": "structure",
      "members": {
        "TypeName": {},
        "SubTypeName": {},
        "TypeParameters": {
          "shape": "Svc"
        },
        "TruthyCellValue": {
          "shape": "Sw"
        },
        "TruthyCellValueSynonyms": {
          "shape": "Sv"
        },
        "FalseyCellValue": {
          "shape": "Sw"
        },
        "FalseyCellValueSynonyms": {
          "shape": "Sv"
        }
      }
    },
    "Svc": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Svd": {
      "type": "list",
      "member": {}
    },
    "Svf": {
      "type": "structure",
      "members": {
        "DisplayFormat": {},
        "DisplayFormatOptions": {
          "type": "structure",
          "members": {
            "UseBlankCellFormat": {
              "type": "boolean"
            },
            "BlankCellFormat": {},
            "DateFormat": {},
            "DecimalSeparator": {},
            "GroupingSeparator": {},
            "UseGrouping": {
              "type": "boolean"
            },
            "FractionDigits": {
              "type": "integer"
            },
            "Prefix": {},
            "Suffix": {},
            "UnitScaler": {},
            "NegativeFormat": {
              "type": "structure",
              "members": {
                "Prefix": {},
                "Suffix": {}
              }
            },
            "CurrencySymbol": {}
          }
        }
      }
    },
    "Svk": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "CellValue": {},
          "Synonyms": {
            "shape": "Sut"
          }
        }
      }
    },
    "Sw0": {
      "type": "structure",
      "required": [
        "IsEnabled",
        "BasedOnSpiceSchedule"
      ],
      "members": {
        "IsEnabled": {
          "type": "boolean"
        },
        "BasedOnSpiceSchedule": {
          "type": "boolean"
        },
        "StartingAt": {
          "type": "timestamp"
        },
        "Timezone": {},
        "RepeatAt": {},
        "TopicScheduleType": {}
      }
    },
    "Sw5": {
      "type": "list",
      "member": {}
    },
    "Sw7": {
      "type": "list",
      "member": {}
    },
    "Sw9": {
      "type": "list",
      "member": {}
    },
    "Sya": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Type": {},
          "Message": {},
          "ViolatedEntities": {
            "shape": "Syd"
          }
        }
      }
    },
    "Syd": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Path": {}
        }
      }
    },
    "Syf": {
      "type": "list",
      "member": {}
    },
    "Syg": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "SheetId": {},
          "Name": {}
        }
      }
    },
    "Syp": {
      "type": "string",
      "sensitive": true
    },
    "Sys": {
      "type": "list",
      "member": {}
    },
    "Syu": {
      "type": "structure",
      "members": {
        "ResourceIdOverrideConfiguration": {
          "type": "structure",
          "members": {
            "PrefixForAllResources": {
              "type": "boolean"
            }
          }
        },
        "VPCConnections": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Arn",
              "Properties"
            ],
            "members": {
              "Arn": {},
              "Properties": {
                "type": "list",
                "member": {}
              }
            }
          }
        },
        "RefreshSchedules": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Arn",
              "Properties"
            ],
            "members": {
              "Arn": {},
              "Properties": {
                "type": "list",
                "member": {}
              }
            }
          }
        },
        "DataSources": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Arn",
              "Properties"
            ],
            "members": {
              "Arn": {},
              "Properties": {
                "type": "list",
                "member": {}
              }
            }
          }
        },
        "DataSets": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Arn",
              "Properties"
            ],
            "members": {
              "Arn": {},
              "Properties": {
                "type": "list",
                "member": {}
              }
            }
          }
        },
        "Themes": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Arn",
              "Properties"
            ],
            "members": {
              "Arn": {},
              "Properties": {
                "type": "list",
                "member": {}
              }
            }
          }
        },
        "Analyses": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Arn",
              "Properties"
            ],
            "members": {
              "Arn": {},
              "Properties": {
                "type": "list",
                "member": {}
              }
            }
          }
        },
        "Dashboards": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Arn",
              "Properties"
            ],
            "members": {
              "Arn": {},
              "Properties": {
                "type": "list",
                "member": {}
              }
            }
          }
        }
      }
    },
    "Szo": {
      "type": "structure",
      "members": {
        "StrictModeForAllResources": {
          "type": "boolean"
        }
      }
    },
    "Szu": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Arn": {},
          "Type": {},
          "Message": {}
        }
      }
    },
    "Szy": {
      "type": "structure",
      "members": {
        "ResourceIdOverrideConfiguration": {
          "type": "structure",
          "members": {
            "PrefixForAllResources": {}
          }
        },
        "VPCConnections": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "VPCConnectionId"
            ],
            "members": {
              "VPCConnectionId": {},
              "Name": {},
              "SubnetIds": {
                "shape": "Sw5"
              },
              "SecurityGroupIds": {
                "shape": "Sw7"
              },
              "DnsResolvers": {
                "shape": "Sw9"
              },
              "RoleArn": {}
            }
          }
        },
        "RefreshSchedules": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "DataSetId",
              "ScheduleId"
            ],
            "members": {
              "DataSetId": {},
              "ScheduleId": {},
              "StartAfterDateTime": {
                "type": "timestamp"
              }
            }
          }
        },
        "DataSources": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "DataSourceId"
            ],
            "members": {
              "DataSourceId": {},
              "Name": {},
              "DataSourceParameters": {
                "shape": "Sq5"
              },
              "VpcConnectionProperties": {
                "shape": "Srt"
              },
              "SslProperties": {
                "shape": "Sru"
              },
              "Credentials": {
                "type": "structure",
                "members": {
                  "CredentialPair": {
                    "type": "structure",
                    "required": [
                      "Username",
                      "Password"
                    ],
                    "members": {
                      "Username": {},
                      "Password": {}
                    },
                    "sensitive": true
                  },
                  "SecretArn": {}
                }
              }
            }
          }
        },
        "DataSets": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "DataSetId"
            ],
            "members": {
              "DataSetId": {},
              "Name": {}
            }
          }
        },
        "Themes": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "ThemeId"
            ],
            "members": {
              "ThemeId": {},
              "Name": {}
            }
          }
        },
        "Analyses": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "AnalysisId"
            ],
            "members": {
              "AnalysisId": {},
              "Name": {}
            }
          }
        },
        "Dashboards": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "DashboardId"
            ],
            "members": {
              "DashboardId": {},
              "Name": {}
            }
          }
        }
      }
    },
    "S10h": {
      "type": "structure",
      "members": {
        "DataSources": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "DataSourceIds",
              "Permissions"
            ],
            "members": {
              "DataSourceIds": {
                "shape": "S10k"
              },
              "Permissions": {
                "shape": "S10m"
              }
            }
          }
        },
        "DataSets": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "DataSetIds",
              "Permissions"
            ],
            "members": {
              "DataSetIds": {
                "shape": "S10k"
              },
              "Permissions": {
                "shape": "S10m"
              }
            }
          }
        },
        "Themes": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "ThemeIds",
              "Permissions"
            ],
            "members": {
              "ThemeIds": {
                "shape": "S10k"
              },
              "Permissions": {
                "shape": "S10m"
              }
            }
          }
        },
        "Analyses": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "AnalysisIds",
              "Permissions"
            ],
            "members": {
              "AnalysisIds": {
                "shape": "S10k"
              },
              "Permissions": {
                "shape": "S10m"
              }
            }
          }
        },
        "Dashboards": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "DashboardIds"
            ],
            "members": {
              "DashboardIds": {
                "shape": "S10k"
              },
              "Permissions": {
                "shape": "S10m"
              },
              "LinkSharingConfiguration": {
                "type": "structure",
                "members": {
                  "Permissions": {
                    "shape": "S10m"
                  }
                }
              }
            }
          }
        }
      }
    },
    "S10k": {
      "type": "list",
      "member": {}
    },
    "S10m": {
      "type": "structure",
      "required": [
        "Principals",
        "Actions"
      ],
      "members": {
        "Principals": {
          "type": "list",
          "member": {}
        },
        "Actions": {
          "shape": "S1c"
        }
      }
    },
    "S10x": {
      "type": "structure",
      "members": {
        "VPCConnections": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "VPCConnectionIds",
              "Tags"
            ],
            "members": {
              "VPCConnectionIds": {
                "shape": "S10k"
              },
              "Tags": {
                "shape": "Sb"
              }
            }
          }
        },
        "DataSources": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "DataSourceIds",
              "Tags"
            ],
            "members": {
              "DataSourceIds": {
                "shape": "S10k"
              },
              "Tags": {
                "shape": "Sb"
              }
            }
          }
        },
        "DataSets": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "DataSetIds",
              "Tags"
            ],
            "members": {
              "DataSetIds": {
                "shape": "S10k"
              },
              "Tags": {
                "shape": "Sb"
              }
            }
          }
        },
        "Themes": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "ThemeIds",
              "Tags"
            ],
            "members": {
              "ThemeIds": {
                "shape": "S10k"
              },
              "Tags": {
                "shape": "Sb"
              }
            }
          }
        },
        "Analyses": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "AnalysisIds",
              "Tags"
            ],
            "members": {
              "AnalysisIds": {
                "shape": "S10k"
              },
              "Tags": {
                "shape": "Sb"
              }
            }
          }
        },
        "Dashboards": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "DashboardIds",
              "Tags"
            ],
            "members": {
              "DashboardIds": {
                "shape": "S10k"
              },
              "Tags": {
                "shape": "Sb"
              }
            }
          }
        }
      }
    },
    "S11a": {
      "type": "structure",
      "members": {
        "StrictModeForAllResources": {
          "type": "boolean"
        }
      }
    },
    "S11f": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Type": {},
          "Message": {},
          "ViolatedEntities": {
            "shape": "Syd"
          }
        }
      }
    },
    "S11s": {
      "type": "structure",
      "required": [
        "FileGroups"
      ],
      "members": {
        "FileGroups": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Files": {
                "shape": "S11v"
              }
            }
          }
        },
        "DestinationConfiguration": {
          "type": "structure",
          "members": {
            "S3Destinations": {
              "type": "list",
              "member": {
                "shape": "S124"
              }
            }
          }
        },
        "Parameters": {
          "shape": "Sr"
        }
      }
    },
    "S11v": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "SheetSelections",
          "FormatType"
        ],
        "members": {
          "SheetSelections": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "SheetId",
                "SelectionScope"
              ],
              "members": {
                "SheetId": {},
                "SelectionScope": {},
                "VisualIds": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "FormatType": {}
        }
      }
    },
    "S124": {
      "type": "structure",
      "required": [
        "BucketConfiguration"
      ],
      "members": {
        "BucketConfiguration": {
          "type": "structure",
          "required": [
            "BucketName",
            "BucketPrefix",
            "BucketRegion"
          ],
          "members": {
            "BucketName": {},
            "BucketPrefix": {},
            "BucketRegion": {}
          }
        }
      }
    },
    "S12s": {
      "type": "structure",
      "required": [
        "RefreshConfiguration"
      ],
      "members": {
        "RefreshConfiguration": {
          "type": "structure",
          "required": [
            "IncrementalRefresh"
          ],
          "members": {
            "IncrementalRefresh": {
              "type": "structure",
              "required": [
                "LookbackWindow"
              ],
              "members": {
                "LookbackWindow": {
                  "type": "structure",
                  "required": [
                    "ColumnName",
                    "Size",
                    "SizeUnit"
                  ],
                  "members": {
                    "ColumnName": {},
                    "Size": {
                      "type": "long"
                    },
                    "SizeUnit": {}
                  }
                }
              }
            }
          }
        }
      }
    },
    "S130": {
      "type": "structure",
      "members": {
        "Arn": {},
        "DataSourceId": {},
        "Name": {},
        "Type": {},
        "Status": {},
        "CreatedTime": {
          "type": "timestamp"
        },
        "LastUpdatedTime": {
          "type": "timestamp"
        },
        "DataSourceParameters": {
          "shape": "Sq5"
        },
        "AlternateDataSourceParameters": {
          "shape": "Srq"
        },
        "VpcConnectionProperties": {
          "shape": "Srt"
        },
        "SslProperties": {
          "shape": "Sru"
        },
        "ErrorInfo": {
          "type": "structure",
          "members": {
            "Type": {},
            "Message": {}
          }
        },
        "SecretArn": {}
      }
    },
    "S13n": {
      "type": "structure",
      "required": [
        "Arn",
        "IngestionStatus",
        "CreatedTime"
      ],
      "members": {
        "Arn": {},
        "IngestionId": {},
        "IngestionStatus": {},
        "ErrorInfo": {
          "type": "structure",
          "members": {
            "Type": {},
            "Message": {}
          }
        },
        "RowInfo": {
          "type": "structure",
          "members": {
            "RowsIngested": {
              "type": "long"
            },
            "RowsDropped": {
              "type": "long"
            },
            "TotalRowsInDataset": {
              "type": "long"
            }
          }
        },
        "QueueInfo": {
          "type": "structure",
          "required": [
            "WaitingOnIngestion",
            "QueuedIngestion"
          ],
          "members": {
            "WaitingOnIngestion": {},
            "QueuedIngestion": {}
          }
        },
        "CreatedTime": {
          "type": "timestamp"
        },
        "IngestionTimeInSeconds": {
          "type": "long"
        },
        "IngestionSizeInBytes": {
          "type": "long"
        },
        "RequestSource": {},
        "RequestType": {}
      }
    },
    "S13w": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S13z": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S142": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S147": {
      "type": "structure",
      "members": {
        "Name": {},
        "Arn": {},
        "CapacityRegion": {},
        "CreationStatus": {},
        "IdentityStore": {},
        "NamespaceError": {
          "type": "structure",
          "members": {
            "Type": {},
            "Message": {}
          }
        }
      }
    },
    "S14j": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Type": {},
          "Message": {},
          "ViolatedEntities": {
            "shape": "Syd"
          }
        }
      }
    },
    "S15h": {
      "type": "structure",
      "members": {
        "Arn": {},
        "UserName": {},
        "Email": {},
        "Role": {},
        "IdentityType": {},
        "Active": {
          "type": "boolean"
        },
        "PrincipalId": {},
        "CustomPermissionsName": {},
        "ExternalLoginFederationProviderType": {},
        "ExternalLoginFederationProviderUrl": {},
        "ExternalLoginId": {}
      }
    },
    "S15n": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "SubnetId": {},
          "AvailabilityZone": {},
          "ErrorMessage": {},
          "Status": {},
          "NetworkInterfaceId": {}
        }
      }
    },
    "S15t": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {
            "shape": "Spi"
          }
        }
      }
    },
    "S15z": {
      "type": "structure",
      "required": [
        "DashboardId",
        "SheetId",
        "VisualId"
      ],
      "members": {
        "DashboardId": {},
        "SheetId": {},
        "VisualId": {}
      }
    },
    "S162": {
      "type": "string",
      "sensitive": true
    },
    "S167": {
      "type": "structure",
      "required": [
        "Enabled"
      ],
      "members": {
        "Enabled": {
          "type": "boolean"
        }
      }
    },
    "S16o": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Arn": {},
          "AnalysisId": {},
          "Name": {},
          "Status": {},
          "CreatedTime": {
            "type": "timestamp"
          },
          "LastUpdatedTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "S174": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Arn": {},
          "DashboardId": {},
          "Name": {},
          "CreatedTime": {
            "type": "timestamp"
          },
          "LastUpdatedTime": {
            "type": "timestamp"
          },
          "PublishedVersionNumber": {
            "type": "long"
          },
          "LastPublishedTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "S178": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Arn": {},
          "DataSetId": {},
          "Name": {},
          "CreatedTime": {
            "type": "timestamp"
          },
          "LastUpdatedTime": {
            "type": "timestamp"
          },
          "ImportMode": {},
          "RowLevelPermissionDataSet": {
            "shape": "Sp9"
          },
          "RowLevelPermissionTagConfigurationApplied": {
            "type": "boolean"
          },
          "ColumnLevelPermissionRulesApplied": {
            "type": "boolean"
          }
        }
      }
    },
    "S17j": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Arn": {},
          "FolderId": {},
          "Name": {},
          "FolderType": {},
          "CreatedTime": {
            "type": "timestamp"
          },
          "LastUpdatedTime": {
            "type": "timestamp"
          },
          "SharingModel": {}
        }
      }
    },
    "S17q": {
      "type": "list",
      "member": {
        "shape": "Ssa"
      }
    },
    "S184": {
      "type": "list",
      "member": {}
    },
    "S1be": {
      "type": "list",
      "member": {
        "shape": "S1a"
      }
    },
    "S1bl": {
      "type": "list",
      "member": {
        "shape": "S1a"
      }
    }
  }
}