import "../../../style.css";

import React, { useContext, useEffect, useState } from "react";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { nanoid } from "nanoid";
import TextInput from "../../components/TextInput";
import LargeTextInput from "../../components/LargeTextInput";
import Grid from "@mui/material/Unstable_Grid2";
import { AssignPublicIp, ECSClient, RunTaskCommand } from "@aws-sdk/client-ecs";
import dayjs from "dayjs";
import SelectItems from "../../components/SelectItemsV2";
import { Button, CircularProgress, FormHelperText, Typography } from "@mui/material";
import useFetch from "../../hooks/useFetch";
import configBase from "../../utils/McpMlConfigBase";
import HorisontalRadioButtons from "../../components/HorisontalRadioButtons";
import useAwsSchedulerClient from "../../hooks/useAwsSchedulerClient";
import useAwsS3Client from "../../hooks/useAwsS3Client";
import { useParams } from "react-router-dom";
import NumberInput from "../../components/NumberInput";
import { Layout } from "../../layout";
import { AuthContext } from "../../contexts/authContext";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";



export const McpMlConfig1 = (): React.ReactElement => {
    
    const { ensureAwsCredentials } = useContext(AuthContext);

    // get URL parameter (if defined)
    const { transferedScheduleName } = useParams();

    // let jobid
    const [transferedJobid, setTransferedJobid] = useState<string>();

    // User inputs
    const [station, setStation] = useState<string[]>([]);
    const [sensor, setSensor] = useState<string[]>([]);
    const [speed, setSpeed] = useState<string[]>([]);
    const [direction, setDirection] = useState<string[]>([]);
    const [config, setConfig] = useState(configBase);
    const [sendResultsTo, setSendResultsTo] = useState("User");
    const [emailRecipient, setEmailRecipient] = useState("");
    const [executionMode, setExecutionMode] = useState("Now");
    const [scheduleRate, setScheduleRate] = useState<string>("");
    const [scheduleName, setScheduleName] = useState("");
    const [scheduleDescription, setScheduleDescription] = useState("");
    const [forecastMode, setForecastMode] = useState("Real-time");
    const [forecastStartDate, setForecastStartDate] = useState(transferedJobid ? dayjs(config["forecast_start_date"], "YYYY-MM-DD") : dayjs());

    // Validation
    const [stationError, setStationError] = useState(false);
    const [sensorError, setSensorError] = useState(false);
    const [speedError, setSpeedError] = useState(false);
    const [directionError, setDirectionError] = useState(false);
    const [configError, setConfigError] = useState(false);
    const [sendResultsToError, setSendResultsToError] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [executionModeError, setExecutionModeError] = useState(false);
    const [scheduleRateError, setScheduleRateError] = useState(false);
    const [scheduleNameError, setScheduleNameError] = useState(false);
    const [scheduleDescriptionError, setScheduleDescriptionError] = useState(false);

    //
    const [catalog, setCatalog] = useState<any>();
    const [transferedScheduleDetails, setTransferedScheduleDetails] = useState<any>();

    const { fetchMetNetGlobalCatalog } = useFetch();

    const urlCatalog = "https://www.shellmetnetglobal.com/api/Schema/Get";

    useEffect(() => {
        (async () => {
            const awsConfig = await ensureAwsCredentials();

            const { getExistingSchedulesDetailsFromScheduleName } =
                useAwsSchedulerClient(awsConfig);
        
            const { getObject } = useAwsS3Client(awsConfig);

            console.log("[LOG] Getting schedule from MetNet Global: ", transferedScheduleName);

            fetchMetNetGlobalCatalog(urlCatalog).then((data) => setCatalog(data));

            console.log("[LOG] Transfering details from schedule: ", transferedScheduleName);

            getExistingSchedulesDetailsFromScheduleName(transferedScheduleName).then((data) => {
                if (data) {
                    setTransferedScheduleDetails(data);
                    setExecutionMode("Scheduled");
                    setScheduleName(data["Name"]);
                    setScheduleDescription(data["Description"]);
                    setScheduleRate(data["ScheduleExpression"].split("(")[1].split(" ")[0]);

                    const _jobid = data["Target"]["EcsParameters"]["Tags"][0]["jobid"];
                    setTransferedJobid(_jobid);

                    console.log(`[LOG] Getting config for jobid ${_jobid} from S3`);
                    const bucket = "shell-dw-module-output";
                    const key = "modules/mcp-ml4/jobs/" + _jobid + "/config.json";
                    getObject(bucket, key)
                        // seems like a horrible approach!!
                        .then((data) => {
                            const _config = JSON.parse(data);
                            setConfig(_config);
                            setForecastMode(_config['forecast_mode']);
                            setForecastStartDate(_config['forecast_start_date']);
                            setStation([_config["stationid"]]);
                            setSensor([_config["sensorid"]]);
                            setSpeed([_config["curspdid"]]);
                            setDirection([_config["curdirid"]]);
                            setEmailRecipient(_config["email_recipient"]);
                            setSendResultsTo(
                                _config["send_results_to_metnet"] === true ? "MetNet Global" : "User"
                            );
                        })
                        // why won't this work?
                        // .then((data) => setConfig(JSON.parse(data)))
                        .then(() => {
                            console.log("[LOG] Config was gotten from S3: ", config);
                        });
                } else {
                    // needs to be set to move on from the progress page further down
                    setTransferedScheduleDetails("No schedule was transfered...");
                }
            });
        })();
    }, []);

    // Show progress/loading page until catalog has been loaded
    if (!catalog || !transferedScheduleDetails) {
        const loadingContent = (
            <Grid container spacing={2} columns={{ xs: 6, sm: 12 }}>
                <Grid
                    xs={12}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "100px",
                    }}
                >
                    <CircularProgress />
                </Grid>
            </Grid>
        );

        return <Layout content={loadingContent} moduleName="MCP-ML4" />;
    }

    console.log("[LOG] (unfiltered)catalog: ", catalog["ParameterSchema"]["Stations"]);
    console.log("[LOG] (unfiltered)catalog: ", catalog);
    // filter the catalog to contain only stations with non-empty Sensor-objects
    const filteredCatalog = Object.keys(catalog["ParameterSchema"]["Stations"])
        .filter(
            (key) => Object.keys(catalog["ParameterSchema"]["Stations"][key]["Sensors"]).length > 0
        )
        .reduce((cur, key) => {
            return Object.assign(cur, { [key]: catalog["ParameterSchema"]["Stations"][key] });
        }, {});

    console.log("[LOG] filteredCatalog: ", filteredCatalog);

    // prepare for rendering stations different from the value used in the config
    const renderedStationOptions = Object.keys(filteredCatalog)
        .map(
            (station) =>
                `${filteredCatalog[station]["Station"]["Label"]} (${filteredCatalog[station]["Station"]["Id"]})`
        )
        .sort();

    const mapFromRenderedStationOptions = {};

    for (const station of Object.keys(filteredCatalog)) {
        mapFromRenderedStationOptions[
            `${filteredCatalog[station]["Station"]["Label"]} (${filteredCatalog[station]["Station"]["Id"]})`
        ] = station;
    }

    const mapToRenderedStationOptions = {};

    for (const station of Object.keys(filteredCatalog)) {
        mapToRenderedStationOptions[
            station
        ] = `${filteredCatalog[station]["Station"]["Label"]} (${filteredCatalog[station]["Station"]["Id"]})`;
    }

    function validateInputs() {
        console.log("[LOG] Validating inputs...");

        let valid = true;

        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

        // Reset errors at the beginning of the validation
        setStationError(false);
        setSensorError(false);
        setSpeedError(false);
        setDirectionError(false);
        setEmailError(false);
        setConfigError(false);
        setScheduleRateError(false);
        setScheduleNameError(false);
        setScheduleDescriptionError(false);

        // Validate
        if (station.length === 0) {
            setStationError(true);
            valid = false;
        }

        if (sensor.length === 0) {
            setSensorError(true);
            valid = false;
        }

        if (speed.length === 0) {
            setSpeedError(true);
            valid = false;
        }

        if (direction.length === 0) {
            setDirectionError(true);
            valid = false;
        }

        if (
            sendResultsTo === "User" &&
            (emailRecipient === "" ||
                !emailRecipient.includes("@") ||
                !emailRecipient.includes("."))
        ) {
            setEmailError(true);
            valid = false;
        }

        if (Object.keys(config).length === 0) {
            setConfigError(true);
            valid = false;
        }

        if (executionMode === "Scheduled" && scheduleRate.length === 0) {
            setScheduleRateError(true);
            valid = false;
        }

        if (
            executionMode === "Scheduled" &&
            (scheduleName.length === 0 ||
                specialChars.test(scheduleName) ||
                scheduleName.includes(" "))
        ) {
            setScheduleNameError(true);
            valid = false;
        }

        if (executionMode === "Scheduled" && scheduleDescription.length === 0) {
            setScheduleDescriptionError(true);
            valid = false;
        }

        // Raise alert if relevant
        if (!valid) {
            alert("Some values are invalid. Please check your inputs.");
            return false;
        } else {
            return true;
        }
    }

    const saveInputs = async (jobid) => {

        console.log(`[LOG] Saving for jobid=${jobid}...`);

        // Configure AWS clients
        const awsConfig = await ensureAwsCredentials();

        const s3Client = new S3Client({
            credentials: awsConfig.credentials,
            region: awsConfig.region
        });

        const ecsClient = new ECSClient({
            credentials: awsConfig.credentials,
            region: awsConfig.region
        });

        // data source
        const key_config = "modules/mcp-ml4/jobs/" + jobid + "/config.json";

        console.log("[LOG] Saving config: ", JSON.stringify(config));

        await s3Client.send(
            new PutObjectCommand({
                Bucket: "shell-dw-module-output",
                Key: key_config,
                Body: JSON.stringify(config),
            })
        );

        if (executionMode === "Now") {
            const response = await ecsClient.send(
                new RunTaskCommand({
                    taskDefinition: "mcp-ml4",
                    cluster:
                        "arn:aws:ecs:us-east-1:348482033654:cluster/dwapi-EcsCluster-NQNzVpomowO9",
                    launchType: "FARGATE",
                    networkConfiguration: {
                        awsvpcConfiguration: {
                            securityGroups: ["sg-029fe4ad2e115f887"],
                            subnets: ["subnet-01ef9e932cd8d4ee5"],
                            assignPublicIp: AssignPublicIp.ENABLED,
                        },
                    },
                    overrides: {
                        containerOverrides: [
                            {
                                name: "module",
                                command: ["python3", "main.py", jobid],
                            },
                        ],
                    },
                })
            );

            if (sendResultsTo === "User") {
                alert(
                    "New job instance created with ID " +
                        jobid +
                        "\n\n" +
                        "Results will be sent to " +
                        emailRecipient
                );
            } else {
                alert(
                    "New job instance created with ID " +
                        jobid +
                        "\n\n" +
                        "Results will be uploaded to MetNet Global"
                );
            }
        } else if (executionMode === "Scheduled") {
            const { runCreateScheduleCommand } = useAwsSchedulerClient(awsConfig);
            runCreateScheduleCommand(jobid, scheduleName, scheduleDescription, scheduleRate);
        }
    };

    const content = (
        <div className="module-page">
            {/* <MenuAppBar module_name="MCP-ML" /> */}

            <p className="module-guide-text">
                This module predicts <strong>currents</strong> from MetNet Global data at the{" "} 
                <strong>Station</strong> for the next x days and uploads the results to MetNet
                Global. But only if <strong>Sensor ID</strong>, <strong>Current speed ID</strong>, 
                and <strong>Current direction ID</strong> are set meaningfully. Choose wisely.
            </p>

            <Grid container spacing={2} justifyContent="center" columns={{ xs: 6, sm: 12 }}>
                <Grid xs={12}>
                    <SelectItems
                        nameParameter={"Station (setting stationid)"}
                        onChange={(ev) => {
                            console.log(
                                "station is set to: ",
                                mapFromRenderedStationOptions[ev[0]]
                            );
                            let _station = mapFromRenderedStationOptions[ev[0]]
                            setStation([_station]);
                            setSensor([]);
                            setSpeed([]);
                            setDirection([]);
                            config["stationid"] = mapFromRenderedStationOptions[ev[0]];
                            config["latitude"] =
                                filteredCatalog[mapFromRenderedStationOptions[ev[0]]]["Station"][
                                    "Latitude"
                                ];
                            config["longitude"] =
                                filteredCatalog[mapFromRenderedStationOptions[ev[0]]]["Station"][
                                    "Longitude"
                                ];

                            config["historical_data_file"] = 
                                `/opt/mcp-ml4/tmp/obs_${_station}_\${jobid}.csv`;
                            config["results_csv"] = 
                                `/opt/mcp-ml4/results/results_${_station}_\${jobid}.csv`;
                            config["results_png"] = 
                                `/opt/mcp-ml4/results/results_${_station}_\${jobid}.png`;
                            config["metnet_json_file"] = 
                                `/opt/mcp-ml4/tmp/metnet_${_station}_\${jobid}.json`;
                        }}
                        options={renderedStationOptions}
                        multiple={false}
                        error={stationError}
                        disabled={false}
                        changingVariable={[mapToRenderedStationOptions[station[0]]]}
                    />
                </Grid>
                <Grid xs={12}>
                    <SelectItems
                        nameParameter={"Sensor ID (setting sensorid)"}
                        onChange={(ev) => {
                            setSensor(ev);
                            setSpeed([]);
                            setDirection([]);
                            config["sensorid"] = ev[0];
                        }}
                        options={
                            station.length === 0
                                ? []
                                : Object.keys(filteredCatalog[station[0]]["Sensors"]).sort()
                        }
                        multiple={false}
                        error={sensorError}
                        disabled={station.length === 0}
                        changingVariable={[sensor[0]]}
                    />
                </Grid>
                <Grid xs={6}>
                    <SelectItems
                        nameParameter={"Current speed ID (setting curspdid)"}
                        onChange={(ev) => {
                            setSpeed(ev);
                            config["curspdid"] = ev[0];
                        }}
                        options={
                            station.length === 0 || sensor.length === 0
                                ? []
                                : Object.keys(
                                      filteredCatalog[station[0]]["Sensors"][sensor[0]][
                                          "Parameters"
                                      ]
                                  ).sort()
                        }
                        multiple={false}
                        error={speedError}
                        disabled={station.length === 0 || sensor.length === 0}
                        changingVariable={[speed[0]]}
                    />
                </Grid>
                <Grid xs={6}>
                    <SelectItems
                        nameParameter={"Current direction ID (setting curdirid)"}
                        onChange={(ev) => {
                            setDirection(ev);
                            config["curdirid"] = ev[0];
                        }}
                        options={
                            station.length === 0 || sensor.length === 0
                                ? []
                                : Object.keys(
                                      filteredCatalog[station[0]]["Sensors"][sensor]["Parameters"]
                                  ).sort()
                        }
                        multiple={false}
                        error={directionError}
                        disabled={station.length === 0 || sensor.length === 0}
                        changingVariable={[direction[0]]}
                    />
                </Grid>
                <Grid xs={6} className="input-box">
                    <HorisontalRadioButtons
                        name_parameter="Send results to (setting send_results_to_metnet)"
                        options={["MetNet Global", "User"]}
                        onChange={(ev) => {
                            setSendResultsTo(ev);
                            config["send_results_to_metnet"] =
                                ev === "MetNet Global" ? true : false;
                            setEmailRecipient("");
                            config["email_recipient"] = ev === "MetNet Global" ? "" : emailRecipient;

                        }}
                        error={false}
                        default={sendResultsTo}
                        // default={'User'}
                    />
                </Grid>
                <Grid xs={6} className="input-box">
                    <TextInput
                        name_parameter={"E-mail recipient (setting email_recipient)"}
                        onChange={(ev) => {
                            setEmailRecipient(ev);
                            // config["email_recipient"] = ev;
                            setConfig({...config, email_recipient: ev });
                        }}
                        error={emailError}
                        disabled={sendResultsTo === "User" ? false : true}
                        value={emailRecipient}
                    />
                </Grid>
                <Grid xs={6} className="input-box">
                    <HorisontalRadioButtons
                        name_parameter="Forecast mode (setting forecast_mode)"
                        options={["Real-time", "Historical"]}
                        onChange={(ev) => {
                            setForecastMode(ev);
                            config["forecast_mode"] = ev;
                            setForecastStartDate(dayjs());
                            config["forecast_start_date"] = dayjs().format("YYYY-MM-DD");
                        }}
                        error={false}
                        default={forecastMode}
                        // default={'User'}
                    />
                </Grid>
                <Grid xs={6}>
                    <FormHelperText id="outlined-weight-helper-text">
                        Start date
                    </FormHelperText>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            disabled={forecastMode === 'Real-time' ? true : false}
                            defaultValue={dayjs()}
                            maxDate={dayjs()}
                            format="YYYY-MM-DD"
                            views={["year", "month", "day"]}
                            onChange={(ev) => {
                                setForecastStartDate(ev);
                                config["forecast_start_date"] = ev.format("YYYY-MM-DD")
                            }}
                            slotProps={{ textField: { fullWidth: true } }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid xs={12} className="input-box">
                    <LargeTextInput
                        name_parameter={"Configuration file (change only existing content)"}
                        //// will render the config from start, but never update it
                        // default_value={JSON.stringify(config, null, "    ")}
                        //// will render the updated config when all fields are filled out, and update when changed afterwards
                        default_value={
                            station.length === 0 ||
                            sensor.length === 0 ||
                            speed.length === 0 ||
                            direction.length === 0 ||
                            sendResultsTo.length === 0 ||
                            (sendResultsTo === "User" && emailRecipient.length === 0
                                ? true
                                : false)
                                ? ""
                                : JSON.stringify(config, null, "    ")
                        }
                        //// will render the config when station field is filled out, and update when changed afterwards
                        //// default_value={station.length === 0 ? '\n'.repeat(16) : JSON.stringify(config, null, "    ")}
                        //// will render the config from start, but never update it
                        // default_value={false ? '' : JSON.stringify(config, null, "    ")}
                        //// will render the config from start, and update it along the way... or no?
                        // default_value={workAroundForConfigRender ? JSON.stringify(config, null, "    ") : ''}
                        onChange={(ev) => {
                            setConfig(JSON.parse(ev));
                            console.log("the config was changed", config);
                        }}
                        error={false}
                        disabled={
                            station.length === 0 ||
                            sensor.length === 0 ||
                            speed.length === 0 ||
                            direction.length === 0 ||
                            sendResultsTo.length === 0 ||
                            (sendResultsTo === "User" && emailRecipient.length === 0
                                ? true
                                : false)
                        }
                    />
                </Grid>
                <Grid xs={12}>
                    <Typography sx={{ fontWeight: 700 }}>Schedule</Typography>
                </Grid>
                <Grid xs={6} className="input-box">
                    <HorisontalRadioButtons
                        name_parameter="Execution mode"
                        options={["Now", "Scheduled"]}
                        onChange={(ev) => {
                            setExecutionMode(ev);
                        }}
                        error={false}
                        default={executionMode}
                    />
                </Grid>
                <Grid xs={6} className="input-box">
                    <NumberInput
                        name_parameter={"Rate"}
                        unit={"hours"}
                        defaultValue={scheduleRate}
                        step="1"
                        onChange={(ev) => setScheduleRate(ev)}
                        error={scheduleRateError}
                        disabled={executionMode === "Scheduled" ? false : true}
                    />
                </Grid>
                <Grid xs={6} className="input-box">
                    <TextInput
                        name_parameter={
                            "Name (only letters and/or numbers, no spaces or special characters)"
                        }
                        onChange={(ev) => {
                            setScheduleName(ev);
                        }}
                        error={scheduleNameError}
                        disabled={executionMode === "Scheduled" ? false : true}
                        value={scheduleName}
                    />
                </Grid>
                <Grid xs={6} className="input-box">
                    <TextInput
                        name_parameter={"Description"}
                        onChange={(ev) => {
                            setScheduleDescription(ev);
                        }}
                        error={scheduleDescriptionError}
                        disabled={executionMode === "Scheduled" ? false : true}
                        value={scheduleDescription}
                    />
                </Grid>
                <Grid xs={12} margin={0} textAlign={"center"}>
                    <Button
                        disabled={
                            station.length === 0 ||
                            sensor.length === 0 ||
                            speed.length === 0 ||
                            direction.length === 0 ||
                            sendResultsTo.length === 0 ||
                            (sendResultsTo === "User" && emailRecipient.length === 0
                                ? true
                                : false)
                        }
                        onClick={() => {
                            if (validateInputs()) {
                                // checkAccessTokenExpiration();
                                // If transferedScheduleName exists and the schedule name wasn't changed, we're editing an existing schedule. Hence, keep the jobid. Else, create new jobid.
                                const jobid =
                                    transferedScheduleName &&
                                    transferedScheduleName === scheduleName
                                        ? transferedJobid
                                        : nanoid();
                                // Update paths in config with the jobid
                                config["historical_data_file"] = 
                                    `/opt/mcp-ml4/tmp/obs_${station}_${jobid}.csv`;
                                config[
                                    "results_csv"
                                ] = `/opt/mcp-ml4/results/results_${station}_${jobid}.csv`;
                                config[
                                    "results_png"
                                ] = `/opt/mcp-ml4/results/results_${station}_${jobid}.png`;
                                config[
                                    "metnet_json_file"
                                ] = `/opt/mcp-ml4/tmp/metnet_${station}_${jobid}.json`;
                                // Save
                                saveInputs(jobid);
                            }
                        }}
                        variant="GO"
                    >
                        GO
                    </Button>
                </Grid>
            </Grid>
        </div>
    )

    return <Layout content={content} moduleName="MCP-ML" />;
};
