{
  "pagination": {
    "GetDedicatedIps": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "PageSize"
    },
    "ListConfigurationSets": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "PageSize"
    },
    "ListContactLists": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "PageSize"
    },
    "ListContacts": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "PageSize"
    },
    "ListCustomVerificationEmailTemplates": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "PageSize"
    },
    "ListDedicatedIpPools": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "PageSize"
    },
    "ListDeliverabilityTestReports": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "PageSize"
    },
    "ListDomainDeliverabilityCampaigns": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "PageSize"
    },
    "ListEmailIdentities": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "PageSize"
    },
    "ListEmailTemplates": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "PageSize"
    },
    "ListExportJobs": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "PageSize"
    },
    "ListImportJobs": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "PageSize"
    },
    "ListRecommendations": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "PageSize"
    },
    "ListSuppressedDestinations": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "PageSize"
    }
  }
}
