{
  "pagination": {
    "BatchGetAssetPropertyAggregates": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "BatchGetAssetPropertyValue": {
      "input_token": "nextToken",
      "output_token": "nextToken"
    },
    "BatchGetAssetPropertyValueHistory": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults"
    },
    "ExecuteQuery": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "rows"
    },
    "GetAssetPropertyAggregates": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "aggregatedValues"
    },
    "GetAssetPropertyValueHistory": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "assetPropertyValueHistory"
    },
    "GetInterpolatedAssetPropertyValues": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "interpolatedAssetPropertyValues"
    },
    "ListAccessPolicies": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "accessPolicySummaries"
    },
    "ListActions": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "actionSummaries"
    },
    "ListAssetModelCompositeModels": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "assetModelCompositeModelSummaries"
    },
    "ListAssetModelProperties": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "assetModelPropertySummaries"
    },
    "ListAssetModels": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "assetModelSummaries"
    },
    "ListAssetProperties": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "assetPropertySummaries"
    },
    "ListAssetRelationships": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "assetRelationshipSummaries"
    },
    "ListAssets": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "assetSummaries"
    },
    "ListAssociatedAssets": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "assetSummaries"
    },
    "ListBulkImportJobs": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "jobSummaries"
    },
    "ListCompositionRelationships": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "compositionRelationshipSummaries"
    },
    "ListDashboards": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "dashboardSummaries"
    },
    "ListGateways": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "gatewaySummaries"
    },
    "ListPortals": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "portalSummaries"
    },
    "ListProjectAssets": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "assetIds"
    },
    "ListProjects": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "projectSummaries"
    },
    "ListTimeSeries": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "TimeSeriesSummaries"
    }
  }
}
