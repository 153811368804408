import {
    SchedulerClient,
    ListSchedulesCommand,
    CreateScheduleCommand,
    GetScheduleCommand,
    UpdateScheduleCommand,
    AssignPublicIp,
    LaunchType,
    FlexibleTimeWindowMode,
} from "@aws-sdk/client-scheduler";
// why must DeleteScheduleCommand be imported individually???
import { DeleteScheduleCommand } from "@aws-sdk/client-scheduler";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const useAwsSchedulerClient = (
    config: Partial<AWS.Config>
) => {
    const schedulerClient = new SchedulerClient({
        credentials: config.credentials,
        region: config.region
    });

    const runListSchedulesCommand = async (): Promise<any> => {
        const input = {
            // GroupName: "STRING_VALUE",
            // NamePrefix: "STRING_VALUE",
            // State: "STRING_VALUE",
            // NextToken: "STRING_VALUE",
            // MaxResults: Number("int"),
        };

        try {
            const command = new ListSchedulesCommand(input);
            const response = await schedulerClient.send(command);

            console.log("Existing schedules list: ", response);

            return response;
        } catch (error) {
            console.log(
                "[ERROR] Something went wrong when fetching list of existing schedules..."
            );
            console.error(error);
        }
    };

    const getExistingSchedulesDetails = async (existingSchedulesList): Promise<any> => {
        const existingSchedulesDetails = [{}];

        try {
            for (const [key, schedule] of Object.entries(existingSchedulesList["Schedules"])) {
                const input = {
                    Name: schedule["Name"],
                    GroupName: schedule["GroupName"],
                };

                const command = new GetScheduleCommand(input);
                const response = await schedulerClient.send(command);

                existingSchedulesDetails[key] = response;
            }

            return existingSchedulesDetails;
        } catch (error) {
            console.log(
                "[ERROR] Something went wrong when fetching existing details of existing schedules..."
            );
            console.error(error);
        }
    };

    const getExistingSchedulesDetailsFromScheduleName = async (scheduleName): Promise<any> => {
        try {
            const input = {
                Name: scheduleName,
                // GroupName: schedule['GroupName'],
            };

            const command = new GetScheduleCommand(input);
            const response = await schedulerClient.send(command);

            return response;
        } catch (error) {
            console.log(
                `[ERROR] Something went wrong when fetching existing details of existing schedule with name ${scheduleName}...`
            );
            console.error(error);
        }
    };

    const runCreateScheduleCommand = async (
        jobid: string,
        name: string,
        description: string,
        scheduleRate: string
    ) => {

        //// Get StartDate: Should be next UTC timepoint where modulus 3h = 0
        // potentialStartDatestamps will start from utc midnight
        const potentialStartDatestamps = [dayjs.utc().startOf('day')];

        // add datestamps for every 3 hours
        for (let i = 3; i <= 24; i += 3) {
            potentialStartDatestamps.push(potentialStartDatestamps[0].add(i, 'hour'))
        }

        // Now, find the date which is closest to the current UTC date, but also in the future
        const closestPotentialDatestamp = potentialStartDatestamps.reduce((prev, curr) => {

            const diffPrev = prev.diff(dayjs.utc(), 'minute');
            const diffCurr = curr.diff(dayjs.utc(), 'minute');

            // console.log('prev: ', prev)
            // console.log('diffPrev: ', diffPrev)
            // console.log('curr: ', curr)
            // console.log('diffCurr: ', diffCurr)
            
            // Check if the current date is more than 5 minutes ahead of the current time
            if (dayjs.utc().isBefore(curr)) {
                return Math.abs(diffCurr) < Math.abs(diffPrev) ? curr : prev;
            } else {
                return prev;
            }
        });

        console.log('closestPotentialDatestamp.toDate(): ', typeof closestPotentialDatestamp.toDate(), closestPotentialDatestamp.toDate())

        const input = {
            Name: name,
            Description: description,
            // GroupName: 'dafault',
            FlexibleTimeWindow: {
                Mode: FlexibleTimeWindowMode.OFF,
            },
            ScheduleExpression: `rate(${scheduleRate} hours)`,
            // StartDate: dayjs().utc().add(1, "minute").toDate(), // next minute // for testing
            // StartDate: dayjs.utc().add(1, 'day').startOf('day').toDate(), // next UTC midnight
            StartDate: closestPotentialDatestamp.toDate(), // next UTC timepoint where modulus 3h = 0
            Target: {
                Arn: "arn:aws:ecs:us-east-1:348482033654:cluster/dwapi-EcsCluster-NQNzVpomowO9",
                RoleArn:
                    "arn:aws:iam::348482033654:role/dwapi-ModuleMcpMlScheduleCreationRole-0jOq29JN3bzm",
                // RoleArn: "arn:aws:iam::348482033654:role/test",
                EcsParameters: {
                    LaunchType: LaunchType.FARGATE,
                    NetworkConfiguration: {
                        awsvpcConfiguration: {
                            SecurityGroups: ["sg-029fe4ad2e115f887"],
                            Subnets: ["subnet-01ef9e932cd8d4ee5"],
                            AssignPublicIp: AssignPublicIp.ENABLED,
                        },
                    },
                    TaskDefinitionArn:
                        "arn:aws:ecs:us-east-1:348482033654:task-definition/mcp-ml4",
                    Tags: [
                        {
                            // The metadata that you apply to the task to help you categorize and organize them.
                            jobid: jobid,
                        },
                    ],
                },
                RetryPolicy: {
                    // MaximumEventAgeInSeconds: Number("int"),
                    MaximumRetryAttempts: 1,
                },
                Input: `{"containerOverrides": [{"name": "module", "command": ["python3", "main.py", "${jobid}"]}]}`,
                // Input: `{\"containerOverrides\": [{\"name\": \"module\", \"command\": [\"python3\", \"main.py\", \"${jobid}\"]}]}`,
            },
        };

        try {
            const command = new CreateScheduleCommand(input);
            const response = await schedulerClient.send(command);

            console.log("New schedule created: ", response);
            alert(`New job schedule created with ID ${jobid}`);
        } catch ({ name, message }) {
            if (message.includes("already exists")) {
                // If schedule already exists, ask user if they wish to update the existing schedule

                if (confirm(`${message}\n\n Do you want to update this schedule?`)) {
                    try {
                        const command = new UpdateScheduleCommand(input);
                        const response = await schedulerClient.send(command);

                        console.log("[LOG] Schedule was updated: ", response);

                        alert(`Job schedule with ID ${jobid} was updated`);
                    } catch (error) {
                        console.log(
                            "[ERROR] Something went wrong when updating the new schedule..."
                        );
                        console.error(error);

                        alert(error);
                    }
                }
            } else {
                // If the error was something else, log the error and alert the user.
                console.log("[ERROR] Something went wrong when creating the new schedule...");
                console.error(message);

                alert(`${name}: ${message}`);
            }
        }
    };

    const runDeleteScheduleCommand = async (name: string): Promise<any> => {
        const input = {
            Name: name, // required
            // GroupName: "STRING_VALUE",
            // ClientToken: "STRING_VALUE",
        };

        try {
            const command = new DeleteScheduleCommand(input);
            const response = await schedulerClient.send(command);

            console.log("Schedule deleted: ", response);

            return false;
        } catch (error) {
            console.log("[ERROR] Something went wrong when deleting the schedule...");
            console.error(error);
        }
    };

    return {
        runListSchedulesCommand,
        getExistingSchedulesDetails,
        getExistingSchedulesDetailsFromScheduleName,
        runCreateScheduleCommand,
        runDeleteScheduleCommand,
    };
};

export default useAwsSchedulerClient;
