import React from "react";

const legalClausesForDocuments =
    <React.Fragment>
        <h1>TERMS and CONDITIONS</h1>

        <ol type='1'>

            <h2><li>DEFINITION</li></h2>
                <ol type="A">
                    <li>COMPANY shall mean :</li>
                    <ol type="a">
                    
                    <li>Royal Dutch Shell plc and (ii) any company (other than the COMPANY), which is from time to time directly or indirectly controlled by Royal Dutch Shell plc. For this purpose:</li>
                    <ol type="i">
                        <li>a company is directly controlled by another company or companies if that latter company owns or those latter companies together own fifty percent (50%) or more of the voting rights attached to the issued share capital or ownership interest of the first mentioned company; and</li>
                        <li>a company is indirectly controlled by another company or companies if a series of companies can be specified, beginning with that latter company or companies, and ending with the first mentioned company, so related that each company of the series (except the latter company or companies) is directly controlled by one or more of the companies earlier in the series.</li>
                    </ol>
                    <li>Any company which has a service agreement with the COMPANY and/or another company as defined under (a) above pursuant to which it pays on a cost sharing or recovery basis a proportion of certain of the costs of the COMPANY or such other company. in respect of any company other than the COMPANY shall mean:</li>
                    <ol type="i">
                        <li>any corporation, partnership, trust, or other entity which beneficially holds fifty percent (50%) or more of the shares carrying the right to vote at a general meeting (or its equivalent) of such company, or fifty percent (50%) or more of the voting rights attached to the ownership interest of such company, and</li>
                        <li>any corporation, partnership, trust or other entity which such company controls, or any company which is controlled by a company which also controls such company, where “control” means to own, directly or indirectly, more than fifty percent (50%) of the shares carrying the right to vote at a general meeting (or its equivalent) of said company, or more than fifty percent (50%) of the voting rights attached to the ownership interest of said company.</li>
                    </ol>
                    </ol>
                </ol>

            <h2><li>SCOPE</li></h2>
                <ol type="A">
                    <li>I will only use the information in the document in connection with the support of the asset or project as I have indicated in the document request form.</li>
                    <li>I will only use the information in the document for the purpose I have indicated in the document request form.</li>
                </ol>

            <h2><li>CONFIDENTIALITY</li></h2>
                <ol type="A">
                    <li>I will not disclose or permit a disclosure of this document to a third party.</li>
                    <li>I must obtain written approval from COMPANY before using the content of this document for any external communication.</li>
                </ol>

            <h2><li>INTELLECTUAL PROPERTY RIGHTS</li></h2> 
                <ol type="A">
                    <li>COMPANY owns all  IP RIGHTS of work done in the document.</li>
                    <li>Without COMPANY’s consent, I shall not use or process any data, or any analysis generated in the document for the benefit of any third party.</li>
                </ol>
        </ol>
        

    </React.Fragment>


export default legalClausesForDocuments;