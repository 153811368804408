import "../../../style.css";

import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { nanoid } from "nanoid";
import NumberInput from "../../components/NumberInput";
import TextInput from "../../components/TextInput";
import Grid from "@mui/material/Unstable_Grid2";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AssignPublicIp, ECSClient, RunTaskCommand } from "@aws-sdk/client-ecs";
import dayjs from "dayjs";
import GoButton from "../../components/GoButton";
import { AuthContext } from "../../contexts/authContext";
import { Layout } from "../../layout";

export const TpxoConfig = () => {
    const { sessionInfo } = useContext(AuthContext);
    const { ensureAwsCredentials } = useContext(AuthContext);
    const { lat, lon } = useParams();

    // User inputs
    const [longitude, setLongitude] = useState(lon ? Number(lon).toFixed(6) : "");
    const [latitude, setLatitude] = useState(lat ? Number(lat).toFixed(6) : "");
    const [dateStart, setDateStart] = useState<any>(dayjs().startOf("day")); // initalise as today
    const [dateEnd, setDateEnd] = useState<any>(dayjs().startOf("day").add(7, "day")); // initalise as today + 7 seven days
    const [emailRecipient, setEmailRecipient] = useState(sessionInfo.email);
    const [locationName, setLocationName] = useState("");

    // Validation
    const [longitudeError, setLongitudeError] = useState(false);
    const [latitudeError, setLatitudeError] = useState(false);
    const [dateStartError, setDateStartError] = useState(false);
    const [dateEndError, setDateEndError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [locationNameError, setLocationNameError] = useState(false);

    function validateInputs() {
        console.log("Validating inputs...");

        const specialCharacters = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

        // Reset errors at the beginning of each validation
        let valid = true;
        setLongitudeError(false);
        setLatitudeError(false);
        setDateStartError(false);
        setDateEndError(false);
        setEmailError(false);
        setLocationNameError(false);

        // Validate
        if (longitude === "" || parseFloat(longitude) < -180 || parseFloat(longitude) > 180) {
            setLongitudeError(true);
            valid = false;
        }

        if (latitude === "" || parseFloat(latitude) < -90 || parseFloat(latitude) > 90) {
            setLatitudeError(true);
            valid = false;
        }

        if (
            dateStart === "" ||
            dayjs(dateStart).isAfter(dayjs(dateEnd)) ||
            dayjs(dateStart).isSame(dayjs(dateEnd))
        ) {
            setDateStartError(true);
            valid = false;
        }

        if (
            dateEnd === "" ||
            dayjs(dateEnd).isBefore(dayjs(dateStart)) ||
            dayjs(dateEnd).isSame(dayjs(dateStart))
        ) {
            setDateEndError(true);
            valid = false;
        }

        if (
            emailRecipient === "" ||
            !emailRecipient.includes("@") ||
            !emailRecipient.includes(".")
        ) {
            setEmailError(true);
            valid = false;
        }

        if (locationName === "" || specialCharacters.test(locationName)) {
            setLocationNameError(true);
            valid = false;
        }

        // Raise alert if relevant
        if (!valid) {
            alert("Some values are invalid. Please check your inputs.");
            return false;
        } else {
            return true;
        }
    }

    const saveInputs = async () => {
        console.log("Saving...");

        const jobid = nanoid();
        console.log(`[LOG] Saving for jobid=${jobid}...`);

        // Configure AWS clients
        const awsConfig = await ensureAwsCredentials();

        const s3Client = new S3Client({
            credentials: awsConfig.credentials,
            region: awsConfig.region
        });

        const ecsClient = new ECSClient({
            credentials: awsConfig.credentials,
            region: awsConfig.region
        });

        // data source
        const key_data_source = "modules/tpxo/jobs/" + jobid + "/config.json";

        const body_data_source = JSON.stringify({
            email_recipients: [emailRecipient],
            pLat: latitude,
            pLon: longitude,
            stDate: dayjs(dateStart).format("YYYY-MM-DDTHH:mm:ss"),
            edDate: dayjs(dateEnd).format("YYYY-MM-DDTHH:mm:ss"),
            locationName: locationName,
        });

        await s3Client.send(
            new PutObjectCommand({
                Bucket: "shell-dw-module-output",
                Key: key_data_source,
                Body: body_data_source,
            })
        );

        alert(
            "New job created with ID " +
            jobid +
            "\n\n" +
            "Results will be sent to " +
            emailRecipient
        );

        const response = await ecsClient.send(
            new RunTaskCommand({
                taskDefinition: "tpxo",
                cluster:
                    "arn:aws:ecs:us-east-1:348482033654:cluster/dwapi-EcsCluster-NQNzVpomowO9",
                launchType: "FARGATE",
                networkConfiguration: {
                    awsvpcConfiguration: {
                        securityGroups: ["sg-029fe4ad2e115f887"],
                        subnets: ["subnet-01ef9e932cd8d4ee5"],
                        assignPublicIp: AssignPublicIp.ENABLED,
                    },
                },
                overrides: {
                    containerOverrides: [
                        {
                            name: "module",
                            command: ["python3", "main.py", jobid],
                        },
                    ],
                },
            })
        );
    };

    const content = (
        <div className="module-page">
            <p className="module-guide-text">
                This module predicts <strong>tidal water level</strong>,{" "}
                <strong>tidal current speed</strong>, and <strong>tidal current direction</strong>{" "}
                at the <strong>Latitude</strong> and <strong>Longitude</strong> for the period
                between <strong>Start date</strong> and <strong>End date</strong>. A link to
                download the results is sent to the <strong>E-mail recipient</strong>.
            </p>
            <Grid container spacing={2} justifyContent="center" columns={{ xs: 6, sm: 12 }}>
                <Grid xs={6}>
                    <NumberInput
                        name_parameter={"Latitude"}
                        unit={"deg"}
                        defaultValue={latitude}
                        step="0.000001"
                        onChange={(ev) => setLatitude(ev)}
                        error={latitudeError}
                    />
                </Grid>
                <Grid xs={6}>
                    <NumberInput
                        name_parameter={"Longitude"}
                        unit={"deg"}
                        defaultValue={longitude}
                        step="0.000001"
                        onChange={(ev) => setLongitude(ev)}
                        error={longitudeError}
                    />
                </Grid>
                <Grid xs={6}>
                    <FormControl variant="outlined" error={dateStartError} className="input-box">
                        <FormHelperText id="outlined-weight-helper-text">
                            Start date
                        </FormHelperText>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                defaultValue={dateStart}
                                // minDate={minPickableDate}
                                // maxDate={maxPickableDate}
                                format="YYYY-MM-DD"
                                views={["year", "month", "day"]}
                                onChange={(ev) => setDateStart(ev)}
                                slotProps={{ textField: { fullWidth: true } }}
                            />
                        </LocalizationProvider>
                    </FormControl>
                </Grid>
                <Grid xs={6}>
                    <FormControl variant="outlined" error={dateEndError} className="input-box">
                        <FormHelperText id="outlined-weight-helper-text">End date</FormHelperText>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                defaultValue={dateEnd}
                                // minDate={minPickableDate}
                                // maxDate={maxPickableDate}
                                format="YYYY-MM-DD"
                                views={["year", "month", "day"]}
                                onChange={(ev) => setDateEnd(ev)}
                                slotProps={{ textField: { fullWidth: true } }}
                            />
                        </LocalizationProvider>
                    </FormControl>
                </Grid>
                <Grid xs={6}>
                    <TextInput
                        name_parameter={"Location name"}
                        onChange={(ev) => setLocationName(ev)}
                        error={locationNameError}
                        value={locationName}
                    />
                </Grid>
                <Grid xs={6}>
                    <TextInput
                        name_parameter={"E-mail recipient"}
                        onChange={(ev) => setEmailRecipient(ev)}
                        error={emailError}
                        value={emailRecipient}
                    />
                </Grid>
            </Grid>
            <br />
            <br />
            <GoButton validateInputs={validateInputs} saveInputs={saveInputs} />
        </div>
    );

    return <Layout content={content} moduleName="TPXO" />;
};
