import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { FormControl, FormHelperText} from '@mui/material/';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';

function getStyles(name: string, personName: readonly string[], theme: Theme) {
    return {
        fontWeight:
        personName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
    };
}

interface Props {
    onChange: (right: readonly string[]) => void;
    name_parameter: string;
    namesOptions: string[];
    error: boolean
}

export default function MultipleSelectChip(props: Props) {

    const theme = useTheme();
    const [optionName, setOptionName] = React.useState<string[]>([]);

    const handleChange = (event: SelectChangeEvent<typeof optionName>) => {
        const {
            target: { value },
        } = event;
        setOptionName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        props.onChange(value);
    };

    return (
    <FormControl variant="outlined" error={props.error} className="input-box">
        <FormHelperText id="outlined-weight-helper-text">
            {props.name_parameter}
        </FormHelperText>
        <Select
            sx={{height: 54}}
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={optionName}
            onChange={handleChange}
            input={<OutlinedInput id="select-multiple-chip" />}
            renderValue={(selected) => (
            <Box sx={{ display: 'flex', gap: 0.5}}>
                {selected.map((value) => (
                <Chip key={value} label={value} />
                ))}
            </Box>
            )}
        >
            {props.namesOptions.map((name) => (
            <MenuItem
                key={name}
                value={name}
                style={getStyles(name, optionName, theme)}
            >
                {name}
            </MenuItem>
            ))}
        </Select>
    </FormControl>
    );
}