import "../../style.css";

import React, { ReactElement } from "react";
import { FormControl, FormHelperText, OutlinedInput } from "@mui/material";

interface Props {
    onChange: (right: string) => void;
    name_parameter: string;
    error: boolean;
    disabled?: boolean;
    value?: string;
}

const TextInput = (props: Props): ReactElement => {

    console.log('props.disabled', props.disabled)

    const { name_parameter, error } = props;

    return (
        <FormControl variant="outlined" error={error} className="input-box">
            <FormHelperText id="outlined-weight-helper-text">
                {name_parameter}
            </FormHelperText>
            <OutlinedInput
                required
                type="text"
                onChange={(ev) => props.onChange(ev.target.value)}
                fullWidth={true}
                disabled={props.disabled || false}
                value={props.value || ''}
            />
        </FormControl>
    )
}

export default TextInput