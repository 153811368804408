import { requestBuilder as rb } from "@smithy/core";
import { _json, collectBody, decorateServiceException as __decorateServiceException, expectNonNull as __expectNonNull, expectNumber as __expectNumber, expectObject as __expectObject, expectString as __expectString, map, parseEpochTimestamp as __parseEpochTimestamp, take, withBaseException, } from "@smithy/smithy-client";
import { v4 as generateIdempotencyToken } from "uuid";
import { ConflictException, InternalServerException, ResourceNotFoundException, ServiceQuotaExceededException, ThrottlingException, ValidationException, } from "../models/models_0";
import { SchedulerServiceException as __BaseException } from "../models/SchedulerServiceException";
export const se_CreateScheduleCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/schedules/{Name}");
    b.p("Name", () => input.Name, "{Name}", false);
    let body;
    body = JSON.stringify(take(input, {
        ActionAfterCompletion: [],
        ClientToken: [true, (_) => _ ?? generateIdempotencyToken()],
        Description: [],
        EndDate: (_) => Math.round(_.getTime() / 1000),
        FlexibleTimeWindow: (_) => _json(_),
        GroupName: [],
        KmsKeyArn: [],
        ScheduleExpression: [],
        ScheduleExpressionTimezone: [],
        StartDate: (_) => Math.round(_.getTime() / 1000),
        State: [],
        Target: (_) => _json(_),
    }));
    b.m("POST").h(headers).b(body);
    return b.build();
};
export const se_CreateScheduleGroupCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/schedule-groups/{Name}");
    b.p("Name", () => input.Name, "{Name}", false);
    let body;
    body = JSON.stringify(take(input, {
        ClientToken: [true, (_) => _ ?? generateIdempotencyToken()],
        Tags: (_) => _json(_),
    }));
    b.m("POST").h(headers).b(body);
    return b.build();
};
export const se_DeleteScheduleCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/schedules/{Name}");
    b.p("Name", () => input.Name, "{Name}", false);
    const query = map({
        [_gN]: [, input[_GN]],
        [_cT]: [, input[_CT] ?? generateIdempotencyToken()],
    });
    let body;
    b.m("DELETE").h(headers).q(query).b(body);
    return b.build();
};
export const se_DeleteScheduleGroupCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/schedule-groups/{Name}");
    b.p("Name", () => input.Name, "{Name}", false);
    const query = map({
        [_cT]: [, input[_CT] ?? generateIdempotencyToken()],
    });
    let body;
    b.m("DELETE").h(headers).q(query).b(body);
    return b.build();
};
export const se_GetScheduleCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/schedules/{Name}");
    b.p("Name", () => input.Name, "{Name}", false);
    const query = map({
        [_gN]: [, input[_GN]],
    });
    let body;
    b.m("GET").h(headers).q(query).b(body);
    return b.build();
};
export const se_GetScheduleGroupCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/schedule-groups/{Name}");
    b.p("Name", () => input.Name, "{Name}", false);
    let body;
    b.m("GET").h(headers).b(body);
    return b.build();
};
export const se_ListScheduleGroupsCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/schedule-groups");
    const query = map({
        [_NP]: [, input[_NP]],
        [_NT]: [, input[_NT]],
        [_MR]: [() => input.MaxResults !== void 0, () => input[_MR].toString()],
    });
    let body;
    b.m("GET").h(headers).q(query).b(body);
    return b.build();
};
export const se_ListSchedulesCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/schedules");
    const query = map({
        [_SG]: [, input[_GN]],
        [_NP]: [, input[_NP]],
        [_S]: [, input[_S]],
        [_NT]: [, input[_NT]],
        [_MR]: [() => input.MaxResults !== void 0, () => input[_MR].toString()],
    });
    let body;
    b.m("GET").h(headers).q(query).b(body);
    return b.build();
};
export const se_ListTagsForResourceCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/tags/{ResourceArn}");
    b.p("ResourceArn", () => input.ResourceArn, "{ResourceArn}", false);
    let body;
    b.m("GET").h(headers).b(body);
    return b.build();
};
export const se_TagResourceCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/tags/{ResourceArn}");
    b.p("ResourceArn", () => input.ResourceArn, "{ResourceArn}", false);
    let body;
    body = JSON.stringify(take(input, {
        Tags: (_) => _json(_),
    }));
    b.m("POST").h(headers).b(body);
    return b.build();
};
export const se_UntagResourceCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {};
    b.bp("/tags/{ResourceArn}");
    b.p("ResourceArn", () => input.ResourceArn, "{ResourceArn}", false);
    const query = map({
        [_TK]: [
            __expectNonNull(input.TagKeys, `TagKeys`) != null,
            () => (input[_TK] || []).map((_entry) => _entry),
        ],
    });
    let body;
    b.m("DELETE").h(headers).q(query).b(body);
    return b.build();
};
export const se_UpdateScheduleCommand = async (input, context) => {
    const b = rb(input, context);
    const headers = {
        "content-type": "application/json",
    };
    b.bp("/schedules/{Name}");
    b.p("Name", () => input.Name, "{Name}", false);
    let body;
    body = JSON.stringify(take(input, {
        ActionAfterCompletion: [],
        ClientToken: [true, (_) => _ ?? generateIdempotencyToken()],
        Description: [],
        EndDate: (_) => Math.round(_.getTime() / 1000),
        FlexibleTimeWindow: (_) => _json(_),
        GroupName: [],
        KmsKeyArn: [],
        ScheduleExpression: [],
        ScheduleExpressionTimezone: [],
        StartDate: (_) => Math.round(_.getTime() / 1000),
        State: [],
        Target: (_) => _json(_),
    }));
    b.m("PUT").h(headers).b(body);
    return b.build();
};
export const de_CreateScheduleCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ScheduleArn: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_CreateScheduleGroupCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ScheduleGroupArn: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_DeleteScheduleCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
export const de_DeleteScheduleGroupCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
export const de_GetScheduleCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ActionAfterCompletion: __expectString,
        Arn: __expectString,
        CreationDate: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        Description: __expectString,
        EndDate: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        FlexibleTimeWindow: _json,
        GroupName: __expectString,
        KmsKeyArn: __expectString,
        LastModificationDate: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        Name: __expectString,
        ScheduleExpression: __expectString,
        ScheduleExpressionTimezone: __expectString,
        StartDate: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        State: __expectString,
        Target: _json,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_GetScheduleGroupCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Arn: __expectString,
        CreationDate: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        LastModificationDate: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        Name: __expectString,
        State: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_ListScheduleGroupsCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        NextToken: __expectString,
        ScheduleGroups: (_) => de_ScheduleGroupList(_, context),
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_ListSchedulesCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        NextToken: __expectString,
        Schedules: (_) => de_ScheduleList(_, context),
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_ListTagsForResourceCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Tags: _json,
    });
    Object.assign(contents, doc);
    return contents;
};
export const de_TagResourceCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
export const de_UntagResourceCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
export const de_UpdateScheduleCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_CommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ScheduleArn: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
const de_CommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ConflictException":
        case "com.amazonaws.scheduler#ConflictException":
            throw await de_ConflictExceptionRes(parsedOutput, context);
        case "InternalServerException":
        case "com.amazonaws.scheduler#InternalServerException":
            throw await de_InternalServerExceptionRes(parsedOutput, context);
        case "ResourceNotFoundException":
        case "com.amazonaws.scheduler#ResourceNotFoundException":
            throw await de_ResourceNotFoundExceptionRes(parsedOutput, context);
        case "ServiceQuotaExceededException":
        case "com.amazonaws.scheduler#ServiceQuotaExceededException":
            throw await de_ServiceQuotaExceededExceptionRes(parsedOutput, context);
        case "ThrottlingException":
        case "com.amazonaws.scheduler#ThrottlingException":
            throw await de_ThrottlingExceptionRes(parsedOutput, context);
        case "ValidationException":
        case "com.amazonaws.scheduler#ValidationException":
            throw await de_ValidationExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
const throwDefaultError = withBaseException(__BaseException);
const de_ConflictExceptionRes = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    const doc = take(data, {
        Message: __expectString,
    });
    Object.assign(contents, doc);
    const exception = new ConflictException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const de_InternalServerExceptionRes = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    const doc = take(data, {
        Message: __expectString,
    });
    Object.assign(contents, doc);
    const exception = new InternalServerException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const de_ResourceNotFoundExceptionRes = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    const doc = take(data, {
        Message: __expectString,
    });
    Object.assign(contents, doc);
    const exception = new ResourceNotFoundException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const de_ServiceQuotaExceededExceptionRes = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    const doc = take(data, {
        Message: __expectString,
    });
    Object.assign(contents, doc);
    const exception = new ServiceQuotaExceededException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const de_ThrottlingExceptionRes = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    const doc = take(data, {
        Message: __expectString,
    });
    Object.assign(contents, doc);
    const exception = new ThrottlingException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const de_ValidationExceptionRes = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    const doc = take(data, {
        Message: __expectString,
    });
    Object.assign(contents, doc);
    const exception = new ValidationException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const de_ScheduleGroupList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_ScheduleGroupSummary(entry, context);
    });
    return retVal;
};
const de_ScheduleGroupSummary = (output, context) => {
    return take(output, {
        Arn: __expectString,
        CreationDate: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        LastModificationDate: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        Name: __expectString,
        State: __expectString,
    });
};
const de_ScheduleList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_ScheduleSummary(entry, context);
    });
    return retVal;
};
const de_ScheduleSummary = (output, context) => {
    return take(output, {
        Arn: __expectString,
        CreationDate: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        GroupName: __expectString,
        LastModificationDate: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        Name: __expectString,
        State: __expectString,
        Target: _json,
    });
};
const deserializeMetadata = (output) => ({
    httpStatusCode: output.statusCode,
    requestId: output.headers["x-amzn-requestid"] ?? output.headers["x-amzn-request-id"] ?? output.headers["x-amz-request-id"],
    extendedRequestId: output.headers["x-amz-id-2"],
    cfId: output.headers["x-amz-cf-id"],
});
const collectBodyString = (streamBody, context) => collectBody(streamBody, context).then((body) => context.utf8Encoder(body));
const isSerializableHeaderValue = (value) => value !== undefined &&
    value !== null &&
    value !== "" &&
    (!Object.getOwnPropertyNames(value).includes("length") || value.length != 0) &&
    (!Object.getOwnPropertyNames(value).includes("size") || value.size != 0);
const _CT = "ClientToken";
const _GN = "GroupName";
const _MR = "MaxResults";
const _NP = "NamePrefix";
const _NT = "NextToken";
const _S = "State";
const _SG = "ScheduleGroup";
const _TK = "TagKeys";
const _cT = "clientToken";
const _gN = "groupName";
const parseBody = (streamBody, context) => collectBodyString(streamBody, context).then((encoded) => {
    if (encoded.length) {
        return JSON.parse(encoded);
    }
    return {};
});
const parseErrorBody = async (errorBody, context) => {
    const value = await parseBody(errorBody, context);
    value.message = value.message ?? value.Message;
    return value;
};
const loadRestJsonErrorCode = (output, data) => {
    const findKey = (object, key) => Object.keys(object).find((k) => k.toLowerCase() === key.toLowerCase());
    const sanitizeErrorCode = (rawValue) => {
        let cleanValue = rawValue;
        if (typeof cleanValue === "number") {
            cleanValue = cleanValue.toString();
        }
        if (cleanValue.indexOf(",") >= 0) {
            cleanValue = cleanValue.split(",")[0];
        }
        if (cleanValue.indexOf(":") >= 0) {
            cleanValue = cleanValue.split(":")[0];
        }
        if (cleanValue.indexOf("#") >= 0) {
            cleanValue = cleanValue.split("#")[1];
        }
        return cleanValue;
    };
    const headerKey = findKey(output.headers, "x-amzn-errortype");
    if (headerKey !== undefined) {
        return sanitizeErrorCode(output.headers[headerKey]);
    }
    if (data.code !== undefined) {
        return sanitizeErrorCode(data.code);
    }
    if (data["__type"] !== undefined) {
        return sanitizeErrorCode(data["__type"]);
    }
};
