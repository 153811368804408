{
  "pagination": {
    "ListAudienceExportJobs": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "audienceExportJobs"
    },
    "ListAudienceGenerationJobs": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "audienceGenerationJobs"
    },
    "ListAudienceModels": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "audienceModels"
    },
    "ListConfiguredAudienceModels": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "configuredAudienceModels"
    },
    "ListTrainingDatasets": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "trainingDatasets"
    }
  }
}
