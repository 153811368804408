import React, { useState, useEffect, useContext, useRef } from "react";

import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool, CognitoUserSession } from "amazon-cognito-identity-js";
import AWS from "aws-sdk";
import { useNavigate } from "react-router-dom";
import { OidcClient, User, UserManager } from "oidc-client-ts";

//// AWS specs
// Region needs to be set if not already set previously elsewhere.
AWS.config.region = "us-east-1";

// Identity pool specs
const identityPoolId = "us-east-1:e1b40222-8763-4f96-8b46-21af74cf90a7";

// User pool specs
export const userPoolData = {
    UserPoolId: "us-east-1_ZVu9npXYH",
    ClientId: "7j94pfo82gk79ua44bima1vupk",
};

const userPool: CognitoUserPool = new CognitoUserPool(userPoolData);

export enum AuthStatus {
    Loading,
    SignedIn,
    SignedOut,
}

export interface IAuth {
    /**
     * @returns Ensures that the default AWS credentials are valid.
     **/
    ensureAwsCredentials: () => Promise<Partial<AWS.Config>>;

    sessionInfo?: ISessionInfo;

    signOut: () => void;
}

interface ISessionInfo {
    email: string,
};

type Props = {
    children?: React.ReactNode;
};

export const AuthContext = React.createContext<IAuth>(undefined);

const logo_shell = new URL(
    "../../logos/logo_digital_weather_v2.png",
    import.meta.url
);

const AuthProvider = ({ children }: Props) => {

    const [user, setUser] = useState<User | undefined>(undefined);
    const [authStatus, setAuthStatus] = useState(AuthStatus.Loading);
    const client = useRef(new UserManager({
        authority: "https://auth.shellmetnetglobal.com/",
        //authority: "https://localhost:7031/",
        client_id: "dw",
        redirect_uri: window.location.href,
        response_type: "code",
    }));

    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            try {
                const user = await client.current.signinCallback();

                if (user) {
                    // Remove creds from address bar
                    navigate("/", {
                        replace: true
                    });

                    setUser(user);
                    setAuthStatus(AuthStatus.SignedIn);
                } else {
                    setAuthStatus(AuthStatus.SignedOut);
                    await client.current.signinRedirect();
                }
            } catch (ex) {
                setAuthStatus(AuthStatus.SignedOut);
                await client.current.signinRedirect();
            }
    })();
    }, []);

    if (authStatus === AuthStatus.Loading) {
        return <div style={{textAlign: "center", marginTop: 200}}>
            <div>
                <img src={String(logo_shell)} style={{width: 300}}/>
            </div>
            <div style={{marginTop: 20}}>
            Checking authentication status. Please wait...
            </div>
        </div>;
    }

    if (authStatus === AuthStatus.SignedOut) {
        const login = async () => {
            await client.current.signinRedirect();
        }

        if (authStatus === AuthStatus.SignedOut) {
            return <div style={{textAlign: "center", marginTop: 200}}>
                <div>
                    <img src={String(logo_shell)} style={{width: 300}}/>
                </div>
                <div>
                <button style={{fontSize: 20, marginTop: 20}} onClick={login}>Log in</button>
                </div>
            </div>;
        }
    }

    if (authStatus === AuthStatus.SignedIn) {
        const signOut = () => {
            setAuthStatus(AuthStatus.SignedOut);
        }

        const state: IAuth = {
            ensureAwsCredentials: () => ensureAwsCredentials(user.id_token),
            sessionInfo: {
                email: user.profile.sub
            },
            signOut,
        };

        return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
    }
};

export default AuthProvider;

async function ensureAwsCredentials(idToken: string): Promise<Partial<AWS.Config>> {
    ///// Get temporary AWS credentials
    // check: https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/CognitoIdentityCredentials.html
    const newCredentials = new AWS.CognitoIdentityCredentials({
        // AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: identityPoolId,
        Logins: {
            "auth.shellmetnetglobal.com": idToken,
        },
    });
    await new Promise<void>((resolve, reject) => newCredentials.get(err => {
        if (err) {
            console.error("Failed to obtain AWS credentials", err);
            reject(err);
        } else {
            console.log("Obtained AWS credentials");
            resolve();
        }
    }));

    return {
        credentials: newCredentials,
        region: "us-east-1"
    };
}