import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import { Checkbox, FormControl, FormHelperText, ListItemText} from '@mui/material/';
import Select, { SelectChangeEvent } from '@mui/material/Select';

function getStyles(option: string, theme: Theme) {
    return {
        fontWeight: option === 'All' ? 700 : theme.typography.fontWeightRegular,
        borderBottom: option === 'All' ? '0.1px solid #e6e6e6' : '',
    };
}

interface Props {
    nameParameter: string;
    onChange: (right: string[]) => void;
    options: string[];
    multiple: boolean;
    error: boolean;
    disabled: boolean;
    changingVariable: string[];
}

export default function SelectItems(props: Props) {

    console.log(`props.changingVariable for ${props.nameParameter}`, props.changingVariable)

    const theme = useTheme();

    // check if all was selected at the point of the most recent rendering
    const wasAllSelected = props.options.every(
        opt => props.changingVariable.includes(opt));

    const handleChange = (event: SelectChangeEvent) => {
        
        // strArray of selected values
        const selectedOptions = typeof event.target.value === 'string' ? [event.target.value] : event.target.value;

        console.log('selectedOptions: ', selectedOptions)

        let actualOptions: string[];

        if (props.multiple) {
            if (selectedOptions.includes("All")) {
                if (!wasAllSelected) {
                    // User just ticked "All", so select everything
                    actualOptions = [...props.options];
                } else {
                    // User just unticked "All", so deselect everything
                    actualOptions = [];
                }
            } else {
                // "All" wasn't selected and isn't being selected now either, apply specific selections
                actualOptions = selectedOptions;
            }
        } else {
            actualOptions = selectedOptions;            
        }

        console.log('actualOptions: ', actualOptions)

        props.onChange(actualOptions);
    };

    return (
        <FormControl variant="outlined" error={props.error} className="input-box">
            <FormHelperText id="outlined-weight-helper-text">
                {props.nameParameter}
            </FormHelperText>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                multiple={props.multiple}
                value={props.changingVariable}
                onChange={handleChange}
                disabled={props.disabled}
                renderValue={(selected) => props.multiple ? selected.sort().join(', ') : selected}
                className="input-box"
            >
                {props.multiple && <MenuItem
                    value="All"
                    style={getStyles("All", theme)}
                >
                    <Checkbox checked={wasAllSelected} />
                    All
                </MenuItem>}
                {props.options.map((option) => (
                    <MenuItem
                        key={option}
                        value={option}
                        style={getStyles(option, theme)}
                    >
                        {props.multiple ? <Checkbox checked={props.changingVariable.includes(option)} /> : ''}
                        {option}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
        );
    };