import "mapbox-gl/dist/mapbox-gl.css";
import "../../../style.css";

import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import {
    addPopup,
    closeAllPopups,
    loadIconImages,
    createLayersFromDocumentCatalog,
    getDocumentTag,
    createLayerFromAreasCatalog,
    setDocumentSourceData,
    setAreaSourceData,
    convertDecimalDegreesToDegreesAndMinutes,
    gebcoColorMap,
    getFeatureIdsOiFromMapEvent,
} from "../../utils/Map";
import ICatalogEntry from "../../types/ICatalogEntry";
import areasCatalog from "../../utils/MetoceanPolygons";
import { mapStyle } from "../../utils/mapStyle";



mapboxgl.accessToken =
    "pk.eyJ1Ijoic2lib3dpIiwiYSI6ImNsczh6YjJkcTAxZjkybm9qeXRzNXg4aWkifQ.ZPA7hKSqRhnprJzPKXU-Zw";


    
interface Props {
    catalog: ICatalogEntry[],
    allDocumentLayerIds: string[],
    visibleLayerIds: string[],
    filteredVisibleCatalog: ICatalogEntry[],
    bboxChanged: (visibleQuery: ((catalogEntry: ICatalogEntry) => boolean)) => void,
    setClickedFeatureIds: (filter: number[]) => void,
    setRenderTableOrDescription: (filter: "Description" | "Table") => void,
    hoveredFeatureIds: number[],
    setHoveredFeatureIds: (filter: number[]) => void,
    centerCoordinates: [number, number],
}



export const MapConfig: React.FC<Props> = (props) => {

    // useRef will prevent the map from reloading when the user interacts with the map.
    const mapContainer = useRef(null);
    const map = useRef<mapboxgl.Map>(null);

    const [mouseLat, setMouseLat] = useState<number>();
    const [mouseLon, setMouseLon] = useState<number>();
    // Initialise map at this zoom level
    const [zoom, setZoom] = useState(1.91);
    
    //// Graticules
    const graticule = {
        type: "FeatureCollection",
        features: [],
    };
    // Degrees
        for (let lng = -170; lng <= 180; lng += 5) {
        graticule.features.push({
            type: "Feature",
            geometry: {
                type: "LineString",
                coordinates: [
                    [lng, -90],
                    [lng, 90],
                ],
            },
            properties: { value: lng },
        });
    }
    for (let lat = -80; lat <= 80; lat += 5) {
        graticule.features.push({
            type: "Feature",
            geometry: {
                type: "LineString",
                coordinates: [
                    [-180, lat],
                    [180, lat],
                ],
            },
            properties: { value: lat },
        });
    }
    // Minutes
    // const one_minute = 1 / 60;

    // for (let lng = -170; lng <= 180; lng += one_minute) {
    //     graticule.features.push({
    //         type: "Feature",
    //         geometry: {
    //             type: "LineString",
    //             coordinates: [
    //                 [lng, -90],
    //                 [lng, 90],
    //             ],
    //         },
    //         properties: { value: lng },
    //     });
    // }
    // for (let lat = -80; lat <= 80; lat += one_minute) {
    //     graticule.features.push({
    //         type: "Feature",
    //         geometry: {
    //             type: "LineString",
    //             coordinates: [
    //                 [-180, lat],
    //                 [180, lat],
    //             ],
    //         },
    //         properties: { value: lat },
    //     });
    // }

    useEffect(() => {
        // Run only once, and only when catalog has been fetched

        if (map.current) return;

        //// INITIALISE MAP
        // Initializing the map within the useEffect() ensures that Mapbox GL JS will not try to
        // render a map before React creates the element that contains the map
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            // style: "mapbox://styles/mapbox/empty-v8",
            // style: "mapbox://styles/mapbox/outdoors-v12",
            style: mapStyle,
            center: props.centerCoordinates,
            zoom: zoom,
            projection: "globe",
        });

        map.current.on("load", () => {
            // Fired immediately after all necessary resources have been downloaded and the first
            // visually complete rendering of the map has occurred.

            //// Load icon images
            loadIconImages(map.current, props.allDocumentLayerIds);

            //// Create document layers
            createLayersFromDocumentCatalog(
                props.filteredVisibleCatalog,
                map.current,
                props.allDocumentLayerIds,
                props.visibleLayerIds
            );

            //// Create polygon layer
            createLayerFromAreasCatalog(map.current, props.visibleLayerIds);

            //// Map layout
            // Add map base
            // map.current.addSource("World_Ocean_Base", {
            //     type: "raster",
            //     tiles: [
            //         "https://services.arcgisonline.com/arcgis/rest/services/Ocean/World_Ocean_Base/MapServer/tile/{z}/{y}/{x}",
            //     ],
            //     tileSize: 256,
            // });
            // map.current.addLayer(
            //     {
            //         id: "World_Ocean_Base",
            //         type: "raster",
            //         source: "World_Ocean_Base",
            //     },
            //     props.allDocumentLayerIds[0] // add layer before this layer to avoid hiding icons
            // );
            // // Add map references
            // map.current.addSource("World_Ocean_Reference", {
            //     type: "raster",
            //     tiles: [
            //         "https://services.arcgisonline.com/arcgis/rest/services/Ocean/World_Ocean_Reference/MapServer/tile/{z}/{y}/{x}",
            //     ],
            //     tileSize: 256,
            // });
            // map.current.addLayer(
            //     {
            //         id: "World_Ocean_Reference",
            //         type: "raster",
            //         source: "World_Ocean_Reference",
            //     },
            //     props.allDocumentLayerIds[0] // add layer before this layer to avoid hiding icons
            // );

            // Map layout: New
            map.current.addSource("gebco-wms", {
                type: "vector",
                url: "mapbox://mapbox.mapbox-bathymetry-v2"
            });
            map.current.addLayer(
                {
                    id: "gebco-wms",
                    type: "fill",
                    source: "gebco-wms",
                    "source-layer": 'depth',
                    'paint': {
                        // cubic bezier is a four point curve for smooth and precise styling
                        // adjust the points to change the rate and intensity of interpolation
                        'fill-color': JSON.stringify([
                            ...[
                                'interpolate',
                                ['cubic-bezier', 0, 0.5, 1, 0.5],
                                ['get', 'min_depth'],
                            ], 
                            ...gebcoColorMap
                        ])
                    }
                },
                // add layer before these layers to avoid hiding icons
                JSON.stringify([
                    props.allDocumentLayerIds[0], 
                    'housenum-label', // name labels from map style
                ])
            );

            //// Background layout
            // Specify globe background style
            map.current.setFog({
                "color": "rgb(186, 210, 235)", // Lower atmosphere
                "high-color": "rgb(36, 92, 223)", // Upper atmosphere
                "horizon-blend": 0.02, // Atmosphere thickness (default 0.2 at low zooms)
                "space-color": "rgb(11, 11, 25)", // Background color
                "star-intensity": 0.05, // Background star brightness (default 0.35 at low zoooms )
            });

            // Add graticules/gridlines
            map.current.addSource("graticule", {
                type: "geojson",
                data: graticule,
            });
            map.current.addLayer({
                id: "graticule",
                type: "line",
                source: "graticule",
                paint: {
                    // Change the color to red
                    "line-color": "#707070", 
                    // "line-color": "#FFF", 
                    "line-width": 1, // Adjust the width as needed
                    "line-opacity": 0.8, // Adjust opacity as needed
                },
            });

            //// Other features
            // Add compass
            const compassControl = new mapboxgl.NavigationControl({
                showCompass: true,
                showZoom: true,
                visualizePitch: true,
            });
            map.current.addControl(compassControl, "top-right");

            // Add a scale control to the map
            map.current.addControl(new mapboxgl.ScaleControl({ maxWidth: 150 }));
        });

        //// DOCUMENT LAYER ICON ACTIONS
        // map.current.on("mouseenter", [...props.allDocumentLayerIds, "Cluster"], (e) => {
        map.current.on("mousemove", [...props.allDocumentLayerIds, "Cluster"], (e) => {

            // Change the cursor to a pointer when the mouse enters an icon.
            map.current.getCanvas().style.cursor = "pointer";

            const featureIdsOi = getFeatureIdsOiFromMapEvent(e.features)

            props.setHoveredFeatureIds(featureIdsOi)
        });

        // map.current.on("mouseleave", props.allDocumentLayerIds, () => {
        map.current.on(
            "mouseleave",
            [...props.allDocumentLayerIds, "Cluster", "widenedOutlinedAreas"],
            () => {

                // Change curser back to a pointer when it leaves a rendered icon.
                map.current.getCanvas().style.cursor = "";

                // Close all popups
                closeAllPopups();

                props.setHoveredFeatureIds([]);
            }
        );

        map.current.on("click", [...props.allDocumentLayerIds, "Cluster"], (e) => {

            const featureIdsOi = getFeatureIdsOiFromMapEvent(e.features)

            props.setClickedFeatureIds(featureIdsOi)    
            props.setRenderTableOrDescription('Description');

        });

        //// CLUSTER ICON ACTIONS
        map.current.on("mouseenter", "Cluster", (e) => {

            // Close all popups
            closeAllPopups();

            // Change the cursor to a pointer when the mouse is enters an icon from the Cluster layer.
            map.current.getCanvas().style.cursor = "pointer";
        });

        // RENDER POP-UP FOR NAVIGATING TO OTHER MODULES
        map.current.on("click", (e) => {
            // check if click was on an icon
            const features = map.current.queryRenderedFeatures(e.point, {
                layers: props.allDocumentLayerIds,
            });

            // if click was not on an icon, render pop-up menu
            if (features.length === 0) {

                // Make sure to be in table-view
                props.setRenderTableOrDescription("Table")

                new mapboxgl.Popup()
                    .setLngLat([e.lngLat.lng, e.lngLat.lat])
                    .setHTML(
                        `
                            <div>(${e.lngLat.lat.toFixed(6)}, ${e.lngLat.lng.toFixed(6)})</div>
                            </br>
                            <strong>Available modules:</strong>
                            <ul style="padding-left:20px">
                                <li><a href="/mm2dlp/${e.lngLat.lat}/${
                            e.lngLat.lng
                        }" style="color: black">Meteomatics2dlp</a></li>
                                <li><a href="/mre/${e.lngLat.lat}/${
                            e.lngLat.lng
                        }" style="color: black">MRE</a></li>
                                <li><a href="/tpxo/${e.lngLat.lat}/${
                            e.lngLat.lng
                        }" style="color: black">TPXO</a></li>
                            </ul>
                        `
                    )
                    .addTo(map.current);
            }
        });

        //// AREA LAYER ICON ACTIONS
        map.current.on("mouseenter", "widenedOutlinedAreas", (e) => {
            // Close all popups
            closeAllPopups();

            // Change the cursor to a pointer when the mouse enters an icon.
            map.current.getCanvas().style.cursor = "pointer";

            console.log("[LOG] mouseenter-event for widenedOutlinedAreas: ", e);

            // get coordinates of the event
            let eventLongitude = e.lngLat.lng;
            let eventLatitude = e.lngLat.lat;

            // Create feature tags for each feature/document involved in the mouseenter-event
            let information = getDocumentTag(
                e.features[0]["properties"]["name"],
                "YYYY",
                e.features[0]["properties"]["layerId"]
            );

            // Render popup with the specified information
            addPopup(map.current, eventLatitude, eventLongitude, information);
        });

        map.current.on("move", (e) => {
            props.bboxChanged(catalogEntry => 
                !isNaN(catalogEntry.Longitude)
                && !isNaN(catalogEntry.Latitude)
                && map.current.getBounds().contains(
                [catalogEntry.Longitude, catalogEntry.Latitude]
            ));
        });

        map.current.on("mousemove", (e) => {
            // get current specs of cursor position
            setMouseLon(e.lngLat.lng);
            setMouseLat(e.lngLat.lat);
        });

        // Add the control to the map.
        map.current.addControl(
            new MapboxGeocoder({
                accessToken: mapboxgl.accessToken,
                mapboxgl: mapboxgl,
            })
        );
    });

    useEffect(() => {
        if (
            !map.current 
            || !map.current.getSource("Documents") 
            || !map.current.getSource("OverlappingDocuments")
        ) return; 

        setDocumentSourceData(
            "Documents",
            props.filteredVisibleCatalog,
            map.current,
            props.visibleLayerIds
        );

        setDocumentSourceData(
            "OverlappingDocuments",
            props.filteredVisibleCatalog,
            map.current,
            props.visibleLayerIds
        );

        setAreaSourceData(
            "Areas",
            areasCatalog,
            map.current,
            props.visibleLayerIds
        );

    }, [props.filteredVisibleCatalog]);

    useEffect(() => {
        if (
            !map.current 
            || !map.current.getSource("Documents") 
            || !map.current.getSource("OverlappingDocuments")
        ) return; 

        map.current.setCenter(props.centerCoordinates)
        map.current.setZoom(4.0)
        // also update the mouseCoordinates because these are displayed on the map
        setMouseLat(props.centerCoordinates[1])
        setMouseLon(props.centerCoordinates[0])

    }, [props.centerCoordinates]);    

    useEffect(() => {
        if (!map.current) return; 

        // get catalogEntries corresponding to the hoveredFeatureIds
        let hoveredCatalogEntries = props.catalog.filter( feature => props.hoveredFeatureIds.includes(feature.id));

        if (hoveredCatalogEntries.length === 0) {
            // if no featureIds were hovered, just close all
            closeAllPopups()
        } else {
            // else, generate the relevant popup
            closeAllPopups()

            // get coordinates of the hovered catalogEntry
            let longitude = hoveredCatalogEntries[0].Longitude;
            let latitude = hoveredCatalogEntries[0].Latitude;
            
            // Create document tags for each feature/document involved in the hovering
            const documentTags = [];

            for (var hoveredCatalogEntry of hoveredCatalogEntries) {

                let documentTag = getDocumentTag(
                    hoveredCatalogEntry["Name"],
                    hoveredCatalogEntry["Year"],
                    hoveredCatalogEntry["Type"]
                );

                documentTags.push(documentTag);
            }

            // Join the array of documents, and add <hr> to separate them.
            const information = documentTags.join("<hr>");

            addPopup(map.current, latitude, longitude, information); 
        }
    }, [props.hoveredFeatureIds]);

    //// SET PAGE CONTENT
    return (
        <div className="map-config">
            <div className="sidebar">
                Latitude: {
                    convertDecimalDegreesToDegreesAndMinutes(
                        mouseLat,
                        false
                    )
                } | 
                Longitude: {
                    convertDecimalDegreesToDegreesAndMinutes(
                        mouseLon,
                        true
                    )
                }
            </div>
            <div id="map" ref={mapContainer} className="map" />
        </div>
    );
}
