{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2021-12-01",
    "endpointPrefix": "verifiedpermissions",
    "jsonVersion": "1.0",
    "protocol": "json",
    "serviceFullName": "Amazon Verified Permissions",
    "serviceId": "VerifiedPermissions",
    "signatureVersion": "v4",
    "signingName": "verifiedpermissions",
    "targetPrefix": "VerifiedPermissions",
    "uid": "verifiedpermissions-2021-12-01"
  },
  "operations": {
    "BatchIsAuthorized": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "requests"
        ],
        "members": {
          "policyStoreId": {},
          "entities": {
            "shape": "S3"
          },
          "requests": {
            "type": "list",
            "member": {
              "shape": "Sj"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "results"
        ],
        "members": {
          "results": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "request",
                "decision",
                "determiningPolicies",
                "errors"
              ],
              "members": {
                "request": {
                  "shape": "Sj"
                },
                "decision": {},
                "determiningPolicies": {
                  "shape": "St"
                },
                "errors": {
                  "shape": "Sw"
                }
              }
            }
          }
        }
      }
    },
    "CreateIdentitySource": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "configuration"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "policyStoreId": {},
          "configuration": {
            "type": "structure",
            "members": {
              "cognitoUserPoolConfiguration": {
                "type": "structure",
                "required": [
                  "userPoolArn"
                ],
                "members": {
                  "userPoolArn": {},
                  "clientIds": {
                    "shape": "S13"
                  }
                }
              }
            },
            "union": true
          },
          "principalEntityType": {
            "shape": "S15"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createdDate",
          "identitySourceId",
          "lastUpdatedDate",
          "policyStoreId"
        ],
        "members": {
          "createdDate": {
            "shape": "S17"
          },
          "identitySourceId": {},
          "lastUpdatedDate": {
            "shape": "S17"
          },
          "policyStoreId": {}
        }
      },
      "idempotent": true
    },
    "CreatePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "definition"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "policyStoreId": {},
          "definition": {
            "type": "structure",
            "members": {
              "static": {
                "type": "structure",
                "required": [
                  "statement"
                ],
                "members": {
                  "description": {
                    "shape": "S1c"
                  },
                  "statement": {
                    "shape": "S1d"
                  }
                }
              },
              "templateLinked": {
                "type": "structure",
                "required": [
                  "policyTemplateId"
                ],
                "members": {
                  "policyTemplateId": {},
                  "principal": {
                    "shape": "S6"
                  },
                  "resource": {
                    "shape": "S6"
                  }
                }
              }
            },
            "union": true
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "policyId",
          "policyType",
          "createdDate",
          "lastUpdatedDate"
        ],
        "members": {
          "policyStoreId": {},
          "policyId": {},
          "policyType": {},
          "principal": {
            "shape": "S6"
          },
          "resource": {
            "shape": "S6"
          },
          "createdDate": {
            "shape": "S17"
          },
          "lastUpdatedDate": {
            "shape": "S17"
          }
        }
      },
      "idempotent": true
    },
    "CreatePolicyStore": {
      "input": {
        "type": "structure",
        "required": [
          "validationSettings"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "validationSettings": {
            "shape": "S1j"
          },
          "description": {
            "shape": "S1l"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "arn",
          "createdDate",
          "lastUpdatedDate"
        ],
        "members": {
          "policyStoreId": {},
          "arn": {},
          "createdDate": {
            "shape": "S17"
          },
          "lastUpdatedDate": {
            "shape": "S17"
          }
        }
      },
      "idempotent": true
    },
    "CreatePolicyTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "statement"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "policyStoreId": {},
          "description": {
            "shape": "S1p"
          },
          "statement": {
            "shape": "S1d"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "policyTemplateId",
          "createdDate",
          "lastUpdatedDate"
        ],
        "members": {
          "policyStoreId": {},
          "policyTemplateId": {},
          "createdDate": {
            "shape": "S17"
          },
          "lastUpdatedDate": {
            "shape": "S17"
          }
        }
      },
      "idempotent": true
    },
    "DeleteIdentitySource": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "identitySourceId"
        ],
        "members": {
          "policyStoreId": {},
          "identitySourceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeletePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "policyId"
        ],
        "members": {
          "policyStoreId": {},
          "policyId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeletePolicyStore": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId"
        ],
        "members": {
          "policyStoreId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeletePolicyTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "policyTemplateId"
        ],
        "members": {
          "policyStoreId": {},
          "policyTemplateId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "GetIdentitySource": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "identitySourceId"
        ],
        "members": {
          "policyStoreId": {},
          "identitySourceId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createdDate",
          "identitySourceId",
          "lastUpdatedDate",
          "policyStoreId",
          "principalEntityType"
        ],
        "members": {
          "createdDate": {
            "shape": "S17"
          },
          "details": {
            "deprecated": true,
            "deprecatedMessage": "This shape has been replaced by ConfigurationDetail",
            "type": "structure",
            "members": {
              "clientIds": {
                "shape": "S13",
                "deprecated": true,
                "deprecatedMessage": "This attribute has been replaced by configuration.cognitoUserPoolConfiguration.clientIds"
              },
              "userPoolArn": {
                "deprecated": true,
                "deprecatedMessage": "This attribute has been replaced by configuration.cognitoUserPoolConfiguration.userPoolArn"
              },
              "discoveryUrl": {
                "deprecated": true,
                "deprecatedMessage": "This attribute has been replaced by configuration.cognitoUserPoolConfiguration.issuer"
              },
              "openIdIssuer": {
                "deprecated": true,
                "deprecatedMessage": "This attribute has been replaced by configuration"
              }
            }
          },
          "identitySourceId": {},
          "lastUpdatedDate": {
            "shape": "S17"
          },
          "policyStoreId": {},
          "principalEntityType": {
            "shape": "S15"
          },
          "configuration": {
            "type": "structure",
            "members": {
              "cognitoUserPoolConfiguration": {
                "type": "structure",
                "required": [
                  "userPoolArn",
                  "clientIds",
                  "issuer"
                ],
                "members": {
                  "userPoolArn": {},
                  "clientIds": {
                    "shape": "S13"
                  },
                  "issuer": {}
                }
              }
            },
            "union": true
          }
        }
      }
    },
    "GetPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "policyId"
        ],
        "members": {
          "policyStoreId": {},
          "policyId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "policyId",
          "policyType",
          "definition",
          "createdDate",
          "lastUpdatedDate"
        ],
        "members": {
          "policyStoreId": {},
          "policyId": {},
          "policyType": {},
          "principal": {
            "shape": "S6"
          },
          "resource": {
            "shape": "S6"
          },
          "definition": {
            "type": "structure",
            "members": {
              "static": {
                "type": "structure",
                "required": [
                  "statement"
                ],
                "members": {
                  "description": {
                    "shape": "S1c"
                  },
                  "statement": {
                    "shape": "S1d"
                  }
                }
              },
              "templateLinked": {
                "type": "structure",
                "required": [
                  "policyTemplateId"
                ],
                "members": {
                  "policyTemplateId": {},
                  "principal": {
                    "shape": "S6"
                  },
                  "resource": {
                    "shape": "S6"
                  }
                }
              }
            },
            "union": true
          },
          "createdDate": {
            "shape": "S17"
          },
          "lastUpdatedDate": {
            "shape": "S17"
          }
        }
      }
    },
    "GetPolicyStore": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId"
        ],
        "members": {
          "policyStoreId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "arn",
          "validationSettings",
          "createdDate",
          "lastUpdatedDate"
        ],
        "members": {
          "policyStoreId": {},
          "arn": {},
          "validationSettings": {
            "shape": "S1j"
          },
          "createdDate": {
            "shape": "S17"
          },
          "lastUpdatedDate": {
            "shape": "S17"
          },
          "description": {
            "shape": "S1l"
          }
        }
      }
    },
    "GetPolicyTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "policyTemplateId"
        ],
        "members": {
          "policyStoreId": {},
          "policyTemplateId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "policyTemplateId",
          "statement",
          "createdDate",
          "lastUpdatedDate"
        ],
        "members": {
          "policyStoreId": {},
          "policyTemplateId": {},
          "description": {
            "shape": "S1p"
          },
          "statement": {
            "shape": "S1d"
          },
          "createdDate": {
            "shape": "S17"
          },
          "lastUpdatedDate": {
            "shape": "S17"
          }
        }
      }
    },
    "GetSchema": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId"
        ],
        "members": {
          "policyStoreId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "schema",
          "createdDate",
          "lastUpdatedDate"
        ],
        "members": {
          "policyStoreId": {},
          "schema": {
            "shape": "S2i"
          },
          "createdDate": {
            "shape": "S17"
          },
          "lastUpdatedDate": {
            "shape": "S17"
          },
          "namespaces": {
            "shape": "S2j"
          }
        }
      }
    },
    "IsAuthorized": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId"
        ],
        "members": {
          "policyStoreId": {},
          "principal": {
            "shape": "S6"
          },
          "action": {
            "shape": "Sk"
          },
          "resource": {
            "shape": "S6"
          },
          "context": {
            "shape": "Sn"
          },
          "entities": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "decision",
          "determiningPolicies",
          "errors"
        ],
        "members": {
          "decision": {},
          "determiningPolicies": {
            "shape": "St"
          },
          "errors": {
            "shape": "Sw"
          }
        }
      }
    },
    "IsAuthorizedWithToken": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId"
        ],
        "members": {
          "policyStoreId": {},
          "identityToken": {
            "shape": "S2o"
          },
          "accessToken": {
            "shape": "S2o"
          },
          "action": {
            "shape": "Sk"
          },
          "resource": {
            "shape": "S6"
          },
          "context": {
            "shape": "Sn"
          },
          "entities": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "decision",
          "determiningPolicies",
          "errors"
        ],
        "members": {
          "decision": {},
          "determiningPolicies": {
            "shape": "St"
          },
          "errors": {
            "shape": "Sw"
          }
        }
      }
    },
    "ListIdentitySources": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId"
        ],
        "members": {
          "policyStoreId": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          },
          "filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "principalEntityType": {
                  "shape": "S15"
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "identitySources"
        ],
        "members": {
          "nextToken": {},
          "identitySources": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "createdDate",
                "identitySourceId",
                "lastUpdatedDate",
                "policyStoreId",
                "principalEntityType"
              ],
              "members": {
                "createdDate": {
                  "shape": "S17"
                },
                "details": {
                  "deprecated": true,
                  "deprecatedMessage": "This shape has been replaced by ConfigurationItem",
                  "type": "structure",
                  "members": {
                    "clientIds": {
                      "shape": "S13",
                      "deprecated": true,
                      "deprecatedMessage": "This attribute has been replaced by configuration.cognitoUserPoolConfiguration.clientIds"
                    },
                    "userPoolArn": {
                      "deprecated": true,
                      "deprecatedMessage": "This attribute has been replaced by configuration.cognitoUserPoolConfiguration.userPoolArn"
                    },
                    "discoveryUrl": {
                      "deprecated": true,
                      "deprecatedMessage": "This attribute has been replaced by configuration.cognitoUserPoolConfiguration.issuer"
                    },
                    "openIdIssuer": {
                      "deprecated": true,
                      "deprecatedMessage": "This attribute has been replaced by configuration"
                    }
                  }
                },
                "identitySourceId": {},
                "lastUpdatedDate": {
                  "shape": "S17"
                },
                "policyStoreId": {},
                "principalEntityType": {
                  "shape": "S15"
                },
                "configuration": {
                  "type": "structure",
                  "members": {
                    "cognitoUserPoolConfiguration": {
                      "type": "structure",
                      "required": [
                        "userPoolArn",
                        "clientIds",
                        "issuer"
                      ],
                      "members": {
                        "userPoolArn": {},
                        "clientIds": {
                          "shape": "S13"
                        },
                        "issuer": {}
                      }
                    }
                  },
                  "union": true
                }
              }
            }
          }
        }
      }
    },
    "ListPolicies": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId"
        ],
        "members": {
          "policyStoreId": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          },
          "filter": {
            "type": "structure",
            "members": {
              "principal": {
                "shape": "S34"
              },
              "resource": {
                "shape": "S34"
              },
              "policyType": {},
              "policyTemplateId": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "policies"
        ],
        "members": {
          "nextToken": {},
          "policies": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "policyStoreId",
                "policyId",
                "policyType",
                "definition",
                "createdDate",
                "lastUpdatedDate"
              ],
              "members": {
                "policyStoreId": {},
                "policyId": {},
                "policyType": {},
                "principal": {
                  "shape": "S6"
                },
                "resource": {
                  "shape": "S6"
                },
                "definition": {
                  "type": "structure",
                  "members": {
                    "static": {
                      "type": "structure",
                      "members": {
                        "description": {
                          "shape": "S1c"
                        }
                      }
                    },
                    "templateLinked": {
                      "type": "structure",
                      "required": [
                        "policyTemplateId"
                      ],
                      "members": {
                        "policyTemplateId": {},
                        "principal": {
                          "shape": "S6"
                        },
                        "resource": {
                          "shape": "S6"
                        }
                      }
                    }
                  },
                  "union": true
                },
                "createdDate": {
                  "shape": "S17"
                },
                "lastUpdatedDate": {
                  "shape": "S17"
                }
              }
            }
          }
        }
      }
    },
    "ListPolicyStores": {
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "policyStores"
        ],
        "members": {
          "nextToken": {},
          "policyStores": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "policyStoreId",
                "arn",
                "createdDate"
              ],
              "members": {
                "policyStoreId": {},
                "arn": {},
                "createdDate": {
                  "shape": "S17"
                },
                "lastUpdatedDate": {
                  "shape": "S17"
                },
                "description": {
                  "shape": "S1l"
                }
              }
            }
          }
        }
      }
    },
    "ListPolicyTemplates": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId"
        ],
        "members": {
          "policyStoreId": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "policyTemplates"
        ],
        "members": {
          "nextToken": {},
          "policyTemplates": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "policyStoreId",
                "policyTemplateId",
                "createdDate",
                "lastUpdatedDate"
              ],
              "members": {
                "policyStoreId": {},
                "policyTemplateId": {},
                "description": {
                  "shape": "S1p"
                },
                "createdDate": {
                  "shape": "S17"
                },
                "lastUpdatedDate": {
                  "shape": "S17"
                }
              }
            }
          }
        }
      }
    },
    "PutSchema": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "definition"
        ],
        "members": {
          "policyStoreId": {},
          "definition": {
            "type": "structure",
            "members": {
              "cedarJson": {
                "shape": "S2i"
              }
            },
            "union": true
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "namespaces",
          "createdDate",
          "lastUpdatedDate"
        ],
        "members": {
          "policyStoreId": {},
          "namespaces": {
            "shape": "S2j"
          },
          "createdDate": {
            "shape": "S17"
          },
          "lastUpdatedDate": {
            "shape": "S17"
          }
        }
      },
      "idempotent": true
    },
    "UpdateIdentitySource": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "identitySourceId",
          "updateConfiguration"
        ],
        "members": {
          "policyStoreId": {},
          "identitySourceId": {},
          "updateConfiguration": {
            "type": "structure",
            "members": {
              "cognitoUserPoolConfiguration": {
                "type": "structure",
                "required": [
                  "userPoolArn"
                ],
                "members": {
                  "userPoolArn": {},
                  "clientIds": {
                    "shape": "S13"
                  }
                }
              }
            },
            "union": true
          },
          "principalEntityType": {
            "shape": "S15"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createdDate",
          "identitySourceId",
          "lastUpdatedDate",
          "policyStoreId"
        ],
        "members": {
          "createdDate": {
            "shape": "S17"
          },
          "identitySourceId": {},
          "lastUpdatedDate": {
            "shape": "S17"
          },
          "policyStoreId": {}
        }
      },
      "idempotent": true
    },
    "UpdatePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "policyId",
          "definition"
        ],
        "members": {
          "policyStoreId": {},
          "policyId": {},
          "definition": {
            "type": "structure",
            "members": {
              "static": {
                "type": "structure",
                "required": [
                  "statement"
                ],
                "members": {
                  "description": {
                    "shape": "S1c"
                  },
                  "statement": {
                    "shape": "S1d"
                  }
                }
              }
            },
            "union": true
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "policyId",
          "policyType",
          "createdDate",
          "lastUpdatedDate"
        ],
        "members": {
          "policyStoreId": {},
          "policyId": {},
          "policyType": {},
          "principal": {
            "shape": "S6"
          },
          "resource": {
            "shape": "S6"
          },
          "createdDate": {
            "shape": "S17"
          },
          "lastUpdatedDate": {
            "shape": "S17"
          }
        }
      },
      "idempotent": true
    },
    "UpdatePolicyStore": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "validationSettings"
        ],
        "members": {
          "policyStoreId": {},
          "validationSettings": {
            "shape": "S1j"
          },
          "description": {
            "shape": "S1l"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "arn",
          "createdDate",
          "lastUpdatedDate"
        ],
        "members": {
          "policyStoreId": {},
          "arn": {},
          "createdDate": {
            "shape": "S17"
          },
          "lastUpdatedDate": {
            "shape": "S17"
          }
        }
      },
      "idempotent": true
    },
    "UpdatePolicyTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "policyTemplateId",
          "statement"
        ],
        "members": {
          "policyStoreId": {},
          "policyTemplateId": {},
          "description": {
            "shape": "S1p"
          },
          "statement": {
            "shape": "S1d"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "policyStoreId",
          "policyTemplateId",
          "createdDate",
          "lastUpdatedDate"
        ],
        "members": {
          "policyStoreId": {},
          "policyTemplateId": {},
          "createdDate": {
            "shape": "S17"
          },
          "lastUpdatedDate": {
            "shape": "S17"
          }
        }
      },
      "idempotent": true
    }
  },
  "shapes": {
    "S3": {
      "type": "structure",
      "members": {
        "entityList": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "identifier"
            ],
            "members": {
              "identifier": {
                "shape": "S6"
              },
              "attributes": {
                "type": "map",
                "key": {},
                "value": {
                  "shape": "Sb"
                }
              },
              "parents": {
                "type": "list",
                "member": {
                  "shape": "S6"
                }
              }
            }
          }
        }
      },
      "union": true
    },
    "S6": {
      "type": "structure",
      "required": [
        "entityType",
        "entityId"
      ],
      "members": {
        "entityType": {
          "type": "string",
          "sensitive": true
        },
        "entityId": {
          "type": "string",
          "sensitive": true
        }
      }
    },
    "Sb": {
      "type": "structure",
      "members": {
        "boolean": {
          "type": "boolean",
          "sensitive": true
        },
        "entityIdentifier": {
          "shape": "S6"
        },
        "long": {
          "type": "long",
          "sensitive": true
        },
        "string": {
          "type": "string",
          "sensitive": true
        },
        "set": {
          "type": "list",
          "member": {
            "shape": "Sb"
          }
        },
        "record": {
          "type": "map",
          "key": {},
          "value": {
            "shape": "Sb"
          }
        }
      },
      "union": true
    },
    "Sj": {
      "type": "structure",
      "members": {
        "principal": {
          "shape": "S6"
        },
        "action": {
          "shape": "Sk"
        },
        "resource": {
          "shape": "S6"
        },
        "context": {
          "shape": "Sn"
        }
      }
    },
    "Sk": {
      "type": "structure",
      "required": [
        "actionType",
        "actionId"
      ],
      "members": {
        "actionType": {
          "type": "string",
          "sensitive": true
        },
        "actionId": {
          "type": "string",
          "sensitive": true
        }
      }
    },
    "Sn": {
      "type": "structure",
      "members": {
        "contextMap": {
          "type": "map",
          "key": {},
          "value": {
            "shape": "Sb"
          }
        }
      },
      "union": true
    },
    "St": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "policyId"
        ],
        "members": {
          "policyId": {}
        }
      }
    },
    "Sw": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "errorDescription"
        ],
        "members": {
          "errorDescription": {}
        },
        "sensitive": true
      }
    },
    "S13": {
      "type": "list",
      "member": {
        "type": "string",
        "sensitive": true
      }
    },
    "S15": {
      "type": "string",
      "sensitive": true
    },
    "S17": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "S1c": {
      "type": "string",
      "sensitive": true
    },
    "S1d": {
      "type": "string",
      "sensitive": true
    },
    "S1j": {
      "type": "structure",
      "required": [
        "mode"
      ],
      "members": {
        "mode": {}
      }
    },
    "S1l": {
      "type": "string",
      "sensitive": true
    },
    "S1p": {
      "type": "string",
      "sensitive": true
    },
    "S2i": {
      "type": "string",
      "sensitive": true
    },
    "S2j": {
      "type": "list",
      "member": {
        "type": "string",
        "sensitive": true
      }
    },
    "S2o": {
      "type": "string",
      "sensitive": true
    },
    "S34": {
      "type": "structure",
      "members": {
        "unspecified": {
          "type": "boolean"
        },
        "identifier": {
          "shape": "S6"
        }
      },
      "union": true
    }
  }
}