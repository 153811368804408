import "../../../style.css";

import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { nanoid } from "nanoid";
import NumberInput from "../../components/NumberInput";
import TextInput from "../../components/TextInput";
import GoButton from "../../components/GoButton";
import Grid from "@mui/material/Unstable_Grid2";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AssignPublicIp, ECSClient, RunTaskCommand } from "@aws-sdk/client-ecs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
import MultipleSelectChip from "../../components/ChipList";
import { AuthContext } from "../../contexts/authContext";
import { Layout } from "../../layout";

export const MreConfig = () => {
    const { sessionInfo } = useContext(AuthContext);
    const { ensureAwsCredentials } = useContext(AuthContext);

    const { lat, lon } = useParams();

    // Date range
    // Data is avaible from 2012-01-01T00:00:00Z.
    const minAvailableDate = dayjs("2012-01-01");
    // This is one year later. 1 year of data is the minimum that can be taken out.
    const minPickableDate = dayjs("2013-12-31");
    // Current data isn't available. Hence, last day of last month is used for max date.
    const maxPickableDate = dayjs.utc().subtract(1, "month").startOf("month");

    // User inputs
    const [longitude, setLongitude] = useState(lon ? Number(lon).toFixed(6) : "");
    const [latitude, setLatitude] = useState(lat ? Number(lat).toFixed(6) : "");
    const [date_end, setDateEnd] = useState(maxPickableDate.endOf("month"));
    const [number_of_years, setNumberOfYears] = useState("");
    const [email_recipient_0, setEmailRecipient0] = useState(sessionInfo.email);
    const [devices, setDevices] = useState<readonly string[]>([""]);

    // Validation
    const [longitudeError, setLongitudeError] = useState(false);
    const [latitudeError, setLatitudeError] = useState(false);
    const [dateError, setDateError] = useState(false);
    const [yearsError, setYearsError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [devicesError, setDevicesError] = useState(false);

    function validateInputs() {
        console.log("Validating inputs...");

        // Reset errors at the beginning of each validation
        let valid = true;
        setLongitudeError(false);
        setLatitudeError(false);
        setDateError(false);
        setYearsError(false);
        setEmailError(false);
        setDevicesError(false);

        // Validate
        if (longitude === "" || parseFloat(longitude) < -180 || parseFloat(longitude) > 180) {
            setLongitudeError(true);
            valid = false;
        }

        if (latitude === "" || parseFloat(latitude) < -90 || parseFloat(latitude) > 90) {
            setLatitudeError(true);
            valid = false;
        }

        if (!date_end.isValid()) {
            setDateError(true);
            valid = false;
        }

        if (
            number_of_years === "" ||
            parseFloat(number_of_years) < 1 ||
            date_end.subtract(parseInt(number_of_years), "years").isBefore(minAvailableDate)
        ) {
            setYearsError(true);
            valid = false;
        }

        if (
            email_recipient_0 === "" ||
            !email_recipient_0.includes("@") ||
            !email_recipient_0.includes(".")
        ) {
            setEmailError(true);
            valid = false;
        }

        if (!Array.isArray(devices) || !(devices.length <= 3) || !(devices.length >= 1)) {
            setDevicesError(true);
            valid = false;
        }

        // Raise alert if relevant
        if (!valid) {
            alert("Some values are invalid. Please check your inputs.");
            return false;
        } else {
            return true;
        }
    }

    const saveInputs = async () => {
        const jobid = nanoid();

        console.log(`Saving jobid = ${jobid}...`);

        // Configure AWS clients
        const awsConfig = await ensureAwsCredentials();

        const s3Client = new S3Client({
            credentials: awsConfig.credentials,
            region: awsConfig.region
        });

        const ecsClient = new ECSClient({
            credentials: awsConfig.credentials,
            region: awsConfig.region
        });

        // data source
        const key_data_source = "modules/mm2dlp/jobs/" + jobid + "/config_data_source.json";

        const body_data_source = JSON.stringify({
            email_recipients: [email_recipient_0],
            latitude,
            longitude,
            date_start: date_end
                .subtract(Number(number_of_years), "years")
                .add(1, "month")
                .startOf("month"),
            // .subtract(15, 'minutes') is not the nicest fix, but without it, the meteomatics api
            // rounds up to next midnight, which is actually next day, which is actually next
            // month, and it messes with the statistics.
            date_end: date_end.endOf("month").subtract(15, "minutes"),
            parameter_package: "MRE",
            interval_hourly: 3,
            next: "MRE",
        });

        console.log(body_data_source);

        await s3Client.send(
            new PutObjectCommand({
                Bucket: "shell-dw-module-output",
                Key: key_data_source,
                Body: body_data_source,
            })
        );

        // MRE
        const key_mre = "modules/mre/jobs/" + jobid + "/config_mre.json";

        const body_mre = JSON.stringify({
            dlp_object_key_input: "NA",
            email_recipients: [email_recipient_0],
            names_devices: devices,
        });

        console.log(body_mre);

        await s3Client.send(
            new PutObjectCommand({
                Bucket: "shell-dw-module-output",
                Key: key_mre,
                Body: body_mre,
            })
        );

        alert(
            "New job created with ID " +
                jobid +
                "\n\n" +
                "Results will be sent to " +
                email_recipient_0
        );

        const response = await ecsClient.send(
            new RunTaskCommand({
                taskDefinition: "mm2dlp",
                cluster:
                    "arn:aws:ecs:us-east-1:348482033654:cluster/dwapi-EcsCluster-NQNzVpomowO9",
                launchType: "FARGATE",
                networkConfiguration: {
                    awsvpcConfiguration: {
                        securityGroups: ["sg-029fe4ad2e115f887"],
                        subnets: ["subnet-01ef9e932cd8d4ee5"],
                        assignPublicIp: AssignPublicIp.ENABLED,
                    },
                },
                overrides: {
                    containerOverrides: [
                        {
                            name: "module",
                            command: ["python3", "main.py", jobid],
                        },
                    ],
                },
            })
        );
    };

    const content = (
        <div className="module-page">
            <p className="module-guide-text">
                This module generates a report on the yield of the{" "}
                <strong>1 to 3 devices of interest</strong> at the <strong>Latitude</strong> and{" "}
                <strong>Longitude</strong> based on data from the period with the length{" "}
                <strong>Data range</strong> ending with <strong>Last month in range</strong>. The
                report is sent to <strong>E-mail recipient</strong>.
            </p>
            <Grid container spacing={2} justifyContent="center" columns={{ xs: 6, sm: 12 }}>
                <Grid xs={6}>
                    <NumberInput
                        name_parameter={"Latitude"}
                        unit={"deg"}
                        defaultValue={latitude}
                        step="0.000001"
                        onChange={(ev) => setLatitude(ev)}
                        error={latitudeError}
                    />
                </Grid>
                <Grid xs={6} className="grid-item">
                    <NumberInput
                        name_parameter={"Longitude"}
                        unit={"deg"}
                        defaultValue={longitude}
                        step="0.000001"
                        onChange={(ev) => setLongitude(ev)}
                        error={longitudeError}
                    />
                </Grid>
                <Grid xs={6} className="grid-item">
                    <NumberInput
                        name_parameter={"Data range"}
                        unit={"year(s)"}
                        defaultValue=""
                        step="1"
                        onChange={(ev) => setNumberOfYears(ev)}
                        error={yearsError}
                    />
                </Grid>
                <Grid xs={6}>
                    <FormControl variant="outlined" error={dateError} className="input-box">
                        <FormHelperText id="outlined-weight-helper-text">
                            Last month in range
                        </FormHelperText>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                defaultValue={maxPickableDate}
                                minDate={minPickableDate}
                                maxDate={maxPickableDate}
                                format="YYYY-MM"
                                views={["year", "month"]}
                                onChange={(ev) => {
                                    // const endOfSelectedMonth = ev.endOf('month');
                                    // setDateEnd(endOfSelectedMonth);
                                    setDateEnd(ev);
                                }}
                                slotProps={{ textField: { fullWidth: true } }}
                            />
                        </LocalizationProvider>
                    </FormControl>
                </Grid>
                <Grid xs={6} className="input-box">
                    <MultipleSelectChip
                        onChange={(ev) => setDevices(ev)}
                        name_parameter={"1 to 3 devices of interest"}
                        namesOptions={["Clapper", "Horizon", "Piston", "Star"]}
                        error={devicesError}
                    />
                </Grid>
                <Grid xs={6} className="input-box">
                    <TextInput
                        name_parameter={"E-mail recipient"}
                        onChange={(ev) => setEmailRecipient0(ev)}
                        error={emailError}
                        value={email_recipient_0}
                    />
                </Grid>
            </Grid>
            <br />
            <br />
            <GoButton validateInputs={validateInputs} saveInputs={saveInputs} />
        </div>
    );

    return <Layout content={content} moduleName="MRE" />;
};
