import { SchedulerServiceException as __BaseException } from "./SchedulerServiceException";
export const ActionAfterCompletion = {
    DELETE: "DELETE",
    NONE: "NONE",
};
export const AssignPublicIp = {
    DISABLED: "DISABLED",
    ENABLED: "ENABLED",
};
export class InternalServerException extends __BaseException {
    constructor(opts) {
        super({
            name: "InternalServerException",
            $fault: "server",
            ...opts,
        });
        this.name = "InternalServerException";
        this.$fault = "server";
        Object.setPrototypeOf(this, InternalServerException.prototype);
        this.Message = opts.Message;
    }
}
export class ResourceNotFoundException extends __BaseException {
    constructor(opts) {
        super({
            name: "ResourceNotFoundException",
            $fault: "client",
            ...opts,
        });
        this.name = "ResourceNotFoundException";
        this.$fault = "client";
        Object.setPrototypeOf(this, ResourceNotFoundException.prototype);
        this.Message = opts.Message;
    }
}
export class ThrottlingException extends __BaseException {
    constructor(opts) {
        super({
            name: "ThrottlingException",
            $fault: "client",
            ...opts,
        });
        this.name = "ThrottlingException";
        this.$fault = "client";
        Object.setPrototypeOf(this, ThrottlingException.prototype);
        this.Message = opts.Message;
    }
}
export class ValidationException extends __BaseException {
    constructor(opts) {
        super({
            name: "ValidationException",
            $fault: "client",
            ...opts,
        });
        this.name = "ValidationException";
        this.$fault = "client";
        Object.setPrototypeOf(this, ValidationException.prototype);
        this.Message = opts.Message;
    }
}
export class ConflictException extends __BaseException {
    constructor(opts) {
        super({
            name: "ConflictException",
            $fault: "client",
            ...opts,
        });
        this.name = "ConflictException";
        this.$fault = "client";
        Object.setPrototypeOf(this, ConflictException.prototype);
        this.Message = opts.Message;
    }
}
export const FlexibleTimeWindowMode = {
    FLEXIBLE: "FLEXIBLE",
    OFF: "OFF",
};
export const ScheduleState = {
    DISABLED: "DISABLED",
    ENABLED: "ENABLED",
};
export const LaunchType = {
    EC2: "EC2",
    EXTERNAL: "EXTERNAL",
    FARGATE: "FARGATE",
};
export const PlacementConstraintType = {
    DISTINCT_INSTANCE: "distinctInstance",
    MEMBER_OF: "memberOf",
};
export const PlacementStrategyType = {
    BINPACK: "binpack",
    RANDOM: "random",
    SPREAD: "spread",
};
export const PropagateTags = {
    TASK_DEFINITION: "TASK_DEFINITION",
};
export class ServiceQuotaExceededException extends __BaseException {
    constructor(opts) {
        super({
            name: "ServiceQuotaExceededException",
            $fault: "client",
            ...opts,
        });
        this.name = "ServiceQuotaExceededException";
        this.$fault = "client";
        Object.setPrototypeOf(this, ServiceQuotaExceededException.prototype);
        this.Message = opts.Message;
    }
}
export const ScheduleGroupState = {
    ACTIVE: "ACTIVE",
    DELETING: "DELETING",
};
