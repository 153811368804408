import { GetObjectCommand, S3Client } from "@aws-sdk/client-s3";

const useAwsS3Client = (
    config: Partial<AWS.Config>
) => {
    const s3Client = new S3Client({
        credentials: config.credentials,
        region: config.region
    });

    const getObject = async (bucket: string, key: string) => {
        const command = new GetObjectCommand({
            Bucket: bucket,
            Key: key,
        });

        try {
            const response = await s3Client.send(command);

            const str = await response.Body.transformToString();
            // const str = await response;

            return str;
        } catch (error) {
            console.log("[ERROR] Something went wrong when getting the object from S3...");
            console.error(error);
        }
    };

    return {
        getObject,
    };
};

export default useAwsS3Client;
