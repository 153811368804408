{
  "pagination": {
    "ListAssetContracts": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "contracts"
    },
    "ListFilteredTransactionEvents": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "events"
    },
    "ListTokenBalances": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "tokenBalances"
    },
    "ListTransactionEvents": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "events"
    },
    "ListTransactions": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "transactions"
    }
  }
}
