{
  "pagination": {
    "ListApplicationAssociations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ApplicationAssociations"
    },
    "ListApplications": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "Applications"
    },
    "ListDataIntegrationAssociations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "DataIntegrationAssociations"
    },
    "ListDataIntegrations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "DataIntegrations"
    },
    "ListEventIntegrationAssociations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "EventIntegrationAssociations"
    },
    "ListEventIntegrations": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "EventIntegrations"
    }
  }
}