const configBase = {
    "email_recipient": "TBD",
    "send_results_to_metnet": false,
    "write_metnet_json_file": true,
    "comment1": "# These are the currents to be predicted",
    "stationid": "TBD",
    "sensorid": "TBD",
    "curspdid": "TBD",
    "curdirid": "TBD",
    "forecast_sensorid": "Forecasts/ShellMCPML",
    "comment8": "# Location of the data",
    "longitude": "TBD",
    "latitude": "TBD",
    "postkey": "lmZnyWPchpzaVDLUd8zq6m8liGLCVYA2",
    "forecast_duration_days": 10.0,
    "forecast_start_date": "TBD",
    "forecast_mode": "Real-time",
    "comment2": "# Data start date in UTC can be restricted with this must be yyyy-mm-dd",
    "data_start_date": "2023-04-22",
    "data_interval": "10min",
    "comment3": "# These define the periods are which to split the data into ",
    "comment4": "# VARX models, with timesteps to be used for each and lags ",
    "harm_resid_fit_duration_days": 10,
    "harm_resid_fit_consit": ["M4", "M2", "K1", "O1", "M3"],
    "_use_drift_exog": false,
    "scaling_factors_u": [1.1, 1.0, 1.0, 1.0],
    "scaling_factors_v": [1.3, 1.0, 1.0, 1.0],
    "separation_periods_hr": [2, 40, 90],
    "var_model_timesteps_hr": [1, 3, 6],
    "var_model_max_lags": [72, 24, 24],
    "comment5": "# Dont use this. Data near the end may have filtered end effects - this can be",
    "comment6": "#  used to avoid say the last 6 hours of data",
    "dont_fit_end_var_hours": 0.0,
    "comment7": "# These may be turned on or off to aid or not the forecast",
    "use_nmi_exog": false,
    "reload_data": false,
    "historical_data_file": "/opt/mcp-ml4/tmp/obs_${station}_${jobid}.csv",
    "results_csv": "/opt/mcp-ml4/results/results_${station}_${jobid}.csv",
    "results_png": "/opt/mcp-ml4/results/results_${station}_${jobid}.png",
    "metnet_json_file": "/opt/mcp-ml4/tmp/metnet_${station}_${jobid}.json",
    "make_database": false,
    "proxy": "",
    "comment9": "# Add any time periods when bad data is to be removed from analysis",
    "bad_data": [{}],
};

export default configBase;
