import * as React from "react";
import { Box, ListItemText, Paper, Typography } from "@mui/material";
import mapboxgl from "mapbox-gl";
import { iconPaths, setDocumentSourceData } from "../utils/Map";
import ICatalogEntry from "../types/ICatalogEntry";
import { styled } from "@mui/material/styles";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import {
    unstable_useTreeItem2 as useTreeItem2,
    UseTreeItem2Parameters,
} from "@mui/x-tree-view/useTreeItem2";
import {
    TreeItem2Content,
    TreeItem2IconContainer,
    TreeItem2GroupTransition,
    TreeItem2Root,
    TreeItem2Checkbox,
} from "@mui/x-tree-view/TreeItem2";
import { TreeItem2Icon } from "@mui/x-tree-view/TreeItem2Icon";
import { TreeItem2Provider } from "@mui/x-tree-view/TreeItem2Provider";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

interface Props {
    allDocumentLayerIds: string[];
    visibleLayerIds: string[];
    setVisibleLayerIds: (filter: string[]) => void;
}

export default function MapLayerMenu(props: Props) {
    const handleParentToggle = (childLayerIds: string[], action: "toggle" | "untoggle") => {
        let _visibleLayerIds: string[];

        if (action === "toggle") {
            // add all childLayerIds to visibleLayers, and get rid of overlaps.
            _visibleLayerIds = Array.from(new Set([...props.visibleLayerIds, ...childLayerIds]));
        } else if (action === "untoggle") {
            // remove all childLayersIds from visibleLayerIds
            _visibleLayerIds = props.visibleLayerIds.filter(
                (element) => !childLayerIds.includes(element)
            );
        }
        props.setVisibleLayerIds(_visibleLayerIds);
    };

    const handleChildToggle = (layerId: string) => {
        let _visibleLayerIds: string[];

        // get current visibility
        const isVisible = props.visibleLayerIds.includes(layerId);
        // if visible when clicked, make non-visible, and vice versa
        if (isVisible) {
            _visibleLayerIds = props.visibleLayerIds.filter((e, i) => e !== layerId);
        } else {
            _visibleLayerIds = [...props.visibleLayerIds, layerId];
        }
        props.setVisibleLayerIds(_visibleLayerIds);
    };

    //// Tree stuff
    // TODO: check this issue for updates on documentation for useTreeItem2:
    // https://github.com/mui/mui-x/issues/13161

    const CustomTreeParent = React.forwardRef(function CustomTreeItem(
        _props: CustomTreeItemProps,
        ref: React.Ref<HTMLLIElement>
    ) {
        const { id, itemId, label, disabled, children, childLayerIds, ...other } = _props;

        const {
            getRootProps,
            getContentProps,
            getIconContainerProps,
            getCheckboxProps,
            getLabelProps,
            getGroupTransitionProps,
            status,
        } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

        return (
            <TreeItem2Provider itemId={itemId}>
                <TreeItem2Root {...getRootProps(other)}>
                    <CustomTreeItemContent {...getContentProps()}>
                        <TreeItem2IconContainer {...getIconContainerProps()}>
                            <TreeItem2Icon status={status} />
                        </TreeItem2IconContainer>
                        <TreeItem2Checkbox
                            {...getCheckboxProps()}
                            // checked if some childLayerIds are included in visibleLayerIds
                            checked={childLayerIds.some((item) =>
                                props.visibleLayerIds.includes(item)
                            )}
                            // Render CheckBoxIcon if all childLayerIds are included in visibleLayerIds
                            // else, render IndeterminateCheckBoxIcon
                            checkedIcon={
                                childLayerIds.every((item) =>
                                    props.visibleLayerIds.includes(item)
                                ) ? (
                                    <CheckBoxIcon />
                                ) : (
                                    <IndeterminateCheckBoxIcon />
                                )
                            }
                            onClick={() => {
                                handleParentToggle(
                                    childLayerIds,
                                    // if some items from the childLayerIds are toggled/visible,
                                    // click should untoggle all. If none are toggled/visible,
                                    // click should toggle all.
                                    childLayerIds.some((item) =>
                                        props.visibleLayerIds.includes(item)
                                    )
                                        ? "untoggle"
                                        : "toggle"
                                );
                            }}
                        />
                        <Typography fontWeight={700}>{label}</Typography>
                    </CustomTreeItemContent>
                    {children && <TreeItem2GroupTransition {...getGroupTransitionProps()} />}
                </TreeItem2Root>
            </TreeItem2Provider>
        );
    });

    const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
        padding: theme.spacing(0.5, 1),
    }));

    interface CustomTreeItemProps
        extends Omit<UseTreeItem2Parameters, "rootRef">,
            Omit<React.HTMLAttributes<HTMLLIElement>, "onFocus"> {
        childLayerIds?: string[];
    }

    const CustomTreeItem = React.forwardRef(function CustomTreeItem(
        _props: CustomTreeItemProps,
        ref: React.Ref<HTMLLIElement>
    ) {
        const { id, itemId, label, disabled, children, ...other } = _props;

        const {
            getRootProps,
            getContentProps,
            getIconContainerProps,
            getCheckboxProps,
            getLabelProps,
            getGroupTransitionProps,
            status,
        } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

        return (
            <TreeItem2Provider itemId={itemId}>
                <TreeItem2Root
                    {...getRootProps(other)}
                    onClick={() => {
                        handleChildToggle(itemId);
                    }}
                    sx={{
                        "backgroundColor": props.visibleLayerIds.includes(itemId)
                            ? "#FFFFFF"
                            // : "grey",
                            : "#A9A9A9",
                        "&:hover": {
                            backgroundColor: "lightgrey",
                        },
                    }}
                >
                    <CustomTreeItemContent {...getContentProps()}>
                        <TreeItem2IconContainer {...getIconContainerProps()}>
                            <TreeItem2Icon status={status} />
                        </TreeItem2IconContainer>
                        <TreeItem2Checkbox
                            {...getCheckboxProps()}
                            checked={props.visibleLayerIds.includes(itemId)}
                        />
                        <img
                            src={iconPaths[itemId]}
                            alt=""
                            style={{ width: "10%", height: "auto" }}
                        />
                        <ListItemText id={itemId} primary={label} />
                    </CustomTreeItemContent>
                </TreeItem2Root>
            </TreeItem2Provider>
        );
    });

    return (
        <Box component={Paper} id="map-layer-menu" className="map-layer-menu">
            <SimpleTreeView
                checkboxSelection={true}
                aria-label="icon expansion"
                defaultExpandedItems={["Documents", "Areas"]}
            >
                <CustomTreeParent
                    itemId="Documents"
                    label="Documents"
                    childLayerIds={props.allDocumentLayerIds}
                >
                    {props.allDocumentLayerIds.map((layerId, idx) => {
                        return <CustomTreeItem key={layerId} itemId={layerId} label={layerId} />;
                    })}
                </CustomTreeParent>
                <CustomTreeParent
                    itemId="Areas"
                    label="Areas"
                    childLayerIds={["outlinedAreas", "widenedOutlinedAreas"]}
                >
                    <CustomTreeItem itemId="outlinedAreas" label="outlinedAreas" />
                    {/* <CustomTreeItem itemId="widenedOutlinedAreas" label="widenedOutlinedAreas" /> */}
                </CustomTreeParent>
            </SimpleTreeView>
        </Box>
    );
}
