import mapboxgl from "mapbox-gl";
import ICatalogEntry from "../types/ICatalogEntry";
import { url } from "aws-sdk/clients/finspace";
import areasCatalog from "./MetoceanPolygons";

interface IconPaths {
    // [key: string]: string;
    [key: string]: URL;
}

export const iconPaths: IconPaths = {
    "Drilling Operability Study": new URL(
        "../../resources/document_logos/Slide1.png",
        import.meta.url
    ),
    "LNG & FSRU operability study": new URL("../../resources/document_logos/Slide2.png", import.meta.url),
    "Metocean Reference Document (MRD)": new URL(
        "../../resources/document_logos/Slide3.png",
        import.meta.url
    ),
    "Metocean data acquisition & analysis plan (MDAAP)": new URL(
        "../../resources/document_logos/Slide4.png",
        import.meta.url
    ),
    "Metocean field survey report": new URL(
        "../../resources/document_logos/Slide5.png",
        import.meta.url
    ),
    "Metocean pipeline criteria report": new URL(
        "../../resources/document_logos/Slide6.png",
        import.meta.url
    ),
    "Metocean study report": new URL("../../resources/document_logos/Slide7.png", import.meta.url),
    "Sea ice  / iceberg study report": new URL(
        "../../resources/document_logos/Slide8.png",
        import.meta.url
    ),
    "Seismic and survey support": new URL(
        "../../resources/document_logos/Slide10.png",
        import.meta.url
    ),
    "Tow and installation criteria report": new URL(
        "../../resources/document_logos/Slide11.png",
        import.meta.url
    ),
    "Weather monitoring & forecasting": new URL(
        "../../resources/document_logos/Slide12.png",
        import.meta.url
    ),
    "Cluster": new URL("../../resources/document_logos/Overlapping.png", import.meta.url),
    "Areas": new URL("../../resources/document_logos/Areas.png", import.meta.url),
    "outlinedAreas": new URL("../../resources/document_logos/Areas.png", import.meta.url),
    // "widenedOutlinedAreas": new URL("../../resources/document_logos/Areas.png", import.meta.url),
};

export const gebcoColorMap: (string|number)[] = [
    // source: https://www.gebco.net/data_and_products/gebco_web_services/web_map_service/images/gebco_depth_colour_key_vertical.jpg
    200, '#B3E1D6',
    500, '#98DAD8',
    1000, '#72D6E5',
    2000, '#4DB6C9',
    3000, '#2CA2BE',
    4000, '#368FBB',
    5000, '#3570A8',
    6000, '#104587',
    7000, '#002B62',
    9000, '#000A3B',
];

export function loadIconImages(map: mapboxgl.Map, allDocumentLayerIds: string[]) {
    let iconPath: URL;

    //// Add icon images for individual icons
    for (const layerId of allDocumentLayerIds) {
        try {
            iconPath = iconPaths[layerId];
        } catch {
            console.log(`[OBS] No icon for layerId = ${layerId}`);
        }

        // Add/load/initialise icons for current layerId
        map.loadImage(iconPath, (error, image) => {
            // This throws a log-error when image is already loaded.
            // But there doesn't really seam to be a good way to get rid of it...

            if (error) throw error;

            // Add the image to the map style.
            map.addImage(layerId, image);
        });
    }

    //// Add icon images for Clusters
    iconPath = iconPaths["Cluster"];

    map.loadImage(iconPath, (error, image) => {
        if (error) throw error;

        // Add the image to the map style.
        map.addImage("Cluster", image);
    });
}



export function getFilteredCatalog(
    catalog: ICatalogEntry[],
    documentTextFilter: string,
    visibleLayerIds: string[],
): ICatalogEntry[] {

    return (
        // go through catalog
        catalog.filter((catalogEntry) => 
            // check if type is included in visibleLayerIds
            visibleLayerIds.includes(catalogEntry['Type'])
            &&
            // check if every element of the documentTextFilter is included in a catalogEntry
            documentTextFilter.split(' ').every(
                e => JSON.stringify(catalogEntry).toLowerCase().includes(e.toLowerCase())
            )
        )
    )
}



export function createLayersFromDocumentCatalog(
    catalog: ICatalogEntry[],
    map: mapboxgl.Map,
    allDocumentLayerIds: string[],
    visibleLayerIds: string[]
): void {

    // if "Documents" source already exists, remove it and it's associated layers
    // Any layers associated with a source must be removed before the source can be removed.
    if (map.getStyle().sources.hasOwnProperty("Documents")) {
        // Remove layers
        for (const layerId of allDocumentLayerIds) {
            map.removeLayer(layerId);
        }
        // Remove source
        map.removeSource("Documents");
    }

    // if "OverlappingDocuments" source already exists, remove it and it's associated layers
    // Any layers associated with a source must be removed before the source can be removed.
    if (map.getStyle().sources.hasOwnProperty("OverlappingDocuments")) {
        // Remove layers
        map.removeLayer("Cluster");
        map.removeLayer("cluster-count");
        // Remove source
        map.removeSource("OverlappingDocuments");
    }

    // Add source
    map.addSource("Documents", {
        type: "geojson",
        cluster: false,
    });

    setDocumentSourceData(
        "Documents", 
        catalog, 
        map, 
        visibleLayerIds
    );

    //  individual icons
    for (const layerId of allDocumentLayerIds) {
        // Add layer
        map.addLayer({
            id: layerId,
            type: "symbol",
            source: "Documents",
            // filter with two conditions. all conditions must be true.
            filter: ["all", ["==", "layerId", layerId], ["!has", "point_count"]],
            layout: {
                // Make the layer visible by default.
                "visibility": "visible",
                "icon-image": layerId,
                "icon-size": 0.5,
                "icon-anchor": "bottom",
                "icon-allow-overlap": true,
            },
        });
    }

    //// FOR CLUSTERING
    // Add source
    map.addSource("OverlappingDocuments", {
        type: "geojson",
        cluster: true,
        clusterMaxZoom: 1000, // Max zoom to cluster points on
        clusterRadius: 0, // Radius of each cluster when clustering points
    });

    setDocumentSourceData(
        "OverlappingDocuments",
        catalog,
        map,
        visibleLayerIds,
    );

    // Clusters: Add circle background
    map.addLayer({
        id: "Cluster",
        // type: "circle",
        type: "symbol",
        source: "OverlappingDocuments",
        filter: ["all", ["has", "point_count"]],
        // paint: {
        //     // Use step expressions (https://docs.mapbox.com/style-spec/reference/expressions/#step)
        //     "circle-color": "#fbd103",
        //     "circle-radius": ["step", ["get", "point_count"], 15, 5, 20, 10, 25],
        // },
        layout: {
            // Make the layer visible by default.
            "visibility": "visible",
            "icon-image": "Cluster",
            "icon-size": 0.55,
            "icon-anchor": "bottom",
            "icon-allow-overlap": true,
            "icon-offset": [0, 0.5],
        },
    });

    // Clusters: Add number of features in cluster
    map.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "OverlappingDocuments",
        filter: ["has", "point_count"],
        layout: {
            "text-field": ["get", "point_count_abbreviated"],
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 20,
            "text-offset": [0, -1.2],
        },
        paint: {
            // "text-color": "#FFFFFF",
            "text-color": "#404040",
        },
    });
}

export function setDocumentSourceData(
    sourceId: string,
    catalog: ICatalogEntry[],
    map: mapboxgl.Map,
    visibleLayerIds: string[],
): void {

    let features = catalog
        // check if type is included in visibleLayerIds
        .filter((catalogEntry) => visibleLayerIds.includes(catalogEntry["Type"]))
        // if documentTextFilter is not empty, check if documentTextFilter is included in meta data
        .map((catalogEntry) => {

            return {
                type: "Feature",
                id: catalogEntry['id'],
                properties: {
                    name: catalogEntry["Name"],
                    layerId: catalogEntry["Type"],
                },
                geometry: {
                    type: "Point",
                    coordinates: [catalogEntry["Longitude"], catalogEntry["Latitude"]],
                },
            }
        })

    // set source data based on visibleLayers and documentTextFilter
    map.getSource(sourceId).setData({
        type: "FeatureCollection",
        features: features
    });
}

export function formatDocumentDescription(jsonDescription: JSON): React.ReactElement[] {
    const formatedDescription: React.ReactElement[] = [];

    for (let key in jsonDescription) {
        formatedDescription.push(
            <div key={key}>
                {key}: {jsonDescription[key]}
                <br />
            </div>
        );
    }

    return formatedDescription;
}

export async function getClusterLeaves(map, source, cluster_id) {
    const limit = Infinity;
    const offset = 0;

    return new Promise((resolve, reject) => {
        map.getSource(source).getClusterLeaves(cluster_id, limit, offset, (err, features) => {
            if (err) reject(err);
            else resolve(features);
        });
    });
}

export async function getFilteredVisibleCatalog(
    map: mapboxgl.Map,
    layerIds: string[],
    filteredCatalog: ICatalogEntry[],
) {
    // Gets features fulfilling:
    // 1. Included in filteredCatalog (i.e. fulfilling documentTextFilter)
    // 2. Within the current field of view (visible)

    // Get documents visible in the current field of view
    const visibleFeatures = map.queryRenderedFeatures(undefined, { layers: layerIds });
    // Get ids of these visible features
    const visibleFeatureIds = visibleFeatures.map(feature => feature['id'])
    // Extract only the document entries who's ids are included in visibleFeatureIds
    const filteredVisibleCatalog = filteredCatalog.filter(entry => visibleFeatureIds.includes(entry['id']))

    return filteredVisibleCatalog.sort().reverse();
}

export function addPopup(
    map: mapboxgl.Map,
    latitude: number,
    longitude: number,
    information: string
): void {
    new mapboxgl.Popup({ closeButton: false, maxWidth: "350px", anchor: "top" })
        .setLngLat([longitude, latitude])
        .setHTML(information)
        .addTo(map);
}

export function getDocumentTag(documentName, documentYear, documentType): string {
    const documentTag = `
        <p style="text-align: left; margin: 0px; font-size: 12.25px">
        ${documentName}
        </p>
        <p style="text-align: left; margin: 0px; color: #545454"; font-size: 12.25px>
        ${documentYear}, ${documentType}
        </p>
    `;

    return documentTag;
}

export function closeAllPopups(): void {
    // get all open pop-ups
    const popups = document.getElementsByClassName("mapboxgl-popup");

    // close all open pop-ups
    if (popups.length) {
        for (let i = popups.length - 1; i >= 0; i--) {
            popups[i].remove();
        }
    }
}

export function createLayerFromAreasCatalog(map: mapboxgl.Map, visibleLayerIds): void {

    // if "Areas" source already exists, remove it and it's associated layers
    // Any layers associated with a source must be removed before the source can be removed.
    if (map.getStyle().sources.hasOwnProperty("Areas")) {
        // Remove layers
        for (const layerId of ["outlinedAreas", "widenedOutlinedAreas"]) {
            map.removeLayer(layerId);
        }
        // Remove source
        map.removeSource("Areas");
    }

    // Add source
    map.addSource("Areas", {
        type: "geojson",
        cluster: false,
    });

    setAreaSourceData(
        "Areas", 
        areasCatalog, 
        map,
        visibleLayerIds
    );

    // Add outline layer
    map.addLayer({
        id: "outlinedAreas",
        type: "line",
        source: "Areas",
        layout: {
            // visibility: visibleLayerIds.includes("outlinedAreas") ? "visible" : "none",
            visibility: 'visible',
        },
        paint: {
            "line-color": "#000",
            "line-width": 1,
        },
    });

    // Add much thicker but opaque outline layer to use for mouseenter events
    map.addLayer({
        id: "widenedOutlinedAreas",
        type: "line",
        source: "Areas",
        layout: {
            // visibility: visibleLayerIds.includes("widenedOutlinedAreas") ? "visible" : "none",
            visibility: 'visible',
        },
        paint: {
            "line-color": "#000",
            "line-opacity": 0.0,
            "line-width": 10,
        },
    });

    // // Add fill layer
    // map.addLayer({
    //     id: "filledAreas",
    //     type: "fill",
    //     source: "Areas", // reference the data source
    //     layout: {},
    //     paint: {
    //         "fill-color": "#000", // blue color fill
    //         "fill-opacity": 0.0,
    //     },
    // });
}

export function setAreaSourceData(
    sourceId: string, 
    catalog: any, 
    map: mapboxgl.Map,
    visibleLayerIds: string[],
): void {

    let features = [];

    if (visibleLayerIds.includes("outlinedAreas")) {

        for (let i = 0; i < catalog["datasetIdList"].length; i++) {

            features.push({
                type: "Feature",
                properties: {
                    name: catalog["datasetIdList"][i],
                    layerId: sourceId,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [catalog["orderedMoodSpaces"][i]],
                },
            });
        }
    }

    map.getSource(sourceId).setData({
        type: "FeatureCollection",
        features: features,
    });
}



export function convertDecimalDegreesToDegreesAndMinutes(D: number, lng: boolean) {
    const dir = D < 0 ? (lng ? "W" : "S") : lng ? "E" : "N";
    const deg = 0 | (D < 0 ? (D = -D) : D);
    const min = 0 | (((D += 1e-9) % 1) * 60);
    // const sec = (0 | (((D * 60) % 1) * 6000)) / 100;

    return `${deg}°${min}'${dir}`;
}



export function getFeatureIdsOiFromMapEvent(features: mapboxgl.MapboxGeoJSONFeature[]) {

    // distinguish cluster-features from non-cluster-features.
    // necessary due to current implementation of clusters.

    // check if "Cluster" was entered
    let allEnteredLayers = features.map((feature) => (feature.layer.id))
    let wasClusterEntered = allEnteredLayers.includes('Cluster')

    // Register which feature ids were involved
    if (wasClusterEntered) {        
        // get clusterCoordinates
        let clusterCoordinates = features[0]['geometry']['coordinates']

        // if Cluster, register all features of that cluster
        let featureIdsOi = features
            .filter(feature => 
                // remove potential "Cluster" feature
                (feature.layer.id !== 'Cluster') 
                // and extract only features which share the coordinates of the cluster
                && (JSON.stringify(feature['geometry']['coordinates']) == JSON.stringify(clusterCoordinates))
            )
            // extract only the ids
            .map(feature => feature['id'] as number)

        return featureIdsOi
    } else {
        // if not Cluster, register only the top feature
        let featureIdsOi = features
            // remove potential "Cluster" feature
            .filter(feature => feature.layer.id !== 'Cluster')
            // extract only the ids
            .map(feature => feature['id'] as number)

        return [featureIdsOi[0]]
    }
}

