{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2023-11-27",
    "endpointPrefix": "qbusiness",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "QBusiness",
    "serviceId": "QBusiness",
    "signatureVersion": "v4",
    "signingName": "qbusiness",
    "uid": "qbusiness-2023-11-27"
  },
  "operations": {
    "BatchDeleteDocument": {
      "http": {
        "requestUri": "/applications/{applicationId}/indices/{indexId}/documents/delete",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "documents",
          "indexId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "dataSourceSyncId": {},
          "documents": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "documentId"
              ],
              "members": {
                "documentId": {}
              }
            }
          },
          "indexId": {
            "location": "uri",
            "locationName": "indexId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "failedDocuments": {
            "shape": "S9"
          }
        }
      }
    },
    "BatchPutDocument": {
      "http": {
        "requestUri": "/applications/{applicationId}/indices/{indexId}/documents",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "documents",
          "indexId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "dataSourceSyncId": {},
          "documents": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "id"
              ],
              "members": {
                "accessConfiguration": {
                  "type": "structure",
                  "required": [
                    "accessControls"
                  ],
                  "members": {
                    "accessControls": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "required": [
                          "principals"
                        ],
                        "members": {
                          "memberRelation": {},
                          "principals": {
                            "type": "list",
                            "member": {
                              "type": "structure",
                              "members": {
                                "group": {
                                  "type": "structure",
                                  "required": [
                                    "access"
                                  ],
                                  "members": {
                                    "access": {},
                                    "membershipType": {},
                                    "name": {}
                                  }
                                },
                                "user": {
                                  "type": "structure",
                                  "required": [
                                    "access"
                                  ],
                                  "members": {
                                    "access": {},
                                    "id": {},
                                    "membershipType": {}
                                  }
                                }
                              },
                              "union": true
                            }
                          }
                        }
                      }
                    },
                    "memberRelation": {}
                  }
                },
                "attributes": {
                  "type": "list",
                  "member": {
                    "shape": "Sv"
                  }
                },
                "content": {
                  "type": "structure",
                  "members": {
                    "blob": {
                      "type": "blob"
                    },
                    "s3": {
                      "type": "structure",
                      "required": [
                        "bucket",
                        "key"
                      ],
                      "members": {
                        "bucket": {},
                        "key": {}
                      }
                    }
                  },
                  "union": true
                },
                "contentType": {},
                "documentEnrichmentConfiguration": {
                  "shape": "S19"
                },
                "id": {},
                "title": {}
              }
            }
          },
          "indexId": {
            "location": "uri",
            "locationName": "indexId"
          },
          "roleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "failedDocuments": {
            "shape": "S9"
          }
        }
      }
    },
    "ChatSync": {
      "http": {
        "requestUri": "/applications/{applicationId}/conversations?sync",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "userId"
        ],
        "members": {
          "actionExecution": {
            "shape": "S1n"
          },
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "attachments": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "data",
                "name"
              ],
              "members": {
                "data": {
                  "type": "blob"
                },
                "name": {}
              }
            }
          },
          "attributeFilter": {
            "shape": "S1x"
          },
          "clientToken": {
            "idempotencyToken": true
          },
          "conversationId": {},
          "parentMessageId": {},
          "userGroups": {
            "shape": "S22",
            "location": "querystring",
            "locationName": "userGroups"
          },
          "userId": {
            "location": "querystring",
            "locationName": "userId"
          },
          "userMessage": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "actionReview": {
            "shape": "S25"
          },
          "conversationId": {},
          "failedAttachments": {
            "shape": "S2e"
          },
          "sourceAttributions": {
            "shape": "S2h"
          },
          "systemMessage": {},
          "systemMessageId": {},
          "userMessageId": {}
        }
      }
    },
    "CreateApplication": {
      "http": {
        "requestUri": "/applications",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "displayName",
          "roleArn"
        ],
        "members": {
          "attachmentsConfiguration": {
            "shape": "S2m"
          },
          "clientToken": {
            "idempotencyToken": true
          },
          "description": {},
          "displayName": {},
          "encryptionConfiguration": {
            "shape": "S2q"
          },
          "roleArn": {},
          "tags": {
            "shape": "S2s"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "applicationArn": {},
          "applicationId": {}
        }
      },
      "idempotent": true
    },
    "CreateDataSource": {
      "http": {
        "requestUri": "/applications/{applicationId}/indices/{indexId}/datasources",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "configuration",
          "displayName",
          "indexId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "clientToken": {
            "idempotencyToken": true
          },
          "configuration": {
            "shape": "S2z"
          },
          "description": {},
          "displayName": {},
          "documentEnrichmentConfiguration": {
            "shape": "S19"
          },
          "indexId": {
            "location": "uri",
            "locationName": "indexId"
          },
          "roleArn": {},
          "syncSchedule": {},
          "tags": {
            "shape": "S2s"
          },
          "vpcConfiguration": {
            "shape": "S32"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "dataSourceArn": {},
          "dataSourceId": {}
        }
      },
      "idempotent": true
    },
    "CreateIndex": {
      "http": {
        "requestUri": "/applications/{applicationId}/indices",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "displayName"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "capacityConfiguration": {
            "shape": "S3a"
          },
          "clientToken": {
            "idempotencyToken": true
          },
          "description": {},
          "displayName": {},
          "tags": {
            "shape": "S2s"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "indexArn": {},
          "indexId": {}
        }
      }
    },
    "CreatePlugin": {
      "http": {
        "requestUri": "/applications/{applicationId}/plugins",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "authConfiguration",
          "displayName",
          "serverUrl",
          "type"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "authConfiguration": {
            "shape": "S3g"
          },
          "clientToken": {
            "idempotencyToken": true
          },
          "displayName": {},
          "serverUrl": {},
          "tags": {
            "shape": "S2s"
          },
          "type": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "pluginArn": {},
          "pluginId": {}
        }
      },
      "idempotent": true
    },
    "CreateRetriever": {
      "http": {
        "requestUri": "/applications/{applicationId}/retrievers",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "configuration",
          "displayName",
          "type"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "clientToken": {
            "idempotencyToken": true
          },
          "configuration": {
            "shape": "S3p"
          },
          "displayName": {},
          "roleArn": {},
          "tags": {
            "shape": "S2s"
          },
          "type": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "retrieverArn": {},
          "retrieverId": {}
        }
      }
    },
    "CreateUser": {
      "http": {
        "requestUri": "/applications/{applicationId}/users",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "userId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "clientToken": {
            "idempotencyToken": true
          },
          "userAliases": {
            "type": "list",
            "member": {
              "shape": "S4b"
            }
          },
          "userId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "CreateWebExperience": {
      "http": {
        "requestUri": "/applications/{applicationId}/experiences",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "clientToken": {
            "idempotencyToken": true
          },
          "samplePromptsControlMode": {},
          "subtitle": {},
          "tags": {
            "shape": "S2s"
          },
          "title": {},
          "welcomeMessage": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "webExperienceArn": {},
          "webExperienceId": {}
        }
      }
    },
    "DeleteApplication": {
      "http": {
        "method": "DELETE",
        "requestUri": "/applications/{applicationId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteChatControlsConfiguration": {
      "http": {
        "method": "DELETE",
        "requestUri": "/applications/{applicationId}/chatcontrols",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteConversation": {
      "http": {
        "method": "DELETE",
        "requestUri": "/applications/{applicationId}/conversations/{conversationId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "conversationId",
          "userId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "conversationId": {
            "location": "uri",
            "locationName": "conversationId"
          },
          "userId": {
            "location": "querystring",
            "locationName": "userId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteDataSource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/applications/{applicationId}/indices/{indexId}/datasources/{dataSourceId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "dataSourceId",
          "indexId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "dataSourceId": {
            "location": "uri",
            "locationName": "dataSourceId"
          },
          "indexId": {
            "location": "uri",
            "locationName": "indexId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteGroup": {
      "http": {
        "method": "DELETE",
        "requestUri": "/applications/{applicationId}/indices/{indexId}/groups/{groupName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "groupName",
          "indexId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "dataSourceId": {
            "location": "querystring",
            "locationName": "dataSourceId"
          },
          "groupName": {
            "location": "uri",
            "locationName": "groupName"
          },
          "indexId": {
            "location": "uri",
            "locationName": "indexId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteIndex": {
      "http": {
        "method": "DELETE",
        "requestUri": "/applications/{applicationId}/indices/{indexId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "indexId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "indexId": {
            "location": "uri",
            "locationName": "indexId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeletePlugin": {
      "http": {
        "method": "DELETE",
        "requestUri": "/applications/{applicationId}/plugins/{pluginId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "pluginId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "pluginId": {
            "location": "uri",
            "locationName": "pluginId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteRetriever": {
      "http": {
        "method": "DELETE",
        "requestUri": "/applications/{applicationId}/retrievers/{retrieverId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "retrieverId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "retrieverId": {
            "location": "uri",
            "locationName": "retrieverId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteUser": {
      "http": {
        "method": "DELETE",
        "requestUri": "/applications/{applicationId}/users/{userId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "userId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "userId": {
            "location": "uri",
            "locationName": "userId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteWebExperience": {
      "http": {
        "method": "DELETE",
        "requestUri": "/applications/{applicationId}/experiences/{webExperienceId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "webExperienceId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "webExperienceId": {
            "location": "uri",
            "locationName": "webExperienceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "GetApplication": {
      "http": {
        "method": "GET",
        "requestUri": "/applications/{applicationId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "applicationArn": {},
          "applicationId": {},
          "attachmentsConfiguration": {
            "type": "structure",
            "members": {
              "attachmentsControlMode": {}
            }
          },
          "createdAt": {
            "type": "timestamp"
          },
          "description": {},
          "displayName": {},
          "encryptionConfiguration": {
            "shape": "S2q"
          },
          "error": {
            "shape": "Sc"
          },
          "roleArn": {},
          "status": {},
          "updatedAt": {
            "type": "timestamp"
          }
        }
      }
    },
    "GetChatControlsConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/applications/{applicationId}/chatcontrols",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "blockedPhrases": {
            "type": "structure",
            "members": {
              "blockedPhrases": {
                "shape": "S5e"
              },
              "systemMessageOverride": {}
            }
          },
          "nextToken": {},
          "responseScope": {},
          "topicConfigurations": {
            "shape": "S5i"
          }
        }
      }
    },
    "GetDataSource": {
      "http": {
        "method": "GET",
        "requestUri": "/applications/{applicationId}/indices/{indexId}/datasources/{dataSourceId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "dataSourceId",
          "indexId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "dataSourceId": {
            "location": "uri",
            "locationName": "dataSourceId"
          },
          "indexId": {
            "location": "uri",
            "locationName": "indexId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "applicationId": {},
          "configuration": {
            "shape": "S2z"
          },
          "createdAt": {
            "type": "timestamp"
          },
          "dataSourceArn": {},
          "dataSourceId": {},
          "description": {},
          "displayName": {},
          "documentEnrichmentConfiguration": {
            "shape": "S19"
          },
          "error": {
            "shape": "Sc"
          },
          "indexId": {},
          "roleArn": {},
          "status": {},
          "syncSchedule": {},
          "type": {},
          "updatedAt": {
            "type": "timestamp"
          },
          "vpcConfiguration": {
            "shape": "S32"
          }
        }
      }
    },
    "GetGroup": {
      "http": {
        "method": "GET",
        "requestUri": "/applications/{applicationId}/indices/{indexId}/groups/{groupName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "groupName",
          "indexId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "dataSourceId": {
            "location": "querystring",
            "locationName": "dataSourceId"
          },
          "groupName": {
            "location": "uri",
            "locationName": "groupName"
          },
          "indexId": {
            "location": "uri",
            "locationName": "indexId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "status": {
            "shape": "S63"
          },
          "statusHistory": {
            "type": "list",
            "member": {
              "shape": "S63"
            }
          }
        }
      }
    },
    "GetIndex": {
      "http": {
        "method": "GET",
        "requestUri": "/applications/{applicationId}/indices/{indexId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "indexId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "indexId": {
            "location": "uri",
            "locationName": "indexId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "applicationId": {},
          "capacityConfiguration": {
            "shape": "S3a"
          },
          "createdAt": {
            "type": "timestamp"
          },
          "description": {},
          "displayName": {},
          "documentAttributeConfigurations": {
            "shape": "S68"
          },
          "error": {
            "shape": "Sc"
          },
          "indexArn": {},
          "indexId": {},
          "indexStatistics": {
            "type": "structure",
            "members": {
              "textDocumentStatistics": {
                "type": "structure",
                "members": {
                  "indexedTextBytes": {
                    "type": "long"
                  },
                  "indexedTextDocumentCount": {
                    "type": "integer"
                  }
                }
              }
            }
          },
          "status": {},
          "updatedAt": {
            "type": "timestamp"
          }
        }
      }
    },
    "GetPlugin": {
      "http": {
        "method": "GET",
        "requestUri": "/applications/{applicationId}/plugins/{pluginId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "pluginId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "pluginId": {
            "location": "uri",
            "locationName": "pluginId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "applicationId": {},
          "authConfiguration": {
            "shape": "S3g"
          },
          "createdAt": {
            "type": "timestamp"
          },
          "displayName": {},
          "pluginArn": {},
          "pluginId": {},
          "serverUrl": {},
          "state": {},
          "type": {},
          "updatedAt": {
            "type": "timestamp"
          }
        }
      }
    },
    "GetRetriever": {
      "http": {
        "method": "GET",
        "requestUri": "/applications/{applicationId}/retrievers/{retrieverId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "retrieverId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "retrieverId": {
            "location": "uri",
            "locationName": "retrieverId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "applicationId": {},
          "configuration": {
            "shape": "S3p"
          },
          "createdAt": {
            "type": "timestamp"
          },
          "displayName": {},
          "retrieverArn": {},
          "retrieverId": {},
          "roleArn": {},
          "status": {},
          "type": {},
          "updatedAt": {
            "type": "timestamp"
          }
        }
      }
    },
    "GetUser": {
      "http": {
        "method": "GET",
        "requestUri": "/applications/{applicationId}/users/{userId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "userId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "userId": {
            "location": "uri",
            "locationName": "userId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "userAliases": {
            "shape": "S6p"
          }
        }
      }
    },
    "GetWebExperience": {
      "http": {
        "method": "GET",
        "requestUri": "/applications/{applicationId}/experiences/{webExperienceId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "webExperienceId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "webExperienceId": {
            "location": "uri",
            "locationName": "webExperienceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "applicationId": {},
          "authenticationConfiguration": {
            "shape": "S6s"
          },
          "createdAt": {
            "type": "timestamp"
          },
          "defaultEndpoint": {},
          "error": {
            "shape": "Sc"
          },
          "samplePromptsControlMode": {},
          "status": {},
          "subtitle": {},
          "title": {},
          "updatedAt": {
            "type": "timestamp"
          },
          "webExperienceArn": {},
          "webExperienceId": {},
          "welcomeMessage": {}
        }
      }
    },
    "ListApplications": {
      "http": {
        "method": "GET",
        "requestUri": "/applications",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "applications": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "applicationId": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "displayName": {},
                "status": {},
                "updatedAt": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListConversations": {
      "http": {
        "method": "GET",
        "requestUri": "/applications/{applicationId}/conversations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "userId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "userId": {
            "location": "querystring",
            "locationName": "userId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "conversations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "conversationId": {},
                "startTime": {
                  "type": "timestamp"
                },
                "title": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListDataSourceSyncJobs": {
      "http": {
        "method": "GET",
        "requestUri": "/applications/{applicationId}/indices/{indexId}/datasources/{dataSourceId}/syncjobs",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "dataSourceId",
          "indexId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "dataSourceId": {
            "location": "uri",
            "locationName": "dataSourceId"
          },
          "endTime": {
            "location": "querystring",
            "locationName": "endTime",
            "type": "timestamp"
          },
          "indexId": {
            "location": "uri",
            "locationName": "indexId"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "startTime": {
            "location": "querystring",
            "locationName": "startTime",
            "type": "timestamp"
          },
          "statusFilter": {
            "location": "querystring",
            "locationName": "syncStatus"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "history": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "dataSourceErrorCode": {},
                "endTime": {
                  "type": "timestamp"
                },
                "error": {
                  "shape": "Sc"
                },
                "executionId": {},
                "metrics": {
                  "type": "structure",
                  "members": {
                    "documentsAdded": {},
                    "documentsDeleted": {},
                    "documentsFailed": {},
                    "documentsModified": {},
                    "documentsScanned": {}
                  }
                },
                "startTime": {
                  "type": "timestamp"
                },
                "status": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListDataSources": {
      "http": {
        "method": "GET",
        "requestUri": "/applications/{applicationId}/indices/{indexId}/datasources",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "indexId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "indexId": {
            "location": "uri",
            "locationName": "indexId"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "dataSources": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "createdAt": {
                  "type": "timestamp"
                },
                "dataSourceId": {},
                "displayName": {},
                "status": {},
                "type": {},
                "updatedAt": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListDocuments": {
      "http": {
        "method": "GET",
        "requestUri": "/applications/{applicationId}/index/{indexId}/documents",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "indexId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "dataSourceIds": {
            "location": "querystring",
            "locationName": "dataSourceIds",
            "type": "list",
            "member": {}
          },
          "indexId": {
            "location": "uri",
            "locationName": "indexId"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "documentDetailList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "createdAt": {
                  "type": "timestamp"
                },
                "documentId": {},
                "error": {
                  "shape": "Sc"
                },
                "status": {},
                "updatedAt": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListGroups": {
      "http": {
        "method": "GET",
        "requestUri": "/applications/{applicationId}/indices/{indexId}/groups",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "indexId",
          "updatedEarlierThan"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "dataSourceId": {
            "location": "querystring",
            "locationName": "dataSourceId"
          },
          "indexId": {
            "location": "uri",
            "locationName": "indexId"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "updatedEarlierThan": {
            "location": "querystring",
            "locationName": "updatedEarlierThan",
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "groupName": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListIndices": {
      "http": {
        "method": "GET",
        "requestUri": "/applications/{applicationId}/indices",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "indices": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "createdAt": {
                  "type": "timestamp"
                },
                "displayName": {},
                "indexId": {},
                "status": {},
                "updatedAt": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListMessages": {
      "http": {
        "method": "GET",
        "requestUri": "/applications/{applicationId}/conversations/{conversationId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "conversationId",
          "userId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "conversationId": {
            "location": "uri",
            "locationName": "conversationId"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "userId": {
            "location": "querystring",
            "locationName": "userId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "messages": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "actionExecution": {
                  "shape": "S1n"
                },
                "actionReview": {
                  "shape": "S25"
                },
                "attachments": {
                  "shape": "S2e"
                },
                "body": {},
                "messageId": {},
                "sourceAttribution": {
                  "shape": "S2h"
                },
                "time": {
                  "type": "timestamp"
                },
                "type": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListPlugins": {
      "http": {
        "method": "GET",
        "requestUri": "/applications/{applicationId}/plugins",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "plugins": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "createdAt": {
                  "type": "timestamp"
                },
                "displayName": {},
                "pluginId": {},
                "serverUrl": {},
                "state": {},
                "type": {},
                "updatedAt": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "ListRetrievers": {
      "http": {
        "method": "GET",
        "requestUri": "/applications/{applicationId}/retrievers",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "retrievers": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "applicationId": {},
                "displayName": {},
                "retrieverId": {},
                "status": {},
                "type": {}
              }
            }
          }
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/tags/{resourceARN}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceARN"
        ],
        "members": {
          "resourceARN": {
            "location": "uri",
            "locationName": "resourceARN"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "S2s"
          }
        }
      }
    },
    "ListWebExperiences": {
      "http": {
        "method": "GET",
        "requestUri": "/applications/{applicationId}/experiences",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "webExperiences": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "createdAt": {
                  "type": "timestamp"
                },
                "defaultEndpoint": {},
                "status": {},
                "updatedAt": {
                  "type": "timestamp"
                },
                "webExperienceId": {}
              }
            }
          }
        }
      }
    },
    "PutFeedback": {
      "http": {
        "requestUri": "/applications/{applicationId}/conversations/{conversationId}/messages/{messageId}/feedback",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "conversationId",
          "messageId",
          "userId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "conversationId": {
            "location": "uri",
            "locationName": "conversationId"
          },
          "messageCopiedAt": {
            "type": "timestamp"
          },
          "messageId": {
            "location": "uri",
            "locationName": "messageId"
          },
          "messageUsefulness": {
            "type": "structure",
            "required": [
              "submittedAt",
              "usefulness"
            ],
            "members": {
              "comment": {},
              "reason": {},
              "submittedAt": {
                "type": "timestamp"
              },
              "usefulness": {}
            }
          },
          "userId": {
            "location": "querystring",
            "locationName": "userId"
          }
        }
      }
    },
    "PutGroup": {
      "http": {
        "method": "PUT",
        "requestUri": "/applications/{applicationId}/indices/{indexId}/groups",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "groupMembers",
          "groupName",
          "indexId",
          "type"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "dataSourceId": {},
          "groupMembers": {
            "type": "structure",
            "members": {
              "memberGroups": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "groupName"
                  ],
                  "members": {
                    "groupName": {},
                    "type": {}
                  }
                }
              },
              "memberUsers": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "userId"
                  ],
                  "members": {
                    "type": {},
                    "userId": {}
                  }
                }
              }
            }
          },
          "groupName": {},
          "indexId": {
            "location": "uri",
            "locationName": "indexId"
          },
          "type": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "StartDataSourceSyncJob": {
      "http": {
        "requestUri": "/applications/{applicationId}/indices/{indexId}/datasources/{dataSourceId}/startsync",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "dataSourceId",
          "indexId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "dataSourceId": {
            "location": "uri",
            "locationName": "dataSourceId"
          },
          "indexId": {
            "location": "uri",
            "locationName": "indexId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "executionId": {}
        }
      }
    },
    "StopDataSourceSyncJob": {
      "http": {
        "requestUri": "/applications/{applicationId}/indices/{indexId}/datasources/{dataSourceId}/stopsync",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "dataSourceId",
          "indexId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "dataSourceId": {
            "location": "uri",
            "locationName": "dataSourceId"
          },
          "indexId": {
            "location": "uri",
            "locationName": "indexId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/v1/tags/{resourceARN}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceARN",
          "tags"
        ],
        "members": {
          "resourceARN": {
            "location": "uri",
            "locationName": "resourceARN"
          },
          "tags": {
            "shape": "S2s"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/tags/{resourceARN}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceARN",
          "tagKeys"
        ],
        "members": {
          "resourceARN": {
            "location": "uri",
            "locationName": "resourceARN"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateApplication": {
      "http": {
        "method": "PUT",
        "requestUri": "/applications/{applicationId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "attachmentsConfiguration": {
            "shape": "S2m"
          },
          "description": {},
          "displayName": {},
          "roleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateChatControlsConfiguration": {
      "http": {
        "method": "PATCH",
        "requestUri": "/applications/{applicationId}/chatcontrols",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "blockedPhrasesConfigurationUpdate": {
            "type": "structure",
            "members": {
              "blockedPhrasesToCreateOrUpdate": {
                "shape": "S5e"
              },
              "blockedPhrasesToDelete": {
                "shape": "S5e"
              },
              "systemMessageOverride": {}
            }
          },
          "clientToken": {
            "idempotencyToken": true
          },
          "responseScope": {},
          "topicConfigurationsToCreateOrUpdate": {
            "shape": "S5i"
          },
          "topicConfigurationsToDelete": {
            "shape": "S5i"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateDataSource": {
      "http": {
        "method": "PUT",
        "requestUri": "/applications/{applicationId}/indices/{indexId}/datasources/{dataSourceId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "dataSourceId",
          "indexId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "configuration": {
            "shape": "S2z"
          },
          "dataSourceId": {
            "location": "uri",
            "locationName": "dataSourceId"
          },
          "description": {},
          "displayName": {},
          "documentEnrichmentConfiguration": {
            "shape": "S19"
          },
          "indexId": {
            "location": "uri",
            "locationName": "indexId"
          },
          "roleArn": {},
          "syncSchedule": {},
          "vpcConfiguration": {
            "shape": "S32"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateIndex": {
      "http": {
        "method": "PUT",
        "requestUri": "/applications/{applicationId}/indices/{indexId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "indexId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "capacityConfiguration": {
            "shape": "S3a"
          },
          "description": {},
          "displayName": {},
          "documentAttributeConfigurations": {
            "shape": "S68"
          },
          "indexId": {
            "location": "uri",
            "locationName": "indexId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdatePlugin": {
      "http": {
        "method": "PUT",
        "requestUri": "/applications/{applicationId}/plugins/{pluginId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "pluginId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "authConfiguration": {
            "shape": "S3g"
          },
          "displayName": {},
          "pluginId": {
            "location": "uri",
            "locationName": "pluginId"
          },
          "serverUrl": {},
          "state": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateRetriever": {
      "http": {
        "method": "PUT",
        "requestUri": "/applications/{applicationId}/retrievers/{retrieverId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "retrieverId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "configuration": {
            "shape": "S3p"
          },
          "displayName": {},
          "retrieverId": {
            "location": "uri",
            "locationName": "retrieverId"
          },
          "roleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateUser": {
      "http": {
        "method": "PUT",
        "requestUri": "/applications/{applicationId}/users/{userId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "userId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "userAliasesToDelete": {
            "shape": "S6p"
          },
          "userAliasesToUpdate": {
            "shape": "S6p"
          },
          "userId": {
            "location": "uri",
            "locationName": "userId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "userAliasesAdded": {
            "shape": "S6p"
          },
          "userAliasesDeleted": {
            "shape": "S6p"
          },
          "userAliasesUpdated": {
            "shape": "S6p"
          }
        }
      },
      "idempotent": true
    },
    "UpdateWebExperience": {
      "http": {
        "method": "PUT",
        "requestUri": "/applications/{applicationId}/experiences/{webExperienceId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicationId",
          "webExperienceId"
        ],
        "members": {
          "applicationId": {
            "location": "uri",
            "locationName": "applicationId"
          },
          "authenticationConfiguration": {
            "shape": "S6s"
          },
          "samplePromptsControlMode": {},
          "subtitle": {},
          "title": {},
          "webExperienceId": {
            "location": "uri",
            "locationName": "webExperienceId"
          },
          "welcomeMessage": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    }
  },
  "shapes": {
    "S9": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "dataSourceId": {},
          "error": {
            "shape": "Sc"
          },
          "id": {}
        }
      }
    },
    "Sc": {
      "type": "structure",
      "members": {
        "errorCode": {},
        "errorMessage": {}
      }
    },
    "Sv": {
      "type": "structure",
      "required": [
        "name",
        "value"
      ],
      "members": {
        "name": {},
        "value": {
          "shape": "Sx"
        }
      }
    },
    "Sx": {
      "type": "structure",
      "members": {
        "dateValue": {
          "type": "timestamp"
        },
        "longValue": {
          "type": "long"
        },
        "stringListValue": {
          "type": "list",
          "member": {}
        },
        "stringValue": {}
      },
      "union": true
    },
    "S19": {
      "type": "structure",
      "members": {
        "inlineConfigurations": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "condition": {
                "shape": "S1c"
              },
              "documentContentOperator": {},
              "target": {
                "type": "structure",
                "required": [
                  "key"
                ],
                "members": {
                  "attributeValueOperator": {},
                  "key": {},
                  "value": {
                    "shape": "Sx"
                  }
                }
              }
            }
          }
        },
        "postExtractionHookConfiguration": {
          "shape": "S1h"
        },
        "preExtractionHookConfiguration": {
          "shape": "S1h"
        }
      }
    },
    "S1c": {
      "type": "structure",
      "required": [
        "key",
        "operator"
      ],
      "members": {
        "key": {},
        "operator": {},
        "value": {
          "shape": "Sx"
        }
      }
    },
    "S1h": {
      "type": "structure",
      "members": {
        "invocationCondition": {
          "shape": "S1c"
        },
        "lambdaArn": {},
        "roleArn": {},
        "s3BucketName": {}
      }
    },
    "S1n": {
      "type": "structure",
      "required": [
        "payload",
        "payloadFieldNameSeparator",
        "pluginId"
      ],
      "members": {
        "payload": {
          "type": "map",
          "key": {},
          "value": {
            "type": "structure",
            "required": [
              "value"
            ],
            "members": {
              "value": {
                "shape": "S1r"
              }
            }
          }
        },
        "payloadFieldNameSeparator": {},
        "pluginId": {}
      }
    },
    "S1r": {
      "type": "structure",
      "members": {},
      "document": true
    },
    "S1x": {
      "type": "structure",
      "members": {
        "andAllFilters": {
          "shape": "S1y"
        },
        "containsAll": {
          "shape": "Sv"
        },
        "containsAny": {
          "shape": "Sv"
        },
        "equalsTo": {
          "shape": "Sv"
        },
        "greaterThan": {
          "shape": "Sv"
        },
        "greaterThanOrEquals": {
          "shape": "Sv"
        },
        "lessThan": {
          "shape": "Sv"
        },
        "lessThanOrEquals": {
          "shape": "Sv"
        },
        "notFilter": {
          "shape": "S1x"
        },
        "orAllFilters": {
          "shape": "S1y"
        }
      }
    },
    "S1y": {
      "type": "list",
      "member": {
        "shape": "S1x"
      }
    },
    "S22": {
      "type": "list",
      "member": {}
    },
    "S25": {
      "type": "structure",
      "members": {
        "payload": {
          "type": "map",
          "key": {},
          "value": {
            "type": "structure",
            "members": {
              "allowedValues": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "displayValue": {
                      "shape": "S1r"
                    },
                    "value": {
                      "shape": "S1r"
                    }
                  }
                }
              },
              "displayName": {},
              "displayOrder": {
                "type": "integer"
              },
              "required": {
                "type": "boolean"
              },
              "type": {},
              "value": {
                "shape": "S1r"
              }
            }
          }
        },
        "payloadFieldNameSeparator": {},
        "pluginId": {},
        "pluginType": {}
      }
    },
    "S2e": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "error": {
            "shape": "Sc"
          },
          "name": {},
          "status": {}
        }
      }
    },
    "S2h": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "citationNumber": {
            "type": "integer"
          },
          "snippet": {},
          "textMessageSegments": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "beginOffset": {
                  "type": "integer"
                },
                "endOffset": {
                  "type": "integer"
                }
              }
            }
          },
          "title": {},
          "updatedAt": {
            "type": "timestamp"
          },
          "url": {}
        }
      }
    },
    "S2m": {
      "type": "structure",
      "required": [
        "attachmentsControlMode"
      ],
      "members": {
        "attachmentsControlMode": {}
      }
    },
    "S2q": {
      "type": "structure",
      "members": {
        "kmsKeyId": {
          "type": "string",
          "sensitive": true
        }
      }
    },
    "S2s": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "key",
          "value"
        ],
        "members": {
          "key": {},
          "value": {}
        }
      }
    },
    "S2z": {
      "type": "structure",
      "members": {},
      "document": true
    },
    "S32": {
      "type": "structure",
      "required": [
        "securityGroupIds",
        "subnetIds"
      ],
      "members": {
        "securityGroupIds": {
          "type": "list",
          "member": {}
        },
        "subnetIds": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S3a": {
      "type": "structure",
      "members": {
        "units": {
          "type": "integer"
        }
      }
    },
    "S3g": {
      "type": "structure",
      "members": {
        "basicAuthConfiguration": {
          "type": "structure",
          "required": [
            "roleArn",
            "secretArn"
          ],
          "members": {
            "roleArn": {},
            "secretArn": {}
          }
        },
        "oAuth2ClientCredentialConfiguration": {
          "type": "structure",
          "required": [
            "roleArn",
            "secretArn"
          ],
          "members": {
            "roleArn": {},
            "secretArn": {}
          }
        }
      },
      "union": true
    },
    "S3p": {
      "type": "structure",
      "members": {
        "kendraIndexConfiguration": {
          "type": "structure",
          "required": [
            "indexId"
          ],
          "members": {
            "indexId": {}
          }
        },
        "nativeIndexConfiguration": {
          "type": "structure",
          "required": [
            "indexId"
          ],
          "members": {
            "boostingOverride": {
              "type": "map",
              "key": {},
              "value": {
                "type": "structure",
                "members": {
                  "dateConfiguration": {
                    "type": "structure",
                    "required": [
                      "boostingLevel"
                    ],
                    "members": {
                      "boostingDurationInSeconds": {
                        "type": "long"
                      },
                      "boostingLevel": {}
                    }
                  },
                  "numberConfiguration": {
                    "type": "structure",
                    "required": [
                      "boostingLevel"
                    ],
                    "members": {
                      "boostingLevel": {},
                      "boostingType": {}
                    }
                  },
                  "stringConfiguration": {
                    "type": "structure",
                    "required": [
                      "boostingLevel"
                    ],
                    "members": {
                      "attributeValueBoosting": {
                        "type": "map",
                        "key": {},
                        "value": {}
                      },
                      "boostingLevel": {}
                    }
                  },
                  "stringListConfiguration": {
                    "type": "structure",
                    "required": [
                      "boostingLevel"
                    ],
                    "members": {
                      "boostingLevel": {}
                    }
                  }
                },
                "union": true
              }
            },
            "indexId": {}
          }
        }
      },
      "union": true
    },
    "S4b": {
      "type": "structure",
      "required": [
        "userId"
      ],
      "members": {
        "dataSourceId": {},
        "indexId": {},
        "userId": {}
      }
    },
    "S5e": {
      "type": "list",
      "member": {}
    },
    "S5i": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "name",
          "rules"
        ],
        "members": {
          "description": {},
          "exampleChatMessages": {
            "type": "list",
            "member": {}
          },
          "name": {},
          "rules": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ruleType"
              ],
              "members": {
                "excludedUsersAndGroups": {
                  "shape": "S5q"
                },
                "includedUsersAndGroups": {
                  "shape": "S5q"
                },
                "ruleConfiguration": {
                  "type": "structure",
                  "members": {
                    "contentBlockerRule": {
                      "type": "structure",
                      "members": {
                        "systemMessageOverride": {}
                      }
                    },
                    "contentRetrievalRule": {
                      "type": "structure",
                      "members": {
                        "eligibleDataSources": {
                          "type": "list",
                          "member": {
                            "type": "structure",
                            "members": {
                              "dataSourceId": {},
                              "indexId": {}
                            }
                          }
                        }
                      }
                    }
                  },
                  "union": true
                },
                "ruleType": {}
              }
            }
          }
        }
      }
    },
    "S5q": {
      "type": "structure",
      "members": {
        "userGroups": {
          "shape": "S22"
        },
        "userIds": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S63": {
      "type": "structure",
      "members": {
        "errorDetail": {
          "shape": "Sc"
        },
        "lastUpdatedAt": {
          "type": "timestamp"
        },
        "status": {}
      }
    },
    "S68": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "name": {},
          "search": {},
          "type": {}
        }
      }
    },
    "S6p": {
      "type": "list",
      "member": {
        "shape": "S4b"
      }
    },
    "S6s": {
      "type": "structure",
      "members": {
        "samlConfiguration": {
          "type": "structure",
          "required": [
            "metadataXML",
            "roleArn",
            "userIdAttribute"
          ],
          "members": {
            "metadataXML": {},
            "roleArn": {},
            "userGroupAttribute": {},
            "userIdAttribute": {}
          }
        }
      },
      "union": true
    }
  }
}