{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-07-24",
    "endpointPrefix": "api.sagemaker",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "SageMaker",
    "serviceFullName": "Amazon SageMaker Service",
    "serviceId": "SageMaker",
    "signatureVersion": "v4",
    "signingName": "sagemaker",
    "targetPrefix": "SageMaker",
    "uid": "sagemaker-2017-07-24"
  },
  "operations": {
    "AddAssociation": {
      "input": {
        "type": "structure",
        "required": [
          "SourceArn",
          "DestinationArn"
        ],
        "members": {
          "SourceArn": {},
          "DestinationArn": {},
          "AssociationType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SourceArn": {},
          "DestinationArn": {}
        }
      }
    },
    "AddTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {},
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S7"
          }
        }
      }
    },
    "AssociateTrialComponent": {
      "input": {
        "type": "structure",
        "required": [
          "TrialComponentName",
          "TrialName"
        ],
        "members": {
          "TrialComponentName": {},
          "TrialName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrialComponentArn": {},
          "TrialArn": {}
        }
      }
    },
    "BatchDescribeModelPackage": {
      "input": {
        "type": "structure",
        "required": [
          "ModelPackageArnList"
        ],
        "members": {
          "ModelPackageArnList": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ModelPackageSummaries": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "required": [
                "ModelPackageGroupName",
                "ModelPackageArn",
                "CreationTime",
                "InferenceSpecification",
                "ModelPackageStatus"
              ],
              "members": {
                "ModelPackageGroupName": {},
                "ModelPackageVersion": {
                  "type": "integer"
                },
                "ModelPackageArn": {},
                "ModelPackageDescription": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "InferenceSpecification": {
                  "shape": "Sr"
                },
                "ModelPackageStatus": {},
                "ModelApprovalStatus": {}
              }
            }
          },
          "BatchDescribeModelPackageErrorMap": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "required": [
                "ErrorCode",
                "ErrorResponse"
              ],
              "members": {
                "ErrorCode": {},
                "ErrorResponse": {}
              }
            }
          }
        }
      }
    },
    "CreateAction": {
      "input": {
        "type": "structure",
        "required": [
          "ActionName",
          "Source",
          "ActionType"
        ],
        "members": {
          "ActionName": {},
          "Source": {
            "shape": "S1u"
          },
          "ActionType": {},
          "Description": {},
          "Status": {},
          "Properties": {
            "shape": "S1z"
          },
          "MetadataProperties": {
            "shape": "S21"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ActionArn": {}
        }
      }
    },
    "CreateAlgorithm": {
      "input": {
        "type": "structure",
        "required": [
          "AlgorithmName",
          "TrainingSpecification"
        ],
        "members": {
          "AlgorithmName": {},
          "AlgorithmDescription": {},
          "TrainingSpecification": {
            "shape": "S26"
          },
          "InferenceSpecification": {
            "shape": "Sr"
          },
          "ValidationSpecification": {
            "shape": "S2y"
          },
          "CertifyForMarketplace": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "AlgorithmArn"
        ],
        "members": {
          "AlgorithmArn": {}
        }
      }
    },
    "CreateApp": {
      "input": {
        "type": "structure",
        "required": [
          "DomainId",
          "AppType",
          "AppName"
        ],
        "members": {
          "DomainId": {},
          "UserProfileName": {},
          "SpaceName": {},
          "AppType": {},
          "AppName": {},
          "Tags": {
            "shape": "S7"
          },
          "ResourceSpec": {
            "shape": "S4p"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AppArn": {}
        }
      }
    },
    "CreateAppImageConfig": {
      "input": {
        "type": "structure",
        "required": [
          "AppImageConfigName"
        ],
        "members": {
          "AppImageConfigName": {},
          "Tags": {
            "shape": "S7"
          },
          "KernelGatewayImageConfig": {
            "shape": "S4z"
          },
          "JupyterLabAppImageConfig": {
            "shape": "S58"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AppImageConfigArn": {}
        }
      }
    },
    "CreateArtifact": {
      "input": {
        "type": "structure",
        "required": [
          "Source",
          "ArtifactType"
        ],
        "members": {
          "ArtifactName": {},
          "Source": {
            "shape": "S5i"
          },
          "ArtifactType": {},
          "Properties": {
            "shape": "S5m"
          },
          "MetadataProperties": {
            "shape": "S21"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ArtifactArn": {}
        }
      }
    },
    "CreateAutoMLJob": {
      "input": {
        "type": "structure",
        "required": [
          "AutoMLJobName",
          "InputDataConfig",
          "OutputDataConfig",
          "RoleArn"
        ],
        "members": {
          "AutoMLJobName": {},
          "InputDataConfig": {
            "shape": "S5s"
          },
          "OutputDataConfig": {
            "shape": "S60"
          },
          "ProblemType": {},
          "AutoMLJobObjective": {
            "shape": "S62"
          },
          "AutoMLJobConfig": {
            "shape": "S64"
          },
          "RoleArn": {},
          "GenerateCandidateDefinitionsOnly": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "S7"
          },
          "ModelDeployConfig": {
            "shape": "S6o"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "AutoMLJobArn"
        ],
        "members": {
          "AutoMLJobArn": {}
        }
      }
    },
    "CreateAutoMLJobV2": {
      "input": {
        "type": "structure",
        "required": [
          "AutoMLJobName",
          "AutoMLJobInputDataConfig",
          "OutputDataConfig",
          "AutoMLProblemTypeConfig",
          "RoleArn"
        ],
        "members": {
          "AutoMLJobName": {},
          "AutoMLJobInputDataConfig": {
            "shape": "S6u"
          },
          "OutputDataConfig": {
            "shape": "S60"
          },
          "AutoMLProblemTypeConfig": {
            "shape": "S6w"
          },
          "RoleArn": {},
          "Tags": {
            "shape": "S7"
          },
          "SecurityConfig": {
            "shape": "S69"
          },
          "AutoMLJobObjective": {
            "shape": "S62"
          },
          "ModelDeployConfig": {
            "shape": "S6o"
          },
          "DataSplitConfig": {
            "shape": "S6k"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "AutoMLJobArn"
        ],
        "members": {
          "AutoMLJobArn": {}
        }
      }
    },
    "CreateCluster": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterName",
          "InstanceGroups"
        ],
        "members": {
          "ClusterName": {},
          "InstanceGroups": {
            "shape": "S7w"
          },
          "VpcConfig": {
            "shape": "S6a"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ClusterArn"
        ],
        "members": {
          "ClusterArn": {}
        }
      }
    },
    "CreateCodeRepository": {
      "input": {
        "type": "structure",
        "required": [
          "CodeRepositoryName",
          "GitConfig"
        ],
        "members": {
          "CodeRepositoryName": {},
          "GitConfig": {
            "shape": "S87"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CodeRepositoryArn"
        ],
        "members": {
          "CodeRepositoryArn": {}
        }
      }
    },
    "CreateCompilationJob": {
      "input": {
        "type": "structure",
        "required": [
          "CompilationJobName",
          "RoleArn",
          "OutputConfig",
          "StoppingCondition"
        ],
        "members": {
          "CompilationJobName": {},
          "RoleArn": {},
          "ModelPackageVersionArn": {},
          "InputConfig": {
            "shape": "S8e"
          },
          "OutputConfig": {
            "shape": "S8h"
          },
          "VpcConfig": {
            "shape": "S8o"
          },
          "StoppingCondition": {
            "shape": "S3w"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CompilationJobArn"
        ],
        "members": {
          "CompilationJobArn": {}
        }
      }
    },
    "CreateContext": {
      "input": {
        "type": "structure",
        "required": [
          "ContextName",
          "Source",
          "ContextType"
        ],
        "members": {
          "ContextName": {},
          "Source": {
            "shape": "S8x"
          },
          "ContextType": {},
          "Description": {},
          "Properties": {
            "shape": "S1z"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ContextArn": {}
        }
      }
    },
    "CreateDataQualityJobDefinition": {
      "input": {
        "type": "structure",
        "required": [
          "JobDefinitionName",
          "DataQualityAppSpecification",
          "DataQualityJobInput",
          "DataQualityJobOutputConfig",
          "JobResources",
          "RoleArn"
        ],
        "members": {
          "JobDefinitionName": {},
          "DataQualityBaselineConfig": {
            "shape": "S92"
          },
          "DataQualityAppSpecification": {
            "shape": "S96"
          },
          "DataQualityJobInput": {
            "shape": "S9f"
          },
          "DataQualityJobOutputConfig": {
            "shape": "S9t"
          },
          "JobResources": {
            "shape": "S9z"
          },
          "NetworkConfig": {
            "shape": "Sa4"
          },
          "RoleArn": {},
          "StoppingCondition": {
            "shape": "Sa5"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "JobDefinitionArn"
        ],
        "members": {
          "JobDefinitionArn": {}
        }
      }
    },
    "CreateDeviceFleet": {
      "input": {
        "type": "structure",
        "required": [
          "DeviceFleetName",
          "OutputConfig"
        ],
        "members": {
          "DeviceFleetName": {},
          "RoleArn": {},
          "Description": {},
          "OutputConfig": {
            "shape": "Sab"
          },
          "Tags": {
            "shape": "S7"
          },
          "EnableIotRoleAlias": {
            "type": "boolean"
          }
        }
      }
    },
    "CreateDomain": {
      "input": {
        "type": "structure",
        "required": [
          "DomainName",
          "AuthMode",
          "DefaultUserSettings",
          "SubnetIds",
          "VpcId"
        ],
        "members": {
          "DomainName": {},
          "AuthMode": {},
          "DefaultUserSettings": {
            "shape": "Sah"
          },
          "DomainSettings": {
            "shape": "Sbp"
          },
          "SubnetIds": {
            "shape": "S6d"
          },
          "VpcId": {},
          "Tags": {
            "shape": "S7"
          },
          "AppNetworkAccessType": {},
          "HomeEfsFileSystemKmsKeyId": {
            "deprecated": true,
            "deprecatedMessage": "This property is deprecated, use KmsKeyId instead."
          },
          "KmsKeyId": {},
          "AppSecurityGroupManagement": {},
          "DefaultSpaceSettings": {
            "shape": "Sbz"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DomainArn": {},
          "Url": {}
        }
      }
    },
    "CreateEdgeDeploymentPlan": {
      "input": {
        "type": "structure",
        "required": [
          "EdgeDeploymentPlanName",
          "ModelConfigs",
          "DeviceFleetName"
        ],
        "members": {
          "EdgeDeploymentPlanName": {},
          "ModelConfigs": {
            "shape": "Sc4"
          },
          "DeviceFleetName": {},
          "Stages": {
            "shape": "Sc6"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "EdgeDeploymentPlanArn"
        ],
        "members": {
          "EdgeDeploymentPlanArn": {}
        }
      }
    },
    "CreateEdgeDeploymentStage": {
      "input": {
        "type": "structure",
        "required": [
          "EdgeDeploymentPlanName",
          "Stages"
        ],
        "members": {
          "EdgeDeploymentPlanName": {},
          "Stages": {
            "shape": "Sc6"
          }
        }
      }
    },
    "CreateEdgePackagingJob": {
      "input": {
        "type": "structure",
        "required": [
          "EdgePackagingJobName",
          "CompilationJobName",
          "ModelName",
          "ModelVersion",
          "RoleArn",
          "OutputConfig"
        ],
        "members": {
          "EdgePackagingJobName": {},
          "CompilationJobName": {},
          "ModelName": {},
          "ModelVersion": {},
          "RoleArn": {},
          "OutputConfig": {
            "shape": "Sab"
          },
          "ResourceKey": {},
          "Tags": {
            "shape": "S7"
          }
        }
      }
    },
    "CreateEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointName",
          "EndpointConfigName"
        ],
        "members": {
          "EndpointName": {},
          "EndpointConfigName": {},
          "DeploymentConfig": {
            "shape": "Scm"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "EndpointArn"
        ],
        "members": {
          "EndpointArn": {}
        }
      }
    },
    "CreateEndpointConfig": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointConfigName",
          "ProductionVariants"
        ],
        "members": {
          "EndpointConfigName": {},
          "ProductionVariants": {
            "shape": "Sd4"
          },
          "DataCaptureConfig": {
            "shape": "Sdq"
          },
          "Tags": {
            "shape": "S7"
          },
          "KmsKeyId": {},
          "AsyncInferenceConfig": {
            "shape": "Se1"
          },
          "ExplainerConfig": {
            "shape": "Se9"
          },
          "ShadowProductionVariants": {
            "shape": "Sd4"
          },
          "ExecutionRoleArn": {},
          "VpcConfig": {
            "shape": "S6a"
          },
          "EnableNetworkIsolation": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "EndpointConfigArn"
        ],
        "members": {
          "EndpointConfigArn": {}
        }
      }
    },
    "CreateExperiment": {
      "input": {
        "type": "structure",
        "required": [
          "ExperimentName"
        ],
        "members": {
          "ExperimentName": {},
          "DisplayName": {},
          "Description": {},
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ExperimentArn": {}
        }
      }
    },
    "CreateFeatureGroup": {
      "input": {
        "type": "structure",
        "required": [
          "FeatureGroupName",
          "RecordIdentifierFeatureName",
          "EventTimeFeatureName",
          "FeatureDefinitions"
        ],
        "members": {
          "FeatureGroupName": {},
          "RecordIdentifierFeatureName": {},
          "EventTimeFeatureName": {},
          "FeatureDefinitions": {
            "shape": "Sf8"
          },
          "OnlineStoreConfig": {
            "shape": "Sff"
          },
          "OfflineStoreConfig": {
            "shape": "Sfl"
          },
          "ThroughputConfig": {
            "type": "structure",
            "required": [
              "ThroughputMode"
            ],
            "members": {
              "ThroughputMode": {},
              "ProvisionedReadCapacityUnits": {
                "type": "integer"
              },
              "ProvisionedWriteCapacityUnits": {
                "type": "integer"
              }
            }
          },
          "RoleArn": {},
          "Description": {},
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "FeatureGroupArn"
        ],
        "members": {
          "FeatureGroupArn": {}
        }
      }
    },
    "CreateFlowDefinition": {
      "input": {
        "type": "structure",
        "required": [
          "FlowDefinitionName",
          "OutputConfig",
          "RoleArn"
        ],
        "members": {
          "FlowDefinitionName": {},
          "HumanLoopRequestSource": {
            "shape": "Sg0"
          },
          "HumanLoopActivationConfig": {
            "shape": "Sg2"
          },
          "HumanLoopConfig": {
            "shape": "Sg5"
          },
          "OutputConfig": {
            "shape": "Sgk"
          },
          "RoleArn": {},
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "FlowDefinitionArn"
        ],
        "members": {
          "FlowDefinitionArn": {}
        }
      }
    },
    "CreateHub": {
      "input": {
        "type": "structure",
        "required": [
          "HubName",
          "HubDescription"
        ],
        "members": {
          "HubName": {},
          "HubDescription": {},
          "HubDisplayName": {},
          "HubSearchKeywords": {
            "shape": "Sgr"
          },
          "S3StorageConfig": {
            "shape": "Sgt"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "HubArn"
        ],
        "members": {
          "HubArn": {}
        }
      }
    },
    "CreateHumanTaskUi": {
      "input": {
        "type": "structure",
        "required": [
          "HumanTaskUiName",
          "UiTemplate"
        ],
        "members": {
          "HumanTaskUiName": {},
          "UiTemplate": {
            "shape": "Sgz"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "HumanTaskUiArn"
        ],
        "members": {
          "HumanTaskUiArn": {}
        }
      }
    },
    "CreateHyperParameterTuningJob": {
      "input": {
        "type": "structure",
        "required": [
          "HyperParameterTuningJobName",
          "HyperParameterTuningJobConfig"
        ],
        "members": {
          "HyperParameterTuningJobName": {},
          "HyperParameterTuningJobConfig": {
            "shape": "Sh4"
          },
          "TrainingJobDefinition": {
            "shape": "Shx"
          },
          "TrainingJobDefinitions": {
            "shape": "Sid"
          },
          "WarmStartConfig": {
            "shape": "Sie"
          },
          "Tags": {
            "shape": "S7"
          },
          "Autotune": {
            "shape": "Sii"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "HyperParameterTuningJobArn"
        ],
        "members": {
          "HyperParameterTuningJobArn": {}
        }
      }
    },
    "CreateImage": {
      "input": {
        "type": "structure",
        "required": [
          "ImageName",
          "RoleArn"
        ],
        "members": {
          "Description": {},
          "DisplayName": {},
          "ImageName": {},
          "RoleArn": {},
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImageArn": {}
        }
      }
    },
    "CreateImageVersion": {
      "input": {
        "type": "structure",
        "required": [
          "BaseImage",
          "ClientToken",
          "ImageName"
        ],
        "members": {
          "BaseImage": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "ImageName": {},
          "Aliases": {
            "shape": "Sit"
          },
          "VendorGuidance": {},
          "JobType": {},
          "MLFramework": {},
          "ProgrammingLang": {},
          "Processor": {},
          "Horovod": {
            "type": "boolean"
          },
          "ReleaseNotes": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImageVersionArn": {}
        }
      }
    },
    "CreateInferenceComponent": {
      "input": {
        "type": "structure",
        "required": [
          "InferenceComponentName",
          "EndpointName",
          "VariantName",
          "Specification",
          "RuntimeConfig"
        ],
        "members": {
          "InferenceComponentName": {},
          "EndpointName": {},
          "VariantName": {},
          "Specification": {
            "shape": "Sj5"
          },
          "RuntimeConfig": {
            "shape": "Sjc"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "InferenceComponentArn"
        ],
        "members": {
          "InferenceComponentArn": {}
        }
      }
    },
    "CreateInferenceExperiment": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Type",
          "RoleArn",
          "EndpointName",
          "ModelVariants",
          "ShadowModeConfig"
        ],
        "members": {
          "Name": {},
          "Type": {},
          "Schedule": {
            "shape": "Sjj"
          },
          "Description": {},
          "RoleArn": {},
          "EndpointName": {},
          "ModelVariants": {
            "shape": "Sjm"
          },
          "DataStorageConfig": {
            "shape": "Sju"
          },
          "ShadowModeConfig": {
            "shape": "Sjv"
          },
          "KmsKey": {},
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "InferenceExperimentArn"
        ],
        "members": {
          "InferenceExperimentArn": {}
        }
      }
    },
    "CreateInferenceRecommendationsJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobName",
          "JobType",
          "RoleArn",
          "InputConfig"
        ],
        "members": {
          "JobName": {},
          "JobType": {},
          "RoleArn": {},
          "InputConfig": {
            "shape": "Sk3"
          },
          "JobDescription": {},
          "StoppingConditions": {
            "shape": "Sl9"
          },
          "OutputConfig": {
            "type": "structure",
            "members": {
              "KmsKeyId": {},
              "CompiledOutputConfig": {
                "type": "structure",
                "members": {
                  "S3OutputUri": {}
                }
              }
            }
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "JobArn"
        ],
        "members": {
          "JobArn": {}
        }
      }
    },
    "CreateLabelingJob": {
      "input": {
        "type": "structure",
        "required": [
          "LabelingJobName",
          "LabelAttributeName",
          "InputConfig",
          "OutputConfig",
          "RoleArn",
          "HumanTaskConfig"
        ],
        "members": {
          "LabelingJobName": {},
          "LabelAttributeName": {},
          "InputConfig": {
            "shape": "Sll"
          },
          "OutputConfig": {
            "shape": "Sls"
          },
          "RoleArn": {},
          "LabelCategoryConfigS3Uri": {},
          "StoppingConditions": {
            "shape": "Slt"
          },
          "LabelingJobAlgorithmsConfig": {
            "shape": "Slw"
          },
          "HumanTaskConfig": {
            "shape": "Sm0"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "LabelingJobArn"
        ],
        "members": {
          "LabelingJobArn": {}
        }
      }
    },
    "CreateModel": {
      "input": {
        "type": "structure",
        "required": [
          "ModelName"
        ],
        "members": {
          "ModelName": {},
          "PrimaryContainer": {
            "shape": "Smf"
          },
          "Containers": {
            "shape": "Smo"
          },
          "InferenceExecutionConfig": {
            "shape": "Smp"
          },
          "ExecutionRoleArn": {},
          "Tags": {
            "shape": "S7"
          },
          "VpcConfig": {
            "shape": "S6a"
          },
          "EnableNetworkIsolation": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ModelArn"
        ],
        "members": {
          "ModelArn": {}
        }
      }
    },
    "CreateModelBiasJobDefinition": {
      "input": {
        "type": "structure",
        "required": [
          "JobDefinitionName",
          "ModelBiasAppSpecification",
          "ModelBiasJobInput",
          "ModelBiasJobOutputConfig",
          "JobResources",
          "RoleArn"
        ],
        "members": {
          "JobDefinitionName": {},
          "ModelBiasBaselineConfig": {
            "shape": "Smt"
          },
          "ModelBiasAppSpecification": {
            "shape": "Smu"
          },
          "ModelBiasJobInput": {
            "shape": "Smv"
          },
          "ModelBiasJobOutputConfig": {
            "shape": "S9t"
          },
          "JobResources": {
            "shape": "S9z"
          },
          "NetworkConfig": {
            "shape": "Sa4"
          },
          "RoleArn": {},
          "StoppingCondition": {
            "shape": "Sa5"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "JobDefinitionArn"
        ],
        "members": {
          "JobDefinitionArn": {}
        }
      }
    },
    "CreateModelCard": {
      "input": {
        "type": "structure",
        "required": [
          "ModelCardName",
          "Content",
          "ModelCardStatus"
        ],
        "members": {
          "ModelCardName": {},
          "SecurityConfig": {
            "shape": "Smz"
          },
          "Content": {
            "shape": "Sn0"
          },
          "ModelCardStatus": {},
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ModelCardArn"
        ],
        "members": {
          "ModelCardArn": {}
        }
      }
    },
    "CreateModelCardExportJob": {
      "input": {
        "type": "structure",
        "required": [
          "ModelCardName",
          "ModelCardExportJobName",
          "OutputConfig"
        ],
        "members": {
          "ModelCardName": {},
          "ModelCardVersion": {
            "type": "integer"
          },
          "ModelCardExportJobName": {},
          "OutputConfig": {
            "shape": "Sn6"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ModelCardExportJobArn"
        ],
        "members": {
          "ModelCardExportJobArn": {}
        }
      }
    },
    "CreateModelExplainabilityJobDefinition": {
      "input": {
        "type": "structure",
        "required": [
          "JobDefinitionName",
          "ModelExplainabilityAppSpecification",
          "ModelExplainabilityJobInput",
          "ModelExplainabilityJobOutputConfig",
          "JobResources",
          "RoleArn"
        ],
        "members": {
          "JobDefinitionName": {},
          "ModelExplainabilityBaselineConfig": {
            "shape": "Sna"
          },
          "ModelExplainabilityAppSpecification": {
            "shape": "Snb"
          },
          "ModelExplainabilityJobInput": {
            "shape": "Snc"
          },
          "ModelExplainabilityJobOutputConfig": {
            "shape": "S9t"
          },
          "JobResources": {
            "shape": "S9z"
          },
          "NetworkConfig": {
            "shape": "Sa4"
          },
          "RoleArn": {},
          "StoppingCondition": {
            "shape": "Sa5"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "JobDefinitionArn"
        ],
        "members": {
          "JobDefinitionArn": {}
        }
      }
    },
    "CreateModelPackage": {
      "input": {
        "type": "structure",
        "members": {
          "ModelPackageName": {},
          "ModelPackageGroupName": {},
          "ModelPackageDescription": {},
          "InferenceSpecification": {
            "shape": "Sr"
          },
          "ValidationSpecification": {
            "shape": "Snf"
          },
          "SourceAlgorithmSpecification": {
            "shape": "Sni"
          },
          "CertifyForMarketplace": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "S7"
          },
          "ModelApprovalStatus": {},
          "MetadataProperties": {
            "shape": "S21"
          },
          "ModelMetrics": {
            "shape": "Snl"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "Domain": {},
          "Task": {},
          "SamplePayloadUrl": {},
          "CustomerMetadataProperties": {
            "shape": "Sns"
          },
          "DriftCheckBaselines": {
            "shape": "Snv"
          },
          "AdditionalInferenceSpecifications": {
            "shape": "So1"
          },
          "SkipModelValidation": {},
          "SourceUri": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ModelPackageArn"
        ],
        "members": {
          "ModelPackageArn": {}
        }
      }
    },
    "CreateModelPackageGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ModelPackageGroupName"
        ],
        "members": {
          "ModelPackageGroupName": {},
          "ModelPackageGroupDescription": {},
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ModelPackageGroupArn"
        ],
        "members": {
          "ModelPackageGroupArn": {}
        }
      }
    },
    "CreateModelQualityJobDefinition": {
      "input": {
        "type": "structure",
        "required": [
          "JobDefinitionName",
          "ModelQualityAppSpecification",
          "ModelQualityJobInput",
          "ModelQualityJobOutputConfig",
          "JobResources",
          "RoleArn"
        ],
        "members": {
          "JobDefinitionName": {},
          "ModelQualityBaselineConfig": {
            "shape": "Soa"
          },
          "ModelQualityAppSpecification": {
            "shape": "Sob"
          },
          "ModelQualityJobInput": {
            "shape": "Sod"
          },
          "ModelQualityJobOutputConfig": {
            "shape": "S9t"
          },
          "JobResources": {
            "shape": "S9z"
          },
          "NetworkConfig": {
            "shape": "Sa4"
          },
          "RoleArn": {},
          "StoppingCondition": {
            "shape": "Sa5"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "JobDefinitionArn"
        ],
        "members": {
          "JobDefinitionArn": {}
        }
      }
    },
    "CreateMonitoringSchedule": {
      "input": {
        "type": "structure",
        "required": [
          "MonitoringScheduleName",
          "MonitoringScheduleConfig"
        ],
        "members": {
          "MonitoringScheduleName": {},
          "MonitoringScheduleConfig": {
            "shape": "Soh"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "MonitoringScheduleArn"
        ],
        "members": {
          "MonitoringScheduleArn": {}
        }
      }
    },
    "CreateNotebookInstance": {
      "input": {
        "type": "structure",
        "required": [
          "NotebookInstanceName",
          "InstanceType",
          "RoleArn"
        ],
        "members": {
          "NotebookInstanceName": {},
          "InstanceType": {},
          "SubnetId": {},
          "SecurityGroupIds": {
            "shape": "Sai"
          },
          "RoleArn": {},
          "KmsKeyId": {},
          "Tags": {
            "shape": "S7"
          },
          "LifecycleConfigName": {},
          "DirectInternetAccess": {},
          "VolumeSizeInGB": {
            "type": "integer"
          },
          "AcceleratorTypes": {
            "shape": "Soy"
          },
          "DefaultCodeRepository": {},
          "AdditionalCodeRepositories": {
            "shape": "Sp1"
          },
          "RootAccess": {},
          "PlatformIdentifier": {},
          "InstanceMetadataServiceConfiguration": {
            "shape": "Sp4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NotebookInstanceArn": {}
        }
      }
    },
    "CreateNotebookInstanceLifecycleConfig": {
      "input": {
        "type": "structure",
        "required": [
          "NotebookInstanceLifecycleConfigName"
        ],
        "members": {
          "NotebookInstanceLifecycleConfigName": {},
          "OnCreate": {
            "shape": "Sp9"
          },
          "OnStart": {
            "shape": "Sp9"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NotebookInstanceLifecycleConfigArn": {}
        }
      }
    },
    "CreatePipeline": {
      "input": {
        "type": "structure",
        "required": [
          "PipelineName",
          "ClientRequestToken",
          "RoleArn"
        ],
        "members": {
          "PipelineName": {},
          "PipelineDisplayName": {},
          "PipelineDefinition": {},
          "PipelineDefinitionS3Location": {
            "shape": "Sph"
          },
          "PipelineDescription": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "RoleArn": {},
          "Tags": {
            "shape": "S7"
          },
          "ParallelismConfiguration": {
            "shape": "Spn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PipelineArn": {}
        }
      }
    },
    "CreatePresignedDomainUrl": {
      "input": {
        "type": "structure",
        "required": [
          "DomainId",
          "UserProfileName"
        ],
        "members": {
          "DomainId": {},
          "UserProfileName": {},
          "SessionExpirationDurationInSeconds": {
            "type": "integer"
          },
          "ExpiresInSeconds": {
            "type": "integer"
          },
          "SpaceName": {},
          "LandingUri": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AuthorizedUrl": {}
        }
      }
    },
    "CreatePresignedNotebookInstanceUrl": {
      "input": {
        "type": "structure",
        "required": [
          "NotebookInstanceName"
        ],
        "members": {
          "NotebookInstanceName": {},
          "SessionExpirationDurationInSeconds": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AuthorizedUrl": {}
        }
      }
    },
    "CreateProcessingJob": {
      "input": {
        "type": "structure",
        "required": [
          "ProcessingJobName",
          "ProcessingResources",
          "AppSpecification",
          "RoleArn"
        ],
        "members": {
          "ProcessingInputs": {
            "shape": "Sq0"
          },
          "ProcessingOutputConfig": {
            "shape": "Sqn"
          },
          "ProcessingJobName": {},
          "ProcessingResources": {
            "shape": "Sqs"
          },
          "StoppingCondition": {
            "shape": "Squ"
          },
          "AppSpecification": {
            "shape": "Sqw"
          },
          "Environment": {
            "shape": "Sqy"
          },
          "NetworkConfig": {
            "shape": "Sop"
          },
          "RoleArn": {},
          "Tags": {
            "shape": "S7"
          },
          "ExperimentConfig": {
            "shape": "Sqz"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ProcessingJobArn"
        ],
        "members": {
          "ProcessingJobArn": {}
        }
      }
    },
    "CreateProject": {
      "input": {
        "type": "structure",
        "required": [
          "ProjectName",
          "ServiceCatalogProvisioningDetails"
        ],
        "members": {
          "ProjectName": {},
          "ProjectDescription": {},
          "ServiceCatalogProvisioningDetails": {
            "shape": "Sr4"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ProjectArn",
          "ProjectId"
        ],
        "members": {
          "ProjectArn": {},
          "ProjectId": {}
        }
      }
    },
    "CreateSpace": {
      "input": {
        "type": "structure",
        "required": [
          "DomainId",
          "SpaceName"
        ],
        "members": {
          "DomainId": {},
          "SpaceName": {},
          "Tags": {
            "shape": "S7"
          },
          "SpaceSettings": {
            "shape": "Sre"
          },
          "OwnershipSettings": {
            "shape": "Srm"
          },
          "SpaceSharingSettings": {
            "shape": "Srn"
          },
          "SpaceDisplayName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SpaceArn": {}
        }
      }
    },
    "CreateStudioLifecycleConfig": {
      "input": {
        "type": "structure",
        "required": [
          "StudioLifecycleConfigName",
          "StudioLifecycleConfigContent",
          "StudioLifecycleConfigAppType"
        ],
        "members": {
          "StudioLifecycleConfigName": {},
          "StudioLifecycleConfigContent": {},
          "StudioLifecycleConfigAppType": {},
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StudioLifecycleConfigArn": {}
        }
      }
    },
    "CreateTrainingJob": {
      "input": {
        "type": "structure",
        "required": [
          "TrainingJobName",
          "AlgorithmSpecification",
          "RoleArn",
          "OutputDataConfig",
          "ResourceConfig",
          "StoppingCondition"
        ],
        "members": {
          "TrainingJobName": {},
          "HyperParameters": {
            "shape": "S33"
          },
          "AlgorithmSpecification": {
            "shape": "Sry"
          },
          "RoleArn": {},
          "InputDataConfig": {
            "shape": "S35"
          },
          "OutputDataConfig": {
            "shape": "S3n"
          },
          "ResourceConfig": {
            "shape": "S3q"
          },
          "VpcConfig": {
            "shape": "S6a"
          },
          "StoppingCondition": {
            "shape": "S3w"
          },
          "Tags": {
            "shape": "S7"
          },
          "EnableNetworkIsolation": {
            "type": "boolean"
          },
          "EnableInterContainerTrafficEncryption": {
            "type": "boolean"
          },
          "EnableManagedSpotTraining": {
            "type": "boolean"
          },
          "CheckpointConfig": {
            "shape": "Si7"
          },
          "DebugHookConfig": {
            "shape": "Ss7"
          },
          "DebugRuleConfigurations": {
            "shape": "Ssf"
          },
          "TensorBoardOutputConfig": {
            "shape": "Ssj"
          },
          "ExperimentConfig": {
            "shape": "Sqz"
          },
          "ProfilerConfig": {
            "shape": "Ssk"
          },
          "ProfilerRuleConfigurations": {
            "shape": "Sso"
          },
          "Environment": {
            "shape": "Ssq"
          },
          "RetryStrategy": {
            "shape": "Si8"
          },
          "RemoteDebugConfig": {
            "shape": "Sst"
          },
          "InfraCheckConfig": {
            "shape": "Ssv"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TrainingJobArn"
        ],
        "members": {
          "TrainingJobArn": {}
        }
      }
    },
    "CreateTransformJob": {
      "input": {
        "type": "structure",
        "required": [
          "TransformJobName",
          "ModelName",
          "TransformInput",
          "TransformOutput",
          "TransformResources"
        ],
        "members": {
          "TransformJobName": {},
          "ModelName": {},
          "MaxConcurrentTransforms": {
            "type": "integer"
          },
          "ModelClientConfig": {
            "shape": "St1"
          },
          "MaxPayloadInMB": {
            "type": "integer"
          },
          "BatchStrategy": {},
          "Environment": {
            "shape": "S44"
          },
          "TransformInput": {
            "shape": "S47"
          },
          "TransformOutput": {
            "shape": "S4b"
          },
          "DataCaptureConfig": {
            "shape": "St4"
          },
          "TransformResources": {
            "shape": "S4e"
          },
          "DataProcessing": {
            "shape": "St5"
          },
          "Tags": {
            "shape": "S7"
          },
          "ExperimentConfig": {
            "shape": "Sqz"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TransformJobArn"
        ],
        "members": {
          "TransformJobArn": {}
        }
      }
    },
    "CreateTrial": {
      "input": {
        "type": "structure",
        "required": [
          "TrialName",
          "ExperimentName"
        ],
        "members": {
          "TrialName": {},
          "DisplayName": {},
          "ExperimentName": {},
          "MetadataProperties": {
            "shape": "S21"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrialArn": {}
        }
      }
    },
    "CreateTrialComponent": {
      "input": {
        "type": "structure",
        "required": [
          "TrialComponentName"
        ],
        "members": {
          "TrialComponentName": {},
          "DisplayName": {},
          "Status": {
            "shape": "Std"
          },
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Parameters": {
            "shape": "Stg"
          },
          "InputArtifacts": {
            "shape": "Stk"
          },
          "OutputArtifacts": {
            "shape": "Stk"
          },
          "MetadataProperties": {
            "shape": "S21"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrialComponentArn": {}
        }
      }
    },
    "CreateUserProfile": {
      "input": {
        "type": "structure",
        "required": [
          "DomainId",
          "UserProfileName"
        ],
        "members": {
          "DomainId": {},
          "UserProfileName": {},
          "SingleSignOnUserIdentifier": {},
          "SingleSignOnUserValue": {},
          "Tags": {
            "shape": "S7"
          },
          "UserSettings": {
            "shape": "Sah"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserProfileArn": {}
        }
      }
    },
    "CreateWorkforce": {
      "input": {
        "type": "structure",
        "required": [
          "WorkforceName"
        ],
        "members": {
          "CognitoConfig": {
            "shape": "Stv"
          },
          "OidcConfig": {
            "shape": "Sty"
          },
          "SourceIpConfig": {
            "shape": "Su1"
          },
          "WorkforceName": {},
          "Tags": {
            "shape": "S7"
          },
          "WorkforceVpcConfig": {
            "shape": "Su5"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "WorkforceArn"
        ],
        "members": {
          "WorkforceArn": {}
        }
      }
    },
    "CreateWorkteam": {
      "input": {
        "type": "structure",
        "required": [
          "WorkteamName",
          "MemberDefinitions",
          "Description"
        ],
        "members": {
          "WorkteamName": {},
          "WorkforceName": {},
          "MemberDefinitions": {
            "shape": "Suf"
          },
          "Description": {},
          "NotificationConfiguration": {
            "shape": "Sun"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkteamArn": {}
        }
      }
    },
    "DeleteAction": {
      "input": {
        "type": "structure",
        "required": [
          "ActionName"
        ],
        "members": {
          "ActionName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ActionArn": {}
        }
      }
    },
    "DeleteAlgorithm": {
      "input": {
        "type": "structure",
        "required": [
          "AlgorithmName"
        ],
        "members": {
          "AlgorithmName": {}
        }
      }
    },
    "DeleteApp": {
      "input": {
        "type": "structure",
        "required": [
          "DomainId",
          "AppType",
          "AppName"
        ],
        "members": {
          "DomainId": {},
          "UserProfileName": {},
          "SpaceName": {},
          "AppType": {},
          "AppName": {}
        }
      }
    },
    "DeleteAppImageConfig": {
      "input": {
        "type": "structure",
        "required": [
          "AppImageConfigName"
        ],
        "members": {
          "AppImageConfigName": {}
        }
      }
    },
    "DeleteArtifact": {
      "input": {
        "type": "structure",
        "members": {
          "ArtifactArn": {},
          "Source": {
            "shape": "S5i"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ArtifactArn": {}
        }
      }
    },
    "DeleteAssociation": {
      "input": {
        "type": "structure",
        "required": [
          "SourceArn",
          "DestinationArn"
        ],
        "members": {
          "SourceArn": {},
          "DestinationArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SourceArn": {},
          "DestinationArn": {}
        }
      }
    },
    "DeleteCluster": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterName"
        ],
        "members": {
          "ClusterName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ClusterArn"
        ],
        "members": {
          "ClusterArn": {}
        }
      }
    },
    "DeleteCodeRepository": {
      "input": {
        "type": "structure",
        "required": [
          "CodeRepositoryName"
        ],
        "members": {
          "CodeRepositoryName": {}
        }
      }
    },
    "DeleteCompilationJob": {
      "input": {
        "type": "structure",
        "required": [
          "CompilationJobName"
        ],
        "members": {
          "CompilationJobName": {}
        }
      }
    },
    "DeleteContext": {
      "input": {
        "type": "structure",
        "required": [
          "ContextName"
        ],
        "members": {
          "ContextName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ContextArn": {}
        }
      }
    },
    "DeleteDataQualityJobDefinition": {
      "input": {
        "type": "structure",
        "required": [
          "JobDefinitionName"
        ],
        "members": {
          "JobDefinitionName": {}
        }
      }
    },
    "DeleteDeviceFleet": {
      "input": {
        "type": "structure",
        "required": [
          "DeviceFleetName"
        ],
        "members": {
          "DeviceFleetName": {}
        }
      }
    },
    "DeleteDomain": {
      "input": {
        "type": "structure",
        "required": [
          "DomainId"
        ],
        "members": {
          "DomainId": {},
          "RetentionPolicy": {
            "type": "structure",
            "members": {
              "HomeEfsFileSystem": {}
            }
          }
        }
      }
    },
    "DeleteEdgeDeploymentPlan": {
      "input": {
        "type": "structure",
        "required": [
          "EdgeDeploymentPlanName"
        ],
        "members": {
          "EdgeDeploymentPlanName": {}
        }
      }
    },
    "DeleteEdgeDeploymentStage": {
      "input": {
        "type": "structure",
        "required": [
          "EdgeDeploymentPlanName",
          "StageName"
        ],
        "members": {
          "EdgeDeploymentPlanName": {},
          "StageName": {}
        }
      }
    },
    "DeleteEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointName"
        ],
        "members": {
          "EndpointName": {}
        }
      }
    },
    "DeleteEndpointConfig": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointConfigName"
        ],
        "members": {
          "EndpointConfigName": {}
        }
      }
    },
    "DeleteExperiment": {
      "input": {
        "type": "structure",
        "required": [
          "ExperimentName"
        ],
        "members": {
          "ExperimentName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ExperimentArn": {}
        }
      }
    },
    "DeleteFeatureGroup": {
      "input": {
        "type": "structure",
        "required": [
          "FeatureGroupName"
        ],
        "members": {
          "FeatureGroupName": {}
        }
      }
    },
    "DeleteFlowDefinition": {
      "input": {
        "type": "structure",
        "required": [
          "FlowDefinitionName"
        ],
        "members": {
          "FlowDefinitionName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteHub": {
      "input": {
        "type": "structure",
        "required": [
          "HubName"
        ],
        "members": {
          "HubName": {}
        }
      }
    },
    "DeleteHubContent": {
      "input": {
        "type": "structure",
        "required": [
          "HubName",
          "HubContentType",
          "HubContentName",
          "HubContentVersion"
        ],
        "members": {
          "HubName": {},
          "HubContentType": {},
          "HubContentName": {},
          "HubContentVersion": {}
        }
      }
    },
    "DeleteHumanTaskUi": {
      "input": {
        "type": "structure",
        "required": [
          "HumanTaskUiName"
        ],
        "members": {
          "HumanTaskUiName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteHyperParameterTuningJob": {
      "input": {
        "type": "structure",
        "required": [
          "HyperParameterTuningJobName"
        ],
        "members": {
          "HyperParameterTuningJobName": {}
        }
      }
    },
    "DeleteImage": {
      "input": {
        "type": "structure",
        "required": [
          "ImageName"
        ],
        "members": {
          "ImageName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteImageVersion": {
      "input": {
        "type": "structure",
        "required": [
          "ImageName"
        ],
        "members": {
          "ImageName": {},
          "Version": {
            "type": "integer"
          },
          "Alias": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteInferenceComponent": {
      "input": {
        "type": "structure",
        "required": [
          "InferenceComponentName"
        ],
        "members": {
          "InferenceComponentName": {}
        }
      }
    },
    "DeleteInferenceExperiment": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "InferenceExperimentArn"
        ],
        "members": {
          "InferenceExperimentArn": {}
        }
      }
    },
    "DeleteModel": {
      "input": {
        "type": "structure",
        "required": [
          "ModelName"
        ],
        "members": {
          "ModelName": {}
        }
      }
    },
    "DeleteModelBiasJobDefinition": {
      "input": {
        "type": "structure",
        "required": [
          "JobDefinitionName"
        ],
        "members": {
          "JobDefinitionName": {}
        }
      }
    },
    "DeleteModelCard": {
      "input": {
        "type": "structure",
        "required": [
          "ModelCardName"
        ],
        "members": {
          "ModelCardName": {}
        }
      }
    },
    "DeleteModelExplainabilityJobDefinition": {
      "input": {
        "type": "structure",
        "required": [
          "JobDefinitionName"
        ],
        "members": {
          "JobDefinitionName": {}
        }
      }
    },
    "DeleteModelPackage": {
      "input": {
        "type": "structure",
        "required": [
          "ModelPackageName"
        ],
        "members": {
          "ModelPackageName": {}
        }
      }
    },
    "DeleteModelPackageGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ModelPackageGroupName"
        ],
        "members": {
          "ModelPackageGroupName": {}
        }
      }
    },
    "DeleteModelPackageGroupPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "ModelPackageGroupName"
        ],
        "members": {
          "ModelPackageGroupName": {}
        }
      }
    },
    "DeleteModelQualityJobDefinition": {
      "input": {
        "type": "structure",
        "required": [
          "JobDefinitionName"
        ],
        "members": {
          "JobDefinitionName": {}
        }
      }
    },
    "DeleteMonitoringSchedule": {
      "input": {
        "type": "structure",
        "required": [
          "MonitoringScheduleName"
        ],
        "members": {
          "MonitoringScheduleName": {}
        }
      }
    },
    "DeleteNotebookInstance": {
      "input": {
        "type": "structure",
        "required": [
          "NotebookInstanceName"
        ],
        "members": {
          "NotebookInstanceName": {}
        }
      }
    },
    "DeleteNotebookInstanceLifecycleConfig": {
      "input": {
        "type": "structure",
        "required": [
          "NotebookInstanceLifecycleConfigName"
        ],
        "members": {
          "NotebookInstanceLifecycleConfigName": {}
        }
      }
    },
    "DeletePipeline": {
      "input": {
        "type": "structure",
        "required": [
          "PipelineName",
          "ClientRequestToken"
        ],
        "members": {
          "PipelineName": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PipelineArn": {}
        }
      }
    },
    "DeleteProject": {
      "input": {
        "type": "structure",
        "required": [
          "ProjectName"
        ],
        "members": {
          "ProjectName": {}
        }
      }
    },
    "DeleteSpace": {
      "input": {
        "type": "structure",
        "required": [
          "DomainId",
          "SpaceName"
        ],
        "members": {
          "DomainId": {},
          "SpaceName": {}
        }
      }
    },
    "DeleteStudioLifecycleConfig": {
      "input": {
        "type": "structure",
        "required": [
          "StudioLifecycleConfigName"
        ],
        "members": {
          "StudioLifecycleConfigName": {}
        }
      }
    },
    "DeleteTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteTrial": {
      "input": {
        "type": "structure",
        "required": [
          "TrialName"
        ],
        "members": {
          "TrialName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrialArn": {}
        }
      }
    },
    "DeleteTrialComponent": {
      "input": {
        "type": "structure",
        "required": [
          "TrialComponentName"
        ],
        "members": {
          "TrialComponentName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrialComponentArn": {}
        }
      }
    },
    "DeleteUserProfile": {
      "input": {
        "type": "structure",
        "required": [
          "DomainId",
          "UserProfileName"
        ],
        "members": {
          "DomainId": {},
          "UserProfileName": {}
        }
      }
    },
    "DeleteWorkforce": {
      "input": {
        "type": "structure",
        "required": [
          "WorkforceName"
        ],
        "members": {
          "WorkforceName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteWorkteam": {
      "input": {
        "type": "structure",
        "required": [
          "WorkteamName"
        ],
        "members": {
          "WorkteamName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Success"
        ],
        "members": {
          "Success": {
            "type": "boolean"
          }
        }
      }
    },
    "DeregisterDevices": {
      "input": {
        "type": "structure",
        "required": [
          "DeviceFleetName",
          "DeviceNames"
        ],
        "members": {
          "DeviceFleetName": {},
          "DeviceNames": {
            "shape": "Scb"
          }
        }
      }
    },
    "DescribeAction": {
      "input": {
        "type": "structure",
        "required": [
          "ActionName"
        ],
        "members": {
          "ActionName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ActionName": {},
          "ActionArn": {},
          "Source": {
            "shape": "S1u"
          },
          "ActionType": {},
          "Description": {},
          "Status": {},
          "Properties": {
            "shape": "S1z"
          },
          "CreationTime": {
            "type": "timestamp"
          },
          "CreatedBy": {
            "shape": "Sww"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "LastModifiedBy": {
            "shape": "Sww"
          },
          "MetadataProperties": {
            "shape": "S21"
          },
          "LineageGroupArn": {}
        }
      }
    },
    "DescribeAlgorithm": {
      "input": {
        "type": "structure",
        "required": [
          "AlgorithmName"
        ],
        "members": {
          "AlgorithmName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "AlgorithmName",
          "AlgorithmArn",
          "CreationTime",
          "TrainingSpecification",
          "AlgorithmStatus",
          "AlgorithmStatusDetails"
        ],
        "members": {
          "AlgorithmName": {},
          "AlgorithmArn": {},
          "AlgorithmDescription": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "TrainingSpecification": {
            "shape": "S26"
          },
          "InferenceSpecification": {
            "shape": "Sr"
          },
          "ValidationSpecification": {
            "shape": "S2y"
          },
          "AlgorithmStatus": {},
          "AlgorithmStatusDetails": {
            "type": "structure",
            "members": {
              "ValidationStatuses": {
                "shape": "Sx3"
              },
              "ImageScanStatuses": {
                "shape": "Sx3"
              }
            }
          },
          "ProductId": {},
          "CertifyForMarketplace": {
            "type": "boolean"
          }
        }
      }
    },
    "DescribeApp": {
      "input": {
        "type": "structure",
        "required": [
          "DomainId",
          "AppType",
          "AppName"
        ],
        "members": {
          "DomainId": {},
          "UserProfileName": {},
          "SpaceName": {},
          "AppType": {},
          "AppName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AppArn": {},
          "AppType": {},
          "AppName": {},
          "DomainId": {},
          "UserProfileName": {},
          "SpaceName": {},
          "Status": {},
          "LastHealthCheckTimestamp": {
            "type": "timestamp"
          },
          "LastUserActivityTimestamp": {
            "type": "timestamp"
          },
          "CreationTime": {
            "type": "timestamp"
          },
          "FailureReason": {},
          "ResourceSpec": {
            "shape": "S4p"
          }
        }
      }
    },
    "DescribeAppImageConfig": {
      "input": {
        "type": "structure",
        "required": [
          "AppImageConfigName"
        ],
        "members": {
          "AppImageConfigName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AppImageConfigArn": {},
          "AppImageConfigName": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "KernelGatewayImageConfig": {
            "shape": "S4z"
          },
          "JupyterLabAppImageConfig": {
            "shape": "S58"
          }
        }
      }
    },
    "DescribeArtifact": {
      "input": {
        "type": "structure",
        "required": [
          "ArtifactArn"
        ],
        "members": {
          "ArtifactArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ArtifactName": {},
          "ArtifactArn": {},
          "Source": {
            "shape": "S5i"
          },
          "ArtifactType": {},
          "Properties": {
            "shape": "S1z"
          },
          "CreationTime": {
            "type": "timestamp"
          },
          "CreatedBy": {
            "shape": "Sww"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "LastModifiedBy": {
            "shape": "Sww"
          },
          "MetadataProperties": {
            "shape": "S21"
          },
          "LineageGroupArn": {}
        }
      }
    },
    "DescribeAutoMLJob": {
      "input": {
        "type": "structure",
        "required": [
          "AutoMLJobName"
        ],
        "members": {
          "AutoMLJobName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "AutoMLJobName",
          "AutoMLJobArn",
          "InputDataConfig",
          "OutputDataConfig",
          "RoleArn",
          "CreationTime",
          "LastModifiedTime",
          "AutoMLJobStatus",
          "AutoMLJobSecondaryStatus"
        ],
        "members": {
          "AutoMLJobName": {},
          "AutoMLJobArn": {},
          "InputDataConfig": {
            "shape": "S5s"
          },
          "OutputDataConfig": {
            "shape": "S60"
          },
          "RoleArn": {},
          "AutoMLJobObjective": {
            "shape": "S62"
          },
          "ProblemType": {},
          "AutoMLJobConfig": {
            "shape": "S64"
          },
          "CreationTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "FailureReason": {},
          "PartialFailureReasons": {
            "shape": "Sxh"
          },
          "BestCandidate": {
            "shape": "Sxj"
          },
          "AutoMLJobStatus": {},
          "AutoMLJobSecondaryStatus": {},
          "GenerateCandidateDefinitionsOnly": {
            "type": "boolean"
          },
          "AutoMLJobArtifacts": {
            "shape": "Syb"
          },
          "ResolvedAttributes": {
            "type": "structure",
            "members": {
              "AutoMLJobObjective": {
                "shape": "S62"
              },
              "ProblemType": {},
              "CompletionCriteria": {
                "shape": "S65"
              }
            }
          },
          "ModelDeployConfig": {
            "shape": "S6o"
          },
          "ModelDeployResult": {
            "shape": "Syf"
          }
        }
      }
    },
    "DescribeAutoMLJobV2": {
      "input": {
        "type": "structure",
        "required": [
          "AutoMLJobName"
        ],
        "members": {
          "AutoMLJobName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "AutoMLJobName",
          "AutoMLJobArn",
          "AutoMLJobInputDataConfig",
          "OutputDataConfig",
          "RoleArn",
          "CreationTime",
          "LastModifiedTime",
          "AutoMLJobStatus",
          "AutoMLJobSecondaryStatus"
        ],
        "members": {
          "AutoMLJobName": {},
          "AutoMLJobArn": {},
          "AutoMLJobInputDataConfig": {
            "shape": "S6u"
          },
          "OutputDataConfig": {
            "shape": "S60"
          },
          "RoleArn": {},
          "AutoMLJobObjective": {
            "shape": "S62"
          },
          "AutoMLProblemTypeConfig": {
            "shape": "S6w"
          },
          "AutoMLProblemTypeConfigName": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "FailureReason": {},
          "PartialFailureReasons": {
            "shape": "Sxh"
          },
          "BestCandidate": {
            "shape": "Sxj"
          },
          "AutoMLJobStatus": {},
          "AutoMLJobSecondaryStatus": {},
          "AutoMLJobArtifacts": {
            "shape": "Syb"
          },
          "ResolvedAttributes": {
            "type": "structure",
            "members": {
              "AutoMLJobObjective": {
                "shape": "S62"
              },
              "CompletionCriteria": {
                "shape": "S65"
              },
              "AutoMLProblemTypeResolvedAttributes": {
                "type": "structure",
                "members": {
                  "TabularResolvedAttributes": {
                    "type": "structure",
                    "members": {
                      "ProblemType": {}
                    }
                  },
                  "TextGenerationResolvedAttributes": {
                    "type": "structure",
                    "members": {
                      "BaseModelName": {}
                    }
                  }
                },
                "union": true
              }
            }
          },
          "ModelDeployConfig": {
            "shape": "S6o"
          },
          "ModelDeployResult": {
            "shape": "Syf"
          },
          "DataSplitConfig": {
            "shape": "S6k"
          },
          "SecurityConfig": {
            "shape": "S69"
          }
        }
      }
    },
    "DescribeCluster": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterName"
        ],
        "members": {
          "ClusterName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ClusterArn",
          "ClusterStatus",
          "InstanceGroups"
        ],
        "members": {
          "ClusterArn": {},
          "ClusterName": {},
          "ClusterStatus": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "FailureMessage": {},
          "InstanceGroups": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "CurrentCount": {
                  "type": "integer"
                },
                "TargetCount": {
                  "type": "integer"
                },
                "InstanceGroupName": {},
                "InstanceType": {},
                "LifeCycleConfig": {
                  "shape": "S81"
                },
                "ExecutionRole": {},
                "ThreadsPerCore": {
                  "type": "integer"
                }
              }
            }
          },
          "VpcConfig": {
            "shape": "S6a"
          }
        }
      }
    },
    "DescribeClusterNode": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterName",
          "NodeId"
        ],
        "members": {
          "ClusterName": {},
          "NodeId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "NodeDetails"
        ],
        "members": {
          "NodeDetails": {
            "type": "structure",
            "members": {
              "InstanceGroupName": {},
              "InstanceId": {},
              "InstanceStatus": {
                "shape": "Syx"
              },
              "InstanceType": {},
              "LaunchTime": {
                "type": "timestamp"
              },
              "LifeCycleConfig": {
                "shape": "S81"
              },
              "ThreadsPerCore": {
                "type": "integer"
              }
            }
          }
        }
      }
    },
    "DescribeCodeRepository": {
      "input": {
        "type": "structure",
        "required": [
          "CodeRepositoryName"
        ],
        "members": {
          "CodeRepositoryName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CodeRepositoryName",
          "CodeRepositoryArn",
          "CreationTime",
          "LastModifiedTime"
        ],
        "members": {
          "CodeRepositoryName": {},
          "CodeRepositoryArn": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "GitConfig": {
            "shape": "S87"
          }
        }
      }
    },
    "DescribeCompilationJob": {
      "input": {
        "type": "structure",
        "required": [
          "CompilationJobName"
        ],
        "members": {
          "CompilationJobName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CompilationJobName",
          "CompilationJobArn",
          "CompilationJobStatus",
          "StoppingCondition",
          "CreationTime",
          "LastModifiedTime",
          "FailureReason",
          "ModelArtifacts",
          "RoleArn",
          "InputConfig",
          "OutputConfig"
        ],
        "members": {
          "CompilationJobName": {},
          "CompilationJobArn": {},
          "CompilationJobStatus": {},
          "CompilationStartTime": {
            "type": "timestamp"
          },
          "CompilationEndTime": {
            "type": "timestamp"
          },
          "StoppingCondition": {
            "shape": "S3w"
          },
          "InferenceImage": {},
          "ModelPackageVersionArn": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "FailureReason": {},
          "ModelArtifacts": {
            "shape": "Sz6"
          },
          "ModelDigests": {
            "type": "structure",
            "members": {
              "ArtifactDigest": {}
            }
          },
          "RoleArn": {},
          "InputConfig": {
            "shape": "S8e"
          },
          "OutputConfig": {
            "shape": "S8h"
          },
          "VpcConfig": {
            "shape": "S8o"
          },
          "DerivedInformation": {
            "type": "structure",
            "members": {
              "DerivedDataInputConfig": {}
            }
          }
        }
      }
    },
    "DescribeContext": {
      "input": {
        "type": "structure",
        "required": [
          "ContextName"
        ],
        "members": {
          "ContextName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ContextName": {},
          "ContextArn": {},
          "Source": {
            "shape": "S8x"
          },
          "ContextType": {},
          "Description": {},
          "Properties": {
            "shape": "S1z"
          },
          "CreationTime": {
            "type": "timestamp"
          },
          "CreatedBy": {
            "shape": "Sww"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "LastModifiedBy": {
            "shape": "Sww"
          },
          "LineageGroupArn": {}
        }
      }
    },
    "DescribeDataQualityJobDefinition": {
      "input": {
        "type": "structure",
        "required": [
          "JobDefinitionName"
        ],
        "members": {
          "JobDefinitionName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "JobDefinitionArn",
          "JobDefinitionName",
          "CreationTime",
          "DataQualityAppSpecification",
          "DataQualityJobInput",
          "DataQualityJobOutputConfig",
          "JobResources",
          "RoleArn"
        ],
        "members": {
          "JobDefinitionArn": {},
          "JobDefinitionName": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "DataQualityBaselineConfig": {
            "shape": "S92"
          },
          "DataQualityAppSpecification": {
            "shape": "S96"
          },
          "DataQualityJobInput": {
            "shape": "S9f"
          },
          "DataQualityJobOutputConfig": {
            "shape": "S9t"
          },
          "JobResources": {
            "shape": "S9z"
          },
          "NetworkConfig": {
            "shape": "Sa4"
          },
          "RoleArn": {},
          "StoppingCondition": {
            "shape": "Sa5"
          }
        }
      }
    },
    "DescribeDevice": {
      "input": {
        "type": "structure",
        "required": [
          "DeviceName",
          "DeviceFleetName"
        ],
        "members": {
          "NextToken": {},
          "DeviceName": {},
          "DeviceFleetName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DeviceName",
          "DeviceFleetName",
          "RegistrationTime"
        ],
        "members": {
          "DeviceArn": {},
          "DeviceName": {},
          "Description": {},
          "DeviceFleetName": {},
          "IotThingName": {},
          "RegistrationTime": {
            "type": "timestamp"
          },
          "LatestHeartbeat": {
            "type": "timestamp"
          },
          "Models": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ModelName",
                "ModelVersion"
              ],
              "members": {
                "ModelName": {},
                "ModelVersion": {},
                "LatestSampleTime": {
                  "type": "timestamp"
                },
                "LatestInference": {
                  "type": "timestamp"
                }
              }
            }
          },
          "MaxModels": {
            "type": "integer"
          },
          "NextToken": {},
          "AgentVersion": {}
        }
      }
    },
    "DescribeDeviceFleet": {
      "input": {
        "type": "structure",
        "required": [
          "DeviceFleetName"
        ],
        "members": {
          "DeviceFleetName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DeviceFleetName",
          "DeviceFleetArn",
          "OutputConfig",
          "CreationTime",
          "LastModifiedTime"
        ],
        "members": {
          "DeviceFleetName": {},
          "DeviceFleetArn": {},
          "OutputConfig": {
            "shape": "Sab"
          },
          "Description": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "RoleArn": {},
          "IotRoleAlias": {}
        }
      }
    },
    "DescribeDomain": {
      "input": {
        "type": "structure",
        "required": [
          "DomainId"
        ],
        "members": {
          "DomainId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DomainArn": {},
          "DomainId": {},
          "DomainName": {},
          "HomeEfsFileSystemId": {},
          "SingleSignOnManagedApplicationInstanceId": {},
          "SingleSignOnApplicationArn": {},
          "Status": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "FailureReason": {},
          "SecurityGroupIdForDomainBoundary": {},
          "AuthMode": {},
          "DefaultUserSettings": {
            "shape": "Sah"
          },
          "DomainSettings": {
            "shape": "Sbp"
          },
          "AppNetworkAccessType": {},
          "HomeEfsFileSystemKmsKeyId": {
            "deprecated": true,
            "deprecatedMessage": "This property is deprecated, use KmsKeyId instead."
          },
          "SubnetIds": {
            "shape": "S6d"
          },
          "Url": {},
          "VpcId": {},
          "KmsKeyId": {},
          "AppSecurityGroupManagement": {},
          "DefaultSpaceSettings": {
            "shape": "Sbz"
          }
        }
      }
    },
    "DescribeEdgeDeploymentPlan": {
      "input": {
        "type": "structure",
        "required": [
          "EdgeDeploymentPlanName"
        ],
        "members": {
          "EdgeDeploymentPlanName": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "EdgeDeploymentPlanArn",
          "EdgeDeploymentPlanName",
          "ModelConfigs",
          "DeviceFleetName",
          "Stages"
        ],
        "members": {
          "EdgeDeploymentPlanArn": {},
          "EdgeDeploymentPlanName": {},
          "ModelConfigs": {
            "shape": "Sc4"
          },
          "DeviceFleetName": {},
          "EdgeDeploymentSuccess": {
            "type": "integer"
          },
          "EdgeDeploymentPending": {
            "type": "integer"
          },
          "EdgeDeploymentFailed": {
            "type": "integer"
          },
          "Stages": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "StageName",
                "DeviceSelectionConfig",
                "DeploymentConfig",
                "DeploymentStatus"
              ],
              "members": {
                "StageName": {},
                "DeviceSelectionConfig": {
                  "shape": "Sc8"
                },
                "DeploymentConfig": {
                  "shape": "Scd"
                },
                "DeploymentStatus": {
                  "type": "structure",
                  "required": [
                    "StageStatus",
                    "EdgeDeploymentSuccessInStage",
                    "EdgeDeploymentPendingInStage",
                    "EdgeDeploymentFailedInStage"
                  ],
                  "members": {
                    "StageStatus": {},
                    "EdgeDeploymentSuccessInStage": {
                      "type": "integer"
                    },
                    "EdgeDeploymentPendingInStage": {
                      "type": "integer"
                    },
                    "EdgeDeploymentFailedInStage": {
                      "type": "integer"
                    },
                    "EdgeDeploymentStatusMessage": {},
                    "EdgeDeploymentStageStartTime": {
                      "type": "timestamp"
                    }
                  }
                }
              }
            }
          },
          "NextToken": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeEdgePackagingJob": {
      "input": {
        "type": "structure",
        "required": [
          "EdgePackagingJobName"
        ],
        "members": {
          "EdgePackagingJobName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "EdgePackagingJobArn",
          "EdgePackagingJobName",
          "EdgePackagingJobStatus"
        ],
        "members": {
          "EdgePackagingJobArn": {},
          "EdgePackagingJobName": {},
          "CompilationJobName": {},
          "ModelName": {},
          "ModelVersion": {},
          "RoleArn": {},
          "OutputConfig": {
            "shape": "Sab"
          },
          "ResourceKey": {},
          "EdgePackagingJobStatus": {},
          "EdgePackagingJobStatusMessage": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "ModelArtifact": {},
          "ModelSignature": {},
          "PresetDeploymentOutput": {
            "type": "structure",
            "required": [
              "Type"
            ],
            "members": {
              "Type": {},
              "Artifact": {},
              "Status": {},
              "StatusMessage": {}
            }
          }
        }
      }
    },
    "DescribeEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointName"
        ],
        "members": {
          "EndpointName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "EndpointName",
          "EndpointArn",
          "EndpointStatus",
          "CreationTime",
          "LastModifiedTime"
        ],
        "members": {
          "EndpointName": {},
          "EndpointArn": {},
          "EndpointConfigName": {},
          "ProductionVariants": {
            "shape": "S10c"
          },
          "DataCaptureConfig": {
            "shape": "S10k"
          },
          "EndpointStatus": {},
          "FailureReason": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "LastDeploymentConfig": {
            "shape": "Scm"
          },
          "AsyncInferenceConfig": {
            "shape": "Se1"
          },
          "PendingDeploymentSummary": {
            "type": "structure",
            "required": [
              "EndpointConfigName"
            ],
            "members": {
              "EndpointConfigName": {},
              "ProductionVariants": {
                "shape": "S10o"
              },
              "StartTime": {
                "type": "timestamp"
              },
              "ShadowProductionVariants": {
                "shape": "S10o"
              }
            }
          },
          "ExplainerConfig": {
            "shape": "Se9"
          },
          "ShadowProductionVariants": {
            "shape": "S10c"
          }
        }
      }
    },
    "DescribeEndpointConfig": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointConfigName"
        ],
        "members": {
          "EndpointConfigName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "EndpointConfigName",
          "EndpointConfigArn",
          "ProductionVariants",
          "CreationTime"
        ],
        "members": {
          "EndpointConfigName": {},
          "EndpointConfigArn": {},
          "ProductionVariants": {
            "shape": "Sd4"
          },
          "DataCaptureConfig": {
            "shape": "Sdq"
          },
          "KmsKeyId": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "AsyncInferenceConfig": {
            "shape": "Se1"
          },
          "ExplainerConfig": {
            "shape": "Se9"
          },
          "ShadowProductionVariants": {
            "shape": "Sd4"
          },
          "ExecutionRoleArn": {},
          "VpcConfig": {
            "shape": "S6a"
          },
          "EnableNetworkIsolation": {
            "type": "boolean"
          }
        }
      }
    },
    "DescribeExperiment": {
      "input": {
        "type": "structure",
        "required": [
          "ExperimentName"
        ],
        "members": {
          "ExperimentName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ExperimentName": {},
          "ExperimentArn": {},
          "DisplayName": {},
          "Source": {
            "shape": "S10u"
          },
          "Description": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "CreatedBy": {
            "shape": "Sww"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "LastModifiedBy": {
            "shape": "Sww"
          }
        }
      }
    },
    "DescribeFeatureGroup": {
      "input": {
        "type": "structure",
        "required": [
          "FeatureGroupName"
        ],
        "members": {
          "FeatureGroupName": {},
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "FeatureGroupArn",
          "FeatureGroupName",
          "RecordIdentifierFeatureName",
          "EventTimeFeatureName",
          "FeatureDefinitions",
          "CreationTime",
          "NextToken"
        ],
        "members": {
          "FeatureGroupArn": {},
          "FeatureGroupName": {},
          "RecordIdentifierFeatureName": {},
          "EventTimeFeatureName": {},
          "FeatureDefinitions": {
            "shape": "Sf8"
          },
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "OnlineStoreConfig": {
            "shape": "Sff"
          },
          "OfflineStoreConfig": {
            "shape": "Sfl"
          },
          "ThroughputConfig": {
            "type": "structure",
            "required": [
              "ThroughputMode"
            ],
            "members": {
              "ThroughputMode": {},
              "ProvisionedReadCapacityUnits": {
                "type": "integer"
              },
              "ProvisionedWriteCapacityUnits": {
                "type": "integer"
              }
            }
          },
          "RoleArn": {},
          "FeatureGroupStatus": {},
          "OfflineStoreStatus": {
            "shape": "S112"
          },
          "LastUpdateStatus": {
            "shape": "S115"
          },
          "FailureReason": {},
          "Description": {},
          "NextToken": {},
          "OnlineStoreTotalSizeBytes": {
            "type": "long"
          }
        }
      }
    },
    "DescribeFeatureMetadata": {
      "input": {
        "type": "structure",
        "required": [
          "FeatureGroupName",
          "FeatureName"
        ],
        "members": {
          "FeatureGroupName": {},
          "FeatureName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "FeatureGroupArn",
          "FeatureGroupName",
          "FeatureName",
          "FeatureType",
          "CreationTime",
          "LastModifiedTime"
        ],
        "members": {
          "FeatureGroupArn": {},
          "FeatureGroupName": {},
          "FeatureName": {},
          "FeatureType": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "Description": {},
          "Parameters": {
            "shape": "S11b"
          }
        }
      }
    },
    "DescribeFlowDefinition": {
      "input": {
        "type": "structure",
        "required": [
          "FlowDefinitionName"
        ],
        "members": {
          "FlowDefinitionName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "FlowDefinitionArn",
          "FlowDefinitionName",
          "FlowDefinitionStatus",
          "CreationTime",
          "OutputConfig",
          "RoleArn"
        ],
        "members": {
          "FlowDefinitionArn": {},
          "FlowDefinitionName": {},
          "FlowDefinitionStatus": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "HumanLoopRequestSource": {
            "shape": "Sg0"
          },
          "HumanLoopActivationConfig": {
            "shape": "Sg2"
          },
          "HumanLoopConfig": {
            "shape": "Sg5"
          },
          "OutputConfig": {
            "shape": "Sgk"
          },
          "RoleArn": {},
          "FailureReason": {}
        }
      }
    },
    "DescribeHub": {
      "input": {
        "type": "structure",
        "required": [
          "HubName"
        ],
        "members": {
          "HubName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "HubName",
          "HubArn",
          "HubStatus",
          "CreationTime",
          "LastModifiedTime"
        ],
        "members": {
          "HubName": {},
          "HubArn": {},
          "HubDisplayName": {},
          "HubDescription": {},
          "HubSearchKeywords": {
            "shape": "Sgr"
          },
          "S3StorageConfig": {
            "shape": "Sgt"
          },
          "HubStatus": {},
          "FailureReason": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeHubContent": {
      "input": {
        "type": "structure",
        "required": [
          "HubName",
          "HubContentType",
          "HubContentName"
        ],
        "members": {
          "HubName": {},
          "HubContentType": {},
          "HubContentName": {},
          "HubContentVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "HubContentName",
          "HubContentArn",
          "HubContentVersion",
          "HubContentType",
          "DocumentSchemaVersion",
          "HubName",
          "HubArn",
          "HubContentDocument",
          "HubContentStatus",
          "CreationTime"
        ],
        "members": {
          "HubContentName": {},
          "HubContentArn": {},
          "HubContentVersion": {},
          "HubContentType": {},
          "DocumentSchemaVersion": {},
          "HubName": {},
          "HubArn": {},
          "HubContentDisplayName": {},
          "HubContentDescription": {},
          "HubContentMarkdown": {},
          "HubContentDocument": {},
          "HubContentSearchKeywords": {
            "shape": "S11t"
          },
          "HubContentDependencies": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DependencyOriginPath": {},
                "DependencyCopyPath": {}
              }
            }
          },
          "HubContentStatus": {},
          "FailureReason": {},
          "CreationTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeHumanTaskUi": {
      "input": {
        "type": "structure",
        "required": [
          "HumanTaskUiName"
        ],
        "members": {
          "HumanTaskUiName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "HumanTaskUiArn",
          "HumanTaskUiName",
          "CreationTime",
          "UiTemplate"
        ],
        "members": {
          "HumanTaskUiArn": {},
          "HumanTaskUiName": {},
          "HumanTaskUiStatus": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "UiTemplate": {
            "type": "structure",
            "members": {
              "Url": {},
              "ContentSha256": {}
            }
          }
        }
      }
    },
    "DescribeHyperParameterTuningJob": {
      "input": {
        "type": "structure",
        "required": [
          "HyperParameterTuningJobName"
        ],
        "members": {
          "HyperParameterTuningJobName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "HyperParameterTuningJobName",
          "HyperParameterTuningJobArn",
          "HyperParameterTuningJobConfig",
          "HyperParameterTuningJobStatus",
          "CreationTime",
          "TrainingJobStatusCounters",
          "ObjectiveStatusCounters"
        ],
        "members": {
          "HyperParameterTuningJobName": {},
          "HyperParameterTuningJobArn": {},
          "HyperParameterTuningJobConfig": {
            "shape": "Sh4"
          },
          "TrainingJobDefinition": {
            "shape": "Shx"
          },
          "TrainingJobDefinitions": {
            "shape": "Sid"
          },
          "HyperParameterTuningJobStatus": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "HyperParameterTuningEndTime": {
            "type": "timestamp"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "TrainingJobStatusCounters": {
            "shape": "S128"
          },
          "ObjectiveStatusCounters": {
            "shape": "S12a"
          },
          "BestTrainingJob": {
            "shape": "S12c"
          },
          "OverallBestTrainingJob": {
            "shape": "S12c"
          },
          "WarmStartConfig": {
            "shape": "Sie"
          },
          "Autotune": {
            "shape": "Sii"
          },
          "FailureReason": {},
          "TuningJobCompletionDetails": {
            "shape": "S12f"
          },
          "ConsumedResources": {
            "shape": "S12g"
          }
        }
      }
    },
    "DescribeImage": {
      "input": {
        "type": "structure",
        "required": [
          "ImageName"
        ],
        "members": {
          "ImageName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CreationTime": {
            "type": "timestamp"
          },
          "Description": {},
          "DisplayName": {},
          "FailureReason": {},
          "ImageArn": {},
          "ImageName": {},
          "ImageStatus": {},
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "RoleArn": {}
        }
      }
    },
    "DescribeImageVersion": {
      "input": {
        "type": "structure",
        "required": [
          "ImageName"
        ],
        "members": {
          "ImageName": {},
          "Version": {
            "type": "integer"
          },
          "Alias": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BaseImage": {},
          "ContainerImage": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "FailureReason": {},
          "ImageArn": {},
          "ImageVersionArn": {},
          "ImageVersionStatus": {},
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "Version": {
            "type": "integer"
          },
          "VendorGuidance": {},
          "JobType": {},
          "MLFramework": {},
          "ProgrammingLang": {},
          "Processor": {},
          "Horovod": {
            "type": "boolean"
          },
          "ReleaseNotes": {}
        }
      }
    },
    "DescribeInferenceComponent": {
      "input": {
        "type": "structure",
        "required": [
          "InferenceComponentName"
        ],
        "members": {
          "InferenceComponentName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "InferenceComponentName",
          "InferenceComponentArn",
          "EndpointName",
          "EndpointArn",
          "CreationTime",
          "LastModifiedTime"
        ],
        "members": {
          "InferenceComponentName": {},
          "InferenceComponentArn": {},
          "EndpointName": {},
          "EndpointArn": {},
          "VariantName": {},
          "FailureReason": {},
          "Specification": {
            "type": "structure",
            "members": {
              "ModelName": {},
              "Container": {
                "type": "structure",
                "members": {
                  "DeployedImage": {
                    "shape": "S10f"
                  },
                  "ArtifactUrl": {},
                  "Environment": {
                    "shape": "S16"
                  }
                }
              },
              "StartupParameters": {
                "shape": "Sj7"
              },
              "ComputeResourceRequirements": {
                "shape": "Sj8"
              }
            }
          },
          "RuntimeConfig": {
            "type": "structure",
            "members": {
              "DesiredCopyCount": {
                "type": "integer"
              },
              "CurrentCopyCount": {
                "type": "integer"
              }
            }
          },
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "InferenceComponentStatus": {}
        }
      }
    },
    "DescribeInferenceExperiment": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Arn",
          "Name",
          "Type",
          "Status",
          "EndpointMetadata",
          "ModelVariants"
        ],
        "members": {
          "Arn": {},
          "Name": {},
          "Type": {},
          "Schedule": {
            "shape": "Sjj"
          },
          "Status": {},
          "StatusReason": {},
          "Description": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "CompletionTime": {
            "type": "timestamp"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "RoleArn": {},
          "EndpointMetadata": {
            "type": "structure",
            "required": [
              "EndpointName"
            ],
            "members": {
              "EndpointName": {},
              "EndpointConfigName": {},
              "EndpointStatus": {},
              "FailureReason": {}
            }
          },
          "ModelVariants": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ModelName",
                "VariantName",
                "InfrastructureConfig",
                "Status"
              ],
              "members": {
                "ModelName": {},
                "VariantName": {},
                "InfrastructureConfig": {
                  "shape": "Sjp"
                },
                "Status": {}
              }
            }
          },
          "DataStorageConfig": {
            "shape": "Sju"
          },
          "ShadowModeConfig": {
            "shape": "Sjv"
          },
          "KmsKey": {}
        }
      }
    },
    "DescribeInferenceRecommendationsJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobName"
        ],
        "members": {
          "JobName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "JobName",
          "JobType",
          "JobArn",
          "RoleArn",
          "Status",
          "CreationTime",
          "LastModifiedTime",
          "InputConfig"
        ],
        "members": {
          "JobName": {},
          "JobDescription": {},
          "JobType": {},
          "JobArn": {},
          "RoleArn": {},
          "Status": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "CompletionTime": {
            "type": "timestamp"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "FailureReason": {},
          "InputConfig": {
            "shape": "Sk3"
          },
          "StoppingConditions": {
            "shape": "Sl9"
          },
          "InferenceRecommendations": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Metrics",
                "EndpointConfiguration",
                "ModelConfiguration"
              ],
              "members": {
                "RecommendationId": {},
                "Metrics": {
                  "shape": "S137"
                },
                "EndpointConfiguration": {
                  "shape": "S13a"
                },
                "ModelConfiguration": {
                  "shape": "S13c"
                },
                "InvocationEndTime": {
                  "type": "timestamp"
                },
                "InvocationStartTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "EndpointPerformances": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Metrics",
                "EndpointInfo"
              ],
              "members": {
                "Metrics": {
                  "shape": "S13k"
                },
                "EndpointInfo": {
                  "shape": "Sl2"
                }
              }
            }
          }
        }
      }
    },
    "DescribeLabelingJob": {
      "input": {
        "type": "structure",
        "required": [
          "LabelingJobName"
        ],
        "members": {
          "LabelingJobName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "LabelingJobStatus",
          "LabelCounters",
          "CreationTime",
          "LastModifiedTime",
          "JobReferenceCode",
          "LabelingJobName",
          "LabelingJobArn",
          "InputConfig",
          "OutputConfig",
          "RoleArn",
          "HumanTaskConfig"
        ],
        "members": {
          "LabelingJobStatus": {},
          "LabelCounters": {
            "shape": "S13o"
          },
          "FailureReason": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "JobReferenceCode": {},
          "LabelingJobName": {},
          "LabelingJobArn": {},
          "LabelAttributeName": {},
          "InputConfig": {
            "shape": "Sll"
          },
          "OutputConfig": {
            "shape": "Sls"
          },
          "RoleArn": {},
          "LabelCategoryConfigS3Uri": {},
          "StoppingConditions": {
            "shape": "Slt"
          },
          "LabelingJobAlgorithmsConfig": {
            "shape": "Slw"
          },
          "HumanTaskConfig": {
            "shape": "Sm0"
          },
          "Tags": {
            "shape": "S7"
          },
          "LabelingJobOutput": {
            "shape": "S13r"
          }
        }
      }
    },
    "DescribeLineageGroup": {
      "input": {
        "type": "structure",
        "required": [
          "LineageGroupName"
        ],
        "members": {
          "LineageGroupName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LineageGroupName": {},
          "LineageGroupArn": {},
          "DisplayName": {},
          "Description": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "CreatedBy": {
            "shape": "Sww"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "LastModifiedBy": {
            "shape": "Sww"
          }
        }
      }
    },
    "DescribeModel": {
      "input": {
        "type": "structure",
        "required": [
          "ModelName"
        ],
        "members": {
          "ModelName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ModelName",
          "CreationTime",
          "ModelArn"
        ],
        "members": {
          "ModelName": {},
          "PrimaryContainer": {
            "shape": "Smf"
          },
          "Containers": {
            "shape": "Smo"
          },
          "InferenceExecutionConfig": {
            "shape": "Smp"
          },
          "ExecutionRoleArn": {},
          "VpcConfig": {
            "shape": "S6a"
          },
          "CreationTime": {
            "type": "timestamp"
          },
          "ModelArn": {},
          "EnableNetworkIsolation": {
            "type": "boolean"
          },
          "DeploymentRecommendation": {
            "shape": "S13w"
          }
        }
      }
    },
    "DescribeModelBiasJobDefinition": {
      "input": {
        "type": "structure",
        "required": [
          "JobDefinitionName"
        ],
        "members": {
          "JobDefinitionName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "JobDefinitionArn",
          "JobDefinitionName",
          "CreationTime",
          "ModelBiasAppSpecification",
          "ModelBiasJobInput",
          "ModelBiasJobOutputConfig",
          "JobResources",
          "RoleArn"
        ],
        "members": {
          "JobDefinitionArn": {},
          "JobDefinitionName": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "ModelBiasBaselineConfig": {
            "shape": "Smt"
          },
          "ModelBiasAppSpecification": {
            "shape": "Smu"
          },
          "ModelBiasJobInput": {
            "shape": "Smv"
          },
          "ModelBiasJobOutputConfig": {
            "shape": "S9t"
          },
          "JobResources": {
            "shape": "S9z"
          },
          "NetworkConfig": {
            "shape": "Sa4"
          },
          "RoleArn": {},
          "StoppingCondition": {
            "shape": "Sa5"
          }
        }
      }
    },
    "DescribeModelCard": {
      "input": {
        "type": "structure",
        "required": [
          "ModelCardName"
        ],
        "members": {
          "ModelCardName": {},
          "ModelCardVersion": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ModelCardArn",
          "ModelCardName",
          "ModelCardVersion",
          "Content",
          "ModelCardStatus",
          "CreationTime",
          "CreatedBy"
        ],
        "members": {
          "ModelCardArn": {},
          "ModelCardName": {},
          "ModelCardVersion": {
            "type": "integer"
          },
          "Content": {
            "shape": "Sn0"
          },
          "ModelCardStatus": {},
          "SecurityConfig": {
            "shape": "Smz"
          },
          "CreationTime": {
            "type": "timestamp"
          },
          "CreatedBy": {
            "shape": "Sww"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "LastModifiedBy": {
            "shape": "Sww"
          },
          "ModelCardProcessingStatus": {}
        }
      }
    },
    "DescribeModelCardExportJob": {
      "input": {
        "type": "structure",
        "required": [
          "ModelCardExportJobArn"
        ],
        "members": {
          "ModelCardExportJobArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ModelCardExportJobName",
          "ModelCardExportJobArn",
          "Status",
          "ModelCardName",
          "ModelCardVersion",
          "OutputConfig",
          "CreatedAt",
          "LastModifiedAt"
        ],
        "members": {
          "ModelCardExportJobName": {},
          "ModelCardExportJobArn": {},
          "Status": {},
          "ModelCardName": {},
          "ModelCardVersion": {
            "type": "integer"
          },
          "OutputConfig": {
            "shape": "Sn6"
          },
          "CreatedAt": {
            "type": "timestamp"
          },
          "LastModifiedAt": {
            "type": "timestamp"
          },
          "FailureReason": {},
          "ExportArtifacts": {
            "type": "structure",
            "required": [
              "S3ExportArtifacts"
            ],
            "members": {
              "S3ExportArtifacts": {}
            }
          }
        }
      }
    },
    "DescribeModelExplainabilityJobDefinition": {
      "input": {
        "type": "structure",
        "required": [
          "JobDefinitionName"
        ],
        "members": {
          "JobDefinitionName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "JobDefinitionArn",
          "JobDefinitionName",
          "CreationTime",
          "ModelExplainabilityAppSpecification",
          "ModelExplainabilityJobInput",
          "ModelExplainabilityJobOutputConfig",
          "JobResources",
          "RoleArn"
        ],
        "members": {
          "JobDefinitionArn": {},
          "JobDefinitionName": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "ModelExplainabilityBaselineConfig": {
            "shape": "Sna"
          },
          "ModelExplainabilityAppSpecification": {
            "shape": "Snb"
          },
          "ModelExplainabilityJobInput": {
            "shape": "Snc"
          },
          "ModelExplainabilityJobOutputConfig": {
            "shape": "S9t"
          },
          "JobResources": {
            "shape": "S9z"
          },
          "NetworkConfig": {
            "shape": "Sa4"
          },
          "RoleArn": {},
          "StoppingCondition": {
            "shape": "Sa5"
          }
        }
      }
    },
    "DescribeModelPackage": {
      "input": {
        "type": "structure",
        "required": [
          "ModelPackageName"
        ],
        "members": {
          "ModelPackageName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ModelPackageName",
          "ModelPackageArn",
          "CreationTime",
          "ModelPackageStatus",
          "ModelPackageStatusDetails"
        ],
        "members": {
          "ModelPackageName": {},
          "ModelPackageGroupName": {},
          "ModelPackageVersion": {
            "type": "integer"
          },
          "ModelPackageArn": {},
          "ModelPackageDescription": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "InferenceSpecification": {
            "shape": "Sr"
          },
          "SourceAlgorithmSpecification": {
            "shape": "Sni"
          },
          "ValidationSpecification": {
            "shape": "Snf"
          },
          "ModelPackageStatus": {},
          "ModelPackageStatusDetails": {
            "shape": "S14d"
          },
          "CertifyForMarketplace": {
            "type": "boolean"
          },
          "ModelApprovalStatus": {},
          "CreatedBy": {
            "shape": "Sww"
          },
          "MetadataProperties": {
            "shape": "S21"
          },
          "ModelMetrics": {
            "shape": "Snl"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "LastModifiedBy": {
            "shape": "Sww"
          },
          "ApprovalDescription": {},
          "Domain": {},
          "Task": {},
          "SamplePayloadUrl": {},
          "CustomerMetadataProperties": {
            "shape": "Sns"
          },
          "DriftCheckBaselines": {
            "shape": "Snv"
          },
          "AdditionalInferenceSpecifications": {
            "shape": "So1"
          },
          "SkipModelValidation": {},
          "SourceUri": {}
        }
      }
    },
    "DescribeModelPackageGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ModelPackageGroupName"
        ],
        "members": {
          "ModelPackageGroupName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ModelPackageGroupName",
          "ModelPackageGroupArn",
          "CreationTime",
          "CreatedBy",
          "ModelPackageGroupStatus"
        ],
        "members": {
          "ModelPackageGroupName": {},
          "ModelPackageGroupArn": {},
          "ModelPackageGroupDescription": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "CreatedBy": {
            "shape": "Sww"
          },
          "ModelPackageGroupStatus": {}
        }
      }
    },
    "DescribeModelQualityJobDefinition": {
      "input": {
        "type": "structure",
        "required": [
          "JobDefinitionName"
        ],
        "members": {
          "JobDefinitionName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "JobDefinitionArn",
          "JobDefinitionName",
          "CreationTime",
          "ModelQualityAppSpecification",
          "ModelQualityJobInput",
          "ModelQualityJobOutputConfig",
          "JobResources",
          "RoleArn"
        ],
        "members": {
          "JobDefinitionArn": {},
          "JobDefinitionName": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "ModelQualityBaselineConfig": {
            "shape": "Soa"
          },
          "ModelQualityAppSpecification": {
            "shape": "Sob"
          },
          "ModelQualityJobInput": {
            "shape": "Sod"
          },
          "ModelQualityJobOutputConfig": {
            "shape": "S9t"
          },
          "JobResources": {
            "shape": "S9z"
          },
          "NetworkConfig": {
            "shape": "Sa4"
          },
          "RoleArn": {},
          "StoppingCondition": {
            "shape": "Sa5"
          }
        }
      }
    },
    "DescribeMonitoringSchedule": {
      "input": {
        "type": "structure",
        "required": [
          "MonitoringScheduleName"
        ],
        "members": {
          "MonitoringScheduleName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "MonitoringScheduleArn",
          "MonitoringScheduleName",
          "MonitoringScheduleStatus",
          "CreationTime",
          "LastModifiedTime",
          "MonitoringScheduleConfig"
        ],
        "members": {
          "MonitoringScheduleArn": {},
          "MonitoringScheduleName": {},
          "MonitoringScheduleStatus": {},
          "MonitoringType": {},
          "FailureReason": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "MonitoringScheduleConfig": {
            "shape": "Soh"
          },
          "EndpointName": {},
          "LastMonitoringExecutionSummary": {
            "shape": "S14q"
          }
        }
      }
    },
    "DescribeNotebookInstance": {
      "input": {
        "type": "structure",
        "required": [
          "NotebookInstanceName"
        ],
        "members": {
          "NotebookInstanceName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NotebookInstanceArn": {},
          "NotebookInstanceName": {},
          "NotebookInstanceStatus": {},
          "FailureReason": {},
          "Url": {},
          "InstanceType": {},
          "SubnetId": {},
          "SecurityGroups": {
            "shape": "Sai"
          },
          "RoleArn": {},
          "KmsKeyId": {},
          "NetworkInterfaceId": {},
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "CreationTime": {
            "type": "timestamp"
          },
          "NotebookInstanceLifecycleConfigName": {},
          "DirectInternetAccess": {},
          "VolumeSizeInGB": {
            "type": "integer"
          },
          "AcceleratorTypes": {
            "shape": "Soy"
          },
          "DefaultCodeRepository": {},
          "AdditionalCodeRepositories": {
            "shape": "Sp1"
          },
          "RootAccess": {},
          "PlatformIdentifier": {},
          "InstanceMetadataServiceConfiguration": {
            "shape": "Sp4"
          }
        }
      }
    },
    "DescribeNotebookInstanceLifecycleConfig": {
      "input": {
        "type": "structure",
        "required": [
          "NotebookInstanceLifecycleConfigName"
        ],
        "members": {
          "NotebookInstanceLifecycleConfigName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NotebookInstanceLifecycleConfigArn": {},
          "NotebookInstanceLifecycleConfigName": {},
          "OnCreate": {
            "shape": "Sp9"
          },
          "OnStart": {
            "shape": "Sp9"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "CreationTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribePipeline": {
      "input": {
        "type": "structure",
        "required": [
          "PipelineName"
        ],
        "members": {
          "PipelineName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PipelineArn": {},
          "PipelineName": {},
          "PipelineDisplayName": {},
          "PipelineDefinition": {},
          "PipelineDescription": {},
          "RoleArn": {},
          "PipelineStatus": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "LastRunTime": {
            "type": "timestamp"
          },
          "CreatedBy": {
            "shape": "Sww"
          },
          "LastModifiedBy": {
            "shape": "Sww"
          },
          "ParallelismConfiguration": {
            "shape": "Spn"
          }
        }
      }
    },
    "DescribePipelineDefinitionForExecution": {
      "input": {
        "type": "structure",
        "required": [
          "PipelineExecutionArn"
        ],
        "members": {
          "PipelineExecutionArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PipelineDefinition": {},
          "CreationTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribePipelineExecution": {
      "input": {
        "type": "structure",
        "required": [
          "PipelineExecutionArn"
        ],
        "members": {
          "PipelineExecutionArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PipelineArn": {},
          "PipelineExecutionArn": {},
          "PipelineExecutionDisplayName": {},
          "PipelineExecutionStatus": {},
          "PipelineExecutionDescription": {},
          "PipelineExperimentConfig": {
            "shape": "S15a"
          },
          "FailureReason": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "CreatedBy": {
            "shape": "Sww"
          },
          "LastModifiedBy": {
            "shape": "Sww"
          },
          "ParallelismConfiguration": {
            "shape": "Spn"
          },
          "SelectiveExecutionConfig": {
            "shape": "S15c"
          }
        }
      }
    },
    "DescribeProcessingJob": {
      "input": {
        "type": "structure",
        "required": [
          "ProcessingJobName"
        ],
        "members": {
          "ProcessingJobName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ProcessingJobName",
          "ProcessingResources",
          "AppSpecification",
          "ProcessingJobArn",
          "ProcessingJobStatus",
          "CreationTime"
        ],
        "members": {
          "ProcessingInputs": {
            "shape": "Sq0"
          },
          "ProcessingOutputConfig": {
            "shape": "Sqn"
          },
          "ProcessingJobName": {},
          "ProcessingResources": {
            "shape": "Sqs"
          },
          "StoppingCondition": {
            "shape": "Squ"
          },
          "AppSpecification": {
            "shape": "Sqw"
          },
          "Environment": {
            "shape": "Sqy"
          },
          "NetworkConfig": {
            "shape": "Sop"
          },
          "RoleArn": {},
          "ExperimentConfig": {
            "shape": "Sqz"
          },
          "ProcessingJobArn": {},
          "ProcessingJobStatus": {},
          "ExitMessage": {},
          "FailureReason": {},
          "ProcessingEndTime": {
            "type": "timestamp"
          },
          "ProcessingStartTime": {
            "type": "timestamp"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "CreationTime": {
            "type": "timestamp"
          },
          "MonitoringScheduleArn": {},
          "AutoMLJobArn": {},
          "TrainingJobArn": {}
        }
      }
    },
    "DescribeProject": {
      "input": {
        "type": "structure",
        "required": [
          "ProjectName"
        ],
        "members": {
          "ProjectName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ProjectArn",
          "ProjectName",
          "ProjectId",
          "ServiceCatalogProvisioningDetails",
          "ProjectStatus",
          "CreationTime"
        ],
        "members": {
          "ProjectArn": {},
          "ProjectName": {},
          "ProjectId": {},
          "ProjectDescription": {},
          "ServiceCatalogProvisioningDetails": {
            "shape": "Sr4"
          },
          "ServiceCatalogProvisionedProductDetails": {
            "shape": "S15l"
          },
          "ProjectStatus": {},
          "CreatedBy": {
            "shape": "Sww"
          },
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "LastModifiedBy": {
            "shape": "Sww"
          }
        }
      }
    },
    "DescribeSpace": {
      "input": {
        "type": "structure",
        "required": [
          "DomainId",
          "SpaceName"
        ],
        "members": {
          "DomainId": {},
          "SpaceName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DomainId": {},
          "SpaceArn": {},
          "SpaceName": {},
          "HomeEfsFileSystemUid": {},
          "Status": {},
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "CreationTime": {
            "type": "timestamp"
          },
          "FailureReason": {},
          "SpaceSettings": {
            "shape": "Sre"
          },
          "OwnershipSettings": {
            "shape": "Srm"
          },
          "SpaceSharingSettings": {
            "shape": "Srn"
          },
          "SpaceDisplayName": {},
          "Url": {}
        }
      }
    },
    "DescribeStudioLifecycleConfig": {
      "input": {
        "type": "structure",
        "required": [
          "StudioLifecycleConfigName"
        ],
        "members": {
          "StudioLifecycleConfigName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StudioLifecycleConfigArn": {},
          "StudioLifecycleConfigName": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "StudioLifecycleConfigContent": {},
          "StudioLifecycleConfigAppType": {}
        }
      }
    },
    "DescribeSubscribedWorkteam": {
      "input": {
        "type": "structure",
        "required": [
          "WorkteamArn"
        ],
        "members": {
          "WorkteamArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "SubscribedWorkteam"
        ],
        "members": {
          "SubscribedWorkteam": {
            "shape": "S15w"
          }
        }
      }
    },
    "DescribeTrainingJob": {
      "input": {
        "type": "structure",
        "required": [
          "TrainingJobName"
        ],
        "members": {
          "TrainingJobName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TrainingJobName",
          "TrainingJobArn",
          "ModelArtifacts",
          "TrainingJobStatus",
          "SecondaryStatus",
          "AlgorithmSpecification",
          "ResourceConfig",
          "StoppingCondition",
          "CreationTime"
        ],
        "members": {
          "TrainingJobName": {},
          "TrainingJobArn": {},
          "TuningJobArn": {},
          "LabelingJobArn": {},
          "AutoMLJobArn": {},
          "ModelArtifacts": {
            "shape": "Sz6"
          },
          "TrainingJobStatus": {},
          "SecondaryStatus": {},
          "FailureReason": {},
          "HyperParameters": {
            "shape": "S33"
          },
          "AlgorithmSpecification": {
            "shape": "Sry"
          },
          "RoleArn": {},
          "InputDataConfig": {
            "shape": "S35"
          },
          "OutputDataConfig": {
            "shape": "S3n"
          },
          "ResourceConfig": {
            "shape": "S3q"
          },
          "WarmPoolStatus": {
            "shape": "S160"
          },
          "VpcConfig": {
            "shape": "S6a"
          },
          "StoppingCondition": {
            "shape": "S3w"
          },
          "CreationTime": {
            "type": "timestamp"
          },
          "TrainingStartTime": {
            "type": "timestamp"
          },
          "TrainingEndTime": {
            "type": "timestamp"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "SecondaryStatusTransitions": {
            "shape": "S163"
          },
          "FinalMetricDataList": {
            "shape": "S166"
          },
          "EnableNetworkIsolation": {
            "type": "boolean"
          },
          "EnableInterContainerTrafficEncryption": {
            "type": "boolean"
          },
          "EnableManagedSpotTraining": {
            "type": "boolean"
          },
          "CheckpointConfig": {
            "shape": "Si7"
          },
          "TrainingTimeInSeconds": {
            "type": "integer"
          },
          "BillableTimeInSeconds": {
            "type": "integer"
          },
          "DebugHookConfig": {
            "shape": "Ss7"
          },
          "ExperimentConfig": {
            "shape": "Sqz"
          },
          "DebugRuleConfigurations": {
            "shape": "Ssf"
          },
          "TensorBoardOutputConfig": {
            "shape": "Ssj"
          },
          "DebugRuleEvaluationStatuses": {
            "shape": "S16a"
          },
          "ProfilerConfig": {
            "shape": "Ssk"
          },
          "ProfilerRuleConfigurations": {
            "shape": "Sso"
          },
          "ProfilerRuleEvaluationStatuses": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "RuleConfigurationName": {},
                "RuleEvaluationJobArn": {},
                "RuleEvaluationStatus": {},
                "StatusDetails": {},
                "LastModifiedTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "ProfilingStatus": {},
          "Environment": {
            "shape": "Ssq"
          },
          "RetryStrategy": {
            "shape": "Si8"
          },
          "RemoteDebugConfig": {
            "shape": "Sst"
          },
          "InfraCheckConfig": {
            "shape": "Ssv"
          }
        }
      }
    },
    "DescribeTransformJob": {
      "input": {
        "type": "structure",
        "required": [
          "TransformJobName"
        ],
        "members": {
          "TransformJobName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TransformJobName",
          "TransformJobArn",
          "TransformJobStatus",
          "ModelName",
          "TransformInput",
          "TransformResources",
          "CreationTime"
        ],
        "members": {
          "TransformJobName": {},
          "TransformJobArn": {},
          "TransformJobStatus": {},
          "FailureReason": {},
          "ModelName": {},
          "MaxConcurrentTransforms": {
            "type": "integer"
          },
          "ModelClientConfig": {
            "shape": "St1"
          },
          "MaxPayloadInMB": {
            "type": "integer"
          },
          "BatchStrategy": {},
          "Environment": {
            "shape": "S44"
          },
          "TransformInput": {
            "shape": "S47"
          },
          "TransformOutput": {
            "shape": "S4b"
          },
          "DataCaptureConfig": {
            "shape": "St4"
          },
          "TransformResources": {
            "shape": "S4e"
          },
          "CreationTime": {
            "type": "timestamp"
          },
          "TransformStartTime": {
            "type": "timestamp"
          },
          "TransformEndTime": {
            "type": "timestamp"
          },
          "LabelingJobArn": {},
          "AutoMLJobArn": {},
          "DataProcessing": {
            "shape": "St5"
          },
          "ExperimentConfig": {
            "shape": "Sqz"
          }
        }
      }
    },
    "DescribeTrial": {
      "input": {
        "type": "structure",
        "required": [
          "TrialName"
        ],
        "members": {
          "TrialName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrialName": {},
          "TrialArn": {},
          "DisplayName": {},
          "ExperimentName": {},
          "Source": {
            "shape": "S16m"
          },
          "CreationTime": {
            "type": "timestamp"
          },
          "CreatedBy": {
            "shape": "Sww"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "LastModifiedBy": {
            "shape": "Sww"
          },
          "MetadataProperties": {
            "shape": "S21"
          }
        }
      }
    },
    "DescribeTrialComponent": {
      "input": {
        "type": "structure",
        "required": [
          "TrialComponentName"
        ],
        "members": {
          "TrialComponentName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrialComponentName": {},
          "TrialComponentArn": {},
          "DisplayName": {},
          "Source": {
            "shape": "S16q"
          },
          "Status": {
            "shape": "Std"
          },
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "CreationTime": {
            "type": "timestamp"
          },
          "CreatedBy": {
            "shape": "Sww"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "LastModifiedBy": {
            "shape": "Sww"
          },
          "Parameters": {
            "shape": "Stg"
          },
          "InputArtifacts": {
            "shape": "Stk"
          },
          "OutputArtifacts": {
            "shape": "Stk"
          },
          "MetadataProperties": {
            "shape": "S21"
          },
          "Metrics": {
            "shape": "S16s"
          },
          "LineageGroupArn": {},
          "Sources": {
            "type": "list",
            "member": {
              "shape": "S16q"
            }
          }
        }
      }
    },
    "DescribeUserProfile": {
      "input": {
        "type": "structure",
        "required": [
          "DomainId",
          "UserProfileName"
        ],
        "members": {
          "DomainId": {},
          "UserProfileName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DomainId": {},
          "UserProfileArn": {},
          "UserProfileName": {},
          "HomeEfsFileSystemUid": {},
          "Status": {},
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "CreationTime": {
            "type": "timestamp"
          },
          "FailureReason": {},
          "SingleSignOnUserIdentifier": {},
          "SingleSignOnUserValue": {},
          "UserSettings": {
            "shape": "Sah"
          }
        }
      }
    },
    "DescribeWorkforce": {
      "input": {
        "type": "structure",
        "required": [
          "WorkforceName"
        ],
        "members": {
          "WorkforceName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Workforce"
        ],
        "members": {
          "Workforce": {
            "shape": "S172"
          }
        }
      }
    },
    "DescribeWorkteam": {
      "input": {
        "type": "structure",
        "required": [
          "WorkteamName"
        ],
        "members": {
          "WorkteamName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Workteam"
        ],
        "members": {
          "Workteam": {
            "shape": "S17a"
          }
        }
      }
    },
    "DisableSagemakerServicecatalogPortfolio": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisassociateTrialComponent": {
      "input": {
        "type": "structure",
        "required": [
          "TrialComponentName",
          "TrialName"
        ],
        "members": {
          "TrialComponentName": {},
          "TrialName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrialComponentArn": {},
          "TrialArn": {}
        }
      }
    },
    "EnableSagemakerServicecatalogPortfolio": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "GetDeviceFleetReport": {
      "input": {
        "type": "structure",
        "required": [
          "DeviceFleetName"
        ],
        "members": {
          "DeviceFleetName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DeviceFleetArn",
          "DeviceFleetName"
        ],
        "members": {
          "DeviceFleetArn": {},
          "DeviceFleetName": {},
          "OutputConfig": {
            "shape": "Sab"
          },
          "Description": {},
          "ReportGenerated": {
            "type": "timestamp"
          },
          "DeviceStats": {
            "type": "structure",
            "required": [
              "ConnectedDeviceCount",
              "RegisteredDeviceCount"
            ],
            "members": {
              "ConnectedDeviceCount": {
                "type": "long"
              },
              "RegisteredDeviceCount": {
                "type": "long"
              }
            }
          },
          "AgentVersions": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Version",
                "AgentCount"
              ],
              "members": {
                "Version": {},
                "AgentCount": {
                  "type": "long"
                }
              }
            }
          },
          "ModelStats": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ModelName",
                "ModelVersion",
                "OfflineDeviceCount",
                "ConnectedDeviceCount",
                "ActiveDeviceCount",
                "SamplingDeviceCount"
              ],
              "members": {
                "ModelName": {},
                "ModelVersion": {},
                "OfflineDeviceCount": {
                  "type": "long"
                },
                "ConnectedDeviceCount": {
                  "type": "long"
                },
                "ActiveDeviceCount": {
                  "type": "long"
                },
                "SamplingDeviceCount": {
                  "type": "long"
                }
              }
            }
          }
        }
      }
    },
    "GetLineageGroupPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "LineageGroupName"
        ],
        "members": {
          "LineageGroupName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LineageGroupArn": {},
          "ResourcePolicy": {}
        }
      }
    },
    "GetModelPackageGroupPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "ModelPackageGroupName"
        ],
        "members": {
          "ModelPackageGroupName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ResourcePolicy"
        ],
        "members": {
          "ResourcePolicy": {}
        }
      }
    },
    "GetSagemakerServicecatalogPortfolioStatus": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {}
        }
      }
    },
    "GetScalingConfigurationRecommendation": {
      "input": {
        "type": "structure",
        "required": [
          "InferenceRecommendationsJobName"
        ],
        "members": {
          "InferenceRecommendationsJobName": {},
          "RecommendationId": {},
          "EndpointName": {},
          "TargetCpuUtilizationPerCore": {
            "type": "integer"
          },
          "ScalingPolicyObjective": {
            "shape": "S182"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InferenceRecommendationsJobName": {},
          "RecommendationId": {},
          "EndpointName": {},
          "TargetCpuUtilizationPerCore": {
            "type": "integer"
          },
          "ScalingPolicyObjective": {
            "shape": "S182"
          },
          "Metric": {
            "type": "structure",
            "members": {
              "InvocationsPerInstance": {
                "type": "integer"
              },
              "ModelLatency": {
                "type": "integer"
              }
            }
          },
          "DynamicScalingConfiguration": {
            "type": "structure",
            "members": {
              "MinCapacity": {
                "type": "integer"
              },
              "MaxCapacity": {
                "type": "integer"
              },
              "ScaleInCooldown": {
                "type": "integer"
              },
              "ScaleOutCooldown": {
                "type": "integer"
              },
              "ScalingPolicies": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "TargetTracking": {
                      "type": "structure",
                      "members": {
                        "MetricSpecification": {
                          "type": "structure",
                          "members": {
                            "Predefined": {
                              "type": "structure",
                              "members": {
                                "PredefinedMetricType": {}
                              }
                            },
                            "Customized": {
                              "type": "structure",
                              "members": {
                                "MetricName": {},
                                "Namespace": {},
                                "Statistic": {}
                              }
                            }
                          },
                          "union": true
                        },
                        "TargetValue": {
                          "type": "double"
                        }
                      }
                    }
                  },
                  "union": true
                }
              }
            }
          }
        }
      }
    },
    "GetSearchSuggestions": {
      "input": {
        "type": "structure",
        "required": [
          "Resource"
        ],
        "members": {
          "Resource": {},
          "SuggestionQuery": {
            "type": "structure",
            "members": {
              "PropertyNameQuery": {
                "type": "structure",
                "required": [
                  "PropertyNameHint"
                ],
                "members": {
                  "PropertyNameHint": {}
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PropertyNameSuggestions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PropertyName": {}
              }
            }
          }
        }
      }
    },
    "ImportHubContent": {
      "input": {
        "type": "structure",
        "required": [
          "HubContentName",
          "HubContentType",
          "DocumentSchemaVersion",
          "HubName",
          "HubContentDocument"
        ],
        "members": {
          "HubContentName": {},
          "HubContentVersion": {},
          "HubContentType": {},
          "DocumentSchemaVersion": {},
          "HubName": {},
          "HubContentDisplayName": {},
          "HubContentDescription": {},
          "HubContentMarkdown": {},
          "HubContentDocument": {},
          "HubContentSearchKeywords": {
            "shape": "S11t"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "HubArn",
          "HubContentArn"
        ],
        "members": {
          "HubArn": {},
          "HubContentArn": {}
        }
      }
    },
    "ListActions": {
      "input": {
        "type": "structure",
        "members": {
          "SourceUri": {},
          "ActionType": {},
          "CreatedAfter": {
            "type": "timestamp"
          },
          "CreatedBefore": {
            "type": "timestamp"
          },
          "SortBy": {},
          "SortOrder": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ActionSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ActionArn": {},
                "ActionName": {},
                "Source": {
                  "shape": "S1u"
                },
                "ActionType": {},
                "Status": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListAlgorithms": {
      "input": {
        "type": "structure",
        "members": {
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NameContains": {},
          "NextToken": {},
          "SortBy": {},
          "SortOrder": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "AlgorithmSummaryList"
        ],
        "members": {
          "AlgorithmSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "AlgorithmName",
                "AlgorithmArn",
                "CreationTime",
                "AlgorithmStatus"
              ],
              "members": {
                "AlgorithmName": {},
                "AlgorithmArn": {},
                "AlgorithmDescription": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "AlgorithmStatus": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListAliases": {
      "input": {
        "type": "structure",
        "required": [
          "ImageName"
        ],
        "members": {
          "ImageName": {},
          "Alias": {},
          "Version": {
            "type": "integer"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SageMakerImageVersionAliases": {
            "shape": "Sit"
          },
          "NextToken": {}
        }
      }
    },
    "ListAppImageConfigs": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "NameContains": {},
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "ModifiedTimeBefore": {
            "type": "timestamp"
          },
          "ModifiedTimeAfter": {
            "type": "timestamp"
          },
          "SortBy": {},
          "SortOrder": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "AppImageConfigs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AppImageConfigArn": {},
                "AppImageConfigName": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "KernelGatewayImageConfig": {
                  "shape": "S4z"
                },
                "JupyterLabAppImageConfig": {
                  "shape": "S58"
                }
              }
            }
          }
        }
      }
    },
    "ListApps": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "SortOrder": {},
          "SortBy": {},
          "DomainIdEquals": {},
          "UserProfileNameEquals": {},
          "SpaceNameEquals": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Apps": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DomainId": {},
                "UserProfileName": {},
                "SpaceName": {},
                "AppType": {},
                "AppName": {},
                "Status": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "ResourceSpec": {
                  "shape": "S4p"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListArtifacts": {
      "input": {
        "type": "structure",
        "members": {
          "SourceUri": {},
          "ArtifactType": {},
          "CreatedAfter": {
            "type": "timestamp"
          },
          "CreatedBefore": {
            "type": "timestamp"
          },
          "SortBy": {},
          "SortOrder": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ArtifactSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ArtifactArn": {},
                "ArtifactName": {},
                "Source": {
                  "shape": "S5i"
                },
                "ArtifactType": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListAssociations": {
      "input": {
        "type": "structure",
        "members": {
          "SourceArn": {},
          "DestinationArn": {},
          "SourceType": {},
          "DestinationType": {},
          "AssociationType": {},
          "CreatedAfter": {
            "type": "timestamp"
          },
          "CreatedBefore": {
            "type": "timestamp"
          },
          "SortBy": {},
          "SortOrder": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssociationSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "SourceArn": {},
                "DestinationArn": {},
                "SourceType": {},
                "DestinationType": {},
                "AssociationType": {},
                "SourceName": {},
                "DestinationName": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "CreatedBy": {
                  "shape": "Sww"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListAutoMLJobs": {
      "input": {
        "type": "structure",
        "members": {
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "LastModifiedTimeAfter": {
            "type": "timestamp"
          },
          "LastModifiedTimeBefore": {
            "type": "timestamp"
          },
          "NameContains": {},
          "StatusEquals": {},
          "SortOrder": {},
          "SortBy": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "AutoMLJobSummaries"
        ],
        "members": {
          "AutoMLJobSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "AutoMLJobName",
                "AutoMLJobArn",
                "AutoMLJobStatus",
                "AutoMLJobSecondaryStatus",
                "CreationTime",
                "LastModifiedTime"
              ],
              "members": {
                "AutoMLJobName": {},
                "AutoMLJobArn": {},
                "AutoMLJobStatus": {},
                "AutoMLJobSecondaryStatus": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "EndTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "FailureReason": {},
                "PartialFailureReasons": {
                  "shape": "Sxh"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListCandidatesForAutoMLJob": {
      "input": {
        "type": "structure",
        "required": [
          "AutoMLJobName"
        ],
        "members": {
          "AutoMLJobName": {},
          "StatusEquals": {},
          "CandidateNameEquals": {},
          "SortOrder": {},
          "SortBy": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Candidates"
        ],
        "members": {
          "Candidates": {
            "type": "list",
            "member": {
              "shape": "Sxj"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListClusterNodes": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterName"
        ],
        "members": {
          "ClusterName": {},
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "InstanceGroupNameContains": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "SortBy": {},
          "SortOrder": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "NextToken",
          "ClusterNodeSummaries"
        ],
        "members": {
          "NextToken": {},
          "ClusterNodeSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "InstanceGroupName",
                "InstanceId",
                "InstanceType",
                "LaunchTime",
                "InstanceStatus"
              ],
              "members": {
                "InstanceGroupName": {},
                "InstanceId": {},
                "InstanceType": {},
                "LaunchTime": {
                  "type": "timestamp"
                },
                "InstanceStatus": {
                  "shape": "Syx"
                }
              }
            }
          }
        }
      }
    },
    "ListClusters": {
      "input": {
        "type": "structure",
        "members": {
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NameContains": {},
          "NextToken": {},
          "SortBy": {},
          "SortOrder": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "NextToken",
          "ClusterSummaries"
        ],
        "members": {
          "NextToken": {},
          "ClusterSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ClusterArn",
                "ClusterName",
                "CreationTime",
                "ClusterStatus"
              ],
              "members": {
                "ClusterArn": {},
                "ClusterName": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "ClusterStatus": {}
              }
            }
          }
        }
      }
    },
    "ListCodeRepositories": {
      "input": {
        "type": "structure",
        "members": {
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "LastModifiedTimeAfter": {
            "type": "timestamp"
          },
          "LastModifiedTimeBefore": {
            "type": "timestamp"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NameContains": {},
          "NextToken": {},
          "SortBy": {},
          "SortOrder": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CodeRepositorySummaryList"
        ],
        "members": {
          "CodeRepositorySummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "CodeRepositoryName",
                "CodeRepositoryArn",
                "CreationTime",
                "LastModifiedTime"
              ],
              "members": {
                "CodeRepositoryName": {},
                "CodeRepositoryArn": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "GitConfig": {
                  "shape": "S87"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListCompilationJobs": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "LastModifiedTimeAfter": {
            "type": "timestamp"
          },
          "LastModifiedTimeBefore": {
            "type": "timestamp"
          },
          "NameContains": {},
          "StatusEquals": {},
          "SortBy": {},
          "SortOrder": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CompilationJobSummaries"
        ],
        "members": {
          "CompilationJobSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "CompilationJobName",
                "CompilationJobArn",
                "CreationTime",
                "CompilationJobStatus"
              ],
              "members": {
                "CompilationJobName": {},
                "CompilationJobArn": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "CompilationStartTime": {
                  "type": "timestamp"
                },
                "CompilationEndTime": {
                  "type": "timestamp"
                },
                "CompilationTargetDevice": {},
                "CompilationTargetPlatformOs": {},
                "CompilationTargetPlatformArch": {},
                "CompilationTargetPlatformAccelerator": {},
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "CompilationJobStatus": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListContexts": {
      "input": {
        "type": "structure",
        "members": {
          "SourceUri": {},
          "ContextType": {},
          "CreatedAfter": {
            "type": "timestamp"
          },
          "CreatedBefore": {
            "type": "timestamp"
          },
          "SortBy": {},
          "SortOrder": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ContextSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ContextArn": {},
                "ContextName": {},
                "Source": {
                  "shape": "S8x"
                },
                "ContextType": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListDataQualityJobDefinitions": {
      "input": {
        "type": "structure",
        "members": {
          "EndpointName": {},
          "SortBy": {},
          "SortOrder": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "NameContains": {},
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "CreationTimeAfter": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "JobDefinitionSummaries"
        ],
        "members": {
          "JobDefinitionSummaries": {
            "shape": "S1au"
          },
          "NextToken": {}
        }
      }
    },
    "ListDeviceFleets": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "LastModifiedTimeAfter": {
            "type": "timestamp"
          },
          "LastModifiedTimeBefore": {
            "type": "timestamp"
          },
          "NameContains": {},
          "SortBy": {},
          "SortOrder": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DeviceFleetSummaries"
        ],
        "members": {
          "DeviceFleetSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "DeviceFleetArn",
                "DeviceFleetName"
              ],
              "members": {
                "DeviceFleetArn": {},
                "DeviceFleetName": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListDevices": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "LatestHeartbeatAfter": {
            "type": "timestamp"
          },
          "ModelName": {},
          "DeviceFleetName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DeviceSummaries"
        ],
        "members": {
          "DeviceSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "DeviceName",
                "DeviceArn"
              ],
              "members": {
                "DeviceName": {},
                "DeviceArn": {},
                "Description": {},
                "DeviceFleetName": {},
                "IotThingName": {},
                "RegistrationTime": {
                  "type": "timestamp"
                },
                "LatestHeartbeat": {
                  "type": "timestamp"
                },
                "Models": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "ModelName",
                      "ModelVersion"
                    ],
                    "members": {
                      "ModelName": {},
                      "ModelVersion": {}
                    }
                  }
                },
                "AgentVersion": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListDomains": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Domains": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DomainArn": {},
                "DomainId": {},
                "DomainName": {},
                "Status": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "Url": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListEdgeDeploymentPlans": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "LastModifiedTimeAfter": {
            "type": "timestamp"
          },
          "LastModifiedTimeBefore": {
            "type": "timestamp"
          },
          "NameContains": {},
          "DeviceFleetNameContains": {},
          "SortBy": {},
          "SortOrder": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "EdgeDeploymentPlanSummaries"
        ],
        "members": {
          "EdgeDeploymentPlanSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "EdgeDeploymentPlanArn",
                "EdgeDeploymentPlanName",
                "DeviceFleetName",
                "EdgeDeploymentSuccess",
                "EdgeDeploymentPending",
                "EdgeDeploymentFailed"
              ],
              "members": {
                "EdgeDeploymentPlanArn": {},
                "EdgeDeploymentPlanName": {},
                "DeviceFleetName": {},
                "EdgeDeploymentSuccess": {
                  "type": "integer"
                },
                "EdgeDeploymentPending": {
                  "type": "integer"
                },
                "EdgeDeploymentFailed": {
                  "type": "integer"
                },
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListEdgePackagingJobs": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "LastModifiedTimeAfter": {
            "type": "timestamp"
          },
          "LastModifiedTimeBefore": {
            "type": "timestamp"
          },
          "NameContains": {},
          "ModelNameContains": {},
          "StatusEquals": {},
          "SortBy": {},
          "SortOrder": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "EdgePackagingJobSummaries"
        ],
        "members": {
          "EdgePackagingJobSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "EdgePackagingJobArn",
                "EdgePackagingJobName",
                "EdgePackagingJobStatus"
              ],
              "members": {
                "EdgePackagingJobArn": {},
                "EdgePackagingJobName": {},
                "EdgePackagingJobStatus": {},
                "CompilationJobName": {},
                "ModelName": {},
                "ModelVersion": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListEndpointConfigs": {
      "input": {
        "type": "structure",
        "members": {
          "SortBy": {},
          "SortOrder": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "NameContains": {},
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "CreationTimeAfter": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "EndpointConfigs"
        ],
        "members": {
          "EndpointConfigs": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "EndpointConfigName",
                "EndpointConfigArn",
                "CreationTime"
              ],
              "members": {
                "EndpointConfigName": {},
                "EndpointConfigArn": {},
                "CreationTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListEndpoints": {
      "input": {
        "type": "structure",
        "members": {
          "SortBy": {},
          "SortOrder": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "NameContains": {},
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "LastModifiedTimeBefore": {
            "type": "timestamp"
          },
          "LastModifiedTimeAfter": {
            "type": "timestamp"
          },
          "StatusEquals": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Endpoints"
        ],
        "members": {
          "Endpoints": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "EndpointName",
                "EndpointArn",
                "CreationTime",
                "LastModifiedTime",
                "EndpointStatus"
              ],
              "members": {
                "EndpointName": {},
                "EndpointArn": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "EndpointStatus": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListExperiments": {
      "input": {
        "type": "structure",
        "members": {
          "CreatedAfter": {
            "type": "timestamp"
          },
          "CreatedBefore": {
            "type": "timestamp"
          },
          "SortBy": {},
          "SortOrder": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ExperimentSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ExperimentArn": {},
                "ExperimentName": {},
                "DisplayName": {},
                "ExperimentSource": {
                  "shape": "S10u"
                },
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListFeatureGroups": {
      "input": {
        "type": "structure",
        "members": {
          "NameContains": {},
          "FeatureGroupStatusEquals": {},
          "OfflineStoreStatusEquals": {},
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "SortOrder": {},
          "SortBy": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "FeatureGroupSummaries"
        ],
        "members": {
          "FeatureGroupSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "FeatureGroupName",
                "FeatureGroupArn",
                "CreationTime"
              ],
              "members": {
                "FeatureGroupName": {},
                "FeatureGroupArn": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "FeatureGroupStatus": {},
                "OfflineStoreStatus": {
                  "shape": "S112"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListFlowDefinitions": {
      "input": {
        "type": "structure",
        "members": {
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "SortOrder": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "FlowDefinitionSummaries"
        ],
        "members": {
          "FlowDefinitionSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "FlowDefinitionName",
                "FlowDefinitionArn",
                "FlowDefinitionStatus",
                "CreationTime"
              ],
              "members": {
                "FlowDefinitionName": {},
                "FlowDefinitionArn": {},
                "FlowDefinitionStatus": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "FailureReason": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListHubContentVersions": {
      "input": {
        "type": "structure",
        "required": [
          "HubName",
          "HubContentType",
          "HubContentName"
        ],
        "members": {
          "HubName": {},
          "HubContentType": {},
          "HubContentName": {},
          "MinVersion": {},
          "MaxSchemaVersion": {},
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "SortBy": {},
          "SortOrder": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "HubContentSummaries"
        ],
        "members": {
          "HubContentSummaries": {
            "shape": "S1ck"
          },
          "NextToken": {}
        }
      }
    },
    "ListHubContents": {
      "input": {
        "type": "structure",
        "required": [
          "HubName",
          "HubContentType"
        ],
        "members": {
          "HubName": {},
          "HubContentType": {},
          "NameContains": {},
          "MaxSchemaVersion": {},
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "SortBy": {},
          "SortOrder": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "HubContentSummaries"
        ],
        "members": {
          "HubContentSummaries": {
            "shape": "S1ck"
          },
          "NextToken": {}
        }
      }
    },
    "ListHubs": {
      "input": {
        "type": "structure",
        "members": {
          "NameContains": {},
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "LastModifiedTimeBefore": {
            "type": "timestamp"
          },
          "LastModifiedTimeAfter": {
            "type": "timestamp"
          },
          "SortBy": {},
          "SortOrder": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "HubSummaries"
        ],
        "members": {
          "HubSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "HubName",
                "HubArn",
                "HubStatus",
                "CreationTime",
                "LastModifiedTime"
              ],
              "members": {
                "HubName": {},
                "HubArn": {},
                "HubDisplayName": {},
                "HubDescription": {},
                "HubSearchKeywords": {
                  "shape": "Sgr"
                },
                "HubStatus": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListHumanTaskUis": {
      "input": {
        "type": "structure",
        "members": {
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "SortOrder": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "HumanTaskUiSummaries"
        ],
        "members": {
          "HumanTaskUiSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "HumanTaskUiName",
                "HumanTaskUiArn",
                "CreationTime"
              ],
              "members": {
                "HumanTaskUiName": {},
                "HumanTaskUiArn": {},
                "CreationTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListHyperParameterTuningJobs": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "SortBy": {},
          "SortOrder": {},
          "NameContains": {},
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "LastModifiedTimeAfter": {
            "type": "timestamp"
          },
          "LastModifiedTimeBefore": {
            "type": "timestamp"
          },
          "StatusEquals": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "HyperParameterTuningJobSummaries"
        ],
        "members": {
          "HyperParameterTuningJobSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "HyperParameterTuningJobName",
                "HyperParameterTuningJobArn",
                "HyperParameterTuningJobStatus",
                "Strategy",
                "CreationTime",
                "TrainingJobStatusCounters",
                "ObjectiveStatusCounters"
              ],
              "members": {
                "HyperParameterTuningJobName": {},
                "HyperParameterTuningJobArn": {},
                "HyperParameterTuningJobStatus": {},
                "Strategy": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "HyperParameterTuningEndTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "TrainingJobStatusCounters": {
                  "shape": "S128"
                },
                "ObjectiveStatusCounters": {
                  "shape": "S12a"
                },
                "ResourceLimits": {
                  "shape": "Sha"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListImageVersions": {
      "input": {
        "type": "structure",
        "required": [
          "ImageName"
        ],
        "members": {
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "ImageName": {},
          "LastModifiedTimeAfter": {
            "type": "timestamp"
          },
          "LastModifiedTimeBefore": {
            "type": "timestamp"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "SortBy": {},
          "SortOrder": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImageVersions": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "CreationTime",
                "ImageArn",
                "ImageVersionArn",
                "ImageVersionStatus",
                "LastModifiedTime",
                "Version"
              ],
              "members": {
                "CreationTime": {
                  "type": "timestamp"
                },
                "FailureReason": {},
                "ImageArn": {},
                "ImageVersionArn": {},
                "ImageVersionStatus": {},
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "Version": {
                  "type": "integer"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListImages": {
      "input": {
        "type": "structure",
        "members": {
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "LastModifiedTimeAfter": {
            "type": "timestamp"
          },
          "LastModifiedTimeBefore": {
            "type": "timestamp"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NameContains": {},
          "NextToken": {},
          "SortBy": {},
          "SortOrder": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Images": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "CreationTime",
                "ImageArn",
                "ImageName",
                "ImageStatus",
                "LastModifiedTime"
              ],
              "members": {
                "CreationTime": {
                  "type": "timestamp"
                },
                "Description": {},
                "DisplayName": {},
                "FailureReason": {},
                "ImageArn": {},
                "ImageName": {},
                "ImageStatus": {},
                "LastModifiedTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListInferenceComponents": {
      "input": {
        "type": "structure",
        "members": {
          "SortBy": {},
          "SortOrder": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "NameContains": {},
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "LastModifiedTimeBefore": {
            "type": "timestamp"
          },
          "LastModifiedTimeAfter": {
            "type": "timestamp"
          },
          "StatusEquals": {},
          "EndpointNameEquals": {},
          "VariantNameEquals": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "InferenceComponents"
        ],
        "members": {
          "InferenceComponents": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "CreationTime",
                "InferenceComponentArn",
                "InferenceComponentName",
                "EndpointArn",
                "EndpointName",
                "VariantName",
                "LastModifiedTime"
              ],
              "members": {
                "CreationTime": {
                  "type": "timestamp"
                },
                "InferenceComponentArn": {},
                "InferenceComponentName": {},
                "EndpointArn": {},
                "EndpointName": {},
                "VariantName": {},
                "InferenceComponentStatus": {},
                "LastModifiedTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListInferenceExperiments": {
      "input": {
        "type": "structure",
        "members": {
          "NameContains": {},
          "Type": {},
          "StatusEquals": {},
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "LastModifiedTimeAfter": {
            "type": "timestamp"
          },
          "LastModifiedTimeBefore": {
            "type": "timestamp"
          },
          "SortBy": {},
          "SortOrder": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InferenceExperiments": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Name",
                "Type",
                "Status",
                "CreationTime",
                "LastModifiedTime"
              ],
              "members": {
                "Name": {},
                "Type": {},
                "Schedule": {
                  "shape": "Sjj"
                },
                "Status": {},
                "StatusReason": {},
                "Description": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "CompletionTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "RoleArn": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListInferenceRecommendationsJobSteps": {
      "input": {
        "type": "structure",
        "required": [
          "JobName"
        ],
        "members": {
          "JobName": {},
          "Status": {},
          "StepType": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Steps": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "StepType",
                "JobName",
                "Status"
              ],
              "members": {
                "StepType": {},
                "JobName": {},
                "Status": {},
                "InferenceBenchmark": {
                  "type": "structure",
                  "required": [
                    "ModelConfiguration"
                  ],
                  "members": {
                    "Metrics": {
                      "shape": "S137"
                    },
                    "EndpointMetrics": {
                      "shape": "S13k"
                    },
                    "EndpointConfiguration": {
                      "shape": "S13a"
                    },
                    "ModelConfiguration": {
                      "shape": "S13c"
                    },
                    "FailureReason": {},
                    "InvocationEndTime": {
                      "type": "timestamp"
                    },
                    "InvocationStartTime": {
                      "type": "timestamp"
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListInferenceRecommendationsJobs": {
      "input": {
        "type": "structure",
        "members": {
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "LastModifiedTimeAfter": {
            "type": "timestamp"
          },
          "LastModifiedTimeBefore": {
            "type": "timestamp"
          },
          "NameContains": {},
          "StatusEquals": {},
          "SortBy": {},
          "SortOrder": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "ModelNameEquals": {},
          "ModelPackageVersionArnEquals": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "InferenceRecommendationsJobs"
        ],
        "members": {
          "InferenceRecommendationsJobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "JobName",
                "JobDescription",
                "JobType",
                "JobArn",
                "Status",
                "CreationTime",
                "RoleArn",
                "LastModifiedTime"
              ],
              "members": {
                "JobName": {},
                "JobDescription": {},
                "JobType": {},
                "JobArn": {},
                "Status": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "CompletionTime": {
                  "type": "timestamp"
                },
                "RoleArn": {},
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "FailureReason": {},
                "ModelName": {},
                "SamplePayloadUrl": {},
                "ModelPackageVersionArn": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListLabelingJobs": {
      "input": {
        "type": "structure",
        "members": {
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "LastModifiedTimeAfter": {
            "type": "timestamp"
          },
          "LastModifiedTimeBefore": {
            "type": "timestamp"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "NameContains": {},
          "SortBy": {},
          "SortOrder": {},
          "StatusEquals": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LabelingJobSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "LabelingJobName",
                "LabelingJobArn",
                "CreationTime",
                "LastModifiedTime",
                "LabelingJobStatus",
                "LabelCounters",
                "WorkteamArn",
                "PreHumanTaskLambdaArn"
              ],
              "members": {
                "LabelingJobName": {},
                "LabelingJobArn": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "LabelingJobStatus": {},
                "LabelCounters": {
                  "shape": "S13o"
                },
                "WorkteamArn": {},
                "PreHumanTaskLambdaArn": {},
                "AnnotationConsolidationLambdaArn": {},
                "FailureReason": {},
                "LabelingJobOutput": {
                  "shape": "S13r"
                },
                "InputConfig": {
                  "shape": "Sll"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListLabelingJobsForWorkteam": {
      "input": {
        "type": "structure",
        "required": [
          "WorkteamArn"
        ],
        "members": {
          "WorkteamArn": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "JobReferenceCodeContains": {},
          "SortBy": {},
          "SortOrder": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "LabelingJobSummaryList"
        ],
        "members": {
          "LabelingJobSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "JobReferenceCode",
                "WorkRequesterAccountId",
                "CreationTime"
              ],
              "members": {
                "LabelingJobName": {},
                "JobReferenceCode": {},
                "WorkRequesterAccountId": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LabelCounters": {
                  "type": "structure",
                  "members": {
                    "HumanLabeled": {
                      "type": "integer"
                    },
                    "PendingHuman": {
                      "type": "integer"
                    },
                    "Total": {
                      "type": "integer"
                    }
                  }
                },
                "NumberOfHumanWorkersPerDataObject": {
                  "type": "integer"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListLineageGroups": {
      "input": {
        "type": "structure",
        "members": {
          "CreatedAfter": {
            "type": "timestamp"
          },
          "CreatedBefore": {
            "type": "timestamp"
          },
          "SortBy": {},
          "SortOrder": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LineageGroupSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "LineageGroupArn": {},
                "LineageGroupName": {},
                "DisplayName": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListModelBiasJobDefinitions": {
      "input": {
        "type": "structure",
        "members": {
          "EndpointName": {},
          "SortBy": {},
          "SortOrder": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "NameContains": {},
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "CreationTimeAfter": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "JobDefinitionSummaries"
        ],
        "members": {
          "JobDefinitionSummaries": {
            "shape": "S1au"
          },
          "NextToken": {}
        }
      }
    },
    "ListModelCardExportJobs": {
      "input": {
        "type": "structure",
        "required": [
          "ModelCardName"
        ],
        "members": {
          "ModelCardName": {},
          "ModelCardVersion": {
            "type": "integer"
          },
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "ModelCardExportJobNameContains": {},
          "StatusEquals": {},
          "SortBy": {},
          "SortOrder": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ModelCardExportJobSummaries"
        ],
        "members": {
          "ModelCardExportJobSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ModelCardExportJobName",
                "ModelCardExportJobArn",
                "Status",
                "ModelCardName",
                "ModelCardVersion",
                "CreatedAt",
                "LastModifiedAt"
              ],
              "members": {
                "ModelCardExportJobName": {},
                "ModelCardExportJobArn": {},
                "Status": {},
                "ModelCardName": {},
                "ModelCardVersion": {
                  "type": "integer"
                },
                "CreatedAt": {
                  "type": "timestamp"
                },
                "LastModifiedAt": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListModelCardVersions": {
      "input": {
        "type": "structure",
        "required": [
          "ModelCardName"
        ],
        "members": {
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "MaxResults": {
            "type": "integer"
          },
          "ModelCardName": {},
          "ModelCardStatus": {},
          "NextToken": {},
          "SortBy": {},
          "SortOrder": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ModelCardVersionSummaryList"
        ],
        "members": {
          "ModelCardVersionSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ModelCardName",
                "ModelCardArn",
                "ModelCardStatus",
                "ModelCardVersion",
                "CreationTime"
              ],
              "members": {
                "ModelCardName": {},
                "ModelCardArn": {},
                "ModelCardStatus": {},
                "ModelCardVersion": {
                  "type": "integer"
                },
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListModelCards": {
      "input": {
        "type": "structure",
        "members": {
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NameContains": {},
          "ModelCardStatus": {},
          "NextToken": {},
          "SortBy": {},
          "SortOrder": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ModelCardSummaries"
        ],
        "members": {
          "ModelCardSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ModelCardName",
                "ModelCardArn",
                "ModelCardStatus",
                "CreationTime"
              ],
              "members": {
                "ModelCardName": {},
                "ModelCardArn": {},
                "ModelCardStatus": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListModelExplainabilityJobDefinitions": {
      "input": {
        "type": "structure",
        "members": {
          "EndpointName": {},
          "SortBy": {},
          "SortOrder": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "NameContains": {},
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "CreationTimeAfter": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "JobDefinitionSummaries"
        ],
        "members": {
          "JobDefinitionSummaries": {
            "shape": "S1au"
          },
          "NextToken": {}
        }
      }
    },
    "ListModelMetadata": {
      "input": {
        "type": "structure",
        "members": {
          "SearchExpression": {
            "type": "structure",
            "members": {
              "Filters": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "Name",
                    "Value"
                  ],
                  "members": {
                    "Name": {},
                    "Value": {}
                  }
                }
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ModelMetadataSummaries"
        ],
        "members": {
          "ModelMetadataSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Domain",
                "Framework",
                "Task",
                "Model",
                "FrameworkVersion"
              ],
              "members": {
                "Domain": {},
                "Framework": {},
                "Task": {},
                "Model": {},
                "FrameworkVersion": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListModelPackageGroups": {
      "input": {
        "type": "structure",
        "members": {
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NameContains": {},
          "NextToken": {},
          "SortBy": {},
          "SortOrder": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ModelPackageGroupSummaryList"
        ],
        "members": {
          "ModelPackageGroupSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ModelPackageGroupName",
                "ModelPackageGroupArn",
                "CreationTime",
                "ModelPackageGroupStatus"
              ],
              "members": {
                "ModelPackageGroupName": {},
                "ModelPackageGroupArn": {},
                "ModelPackageGroupDescription": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "ModelPackageGroupStatus": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListModelPackages": {
      "input": {
        "type": "structure",
        "members": {
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NameContains": {},
          "ModelApprovalStatus": {},
          "ModelPackageGroupName": {},
          "ModelPackageType": {},
          "NextToken": {},
          "SortBy": {},
          "SortOrder": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ModelPackageSummaryList"
        ],
        "members": {
          "ModelPackageSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ModelPackageArn",
                "CreationTime",
                "ModelPackageStatus"
              ],
              "members": {
                "ModelPackageName": {},
                "ModelPackageGroupName": {},
                "ModelPackageVersion": {
                  "type": "integer"
                },
                "ModelPackageArn": {},
                "ModelPackageDescription": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "ModelPackageStatus": {},
                "ModelApprovalStatus": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListModelQualityJobDefinitions": {
      "input": {
        "type": "structure",
        "members": {
          "EndpointName": {},
          "SortBy": {},
          "SortOrder": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "NameContains": {},
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "CreationTimeAfter": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "JobDefinitionSummaries"
        ],
        "members": {
          "JobDefinitionSummaries": {
            "shape": "S1au"
          },
          "NextToken": {}
        }
      }
    },
    "ListModels": {
      "input": {
        "type": "structure",
        "members": {
          "SortBy": {},
          "SortOrder": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "NameContains": {},
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "CreationTimeAfter": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Models"
        ],
        "members": {
          "Models": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ModelName",
                "ModelArn",
                "CreationTime"
              ],
              "members": {
                "ModelName": {},
                "ModelArn": {},
                "CreationTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListMonitoringAlertHistory": {
      "input": {
        "type": "structure",
        "members": {
          "MonitoringScheduleName": {},
          "MonitoringAlertName": {},
          "SortBy": {},
          "SortOrder": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "StatusEquals": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MonitoringAlertHistory": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "MonitoringScheduleName",
                "MonitoringAlertName",
                "CreationTime",
                "AlertStatus"
              ],
              "members": {
                "MonitoringScheduleName": {},
                "MonitoringAlertName": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "AlertStatus": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListMonitoringAlerts": {
      "input": {
        "type": "structure",
        "required": [
          "MonitoringScheduleName"
        ],
        "members": {
          "MonitoringScheduleName": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MonitoringAlertSummaries": {
            "shape": "S1g4"
          },
          "NextToken": {}
        }
      }
    },
    "ListMonitoringExecutions": {
      "input": {
        "type": "structure",
        "members": {
          "MonitoringScheduleName": {},
          "EndpointName": {},
          "SortBy": {},
          "SortOrder": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "ScheduledTimeBefore": {
            "type": "timestamp"
          },
          "ScheduledTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "LastModifiedTimeBefore": {
            "type": "timestamp"
          },
          "LastModifiedTimeAfter": {
            "type": "timestamp"
          },
          "StatusEquals": {},
          "MonitoringJobDefinitionName": {},
          "MonitoringTypeEquals": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "MonitoringExecutionSummaries"
        ],
        "members": {
          "MonitoringExecutionSummaries": {
            "type": "list",
            "member": {
              "shape": "S14q"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListMonitoringSchedules": {
      "input": {
        "type": "structure",
        "members": {
          "EndpointName": {},
          "SortBy": {},
          "SortOrder": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "NameContains": {},
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "LastModifiedTimeBefore": {
            "type": "timestamp"
          },
          "LastModifiedTimeAfter": {
            "type": "timestamp"
          },
          "StatusEquals": {},
          "MonitoringJobDefinitionName": {},
          "MonitoringTypeEquals": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "MonitoringScheduleSummaries"
        ],
        "members": {
          "MonitoringScheduleSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "MonitoringScheduleName",
                "MonitoringScheduleArn",
                "CreationTime",
                "LastModifiedTime",
                "MonitoringScheduleStatus"
              ],
              "members": {
                "MonitoringScheduleName": {},
                "MonitoringScheduleArn": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "MonitoringScheduleStatus": {},
                "EndpointName": {},
                "MonitoringJobDefinitionName": {},
                "MonitoringType": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListNotebookInstanceLifecycleConfigs": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "SortBy": {},
          "SortOrder": {},
          "NameContains": {},
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "LastModifiedTimeBefore": {
            "type": "timestamp"
          },
          "LastModifiedTimeAfter": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "NotebookInstanceLifecycleConfigs": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "NotebookInstanceLifecycleConfigName",
                "NotebookInstanceLifecycleConfigArn"
              ],
              "members": {
                "NotebookInstanceLifecycleConfigName": {},
                "NotebookInstanceLifecycleConfigArn": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "ListNotebookInstances": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "SortBy": {},
          "SortOrder": {},
          "NameContains": {},
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "LastModifiedTimeBefore": {
            "type": "timestamp"
          },
          "LastModifiedTimeAfter": {
            "type": "timestamp"
          },
          "StatusEquals": {},
          "NotebookInstanceLifecycleConfigNameContains": {},
          "DefaultCodeRepositoryContains": {},
          "AdditionalCodeRepositoryEquals": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "NotebookInstances": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "NotebookInstanceName",
                "NotebookInstanceArn"
              ],
              "members": {
                "NotebookInstanceName": {},
                "NotebookInstanceArn": {},
                "NotebookInstanceStatus": {},
                "Url": {},
                "InstanceType": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "NotebookInstanceLifecycleConfigName": {},
                "DefaultCodeRepository": {},
                "AdditionalCodeRepositories": {
                  "shape": "Sp1"
                }
              }
            }
          }
        }
      }
    },
    "ListPipelineExecutionSteps": {
      "input": {
        "type": "structure",
        "members": {
          "PipelineExecutionArn": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "SortOrder": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PipelineExecutionSteps": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "StepName": {},
                "StepDisplayName": {},
                "StepDescription": {},
                "StartTime": {
                  "type": "timestamp"
                },
                "EndTime": {
                  "type": "timestamp"
                },
                "StepStatus": {},
                "CacheHitResult": {
                  "type": "structure",
                  "members": {
                    "SourcePipelineExecutionArn": {}
                  }
                },
                "FailureReason": {},
                "Metadata": {
                  "type": "structure",
                  "members": {
                    "TrainingJob": {
                      "type": "structure",
                      "members": {
                        "Arn": {}
                      }
                    },
                    "ProcessingJob": {
                      "type": "structure",
                      "members": {
                        "Arn": {}
                      }
                    },
                    "TransformJob": {
                      "type": "structure",
                      "members": {
                        "Arn": {}
                      }
                    },
                    "TuningJob": {
                      "type": "structure",
                      "members": {
                        "Arn": {}
                      }
                    },
                    "Model": {
                      "type": "structure",
                      "members": {
                        "Arn": {}
                      }
                    },
                    "RegisterModel": {
                      "type": "structure",
                      "members": {
                        "Arn": {}
                      }
                    },
                    "Condition": {
                      "type": "structure",
                      "members": {
                        "Outcome": {}
                      }
                    },
                    "Callback": {
                      "type": "structure",
                      "members": {
                        "CallbackToken": {},
                        "SqsQueueUrl": {},
                        "OutputParameters": {
                          "shape": "S1hi"
                        }
                      }
                    },
                    "Lambda": {
                      "type": "structure",
                      "members": {
                        "Arn": {},
                        "OutputParameters": {
                          "shape": "S1hi"
                        }
                      }
                    },
                    "EMR": {
                      "type": "structure",
                      "members": {
                        "ClusterId": {},
                        "StepId": {},
                        "StepName": {},
                        "LogFilePath": {}
                      }
                    },
                    "QualityCheck": {
                      "type": "structure",
                      "members": {
                        "CheckType": {},
                        "BaselineUsedForDriftCheckStatistics": {},
                        "BaselineUsedForDriftCheckConstraints": {},
                        "CalculatedBaselineStatistics": {},
                        "CalculatedBaselineConstraints": {},
                        "ModelPackageGroupName": {},
                        "ViolationReport": {},
                        "CheckJobArn": {},
                        "SkipCheck": {
                          "type": "boolean"
                        },
                        "RegisterNewBaseline": {
                          "type": "boolean"
                        }
                      }
                    },
                    "ClarifyCheck": {
                      "type": "structure",
                      "members": {
                        "CheckType": {},
                        "BaselineUsedForDriftCheckConstraints": {},
                        "CalculatedBaselineConstraints": {},
                        "ModelPackageGroupName": {},
                        "ViolationReport": {},
                        "CheckJobArn": {},
                        "SkipCheck": {
                          "type": "boolean"
                        },
                        "RegisterNewBaseline": {
                          "type": "boolean"
                        }
                      }
                    },
                    "Fail": {
                      "type": "structure",
                      "members": {
                        "ErrorMessage": {}
                      }
                    },
                    "AutoMLJob": {
                      "type": "structure",
                      "members": {
                        "Arn": {}
                      }
                    }
                  }
                },
                "AttemptCount": {
                  "type": "integer"
                },
                "SelectiveExecutionResult": {
                  "type": "structure",
                  "members": {
                    "SourcePipelineExecutionArn": {}
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListPipelineExecutions": {
      "input": {
        "type": "structure",
        "required": [
          "PipelineName"
        ],
        "members": {
          "PipelineName": {},
          "CreatedAfter": {
            "type": "timestamp"
          },
          "CreatedBefore": {
            "type": "timestamp"
          },
          "SortBy": {},
          "SortOrder": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PipelineExecutionSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PipelineExecutionArn": {},
                "StartTime": {
                  "type": "timestamp"
                },
                "PipelineExecutionStatus": {},
                "PipelineExecutionDescription": {},
                "PipelineExecutionDisplayName": {},
                "PipelineExecutionFailureReason": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListPipelineParametersForExecution": {
      "input": {
        "type": "structure",
        "required": [
          "PipelineExecutionArn"
        ],
        "members": {
          "PipelineExecutionArn": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PipelineParameters": {
            "shape": "S1hz"
          },
          "NextToken": {}
        }
      }
    },
    "ListPipelines": {
      "input": {
        "type": "structure",
        "members": {
          "PipelineNamePrefix": {},
          "CreatedAfter": {
            "type": "timestamp"
          },
          "CreatedBefore": {
            "type": "timestamp"
          },
          "SortBy": {},
          "SortOrder": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PipelineSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PipelineArn": {},
                "PipelineName": {},
                "PipelineDisplayName": {},
                "PipelineDescription": {},
                "RoleArn": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "LastExecutionTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListProcessingJobs": {
      "input": {
        "type": "structure",
        "members": {
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "LastModifiedTimeAfter": {
            "type": "timestamp"
          },
          "LastModifiedTimeBefore": {
            "type": "timestamp"
          },
          "NameContains": {},
          "StatusEquals": {},
          "SortBy": {},
          "SortOrder": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ProcessingJobSummaries"
        ],
        "members": {
          "ProcessingJobSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ProcessingJobName",
                "ProcessingJobArn",
                "CreationTime",
                "ProcessingJobStatus"
              ],
              "members": {
                "ProcessingJobName": {},
                "ProcessingJobArn": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "ProcessingEndTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "ProcessingJobStatus": {},
                "FailureReason": {},
                "ExitMessage": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListProjects": {
      "input": {
        "type": "structure",
        "members": {
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NameContains": {},
          "NextToken": {},
          "SortBy": {},
          "SortOrder": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ProjectSummaryList"
        ],
        "members": {
          "ProjectSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ProjectName",
                "ProjectArn",
                "ProjectId",
                "CreationTime",
                "ProjectStatus"
              ],
              "members": {
                "ProjectName": {},
                "ProjectDescription": {},
                "ProjectArn": {},
                "ProjectId": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "ProjectStatus": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListResourceCatalogs": {
      "input": {
        "type": "structure",
        "members": {
          "NameContains": {},
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "SortOrder": {},
          "SortBy": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceCatalogs": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ResourceCatalogArn",
                "ResourceCatalogName",
                "Description",
                "CreationTime"
              ],
              "members": {
                "ResourceCatalogArn": {},
                "ResourceCatalogName": {},
                "Description": {},
                "CreationTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListSpaces": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "SortOrder": {},
          "SortBy": {},
          "DomainIdEquals": {},
          "SpaceNameContains": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Spaces": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DomainId": {},
                "SpaceName": {},
                "Status": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "SpaceSettingsSummary": {
                  "type": "structure",
                  "members": {
                    "AppType": {},
                    "SpaceStorageSettings": {
                      "shape": "Srh"
                    }
                  }
                },
                "SpaceSharingSettingsSummary": {
                  "type": "structure",
                  "members": {
                    "SharingType": {}
                  }
                },
                "OwnershipSettingsSummary": {
                  "type": "structure",
                  "members": {
                    "OwnerUserProfileName": {}
                  }
                },
                "SpaceDisplayName": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListStageDevices": {
      "input": {
        "type": "structure",
        "required": [
          "EdgeDeploymentPlanName",
          "StageName"
        ],
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "EdgeDeploymentPlanName": {},
          "ExcludeDevicesDeployedInOtherStage": {
            "type": "boolean"
          },
          "StageName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DeviceDeploymentSummaries"
        ],
        "members": {
          "DeviceDeploymentSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "EdgeDeploymentPlanArn",
                "EdgeDeploymentPlanName",
                "StageName",
                "DeviceName",
                "DeviceArn"
              ],
              "members": {
                "EdgeDeploymentPlanArn": {},
                "EdgeDeploymentPlanName": {},
                "StageName": {},
                "DeployedStageName": {},
                "DeviceFleetName": {},
                "DeviceName": {},
                "DeviceArn": {},
                "DeviceDeploymentStatus": {},
                "DeviceDeploymentStatusMessage": {},
                "Description": {},
                "DeploymentStartTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListStudioLifecycleConfigs": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "NameContains": {},
          "AppTypeEquals": {},
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "ModifiedTimeBefore": {
            "type": "timestamp"
          },
          "ModifiedTimeAfter": {
            "type": "timestamp"
          },
          "SortBy": {},
          "SortOrder": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "StudioLifecycleConfigs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "StudioLifecycleConfigArn": {},
                "StudioLifecycleConfigName": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "StudioLifecycleConfigAppType": {}
              }
            }
          }
        }
      }
    },
    "ListSubscribedWorkteams": {
      "input": {
        "type": "structure",
        "members": {
          "NameContains": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "SubscribedWorkteams"
        ],
        "members": {
          "SubscribedWorkteams": {
            "type": "list",
            "member": {
              "shape": "S15w"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S7"
          },
          "NextToken": {}
        }
      }
    },
    "ListTrainingJobs": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "LastModifiedTimeAfter": {
            "type": "timestamp"
          },
          "LastModifiedTimeBefore": {
            "type": "timestamp"
          },
          "NameContains": {},
          "StatusEquals": {},
          "SortBy": {},
          "SortOrder": {},
          "WarmPoolStatusEquals": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TrainingJobSummaries"
        ],
        "members": {
          "TrainingJobSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "TrainingJobName",
                "TrainingJobArn",
                "CreationTime",
                "TrainingJobStatus"
              ],
              "members": {
                "TrainingJobName": {},
                "TrainingJobArn": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "TrainingEndTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "TrainingJobStatus": {},
                "WarmPoolStatus": {
                  "shape": "S160"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTrainingJobsForHyperParameterTuningJob": {
      "input": {
        "type": "structure",
        "required": [
          "HyperParameterTuningJobName"
        ],
        "members": {
          "HyperParameterTuningJobName": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "StatusEquals": {},
          "SortBy": {},
          "SortOrder": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TrainingJobSummaries"
        ],
        "members": {
          "TrainingJobSummaries": {
            "type": "list",
            "member": {
              "shape": "S12c"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTransformJobs": {
      "input": {
        "type": "structure",
        "members": {
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "LastModifiedTimeAfter": {
            "type": "timestamp"
          },
          "LastModifiedTimeBefore": {
            "type": "timestamp"
          },
          "NameContains": {},
          "StatusEquals": {},
          "SortBy": {},
          "SortOrder": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TransformJobSummaries"
        ],
        "members": {
          "TransformJobSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "TransformJobName",
                "TransformJobArn",
                "CreationTime",
                "TransformJobStatus"
              ],
              "members": {
                "TransformJobName": {},
                "TransformJobArn": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "TransformEndTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "TransformJobStatus": {},
                "FailureReason": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTrialComponents": {
      "input": {
        "type": "structure",
        "members": {
          "ExperimentName": {},
          "TrialName": {},
          "SourceArn": {},
          "CreatedAfter": {
            "type": "timestamp"
          },
          "CreatedBefore": {
            "type": "timestamp"
          },
          "SortBy": {},
          "SortOrder": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrialComponentSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TrialComponentName": {},
                "TrialComponentArn": {},
                "DisplayName": {},
                "TrialComponentSource": {
                  "shape": "S16q"
                },
                "Status": {
                  "shape": "Std"
                },
                "StartTime": {
                  "type": "timestamp"
                },
                "EndTime": {
                  "type": "timestamp"
                },
                "CreationTime": {
                  "type": "timestamp"
                },
                "CreatedBy": {
                  "shape": "Sww"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "LastModifiedBy": {
                  "shape": "Sww"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTrials": {
      "input": {
        "type": "structure",
        "members": {
          "ExperimentName": {},
          "TrialComponentName": {},
          "CreatedAfter": {
            "type": "timestamp"
          },
          "CreatedBefore": {
            "type": "timestamp"
          },
          "SortBy": {},
          "SortOrder": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrialSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TrialArn": {},
                "TrialName": {},
                "DisplayName": {},
                "TrialSource": {
                  "shape": "S16m"
                },
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListUserProfiles": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "SortOrder": {},
          "SortBy": {},
          "DomainIdEquals": {},
          "UserProfileNameContains": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserProfiles": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DomainId": {},
                "UserProfileName": {},
                "Status": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListWorkforces": {
      "input": {
        "type": "structure",
        "members": {
          "SortBy": {},
          "SortOrder": {},
          "NameContains": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Workforces"
        ],
        "members": {
          "Workforces": {
            "type": "list",
            "member": {
              "shape": "S172"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListWorkteams": {
      "input": {
        "type": "structure",
        "members": {
          "SortBy": {},
          "SortOrder": {},
          "NameContains": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Workteams"
        ],
        "members": {
          "Workteams": {
            "type": "list",
            "member": {
              "shape": "S17a"
            }
          },
          "NextToken": {}
        }
      }
    },
    "PutModelPackageGroupPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "ModelPackageGroupName",
          "ResourcePolicy"
        ],
        "members": {
          "ModelPackageGroupName": {},
          "ResourcePolicy": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ModelPackageGroupArn"
        ],
        "members": {
          "ModelPackageGroupArn": {}
        }
      }
    },
    "QueryLineage": {
      "input": {
        "type": "structure",
        "members": {
          "StartArns": {
            "type": "list",
            "member": {}
          },
          "Direction": {},
          "IncludeEdges": {
            "type": "boolean"
          },
          "Filters": {
            "type": "structure",
            "members": {
              "Types": {
                "type": "list",
                "member": {}
              },
              "LineageTypes": {
                "type": "list",
                "member": {}
              },
              "CreatedBefore": {
                "type": "timestamp"
              },
              "CreatedAfter": {
                "type": "timestamp"
              },
              "ModifiedBefore": {
                "type": "timestamp"
              },
              "ModifiedAfter": {
                "type": "timestamp"
              },
              "Properties": {
                "type": "map",
                "key": {},
                "value": {}
              }
            }
          },
          "MaxDepth": {
            "type": "integer"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Vertices": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "Type": {},
                "LineageType": {}
              }
            }
          },
          "Edges": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "SourceArn": {},
                "DestinationArn": {},
                "AssociationType": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "RegisterDevices": {
      "input": {
        "type": "structure",
        "required": [
          "DeviceFleetName",
          "Devices"
        ],
        "members": {
          "DeviceFleetName": {},
          "Devices": {
            "shape": "S1kx"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      }
    },
    "RenderUiTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "Task",
          "RoleArn"
        ],
        "members": {
          "UiTemplate": {
            "shape": "Sgz"
          },
          "Task": {
            "type": "structure",
            "required": [
              "Input"
            ],
            "members": {
              "Input": {}
            }
          },
          "RoleArn": {},
          "HumanTaskUiArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "RenderedContent",
          "Errors"
        ],
        "members": {
          "RenderedContent": {},
          "Errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Code",
                "Message"
              ],
              "members": {
                "Code": {},
                "Message": {}
              }
            }
          }
        }
      }
    },
    "RetryPipelineExecution": {
      "input": {
        "type": "structure",
        "required": [
          "PipelineExecutionArn",
          "ClientRequestToken"
        ],
        "members": {
          "PipelineExecutionArn": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "ParallelismConfiguration": {
            "shape": "Spn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PipelineExecutionArn": {}
        }
      }
    },
    "Search": {
      "input": {
        "type": "structure",
        "required": [
          "Resource"
        ],
        "members": {
          "Resource": {},
          "SearchExpression": {
            "shape": "S1l8"
          },
          "SortBy": {},
          "SortOrder": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "CrossAccountFilterOption": {},
          "VisibilityConditions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Key": {},
                "Value": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Results": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TrainingJob": {
                  "shape": "S1lq"
                },
                "Experiment": {
                  "type": "structure",
                  "members": {
                    "ExperimentName": {},
                    "ExperimentArn": {},
                    "DisplayName": {},
                    "Source": {
                      "shape": "S10u"
                    },
                    "Description": {},
                    "CreationTime": {
                      "type": "timestamp"
                    },
                    "CreatedBy": {
                      "shape": "Sww"
                    },
                    "LastModifiedTime": {
                      "type": "timestamp"
                    },
                    "LastModifiedBy": {
                      "shape": "Sww"
                    },
                    "Tags": {
                      "shape": "S7"
                    }
                  }
                },
                "Trial": {
                  "type": "structure",
                  "members": {
                    "TrialName": {},
                    "TrialArn": {},
                    "DisplayName": {},
                    "ExperimentName": {},
                    "Source": {
                      "shape": "S16m"
                    },
                    "CreationTime": {
                      "type": "timestamp"
                    },
                    "CreatedBy": {
                      "shape": "Sww"
                    },
                    "LastModifiedTime": {
                      "type": "timestamp"
                    },
                    "LastModifiedBy": {
                      "shape": "Sww"
                    },
                    "MetadataProperties": {
                      "shape": "S21"
                    },
                    "Tags": {
                      "shape": "S7"
                    },
                    "TrialComponentSummaries": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "members": {
                          "TrialComponentName": {},
                          "TrialComponentArn": {},
                          "TrialComponentSource": {
                            "shape": "S16q"
                          },
                          "CreationTime": {
                            "type": "timestamp"
                          },
                          "CreatedBy": {
                            "shape": "Sww"
                          }
                        }
                      }
                    }
                  }
                },
                "TrialComponent": {
                  "type": "structure",
                  "members": {
                    "TrialComponentName": {},
                    "DisplayName": {},
                    "TrialComponentArn": {},
                    "Source": {
                      "shape": "S16q"
                    },
                    "Status": {
                      "shape": "Std"
                    },
                    "StartTime": {
                      "type": "timestamp"
                    },
                    "EndTime": {
                      "type": "timestamp"
                    },
                    "CreationTime": {
                      "type": "timestamp"
                    },
                    "CreatedBy": {
                      "shape": "Sww"
                    },
                    "LastModifiedTime": {
                      "type": "timestamp"
                    },
                    "LastModifiedBy": {
                      "shape": "Sww"
                    },
                    "Parameters": {
                      "shape": "Stg"
                    },
                    "InputArtifacts": {
                      "shape": "Stk"
                    },
                    "OutputArtifacts": {
                      "shape": "Stk"
                    },
                    "Metrics": {
                      "shape": "S16s"
                    },
                    "MetadataProperties": {
                      "shape": "S21"
                    },
                    "SourceDetail": {
                      "type": "structure",
                      "members": {
                        "SourceArn": {},
                        "TrainingJob": {
                          "shape": "S1lq"
                        },
                        "ProcessingJob": {
                          "type": "structure",
                          "members": {
                            "ProcessingInputs": {
                              "shape": "Sq0"
                            },
                            "ProcessingOutputConfig": {
                              "shape": "Sqn"
                            },
                            "ProcessingJobName": {},
                            "ProcessingResources": {
                              "shape": "Sqs"
                            },
                            "StoppingCondition": {
                              "shape": "Squ"
                            },
                            "AppSpecification": {
                              "shape": "Sqw"
                            },
                            "Environment": {
                              "shape": "Sqy"
                            },
                            "NetworkConfig": {
                              "shape": "Sop"
                            },
                            "RoleArn": {},
                            "ExperimentConfig": {
                              "shape": "Sqz"
                            },
                            "ProcessingJobArn": {},
                            "ProcessingJobStatus": {},
                            "ExitMessage": {},
                            "FailureReason": {},
                            "ProcessingEndTime": {
                              "type": "timestamp"
                            },
                            "ProcessingStartTime": {
                              "type": "timestamp"
                            },
                            "LastModifiedTime": {
                              "type": "timestamp"
                            },
                            "CreationTime": {
                              "type": "timestamp"
                            },
                            "MonitoringScheduleArn": {},
                            "AutoMLJobArn": {},
                            "TrainingJobArn": {},
                            "Tags": {
                              "shape": "S7"
                            }
                          }
                        },
                        "TransformJob": {
                          "shape": "S1ly"
                        }
                      }
                    },
                    "LineageGroupArn": {},
                    "Tags": {
                      "shape": "S7"
                    },
                    "Parents": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "members": {
                          "TrialName": {},
                          "ExperimentName": {}
                        }
                      }
                    },
                    "RunName": {}
                  }
                },
                "Endpoint": {
                  "type": "structure",
                  "required": [
                    "EndpointName",
                    "EndpointArn",
                    "EndpointConfigName",
                    "EndpointStatus",
                    "CreationTime",
                    "LastModifiedTime"
                  ],
                  "members": {
                    "EndpointName": {},
                    "EndpointArn": {},
                    "EndpointConfigName": {},
                    "ProductionVariants": {
                      "shape": "S10c"
                    },
                    "DataCaptureConfig": {
                      "shape": "S10k"
                    },
                    "EndpointStatus": {},
                    "FailureReason": {},
                    "CreationTime": {
                      "type": "timestamp"
                    },
                    "LastModifiedTime": {
                      "type": "timestamp"
                    },
                    "MonitoringSchedules": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "members": {
                          "MonitoringScheduleArn": {},
                          "MonitoringScheduleName": {},
                          "MonitoringScheduleStatus": {},
                          "MonitoringType": {},
                          "FailureReason": {},
                          "CreationTime": {
                            "type": "timestamp"
                          },
                          "LastModifiedTime": {
                            "type": "timestamp"
                          },
                          "MonitoringScheduleConfig": {
                            "shape": "Soh"
                          },
                          "EndpointName": {},
                          "LastMonitoringExecutionSummary": {
                            "shape": "S14q"
                          },
                          "Tags": {
                            "shape": "S7"
                          }
                        }
                      }
                    },
                    "Tags": {
                      "shape": "S7"
                    },
                    "ShadowProductionVariants": {
                      "shape": "S10c"
                    }
                  }
                },
                "ModelPackage": {
                  "type": "structure",
                  "members": {
                    "ModelPackageName": {},
                    "ModelPackageGroupName": {},
                    "ModelPackageVersion": {
                      "type": "integer"
                    },
                    "ModelPackageArn": {},
                    "ModelPackageDescription": {},
                    "CreationTime": {
                      "type": "timestamp"
                    },
                    "InferenceSpecification": {
                      "shape": "Sr"
                    },
                    "SourceAlgorithmSpecification": {
                      "shape": "Sni"
                    },
                    "ValidationSpecification": {
                      "shape": "Snf"
                    },
                    "ModelPackageStatus": {},
                    "ModelPackageStatusDetails": {
                      "shape": "S14d"
                    },
                    "CertifyForMarketplace": {
                      "type": "boolean"
                    },
                    "ModelApprovalStatus": {},
                    "CreatedBy": {
                      "shape": "Sww"
                    },
                    "MetadataProperties": {
                      "shape": "S21"
                    },
                    "ModelMetrics": {
                      "shape": "Snl"
                    },
                    "LastModifiedTime": {
                      "type": "timestamp"
                    },
                    "LastModifiedBy": {
                      "shape": "Sww"
                    },
                    "ApprovalDescription": {},
                    "Domain": {},
                    "Task": {},
                    "SamplePayloadUrl": {},
                    "AdditionalInferenceSpecifications": {
                      "shape": "So1"
                    },
                    "SourceUri": {},
                    "Tags": {
                      "shape": "S7"
                    },
                    "CustomerMetadataProperties": {
                      "shape": "Sns"
                    },
                    "DriftCheckBaselines": {
                      "shape": "Snv"
                    },
                    "SkipModelValidation": {}
                  }
                },
                "ModelPackageGroup": {
                  "type": "structure",
                  "members": {
                    "ModelPackageGroupName": {},
                    "ModelPackageGroupArn": {},
                    "ModelPackageGroupDescription": {},
                    "CreationTime": {
                      "type": "timestamp"
                    },
                    "CreatedBy": {
                      "shape": "Sww"
                    },
                    "ModelPackageGroupStatus": {},
                    "Tags": {
                      "shape": "S7"
                    }
                  }
                },
                "Pipeline": {
                  "type": "structure",
                  "members": {
                    "PipelineArn": {},
                    "PipelineName": {},
                    "PipelineDisplayName": {},
                    "PipelineDescription": {},
                    "RoleArn": {},
                    "PipelineStatus": {},
                    "CreationTime": {
                      "type": "timestamp"
                    },
                    "LastModifiedTime": {
                      "type": "timestamp"
                    },
                    "LastRunTime": {
                      "type": "timestamp"
                    },
                    "CreatedBy": {
                      "shape": "Sww"
                    },
                    "LastModifiedBy": {
                      "shape": "Sww"
                    },
                    "ParallelismConfiguration": {
                      "shape": "Spn"
                    },
                    "Tags": {
                      "shape": "S7"
                    }
                  }
                },
                "PipelineExecution": {
                  "type": "structure",
                  "members": {
                    "PipelineArn": {},
                    "PipelineExecutionArn": {},
                    "PipelineExecutionDisplayName": {},
                    "PipelineExecutionStatus": {},
                    "PipelineExecutionDescription": {},
                    "PipelineExperimentConfig": {
                      "shape": "S15a"
                    },
                    "FailureReason": {},
                    "CreationTime": {
                      "type": "timestamp"
                    },
                    "LastModifiedTime": {
                      "type": "timestamp"
                    },
                    "CreatedBy": {
                      "shape": "Sww"
                    },
                    "LastModifiedBy": {
                      "shape": "Sww"
                    },
                    "ParallelismConfiguration": {
                      "shape": "Spn"
                    },
                    "SelectiveExecutionConfig": {
                      "shape": "S15c"
                    },
                    "PipelineParameters": {
                      "shape": "S1hz"
                    }
                  }
                },
                "FeatureGroup": {
                  "type": "structure",
                  "members": {
                    "FeatureGroupArn": {},
                    "FeatureGroupName": {},
                    "RecordIdentifierFeatureName": {},
                    "EventTimeFeatureName": {},
                    "FeatureDefinitions": {
                      "shape": "Sf8"
                    },
                    "CreationTime": {
                      "type": "timestamp"
                    },
                    "LastModifiedTime": {
                      "type": "timestamp"
                    },
                    "OnlineStoreConfig": {
                      "shape": "Sff"
                    },
                    "OfflineStoreConfig": {
                      "shape": "Sfl"
                    },
                    "RoleArn": {},
                    "FeatureGroupStatus": {},
                    "OfflineStoreStatus": {
                      "shape": "S112"
                    },
                    "LastUpdateStatus": {
                      "shape": "S115"
                    },
                    "FailureReason": {},
                    "Description": {},
                    "Tags": {
                      "shape": "S7"
                    }
                  }
                },
                "FeatureMetadata": {
                  "type": "structure",
                  "members": {
                    "FeatureGroupArn": {},
                    "FeatureGroupName": {},
                    "FeatureName": {},
                    "FeatureType": {},
                    "CreationTime": {
                      "type": "timestamp"
                    },
                    "LastModifiedTime": {
                      "type": "timestamp"
                    },
                    "Description": {},
                    "Parameters": {
                      "shape": "S11b"
                    }
                  }
                },
                "Project": {
                  "type": "structure",
                  "members": {
                    "ProjectArn": {},
                    "ProjectName": {},
                    "ProjectId": {},
                    "ProjectDescription": {},
                    "ServiceCatalogProvisioningDetails": {
                      "shape": "Sr4"
                    },
                    "ServiceCatalogProvisionedProductDetails": {
                      "shape": "S15l"
                    },
                    "ProjectStatus": {},
                    "CreatedBy": {
                      "shape": "Sww"
                    },
                    "CreationTime": {
                      "type": "timestamp"
                    },
                    "Tags": {
                      "shape": "S7"
                    },
                    "LastModifiedTime": {
                      "type": "timestamp"
                    },
                    "LastModifiedBy": {
                      "shape": "Sww"
                    }
                  }
                },
                "HyperParameterTuningJob": {
                  "type": "structure",
                  "members": {
                    "HyperParameterTuningJobName": {},
                    "HyperParameterTuningJobArn": {},
                    "HyperParameterTuningJobConfig": {
                      "shape": "Sh4"
                    },
                    "TrainingJobDefinition": {
                      "shape": "Shx"
                    },
                    "TrainingJobDefinitions": {
                      "shape": "Sid"
                    },
                    "HyperParameterTuningJobStatus": {},
                    "CreationTime": {
                      "type": "timestamp"
                    },
                    "HyperParameterTuningEndTime": {
                      "type": "timestamp"
                    },
                    "LastModifiedTime": {
                      "type": "timestamp"
                    },
                    "TrainingJobStatusCounters": {
                      "shape": "S128"
                    },
                    "ObjectiveStatusCounters": {
                      "shape": "S12a"
                    },
                    "BestTrainingJob": {
                      "shape": "S12c"
                    },
                    "OverallBestTrainingJob": {
                      "shape": "S12c"
                    },
                    "WarmStartConfig": {
                      "shape": "Sie"
                    },
                    "FailureReason": {},
                    "TuningJobCompletionDetails": {
                      "shape": "S12f"
                    },
                    "ConsumedResources": {
                      "shape": "S12g"
                    },
                    "Tags": {
                      "shape": "S7"
                    }
                  }
                },
                "ModelCard": {
                  "type": "structure",
                  "members": {
                    "ModelCardArn": {},
                    "ModelCardName": {},
                    "ModelCardVersion": {
                      "type": "integer"
                    },
                    "Content": {
                      "shape": "Sn0"
                    },
                    "ModelCardStatus": {},
                    "SecurityConfig": {
                      "shape": "Smz"
                    },
                    "CreationTime": {
                      "type": "timestamp"
                    },
                    "CreatedBy": {
                      "shape": "Sww"
                    },
                    "LastModifiedTime": {
                      "type": "timestamp"
                    },
                    "LastModifiedBy": {
                      "shape": "Sww"
                    },
                    "Tags": {
                      "shape": "S7"
                    },
                    "ModelId": {},
                    "RiskRating": {},
                    "ModelPackageGroupName": {}
                  }
                },
                "Model": {
                  "type": "structure",
                  "members": {
                    "Model": {
                      "type": "structure",
                      "members": {
                        "ModelName": {},
                        "PrimaryContainer": {
                          "shape": "Smf"
                        },
                        "Containers": {
                          "shape": "Smo"
                        },
                        "InferenceExecutionConfig": {
                          "shape": "Smp"
                        },
                        "ExecutionRoleArn": {},
                        "VpcConfig": {
                          "shape": "S6a"
                        },
                        "CreationTime": {
                          "type": "timestamp"
                        },
                        "ModelArn": {},
                        "EnableNetworkIsolation": {
                          "type": "boolean"
                        },
                        "Tags": {
                          "shape": "S7"
                        },
                        "DeploymentRecommendation": {
                          "shape": "S13w"
                        }
                      }
                    },
                    "Endpoints": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "required": [
                          "EndpointName",
                          "EndpointArn",
                          "CreationTime",
                          "LastModifiedTime",
                          "EndpointStatus"
                        ],
                        "members": {
                          "EndpointName": {},
                          "EndpointArn": {},
                          "CreationTime": {
                            "type": "timestamp"
                          },
                          "LastModifiedTime": {
                            "type": "timestamp"
                          },
                          "EndpointStatus": {}
                        }
                      }
                    },
                    "LastBatchTransformJob": {
                      "shape": "S1ly"
                    },
                    "MonitoringSchedules": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "members": {
                          "MonitoringScheduleArn": {},
                          "MonitoringScheduleName": {},
                          "MonitoringScheduleStatus": {},
                          "MonitoringType": {},
                          "FailureReason": {},
                          "CreationTime": {
                            "type": "timestamp"
                          },
                          "LastModifiedTime": {
                            "type": "timestamp"
                          },
                          "MonitoringScheduleConfig": {
                            "shape": "Soh"
                          },
                          "EndpointName": {},
                          "MonitoringAlertSummaries": {
                            "shape": "S1g4"
                          },
                          "LastMonitoringExecutionSummary": {
                            "shape": "S14q"
                          },
                          "BatchTransformInput": {
                            "shape": "S9n"
                          }
                        }
                      }
                    },
                    "ModelCard": {
                      "type": "structure",
                      "members": {
                        "ModelCardArn": {},
                        "ModelCardName": {},
                        "ModelCardVersion": {
                          "type": "integer"
                        },
                        "ModelCardStatus": {},
                        "SecurityConfig": {
                          "shape": "Smz"
                        },
                        "CreationTime": {
                          "type": "timestamp"
                        },
                        "CreatedBy": {
                          "shape": "Sww"
                        },
                        "LastModifiedTime": {
                          "type": "timestamp"
                        },
                        "LastModifiedBy": {
                          "shape": "Sww"
                        },
                        "Tags": {
                          "shape": "S7"
                        },
                        "ModelId": {},
                        "RiskRating": {}
                      }
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "SendPipelineExecutionStepFailure": {
      "input": {
        "type": "structure",
        "required": [
          "CallbackToken"
        ],
        "members": {
          "CallbackToken": {},
          "FailureReason": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PipelineExecutionArn": {}
        }
      }
    },
    "SendPipelineExecutionStepSuccess": {
      "input": {
        "type": "structure",
        "required": [
          "CallbackToken"
        ],
        "members": {
          "CallbackToken": {},
          "OutputParameters": {
            "shape": "S1hi"
          },
          "ClientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PipelineExecutionArn": {}
        }
      }
    },
    "StartEdgeDeploymentStage": {
      "input": {
        "type": "structure",
        "required": [
          "EdgeDeploymentPlanName",
          "StageName"
        ],
        "members": {
          "EdgeDeploymentPlanName": {},
          "StageName": {}
        }
      }
    },
    "StartInferenceExperiment": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "InferenceExperimentArn"
        ],
        "members": {
          "InferenceExperimentArn": {}
        }
      }
    },
    "StartMonitoringSchedule": {
      "input": {
        "type": "structure",
        "required": [
          "MonitoringScheduleName"
        ],
        "members": {
          "MonitoringScheduleName": {}
        }
      }
    },
    "StartNotebookInstance": {
      "input": {
        "type": "structure",
        "required": [
          "NotebookInstanceName"
        ],
        "members": {
          "NotebookInstanceName": {}
        }
      }
    },
    "StartPipelineExecution": {
      "input": {
        "type": "structure",
        "required": [
          "PipelineName",
          "ClientRequestToken"
        ],
        "members": {
          "PipelineName": {},
          "PipelineExecutionDisplayName": {},
          "PipelineParameters": {
            "shape": "S1hz"
          },
          "PipelineExecutionDescription": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "ParallelismConfiguration": {
            "shape": "Spn"
          },
          "SelectiveExecutionConfig": {
            "shape": "S15c"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PipelineExecutionArn": {}
        }
      }
    },
    "StopAutoMLJob": {
      "input": {
        "type": "structure",
        "required": [
          "AutoMLJobName"
        ],
        "members": {
          "AutoMLJobName": {}
        }
      }
    },
    "StopCompilationJob": {
      "input": {
        "type": "structure",
        "required": [
          "CompilationJobName"
        ],
        "members": {
          "CompilationJobName": {}
        }
      }
    },
    "StopEdgeDeploymentStage": {
      "input": {
        "type": "structure",
        "required": [
          "EdgeDeploymentPlanName",
          "StageName"
        ],
        "members": {
          "EdgeDeploymentPlanName": {},
          "StageName": {}
        }
      }
    },
    "StopEdgePackagingJob": {
      "input": {
        "type": "structure",
        "required": [
          "EdgePackagingJobName"
        ],
        "members": {
          "EdgePackagingJobName": {}
        }
      }
    },
    "StopHyperParameterTuningJob": {
      "input": {
        "type": "structure",
        "required": [
          "HyperParameterTuningJobName"
        ],
        "members": {
          "HyperParameterTuningJobName": {}
        }
      }
    },
    "StopInferenceExperiment": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "ModelVariantActions"
        ],
        "members": {
          "Name": {},
          "ModelVariantActions": {
            "type": "map",
            "key": {},
            "value": {}
          },
          "DesiredModelVariants": {
            "shape": "Sjm"
          },
          "DesiredState": {},
          "Reason": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "InferenceExperimentArn"
        ],
        "members": {
          "InferenceExperimentArn": {}
        }
      }
    },
    "StopInferenceRecommendationsJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobName"
        ],
        "members": {
          "JobName": {}
        }
      }
    },
    "StopLabelingJob": {
      "input": {
        "type": "structure",
        "required": [
          "LabelingJobName"
        ],
        "members": {
          "LabelingJobName": {}
        }
      }
    },
    "StopMonitoringSchedule": {
      "input": {
        "type": "structure",
        "required": [
          "MonitoringScheduleName"
        ],
        "members": {
          "MonitoringScheduleName": {}
        }
      }
    },
    "StopNotebookInstance": {
      "input": {
        "type": "structure",
        "required": [
          "NotebookInstanceName"
        ],
        "members": {
          "NotebookInstanceName": {}
        }
      }
    },
    "StopPipelineExecution": {
      "input": {
        "type": "structure",
        "required": [
          "PipelineExecutionArn",
          "ClientRequestToken"
        ],
        "members": {
          "PipelineExecutionArn": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PipelineExecutionArn": {}
        }
      }
    },
    "StopProcessingJob": {
      "input": {
        "type": "structure",
        "required": [
          "ProcessingJobName"
        ],
        "members": {
          "ProcessingJobName": {}
        }
      }
    },
    "StopTrainingJob": {
      "input": {
        "type": "structure",
        "required": [
          "TrainingJobName"
        ],
        "members": {
          "TrainingJobName": {}
        }
      }
    },
    "StopTransformJob": {
      "input": {
        "type": "structure",
        "required": [
          "TransformJobName"
        ],
        "members": {
          "TransformJobName": {}
        }
      }
    },
    "UpdateAction": {
      "input": {
        "type": "structure",
        "required": [
          "ActionName"
        ],
        "members": {
          "ActionName": {},
          "Description": {},
          "Status": {},
          "Properties": {
            "shape": "S1z"
          },
          "PropertiesToRemove": {
            "shape": "S1nf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ActionArn": {}
        }
      }
    },
    "UpdateAppImageConfig": {
      "input": {
        "type": "structure",
        "required": [
          "AppImageConfigName"
        ],
        "members": {
          "AppImageConfigName": {},
          "KernelGatewayImageConfig": {
            "shape": "S4z"
          },
          "JupyterLabAppImageConfig": {
            "shape": "S58"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AppImageConfigArn": {}
        }
      }
    },
    "UpdateArtifact": {
      "input": {
        "type": "structure",
        "required": [
          "ArtifactArn"
        ],
        "members": {
          "ArtifactArn": {},
          "ArtifactName": {},
          "Properties": {
            "shape": "S5m"
          },
          "PropertiesToRemove": {
            "shape": "S1nf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ArtifactArn": {}
        }
      }
    },
    "UpdateCluster": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterName",
          "InstanceGroups"
        ],
        "members": {
          "ClusterName": {},
          "InstanceGroups": {
            "shape": "S7w"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ClusterArn"
        ],
        "members": {
          "ClusterArn": {}
        }
      }
    },
    "UpdateClusterSoftware": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterName"
        ],
        "members": {
          "ClusterName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ClusterArn"
        ],
        "members": {
          "ClusterArn": {}
        }
      }
    },
    "UpdateCodeRepository": {
      "input": {
        "type": "structure",
        "required": [
          "CodeRepositoryName"
        ],
        "members": {
          "CodeRepositoryName": {},
          "GitConfig": {
            "type": "structure",
            "members": {
              "SecretArn": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CodeRepositoryArn"
        ],
        "members": {
          "CodeRepositoryArn": {}
        }
      }
    },
    "UpdateContext": {
      "input": {
        "type": "structure",
        "required": [
          "ContextName"
        ],
        "members": {
          "ContextName": {},
          "Description": {},
          "Properties": {
            "shape": "S1z"
          },
          "PropertiesToRemove": {
            "shape": "S1nf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ContextArn": {}
        }
      }
    },
    "UpdateDeviceFleet": {
      "input": {
        "type": "structure",
        "required": [
          "DeviceFleetName",
          "OutputConfig"
        ],
        "members": {
          "DeviceFleetName": {},
          "RoleArn": {},
          "Description": {},
          "OutputConfig": {
            "shape": "Sab"
          },
          "EnableIotRoleAlias": {
            "type": "boolean"
          }
        }
      }
    },
    "UpdateDevices": {
      "input": {
        "type": "structure",
        "required": [
          "DeviceFleetName",
          "Devices"
        ],
        "members": {
          "DeviceFleetName": {},
          "Devices": {
            "shape": "S1kx"
          }
        }
      }
    },
    "UpdateDomain": {
      "input": {
        "type": "structure",
        "required": [
          "DomainId"
        ],
        "members": {
          "DomainId": {},
          "DefaultUserSettings": {
            "shape": "Sah"
          },
          "DomainSettingsForUpdate": {
            "type": "structure",
            "members": {
              "RStudioServerProDomainSettingsForUpdate": {
                "type": "structure",
                "required": [
                  "DomainExecutionRoleArn"
                ],
                "members": {
                  "DomainExecutionRoleArn": {},
                  "DefaultResourceSpec": {
                    "shape": "S4p"
                  },
                  "RStudioConnectUrl": {},
                  "RStudioPackageManagerUrl": {}
                }
              },
              "ExecutionRoleIdentityConfig": {},
              "SecurityGroupIds": {
                "shape": "Sbq"
              },
              "DockerSettings": {
                "shape": "Sbt"
              }
            }
          },
          "AppSecurityGroupManagement": {},
          "DefaultSpaceSettings": {
            "shape": "Sbz"
          },
          "SubnetIds": {
            "shape": "S6d"
          },
          "AppNetworkAccessType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DomainArn": {}
        }
      }
    },
    "UpdateEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointName",
          "EndpointConfigName"
        ],
        "members": {
          "EndpointName": {},
          "EndpointConfigName": {},
          "RetainAllVariantProperties": {
            "type": "boolean"
          },
          "ExcludeRetainedVariantProperties": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "VariantPropertyType"
              ],
              "members": {
                "VariantPropertyType": {}
              }
            }
          },
          "DeploymentConfig": {
            "shape": "Scm"
          },
          "RetainDeploymentConfig": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "EndpointArn"
        ],
        "members": {
          "EndpointArn": {}
        }
      }
    },
    "UpdateEndpointWeightsAndCapacities": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointName",
          "DesiredWeightsAndCapacities"
        ],
        "members": {
          "EndpointName": {},
          "DesiredWeightsAndCapacities": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "VariantName"
              ],
              "members": {
                "VariantName": {},
                "DesiredWeight": {
                  "type": "float"
                },
                "DesiredInstanceCount": {
                  "type": "integer"
                },
                "ServerlessUpdateConfig": {
                  "type": "structure",
                  "members": {
                    "MaxConcurrency": {
                      "type": "integer"
                    },
                    "ProvisionedConcurrency": {
                      "type": "integer"
                    }
                  }
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "EndpointArn"
        ],
        "members": {
          "EndpointArn": {}
        }
      }
    },
    "UpdateExperiment": {
      "input": {
        "type": "structure",
        "required": [
          "ExperimentName"
        ],
        "members": {
          "ExperimentName": {},
          "DisplayName": {},
          "Description": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ExperimentArn": {}
        }
      }
    },
    "UpdateFeatureGroup": {
      "input": {
        "type": "structure",
        "required": [
          "FeatureGroupName"
        ],
        "members": {
          "FeatureGroupName": {},
          "FeatureAdditions": {
            "type": "list",
            "member": {
              "shape": "Sf9"
            }
          },
          "OnlineStoreConfig": {
            "type": "structure",
            "members": {
              "TtlDuration": {
                "shape": "Sfh"
              }
            }
          },
          "ThroughputConfig": {
            "type": "structure",
            "members": {
              "ThroughputMode": {},
              "ProvisionedReadCapacityUnits": {
                "type": "integer"
              },
              "ProvisionedWriteCapacityUnits": {
                "type": "integer"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "FeatureGroupArn"
        ],
        "members": {
          "FeatureGroupArn": {}
        }
      }
    },
    "UpdateFeatureMetadata": {
      "input": {
        "type": "structure",
        "required": [
          "FeatureGroupName",
          "FeatureName"
        ],
        "members": {
          "FeatureGroupName": {},
          "FeatureName": {},
          "Description": {},
          "ParameterAdditions": {
            "type": "list",
            "member": {
              "shape": "S11c"
            }
          },
          "ParameterRemovals": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "UpdateHub": {
      "input": {
        "type": "structure",
        "required": [
          "HubName"
        ],
        "members": {
          "HubName": {},
          "HubDescription": {},
          "HubDisplayName": {},
          "HubSearchKeywords": {
            "shape": "Sgr"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "HubArn"
        ],
        "members": {
          "HubArn": {}
        }
      }
    },
    "UpdateImage": {
      "input": {
        "type": "structure",
        "required": [
          "ImageName"
        ],
        "members": {
          "DeleteProperties": {
            "type": "list",
            "member": {}
          },
          "Description": {},
          "DisplayName": {},
          "ImageName": {},
          "RoleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImageArn": {}
        }
      }
    },
    "UpdateImageVersion": {
      "input": {
        "type": "structure",
        "required": [
          "ImageName"
        ],
        "members": {
          "ImageName": {},
          "Alias": {},
          "Version": {
            "type": "integer"
          },
          "AliasesToAdd": {
            "shape": "Sit"
          },
          "AliasesToDelete": {
            "shape": "Sit"
          },
          "VendorGuidance": {},
          "JobType": {},
          "MLFramework": {},
          "ProgrammingLang": {},
          "Processor": {},
          "Horovod": {
            "type": "boolean"
          },
          "ReleaseNotes": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImageVersionArn": {}
        }
      }
    },
    "UpdateInferenceComponent": {
      "input": {
        "type": "structure",
        "required": [
          "InferenceComponentName"
        ],
        "members": {
          "InferenceComponentName": {},
          "Specification": {
            "shape": "Sj5"
          },
          "RuntimeConfig": {
            "shape": "Sjc"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "InferenceComponentArn"
        ],
        "members": {
          "InferenceComponentArn": {}
        }
      }
    },
    "UpdateInferenceComponentRuntimeConfig": {
      "input": {
        "type": "structure",
        "required": [
          "InferenceComponentName",
          "DesiredRuntimeConfig"
        ],
        "members": {
          "InferenceComponentName": {},
          "DesiredRuntimeConfig": {
            "shape": "Sjc"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "InferenceComponentArn"
        ],
        "members": {
          "InferenceComponentArn": {}
        }
      }
    },
    "UpdateInferenceExperiment": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "Schedule": {
            "shape": "Sjj"
          },
          "Description": {},
          "ModelVariants": {
            "shape": "Sjm"
          },
          "DataStorageConfig": {
            "shape": "Sju"
          },
          "ShadowModeConfig": {
            "shape": "Sjv"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "InferenceExperimentArn"
        ],
        "members": {
          "InferenceExperimentArn": {}
        }
      }
    },
    "UpdateModelCard": {
      "input": {
        "type": "structure",
        "required": [
          "ModelCardName"
        ],
        "members": {
          "ModelCardName": {},
          "Content": {
            "shape": "Sn0"
          },
          "ModelCardStatus": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ModelCardArn"
        ],
        "members": {
          "ModelCardArn": {}
        }
      }
    },
    "UpdateModelPackage": {
      "input": {
        "type": "structure",
        "required": [
          "ModelPackageArn"
        ],
        "members": {
          "ModelPackageArn": {},
          "ModelApprovalStatus": {},
          "ApprovalDescription": {},
          "CustomerMetadataProperties": {
            "shape": "Sns"
          },
          "CustomerMetadataPropertiesToRemove": {
            "type": "list",
            "member": {}
          },
          "AdditionalInferenceSpecificationsToAdd": {
            "shape": "So1"
          },
          "InferenceSpecification": {
            "shape": "Sr"
          },
          "SourceUri": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ModelPackageArn"
        ],
        "members": {
          "ModelPackageArn": {}
        }
      }
    },
    "UpdateMonitoringAlert": {
      "input": {
        "type": "structure",
        "required": [
          "MonitoringScheduleName",
          "MonitoringAlertName",
          "DatapointsToAlert",
          "EvaluationPeriod"
        ],
        "members": {
          "MonitoringScheduleName": {},
          "MonitoringAlertName": {},
          "DatapointsToAlert": {
            "type": "integer"
          },
          "EvaluationPeriod": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "MonitoringScheduleArn"
        ],
        "members": {
          "MonitoringScheduleArn": {},
          "MonitoringAlertName": {}
        }
      }
    },
    "UpdateMonitoringSchedule": {
      "input": {
        "type": "structure",
        "required": [
          "MonitoringScheduleName",
          "MonitoringScheduleConfig"
        ],
        "members": {
          "MonitoringScheduleName": {},
          "MonitoringScheduleConfig": {
            "shape": "Soh"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "MonitoringScheduleArn"
        ],
        "members": {
          "MonitoringScheduleArn": {}
        }
      }
    },
    "UpdateNotebookInstance": {
      "input": {
        "type": "structure",
        "required": [
          "NotebookInstanceName"
        ],
        "members": {
          "NotebookInstanceName": {},
          "InstanceType": {},
          "RoleArn": {},
          "LifecycleConfigName": {},
          "DisassociateLifecycleConfig": {
            "type": "boolean"
          },
          "VolumeSizeInGB": {
            "type": "integer"
          },
          "DefaultCodeRepository": {},
          "AdditionalCodeRepositories": {
            "shape": "Sp1"
          },
          "AcceleratorTypes": {
            "shape": "Soy"
          },
          "DisassociateAcceleratorTypes": {
            "type": "boolean"
          },
          "DisassociateDefaultCodeRepository": {
            "type": "boolean"
          },
          "DisassociateAdditionalCodeRepositories": {
            "type": "boolean"
          },
          "RootAccess": {},
          "InstanceMetadataServiceConfiguration": {
            "shape": "Sp4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateNotebookInstanceLifecycleConfig": {
      "input": {
        "type": "structure",
        "required": [
          "NotebookInstanceLifecycleConfigName"
        ],
        "members": {
          "NotebookInstanceLifecycleConfigName": {},
          "OnCreate": {
            "shape": "Sp9"
          },
          "OnStart": {
            "shape": "Sp9"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdatePipeline": {
      "input": {
        "type": "structure",
        "required": [
          "PipelineName"
        ],
        "members": {
          "PipelineName": {},
          "PipelineDisplayName": {},
          "PipelineDefinition": {},
          "PipelineDefinitionS3Location": {
            "shape": "Sph"
          },
          "PipelineDescription": {},
          "RoleArn": {},
          "ParallelismConfiguration": {
            "shape": "Spn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PipelineArn": {}
        }
      }
    },
    "UpdatePipelineExecution": {
      "input": {
        "type": "structure",
        "required": [
          "PipelineExecutionArn"
        ],
        "members": {
          "PipelineExecutionArn": {},
          "PipelineExecutionDescription": {},
          "PipelineExecutionDisplayName": {},
          "ParallelismConfiguration": {
            "shape": "Spn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PipelineExecutionArn": {}
        }
      }
    },
    "UpdateProject": {
      "input": {
        "type": "structure",
        "required": [
          "ProjectName"
        ],
        "members": {
          "ProjectName": {},
          "ProjectDescription": {},
          "ServiceCatalogProvisioningUpdateDetails": {
            "type": "structure",
            "members": {
              "ProvisioningArtifactId": {},
              "ProvisioningParameters": {
                "shape": "Sr6"
              }
            }
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ProjectArn"
        ],
        "members": {
          "ProjectArn": {}
        }
      }
    },
    "UpdateSpace": {
      "input": {
        "type": "structure",
        "required": [
          "DomainId",
          "SpaceName"
        ],
        "members": {
          "DomainId": {},
          "SpaceName": {},
          "SpaceSettings": {
            "shape": "Sre"
          },
          "SpaceDisplayName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SpaceArn": {}
        }
      }
    },
    "UpdateTrainingJob": {
      "input": {
        "type": "structure",
        "required": [
          "TrainingJobName"
        ],
        "members": {
          "TrainingJobName": {},
          "ProfilerConfig": {
            "type": "structure",
            "members": {
              "S3OutputPath": {},
              "ProfilingIntervalInMilliseconds": {
                "type": "long"
              },
              "ProfilingParameters": {
                "shape": "Ssm"
              },
              "DisableProfiler": {
                "type": "boolean"
              }
            }
          },
          "ProfilerRuleConfigurations": {
            "shape": "Sso"
          },
          "ResourceConfig": {
            "type": "structure",
            "required": [
              "KeepAlivePeriodInSeconds"
            ],
            "members": {
              "KeepAlivePeriodInSeconds": {
                "type": "integer"
              }
            }
          },
          "RemoteDebugConfig": {
            "type": "structure",
            "members": {
              "EnableRemoteDebug": {
                "type": "boolean"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TrainingJobArn"
        ],
        "members": {
          "TrainingJobArn": {}
        }
      }
    },
    "UpdateTrial": {
      "input": {
        "type": "structure",
        "required": [
          "TrialName"
        ],
        "members": {
          "TrialName": {},
          "DisplayName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrialArn": {}
        }
      }
    },
    "UpdateTrialComponent": {
      "input": {
        "type": "structure",
        "required": [
          "TrialComponentName"
        ],
        "members": {
          "TrialComponentName": {},
          "DisplayName": {},
          "Status": {
            "shape": "Std"
          },
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Parameters": {
            "shape": "Stg"
          },
          "ParametersToRemove": {
            "shape": "S1pw"
          },
          "InputArtifacts": {
            "shape": "Stk"
          },
          "InputArtifactsToRemove": {
            "shape": "S1pw"
          },
          "OutputArtifacts": {
            "shape": "Stk"
          },
          "OutputArtifactsToRemove": {
            "shape": "S1pw"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrialComponentArn": {}
        }
      }
    },
    "UpdateUserProfile": {
      "input": {
        "type": "structure",
        "required": [
          "DomainId",
          "UserProfileName"
        ],
        "members": {
          "DomainId": {},
          "UserProfileName": {},
          "UserSettings": {
            "shape": "Sah"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserProfileArn": {}
        }
      }
    },
    "UpdateWorkforce": {
      "input": {
        "type": "structure",
        "required": [
          "WorkforceName"
        ],
        "members": {
          "WorkforceName": {},
          "SourceIpConfig": {
            "shape": "Su1"
          },
          "OidcConfig": {
            "shape": "Sty"
          },
          "WorkforceVpcConfig": {
            "shape": "Su5"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Workforce"
        ],
        "members": {
          "Workforce": {
            "shape": "S172"
          }
        }
      }
    },
    "UpdateWorkteam": {
      "input": {
        "type": "structure",
        "required": [
          "WorkteamName"
        ],
        "members": {
          "WorkteamName": {},
          "MemberDefinitions": {
            "shape": "Suf"
          },
          "Description": {},
          "NotificationConfiguration": {
            "shape": "Sun"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Workteam"
        ],
        "members": {
          "Workteam": {
            "shape": "S17a"
          }
        }
      }
    }
  },
  "shapes": {
    "S7": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sr": {
      "type": "structure",
      "required": [
        "Containers"
      ],
      "members": {
        "Containers": {
          "shape": "Ss"
        },
        "SupportedTransformInstanceTypes": {
          "shape": "S1h"
        },
        "SupportedRealtimeInferenceInstanceTypes": {
          "shape": "S1j"
        },
        "SupportedContentTypes": {
          "shape": "S1l"
        },
        "SupportedResponseMIMETypes": {
          "shape": "S1n"
        }
      }
    },
    "Ss": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Image"
        ],
        "members": {
          "ContainerHostname": {},
          "Image": {},
          "ImageDigest": {},
          "ModelDataUrl": {},
          "ModelDataSource": {
            "shape": "Sy"
          },
          "ProductId": {},
          "Environment": {
            "shape": "S16"
          },
          "ModelInput": {
            "type": "structure",
            "required": [
              "DataInputConfig"
            ],
            "members": {
              "DataInputConfig": {}
            }
          },
          "Framework": {},
          "FrameworkVersion": {},
          "NearestModelName": {},
          "AdditionalS3DataSource": {
            "shape": "S1d"
          }
        }
      }
    },
    "Sy": {
      "type": "structure",
      "members": {
        "S3DataSource": {
          "type": "structure",
          "required": [
            "S3Uri",
            "S3DataType",
            "CompressionType"
          ],
          "members": {
            "S3Uri": {},
            "S3DataType": {},
            "CompressionType": {},
            "ModelAccessConfig": {
              "shape": "S13"
            }
          }
        }
      }
    },
    "S13": {
      "type": "structure",
      "required": [
        "AcceptEula"
      ],
      "members": {
        "AcceptEula": {
          "type": "boolean"
        }
      }
    },
    "S16": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1d": {
      "type": "structure",
      "required": [
        "S3DataType",
        "S3Uri"
      ],
      "members": {
        "S3DataType": {},
        "S3Uri": {},
        "CompressionType": {}
      }
    },
    "S1h": {
      "type": "list",
      "member": {}
    },
    "S1j": {
      "type": "list",
      "member": {}
    },
    "S1l": {
      "type": "list",
      "member": {}
    },
    "S1n": {
      "type": "list",
      "member": {}
    },
    "S1u": {
      "type": "structure",
      "required": [
        "SourceUri"
      ],
      "members": {
        "SourceUri": {},
        "SourceType": {},
        "SourceId": {}
      }
    },
    "S1z": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S21": {
      "type": "structure",
      "members": {
        "CommitId": {},
        "Repository": {},
        "GeneratedBy": {},
        "ProjectId": {}
      }
    },
    "S26": {
      "type": "structure",
      "required": [
        "TrainingImage",
        "SupportedTrainingInstanceTypes",
        "TrainingChannels"
      ],
      "members": {
        "TrainingImage": {},
        "TrainingImageDigest": {},
        "SupportedHyperParameters": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Name",
              "Type"
            ],
            "members": {
              "Name": {},
              "Description": {},
              "Type": {},
              "Range": {
                "type": "structure",
                "members": {
                  "IntegerParameterRangeSpecification": {
                    "type": "structure",
                    "required": [
                      "MinValue",
                      "MaxValue"
                    ],
                    "members": {
                      "MinValue": {},
                      "MaxValue": {}
                    }
                  },
                  "ContinuousParameterRangeSpecification": {
                    "type": "structure",
                    "required": [
                      "MinValue",
                      "MaxValue"
                    ],
                    "members": {
                      "MinValue": {},
                      "MaxValue": {}
                    }
                  },
                  "CategoricalParameterRangeSpecification": {
                    "type": "structure",
                    "required": [
                      "Values"
                    ],
                    "members": {
                      "Values": {
                        "shape": "S2g"
                      }
                    }
                  }
                }
              },
              "IsTunable": {
                "type": "boolean"
              },
              "IsRequired": {
                "type": "boolean"
              },
              "DefaultValue": {}
            }
          }
        },
        "SupportedTrainingInstanceTypes": {
          "type": "list",
          "member": {}
        },
        "SupportsDistributedTraining": {
          "type": "boolean"
        },
        "MetricDefinitions": {
          "shape": "S2l"
        },
        "TrainingChannels": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Name",
              "SupportedContentTypes",
              "SupportedInputModes"
            ],
            "members": {
              "Name": {},
              "Description": {},
              "IsRequired": {
                "type": "boolean"
              },
              "SupportedContentTypes": {
                "shape": "S1l"
              },
              "SupportedCompressionTypes": {
                "type": "list",
                "member": {}
              },
              "SupportedInputModes": {
                "type": "list",
                "member": {}
              }
            }
          }
        },
        "SupportedTuningJobObjectiveMetrics": {
          "type": "list",
          "member": {
            "shape": "S2w"
          }
        },
        "AdditionalS3DataSource": {
          "shape": "S1d"
        }
      }
    },
    "S2g": {
      "type": "list",
      "member": {}
    },
    "S2l": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name",
          "Regex"
        ],
        "members": {
          "Name": {},
          "Regex": {}
        }
      }
    },
    "S2w": {
      "type": "structure",
      "required": [
        "Type",
        "MetricName"
      ],
      "members": {
        "Type": {},
        "MetricName": {}
      }
    },
    "S2y": {
      "type": "structure",
      "required": [
        "ValidationRole",
        "ValidationProfiles"
      ],
      "members": {
        "ValidationRole": {},
        "ValidationProfiles": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "ProfileName",
              "TrainingJobDefinition"
            ],
            "members": {
              "ProfileName": {},
              "TrainingJobDefinition": {
                "type": "structure",
                "required": [
                  "TrainingInputMode",
                  "InputDataConfig",
                  "OutputDataConfig",
                  "ResourceConfig",
                  "StoppingCondition"
                ],
                "members": {
                  "TrainingInputMode": {},
                  "HyperParameters": {
                    "shape": "S33"
                  },
                  "InputDataConfig": {
                    "shape": "S35"
                  },
                  "OutputDataConfig": {
                    "shape": "S3n"
                  },
                  "ResourceConfig": {
                    "shape": "S3q"
                  },
                  "StoppingCondition": {
                    "shape": "S3w"
                  }
                }
              },
              "TransformJobDefinition": {
                "shape": "S40"
              }
            }
          }
        }
      }
    },
    "S33": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S35": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "ChannelName",
          "DataSource"
        ],
        "members": {
          "ChannelName": {},
          "DataSource": {
            "type": "structure",
            "members": {
              "S3DataSource": {
                "type": "structure",
                "required": [
                  "S3DataType",
                  "S3Uri"
                ],
                "members": {
                  "S3DataType": {},
                  "S3Uri": {},
                  "S3DataDistributionType": {},
                  "AttributeNames": {
                    "type": "list",
                    "member": {}
                  },
                  "InstanceGroupNames": {
                    "type": "list",
                    "member": {}
                  }
                }
              },
              "FileSystemDataSource": {
                "type": "structure",
                "required": [
                  "FileSystemId",
                  "FileSystemAccessMode",
                  "FileSystemType",
                  "DirectoryPath"
                ],
                "members": {
                  "FileSystemId": {},
                  "FileSystemAccessMode": {},
                  "FileSystemType": {},
                  "DirectoryPath": {}
                }
              }
            }
          },
          "ContentType": {},
          "CompressionType": {},
          "RecordWrapperType": {},
          "InputMode": {},
          "ShuffleConfig": {
            "type": "structure",
            "required": [
              "Seed"
            ],
            "members": {
              "Seed": {
                "type": "long"
              }
            }
          }
        }
      }
    },
    "S3n": {
      "type": "structure",
      "required": [
        "S3OutputPath"
      ],
      "members": {
        "KmsKeyId": {},
        "S3OutputPath": {},
        "CompressionType": {}
      }
    },
    "S3q": {
      "type": "structure",
      "required": [
        "VolumeSizeInGB"
      ],
      "members": {
        "InstanceType": {},
        "InstanceCount": {
          "type": "integer"
        },
        "VolumeSizeInGB": {
          "type": "integer"
        },
        "VolumeKmsKeyId": {},
        "KeepAlivePeriodInSeconds": {
          "type": "integer"
        },
        "InstanceGroups": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "InstanceType",
              "InstanceCount",
              "InstanceGroupName"
            ],
            "members": {
              "InstanceType": {},
              "InstanceCount": {
                "type": "integer"
              },
              "InstanceGroupName": {}
            }
          }
        }
      }
    },
    "S3w": {
      "type": "structure",
      "members": {
        "MaxRuntimeInSeconds": {
          "type": "integer"
        },
        "MaxWaitTimeInSeconds": {
          "type": "integer"
        },
        "MaxPendingTimeInSeconds": {
          "type": "integer"
        }
      }
    },
    "S40": {
      "type": "structure",
      "required": [
        "TransformInput",
        "TransformOutput",
        "TransformResources"
      ],
      "members": {
        "MaxConcurrentTransforms": {
          "type": "integer"
        },
        "MaxPayloadInMB": {
          "type": "integer"
        },
        "BatchStrategy": {},
        "Environment": {
          "shape": "S44"
        },
        "TransformInput": {
          "shape": "S47"
        },
        "TransformOutput": {
          "shape": "S4b"
        },
        "TransformResources": {
          "shape": "S4e"
        }
      }
    },
    "S44": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S47": {
      "type": "structure",
      "required": [
        "DataSource"
      ],
      "members": {
        "DataSource": {
          "type": "structure",
          "required": [
            "S3DataSource"
          ],
          "members": {
            "S3DataSource": {
              "type": "structure",
              "required": [
                "S3DataType",
                "S3Uri"
              ],
              "members": {
                "S3DataType": {},
                "S3Uri": {}
              }
            }
          }
        },
        "ContentType": {},
        "CompressionType": {},
        "SplitType": {}
      }
    },
    "S4b": {
      "type": "structure",
      "required": [
        "S3OutputPath"
      ],
      "members": {
        "S3OutputPath": {},
        "Accept": {},
        "AssembleWith": {},
        "KmsKeyId": {}
      }
    },
    "S4e": {
      "type": "structure",
      "required": [
        "InstanceType",
        "InstanceCount"
      ],
      "members": {
        "InstanceType": {},
        "InstanceCount": {
          "type": "integer"
        },
        "VolumeKmsKeyId": {}
      }
    },
    "S4p": {
      "type": "structure",
      "members": {
        "SageMakerImageArn": {},
        "SageMakerImageVersionArn": {},
        "SageMakerImageVersionAlias": {},
        "InstanceType": {},
        "LifecycleConfigArn": {}
      }
    },
    "S4z": {
      "type": "structure",
      "required": [
        "KernelSpecs"
      ],
      "members": {
        "KernelSpecs": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Name"
            ],
            "members": {
              "Name": {},
              "DisplayName": {}
            }
          }
        },
        "FileSystemConfig": {
          "shape": "S54"
        }
      }
    },
    "S54": {
      "type": "structure",
      "members": {
        "MountPath": {},
        "DefaultUid": {
          "type": "integer"
        },
        "DefaultGid": {
          "type": "integer"
        }
      }
    },
    "S58": {
      "type": "structure",
      "members": {
        "FileSystemConfig": {
          "shape": "S54"
        },
        "ContainerConfig": {
          "type": "structure",
          "members": {
            "ContainerArguments": {
              "type": "list",
              "member": {}
            },
            "ContainerEntrypoint": {
              "type": "list",
              "member": {}
            },
            "ContainerEnvironmentVariables": {
              "type": "map",
              "key": {},
              "value": {}
            }
          }
        }
      }
    },
    "S5i": {
      "type": "structure",
      "required": [
        "SourceUri"
      ],
      "members": {
        "SourceUri": {},
        "SourceTypes": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "SourceIdType",
              "Value"
            ],
            "members": {
              "SourceIdType": {},
              "Value": {}
            }
          }
        }
      }
    },
    "S5m": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S5s": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "TargetAttributeName"
        ],
        "members": {
          "DataSource": {
            "shape": "S5u"
          },
          "CompressionType": {},
          "TargetAttributeName": {},
          "ContentType": {},
          "ChannelType": {},
          "SampleWeightAttributeName": {}
        }
      }
    },
    "S5u": {
      "type": "structure",
      "required": [
        "S3DataSource"
      ],
      "members": {
        "S3DataSource": {
          "type": "structure",
          "required": [
            "S3DataType",
            "S3Uri"
          ],
          "members": {
            "S3DataType": {},
            "S3Uri": {}
          }
        }
      }
    },
    "S60": {
      "type": "structure",
      "required": [
        "S3OutputPath"
      ],
      "members": {
        "KmsKeyId": {},
        "S3OutputPath": {}
      }
    },
    "S62": {
      "type": "structure",
      "required": [
        "MetricName"
      ],
      "members": {
        "MetricName": {}
      }
    },
    "S64": {
      "type": "structure",
      "members": {
        "CompletionCriteria": {
          "shape": "S65"
        },
        "SecurityConfig": {
          "shape": "S69"
        },
        "CandidateGenerationConfig": {
          "type": "structure",
          "members": {
            "FeatureSpecificationS3Uri": {},
            "AlgorithmsConfig": {
              "shape": "S6g"
            }
          }
        },
        "DataSplitConfig": {
          "shape": "S6k"
        },
        "Mode": {}
      }
    },
    "S65": {
      "type": "structure",
      "members": {
        "MaxCandidates": {
          "type": "integer"
        },
        "MaxRuntimePerTrainingJobInSeconds": {
          "type": "integer"
        },
        "MaxAutoMLJobRuntimeInSeconds": {
          "type": "integer"
        }
      }
    },
    "S69": {
      "type": "structure",
      "members": {
        "VolumeKmsKeyId": {},
        "EnableInterContainerTrafficEncryption": {
          "type": "boolean"
        },
        "VpcConfig": {
          "shape": "S6a"
        }
      }
    },
    "S6a": {
      "type": "structure",
      "required": [
        "SecurityGroupIds",
        "Subnets"
      ],
      "members": {
        "SecurityGroupIds": {
          "type": "list",
          "member": {}
        },
        "Subnets": {
          "shape": "S6d"
        }
      }
    },
    "S6d": {
      "type": "list",
      "member": {}
    },
    "S6g": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "AutoMLAlgorithms"
        ],
        "members": {
          "AutoMLAlgorithms": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "S6k": {
      "type": "structure",
      "members": {
        "ValidationFraction": {
          "type": "float"
        }
      }
    },
    "S6o": {
      "type": "structure",
      "members": {
        "AutoGenerateEndpointName": {
          "type": "boolean"
        },
        "EndpointName": {}
      }
    },
    "S6u": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ChannelType": {},
          "ContentType": {},
          "CompressionType": {},
          "DataSource": {
            "shape": "S5u"
          }
        }
      }
    },
    "S6w": {
      "type": "structure",
      "members": {
        "ImageClassificationJobConfig": {
          "type": "structure",
          "members": {
            "CompletionCriteria": {
              "shape": "S65"
            }
          }
        },
        "TextClassificationJobConfig": {
          "type": "structure",
          "required": [
            "ContentColumn",
            "TargetLabelColumn"
          ],
          "members": {
            "CompletionCriteria": {
              "shape": "S65"
            },
            "ContentColumn": {},
            "TargetLabelColumn": {}
          }
        },
        "TimeSeriesForecastingJobConfig": {
          "type": "structure",
          "required": [
            "ForecastFrequency",
            "ForecastHorizon",
            "TimeSeriesConfig"
          ],
          "members": {
            "FeatureSpecificationS3Uri": {},
            "CompletionCriteria": {
              "shape": "S65"
            },
            "ForecastFrequency": {},
            "ForecastHorizon": {
              "type": "integer"
            },
            "ForecastQuantiles": {
              "type": "list",
              "member": {}
            },
            "Transformations": {
              "type": "structure",
              "members": {
                "Filling": {
                  "type": "map",
                  "key": {},
                  "value": {
                    "type": "map",
                    "key": {},
                    "value": {}
                  }
                },
                "Aggregation": {
                  "type": "map",
                  "key": {},
                  "value": {}
                }
              }
            },
            "TimeSeriesConfig": {
              "type": "structure",
              "required": [
                "TargetAttributeName",
                "TimestampAttributeName",
                "ItemIdentifierAttributeName"
              ],
              "members": {
                "TargetAttributeName": {},
                "TimestampAttributeName": {},
                "ItemIdentifierAttributeName": {},
                "GroupingAttributeNames": {
                  "type": "list",
                  "member": {}
                }
              }
            },
            "HolidayConfig": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "CountryCode": {}
                }
              }
            }
          }
        },
        "TabularJobConfig": {
          "type": "structure",
          "required": [
            "TargetAttributeName"
          ],
          "members": {
            "CandidateGenerationConfig": {
              "type": "structure",
              "members": {
                "AlgorithmsConfig": {
                  "shape": "S6g"
                }
              }
            },
            "CompletionCriteria": {
              "shape": "S65"
            },
            "FeatureSpecificationS3Uri": {},
            "Mode": {},
            "GenerateCandidateDefinitionsOnly": {
              "type": "boolean"
            },
            "ProblemType": {},
            "TargetAttributeName": {},
            "SampleWeightAttributeName": {}
          }
        },
        "TextGenerationJobConfig": {
          "type": "structure",
          "members": {
            "CompletionCriteria": {
              "shape": "S65"
            },
            "BaseModelName": {},
            "TextGenerationHyperParameters": {
              "type": "map",
              "key": {},
              "value": {}
            },
            "ModelAccessConfig": {
              "shape": "S13"
            }
          }
        }
      },
      "union": true
    },
    "S7w": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "InstanceCount",
          "InstanceGroupName",
          "InstanceType",
          "LifeCycleConfig",
          "ExecutionRole"
        ],
        "members": {
          "InstanceCount": {
            "type": "integer"
          },
          "InstanceGroupName": {},
          "InstanceType": {},
          "LifeCycleConfig": {
            "shape": "S81"
          },
          "ExecutionRole": {},
          "ThreadsPerCore": {
            "type": "integer"
          }
        }
      }
    },
    "S81": {
      "type": "structure",
      "required": [
        "SourceS3Uri",
        "OnCreate"
      ],
      "members": {
        "SourceS3Uri": {},
        "OnCreate": {}
      }
    },
    "S87": {
      "type": "structure",
      "required": [
        "RepositoryUrl"
      ],
      "members": {
        "RepositoryUrl": {},
        "Branch": {},
        "SecretArn": {}
      }
    },
    "S8e": {
      "type": "structure",
      "required": [
        "S3Uri",
        "Framework"
      ],
      "members": {
        "S3Uri": {},
        "DataInputConfig": {},
        "Framework": {},
        "FrameworkVersion": {}
      }
    },
    "S8h": {
      "type": "structure",
      "required": [
        "S3OutputLocation"
      ],
      "members": {
        "S3OutputLocation": {},
        "TargetDevice": {},
        "TargetPlatform": {
          "type": "structure",
          "required": [
            "Os",
            "Arch"
          ],
          "members": {
            "Os": {},
            "Arch": {},
            "Accelerator": {}
          }
        },
        "CompilerOptions": {},
        "KmsKeyId": {}
      }
    },
    "S8o": {
      "type": "structure",
      "required": [
        "SecurityGroupIds",
        "Subnets"
      ],
      "members": {
        "SecurityGroupIds": {
          "type": "list",
          "member": {}
        },
        "Subnets": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S8x": {
      "type": "structure",
      "required": [
        "SourceUri"
      ],
      "members": {
        "SourceUri": {},
        "SourceType": {},
        "SourceId": {}
      }
    },
    "S92": {
      "type": "structure",
      "members": {
        "BaseliningJobName": {},
        "ConstraintsResource": {
          "shape": "S94"
        },
        "StatisticsResource": {
          "shape": "S95"
        }
      }
    },
    "S94": {
      "type": "structure",
      "members": {
        "S3Uri": {}
      }
    },
    "S95": {
      "type": "structure",
      "members": {
        "S3Uri": {}
      }
    },
    "S96": {
      "type": "structure",
      "required": [
        "ImageUri"
      ],
      "members": {
        "ImageUri": {},
        "ContainerEntrypoint": {
          "shape": "S98"
        },
        "ContainerArguments": {
          "shape": "S9a"
        },
        "RecordPreprocessorSourceUri": {},
        "PostAnalyticsProcessorSourceUri": {},
        "Environment": {
          "shape": "S9c"
        }
      }
    },
    "S98": {
      "type": "list",
      "member": {}
    },
    "S9a": {
      "type": "list",
      "member": {}
    },
    "S9c": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S9f": {
      "type": "structure",
      "members": {
        "EndpointInput": {
          "shape": "S9g"
        },
        "BatchTransformInput": {
          "shape": "S9n"
        }
      }
    },
    "S9g": {
      "type": "structure",
      "required": [
        "EndpointName",
        "LocalPath"
      ],
      "members": {
        "EndpointName": {},
        "LocalPath": {},
        "S3InputMode": {},
        "S3DataDistributionType": {},
        "FeaturesAttribute": {},
        "InferenceAttribute": {},
        "ProbabilityAttribute": {},
        "ProbabilityThresholdAttribute": {
          "type": "double"
        },
        "StartTimeOffset": {},
        "EndTimeOffset": {},
        "ExcludeFeaturesAttribute": {}
      }
    },
    "S9n": {
      "type": "structure",
      "required": [
        "DataCapturedDestinationS3Uri",
        "DatasetFormat",
        "LocalPath"
      ],
      "members": {
        "DataCapturedDestinationS3Uri": {},
        "DatasetFormat": {
          "type": "structure",
          "members": {
            "Csv": {
              "type": "structure",
              "members": {
                "Header": {
                  "type": "boolean"
                }
              }
            },
            "Json": {
              "type": "structure",
              "members": {
                "Line": {
                  "type": "boolean"
                }
              }
            },
            "Parquet": {
              "type": "structure",
              "members": {}
            }
          }
        },
        "LocalPath": {},
        "S3InputMode": {},
        "S3DataDistributionType": {},
        "FeaturesAttribute": {},
        "InferenceAttribute": {},
        "ProbabilityAttribute": {},
        "ProbabilityThresholdAttribute": {
          "type": "double"
        },
        "StartTimeOffset": {},
        "EndTimeOffset": {},
        "ExcludeFeaturesAttribute": {}
      }
    },
    "S9t": {
      "type": "structure",
      "required": [
        "MonitoringOutputs"
      ],
      "members": {
        "MonitoringOutputs": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "S3Output"
            ],
            "members": {
              "S3Output": {
                "type": "structure",
                "required": [
                  "S3Uri",
                  "LocalPath"
                ],
                "members": {
                  "S3Uri": {},
                  "LocalPath": {},
                  "S3UploadMode": {}
                }
              }
            }
          }
        },
        "KmsKeyId": {}
      }
    },
    "S9z": {
      "type": "structure",
      "required": [
        "ClusterConfig"
      ],
      "members": {
        "ClusterConfig": {
          "type": "structure",
          "required": [
            "InstanceCount",
            "InstanceType",
            "VolumeSizeInGB"
          ],
          "members": {
            "InstanceCount": {
              "type": "integer"
            },
            "InstanceType": {},
            "VolumeSizeInGB": {
              "type": "integer"
            },
            "VolumeKmsKeyId": {}
          }
        }
      }
    },
    "Sa4": {
      "type": "structure",
      "members": {
        "EnableInterContainerTrafficEncryption": {
          "type": "boolean"
        },
        "EnableNetworkIsolation": {
          "type": "boolean"
        },
        "VpcConfig": {
          "shape": "S6a"
        }
      }
    },
    "Sa5": {
      "type": "structure",
      "required": [
        "MaxRuntimeInSeconds"
      ],
      "members": {
        "MaxRuntimeInSeconds": {
          "type": "integer"
        }
      }
    },
    "Sab": {
      "type": "structure",
      "required": [
        "S3OutputLocation"
      ],
      "members": {
        "S3OutputLocation": {},
        "KmsKeyId": {},
        "PresetDeploymentType": {},
        "PresetDeploymentConfig": {}
      }
    },
    "Sah": {
      "type": "structure",
      "members": {
        "ExecutionRole": {},
        "SecurityGroups": {
          "shape": "Sai"
        },
        "SharingSettings": {
          "type": "structure",
          "members": {
            "NotebookOutputOption": {},
            "S3OutputPath": {},
            "S3KmsKeyId": {}
          }
        },
        "JupyterServerAppSettings": {
          "shape": "Sal"
        },
        "KernelGatewayAppSettings": {
          "shape": "Saq"
        },
        "TensorBoardAppSettings": {
          "type": "structure",
          "members": {
            "DefaultResourceSpec": {
              "shape": "S4p"
            }
          }
        },
        "RStudioServerProAppSettings": {
          "type": "structure",
          "members": {
            "AccessStatus": {},
            "UserGroup": {}
          }
        },
        "RSessionAppSettings": {
          "type": "structure",
          "members": {
            "DefaultResourceSpec": {
              "shape": "S4p"
            },
            "CustomImages": {
              "shape": "Sar"
            }
          }
        },
        "CanvasAppSettings": {
          "type": "structure",
          "members": {
            "TimeSeriesForecastingSettings": {
              "type": "structure",
              "members": {
                "Status": {},
                "AmazonForecastRoleArn": {}
              }
            },
            "ModelRegisterSettings": {
              "type": "structure",
              "members": {
                "Status": {},
                "CrossAccountModelRegisterRoleArn": {}
              }
            },
            "WorkspaceSettings": {
              "type": "structure",
              "members": {
                "S3ArtifactPath": {},
                "S3KmsKeyId": {}
              }
            },
            "IdentityProviderOAuthSettings": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "DataSourceName": {},
                  "Status": {},
                  "SecretArn": {}
                }
              }
            },
            "DirectDeploySettings": {
              "type": "structure",
              "members": {
                "Status": {}
              }
            },
            "KendraSettings": {
              "type": "structure",
              "members": {
                "Status": {}
              }
            },
            "GenerativeAiSettings": {
              "type": "structure",
              "members": {
                "AmazonBedrockRoleArn": {}
              }
            }
          }
        },
        "CodeEditorAppSettings": {
          "type": "structure",
          "members": {
            "DefaultResourceSpec": {
              "shape": "S4p"
            },
            "LifecycleConfigArns": {
              "shape": "Sam"
            }
          }
        },
        "JupyterLabAppSettings": {
          "type": "structure",
          "members": {
            "DefaultResourceSpec": {
              "shape": "S4p"
            },
            "CustomImages": {
              "shape": "Sar"
            },
            "LifecycleConfigArns": {
              "shape": "Sam"
            },
            "CodeRepositories": {
              "shape": "San"
            }
          }
        },
        "SpaceStorageSettings": {
          "type": "structure",
          "members": {
            "DefaultEbsStorageSettings": {
              "type": "structure",
              "required": [
                "DefaultEbsVolumeSizeInGb",
                "MaximumEbsVolumeSizeInGb"
              ],
              "members": {
                "DefaultEbsVolumeSizeInGb": {
                  "type": "integer"
                },
                "MaximumEbsVolumeSizeInGb": {
                  "type": "integer"
                }
              }
            }
          }
        },
        "DefaultLandingUri": {},
        "StudioWebPortal": {},
        "CustomPosixUserConfig": {
          "type": "structure",
          "required": [
            "Uid",
            "Gid"
          ],
          "members": {
            "Uid": {
              "type": "long"
            },
            "Gid": {
              "type": "long"
            }
          }
        },
        "CustomFileSystemConfigs": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "EFSFileSystemConfig": {
                "type": "structure",
                "required": [
                  "FileSystemId"
                ],
                "members": {
                  "FileSystemId": {},
                  "FileSystemPath": {}
                }
              }
            },
            "union": true
          }
        }
      }
    },
    "Sai": {
      "type": "list",
      "member": {}
    },
    "Sal": {
      "type": "structure",
      "members": {
        "DefaultResourceSpec": {
          "shape": "S4p"
        },
        "LifecycleConfigArns": {
          "shape": "Sam"
        },
        "CodeRepositories": {
          "shape": "San"
        }
      }
    },
    "Sam": {
      "type": "list",
      "member": {}
    },
    "San": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "RepositoryUrl"
        ],
        "members": {
          "RepositoryUrl": {}
        }
      }
    },
    "Saq": {
      "type": "structure",
      "members": {
        "DefaultResourceSpec": {
          "shape": "S4p"
        },
        "CustomImages": {
          "shape": "Sar"
        },
        "LifecycleConfigArns": {
          "shape": "Sam"
        }
      }
    },
    "Sar": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "ImageName",
          "AppImageConfigName"
        ],
        "members": {
          "ImageName": {},
          "ImageVersionNumber": {
            "type": "integer"
          },
          "AppImageConfigName": {}
        }
      }
    },
    "Sbp": {
      "type": "structure",
      "members": {
        "SecurityGroupIds": {
          "shape": "Sbq"
        },
        "RStudioServerProDomainSettings": {
          "type": "structure",
          "required": [
            "DomainExecutionRoleArn"
          ],
          "members": {
            "DomainExecutionRoleArn": {},
            "RStudioConnectUrl": {},
            "RStudioPackageManagerUrl": {},
            "DefaultResourceSpec": {
              "shape": "S4p"
            }
          }
        },
        "ExecutionRoleIdentityConfig": {},
        "DockerSettings": {
          "shape": "Sbt"
        }
      }
    },
    "Sbq": {
      "type": "list",
      "member": {}
    },
    "Sbt": {
      "type": "structure",
      "members": {
        "EnableDockerAccess": {},
        "VpcOnlyTrustedAccounts": {
          "type": "list",
          "member": {}
        }
      }
    },
    "Sbz": {
      "type": "structure",
      "members": {
        "ExecutionRole": {},
        "SecurityGroups": {
          "shape": "Sai"
        },
        "JupyterServerAppSettings": {
          "shape": "Sal"
        },
        "KernelGatewayAppSettings": {
          "shape": "Saq"
        }
      }
    },
    "Sc4": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "ModelHandle",
          "EdgePackagingJobName"
        ],
        "members": {
          "ModelHandle": {},
          "EdgePackagingJobName": {}
        }
      }
    },
    "Sc6": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "StageName",
          "DeviceSelectionConfig"
        ],
        "members": {
          "StageName": {},
          "DeviceSelectionConfig": {
            "shape": "Sc8"
          },
          "DeploymentConfig": {
            "shape": "Scd"
          }
        }
      }
    },
    "Sc8": {
      "type": "structure",
      "required": [
        "DeviceSubsetType"
      ],
      "members": {
        "DeviceSubsetType": {},
        "Percentage": {
          "type": "integer"
        },
        "DeviceNames": {
          "shape": "Scb"
        },
        "DeviceNameContains": {}
      }
    },
    "Scb": {
      "type": "list",
      "member": {}
    },
    "Scd": {
      "type": "structure",
      "required": [
        "FailureHandlingPolicy"
      ],
      "members": {
        "FailureHandlingPolicy": {}
      }
    },
    "Scm": {
      "type": "structure",
      "members": {
        "BlueGreenUpdatePolicy": {
          "type": "structure",
          "required": [
            "TrafficRoutingConfiguration"
          ],
          "members": {
            "TrafficRoutingConfiguration": {
              "type": "structure",
              "required": [
                "Type",
                "WaitIntervalInSeconds"
              ],
              "members": {
                "Type": {},
                "WaitIntervalInSeconds": {
                  "type": "integer"
                },
                "CanarySize": {
                  "shape": "Scr"
                },
                "LinearStepSize": {
                  "shape": "Scr"
                }
              }
            },
            "TerminationWaitInSeconds": {
              "type": "integer"
            },
            "MaximumExecutionTimeoutInSeconds": {
              "type": "integer"
            }
          }
        },
        "RollingUpdatePolicy": {
          "type": "structure",
          "required": [
            "MaximumBatchSize",
            "WaitIntervalInSeconds"
          ],
          "members": {
            "MaximumBatchSize": {
              "shape": "Scr"
            },
            "WaitIntervalInSeconds": {
              "type": "integer"
            },
            "MaximumExecutionTimeoutInSeconds": {
              "type": "integer"
            },
            "RollbackMaximumBatchSize": {
              "shape": "Scr"
            }
          }
        },
        "AutoRollbackConfiguration": {
          "type": "structure",
          "members": {
            "Alarms": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "AlarmName": {}
                }
              }
            }
          }
        }
      }
    },
    "Scr": {
      "type": "structure",
      "required": [
        "Type",
        "Value"
      ],
      "members": {
        "Type": {},
        "Value": {
          "type": "integer"
        }
      }
    },
    "Sd4": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "VariantName"
        ],
        "members": {
          "VariantName": {},
          "ModelName": {},
          "InitialInstanceCount": {
            "type": "integer"
          },
          "InstanceType": {},
          "InitialVariantWeight": {
            "type": "float"
          },
          "AcceleratorType": {},
          "CoreDumpConfig": {
            "type": "structure",
            "required": [
              "DestinationS3Uri"
            ],
            "members": {
              "DestinationS3Uri": {},
              "KmsKeyId": {}
            }
          },
          "ServerlessConfig": {
            "shape": "Sdc"
          },
          "VolumeSizeInGB": {
            "type": "integer"
          },
          "ModelDataDownloadTimeoutInSeconds": {
            "type": "integer"
          },
          "ContainerStartupHealthCheckTimeoutInSeconds": {
            "type": "integer"
          },
          "EnableSSMAccess": {
            "type": "boolean"
          },
          "ManagedInstanceScaling": {
            "shape": "Sdk"
          },
          "RoutingConfig": {
            "shape": "Sdo"
          }
        }
      }
    },
    "Sdc": {
      "type": "structure",
      "required": [
        "MemorySizeInMB",
        "MaxConcurrency"
      ],
      "members": {
        "MemorySizeInMB": {
          "type": "integer"
        },
        "MaxConcurrency": {
          "type": "integer"
        },
        "ProvisionedConcurrency": {
          "type": "integer"
        }
      }
    },
    "Sdk": {
      "type": "structure",
      "members": {
        "Status": {},
        "MinInstanceCount": {
          "type": "integer"
        },
        "MaxInstanceCount": {
          "type": "integer"
        }
      }
    },
    "Sdo": {
      "type": "structure",
      "required": [
        "RoutingStrategy"
      ],
      "members": {
        "RoutingStrategy": {}
      }
    },
    "Sdq": {
      "type": "structure",
      "required": [
        "InitialSamplingPercentage",
        "DestinationS3Uri",
        "CaptureOptions"
      ],
      "members": {
        "EnableCapture": {
          "type": "boolean"
        },
        "InitialSamplingPercentage": {
          "type": "integer"
        },
        "DestinationS3Uri": {},
        "KmsKeyId": {},
        "CaptureOptions": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "CaptureMode"
            ],
            "members": {
              "CaptureMode": {}
            }
          }
        },
        "CaptureContentTypeHeader": {
          "shape": "Sdw"
        }
      }
    },
    "Sdw": {
      "type": "structure",
      "members": {
        "CsvContentTypes": {
          "type": "list",
          "member": {}
        },
        "JsonContentTypes": {
          "type": "list",
          "member": {}
        }
      }
    },
    "Se1": {
      "type": "structure",
      "required": [
        "OutputConfig"
      ],
      "members": {
        "ClientConfig": {
          "type": "structure",
          "members": {
            "MaxConcurrentInvocationsPerInstance": {
              "type": "integer"
            }
          }
        },
        "OutputConfig": {
          "type": "structure",
          "members": {
            "KmsKeyId": {},
            "S3OutputPath": {},
            "NotificationConfig": {
              "type": "structure",
              "members": {
                "SuccessTopic": {},
                "ErrorTopic": {},
                "IncludeInferenceResponseIn": {
                  "type": "list",
                  "member": {}
                }
              }
            },
            "S3FailurePath": {}
          }
        }
      }
    },
    "Se9": {
      "type": "structure",
      "members": {
        "ClarifyExplainerConfig": {
          "type": "structure",
          "required": [
            "ShapConfig"
          ],
          "members": {
            "EnableExplanations": {},
            "InferenceConfig": {
              "type": "structure",
              "members": {
                "FeaturesAttribute": {},
                "ContentTemplate": {},
                "MaxRecordCount": {
                  "type": "integer"
                },
                "MaxPayloadInMB": {
                  "type": "integer"
                },
                "ProbabilityIndex": {
                  "type": "integer"
                },
                "LabelIndex": {
                  "type": "integer"
                },
                "ProbabilityAttribute": {},
                "LabelAttribute": {},
                "LabelHeaders": {
                  "type": "list",
                  "member": {}
                },
                "FeatureHeaders": {
                  "type": "list",
                  "member": {}
                },
                "FeatureTypes": {
                  "type": "list",
                  "member": {}
                }
              }
            },
            "ShapConfig": {
              "type": "structure",
              "required": [
                "ShapBaselineConfig"
              ],
              "members": {
                "ShapBaselineConfig": {
                  "type": "structure",
                  "members": {
                    "MimeType": {},
                    "ShapBaseline": {},
                    "ShapBaselineUri": {}
                  }
                },
                "NumberOfSamples": {
                  "type": "integer"
                },
                "UseLogit": {
                  "type": "boolean"
                },
                "Seed": {
                  "type": "integer"
                },
                "TextConfig": {
                  "type": "structure",
                  "required": [
                    "Language",
                    "Granularity"
                  ],
                  "members": {
                    "Language": {},
                    "Granularity": {}
                  }
                }
              }
            }
          }
        }
      }
    },
    "Sf8": {
      "type": "list",
      "member": {
        "shape": "Sf9"
      }
    },
    "Sf9": {
      "type": "structure",
      "required": [
        "FeatureName",
        "FeatureType"
      ],
      "members": {
        "FeatureName": {},
        "FeatureType": {},
        "CollectionType": {},
        "CollectionConfig": {
          "type": "structure",
          "members": {
            "VectorConfig": {
              "type": "structure",
              "required": [
                "Dimension"
              ],
              "members": {
                "Dimension": {
                  "type": "integer"
                }
              }
            }
          },
          "union": true
        }
      }
    },
    "Sff": {
      "type": "structure",
      "members": {
        "SecurityConfig": {
          "type": "structure",
          "members": {
            "KmsKeyId": {}
          }
        },
        "EnableOnlineStore": {
          "type": "boolean"
        },
        "TtlDuration": {
          "shape": "Sfh"
        },
        "StorageType": {}
      }
    },
    "Sfh": {
      "type": "structure",
      "members": {
        "Unit": {},
        "Value": {
          "type": "integer"
        }
      }
    },
    "Sfl": {
      "type": "structure",
      "required": [
        "S3StorageConfig"
      ],
      "members": {
        "S3StorageConfig": {
          "type": "structure",
          "required": [
            "S3Uri"
          ],
          "members": {
            "S3Uri": {},
            "KmsKeyId": {},
            "ResolvedOutputS3Uri": {}
          }
        },
        "DisableGlueTableCreation": {
          "type": "boolean"
        },
        "DataCatalogConfig": {
          "type": "structure",
          "required": [
            "TableName",
            "Catalog",
            "Database"
          ],
          "members": {
            "TableName": {},
            "Catalog": {},
            "Database": {}
          }
        },
        "TableFormat": {}
      }
    },
    "Sg0": {
      "type": "structure",
      "required": [
        "AwsManagedHumanLoopRequestSource"
      ],
      "members": {
        "AwsManagedHumanLoopRequestSource": {}
      }
    },
    "Sg2": {
      "type": "structure",
      "required": [
        "HumanLoopActivationConditionsConfig"
      ],
      "members": {
        "HumanLoopActivationConditionsConfig": {
          "type": "structure",
          "required": [
            "HumanLoopActivationConditions"
          ],
          "members": {
            "HumanLoopActivationConditions": {
              "jsonvalue": true
            }
          }
        }
      }
    },
    "Sg5": {
      "type": "structure",
      "required": [
        "WorkteamArn",
        "HumanTaskUiArn",
        "TaskTitle",
        "TaskDescription",
        "TaskCount"
      ],
      "members": {
        "WorkteamArn": {},
        "HumanTaskUiArn": {},
        "TaskTitle": {},
        "TaskDescription": {},
        "TaskCount": {
          "type": "integer"
        },
        "TaskAvailabilityLifetimeInSeconds": {
          "type": "integer"
        },
        "TaskTimeLimitInSeconds": {
          "type": "integer"
        },
        "TaskKeywords": {
          "type": "list",
          "member": {}
        },
        "PublicWorkforceTaskPrice": {
          "shape": "Sgf"
        }
      }
    },
    "Sgf": {
      "type": "structure",
      "members": {
        "AmountInUsd": {
          "type": "structure",
          "members": {
            "Dollars": {
              "type": "integer"
            },
            "Cents": {
              "type": "integer"
            },
            "TenthFractionsOfACent": {
              "type": "integer"
            }
          }
        }
      }
    },
    "Sgk": {
      "type": "structure",
      "required": [
        "S3OutputPath"
      ],
      "members": {
        "S3OutputPath": {},
        "KmsKeyId": {}
      }
    },
    "Sgr": {
      "type": "list",
      "member": {}
    },
    "Sgt": {
      "type": "structure",
      "members": {
        "S3OutputPath": {}
      }
    },
    "Sgz": {
      "type": "structure",
      "required": [
        "Content"
      ],
      "members": {
        "Content": {}
      }
    },
    "Sh4": {
      "type": "structure",
      "required": [
        "Strategy",
        "ResourceLimits"
      ],
      "members": {
        "Strategy": {},
        "StrategyConfig": {
          "type": "structure",
          "members": {
            "HyperbandStrategyConfig": {
              "type": "structure",
              "members": {
                "MinResource": {
                  "type": "integer"
                },
                "MaxResource": {
                  "type": "integer"
                }
              }
            }
          }
        },
        "HyperParameterTuningJobObjective": {
          "shape": "S2w"
        },
        "ResourceLimits": {
          "shape": "Sha"
        },
        "ParameterRanges": {
          "shape": "She"
        },
        "TrainingJobEarlyStoppingType": {},
        "TuningJobCompletionCriteria": {
          "type": "structure",
          "members": {
            "TargetObjectiveMetricValue": {
              "type": "float"
            },
            "BestObjectiveNotImproving": {
              "type": "structure",
              "members": {
                "MaxNumberOfTrainingJobsNotImproving": {
                  "type": "integer"
                }
              }
            },
            "ConvergenceDetected": {
              "type": "structure",
              "members": {
                "CompleteOnConvergence": {}
              }
            }
          }
        },
        "RandomSeed": {
          "type": "integer"
        }
      }
    },
    "Sha": {
      "type": "structure",
      "required": [
        "MaxParallelTrainingJobs"
      ],
      "members": {
        "MaxNumberOfTrainingJobs": {
          "type": "integer"
        },
        "MaxParallelTrainingJobs": {
          "type": "integer"
        },
        "MaxRuntimeInSeconds": {
          "type": "integer"
        }
      }
    },
    "She": {
      "type": "structure",
      "members": {
        "IntegerParameterRanges": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Name",
              "MinValue",
              "MaxValue"
            ],
            "members": {
              "Name": {},
              "MinValue": {},
              "MaxValue": {},
              "ScalingType": {}
            }
          }
        },
        "ContinuousParameterRanges": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Name",
              "MinValue",
              "MaxValue"
            ],
            "members": {
              "Name": {},
              "MinValue": {},
              "MaxValue": {},
              "ScalingType": {}
            }
          }
        },
        "CategoricalParameterRanges": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Name",
              "Values"
            ],
            "members": {
              "Name": {},
              "Values": {
                "shape": "S2g"
              }
            }
          }
        },
        "AutoParameters": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Name",
              "ValueHint"
            ],
            "members": {
              "Name": {},
              "ValueHint": {}
            }
          }
        }
      }
    },
    "Shx": {
      "type": "structure",
      "required": [
        "AlgorithmSpecification",
        "RoleArn",
        "OutputDataConfig",
        "StoppingCondition"
      ],
      "members": {
        "DefinitionName": {},
        "TuningObjective": {
          "shape": "S2w"
        },
        "HyperParameterRanges": {
          "shape": "She"
        },
        "StaticHyperParameters": {
          "shape": "S33"
        },
        "AlgorithmSpecification": {
          "type": "structure",
          "required": [
            "TrainingInputMode"
          ],
          "members": {
            "TrainingImage": {},
            "TrainingInputMode": {},
            "AlgorithmName": {},
            "MetricDefinitions": {
              "shape": "S2l"
            }
          }
        },
        "RoleArn": {},
        "InputDataConfig": {
          "shape": "S35"
        },
        "VpcConfig": {
          "shape": "S6a"
        },
        "OutputDataConfig": {
          "shape": "S3n"
        },
        "ResourceConfig": {
          "shape": "S3q"
        },
        "HyperParameterTuningResourceConfig": {
          "type": "structure",
          "members": {
            "InstanceType": {},
            "InstanceCount": {
              "type": "integer"
            },
            "VolumeSizeInGB": {
              "type": "integer"
            },
            "VolumeKmsKeyId": {},
            "AllocationStrategy": {},
            "InstanceConfigs": {
              "type": "list",
              "member": {
                "type": "structure",
                "required": [
                  "InstanceType",
                  "InstanceCount",
                  "VolumeSizeInGB"
                ],
                "members": {
                  "InstanceType": {},
                  "InstanceCount": {
                    "type": "integer"
                  },
                  "VolumeSizeInGB": {
                    "type": "integer"
                  }
                }
              }
            }
          }
        },
        "StoppingCondition": {
          "shape": "S3w"
        },
        "EnableNetworkIsolation": {
          "type": "boolean"
        },
        "EnableInterContainerTrafficEncryption": {
          "type": "boolean"
        },
        "EnableManagedSpotTraining": {
          "type": "boolean"
        },
        "CheckpointConfig": {
          "shape": "Si7"
        },
        "RetryStrategy": {
          "shape": "Si8"
        },
        "Environment": {
          "type": "map",
          "key": {},
          "value": {}
        }
      }
    },
    "Si7": {
      "type": "structure",
      "required": [
        "S3Uri"
      ],
      "members": {
        "S3Uri": {},
        "LocalPath": {}
      }
    },
    "Si8": {
      "type": "structure",
      "required": [
        "MaximumRetryAttempts"
      ],
      "members": {
        "MaximumRetryAttempts": {
          "type": "integer"
        }
      }
    },
    "Sid": {
      "type": "list",
      "member": {
        "shape": "Shx"
      }
    },
    "Sie": {
      "type": "structure",
      "required": [
        "ParentHyperParameterTuningJobs",
        "WarmStartType"
      ],
      "members": {
        "ParentHyperParameterTuningJobs": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "HyperParameterTuningJobName": {}
            }
          }
        },
        "WarmStartType": {}
      }
    },
    "Sii": {
      "type": "structure",
      "required": [
        "Mode"
      ],
      "members": {
        "Mode": {}
      }
    },
    "Sit": {
      "type": "list",
      "member": {}
    },
    "Sj5": {
      "type": "structure",
      "required": [
        "ComputeResourceRequirements"
      ],
      "members": {
        "ModelName": {},
        "Container": {
          "type": "structure",
          "members": {
            "Image": {},
            "ArtifactUrl": {},
            "Environment": {
              "shape": "S16"
            }
          }
        },
        "StartupParameters": {
          "shape": "Sj7"
        },
        "ComputeResourceRequirements": {
          "shape": "Sj8"
        }
      }
    },
    "Sj7": {
      "type": "structure",
      "members": {
        "ModelDataDownloadTimeoutInSeconds": {
          "type": "integer"
        },
        "ContainerStartupHealthCheckTimeoutInSeconds": {
          "type": "integer"
        }
      }
    },
    "Sj8": {
      "type": "structure",
      "required": [
        "MinMemoryRequiredInMb"
      ],
      "members": {
        "NumberOfCpuCoresRequired": {
          "type": "float"
        },
        "NumberOfAcceleratorDevicesRequired": {
          "type": "float"
        },
        "MinMemoryRequiredInMb": {
          "type": "integer"
        },
        "MaxMemoryRequiredInMb": {
          "type": "integer"
        }
      }
    },
    "Sjc": {
      "type": "structure",
      "required": [
        "CopyCount"
      ],
      "members": {
        "CopyCount": {
          "type": "integer"
        }
      }
    },
    "Sjj": {
      "type": "structure",
      "members": {
        "StartTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        }
      }
    },
    "Sjm": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "ModelName",
          "VariantName",
          "InfrastructureConfig"
        ],
        "members": {
          "ModelName": {},
          "VariantName": {},
          "InfrastructureConfig": {
            "shape": "Sjp"
          }
        }
      }
    },
    "Sjp": {
      "type": "structure",
      "required": [
        "InfrastructureType",
        "RealTimeInferenceConfig"
      ],
      "members": {
        "InfrastructureType": {},
        "RealTimeInferenceConfig": {
          "type": "structure",
          "required": [
            "InstanceType",
            "InstanceCount"
          ],
          "members": {
            "InstanceType": {},
            "InstanceCount": {
              "type": "integer"
            }
          }
        }
      }
    },
    "Sju": {
      "type": "structure",
      "required": [
        "Destination"
      ],
      "members": {
        "Destination": {},
        "KmsKey": {},
        "ContentType": {
          "shape": "Sdw"
        }
      }
    },
    "Sjv": {
      "type": "structure",
      "required": [
        "SourceModelVariantName",
        "ShadowModelVariants"
      ],
      "members": {
        "SourceModelVariantName": {},
        "ShadowModelVariants": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "ShadowModelVariantName",
              "SamplingPercentage"
            ],
            "members": {
              "ShadowModelVariantName": {},
              "SamplingPercentage": {
                "type": "integer"
              }
            }
          }
        }
      }
    },
    "Sk3": {
      "type": "structure",
      "members": {
        "ModelPackageVersionArn": {},
        "ModelName": {},
        "JobDurationInSeconds": {
          "type": "integer"
        },
        "TrafficPattern": {
          "type": "structure",
          "members": {
            "TrafficType": {},
            "Phases": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "InitialNumberOfUsers": {
                    "type": "integer"
                  },
                  "SpawnRate": {
                    "type": "integer"
                  },
                  "DurationInSeconds": {
                    "type": "integer"
                  }
                }
              }
            },
            "Stairs": {
              "type": "structure",
              "members": {
                "DurationInSeconds": {
                  "type": "integer"
                },
                "NumberOfSteps": {
                  "type": "integer"
                },
                "UsersPerStep": {
                  "type": "integer"
                }
              }
            }
          }
        },
        "ResourceLimit": {
          "type": "structure",
          "members": {
            "MaxNumberOfTests": {
              "type": "integer"
            },
            "MaxParallelOfTests": {
              "type": "integer"
            }
          }
        },
        "EndpointConfigurations": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "InstanceType": {},
              "ServerlessConfig": {
                "shape": "Sdc"
              },
              "InferenceSpecificationName": {},
              "EnvironmentParameterRanges": {
                "type": "structure",
                "members": {
                  "CategoricalParameterRanges": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "required": [
                        "Name",
                        "Value"
                      ],
                      "members": {
                        "Name": {},
                        "Value": {
                          "type": "list",
                          "member": {}
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        },
        "VolumeKmsKeyId": {},
        "ContainerConfig": {
          "type": "structure",
          "members": {
            "Domain": {},
            "Task": {},
            "Framework": {},
            "FrameworkVersion": {},
            "PayloadConfig": {
              "type": "structure",
              "members": {
                "SamplePayloadUrl": {},
                "SupportedContentTypes": {
                  "type": "list",
                  "member": {}
                }
              }
            },
            "NearestModelName": {},
            "SupportedInstanceTypes": {
              "type": "list",
              "member": {}
            },
            "SupportedEndpointType": {},
            "DataInputConfig": {},
            "SupportedResponseMIMETypes": {
              "type": "list",
              "member": {}
            }
          }
        },
        "Endpoints": {
          "type": "list",
          "member": {
            "shape": "Sl2"
          }
        },
        "VpcConfig": {
          "type": "structure",
          "required": [
            "SecurityGroupIds",
            "Subnets"
          ],
          "members": {
            "SecurityGroupIds": {
              "type": "list",
              "member": {}
            },
            "Subnets": {
              "type": "list",
              "member": {}
            }
          }
        }
      }
    },
    "Sl2": {
      "type": "structure",
      "members": {
        "EndpointName": {}
      }
    },
    "Sl9": {
      "type": "structure",
      "members": {
        "MaxInvocations": {
          "type": "integer"
        },
        "ModelLatencyThresholds": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Percentile": {},
              "ValueInMilliseconds": {
                "type": "integer"
              }
            }
          }
        },
        "FlatInvocations": {}
      }
    },
    "Sll": {
      "type": "structure",
      "required": [
        "DataSource"
      ],
      "members": {
        "DataSource": {
          "type": "structure",
          "members": {
            "S3DataSource": {
              "type": "structure",
              "required": [
                "ManifestS3Uri"
              ],
              "members": {
                "ManifestS3Uri": {}
              }
            },
            "SnsDataSource": {
              "type": "structure",
              "required": [
                "SnsTopicArn"
              ],
              "members": {
                "SnsTopicArn": {}
              }
            }
          }
        },
        "DataAttributes": {
          "type": "structure",
          "members": {
            "ContentClassifiers": {
              "type": "list",
              "member": {}
            }
          }
        }
      }
    },
    "Sls": {
      "type": "structure",
      "required": [
        "S3OutputPath"
      ],
      "members": {
        "S3OutputPath": {},
        "KmsKeyId": {},
        "SnsTopicArn": {}
      }
    },
    "Slt": {
      "type": "structure",
      "members": {
        "MaxHumanLabeledObjectCount": {
          "type": "integer"
        },
        "MaxPercentageOfInputDatasetLabeled": {
          "type": "integer"
        }
      }
    },
    "Slw": {
      "type": "structure",
      "required": [
        "LabelingJobAlgorithmSpecificationArn"
      ],
      "members": {
        "LabelingJobAlgorithmSpecificationArn": {},
        "InitialActiveLearningModelArn": {},
        "LabelingJobResourceConfig": {
          "type": "structure",
          "members": {
            "VolumeKmsKeyId": {},
            "VpcConfig": {
              "shape": "S6a"
            }
          }
        }
      }
    },
    "Sm0": {
      "type": "structure",
      "required": [
        "WorkteamArn",
        "UiConfig",
        "PreHumanTaskLambdaArn",
        "TaskTitle",
        "TaskDescription",
        "NumberOfHumanWorkersPerDataObject",
        "TaskTimeLimitInSeconds",
        "AnnotationConsolidationConfig"
      ],
      "members": {
        "WorkteamArn": {},
        "UiConfig": {
          "type": "structure",
          "members": {
            "UiTemplateS3Uri": {},
            "HumanTaskUiArn": {}
          }
        },
        "PreHumanTaskLambdaArn": {},
        "TaskKeywords": {
          "type": "list",
          "member": {}
        },
        "TaskTitle": {},
        "TaskDescription": {},
        "NumberOfHumanWorkersPerDataObject": {
          "type": "integer"
        },
        "TaskTimeLimitInSeconds": {
          "type": "integer"
        },
        "TaskAvailabilityLifetimeInSeconds": {
          "type": "integer"
        },
        "MaxConcurrentTaskCount": {
          "type": "integer"
        },
        "AnnotationConsolidationConfig": {
          "type": "structure",
          "required": [
            "AnnotationConsolidationLambdaArn"
          ],
          "members": {
            "AnnotationConsolidationLambdaArn": {}
          }
        },
        "PublicWorkforceTaskPrice": {
          "shape": "Sgf"
        }
      }
    },
    "Smf": {
      "type": "structure",
      "members": {
        "ContainerHostname": {},
        "Image": {},
        "ImageConfig": {
          "type": "structure",
          "required": [
            "RepositoryAccessMode"
          ],
          "members": {
            "RepositoryAccessMode": {},
            "RepositoryAuthConfig": {
              "type": "structure",
              "required": [
                "RepositoryCredentialsProviderArn"
              ],
              "members": {
                "RepositoryCredentialsProviderArn": {}
              }
            }
          }
        },
        "Mode": {},
        "ModelDataUrl": {},
        "ModelDataSource": {
          "shape": "Sy"
        },
        "Environment": {
          "shape": "S16"
        },
        "ModelPackageName": {},
        "InferenceSpecificationName": {},
        "MultiModelConfig": {
          "type": "structure",
          "members": {
            "ModelCacheSetting": {}
          }
        }
      }
    },
    "Smo": {
      "type": "list",
      "member": {
        "shape": "Smf"
      }
    },
    "Smp": {
      "type": "structure",
      "required": [
        "Mode"
      ],
      "members": {
        "Mode": {}
      }
    },
    "Smt": {
      "type": "structure",
      "members": {
        "BaseliningJobName": {},
        "ConstraintsResource": {
          "shape": "S94"
        }
      }
    },
    "Smu": {
      "type": "structure",
      "required": [
        "ImageUri",
        "ConfigUri"
      ],
      "members": {
        "ImageUri": {},
        "ConfigUri": {},
        "Environment": {
          "shape": "S9c"
        }
      }
    },
    "Smv": {
      "type": "structure",
      "required": [
        "GroundTruthS3Input"
      ],
      "members": {
        "EndpointInput": {
          "shape": "S9g"
        },
        "BatchTransformInput": {
          "shape": "S9n"
        },
        "GroundTruthS3Input": {
          "shape": "Smw"
        }
      }
    },
    "Smw": {
      "type": "structure",
      "members": {
        "S3Uri": {}
      }
    },
    "Smz": {
      "type": "structure",
      "members": {
        "KmsKeyId": {}
      }
    },
    "Sn0": {
      "type": "string",
      "sensitive": true
    },
    "Sn6": {
      "type": "structure",
      "required": [
        "S3OutputPath"
      ],
      "members": {
        "S3OutputPath": {}
      }
    },
    "Sna": {
      "type": "structure",
      "members": {
        "BaseliningJobName": {},
        "ConstraintsResource": {
          "shape": "S94"
        }
      }
    },
    "Snb": {
      "type": "structure",
      "required": [
        "ImageUri",
        "ConfigUri"
      ],
      "members": {
        "ImageUri": {},
        "ConfigUri": {},
        "Environment": {
          "shape": "S9c"
        }
      }
    },
    "Snc": {
      "type": "structure",
      "members": {
        "EndpointInput": {
          "shape": "S9g"
        },
        "BatchTransformInput": {
          "shape": "S9n"
        }
      }
    },
    "Snf": {
      "type": "structure",
      "required": [
        "ValidationRole",
        "ValidationProfiles"
      ],
      "members": {
        "ValidationRole": {},
        "ValidationProfiles": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "ProfileName",
              "TransformJobDefinition"
            ],
            "members": {
              "ProfileName": {},
              "TransformJobDefinition": {
                "shape": "S40"
              }
            }
          }
        }
      }
    },
    "Sni": {
      "type": "structure",
      "required": [
        "SourceAlgorithms"
      ],
      "members": {
        "SourceAlgorithms": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "AlgorithmName"
            ],
            "members": {
              "ModelDataUrl": {},
              "ModelDataSource": {
                "shape": "Sy"
              },
              "AlgorithmName": {}
            }
          }
        }
      }
    },
    "Snl": {
      "type": "structure",
      "members": {
        "ModelQuality": {
          "type": "structure",
          "members": {
            "Statistics": {
              "shape": "Snn"
            },
            "Constraints": {
              "shape": "Snn"
            }
          }
        },
        "ModelDataQuality": {
          "type": "structure",
          "members": {
            "Statistics": {
              "shape": "Snn"
            },
            "Constraints": {
              "shape": "Snn"
            }
          }
        },
        "Bias": {
          "type": "structure",
          "members": {
            "Report": {
              "shape": "Snn"
            },
            "PreTrainingReport": {
              "shape": "Snn"
            },
            "PostTrainingReport": {
              "shape": "Snn"
            }
          }
        },
        "Explainability": {
          "type": "structure",
          "members": {
            "Report": {
              "shape": "Snn"
            }
          }
        }
      }
    },
    "Snn": {
      "type": "structure",
      "required": [
        "ContentType",
        "S3Uri"
      ],
      "members": {
        "ContentType": {},
        "ContentDigest": {},
        "S3Uri": {}
      }
    },
    "Sns": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Snv": {
      "type": "structure",
      "members": {
        "Bias": {
          "type": "structure",
          "members": {
            "ConfigFile": {
              "shape": "Snx"
            },
            "PreTrainingConstraints": {
              "shape": "Snn"
            },
            "PostTrainingConstraints": {
              "shape": "Snn"
            }
          }
        },
        "Explainability": {
          "type": "structure",
          "members": {
            "Constraints": {
              "shape": "Snn"
            },
            "ConfigFile": {
              "shape": "Snx"
            }
          }
        },
        "ModelQuality": {
          "type": "structure",
          "members": {
            "Statistics": {
              "shape": "Snn"
            },
            "Constraints": {
              "shape": "Snn"
            }
          }
        },
        "ModelDataQuality": {
          "type": "structure",
          "members": {
            "Statistics": {
              "shape": "Snn"
            },
            "Constraints": {
              "shape": "Snn"
            }
          }
        }
      }
    },
    "Snx": {
      "type": "structure",
      "required": [
        "S3Uri"
      ],
      "members": {
        "ContentType": {},
        "ContentDigest": {},
        "S3Uri": {}
      }
    },
    "So1": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name",
          "Containers"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "Containers": {
            "shape": "Ss"
          },
          "SupportedTransformInstanceTypes": {
            "shape": "S1h"
          },
          "SupportedRealtimeInferenceInstanceTypes": {
            "shape": "S1j"
          },
          "SupportedContentTypes": {
            "shape": "S1l"
          },
          "SupportedResponseMIMETypes": {
            "shape": "S1n"
          }
        }
      }
    },
    "Soa": {
      "type": "structure",
      "members": {
        "BaseliningJobName": {},
        "ConstraintsResource": {
          "shape": "S94"
        }
      }
    },
    "Sob": {
      "type": "structure",
      "required": [
        "ImageUri"
      ],
      "members": {
        "ImageUri": {},
        "ContainerEntrypoint": {
          "shape": "S98"
        },
        "ContainerArguments": {
          "shape": "S9a"
        },
        "RecordPreprocessorSourceUri": {},
        "PostAnalyticsProcessorSourceUri": {},
        "ProblemType": {},
        "Environment": {
          "shape": "S9c"
        }
      }
    },
    "Sod": {
      "type": "structure",
      "required": [
        "GroundTruthS3Input"
      ],
      "members": {
        "EndpointInput": {
          "shape": "S9g"
        },
        "BatchTransformInput": {
          "shape": "S9n"
        },
        "GroundTruthS3Input": {
          "shape": "Smw"
        }
      }
    },
    "Soh": {
      "type": "structure",
      "members": {
        "ScheduleConfig": {
          "type": "structure",
          "required": [
            "ScheduleExpression"
          ],
          "members": {
            "ScheduleExpression": {},
            "DataAnalysisStartTime": {},
            "DataAnalysisEndTime": {}
          }
        },
        "MonitoringJobDefinition": {
          "type": "structure",
          "required": [
            "MonitoringInputs",
            "MonitoringOutputConfig",
            "MonitoringResources",
            "MonitoringAppSpecification",
            "RoleArn"
          ],
          "members": {
            "BaselineConfig": {
              "type": "structure",
              "members": {
                "BaseliningJobName": {},
                "ConstraintsResource": {
                  "shape": "S94"
                },
                "StatisticsResource": {
                  "shape": "S95"
                }
              }
            },
            "MonitoringInputs": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "EndpointInput": {
                    "shape": "S9g"
                  },
                  "BatchTransformInput": {
                    "shape": "S9n"
                  }
                }
              }
            },
            "MonitoringOutputConfig": {
              "shape": "S9t"
            },
            "MonitoringResources": {
              "shape": "S9z"
            },
            "MonitoringAppSpecification": {
              "type": "structure",
              "required": [
                "ImageUri"
              ],
              "members": {
                "ImageUri": {},
                "ContainerEntrypoint": {
                  "shape": "S98"
                },
                "ContainerArguments": {
                  "shape": "S9a"
                },
                "RecordPreprocessorSourceUri": {},
                "PostAnalyticsProcessorSourceUri": {}
              }
            },
            "StoppingCondition": {
              "shape": "Sa5"
            },
            "Environment": {
              "shape": "S9c"
            },
            "NetworkConfig": {
              "shape": "Sop"
            },
            "RoleArn": {}
          }
        },
        "MonitoringJobDefinitionName": {},
        "MonitoringType": {}
      }
    },
    "Sop": {
      "type": "structure",
      "members": {
        "EnableInterContainerTrafficEncryption": {
          "type": "boolean"
        },
        "EnableNetworkIsolation": {
          "type": "boolean"
        },
        "VpcConfig": {
          "shape": "S6a"
        }
      }
    },
    "Soy": {
      "type": "list",
      "member": {}
    },
    "Sp1": {
      "type": "list",
      "member": {}
    },
    "Sp4": {
      "type": "structure",
      "required": [
        "MinimumInstanceMetadataServiceVersion"
      ],
      "members": {
        "MinimumInstanceMetadataServiceVersion": {}
      }
    },
    "Sp9": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Content": {}
        }
      }
    },
    "Sph": {
      "type": "structure",
      "required": [
        "Bucket",
        "ObjectKey"
      ],
      "members": {
        "Bucket": {},
        "ObjectKey": {},
        "VersionId": {}
      }
    },
    "Spn": {
      "type": "structure",
      "required": [
        "MaxParallelExecutionSteps"
      ],
      "members": {
        "MaxParallelExecutionSteps": {
          "type": "integer"
        }
      }
    },
    "Sq0": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "InputName"
        ],
        "members": {
          "InputName": {},
          "AppManaged": {
            "type": "boolean"
          },
          "S3Input": {
            "type": "structure",
            "required": [
              "S3Uri",
              "S3DataType"
            ],
            "members": {
              "S3Uri": {},
              "LocalPath": {},
              "S3DataType": {},
              "S3InputMode": {},
              "S3DataDistributionType": {},
              "S3CompressionType": {}
            }
          },
          "DatasetDefinition": {
            "type": "structure",
            "members": {
              "AthenaDatasetDefinition": {
                "type": "structure",
                "required": [
                  "Catalog",
                  "Database",
                  "QueryString",
                  "OutputS3Uri",
                  "OutputFormat"
                ],
                "members": {
                  "Catalog": {},
                  "Database": {},
                  "QueryString": {},
                  "WorkGroup": {},
                  "OutputS3Uri": {},
                  "KmsKeyId": {},
                  "OutputFormat": {},
                  "OutputCompression": {}
                }
              },
              "RedshiftDatasetDefinition": {
                "type": "structure",
                "required": [
                  "ClusterId",
                  "Database",
                  "DbUser",
                  "QueryString",
                  "ClusterRoleArn",
                  "OutputS3Uri",
                  "OutputFormat"
                ],
                "members": {
                  "ClusterId": {},
                  "Database": {},
                  "DbUser": {},
                  "QueryString": {},
                  "ClusterRoleArn": {},
                  "OutputS3Uri": {},
                  "KmsKeyId": {},
                  "OutputFormat": {},
                  "OutputCompression": {}
                }
              },
              "LocalPath": {},
              "DataDistributionType": {},
              "InputMode": {}
            }
          }
        }
      }
    },
    "Sqn": {
      "type": "structure",
      "required": [
        "Outputs"
      ],
      "members": {
        "Outputs": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "OutputName"
            ],
            "members": {
              "OutputName": {},
              "S3Output": {
                "type": "structure",
                "required": [
                  "S3Uri",
                  "LocalPath",
                  "S3UploadMode"
                ],
                "members": {
                  "S3Uri": {},
                  "LocalPath": {},
                  "S3UploadMode": {}
                }
              },
              "FeatureStoreOutput": {
                "type": "structure",
                "required": [
                  "FeatureGroupName"
                ],
                "members": {
                  "FeatureGroupName": {}
                }
              },
              "AppManaged": {
                "type": "boolean"
              }
            }
          }
        },
        "KmsKeyId": {}
      }
    },
    "Sqs": {
      "type": "structure",
      "required": [
        "ClusterConfig"
      ],
      "members": {
        "ClusterConfig": {
          "type": "structure",
          "required": [
            "InstanceCount",
            "InstanceType",
            "VolumeSizeInGB"
          ],
          "members": {
            "InstanceCount": {
              "type": "integer"
            },
            "InstanceType": {},
            "VolumeSizeInGB": {
              "type": "integer"
            },
            "VolumeKmsKeyId": {}
          }
        }
      }
    },
    "Squ": {
      "type": "structure",
      "required": [
        "MaxRuntimeInSeconds"
      ],
      "members": {
        "MaxRuntimeInSeconds": {
          "type": "integer"
        }
      }
    },
    "Sqw": {
      "type": "structure",
      "required": [
        "ImageUri"
      ],
      "members": {
        "ImageUri": {},
        "ContainerEntrypoint": {
          "shape": "S98"
        },
        "ContainerArguments": {
          "type": "list",
          "member": {}
        }
      }
    },
    "Sqy": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sqz": {
      "type": "structure",
      "members": {
        "ExperimentName": {},
        "TrialName": {},
        "TrialComponentDisplayName": {},
        "RunName": {}
      }
    },
    "Sr4": {
      "type": "structure",
      "required": [
        "ProductId"
      ],
      "members": {
        "ProductId": {},
        "ProvisioningArtifactId": {},
        "PathId": {},
        "ProvisioningParameters": {
          "shape": "Sr6"
        }
      }
    },
    "Sr6": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sre": {
      "type": "structure",
      "members": {
        "JupyterServerAppSettings": {
          "shape": "Sal"
        },
        "KernelGatewayAppSettings": {
          "shape": "Saq"
        },
        "CodeEditorAppSettings": {
          "type": "structure",
          "members": {
            "DefaultResourceSpec": {
              "shape": "S4p"
            }
          }
        },
        "JupyterLabAppSettings": {
          "type": "structure",
          "members": {
            "DefaultResourceSpec": {
              "shape": "S4p"
            },
            "CodeRepositories": {
              "shape": "San"
            }
          }
        },
        "AppType": {},
        "SpaceStorageSettings": {
          "shape": "Srh"
        },
        "CustomFileSystems": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "EFSFileSystem": {
                "type": "structure",
                "required": [
                  "FileSystemId"
                ],
                "members": {
                  "FileSystemId": {}
                }
              }
            },
            "union": true
          }
        }
      }
    },
    "Srh": {
      "type": "structure",
      "members": {
        "EbsStorageSettings": {
          "type": "structure",
          "required": [
            "EbsVolumeSizeInGb"
          ],
          "members": {
            "EbsVolumeSizeInGb": {
              "type": "integer"
            }
          }
        }
      }
    },
    "Srm": {
      "type": "structure",
      "required": [
        "OwnerUserProfileName"
      ],
      "members": {
        "OwnerUserProfileName": {}
      }
    },
    "Srn": {
      "type": "structure",
      "required": [
        "SharingType"
      ],
      "members": {
        "SharingType": {}
      }
    },
    "Sry": {
      "type": "structure",
      "required": [
        "TrainingInputMode"
      ],
      "members": {
        "TrainingImage": {},
        "AlgorithmName": {},
        "TrainingInputMode": {},
        "MetricDefinitions": {
          "shape": "S2l"
        },
        "EnableSageMakerMetricsTimeSeries": {
          "type": "boolean"
        },
        "ContainerEntrypoint": {
          "type": "list",
          "member": {}
        },
        "ContainerArguments": {
          "type": "list",
          "member": {}
        },
        "TrainingImageConfig": {
          "type": "structure",
          "required": [
            "TrainingRepositoryAccessMode"
          ],
          "members": {
            "TrainingRepositoryAccessMode": {},
            "TrainingRepositoryAuthConfig": {
              "type": "structure",
              "required": [
                "TrainingRepositoryCredentialsProviderArn"
              ],
              "members": {
                "TrainingRepositoryCredentialsProviderArn": {}
              }
            }
          }
        }
      }
    },
    "Ss7": {
      "type": "structure",
      "required": [
        "S3OutputPath"
      ],
      "members": {
        "LocalPath": {},
        "S3OutputPath": {},
        "HookParameters": {
          "type": "map",
          "key": {},
          "value": {}
        },
        "CollectionConfigurations": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "CollectionName": {},
              "CollectionParameters": {
                "type": "map",
                "key": {},
                "value": {}
              }
            }
          }
        }
      }
    },
    "Ssf": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "RuleConfigurationName",
          "RuleEvaluatorImage"
        ],
        "members": {
          "RuleConfigurationName": {},
          "LocalPath": {},
          "S3OutputPath": {},
          "RuleEvaluatorImage": {},
          "InstanceType": {},
          "VolumeSizeInGB": {
            "type": "integer"
          },
          "RuleParameters": {
            "shape": "Ssi"
          }
        }
      }
    },
    "Ssi": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Ssj": {
      "type": "structure",
      "required": [
        "S3OutputPath"
      ],
      "members": {
        "LocalPath": {},
        "S3OutputPath": {}
      }
    },
    "Ssk": {
      "type": "structure",
      "members": {
        "S3OutputPath": {},
        "ProfilingIntervalInMilliseconds": {
          "type": "long"
        },
        "ProfilingParameters": {
          "shape": "Ssm"
        },
        "DisableProfiler": {
          "type": "boolean"
        }
      }
    },
    "Ssm": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sso": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "RuleConfigurationName",
          "RuleEvaluatorImage"
        ],
        "members": {
          "RuleConfigurationName": {},
          "LocalPath": {},
          "S3OutputPath": {},
          "RuleEvaluatorImage": {},
          "InstanceType": {},
          "VolumeSizeInGB": {
            "type": "integer"
          },
          "RuleParameters": {
            "shape": "Ssi"
          }
        }
      }
    },
    "Ssq": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sst": {
      "type": "structure",
      "members": {
        "EnableRemoteDebug": {
          "type": "boolean"
        }
      }
    },
    "Ssv": {
      "type": "structure",
      "members": {
        "EnableInfraCheck": {
          "type": "boolean"
        }
      }
    },
    "St1": {
      "type": "structure",
      "members": {
        "InvocationsTimeoutInSeconds": {
          "type": "integer"
        },
        "InvocationsMaxRetries": {
          "type": "integer"
        }
      }
    },
    "St4": {
      "type": "structure",
      "required": [
        "DestinationS3Uri"
      ],
      "members": {
        "DestinationS3Uri": {},
        "KmsKeyId": {},
        "GenerateInferenceId": {
          "type": "boolean"
        }
      }
    },
    "St5": {
      "type": "structure",
      "members": {
        "InputFilter": {},
        "OutputFilter": {},
        "JoinSource": {}
      }
    },
    "Std": {
      "type": "structure",
      "members": {
        "PrimaryStatus": {},
        "Message": {}
      }
    },
    "Stg": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "StringValue": {},
          "NumberValue": {
            "type": "double"
          }
        }
      }
    },
    "Stk": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "required": [
          "Value"
        ],
        "members": {
          "MediaType": {},
          "Value": {}
        }
      }
    },
    "Stv": {
      "type": "structure",
      "required": [
        "UserPool",
        "ClientId"
      ],
      "members": {
        "UserPool": {},
        "ClientId": {}
      }
    },
    "Sty": {
      "type": "structure",
      "required": [
        "ClientId",
        "ClientSecret",
        "Issuer",
        "AuthorizationEndpoint",
        "TokenEndpoint",
        "UserInfoEndpoint",
        "LogoutEndpoint",
        "JwksUri"
      ],
      "members": {
        "ClientId": {},
        "ClientSecret": {
          "type": "string",
          "sensitive": true
        },
        "Issuer": {},
        "AuthorizationEndpoint": {},
        "TokenEndpoint": {},
        "UserInfoEndpoint": {},
        "LogoutEndpoint": {},
        "JwksUri": {}
      }
    },
    "Su1": {
      "type": "structure",
      "required": [
        "Cidrs"
      ],
      "members": {
        "Cidrs": {
          "type": "list",
          "member": {}
        }
      }
    },
    "Su5": {
      "type": "structure",
      "members": {
        "VpcId": {},
        "SecurityGroupIds": {
          "shape": "Su7"
        },
        "Subnets": {
          "shape": "Su9"
        }
      }
    },
    "Su7": {
      "type": "list",
      "member": {}
    },
    "Su9": {
      "type": "list",
      "member": {}
    },
    "Suf": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "CognitoMemberDefinition": {
            "type": "structure",
            "required": [
              "UserPool",
              "UserGroup",
              "ClientId"
            ],
            "members": {
              "UserPool": {},
              "UserGroup": {},
              "ClientId": {}
            }
          },
          "OidcMemberDefinition": {
            "type": "structure",
            "members": {
              "Groups": {
                "type": "list",
                "member": {}
              }
            }
          }
        }
      }
    },
    "Sun": {
      "type": "structure",
      "members": {
        "NotificationTopicArn": {}
      }
    },
    "Sww": {
      "type": "structure",
      "members": {
        "UserProfileArn": {},
        "UserProfileName": {},
        "DomainId": {},
        "IamIdentity": {
          "type": "structure",
          "members": {
            "Arn": {},
            "PrincipalId": {},
            "SourceIdentity": {}
          }
        }
      }
    },
    "Sx3": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name",
          "Status"
        ],
        "members": {
          "Name": {},
          "Status": {},
          "FailureReason": {}
        }
      }
    },
    "Sxh": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "PartialFailureMessage": {}
        }
      }
    },
    "Sxj": {
      "type": "structure",
      "required": [
        "CandidateName",
        "ObjectiveStatus",
        "CandidateSteps",
        "CandidateStatus",
        "CreationTime",
        "LastModifiedTime"
      ],
      "members": {
        "CandidateName": {},
        "FinalAutoMLJobObjectiveMetric": {
          "type": "structure",
          "required": [
            "MetricName",
            "Value"
          ],
          "members": {
            "Type": {},
            "MetricName": {},
            "Value": {
              "type": "float"
            },
            "StandardMetricName": {}
          }
        },
        "ObjectiveStatus": {},
        "CandidateSteps": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "CandidateStepType",
              "CandidateStepArn",
              "CandidateStepName"
            ],
            "members": {
              "CandidateStepType": {},
              "CandidateStepArn": {},
              "CandidateStepName": {}
            }
          }
        },
        "CandidateStatus": {},
        "InferenceContainers": {
          "shape": "Sxv"
        },
        "CreationTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "LastModifiedTime": {
          "type": "timestamp"
        },
        "FailureReason": {},
        "CandidateProperties": {
          "type": "structure",
          "members": {
            "CandidateArtifactLocations": {
              "type": "structure",
              "required": [
                "Explainability"
              ],
              "members": {
                "Explainability": {},
                "ModelInsights": {},
                "BacktestResults": {}
              }
            },
            "CandidateMetrics": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "MetricName": {},
                  "Value": {
                    "type": "float"
                  },
                  "Set": {},
                  "StandardMetricName": {}
                }
              }
            }
          }
        },
        "InferenceContainerDefinitions": {
          "type": "map",
          "key": {},
          "value": {
            "shape": "Sxv"
          }
        }
      }
    },
    "Sxv": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Image",
          "ModelDataUrl"
        ],
        "members": {
          "Image": {},
          "ModelDataUrl": {},
          "Environment": {
            "shape": "S16"
          }
        }
      }
    },
    "Syb": {
      "type": "structure",
      "members": {
        "CandidateDefinitionNotebookLocation": {},
        "DataExplorationNotebookLocation": {}
      }
    },
    "Syf": {
      "type": "structure",
      "members": {
        "EndpointName": {}
      }
    },
    "Syx": {
      "type": "structure",
      "required": [
        "Status"
      ],
      "members": {
        "Status": {},
        "Message": {}
      }
    },
    "Sz6": {
      "type": "structure",
      "required": [
        "S3ModelArtifacts"
      ],
      "members": {
        "S3ModelArtifacts": {}
      }
    },
    "S10c": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "VariantName"
        ],
        "members": {
          "VariantName": {},
          "DeployedImages": {
            "shape": "S10e"
          },
          "CurrentWeight": {
            "type": "float"
          },
          "DesiredWeight": {
            "type": "float"
          },
          "CurrentInstanceCount": {
            "type": "integer"
          },
          "DesiredInstanceCount": {
            "type": "integer"
          },
          "VariantStatus": {
            "shape": "S10g"
          },
          "CurrentServerlessConfig": {
            "shape": "Sdc"
          },
          "DesiredServerlessConfig": {
            "shape": "Sdc"
          },
          "ManagedInstanceScaling": {
            "shape": "Sdk"
          },
          "RoutingConfig": {
            "shape": "Sdo"
          }
        }
      }
    },
    "S10e": {
      "type": "list",
      "member": {
        "shape": "S10f"
      }
    },
    "S10f": {
      "type": "structure",
      "members": {
        "SpecifiedImage": {},
        "ResolvedImage": {},
        "ResolutionTime": {
          "type": "timestamp"
        }
      }
    },
    "S10g": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Status"
        ],
        "members": {
          "Status": {},
          "StatusMessage": {},
          "StartTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "S10k": {
      "type": "structure",
      "required": [
        "EnableCapture",
        "CaptureStatus",
        "CurrentSamplingPercentage",
        "DestinationS3Uri",
        "KmsKeyId"
      ],
      "members": {
        "EnableCapture": {
          "type": "boolean"
        },
        "CaptureStatus": {},
        "CurrentSamplingPercentage": {
          "type": "integer"
        },
        "DestinationS3Uri": {},
        "KmsKeyId": {}
      }
    },
    "S10o": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "VariantName"
        ],
        "members": {
          "VariantName": {},
          "DeployedImages": {
            "shape": "S10e"
          },
          "CurrentWeight": {
            "type": "float"
          },
          "DesiredWeight": {
            "type": "float"
          },
          "CurrentInstanceCount": {
            "type": "integer"
          },
          "DesiredInstanceCount": {
            "type": "integer"
          },
          "InstanceType": {},
          "AcceleratorType": {},
          "VariantStatus": {
            "shape": "S10g"
          },
          "CurrentServerlessConfig": {
            "shape": "Sdc"
          },
          "DesiredServerlessConfig": {
            "shape": "Sdc"
          },
          "ManagedInstanceScaling": {
            "shape": "Sdk"
          },
          "RoutingConfig": {
            "shape": "Sdo"
          }
        }
      }
    },
    "S10u": {
      "type": "structure",
      "required": [
        "SourceArn"
      ],
      "members": {
        "SourceArn": {},
        "SourceType": {}
      }
    },
    "S112": {
      "type": "structure",
      "required": [
        "Status"
      ],
      "members": {
        "Status": {},
        "BlockedReason": {}
      }
    },
    "S115": {
      "type": "structure",
      "required": [
        "Status"
      ],
      "members": {
        "Status": {},
        "FailureReason": {}
      }
    },
    "S11b": {
      "type": "list",
      "member": {
        "shape": "S11c"
      }
    },
    "S11c": {
      "type": "structure",
      "members": {
        "Key": {},
        "Value": {}
      }
    },
    "S11t": {
      "type": "list",
      "member": {}
    },
    "S128": {
      "type": "structure",
      "members": {
        "Completed": {
          "type": "integer"
        },
        "InProgress": {
          "type": "integer"
        },
        "RetryableError": {
          "type": "integer"
        },
        "NonRetryableError": {
          "type": "integer"
        },
        "Stopped": {
          "type": "integer"
        }
      }
    },
    "S12a": {
      "type": "structure",
      "members": {
        "Succeeded": {
          "type": "integer"
        },
        "Pending": {
          "type": "integer"
        },
        "Failed": {
          "type": "integer"
        }
      }
    },
    "S12c": {
      "type": "structure",
      "required": [
        "TrainingJobName",
        "TrainingJobArn",
        "CreationTime",
        "TrainingJobStatus",
        "TunedHyperParameters"
      ],
      "members": {
        "TrainingJobDefinitionName": {},
        "TrainingJobName": {},
        "TrainingJobArn": {},
        "TuningJobName": {},
        "CreationTime": {
          "type": "timestamp"
        },
        "TrainingStartTime": {
          "type": "timestamp"
        },
        "TrainingEndTime": {
          "type": "timestamp"
        },
        "TrainingJobStatus": {},
        "TunedHyperParameters": {
          "shape": "S33"
        },
        "FailureReason": {},
        "FinalHyperParameterTuningJobObjectiveMetric": {
          "type": "structure",
          "required": [
            "MetricName",
            "Value"
          ],
          "members": {
            "Type": {},
            "MetricName": {},
            "Value": {
              "type": "float"
            }
          }
        },
        "ObjectiveStatus": {}
      }
    },
    "S12f": {
      "type": "structure",
      "members": {
        "NumberOfTrainingJobsObjectiveNotImproving": {
          "type": "integer"
        },
        "ConvergenceDetectedTime": {
          "type": "timestamp"
        }
      }
    },
    "S12g": {
      "type": "structure",
      "members": {
        "RuntimeInSeconds": {
          "type": "integer"
        }
      }
    },
    "S137": {
      "type": "structure",
      "required": [
        "CostPerHour",
        "CostPerInference",
        "MaxInvocations",
        "ModelLatency"
      ],
      "members": {
        "CostPerHour": {
          "type": "float"
        },
        "CostPerInference": {
          "type": "float"
        },
        "MaxInvocations": {
          "type": "integer"
        },
        "ModelLatency": {
          "type": "integer"
        },
        "CpuUtilization": {
          "type": "float"
        },
        "MemoryUtilization": {
          "type": "float"
        },
        "ModelSetupTime": {
          "type": "integer"
        }
      }
    },
    "S13a": {
      "type": "structure",
      "required": [
        "EndpointName",
        "VariantName"
      ],
      "members": {
        "EndpointName": {},
        "VariantName": {},
        "InstanceType": {},
        "InitialInstanceCount": {
          "type": "integer"
        },
        "ServerlessConfig": {
          "shape": "Sdc"
        }
      }
    },
    "S13c": {
      "type": "structure",
      "members": {
        "InferenceSpecificationName": {},
        "EnvironmentParameters": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Key",
              "ValueType",
              "Value"
            ],
            "members": {
              "Key": {},
              "ValueType": {},
              "Value": {}
            }
          }
        },
        "CompilationJobName": {}
      }
    },
    "S13k": {
      "type": "structure",
      "required": [
        "MaxInvocations",
        "ModelLatency"
      ],
      "members": {
        "MaxInvocations": {
          "type": "integer"
        },
        "ModelLatency": {
          "type": "integer"
        }
      }
    },
    "S13o": {
      "type": "structure",
      "members": {
        "TotalLabeled": {
          "type": "integer"
        },
        "HumanLabeled": {
          "type": "integer"
        },
        "MachineLabeled": {
          "type": "integer"
        },
        "FailedNonRetryableError": {
          "type": "integer"
        },
        "Unlabeled": {
          "type": "integer"
        }
      }
    },
    "S13r": {
      "type": "structure",
      "required": [
        "OutputDatasetS3Uri"
      ],
      "members": {
        "OutputDatasetS3Uri": {},
        "FinalActiveLearningModelArn": {}
      }
    },
    "S13w": {
      "type": "structure",
      "required": [
        "RecommendationStatus"
      ],
      "members": {
        "RecommendationStatus": {},
        "RealTimeInferenceRecommendations": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "RecommendationId",
              "InstanceType"
            ],
            "members": {
              "RecommendationId": {},
              "InstanceType": {},
              "Environment": {
                "shape": "S16"
              }
            }
          }
        }
      }
    },
    "S14d": {
      "type": "structure",
      "required": [
        "ValidationStatuses"
      ],
      "members": {
        "ValidationStatuses": {
          "shape": "S14e"
        },
        "ImageScanStatuses": {
          "shape": "S14e"
        }
      }
    },
    "S14e": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name",
          "Status"
        ],
        "members": {
          "Name": {},
          "Status": {},
          "FailureReason": {}
        }
      }
    },
    "S14q": {
      "type": "structure",
      "required": [
        "MonitoringScheduleName",
        "ScheduledTime",
        "CreationTime",
        "LastModifiedTime",
        "MonitoringExecutionStatus"
      ],
      "members": {
        "MonitoringScheduleName": {},
        "ScheduledTime": {
          "type": "timestamp"
        },
        "CreationTime": {
          "type": "timestamp"
        },
        "LastModifiedTime": {
          "type": "timestamp"
        },
        "MonitoringExecutionStatus": {},
        "ProcessingJobArn": {},
        "EndpointName": {},
        "FailureReason": {},
        "MonitoringJobDefinitionName": {},
        "MonitoringType": {}
      }
    },
    "S15a": {
      "type": "structure",
      "members": {
        "ExperimentName": {},
        "TrialName": {}
      }
    },
    "S15c": {
      "type": "structure",
      "required": [
        "SelectedSteps"
      ],
      "members": {
        "SourcePipelineExecutionArn": {},
        "SelectedSteps": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "StepName"
            ],
            "members": {
              "StepName": {}
            }
          }
        }
      }
    },
    "S15l": {
      "type": "structure",
      "members": {
        "ProvisionedProductId": {},
        "ProvisionedProductStatusMessage": {}
      }
    },
    "S15w": {
      "type": "structure",
      "required": [
        "WorkteamArn"
      ],
      "members": {
        "WorkteamArn": {},
        "MarketplaceTitle": {},
        "SellerName": {},
        "MarketplaceDescription": {},
        "ListingId": {}
      }
    },
    "S160": {
      "type": "structure",
      "required": [
        "Status"
      ],
      "members": {
        "Status": {},
        "ResourceRetainedBillableTimeInSeconds": {
          "type": "integer"
        },
        "ReusedByJob": {}
      }
    },
    "S163": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Status",
          "StartTime"
        ],
        "members": {
          "Status": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "StatusMessage": {}
        }
      }
    },
    "S166": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "MetricName": {},
          "Value": {
            "type": "float"
          },
          "Timestamp": {
            "type": "timestamp"
          }
        }
      }
    },
    "S16a": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "RuleConfigurationName": {},
          "RuleEvaluationJobArn": {},
          "RuleEvaluationStatus": {},
          "StatusDetails": {},
          "LastModifiedTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "S16m": {
      "type": "structure",
      "required": [
        "SourceArn"
      ],
      "members": {
        "SourceArn": {},
        "SourceType": {}
      }
    },
    "S16q": {
      "type": "structure",
      "required": [
        "SourceArn"
      ],
      "members": {
        "SourceArn": {},
        "SourceType": {}
      }
    },
    "S16s": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "MetricName": {},
          "SourceArn": {},
          "TimeStamp": {
            "type": "timestamp"
          },
          "Max": {
            "type": "double"
          },
          "Min": {
            "type": "double"
          },
          "Last": {
            "type": "double"
          },
          "Count": {
            "type": "integer"
          },
          "Avg": {
            "type": "double"
          },
          "StdDev": {
            "type": "double"
          }
        }
      }
    },
    "S172": {
      "type": "structure",
      "required": [
        "WorkforceName",
        "WorkforceArn"
      ],
      "members": {
        "WorkforceName": {},
        "WorkforceArn": {},
        "LastUpdatedDate": {
          "type": "timestamp"
        },
        "SourceIpConfig": {
          "shape": "Su1"
        },
        "SubDomain": {},
        "CognitoConfig": {
          "shape": "Stv"
        },
        "OidcConfig": {
          "type": "structure",
          "members": {
            "ClientId": {},
            "Issuer": {},
            "AuthorizationEndpoint": {},
            "TokenEndpoint": {},
            "UserInfoEndpoint": {},
            "LogoutEndpoint": {},
            "JwksUri": {}
          }
        },
        "CreateDate": {
          "type": "timestamp"
        },
        "WorkforceVpcConfig": {
          "type": "structure",
          "required": [
            "VpcId",
            "SecurityGroupIds",
            "Subnets"
          ],
          "members": {
            "VpcId": {},
            "SecurityGroupIds": {
              "shape": "Su7"
            },
            "Subnets": {
              "shape": "Su9"
            },
            "VpcEndpointId": {}
          }
        },
        "Status": {},
        "FailureReason": {}
      }
    },
    "S17a": {
      "type": "structure",
      "required": [
        "WorkteamName",
        "MemberDefinitions",
        "WorkteamArn",
        "Description"
      ],
      "members": {
        "WorkteamName": {},
        "MemberDefinitions": {
          "shape": "Suf"
        },
        "WorkteamArn": {},
        "WorkforceArn": {},
        "ProductListingIds": {
          "type": "list",
          "member": {}
        },
        "Description": {},
        "SubDomain": {},
        "CreateDate": {
          "type": "timestamp"
        },
        "LastUpdatedDate": {
          "type": "timestamp"
        },
        "NotificationConfiguration": {
          "shape": "Sun"
        }
      }
    },
    "S182": {
      "type": "structure",
      "members": {
        "MinInvocationsPerMinute": {
          "type": "integer"
        },
        "MaxInvocationsPerMinute": {
          "type": "integer"
        }
      }
    },
    "S1au": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "MonitoringJobDefinitionName",
          "MonitoringJobDefinitionArn",
          "CreationTime",
          "EndpointName"
        ],
        "members": {
          "MonitoringJobDefinitionName": {},
          "MonitoringJobDefinitionArn": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "EndpointName": {}
        }
      }
    },
    "S1ck": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "HubContentName",
          "HubContentArn",
          "HubContentVersion",
          "HubContentType",
          "DocumentSchemaVersion",
          "HubContentStatus",
          "CreationTime"
        ],
        "members": {
          "HubContentName": {},
          "HubContentArn": {},
          "HubContentVersion": {},
          "HubContentType": {},
          "DocumentSchemaVersion": {},
          "HubContentDisplayName": {},
          "HubContentDescription": {},
          "HubContentSearchKeywords": {
            "shape": "S11t"
          },
          "HubContentStatus": {},
          "CreationTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "S1g4": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "MonitoringAlertName",
          "CreationTime",
          "LastModifiedTime",
          "AlertStatus",
          "DatapointsToAlert",
          "EvaluationPeriod",
          "Actions"
        ],
        "members": {
          "MonitoringAlertName": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "AlertStatus": {},
          "DatapointsToAlert": {
            "type": "integer"
          },
          "EvaluationPeriod": {
            "type": "integer"
          },
          "Actions": {
            "type": "structure",
            "members": {
              "ModelDashboardIndicator": {
                "type": "structure",
                "members": {
                  "Enabled": {
                    "type": "boolean"
                  }
                }
              }
            }
          }
        }
      }
    },
    "S1hi": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name",
          "Value"
        ],
        "members": {
          "Name": {},
          "Value": {}
        }
      }
    },
    "S1hz": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name",
          "Value"
        ],
        "members": {
          "Name": {},
          "Value": {}
        }
      }
    },
    "S1kx": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "DeviceName"
        ],
        "members": {
          "DeviceName": {},
          "Description": {},
          "IotThingName": {}
        }
      }
    },
    "S1l8": {
      "type": "structure",
      "members": {
        "Filters": {
          "shape": "S1l9"
        },
        "NestedFilters": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "NestedPropertyName",
              "Filters"
            ],
            "members": {
              "NestedPropertyName": {},
              "Filters": {
                "shape": "S1l9"
              }
            }
          }
        },
        "SubExpressions": {
          "type": "list",
          "member": {
            "shape": "S1l8"
          }
        },
        "Operator": {}
      }
    },
    "S1l9": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "Operator": {},
          "Value": {}
        }
      }
    },
    "S1lq": {
      "type": "structure",
      "members": {
        "TrainingJobName": {},
        "TrainingJobArn": {},
        "TuningJobArn": {},
        "LabelingJobArn": {},
        "AutoMLJobArn": {},
        "ModelArtifacts": {
          "shape": "Sz6"
        },
        "TrainingJobStatus": {},
        "SecondaryStatus": {},
        "FailureReason": {},
        "HyperParameters": {
          "shape": "S33"
        },
        "AlgorithmSpecification": {
          "shape": "Sry"
        },
        "RoleArn": {},
        "InputDataConfig": {
          "shape": "S35"
        },
        "OutputDataConfig": {
          "shape": "S3n"
        },
        "ResourceConfig": {
          "shape": "S3q"
        },
        "VpcConfig": {
          "shape": "S6a"
        },
        "StoppingCondition": {
          "shape": "S3w"
        },
        "CreationTime": {
          "type": "timestamp"
        },
        "TrainingStartTime": {
          "type": "timestamp"
        },
        "TrainingEndTime": {
          "type": "timestamp"
        },
        "LastModifiedTime": {
          "type": "timestamp"
        },
        "SecondaryStatusTransitions": {
          "shape": "S163"
        },
        "FinalMetricDataList": {
          "shape": "S166"
        },
        "EnableNetworkIsolation": {
          "type": "boolean"
        },
        "EnableInterContainerTrafficEncryption": {
          "type": "boolean"
        },
        "EnableManagedSpotTraining": {
          "type": "boolean"
        },
        "CheckpointConfig": {
          "shape": "Si7"
        },
        "TrainingTimeInSeconds": {
          "type": "integer"
        },
        "BillableTimeInSeconds": {
          "type": "integer"
        },
        "DebugHookConfig": {
          "shape": "Ss7"
        },
        "ExperimentConfig": {
          "shape": "Sqz"
        },
        "DebugRuleConfigurations": {
          "shape": "Ssf"
        },
        "TensorBoardOutputConfig": {
          "shape": "Ssj"
        },
        "DebugRuleEvaluationStatuses": {
          "shape": "S16a"
        },
        "ProfilerConfig": {
          "shape": "Ssk"
        },
        "Environment": {
          "shape": "Ssq"
        },
        "RetryStrategy": {
          "shape": "Si8"
        },
        "Tags": {
          "shape": "S7"
        }
      }
    },
    "S1ly": {
      "type": "structure",
      "members": {
        "TransformJobName": {},
        "TransformJobArn": {},
        "TransformJobStatus": {},
        "FailureReason": {},
        "ModelName": {},
        "MaxConcurrentTransforms": {
          "type": "integer"
        },
        "ModelClientConfig": {
          "shape": "St1"
        },
        "MaxPayloadInMB": {
          "type": "integer"
        },
        "BatchStrategy": {},
        "Environment": {
          "shape": "S44"
        },
        "TransformInput": {
          "shape": "S47"
        },
        "TransformOutput": {
          "shape": "S4b"
        },
        "DataCaptureConfig": {
          "shape": "St4"
        },
        "TransformResources": {
          "shape": "S4e"
        },
        "CreationTime": {
          "type": "timestamp"
        },
        "TransformStartTime": {
          "type": "timestamp"
        },
        "TransformEndTime": {
          "type": "timestamp"
        },
        "LabelingJobArn": {},
        "AutoMLJobArn": {},
        "DataProcessing": {
          "shape": "St5"
        },
        "ExperimentConfig": {
          "shape": "Sqz"
        },
        "Tags": {
          "shape": "S7"
        }
      }
    },
    "S1nf": {
      "type": "list",
      "member": {}
    },
    "S1pw": {
      "type": "list",
      "member": {}
    }
  }
}