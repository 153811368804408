{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2023-09-30",
    "endpointPrefix": "bedrock-runtime",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "Amazon Bedrock Runtime",
    "serviceId": "Bedrock Runtime",
    "signatureVersion": "v4",
    "signingName": "bedrock",
    "uid": "bedrock-runtime-2023-09-30"
  },
  "operations": {
    "InvokeModel": {
      "http": {
        "requestUri": "/model/{modelId}/invoke",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "body",
          "modelId"
        ],
        "members": {
          "body": {
            "shape": "S2"
          },
          "contentType": {
            "location": "header",
            "locationName": "Content-Type"
          },
          "accept": {
            "location": "header",
            "locationName": "Accept"
          },
          "modelId": {
            "location": "uri",
            "locationName": "modelId"
          }
        },
        "payload": "body"
      },
      "output": {
        "type": "structure",
        "required": [
          "body",
          "contentType"
        ],
        "members": {
          "body": {
            "shape": "S2"
          },
          "contentType": {
            "location": "header",
            "locationName": "Content-Type"
          }
        },
        "payload": "body"
      }
    },
    "InvokeModelWithResponseStream": {
      "http": {
        "requestUri": "/model/{modelId}/invoke-with-response-stream",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "body",
          "modelId"
        ],
        "members": {
          "body": {
            "shape": "S2"
          },
          "contentType": {
            "location": "header",
            "locationName": "Content-Type"
          },
          "accept": {
            "location": "header",
            "locationName": "X-Amzn-Bedrock-Accept"
          },
          "modelId": {
            "location": "uri",
            "locationName": "modelId"
          }
        },
        "payload": "body"
      },
      "output": {
        "type": "structure",
        "required": [
          "body",
          "contentType"
        ],
        "members": {
          "body": {
            "type": "structure",
            "members": {
              "chunk": {
                "type": "structure",
                "members": {
                  "bytes": {
                    "type": "blob",
                    "sensitive": true
                  }
                },
                "event": true,
                "sensitive": true
              },
              "internalServerException": {
                "type": "structure",
                "members": {
                  "message": {}
                },
                "error": {
                  "httpStatusCode": 500
                },
                "exception": true,
                "fault": true
              },
              "modelStreamErrorException": {
                "type": "structure",
                "members": {
                  "message": {},
                  "originalStatusCode": {
                    "type": "integer"
                  },
                  "originalMessage": {}
                },
                "error": {
                  "httpStatusCode": 424,
                  "senderFault": true
                },
                "exception": true
              },
              "validationException": {
                "type": "structure",
                "members": {
                  "message": {}
                },
                "error": {
                  "httpStatusCode": 400,
                  "senderFault": true
                },
                "exception": true
              },
              "throttlingException": {
                "type": "structure",
                "members": {
                  "message": {}
                },
                "error": {
                  "httpStatusCode": 429,
                  "senderFault": true
                },
                "exception": true
              },
              "modelTimeoutException": {
                "type": "structure",
                "members": {
                  "message": {}
                },
                "error": {
                  "httpStatusCode": 408,
                  "senderFault": true
                },
                "exception": true
              }
            },
            "eventstream": true
          },
          "contentType": {
            "location": "header",
            "locationName": "X-Amzn-Bedrock-Content-Type"
          }
        },
        "payload": "body"
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "blob",
      "sensitive": true
    }
  }
}