{
  "pagination": {
    "GetServerDetails": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "associatedApplications"
    },
    "ListAnalyzableServers": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "analyzableServers"
    },
    "ListApplicationComponents": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "applicationComponentInfos"
    },
    "ListCollectors": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "Collectors"
    },
    "ListImportFileTask": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "taskInfos"
    },
    "ListServers": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "serverInfos"
    }
  }
}
