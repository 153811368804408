import "../../../style.css";

import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { nanoid } from "nanoid";
import NumberInput from "../../components/NumberInput";
import TextInput from "../../components/TextInput";
import GoButton from "../../components/GoButton";
import Grid from "@mui/material/Unstable_Grid2";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AssignPublicIp, ECSClient, RunTaskCommand } from "@aws-sdk/client-ecs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);
import SelectItems from "../../components/SelectItems";
import { AuthContext } from "../../contexts/authContext";
import { Layout } from "../../layout";

export const Mm2DlpConfig = () => {
    const { sessionInfo } = useContext(AuthContext);
    const { ensureAwsCredentials } = useContext(AuthContext);

    // get URL parameter (if defined)
    const { lat, lon } = useParams();

    // User inputs
    const [longitude, setLongitude] = useState(lon ? Number(lon).toFixed(6) : "");
    const [latitude, setLatitude] = useState(lat ? Number(lat).toFixed(6) : "");
    const [date_start, setStartDate] = useState(dayjs().startOf("day"));
    const [date_end, setEndDate] = useState(dayjs().startOf("day"));
    const [email_recipient_0, setEmailRecipient0] = useState(sessionInfo.email);
    const [parameterPackage, setParameterPackage] = useState<string>("Dummy");

    console.log("date_start", date_start);
    console.log("date_end", date_end);

    // Validation
    const [longitudeError, setLongitudeError] = useState(false);
    const [latitudeError, setLatitudeError] = useState(false);
    const [endDateError, setEndDateError] = useState(false);
    const [startDateError, setStartDateError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [parameterPackageError, setParameterPackageError] = useState(false);

    const dateConstraints = {
        // minDate has to be checked. e.g. with modules/mm2dlp/src/get_available_time_ranges_of_parameters.py
        // maxDate is provided with the model descriptions: https://www.meteomatics.com/en/api/request/optional-parameters/data-source/
        "Dummy": {
            minDate: dayjs(),
            maxDate: dayjs(),
        },
        "Wind Energy": {
            minDate: dayjs("2013-12-30 00:00:00+00:00"),
            maxDate: dayjs().add(10 - 1, "day"),
        },
        "Tide Energy": {
            minDate: dayjs("2019-05-31 00:00:00+00:00"),
            maxDate: dayjs().add(7 - 1, "day"),
        },
        "Wave Energy": {
            minDate: dayjs("2012-01-01 00:00:00+00:00"),
            maxDate: dayjs().add(10 - 1, "day"),
        },
        "Solar Energy": {
            minDate: dayjs("2013-12-31 00:00:00+00:00"),
            maxDate: dayjs().add(10 - 1, "day"),
        },
        "Aviation": {
            minDate: dayjs("2013-12-30 00:00:00+00:00"),
            maxDate: dayjs().add(10 - 1, "day"),
        },
    };

    if (parameterPackage) {
        console.log("parameterPackage: ", typeof parameterPackage);
        console.log("minDate", parameterPackage, dateConstraints[parameterPackage]["minDate"]);
        console.log("maxDate", parameterPackage, dateConstraints[parameterPackage]["maxDate"]);
    }

    function validateInputs() {
        console.log("Validating inputs...");

        let valid = true;

        // Reset errors at the beginning of the validation
        setLongitudeError(false);
        setLatitudeError(false);
        setEndDateError(false);
        setStartDateError(false);
        setEmailError(false);
        setParameterPackageError(false);

        // Validate
        if (longitude === "" || parseFloat(longitude) < -180 || parseFloat(longitude) > 180) {
            setLongitudeError(true);
            valid = false;
        }

        if (latitude === "" || parseFloat(latitude) < -90 || parseFloat(latitude) > 90) {
            setLatitudeError(true);
            valid = false;
        }

        if (
            !date_end.isValid() ||
            date_end.isAfter(dateConstraints[parameterPackage]["maxDate"]) ||
            !date_end.isAfter(date_start) ||
            date_end.isSame(date_start)
        ) {
            setEndDateError(true);
            valid = false;
        }

        if (
            !date_start.isValid() ||
            date_start.isBefore(dateConstraints[parameterPackage]["minDate"]) ||
            !date_start.isBefore(date_end) ||
            date_start.isSame(date_end)
        ) {
            setStartDateError(true);
            valid = false;
        }

        if (
            email_recipient_0 === "" ||
            !email_recipient_0.includes("@") ||
            !email_recipient_0.includes(".")
        ) {
            setEmailError(true);
            valid = false;
        }

        if (parameterPackage === "") {
            setParameterPackageError(true);
            valid = false;
        }

        // Raise alert if relevant
        if (!valid) {
            alert("Some values are invalid. Please check your inputs.");
            return false;
        } else {
            return true;
        }
    }

    const saveInputs = async () => {

        const jobid = nanoid();

        console.log(`Saving with jobid = ${jobid}...`);

        // Configure AWS clients
        const awsConfig = await ensureAwsCredentials();

        const s3Client = new S3Client({
            credentials: awsConfig.credentials,
            region: awsConfig.region
        });

        const ecsClient = new ECSClient({
            credentials: awsConfig.credentials,
            region: awsConfig.region
        });

        // data source
        const key_data_source = "modules/mm2dlp/jobs/" + jobid + "/config_data_source.json";

        const body_data_source = JSON.stringify({
            email_recipients: [email_recipient_0],
            latitude,
            longitude,
            // make sure that date_start is not before minDate. can happen due to timezone shenanigans.
            date_start: date_start
                .startOf("day")
                .utc()
                .isBefore(dateConstraints[parameterPackage]["minDate"])
                ? dateConstraints[parameterPackage]["minDate"]
                : date_start.startOf("day").utc(),
            // make sure that date_end is not after maxDate. can happen due to timezone shenanigans.
            date_end: date_end
                .endOf("day")
                .utc()
                .isAfter(dateConstraints[parameterPackage]["maxDate"])
                ? dateConstraints[parameterPackage]["maxDate"]
                : date_end.endOf("day").utc(),
            interval_hourly: 3,
            parameter_package: parameterPackage,
            next: "Send email with presigned URL to user",
        });

        console.log(body_data_source);

        await s3Client.send(
            new PutObjectCommand({
                Bucket: "shell-dw-module-output",
                Key: key_data_source,
                Body: body_data_source,
            })
        );

        alert(
            "New job created with ID " +
                jobid +
                "\n\n" +
                "Results will be sent to " +
                email_recipient_0
        );

        const response = await ecsClient.send(
            new RunTaskCommand({
                taskDefinition: "mm2dlp",
                cluster:
                    "arn:aws:ecs:us-east-1:348482033654:cluster/dwapi-EcsCluster-NQNzVpomowO9",
                launchType: "FARGATE",
                networkConfiguration: {
                    awsvpcConfiguration: {
                        securityGroups: ["sg-029fe4ad2e115f887"],
                        subnets: ["subnet-01ef9e932cd8d4ee5"],
                        assignPublicIp: AssignPublicIp.ENABLED,
                    },
                },
                overrides: {
                    containerOverrides: [
                        {
                            name: "module",
                            command: ["python3", "main.py", jobid],
                        },
                    ],
                },
            })
        );
    };

    const content = (
        <div>
            {/* <MenuAppBar module_name="Meteomatics2dlp" /> */}
            <p className="module-guide-text">
                This module fecthes the selected <strong>Parameter package</strong> from
                Meteomatics for the period between the <strong>Start date</strong> and{" "}
                <strong>End date</strong> at the location of the <strong>Latitude</strong> and{" "}
                <strong>Longitude</strong>. A link to download the data in DLP format is sent to
                the <strong>E-mail recipient</strong>.
            </p>
            <Grid container spacing={2} justifyContent="center" columns={{ xs: 6, sm: 12 }}>
                <Grid xs={6} className="input-box">
                    <TextInput
                        name_parameter={"E-mail recipient"}
                        onChange={(ev) => setEmailRecipient0(ev)}
                        error={emailError}
                        value={email_recipient_0}
                    />
                </Grid>
                <Grid xs={6} className="input-box">
                    <SelectItems
                        name_parameter={"Parameter package"}
                        namesOptions={[
                            "Wind Energy",
                            "Tide Energy",
                            "Wave Energy",
                            "Solar Energy",
                            "Aviation",
                        ]}
                        error={parameterPackageError}
                        multiple={false}
                        onChange={(ev) => {
                            setParameterPackage(ev);
                        }}
                        disabled={false}
                    />
                </Grid>
                <Grid xs={6} className="grid-item">
                    <FormControl variant="outlined" error={startDateError} className="input-box">
                        <FormHelperText id="outlined-weight-helper-text">
                            Start date
                        </FormHelperText>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                // defaultValue={dateConstraints[parameterPackage]['minDate']}
                                minDate={
                                    parameterPackage
                                        ? dateConstraints[parameterPackage]["minDate"]
                                        : dayjs()
                                }
                                maxDate={
                                    parameterPackage
                                        ? dateConstraints[parameterPackage]["maxDate"].subtract(
                                              1,
                                              "day"
                                          )
                                        : dayjs()
                                }
                                format="YYYY-MM-DD"
                                views={["year", "month", "day"]}
                                timezone="system"
                                onChange={(ev) => {
                                    setStartDate(ev);
                                }}
                                slotProps={{ textField: { fullWidth: true } }}
                                disabled={parameterPackage === "Dummy" ? true : false}
                            />
                        </LocalizationProvider>
                    </FormControl>
                </Grid>
                <Grid xs={6}>
                    <FormControl variant="outlined" error={endDateError} className="input-box">
                        <FormHelperText id="outlined-weight-helper-text">End date</FormHelperText>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                // defaultValue={dateConstraints[parameterPackage]['maxDate']}
                                minDate={
                                    parameterPackage
                                        ? dateConstraints[parameterPackage]["minDate"].add(
                                              1,
                                              "day"
                                          )
                                        : dayjs()
                                }
                                maxDate={
                                    parameterPackage
                                        ? dateConstraints[parameterPackage]["maxDate"]
                                        : dayjs()
                                }
                                format="YYYY-MM-DD"
                                views={["year", "month", "day"]}
                                timezone="system"
                                onChange={(ev) => {
                                    setEndDate(ev);
                                }}
                                slotProps={{ textField: { fullWidth: true } }}
                                disabled={parameterPackage === "Dummy" ? true : false}
                            />
                        </LocalizationProvider>
                    </FormControl>
                </Grid>
                <Grid xs={6}>
                    <NumberInput
                        name_parameter={"Latitude"}
                        unit={"deg"}
                        defaultValue={latitude}
                        step="0.000001"
                        onChange={(ev) => setLatitude(ev)}
                        error={latitudeError}
                    />
                </Grid>
                <Grid xs={6} className="grid-item">
                    <NumberInput
                        name_parameter={"Longitude"}
                        unit={"deg"}
                        defaultValue={longitude}
                        step="0.000001"
                        onChange={(ev) => setLongitude(ev)}
                        error={longitudeError}
                    />
                </Grid>
            </Grid>
            <br />
            <br />
            <GoButton validateInputs={validateInputs} saveInputs={saveInputs} />
        </div>
    );

    return <Layout content={content} moduleName="Meteomatics2dlp" />;
};
