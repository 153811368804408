import "../../style.css";

import { Button } from "@mui/material";
import { ReactElement } from "react";

interface Props {
    validateInputs: Function;
    saveInputs: Function
}

const GoButton = (props: Props):
ReactElement => {

    const { validateInputs, saveInputs } = props;

    return (
        <div style={{textAlign: 'center'}}>
            <Button 
                onClick={() => {
                    if (validateInputs()) {
                        saveInputs();
                    } 
                    }} 
                variant="GO"
            >
                GO
            </Button>
        </div>
    )
}

export default GoButton