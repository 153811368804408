import "../../../style.css";

import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    CircularProgress,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    Tooltip,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Layout } from "../../layout";
import useAwsSchedulerClient from "../../hooks/useAwsSchedulerClient";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";
import { AuthContext } from "../../contexts/authContext";

const CustomTable = styled(Table)(({ theme }) => ({
    ["& .MuiTableCell-sizeSmall"]: {
        padding: "10px 2px 10px 2px",
    },
    ["& .MuiTableCell-head"]: {
        fontSize: 14,
        textAlign: "center",
        fontWeight: 700,
    },
    ["& .MuiTableCell-body"]: {
        fontSize: 14,
        textAlign: "center",
    },
}));

export const McpMlConfig0 = (): React.ReactElement => {
    const { ensureAwsCredentials } = useContext(AuthContext);

    const [existingSchedulesDetails, setExistingSchedulesDetails] = useState<any>();

    const navigate = useNavigate();


    useEffect(() => {
        (async () => {
            const awsConfig = await ensureAwsCredentials();
            const { runListSchedulesCommand, getExistingSchedulesDetails } =
                useAwsSchedulerClient(awsConfig);

            // Get list of existing schedules
            runListSchedulesCommand()
                // Get the details of the existing schedules from the list
                .then((data) =>
                    getExistingSchedulesDetails(data).then((data) => setExistingSchedulesDetails(data))
                );
        })();
    }, []);

    // Show progress/loading page until catalog has been loaded
    if (!existingSchedulesDetails) {
        const loadingContent = (
            <Grid container spacing={2} columns={{ xs: 6, sm: 12 }}>
                <Grid
                    xs={12}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "100px",
                    }}
                >
                    <CircularProgress />
                </Grid>
            </Grid>
        );

        return <Layout content={loadingContent} moduleName="MCP-ML4" />;
    }

    const content = (
        <>
            <TableContainer component={Paper}>
                <CustomTable size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell width="25%">
                                <div>Name</div> <div>(jobid)</div>
                            </TableCell>
                            <TableCell width="47%">Description</TableCell>
                            <TableCell width="12%">Schedule expression</TableCell>
                            <TableCell width="12%">
                                <div>Start date</div> <div>[UTC+00:00]</div>
                            </TableCell>
                            <TableCell width="2%">{}</TableCell>
                            <TableCell width="2%">{}</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {existingSchedulesDetails.map((schedule, idx) => (
                            <TableRow key={idx}>
                                <TableCell width="25%">
                                    <div>{schedule["Name"]}</div>{" "}
                                    <div>
                                        ({schedule["Target"]["EcsParameters"]["Tags"][0]["jobid"]})
                                    </div>
                                </TableCell>
                                <TableCell width="47%">{schedule["Description"]}</TableCell>
                                <TableCell width="12%">{schedule["ScheduleExpression"]}</TableCell>
                                <TableCell width="12%">
                                    <div>{schedule["StartDate"].toISOString().split("T")[0]}</div>{" "}
                                    <div>
                                        {
                                            schedule["StartDate"]
                                                .toISOString()
                                                .split("T")[1]
                                                .split(".")[0]
                                        }
                                    </div>
                                </TableCell>
                                <TableCell width="2%">
                                    <Tooltip title="Edit" placement="top">
                                        <IconButton href={`/mcp-ml/setup/${schedule["Name"]}`}>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                                <TableCell width="2%">
                                    <Tooltip title="Delete" placement="top">
                                        <IconButton
                                            onClick={async () => {
                                                const awsConfig = await ensureAwsCredentials();
                                                const { runDeleteScheduleCommand } =
                                                    useAwsSchedulerClient(awsConfig);

                                                if (
                                                    confirm(
                                                        `Do you want to delete the schedule: ${schedule["Name"]}?`
                                                    )
                                                ) {
                                                    runDeleteScheduleCommand(
                                                        schedule["Name"]
                                                    ).then(() => window.location.reload());
                                                }
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </CustomTable>
            </TableContainer>

            <Grid container textAlign={"center"} marginTop={"30px"} marginBottom={"30px"}>
                <Grid xs={12}>
                    <Button
                        disabled={false}
                        onClick={() => {
                            navigate("/mcp-ml/setup");
                        }}
                        variant="GO"
                    >
                        New forecast
                    </Button>
                </Grid>
            </Grid>
        </>
    );

    return <Layout content={content} moduleName="MCP-ML4" />;
};
